import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTextBulletGroupLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1V4zm3 0H3v2h2V4z"
          fill="currentColor"
        />
        <path
          d="M8 3a.5.5 0 000 1h5.5a.5.5 0 000-1H8zM7.5 6.5A.5.5 0 018 6h5.5a.5.5 0 010 1H8a.5.5 0 01-.5-.5zM8 9a.5.5 0 000 1h5.5a.5.5 0 000-1H8zM8 12a.5.5 0 000 1h5.5a.5.5 0 000-1H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 9a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1H3zm0 1h2v2H3v-2z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 01-1-1V4zm4 0H3v3h3V4z"
        fill="currentColor"
      />
      <path
        d="M9.5 3a.5.5 0 000 1h7a.5.5 0 000-1h-7zM9 7.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM9.5 11a.5.5 0 000 1h7a.5.5 0 000-1h-7zM9.5 15a.5.5 0 000 1h7a.5.5 0 000-1h-7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1H3zm0 1h3v3H3v-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletGroupLight)
