import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeDRadiusDownLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14.017c0 .847-1.023 1.27-1.622.672L.574 8.884a1.25 1.25 0 010-1.768l5.804-5.805C6.977.713 8 1.137 8 1.983v2.759c1.41-.034 2.28-.176 2.996-.505.83-.38 1.543-1.05 2.643-2.367a.75.75 0 011.324.428c.225 3.158-.585 5.435-2.095 6.916-1.313 1.288-3.066 1.885-4.868 2.01v2.793zM6.5 12.69V10.5a.75.75 0 01.75-.75h.003c1.804 0 3.437-.5 4.565-1.607.843-.827 1.471-2.059 1.638-3.844-.585.565-1.166.995-1.835 1.302-1.155.529-2.485.649-4.368.649H7.25a.75.75 0 01-.75-.75V3.311L1.81 8.001 6.5 12.69z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 17.044c0 1.113-1.346 1.67-2.134.883l-6.69-6.689a1.75 1.75 0 010-2.475l6.69-6.69C8.654 1.287 10 1.845 10 2.959V6c1.32-.007 2.358-.055 3.394-.396 1.14-.375 2.343-1.132 3.866-2.746.575-.609 1.692-.292 1.728.641.14 3.694-.916 6.354-2.738 8.084-1.687 1.601-3.94 2.32-6.25 2.408v3.053zm-1.5-.604v-3.19a.75.75 0 01.75-.75h.254c2.217 0 4.261-.627 5.713-2.005 1.261-1.198 2.156-3.038 2.27-5.747-1.28 1.194-2.437 1.89-3.624 2.281-1.426.47-2.832.472-4.36.472H9.25a.75.75 0 01-.75-.75V3.56L2.237 9.824a.25.25 0 000 .353L8.5 16.44z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 20.551c0 1.203-1.454 1.805-2.305.955l-8.178-8.18a1.875 1.875 0 010-2.651l8.178-8.18c.85-.85 2.305-.247 2.305.955V7c1.8 0 3.18-.007 4.564-.453 1.37-.442 2.807-1.336 4.608-3.24.604-.638 1.777-.307 1.814.673.168 4.415-1.09 7.691-3.219 9.865-2.064 2.107-4.872 3.1-7.767 3.153v3.553zm-1.5-.362V16.25a.75.75 0 01.75-.75h.505c2.657 0 5.151-.878 6.94-2.705 1.623-1.656 2.733-4.162 2.803-7.686-1.599 1.535-3.023 2.398-4.474 2.865-1.637.528-3.247.527-4.998.526h-.776a.75.75 0 01-.75-.75V3.813l-7.922 7.923a.375.375 0 000 .53l7.922 7.923z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.005 26.886c0 1.67-2.02 2.507-3.2 1.326l-9.92-9.915a3.25 3.25 0 010-4.597l9.92-9.916c1.18-1.18 3.2-.344 3.2 1.326v4.884c2.003-.024 3.631-.15 5.254-.724 1.866-.659 3.815-1.944 6.273-4.557.807-.858 2.37-.407 2.42.895.223 5.861-1.447 10.01-4.31 12.681-2.6 2.426-6.062 3.519-9.637 3.687v4.91zm-2-.301V21a1 1 0 011-1c3.594 0 6.913-.971 9.272-3.173 2.125-1.982 3.594-5.076 3.69-9.701-2.165 2.109-4.083 3.338-6.042 4.03-2.244.792-4.453.843-6.92.843a1 1 0 01-1-1V5.412l-9.706 9.702a1.25 1.25 0 000 1.768l9.706 9.703z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.999 53.774c0 2.895-3.501 4.346-5.548 2.298L4.56 35.181a4.5 4.5 0 010-6.364l20.89-20.89c2.047-2.048 5.548-.598 5.548 2.297v9.774c4.282-.014 7.758-.14 11.24-1.264 3.833-1.238 7.797-3.737 12.738-8.968 1.33-1.408 3.893-.663 3.974 1.47.442 11.63-2.882 19.888-8.479 25.226-5.212 4.972-12.216 7.239-19.473 7.51v9.802zm-3 0V42.499a1.5 1.5 0 011.5-1.5h.002c7.25 0 14.04-2.07 18.9-6.708 4.584-4.371 7.639-11.2 7.588-21.26-4.668 4.694-8.715 7.23-12.828 8.558-4.367 1.41-8.686 1.41-13.535 1.41h-.127a1.5 1.5 0 01-1.5-1.5V10.225a.251.251 0 00-.427-.177L6.682 30.94a1.5 1.5 0 000 2.12l20.89 20.892a.251.251 0 00.427-.177z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusDownLeftRegular)
