import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoFacebookRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.708 8L11 6H9V5c0-.52.318-1.029 1.136-1.029h.83V2.13S10.213 2 9.492 2c-1.504 0-2.487.912-2.487 2.562V6H5v2h2.005v6H9V8h1.708z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.363 1.895C9.256.998 10.502.568 11.909.568a15.224 15.224 0 012.413.217l.043.008.012.002.004.001s.002 0-.139.737l.14-.737a.75.75 0 01.61.737V4.24a.75.75 0 01-.75.75h-1.258c-.52 0-.71.153-.794.256-.108.132-.183.362-.183.712v.056h2.236a.75.75 0 01.74.866l-.487 3.106a.75.75 0 01-.741.634h-1.748v6.63a.75.75 0 01-.75.75H7.762a.75.75 0 01-.75-.75v-6.63h-2.05a.75.75 0 01-.75-.75V6.764a.75.75 0 01.75-.75h2.05v-.416c0-1.527.456-2.806 1.35-3.703zm5.13.282a12.718 12.718 0 00-1.583-.109c-1.105 0-1.933.332-2.485.886-.551.553-.913 1.414-.913 2.644v1.166a.75.75 0 01-.75.75h-2.05V9.12h2.05a.75.75 0 01.75.75v6.63h1.995V9.87a.75.75 0 01.75-.75h1.857l.252-1.606h-2.11a.75.75 0 01-.75-.75v-.806c0-.52.106-1.15.52-1.66.44-.538 1.112-.808 1.959-.808h.508V2.177z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.77 2.48c.987-.954 2.349-1.417 3.87-1.417a13.215 13.215 0 012.463.24l.042.009.012.002.004.001h.002L17 2.049l.163-.732a.751.751 0 01.587.732V5a.75.75 0 01-.75.75h-1.2c-.58 0-.776.17-.86.282-.116.155-.19.423-.19.841V8.25h2.434a.75.75 0 01.74.87l-.532 3.251a.75.75 0 01-.74.629H14.75v8a.75.75 0 01-.75.75h-4a.75.75 0 01-.75-.75v-8H7a.75.75 0 01-.75-.75V9A.75.75 0 017 8.25h2.25V6.48c0-1.665.53-3.04 1.52-4zm5.48.2a11.714 11.714 0 00-1.61-.116c-1.22 0-2.178.367-2.826.995-.645.623-1.064 1.58-1.064 2.922V9a.75.75 0 01-.75.75H7.75v1.75H10a.75.75 0 01.75.75v8h2.5v-8a.75.75 0 01.75-.75h2.015l.286-1.75h-2.3a.75.75 0 01-.75-.75V6.873c0-.53.08-1.199.492-1.744.443-.588 1.148-.88 2.057-.88h.45V2.68z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.882 4.836C15.121 3.596 16.847 3 18.801 3c.884 0 1.968.076 2.815.149a63.644 63.644 0 011.381.134l.086.01.03.003L23 4.29l.113-.994A1 1 0 0124 4.29V8a1 1 0 01-.996 1l-2.52.01h-.003c-.736 0-1.055.219-1.208.397-.177.204-.292.537-.292.998v1.614h3.254a1 1 0 01.992 1.132l-.662 4.98a1 1 0 01-.99.869h-2.577l-.017 9.002a1 1 0 01-1 .998h-4.974a1 1 0 01-1-1.002L12.024 19H9.019a1 1 0 01-1-.996L8 13.023a1 1 0 011-1.004h3.007V9.968c0-2.122.636-3.892 1.875-5.132zM22 5.192c-.17-.016-.357-.034-.556-.05C20.61 5.068 19.595 5 18.8 5c-1.55 0-2.721.466-3.504 1.25-.781.781-1.29 1.995-1.29 3.718v3.051a1 1 0 01-1 1h-3.003L10.015 17h3.01a1 1 0 011 1.002L14.01 27h2.974L17 17.998A1 1 0 0118 17h2.698l.396-2.98H17.98a1 1 0 01-1-1v-2.615c0-.752.182-1.617.779-2.307.62-.716 1.55-1.088 2.719-1.088h.002L22 7.004V5.192z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.619 10.524C29.982 8.16 33.295 7 37.102 7c2.511 0 5.357.32 6.736.496a2.482 2.482 0 012.165 2.466v5.558a2.5 2.5 0 01-2.5 2.5h-3.041c-1.58 0-2.37.476-2.794.966-.46.531-.705 1.33-.705 2.324v3.728h5.845a2.5 2.5 0 012.472 2.874l-1.057 6.993a2.5 2.5 0 01-2.472 2.126h-4.788V55.5a2.5 2.5 0 01-2.5 2.5h-7.949a2.5 2.5 0 01-2.5-2.5V37.031H18.5a2.5 2.5 0 01-2.5-2.5v-6.993a2.5 2.5 0 012.5-2.5h5.514v-4.602c0-4.144 1.24-7.545 3.605-9.912zm2.122 2.12c-1.677 1.68-2.727 4.247-2.727 7.792v6.102a1.5 1.5 0 01-1.5 1.5H19v5.993h6.514a1.5 1.5 0 011.5 1.5V55h6.949V35.531a1.5 1.5 0 011.5-1.5h5.858l.906-5.993h-6.764a1.5 1.5 0 01-1.5-1.5V21.31c0-1.43.347-3.028 1.436-4.286 1.123-1.299 2.832-2.005 5.063-2.005h2.54v-4.604c-1.444-.174-3.824-.415-5.9-.415-3.2 0-5.682.964-7.36 2.645z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoFacebookRegular)
