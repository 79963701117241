import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTimeClockFastLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 7.5a5.5 5.5 0 116 5.478v1.003A6.5 6.5 0 101.019 8h1.003A5.57 5.57 0 012 7.5z"
          fill="currentColor"
        />
        <path
          d="M7.5 3.902a.5.5 0 01.5.5v2.325c0 .284.16.544.415.671l2.309 1.155a.5.5 0 11-.448.894L7.967 8.293A1.75 1.75 0 017 6.727V4.402a.5.5 0 01.5-.5zM.797 9a.5.5 0 000 1H3a.5.5 0 000-1H.797zM0 11.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM1.5 13a.5.5 0 000 1H6a.5.5 0 000-1H1.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3.07 9A7.002 7.002 0 0117 10a7 7 0 01-7 7v1a8 8 0 10-7.938-9H3.07z"
        fill="currentColor"
      />
      <path
        d="M9.503 6.005a.5.5 0 01.5.5v2.972c0 .284.16.544.415.67l2.806 1.406a.5.5 0 01-.448.894L9.97 11.042a1.75 1.75 0 01-.967-1.565V6.505a.5.5 0 01.5-.5zM1.5 17a.5.5 0 000 1h6a.5.5 0 000-1h-6zM0 14.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zM1.5 11a.5.5 0 000 1H4a.5.5 0 000-1H1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeClockFastLight)
