import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.502 3.502 0 100 7.003A3.502 3.502 0 008 1zM5.498 4.502a2.502 2.502 0 115.004 0 2.502 2.502 0 01-5.004 0z"
          fill="currentColor"
        />
        <path
          d="M3 13.505c0-.855.208-1.473.518-1.93.311-.46.749-.79 1.26-1.025 1.04-.481 2.32-.545 3.222-.545.903 0 2.181.064 3.222.544.511.237.949.567 1.26 1.026.31.457.518 1.075.518 1.93a.5.5 0 001 0c0-1.025-.252-1.846-.69-2.491-.436-.643-1.032-1.078-1.669-1.372-1.254-.58-2.726-.637-3.641-.637s-2.387.057-3.641.637c-.637.294-1.233.729-1.67 1.372-.437.645-.689 1.466-.689 2.49a.5.5 0 001 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a4.499 4.499 0 100 8.997A4.499 4.499 0 0010 2zM6.501 6.499a3.499 3.499 0 116.998 0 3.499 3.499 0 01-6.998 0z"
        fill="currentColor"
      />
      <path
        d="M3 17.5c0-1.08.287-1.872.725-2.466.443-.598 1.066-1.028 1.79-1.335C6.98 13.079 8.765 13 10 13c1.234 0 3.02.079 4.485.699.724.307 1.347.737 1.79 1.335.439.594.725 1.386.725 2.466a.5.5 0 001 0c0-1.269-.341-2.276-.921-3.06-.577-.78-1.364-1.306-2.205-1.662C13.21 12.072 11.244 12 10 12c-1.244 0-3.209.072-4.875.778-.84.356-1.627.882-2.204 1.662C2.341 15.224 2 16.23 2 17.5a.5.5 0 001 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1Light)
