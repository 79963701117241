import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconStarHalfRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.311.9A.75.75 0 018.69.9l1.764 4.096 4.442.412a.75.75 0 01.425 1.31l-3.35 2.944.98 4.352a.75.75 0 01-1.114.81L8 12.545l-3.835 2.277a.75.75 0 01-1.115-.81l.98-4.35L.68 6.717a.75.75 0 01.425-1.31l4.442-.412L7.31.899zm1.765 4.69a1.5 1.5 0 001.239.9l2.707.25-2.043 1.795a1.5 1.5 0 00-.473 1.457l.598 2.652-2.338-1.388c-.236-.14-.501-.21-.766-.21V3.092l1.076 2.497z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.139 1.7a.925.925 0 01.86-.567c.35 0 .7.19.862.567l2.03 4.713 5.11.474c.819.076 1.15 1.095.532 1.638l-3.856 3.387 1.129 5.006c.18.802-.686 1.432-1.394 1.012L10 15.31l-4.413 2.62c-.655.39-1.448-.123-1.416-.84a.983.983 0 01.023-.173l1.129-5.005-3.856-3.387a.918.918 0 01-.318-.76.933.933 0 01.85-.878l5.11-.474L9.14 1.7zm.86 12.11c.266 0 .53.07.767.21l3.29 1.953-.842-3.732a1.5 1.5 0 01.473-1.456l2.874-2.525-3.809-.353a1.5 1.5 0 01-1.239-.9L10 3.492V13.81z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.046 9.325a1.12 1.12 0 011.02-1.06l6.37-.59 2.53-5.876c.391-.907 1.677-.907 2.067 0l2.53 5.876 6.37.59c.984.092 1.381 1.315.64 1.966l-4.807 4.222 1.407 6.241c.217.963-.823 1.719-1.672 1.215L12 18.643l-5.5 3.266c-.793.47-1.75-.155-1.7-1.023.004-.063.013-.127.027-.192l1.407-6.24-4.806-4.223a1.102 1.102 0 01-.382-.906zm13.14-1.057a1.5 1.5 0 001.24.9l5.502.51-4.152 3.648a1.5 1.5 0 00-.473 1.457l1.215 5.392-4.752-2.822a1.5 1.5 0 00-.766-.21V3.193l2.186 5.075z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.622 2.199A1.48 1.48 0 0116 1.292a1.48 1.48 0 011.378.907l3.439 7.986 8.657.803c1.31.121 1.84 1.751.851 2.62l-6.532 5.738 1.912 8.482c.29 1.284-1.097 2.292-2.23 1.62L16 25.008l-7.476 4.44c-1.027.61-2.264-.163-2.268-1.27 0-.114.012-.231.039-.35l1.911-8.482-6.531-5.738a1.47 1.47 0 01-.513-1.11c-.003-.725.518-1.432 1.364-1.51l8.657-.804 3.44-7.985zM16 23.009a2 2 0 011.021.28l6.478 3.846-1.657-7.349a2 2 0 01.632-1.942l5.66-4.972-7.502-.696a2 2 0 01-1.652-1.2L16 4.056V23.01z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.207 5.68A3.01 3.01 0 0132 5.096c1.117 0 2.235.605 2.755 1.814l6.342 14.727 15.966 1.48c2.622.244 3.681 3.504 1.703 5.241L46.72 38.941l3.526 15.642c.578 2.569-2.195 4.584-4.459 3.24L32 49.635l-13.787 8.186c-2.264 1.344-5.037-.67-4.459-3.239l3.526-15.642L5.234 28.358c-1.237-1.085-1.286-2.766-.51-3.934.19-.284.427-.537.709-.744.412-.304.92-.508 1.503-.563l15.966-1.48L29.245 6.91c.032-.076.067-.15.104-.22M32 46.636a3 3 0 011.532.42l13.787 8.187L43.793 39.6a3 3 0 01.947-2.914l12.046-10.582-15.966-1.481a3 3 0 01-2.478-1.8L32 8.095v38.54z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconStarHalfRegular)
