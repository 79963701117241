import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTextBulletChecklistLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.604 2.354a.5.5 0 10-.708-.708L2.5 4.043l-.896-.897a.5.5 0 10-.708.708l.897.896a1 1 0 001.414 0l2.397-2.396zM13.5 4h-6a.5.5 0 010-1h6a.5.5 0 010 1zM7.5 7a.5.5 0 000 1h6a.5.5 0 000-1h-6zM7.5 11a.5.5 0 000 1h6a.5.5 0 000-1h-6zM5.604 5.896a.5.5 0 010 .708L3.207 9a1 1 0 01-1.414 0l-.897-.896a.5.5 0 11.708-.708l.896.897 2.396-2.397a.5.5 0 01.708 0zM5.604 10.854a.5.5 0 00-.708-.708L2.5 12.543l-.896-.897a.5.5 0 00-.708.708l.897.896a1 1 0 001.414 0l2.397-2.396z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.604 3.354a.5.5 0 10-.708-.708L3.5 5.043l-.896-.897a.5.5 0 10-.708.708l.897.896a1 1 0 001.414 0l2.397-2.396zM16.5 5h-8a.5.5 0 010-1h8a.5.5 0 010 1zM8.5 9a.5.5 0 000 1h8a.5.5 0 000-1h-8zM16.5 14h-8a.5.5 0 000 1h8a.5.5 0 000-1zM6.604 7.646a.5.5 0 010 .708L4.207 10.75a1 1 0 01-1.414 0l-.897-.896a.5.5 0 11.708-.708l.896.897 2.396-2.397a.5.5 0 01.708 0zM6.604 13.354a.5.5 0 00-.708-.708L3.5 15.043l-.896-.897a.5.5 0 00-.708.708l.897.896a1 1 0 001.414 0l2.397-2.396z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletChecklistLight)
