import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowUiAuthLogoutLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.75 2A1.75 1.75 0 004 3.75v7.5c0 .966.784 1.75 1.75 1.75H9.5a.5.5 0 010 1H5.75A2.75 2.75 0 013 11.25v-7.5A2.75 2.75 0 015.75 1H9.5a.5.5 0 010 1H5.75z"
          fill="currentColor"
        />
        <path
          d="M11.127 4.164a.5.5 0 00.035.707L13.515 7H6.5a.5.5 0 000 1h7.015l-2.353 2.13a.5.5 0 10.671.74l2.7-2.443a1.25 1.25 0 000-1.854l-2.7-2.444a.5.5 0 00-.706.035z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3 5a3 3 0 013-3h5.5a.5.5 0 010 1H6a2 2 0 00-2 2v9a2 2 0 002 2h5.5a.5.5 0 010 1H6a3 3 0 01-3-3V5z"
        fill="currentColor"
      />
      <path
        d="M16.784 9H7.5a.5.5 0 000 1h9.284l-3.248 3.247a.5.5 0 00.707.707l3.273-3.272a1.672 1.672 0 000-2.365l-3.273-3.273a.5.5 0 10-.707.707L16.784 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiAuthLogoutLight)
