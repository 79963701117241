import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCollapseCHClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.505 9.202a1.7 1.7 0 000-2.404L3.354 3.646a.5.5 0 10-.708.708l3.152 3.151a.7.7 0 010 .99l-3.152 3.151a.5.5 0 00.708.708l3.151-3.152zM9.495 9.202a1.7 1.7 0 010-2.404l3.151-3.152a.5.5 0 01.708.708l-3.152 3.151a.7.7 0 000 .99l3.152 3.151a.5.5 0 01-.708.708L9.495 9.202z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8.293 11.414a2 2 0 000-2.828l-4.44-4.44a.5.5 0 10-.707.708l4.44 4.439a1 1 0 010 1.414l-4.44 4.44a.5.5 0 00.708.707l4.439-4.44zM11.707 11.414a2 2 0 010-2.828l4.44-4.44a.5.5 0 01.707.708l-4.44 4.439a1 1 0 000 1.414l4.44 4.44a.5.5 0 01-.708.707l-4.439-4.44z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseCHClosedLight)
