import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDataChartPieA2Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h6a.5.5 0 00.5-.5A6.5 6.5 0 007.5 1zM8 7V2.023A5.5 5.5 0 0112.977 7H8z"
          fill="currentColor"
        />
        <path
          d="M5.683 2.309a.5.5 0 00-.33-.944 6.5 6.5 0 108.272 8.31.5.5 0 00-.942-.334 5.5 5.5 0 11-7-7.032z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5A7.5 7.5 0 009.5 2zm.5 7V3.02A6.5 6.5 0 0115.98 9H10z"
        fill="currentColor"
      />
      <path
        d="M7.225 3.411a.5.5 0 00-.35-.937 7.5 7.5 0 109.639 9.683.5.5 0 00-.935-.354A6.5 6.5 0 117.225 3.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartPieA2Light)
