import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyWalletARegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.15 9.4a.7.7 0 100-1.4.7.7 0 000 1.4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 3.499a1 1 0 011-1h7.75a.75.75 0 100-1.5H3.5a2.5 2.5 0 00-2.5 2.5v8a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5v-.24c.37-.185.625-.567.625-1.009V7.127c0-.441-.255-.824-.625-1.008V6a2.5 2.5 0 00-2.5-2.5H4.25a.75.75 0 000 1.5h8.25a1 1 0 011 1v.002h-2.85a2.625 2.625 0 00-2.625 2.625v.123a2.625 2.625 0 002.625 2.625h2.85v.123a1 1 0 01-1 1h-9a1 1 0 01-1-1v-8zm8.15 3.753c-.76 0-1.375.616-1.375 1.375v.123c0 .76.616 1.375 1.375 1.375h3.725V7.252H10.65z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M14 11.9a.9.9 0 100-1.8.9.9 0 000 1.8z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 5.2c0-.8.65-1.45 1.45-1.45H14a.75.75 0 000-1.5H4.2A2.95 2.95 0 001.25 5.2v9.6a2.95 2.95 0 002.95 2.95h10.6a2.95 2.95 0 002.95-2.95v-.808A1.4 1.4 0 0019 12.6V9.4a1.4 1.4 0 00-1.256-1.393A2.95 2.95 0 0014.8 5.25H5a.75.75 0 000 1.5h9.8A1.45 1.45 0 0116.236 8H13.5a3 3 0 100 6h2.75v.8c0 .8-.65 1.45-1.45 1.45H4.2c-.8 0-1.45-.65-1.45-1.45V5.2zM13.5 9.5h4v3h-4a1.5 1.5 0 010-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M17 15a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 6.5a2 2 0 012-2h11.75a.75.75 0 000-1.5H5.5A3.5 3.5 0 002 6.5v12A3.5 3.5 0 005.5 22h13a3.5 3.5 0 003.5-3.5v-.835a1.5 1.5 0 001-1.415v-4.5a1.5 1.5 0 00-1-1.415V9.5A3.5 3.5 0 0018.5 6H6a.75.75 0 000 1.5h12.5a2 2 0 012 2v.75H17a3.75 3.75 0 100 7.5h3.5v.75a2 2 0 01-2 2h-13a2 2 0 01-2-2v-12zM17 11.75h4.5v4.5H17a2.25 2.25 0 010-4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M22 19.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 8.5A2.5 2.5 0 017.5 6H23a1 1 0 100-2H7.5A4.5 4.5 0 003 8.5v16A4.5 4.5 0 007.5 29h17a4.5 4.5 0 004.5-4.5v-1.768A2 2 0 0030 21v-5a2 2 0 00-1-1.732V12.5A4.5 4.5 0 0024.5 8H8a1 1 0 000 2h16.5a2.5 2.5 0 012.5 2.5V14h-5.5a4.5 4.5 0 100 9H27v1.5a2.5 2.5 0 01-2.5 2.5h-17A2.5 2.5 0 015 24.5v-16zM21.5 16H28v5h-6.5a2.5 2.5 0 010-5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path d="M46 37.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7A8.5 8.5 0 006 15.5v32a8.5 8.5 0 008.5 8.5h35a8.5 8.5 0 008.5-8.5v-3.535a3.501 3.501 0 003-3.465v-11a3.501 3.501 0 00-3-3.465V22.5a8.5 8.5 0 00-8.5-8.5h-35a1.5 1.5 0 000 3h35a5.5 5.5 0 015.5 5.5V26h-9a9 9 0 100 18h9v3.5a5.5 5.5 0 01-5.5 5.5h-35A5.5 5.5 0 019 47.5v-32a5.5 5.5 0 015.5-5.5h33a1.5 1.5 0 000-3h-33zM46 29h11.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H46a6 6 0 010-12z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyWalletARegular)
