import Button from '@ui/Button'
import { Typography } from '@ui/Typography'

import { EMainRoutes } from '@lib/navigation'

import { NotFoundImage } from './NotFoundImage'
import styles from './styles.module.scss'

const NotFoundPage = () => (
  <div className={styles.container}>
    <NotFoundImage />
    <Typography className={styles.title} size={32}>
      Тут ничего нет
    </Typography>
    <Typography className={styles.subtitle} size={18}>
      Код ошибки: 404
    </Typography>
    <Button
      fullSize
      className={styles.mainButton}
      type="link"
      linkTo={EMainRoutes.Main}>
      Вернуться на главную
    </Button>
    <Button
      fullSize
      type="link"
      className={styles.supportButton}
      linkTo={EMainRoutes.SupportContact}>
      Сообщить о проблеме
    </Button>
  </div>
)

export default NotFoundPage
