import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCollapseTrianglesVClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.202 6.505a1.7 1.7 0 01-2.404 0l-3.31-3.31A.7.7 0 013.983 2h8.034a.7.7 0 01.495 1.195l-3.31 3.31zm-1.697-.707a.7.7 0 00.99 0L11.293 3H4.707l2.798 2.798zM9.202 9.495a1.7 1.7 0 00-2.404 0l-3.31 3.31A.7.7 0 003.983 14h8.034a.7.7 0 00.495-1.195l-3.31-3.31zm-1.697.707a.7.7 0 01.99 0L11.293 13H4.707l2.798-2.798z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.415 8.393a2 2 0 01-2.829 0L4.9 4.707C4.27 4.077 4.717 3 5.607 3h8.786c.891 0 1.337 1.077.707 1.707l-3.685 3.686zm-2.122-.707a1 1 0 001.415 0L14.393 4H5.607l3.686 3.686zM11.415 11.607a2 2 0 00-2.829 0L4.9 15.293c-.63.63-.183 1.707.707 1.707h8.786c.891 0 1.337-1.077.707-1.707l-3.685-3.686zm-2.122.707a1 1 0 011.415 0L14.393 16H5.607l3.686-3.686z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesVClosedLight)
