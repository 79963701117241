import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconAttachLinkOffLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.354.646a.5.5 0 10-.708.708l12.5 12.5a.5.5 0 00.708-.708l-1.697-1.696A4.5 4.5 0 0011 3H9.5a.5.5 0 000 1H11a3.5 3.5 0 011.411 6.704L9.707 8H11.5a.5.5 0 000-1H8.707l-3-3H6.5a.5.5 0 000-1H5c-.095 0-.19.003-.284.009L2.354.646zM2.407 3.822A4.5 4.5 0 005 12h1.5a.5.5 0 000-1H5a3.5 3.5 0 01-1.872-6.458l-.72-.72z"
          fill="currentColor"
        />
        <path d="M5.586 7H4.5a.5.5 0 000 1h2.086l-1-1z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M2.854 1.146a.5.5 0 10-.708.708l15 15a.5.5 0 00.708-.708l-1.783-1.783A5.5 5.5 0 0013.5 4h-1.251a.5.5 0 000 1.001h1.25a4.5 4.5 0 011.823 8.616L11.707 10H14a.5.5 0 000-1h-3.293l-4-4H7.75a.5.5 0 000-1H6.5c-.252 0-.5.017-.743.05L2.854 1.146zM1 9.5c0-1.86.924-3.505 2.338-4.5l.72.72A4.5 4.5 0 006.5 14h1.25a.5.5 0 010 1H6.5A5.5 5.5 0 011 9.5z"
        fill="currentColor"
      />
      <path d="M6 9h1.338l1 1H6a.5.5 0 010-1z" fill="currentColor" />
    </svg>
  )
}

export default observer(IconAttachLinkOffLight)
