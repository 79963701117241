import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMailArrowOutLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.5 3A2.5 2.5 0 000 5.5v5A2.5 2.5 0 002.5 13h3a.5.5 0 000-1h-3A1.5 1.5 0 011 10.5v-5A1.5 1.5 0 012.5 4h8A1.5 1.5 0 0112 5.5v1a.5.5 0 001 0v-1A2.5 2.5 0 0010.5 3h-8z"
          fill="currentColor"
        />
        <path
          d="M2.8 5.6a.5.5 0 00-.6.8L5 8.5a2.5 2.5 0 003 0l2.8-2.1a.5.5 0 00-.6-.8L7.4 7.7a1.5 1.5 0 01-1.8 0L2.8 5.6zM11.45 12A3.45 3.45 0 008 15.45v.05a.5.5 0 11-1 0v-.05A4.45 4.45 0 0111.45 11h3.11l-1.886-1.62a.5.5 0 01.652-.76l2.026 1.742a1.5 1.5 0 010 2.276l-2.026 1.741a.5.5 0 01-.652-.758L14.56 12h-3.11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h2.5a.5.5 0 000-1H5a2 2 0 01-2-2V7a2 2 0 012-2h10a2 2 0 012 2v1.5a.5.5 0 001 0V7a3 3 0 00-3-3H5z"
        fill="currentColor"
      />
      <path
        d="M5.312 6.81a.5.5 0 10-.624.78l3.375 2.701a3.1 3.1 0 003.874 0l3.375-2.7a.5.5 0 10-.624-.781l-3.376 2.7a2.1 2.1 0 01-2.624 0l-3.376-2.7zM14.45 15A3.45 3.45 0 0011 18.45v.05a.5.5 0 01-1 0v-.05A4.45 4.45 0 0114.45 14h4.134l-2.414-2.125a.5.5 0 01.66-.75l2.556 2.249a1.5 1.5 0 010 2.252l-2.556 2.25a.5.5 0 01-.66-.751L18.584 15H14.45z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMailArrowOutLight)
