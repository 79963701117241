import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { IconMoneyCurrencyRubleRegular } from '@ui/icons'
import { Input } from '@ui/Input'
import { Typography } from '@ui/Typography'

import CardForm from '@lib/components/CardForm'
import NotFoundPage from '@lib/components/NotFoundPage'
import PageWrapper from '@lib/components/Page/PageWrapper'
import TaggedContainer from '@lib/components/TaggedContainer'

import TransactionRejected from '@modules/sales/components/TransactionRejected'
import TransactionStage from '@modules/sales/components/TransactionStage'
import { LokalizedStatus, Statuses } from '@modules/sales/models/Transaction'
import { useTransactionStore } from '@modules/sales/store/TransactionStore'

import styles from './TransactionPage.module.scss'

const TransactionPage = () => {
  const { id } = useParams()
  const transactionStore = useTransactionStore()

  const tags = [
    {
      label: 'Номер сделки',
      value: id,
    },
    {
      label: 'Дата регистрации',
      value: new Date(
        transactionStore.transaction?.created_at,
      ).toLocaleDateString(),
    },
    {
      label: 'Статус',
      value: LokalizedStatus[transactionStore.transaction?.status],
    },
  ]

  useEffect(() => {
    transactionStore.getTransaction(id)

    return () => {
      transactionStore.resetTransactionFetching()
    }
  }, [])

  return (
    <PageWrapper
      title="Страница сделки"
      isLoading={transactionStore.isFetchingTransaction}>
      {transactionStore.transaction ? (
        <>
          <div className={styles.tags}>
            <TaggedContainer tags={tags} />

            {transactionStore.transaction.status === Statuses.Concluded && (
              <TransactionStage stage={transactionStore.transaction.stage} />
            )}
            {transactionStore.transaction.status === Statuses.Rejected && (
              <TransactionRejected />
            )}
          </div>

          <div className={styles.wrapper}>
            <CardForm inputDirection="column">
              <Typography size={18}>Заказчик</Typography>
              <Input
                disabled
                label="Компания"
                placeholder="Не заполнено"
                value={transactionStore.transaction.companyName}
                name="companyName"
              />
              <Input
                disabled
                label="ИНН"
                placeholder="Не заполнено"
                value={transactionStore.transaction.inn}
                name="inn"
              />
              <Input
                disabled
                label="Сайт Компании"
                placeholder="Не заполнено"
                value={transactionStore.transaction.companyUrl}
                name="companyUrl"
              />
              <Input
                disabled
                label="Юридический адрес"
                placeholder="Не заполнено"
                value={transactionStore.transaction.address}
                name="address"
              />
              <Input
                disabled
                label="Индекс"
                placeholder="Не заполнено"
                value={transactionStore.transaction.index}
                type="number"
                name="index"
              />
              <Input
                disabled
                label="Дистрибьютор"
                placeholder="Не заполнено"
                value={transactionStore.transaction.distributor}
                name="distributor"
              />

              <Typography size={18}>Конкурс</Typography>
              <Input
                disabled
                label="Название конкурса"
                placeholder="Не заполнено"
                value={transactionStore.transaction.name}
                name="name"
              />
              <Input
                disabled
                label="Ссылка на конкурс"
                placeholder="Не заполнено"
                value={transactionStore.transaction.url}
                name="url"
              />
              <Input
                disabled
                label="Сумма сделки"
                placeholder="0"
                type="number"
                value={transactionStore.transaction.amount}
                rightIcon={<IconMoneyCurrencyRubleRegular />}
                className={styles.sum}
                name="amount"
              />
              <Input
                disabled
                label="Дата конкурса"
                placeholder="Не заполнено"
                type="date"
                value={transactionStore.transaction.date}
                name="date"
              />
            </CardForm>

            <CardForm inputDirection="column">
              <Typography size={18}>Информация участника конкурса</Typography>
              <Input
                disabled
                label="Юр.лицо, участника"
                placeholder="Не заполнено"
                value={transactionStore.transaction.customer}
                name="customer"
              />
              <Input
                disabled
                label="ФИО ведущего менеджера"
                placeholder="Не заполнено"
                value={transactionStore.transaction.manager}
                name="manager"
              />

              <Typography size={18}>Дополнительная информация</Typography>
              <div className={styles.textarea}>
                <div className={styles.label}>
                  <Typography size={16}>Конкуренты</Typography>
                </div>
                <div className={styles.content}>
                  <Typography size={16}>
                    {transactionStore.transaction.сompetitors
                      ? transactionStore.transaction.сompetitors
                      : 'Поле не было заполнено'}
                  </Typography>
                </div>
              </div>

              <div className={styles.textarea}>
                <div className={styles.label}>
                  <Typography size={16}>Комментарий</Typography>
                </div>
                <div className={styles.content}>
                  <Typography size={16}>
                    {transactionStore.transaction.comments
                      ? transactionStore.transaction.comments
                      : 'Поле не было заполнено'}
                  </Typography>
                </div>
              </div>

              <Input
                disabled
                label="Выигрыш сделки"
                placeholder="0"
                type="string"
                value={`${transactionStore.transaction.percent}%`}
                className={styles.sum}
                name="percent"
              />
            </CardForm>
          </div>
        </>
      ) : (
        <NotFoundPage />
      )}
    </PageWrapper>
  )
}

export default observer(TransactionPage)
