import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EStatusProcess } from '@lib/form'
import { EAuthRoutes, EMainRoutes } from '@lib/navigation'

import { useAuthStore } from '@modules/auth/store/AuthStore'
import { useOnboardingFormStore } from '@modules/auth/store/OnboardingFormStore'
import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { useBannerStore } from '@modules/home/store/bannerStore'
import { useUserStore } from '@modules/user/store/UserStore'

export const Stage = {
  LOADING: 'loading',
  AUTH: 'auth',
  ONBOARDING: 'onboarding',
  MAIN: 'main',
} as const

type TStage = ValueOf<typeof Stage>

export const useInit = (): TStage => {
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const bannerStore = useBannerStore()
  const companyStore = useCompanyStore()
  const onboardingStore = useOnboardingFormStore()
  const navigate = useNavigate()
  const location = useLocation()

  const [stage, setStage] = useState<TStage>(Stage.LOADING)

  useEffect(() => {
    authStore.checkIsAuthenticated()
  }, [])

  useEffect(() => {
    if (authStore.isAuthenticated !== undefined) {
      if (authStore.isAuthenticated) {
        Promise.all([
          companyStore.fetchCompany(),
          userStore.fetchUser(),
          bannerStore.fetchBannerData(),
        ])
          .then(() => {
            setStage(
              onboardingStore.showOnboarding ? Stage.ONBOARDING : Stage.MAIN,
            )
            if (location.pathname === EAuthRoutes.Auth) {
              navigate(EMainRoutes.Main)
            }
          })
          .catch(() => {
            setStage(Stage.AUTH)
          })
      } else {
        setStage(Stage.AUTH)
      }
    }
  }, [authStore.isAuthenticated])

  useEffect(() => {
    if (onboardingStore.form.meta.fetchStatus === EStatusProcess.SUCCESS) {
      setStage(Stage.MAIN)
    }
  }, [onboardingStore.form.meta.fetchStatus])

  return stage
}
