import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMathFunctionOffRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.226 1.48a.75.75 0 00-1.06 1.062L13.82 14.196a.75.75 0 001.06-1.06l-2.359-2.36 1.322-1.322a.75.75 0 00-1.06-1.06L11.46 9.715 8.745 7h.225a.75.75 0 100-1.5H7.245l-.418-.419a2.394 2.394 0 012.247-1.57h.187a.75.75 0 000-1.5h-.187c-1.44 0-2.698.783-3.372 1.946L3.226 1.481zM5.181 10.096V7.557l1.5 1.5v1.039a3.893 3.893 0 01-3.893 3.893h-.055a.75.75 0 010-1.5h.055a2.393 2.393 0 002.393-2.393zM8.47 12.706l.93-.93 1.06 1.06-.93.93a.75.75 0 01-1.06-1.06zM2.779 5.5h.345l1.5 1.5H2.779a.75.75 0 010-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.78 1.72a.75.75 0 10-1.061 1.06l15.975 15.975a.75.75 0 001.06-1.06l-3.75-3.75 2.225-2.225a.75.75 0 00-1.06-1.061l-2.226 2.225-3.85-3.851h.337a.75.75 0 000-1.5H8.593L7.519 6.459v-.022a2.688 2.688 0 012.687-2.687h.89a.75.75 0 000-1.5h-.89a4.19 4.19 0 00-3.988 2.908L2.779 1.72zM6.019 9.08l1.5 1.5v2.96a4.187 4.187 0 01-4.188 4.188h-.556a.75.75 0 010-1.5h.556a2.687 2.687 0 002.688-2.687V9.08zM4.471 7.533l1.5 1.5H3a.75.75 0 010-1.5H4.47zM12.944 16.005l-1.061-1.06-1.318 1.317a.75.75 0 001.06 1.061l1.319-1.318z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.35 2.07a.75.75 0 10-1.061 1.062L21.018 21.86a.75.75 0 101.06-1.06l-4.282-4.282 2.58-2.58a.75.75 0 00-1.06-1.06l-2.58 2.579-4.707-4.707h.467a.75.75 0 100-1.5h-1.967L8.963 7.684a3.375 3.375 0 013.366-3.128h.947a.75.75 0 000-1.5h-.947a4.877 4.877 0 00-4.635 3.36L3.35 2.07zM4.093 9.25H5.9l1.5 1.5H4.093a.75.75 0 010-1.5zM7.454 16.069v-5.265l1.5 1.5v3.765a4.875 4.875 0 01-4.875 4.875h-.557a.75.75 0 010-1.5h.557a3.375 3.375 0 003.375-3.375zM12.746 19.447l1.675-1.676 1.061 1.06-1.675 1.676a.75.75 0 01-1.06-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.945 3.293a1 1 0 00-1.414 1.414l24 24a1 1 0 001.414-1.414l-5.734-5.734 3.298-3.298a1 1 0 00-1.414-1.414l-3.298 3.297L15.652 14h1.339a1 1 0 100-2h-3.338l-1.403-1.402V10.5a4.5 4.5 0 014.5-4.5h1a1 1 0 100-2h-1a6.503 6.503 0 00-6.28 4.817L4.945 3.293zM6.505 12h1.342l2 2H6.505a1 1 0 110-2zM10.25 20.5v-6.097l2 2V20.5a6.5 6.5 0 01-6.5 6.5h-.5a1 1 0 110-2h.5a4.5 4.5 0 004.5-4.5zM16.677 25.265l2.217-2.218 1.415 1.414-2.218 2.218a1 1 0 01-1.415-1.414z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.06 7.94a1.5 1.5 0 10-2.12 2.12l48 48a1.5 1.5 0 002.12-2.12L44.978 43.855l7.687-7.688a1.5 1.5 0 10-2.121-2.121l-7.688 7.687L29.121 28H33.5a1.5 1.5 0 000-3h-7.379L24 22.879V21.5a9.5 9.5 0 019.5-9.5h2a1.5 1.5 0 000-3h-2c-6.386 0-11.654 4.789-12.407 10.971L9.06 7.94zM13.5 25h3.672l3 3H13.5a1.5 1.5 0 010-3zM21 41.5V28.828l3 3V41.5C24 48.404 18.404 54 11.5 54h-1a1.5 1.5 0 010-3h1a9.5 9.5 0 009.5-9.5zM33.707 50.883l4.674-4.674 2.121 2.121-4.674 4.675a1.5 1.5 0 11-2.121-2.122z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathFunctionOffRegular)
