import { useCallback, useState } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Table } from '@ui/Table'
import TableItem from '@ui/Table/components/TableItem'
import TableRow from '@ui/Table/components/TableRow'
import { Typography } from '@ui/Typography'

import { useCompanyStore } from '@modules/company/store/CompanyStore'

import styles from './styles.module.scss'

const TableWaitingEmployees = ({ data }) => {
  const companyStore = useCompanyStore()
  const [loaderDelete, setLoaderDelete] = useState(false)
  const [loaderConfirm, setLoaderConfirm] = useState(false)
  const [userId, setUserId] = useState(0)

  const onConfirm = useCallback((id) => {
    setUserId(id)
    setLoaderConfirm(true)
    companyStore.confirmEmployee(id).then(() => setLoaderConfirm(false))
  }, [])

  const onDelete = useCallback((id) => {
    setUserId(id)
    setLoaderDelete(true)
    companyStore.deleteEmployee(id).then(() => setLoaderDelete(false))
  }, [])

  return (
    <Table className={styles.table}>
      <Typography size={18}>Ожидают подтверждение регистрации</Typography>
      {data.map((item) => (
        <>
          <TableRow>
            <TableItem colSpan={4} align="left">
              {item.fullName}
            </TableItem>
          </TableRow>
          <table className={styles.tableDescription}>
            <tbody>
              <tr>
                <td align="left" width={300}>
                  Должность
                </td>
                <td align="left" width={300}>
                  Почта
                </td>
                <td align="left" width={150}>
                  Телефон
                </td>
              </tr>
              <tr key={item.id} className={styles.description}>
                <td align="left">{item.position}</td>
                <td align="left">{item.email}</td>
                <td align="left">{item.phone}</td>
              </tr>
              <tr>
                <td colSpan={4} align="right">
                  <div className={styles.button}>
                    <Button
                      type="fill"
                      size="small"
                      withBorder
                      onClick={() => onDelete(item.id)}
                      {...(userId === item.id && {
                        loading: loaderDelete,
                      })}>
                      Отменить
                    </Button>
                    <Button
                      className={styles.margin}
                      size="small"
                      onClick={() => onConfirm(item.id)}
                      loading={loaderConfirm}
                      {...(userId === item.id && {
                        loading: loaderConfirm,
                      })}>
                      Подтвердить
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ))}
    </Table>
  )
}

export default observer(TableWaitingEmployees)
