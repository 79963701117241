import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSecurityLockClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.25 8.75c0 .409-.196.772-.5 1v1a.75.75 0 01-1.5 0v-1a1.25 1.25 0 112-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 3.75a3.75 3.75 0 117.5 0v1.295A2.75 2.75 0 0114 7.75v3.5A2.75 2.75 0 0111.25 14h-6.5A2.75 2.75 0 012 11.25v-3.5c0-1.348.97-2.47 2.25-2.705V3.75zm6 0V5h-4.5V3.75a2.25 2.25 0 014.5 0zM4.75 6.5h6.5c.69 0 1.25.56 1.25 1.25v3.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-3.5c0-.69.56-1.25 1.25-1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.375 11.75c0 .483-.249.907-.625 1.152V14a.75.75 0 01-1.5 0v-1.098a1.373 1.373 0 01.75-2.527c.76 0 1.375.616 1.375 1.375z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 5.75a4.75 4.75 0 019.5 0v1.345A3.001 3.001 0 0117 10v5a3 3 0 01-3 3H6a3 3 0 01-3-3v-5c0-1.398.956-2.572 2.25-2.905V5.75zm8 0V7h-6.5V5.75a3.25 3.25 0 116.5 0zM4.5 10A1.5 1.5 0 016 8.5h8a1.5 1.5 0 011.5 1.5v5a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 15v-5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.65 14.1a1.65 1.65 0 01-.9 1.47V17a.75.75 0 01-1.5 0v-1.43a1.65 1.65 0 112.4-1.47z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 7a5.75 5.75 0 1111.5 0v1.325c1.712.347 3 1.86 3 3.675v6A3.75 3.75 0 0117 21.75H7A3.75 3.75 0 013.25 18v-6a3.751 3.751 0 013-3.675V7zm10 0v1.25h-8.5V7a4.25 4.25 0 018.5 0zm-11.5 5A2.25 2.25 0 017 9.75h10A2.25 2.25 0 0119.25 12v6A2.25 2.25 0 0117 20.25H7A2.25 2.25 0 014.75 18v-6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 8a7 7 0 1114 0v1a1 1 0 11-2 0V8a5 5 0 00-10 0v1a1 1 0 11-2 0V8zM18.2 18.8a2.2 2.2 0 01-1.2 1.96V23a1 1 0 11-2 0v-2.24a2.2 2.2 0 113.2-1.96z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 16a5 5 0 015-5h14a5 5 0 015 5v8a5 5 0 01-5 5H9a5 5 0 01-5-5v-8zm5-3a3 3 0 00-3 3v8a3 3 0 003 3h14a3 3 0 003-3v-8a3 3 0 00-3-3H9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19 15c0-7.18 5.82-13 13-13s13 5.82 13 13v1.5a1.5 1.5 0 01-3 0V15c0-5.523-4.477-10-10-10S22 9.477 22 15v1.5a1.5 1.5 0 01-3 0V15zM35 37c0 1.11-.603 2.08-1.5 2.599V44.5a1.5 1.5 0 01-3 0v-4.901A2.999 2.999 0 0132 34a3 3 0 013 3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 30.5a9.5 9.5 0 019.5-9.5h27a9.5 9.5 0 019.5 9.5v18a9.5 9.5 0 01-9.5 9.5h-27A9.5 9.5 0 019 48.5v-18zm9.5-6.5a6.5 6.5 0 00-6.5 6.5v18a6.5 6.5 0 006.5 6.5h27a6.5 6.5 0 006.5-6.5v-18a6.5 6.5 0 00-6.5-6.5h-27z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityLockClosedRegular)
