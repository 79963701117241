import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconEyeOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM6.25 8a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25c-3.631 0-6.728 2.279-7.95 5.483a.75.75 0 000 .534C1.272 11.471 4.369 13.75 8 13.75c3.631 0 6.728-2.279 7.95-5.483a.75.75 0 000-.534C14.729 4.529 11.631 2.25 8 2.25zm0 10A7.011 7.011 0 011.56 8 7.011 7.011 0 018 3.75 7.011 7.011 0 0114.44 8 7.011 7.011 0 018 12.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 6.125a3.875 3.875 0 100 7.75 3.875 3.875 0 000-7.75zM7.625 10a2.375 2.375 0 114.75 0 2.375 2.375 0 01-4.75 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3C5.687 3 1.987 5.619.391 9.351a1.65 1.65 0 000 1.298C1.987 14.38 5.687 17 10 17s8.013-2.619 9.609-6.351a1.65 1.65 0 000-1.298C18.013 5.62 14.313 3 10 3zM1.77 9.941C3.14 6.74 6.31 4.5 10 4.5c3.69 0 6.86 2.24 8.23 5.441a.15.15 0 010 .118C16.86 13.261 13.69 15.5 10 15.5c-3.69 0-6.86-2.24-8.23-5.441a.15.15 0 010-.118z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM9 12a3 3 0 116 0 3 3 0 01-6 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.75c-4.998 0-9.303 2.966-11.26 7.233a2.437 2.437 0 000 2.034C2.696 17.284 7.001 20.25 12 20.25s9.303-2.966 11.26-7.233a2.437 2.437 0 000-2.034C21.304 6.716 16.999 3.75 12 3.75zm-9.898 7.86C3.826 7.853 7.611 5.25 12 5.25c4.389 0 8.174 2.604 9.898 6.36a.937.937 0 010 .78c-1.724 3.756-5.509 6.36-9.898 6.36-4.389 0-8.174-2.604-9.898-6.36a.937.937 0 010-.78z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 10a6 6 0 100 12 6 6 0 000-12zm-4 6a4 4 0 118 0 4 4 0 01-8 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 5C9.384 5 3.68 8.898 1.042 14.522c-.439.936-.439 2.02 0 2.956C3.68 23.102 9.384 27 16 27s12.32-3.898 14.958-9.522c.439-.936.439-2.02 0-2.956C28.32 8.898 22.616 5 16 5zM2.853 15.371C5.173 10.421 10.19 7 16 7c5.81 0 10.826 3.422 13.147 8.371.187.399.187.86 0 1.258C26.827 21.579 21.81 25 16 25c-5.81 0-10.826-3.422-13.147-8.371a1.482 1.482 0 010-1.258z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 20c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-9 12a9 9 0 1118 0 9 9 0 01-18 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 10.5c-12.889 0-24.023 7.511-29.29 18.395a7.128 7.128 0 000 6.21C7.977 45.99 19.11 53.5 32 53.5s24.023-7.511 29.29-18.395c.95-1.96.95-4.25 0-6.21C56.023 18.01 44.89 10.5 32 10.5zM5.41 30.202C10.197 20.312 20.307 13.5 32 13.5s21.803 6.811 26.59 16.702c.55 1.135.55 2.46 0 3.596C53.803 43.688 43.693 50.5 32 50.5S10.197 43.689 5.41 33.798a4.128 4.128 0 010-3.596z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeOpenRegular)
