import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiMenuShapeSquare4Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          stroke="red"
          strokeOpacity={0.2}
          strokeWidth={3}
          d="M1.5 1.5h13v13h-13z"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          stroke="red"
          strokeOpacity={0.2}
          strokeWidth={3}
          d="M1.5 1.5h17v17h-17z"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          stroke="red"
          strokeOpacity={0.2}
          strokeWidth={3}
          d="M1.5 1.5h21v21h-21z"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          stroke="red"
          strokeOpacity={0.2}
          strokeWidth={3}
          d="M1.5 1.5h29v29h-29z"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <rect
        x={9.5}
        y={9.5}
        width={19}
        height={19}
        rx={4}
        stroke="#222"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <rect
        x={9.5}
        y={35.5}
        width={19}
        height={19}
        rx={4}
        stroke="#222"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <rect
        x={35.5}
        y={9.5}
        width={19}
        height={19}
        rx={4}
        stroke="#222"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <rect
        x={35.5}
        y={35.5}
        width={19}
        height={19}
        rx={4}
        stroke="#222"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <path
        stroke="red"
        strokeOpacity={0.2}
        strokeWidth={3}
        d="M1.5 1.5h61v61h-61z"
      />
    </svg>
  )
}

export default observer(IconUiMenuShapeSquare4Regular)
