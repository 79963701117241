import styles from './styles.scss'

const Ram = () => (
  <div className={styles.container}>
    <p>
      Оперативная память, или RAM (Random Access Memory) – это тип компьютерной
      памяти, который используется для временного хранения данных и программ, с
      которыми процессор работает в настоящее время. Она является одним из
      ключевых компонентов конфигурации и используется для выполнения
      операционных систем и приложений.
    </p>
    <p>
      ОЗУ является типом "временной" памяти, что означает, что данные в ней
      хранятся только во время работы компьютера и удаляются при выключении. В
      отличие от постоянной памяти, такой как жесткие диски или SSD, ОЗУ
      обеспечивает очень быстрый доступ к данным, который не может быть
      достигнут на жестких дисках.
    </p>
    <p>
      Количество оперативной памяти в компьютере может быть увеличено путем
      установки дополнительных модулей памяти. Чем больше оперативной памяти
      установлено в компьютере, тем более быстро и эффективно компьютер может
      работать с большим количеством данных и приложений.
    </p>
  </div>
)

export default Ram
