import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDocArrowConvertLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05 3A2.5 2.5 0 015.5 1h3.015a1.5 1.5 0 011.06.44l2.986 2.984A1.5 1.5 0 0113 5.484V11.5a2.5 2.5 0 01-2.5 2.5h-5A2.5 2.5 0 013 11.5V9a3 3 0 010-6h.05zm.95.5A1.5 1.5 0 015.5 2H8v2.5A1.5 1.5 0 009.5 6H12v5.5a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 11.5V9h3.618L5.77 10.624a.5.5 0 00.66.752l2.203-1.937a1.25 1.25 0 000-1.878L6.43 5.624a.5.5 0 10-.66.751L7.618 8H4V3.5zM3 8a2 2 0 110-4v4zm8.722-3L9 2.278V4.5a.5.5 0 00.5.5h2.222z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6V5a3 3 0 013-3h3.622a1.5 1.5 0 011.06.44l3.879 3.877A1.5 1.5 0 0116 7.378V15a3 3 0 01-3 3H7a3 3 0 01-3-3v-3a3 3 0 010-6zm1-1a2 2 0 012-2h3v3a2 2 0 002 2h3v7a2 2 0 01-2 2H7a2 2 0 01-2-2v-3h4.648l-2.473 2.12a.5.5 0 10.65.76l2.836-2.43a1.25 1.25 0 000-1.9L7.825 8.12a.5.5 0 00-.65.76L9.648 11H5V5zm-1 6a2 2 0 010-4v4zm10.83-4H12a1 1 0 01-1-1V3.17L14.83 7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocArrowConvertLight)
