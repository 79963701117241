import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconEyeOpenRegular } from '@ui/icons'
import { Input } from '@ui/Input'
import { Popup } from '@ui/Popup'

import { Form } from '@lib/components/Form/Form'
import { EAuthRoutes } from '@lib/navigation'

import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'
import { useAuthFormStore } from '@modules/auth/store/AuthFormStore'

import { FormAnimation } from '../FormAnimation/FormAnimation'
import { FormWindowLayout } from '../FormWindowLayout/FormWindowLayout'

import styles from './AuthForm.module.scss'

/**
 * Форма авторизации.
 */
export const AuthForm: React.FC = observer(() => {
  const authStore = useAuthFormStore()
  const animatedStore = useAnimatedFormStore()
  const navigate = useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      authStore.form.handleFieldChange(event.target.name, event.target.value)
    },
    [],
  )

  const handleSubmitForm = useCallback(async () => {
    setLoading(true)
    animatedStore.setAnimatedState('none')
    await authStore.handleSubmit().catch(() => setLoading(false))
  }, [])

  const forgotPasswordHandler = useCallback(() => {
    animatedStore.setAnimatedState('forward')
    navigate(EAuthRoutes.Restore)
  }, [])

  return (
    <FormWindowLayout>
      <FormAnimation animationDirection={null}>
        <Form title="Авторизация">
          <Popup
            key="popup"
            isShown={authStore.formError}
            title={authStore.form.fields.email.errorText}
            state="attention"
            className={styles.mt20}
          />
          <Input
            key="email"
            name="email"
            placeholder="Введите логин"
            label="Логин"
            id="email"
            className={styles.mt20}
            value={authStore.form.fields.email.value}
            error={authStore.formError}
            onChange={handleFieldChange}
          />
          <Input
            key="password"
            name="password"
            label="Пароль"
            placeholder="Введите пароль"
            className={styles.mt20}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            value={authStore.form.fields.password.value}
            error={authStore.formError}
            onChange={handleFieldChange}
            rightIcon={
              <IconEyeOpenRegular
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            }
          />
          <Button
            disabled={authStore.submitFormDisabled}
            className={styles.submitBtn}
            type="primary"
            fullSize
            onClick={handleSubmitForm}
            loading={loading}>
            Войти
          </Button>
          <Button type="link" onClick={forgotPasswordHandler}>
            Забыли пароль?
          </Button>
        </Form>
      </FormAnimation>
    </FormWindowLayout>
  )
})
