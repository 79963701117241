import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetDownloadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 .675a.75.75 0 01.75.75V9.13l3.252-2.89a.75.75 0 01.996 1.12l-3.503 3.115a2.25 2.25 0 01-2.99 0L3.002 7.36a.75.75 0 11.996-1.122L7.25 9.13V1.426A.75.75 0 018 .675zM1.75 13a.75.75 0 01.75-.75h11a.75.75 0 010 1.5h-11a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 1.25a.75.75 0 01.75.75v10.234l4.377-3.89a.75.75 0 01.996 1.12l-4.379 3.893a2.625 2.625 0 01-3.488 0l-4.38-3.892a.75.75 0 11.997-1.121l4.377 3.89V2a.75.75 0 01.75-.75zM2.25 17a.75.75 0 01.75-.75h14a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 1.75a.75.75 0 01.75.75v13.589c.086-.05.17-.11.246-.178l5.256-4.672a.75.75 0 01.996 1.122l-5.255 4.67a3 3 0 01-3.986 0l-5.255-4.67a.75.75 0 01.996-1.122l5.256 4.672c.077.069.16.128.246.178V2.5a.75.75 0 01.75-.75zM2.75 21a.75.75 0 01.75-.75h17a.75.75 0 010 1.5h-17a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 2.25a1 1 0 011 1v18.468c.115-.066.226-.145.329-.237l7.007-6.228a1 1 0 011.328 1.494l-7.007 6.229a4 4 0 01-5.315 0l-7.006-6.229a1 1 0 111.328-1.494l7.007 6.228c.103.092.214.17.329.237V3.25a1 1 0 011-1zM3.5 28.25a1 1 0 011-1h23a1 1 0 110 2h-23a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 5a1.5 1.5 0 011.5 1.5v37.716a4.49 4.49 0 001.49-.88l14.014-12.457a1.5 1.5 0 111.992 2.242L36.983 45.578a7.5 7.5 0 01-9.966 0L13.004 33.12a1.5 1.5 0 111.992-2.242L29.01 43.336a4.49 4.49 0 001.49.88V6.5A1.5 1.5 0 0132 5zM7 56.5A1.5 1.5 0 018.5 55h47a1.5 1.5 0 010 3h-47A1.5 1.5 0 017 56.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetDownloadRegular)
