import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeADownRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.001a2 2 0 002-2V3.467c0-1.025-1.239-1.538-1.963-.814l-1.85 1.85-2.7-2.7a1.75 1.75 0 00-2.475 0l-3.21 3.21a1.75 1.75 0 000 2.475l2.7 2.7-1.85 1.85c-.724.724-.211 1.963.813 1.963H12zm.5-2a.5.5 0 01-.5.5H4.31l1.783-1.782a.75.75 0 000-1.061l-3.23-3.23a.25.25 0 010-.354l3.21-3.21a.25.25 0 01.353 0l3.231 3.23a.75.75 0 001.06 0L12.5 4.312V12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 17A2.25 2.25 0 0017 14.75V4.573c0-1.202-1.454-1.805-2.305-.954l-2.117 2.117-3.351-3.351a2 2 0 00-2.829 0L2.385 6.398a2 2 0 000 2.828l3.351 3.352-2.117 2.117c-.85.85-.248 2.305.954 2.305H14.75zm.75-2.25a.75.75 0 01-.75.75H4.935l2.392-2.392a.75.75 0 000-1.06l-3.88-3.882a.5.5 0 010-.707l4.012-4.014a.5.5 0 01.707 0l3.882 3.882a.75.75 0 001.06 0L15.5 4.936v9.814z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.124 20.751a2.625 2.625 0 002.625-2.625V5.313c0-1.337-1.615-2.006-2.56-1.061l-2.97 2.97-4.253-4.253a2.25 2.25 0 00-3.182 0L2.968 7.785a2.25 2.25 0 000 3.182l4.253 4.252L4.25 18.19c-.945.945-.276 2.56 1.06 2.56l12.814.001zm1.125-2.625c0 .622-.503 1.125-1.125 1.125H5.31l3.502-3.501a.75.75 0 000-1.06L4.029 9.905a.75.75 0 010-1.06l4.815-4.817a.75.75 0 011.061 0l4.784 4.784a.75.75 0 001.06 0l3.5-3.5v12.813z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 27a3.5 3.5 0 003.5-3.5V7.415C27 5.633 24.846 4.74 23.585 6l-3.46 3.46-5.503-5.503a3 3 0 00-4.243 0l-6.42 6.422a3 3 0 000 4.242l5.502 5.503L6 23.585C4.74 24.846 5.632 27 7.414 27H23.5zm1.5-3.5a1.5 1.5 0 01-1.5 1.5H7.414l4.168-4.168a1 1 0 000-1.414l-6.21-6.21a1 1 0 010-1.414l6.421-6.422a1 1 0 011.414 0l6.21 6.21a1 1 0 001.415 0L25 7.415V23.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5 54a6.5 6.5 0 006.5-6.5V14.829c0-3.118-3.77-4.68-5.975-2.475L40.5 19.879 28.89 8.269a5.5 5.5 0 00-7.779 0L8.271 21.112a5.5 5.5 0 000 7.777L19.879 40.5l-7.525 7.526C10.149 50.23 11.71 54 14.829 54H47.5zm3.5-6.5a3.5 3.5 0 01-3.5 3.5H14.83a.5.5 0 01-.354-.854l8.586-8.586a1.5 1.5 0 000-2.12L10.392 26.767a2.5 2.5 0 010-3.535l12.84-12.843a2.5 2.5 0 013.536 0L39.44 23.06a1.5 1.5 0 002.121 0l8.586-8.585a.5.5 0 01.853.354v32.67z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownRightRegular)
