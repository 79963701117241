export const registerPartner = {
  title: 'Станьте нашим партнером!',
  description:
    'Для присоединения к партнёрской программе и получения авторизации пройдите регистрацию. После принятия решения о присвоении партнёрского статуса, компании-претенденту придет письмо с решением о присвоении статуса от менеджера нашей компании.',
}
export const registerEmployee = {
  title: 'Вас пригласили пройти регистрацию на портале',
  description:
    'Для получения авторизации пройдите регистрацию. После подтверждения регистрации придет письмо с данными для авторизации.',
}
