import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMathInfinityLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.5 10.5c1.243 0 2.037-.664 2.779-1.628l.075.114c.155.234.325.49.515.754-.798.964-1.82 1.76-3.369 1.76A3.494 3.494 0 010 8c0-1.932 1.556-3.5 3.5-3.5 2.269 0 3.408 1.71 4.368 3.15l.048.073C8.946 9.268 9.807 10.5 11.5 10.5c1.39 0 2.5-1.118 2.5-2.5s-1.11-2.5-2.5-2.5c-1.242 0-2.037.664-2.779 1.628l-.075-.114c-.155-.234-.325-.49-.515-.754.798-.964 1.82-1.76 3.369-1.76C13.444 4.5 15 6.068 15 8s-1.556 3.5-3.5 3.5c-2.269 0-3.408-1.71-4.368-3.15l-.048-.073C6.054 6.732 5.193 5.5 3.5 5.5 2.11 5.5 1 6.618 1 8s1.11 2.5 2.5 2.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M2 10a3.136 3.136 0 013.143-3.143c2.121 0 3.197 1.553 4.441 3.42l.05.075c1.172 1.76 2.526 3.791 5.224 3.791A4.135 4.135 0 0019 10a4.135 4.135 0 00-4.143-4.143c-1.773 0-2.966.877-3.908 1.979.215.302.408.593.584.859l.003.004c.873-1.089 1.843-1.842 3.32-1.842A3.135 3.135 0 0118.002 10a3.136 3.136 0 01-3.143 3.143c-2.122 0-3.197-1.553-4.442-3.42l-.05-.075C9.194 7.888 7.84 5.857 5.144 5.857A4.136 4.136 0 001 10a4.136 4.136 0 004.143 4.143c1.773 0 2.965-.877 3.907-1.979a31.672 31.672 0 01-.584-.859l-.002-.004c-.874 1.089-1.844 1.842-3.321 1.842A3.136 3.136 0 012 10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathInfinityLight)
