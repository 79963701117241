import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDocArrowSyncLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 2A1.5 1.5 0 004 3.5v8A1.5 1.5 0 005.5 13H6v1h-.5A2.5 2.5 0 013 11.5v-8A2.5 2.5 0 015.5 1h3.015a1.5 1.5 0 011.06.44l2.986 2.984A1.5 1.5 0 0113 5.484V6H9.5A1.5 1.5 0 018 4.5V2H5.5zm3.5.278L11.722 5H9.5a.5.5 0 01-.5-.5V2.278z"
          fill="currentColor"
        />
        <path
          d="M11.732 13.998A2.5 2.5 0 019.623 13H10a.5.5 0 100-1H8.5a.5.5 0 00-.5.5v1.624a.5.5 0 001 0v-.306a3.499 3.499 0 005.988-1.36.5.5 0 10-.961-.274 2.5 2.5 0 01-2.294 1.814zM11.276 9.002a2.5 2.5 0 012.102.998H13a.5.5 0 100 1h1.5a.5.5 0 00.5-.5V8.877a.5.5 0 00-1 0v.305a3.5 3.5 0 00-5.982 1.338.5.5 0 10.96.28 2.5 2.5 0 012.298-1.798z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a2 2 0 00-2 2v10a2 2 0 002 2h2v1H7a3 3 0 01-3-3V5a3 3 0 013-3h3.622a1.5 1.5 0 011.06.44l3.879 3.877A1.5 1.5 0 0116 7.378V10h-1V8h-3a2 2 0 01-2-2V3H7zm5 4h2.83L11 3.17V6a1 1 0 001 1z"
        fill="currentColor"
      />
      <path
        d="M15.273 18.301a2.82 2.82 0 01-2.406-1.196h.832a.5.5 0 100-1h-2.047a.5.5 0 00-.5.5v2.029a.5.5 0 001 0v-.814a3.817 3.817 0 006.538-.847.5.5 0 00-.92-.39 2.819 2.819 0 01-2.497 1.718zM14.409 12.784a2.906 2.906 0 012.66 1.099h-.929a.5.5 0 100 1h2.232a.5.5 0 00.5-.5v-2.217a.5.5 0 00-1 0v1.121a3.906 3.906 0 00-6.816 1.186.5.5 0 00.952.306 2.906 2.906 0 012.4-1.995z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocArrowSyncLight)
