import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCommentRectXLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.8 5.907a.5.5 0 00-.708-.707L7.5 6.793 5.908 5.2a.5.5 0 10-.708.707L6.793 7.5 5.2 9.092a.5.5 0 10.708.708L7.5 8.207 9.092 9.8a.5.5 0 00.708-.708L8.207 7.5 9.8 5.907z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 2A2.5 2.5 0 001 4.5v6a2.5 2.5 0 002 2.45v1.99a.8.8 0 001.244.665L8.15 13H11.5a2.5 2.5 0 002.5-2.5v-6A2.5 2.5 0 0011.5 2h-8zM2 4.5A1.5 1.5 0 013.5 3h8A1.5 1.5 0 0113 4.5v6a1.5 1.5 0 01-1.5 1.5H8a.5.5 0 00-.277.084L4 14.566V12.5a.5.5 0 00-.5-.5A1.5 1.5 0 012 10.5v-6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.062 7.148a.5.5 0 00-.707-.707L9.5 8.296 7.645 6.441a.5.5 0 10-.707.707l1.855 1.855-1.855 1.855a.5.5 0 10.707.707L9.5 9.71l1.855 1.855a.5.5 0 00.707-.707l-1.855-1.855 1.855-1.855z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3a3 3 0 00-3 3v6a3 3 0 003 3v2.04a1 1 0 001.573.819L10.658 15H14a3 3 0 003-3V6a3 3 0 00-3-3H5zM3 6a2 2 0 012-2h9a2 2 0 012 2v6a2 2 0 01-2 2h-3.5a.5.5 0 00-.287.09L6 17.04V14.5a.5.5 0 00-.5-.5H5a2 2 0 01-2-2V6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRectXLight)
