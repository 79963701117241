import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowFigureASquareUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 11a.5.5 0 01-.5-.5V5.381l-1.616 1.94a.5.5 0 11-.768-.641l1.732-2.078a1.5 1.5 0 012.304 0l1.732 2.078a.5.5 0 01-.768.64L8 5.381V10.5a.5.5 0 01-.5.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 14a3 3 0 01-3-3V4a3 3 0 013-3h7a3 3 0 013 3v7a3 3 0 01-3 3H4zm-2-3a2 2 0 002 2h7a2 2 0 002-2V4a2 2 0 00-2-2H4a2 2 0 00-2 2v7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.5 13.5A.5.5 0 019 13V6.781l-2.116 2.54a.5.5 0 11-.768-.641l2.232-2.678a1.5 1.5 0 012.304 0l2.232 2.678a.5.5 0 01-.768.64L10 6.781V13a.5.5 0 01-.5.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 17A3.5 3.5 0 012 13.5v-8A3.5 3.5 0 015.5 2h8A3.5 3.5 0 0117 5.5v8a3.5 3.5 0 01-3.5 3.5h-8zM3 13.5A2.5 2.5 0 005.5 16h8a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0013.5 3h-8A2.5 2.5 0 003 5.5v8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareUpLight)
