import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextActionCheckRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.25 4A.75.75 0 013 3.25h10a.75.75 0 010 1.5H3A.75.75 0 012.25 4zM3 7.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zM3 11.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5H3zM15.28 11.53a.75.75 0 10-1.06-1.06l-2.97 2.97-1.47-1.47a.75.75 0 00-1.06 1.06l1.646 1.647a1.25 1.25 0 001.768 0l3.146-3.147z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 5a.75.75 0 01.75-.75h12.5a.75.75 0 010 1.5H3.75A.75.75 0 013 5zM3.75 8.25a.75.75 0 000 1.5h12.5a.75.75 0 000-1.5H3.75zM3.75 12.25a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zM19.593 13.03a.75.75 0 10-1.06-1.06l-4.025 4.024a.188.188 0 01-.266 0l-1.837-1.837a.75.75 0 10-1.06 1.06l1.837 1.838a1.687 1.687 0 002.386 0l4.025-4.025z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 4a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 4zM3.75 8.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3.75 13.25a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zM23.736 15.315a.75.75 0 10-1.06-1.06l-5.261 5.26a.375.375 0 01-.53 0L14.45 17.08a.75.75 0 10-1.06 1.061l2.434 2.434c.732.733 1.919.733 2.651 0l5.26-5.26z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 6a1 1 0 000 2h22a1 1 0 100-2H5zM4 14a1 1 0 011-1h22a1 1 0 110 2H5a1 1 0 01-1-1zM24.768 27.146l6.44-6.439a1 1 0 00-1.415-1.414l-6.44 6.44a.5.5 0 01-.707 0l-2.939-2.94a1 1 0 00-1.414 1.414l2.94 2.94a2.5 2.5 0 003.535 0zM5 20a1 1 0 100 2h9a1 1 0 100-2H5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8 12.5A1.5 1.5 0 019.5 11h45a1.5 1.5 0 010 3h-45A1.5 1.5 0 018 12.5zM9.5 25a1.5 1.5 0 000 3h45a1.5 1.5 0 000-3h-45zM9.5 39a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3h-19zM61.06 41.06a1.5 1.5 0 00-2.12-2.12L46.413 51.464a2 2 0 01-2.828 0l-5.525-5.526a1.5 1.5 0 00-2.122 2.122l5.526 5.525a5 5 0 007.07 0L61.061 41.06z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextActionCheckRegular)
