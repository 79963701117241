import React from 'react'

import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './Form.module.scss'

/**
 * Пропсы формы.
 *
 * @prop children Чилдрен.
 * @prop [title] Заголовок.
 * @prop [description] Описание.
 * @prop [className] Дополнительный CSS-класс.
 */
interface IProps {
  children: JSX.Element | JSX.Element[]
  title?: string
  description?: string
  className?: string
  titleClassName?: string
  descriptionClassName?: string
}

/**
 * Компонент формы.
 */
export const Form: React.FC<IProps> = ({
  children,
  title,
  description,
  className,
  titleClassName,
  descriptionClassName,
}) => (
  <form className={cx(styles.form, className)}>
    {title && (
      <Typography className={titleClassName} size={24}>
        {title}
      </Typography>
    )}
    {description && (
      <Typography className={descriptionClassName}>{description}</Typography>
    )}
    {children}
  </form>
)
