import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMarketShoppingBasketLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.15 1.8a.5.5 0 00-.8-.6l-2.25 3a.5.5 0 10.8.6l2.25-3zM6 9a.5.5 0 00-1 0v3a.5.5 0 101 0V9zM8 8.5a.5.5 0 01.5.5v3a.5.5 0 11-1 0V9a.5.5 0 01.5-.5zM11 9a.5.5 0 10-1 0v3a.5.5 0 101 0V9z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.39 6A1.5 1.5 0 00.93 7.846l1.194 5.035A2.75 2.75 0 004.8 14.997h6.4a2.75 2.75 0 002.675-2.116l1.194-5.035A1.5 1.5 0 0013.61 6H2.39zm-.487 1.615A.5.5 0 012.39 7h11.22a.5.5 0 01.487.615l-1.195 5.036a1.75 1.75 0 01-1.702 1.346H4.8a1.75 1.75 0 01-1.702-1.346L1.903 7.615z"
          fill="currentColor"
        />
        <path
          d="M8.95 1.1a.5.5 0 01.7.1l2.25 3a.5.5 0 01-.8.6l-2.25-3a.5.5 0 01.1-.7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M11.374 1.886a.5.5 0 01.698.113l2.708 3.75a.5.5 0 01-.81.585l-2.709-3.75a.5.5 0 01.113-.698zM8.739 2.584A.5.5 0 007.928 2L5.22 5.749a.5.5 0 00.81.585l2.709-3.75zM7 11a.5.5 0 00-1 0v4a.5.5 0 001 0v-4zM10 10.5a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM14 11a.5.5 0 10-1 0v4a.5.5 0 101 0v-4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.02 7.833a1.75 1.75 0 00-1.694 2.189l1.482 5.73A3 3 0 005.713 18h8.574a3 3 0 002.905-2.249l1.482-5.73a1.75 1.75 0 00-1.695-2.188H3.021zm-.725 1.938a.75.75 0 01.726-.938h13.958a.75.75 0 01.727.938l-1.482 5.73a2 2 0 01-1.937 1.5H5.713a2 2 0 01-1.937-1.5L2.295 9.77z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketShoppingBasketLight)
