import { endOfDay, isFuture } from 'date-fns'
import { makeAutoObservable } from 'mobx'
import Validator from 'validatorjs'

import { IInputChangeEvent } from '@ui/Input'

import { EServerError, IServerError } from '@lib/services'

import {
  EStatusProcess,
  FieldValueType,
  IBooleanFormField,
  IFormField,
  IFormMeta,
  INumberFormField,
  IStringFormField,
} from './FormModels'

Validator.useLang('ru')

// Валидтор для даты, которая не может быть меньше
Validator.register(
  'min_date',
  function (value: string) {
    return isFuture(endOfDay(new Date(value)))
  },
  `Дата не может быть ранее, чем ${new Date().toLocaleDateString()}`,
)

// Валидтор для номера телефона
Validator.register(
  'phone',
  function (value: string): boolean {
    const phoneRegExp = /^\+?[7][ \(]?\d{3}\)? ?\d{3} ?\d{2} ?\d{2}$/

    return phoneRegExp.test(value)
  },
  'Номер телфона должен быть формата +7 XXX XXX XXXX.',
)

type IConstructorFields = Record<string, Partial<IFormField>>

type IFields<T = IConstructorFields> = {
  [Param in keyof T]: T[Param] extends { valueType: 'number' }
    ? INumberFormField
    : T[Param] extends { valueType: 'boolean' }
    ? IBooleanFormField
    : IStringFormField
}

type KeyOfFieldsType<T> = keyof IFields<T>

const DEFAULT_FORM_VALUES: Record<FieldValueType, IFormField['value']> = {
  string: '',
  number: undefined,
  boolean: false,
}
const DEFAULT_FORM_FIELD: IFormField = {
  error: false,
  errorText: '',
  rule: 'required',
  messages: [],
  label: '',
  value: '',
}

export class FormConstructor<T extends IConstructorFields> {
  fields: IFields<T>
  meta: IFormMeta

  constructor(fields: T) {
    makeAutoObservable(this)

    this.fields = Object.keys(fields).reduce<IFields<T>>((acc, currKey) => {
      // @ts-expect-error I will do better typings later
      acc[currKey as keyof T] = {
        ...DEFAULT_FORM_FIELD,
        value:
          DEFAULT_FORM_VALUES[
            (fields[currKey as keyof T] as IFormField).valueType || 'string'
          ],
        ...fields[currKey as keyof T],
      }
      return acc
    }, {} as IFields<T>)

    this.meta = {
      fetchStatus: EStatusProcess.IDLE,
    }
  }

  private createValidator() {
    const rules: any = {}
    const values: any = {}
    const customMessages: any = {}
    const customAttributes: any = {}

    Object.keys(this.fields).forEach((key) => {
      const fieldKey = key as KeyOfFieldsType<T>
      const { rule } = this.fields[fieldKey]
      const { messages } = this.fields[fieldKey]
      const { label } = this.fields[fieldKey]

      if (rule !== '') {
        rules[fieldKey] = rule
        values[fieldKey] = this.fields[fieldKey].value
      }
      if (label !== '') {
        customAttributes[fieldKey] = `"${label}"`
      }
      if (messages?.length > 0) {
        messages.forEach((item) => {
          customMessages[`${item.rule}.${fieldKey as string}`] = item.message
        })
      }
    })

    const validator = new Validator(values, rules, customMessages)
    validator.setAttributeNames(customAttributes)

    return validator
  }

  validate() {
    const validation = this.createValidator()

    const isPassedValidation = validation.passes() as boolean

    Object.keys(validation.errors.all()).forEach((fieldKey) => {
      const { errorText } = this.fields[fieldKey as KeyOfFieldsType<T>]
      const firstError = validation.errors.first(fieldKey)

      if (firstError && !errorText) {
        this.fields[fieldKey as KeyOfFieldsType<T>].errorText = firstError
      }
      this.fields[fieldKey as KeyOfFieldsType<T>].error = true
    })

    return isPassedValidation
  }

  handleFieldChange = (field: string, value: any) => {
    this.fields[field as KeyOfFieldsType<T>].value = value
    this.fields[field as KeyOfFieldsType<T>].error = false
  }

  // TODO refactor handleFieldChange
  handleFieldChange2 = (evt: IInputChangeEvent<IFormField['value']>) => {
    this.handleFieldChange(evt.target.name, evt.target.value)
  }

  clear() {
    Object.keys(this.fields).forEach((fieldKey) => {
      const { label, rule, messages, ...defaultValues } = DEFAULT_FORM_FIELD
      this.fields[fieldKey as KeyOfFieldsType<T>] = {
        ...this.fields[fieldKey],
        ...defaultValues,
      }
    })
    this.meta.fetchStatus = EStatusProcess.IDLE
  }

  clearErrors() {
    Object.keys(this.fields).forEach((fieldKey) => {
      this.fields[fieldKey as KeyOfFieldsType<T>] = {
        ...this.fields[fieldKey as KeyOfFieldsType<T>],
        error: false,
        errorText: '',
      }
    })
  }

  async handleSubmit<Response = void>(
    fetchFunc: (fields: IFields<T>) => Response | Promise<Response>,
    handleErrorField?: (
      errorType: ValueOf<typeof EServerError>,
    ) => KeyOfFieldsType<T> | undefined,
  ): Promise<Response> {
    return new Promise(async (resolve, reject) => {
      try {
        const isPassed = this.validate()

        if (isPassed) {
          this.meta.fetchStatus = EStatusProcess.RUNNING

          const response = await fetchFunc(this.fields)

          this.meta.fetchStatus = EStatusProcess.SUCCESS
          resolve(response)
        } else {
          reject('Validation error')
        }
      } catch (err) {
        reject(`Catch error ${err}`)

        this.meta.fetchStatus = EStatusProcess.FAIL

        const errMessage = (err as IServerError).message

        if (handleErrorField) {
          const fieldKey = handleErrorField((err as IServerError).name)
          if (fieldKey) {
            this.fields[fieldKey].error = true
            this.fields[fieldKey].errorText = errMessage
            return
          }
        }

        const fieldKeys = Object.keys(this.fields) as KeyOfFieldsType<T>[]

        fieldKeys.forEach((fieldKey, idx) => {
          const isLastElement = fieldKeys.length - 1 === idx
          this.fields[fieldKey].errorText = isLastElement ? errMessage : ''
        })
      }
    })
  }
}
