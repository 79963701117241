import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowParallelVRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.25 4.432V13a.75.75 0 001.5 0V4.432l1.969 1.987a.75.75 0 001.065-1.056l-2.586-2.61a1.681 1.681 0 00-1.097-.496.758.758 0 00-.202 0c-.4.024-.793.19-1.098.496L.215 5.363a.75.75 0 001.066 1.056L3.25 4.432zM11.11 13.742a.748.748 0 01-.22 0 1.681 1.681 0 01-1.09-.497L7.22 10.637a.75.75 0 111.066-1.055l1.965 1.985V3a.75.75 0 011.5 0v8.567l1.965-1.985a.75.75 0 011.066 1.055L12.2 13.245a1.68 1.68 0 01-1.09.497z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.18 7.894a.75.75 0 01-1.06-1.061L4.393 3.56c.75-.75 1.967-.75 2.718 0l3.273 3.273a.75.75 0 01-1.06 1.06l-2.822-2.82V16.25a.75.75 0 11-1.5 0V5.072L2.18 7.894zM15 14.928V3.75a.75.75 0 00-1.5 0v11.178l-2.82-2.822a.75.75 0 10-1.062 1.061l3.273 3.273c.75.75 1.968.75 2.718 0l3.273-3.273a.75.75 0 00-1.06-1.06L15 14.926z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.61 9.366a.75.75 0 01-1.06-1.06l3.927-3.928a2.156 2.156 0 013.05 0l3.927 3.928a.75.75 0 11-1.06 1.06L7.752 5.725V19.5a.75.75 0 01-1.5 0V5.725L2.61 9.366zM17.751 18.28V4.503a.75.75 0 10-1.5 0v13.775l-3.641-3.641a.75.75 0 00-1.06 1.06l3.927 3.928a2.156 2.156 0 003.049 0l3.927-3.928a.75.75 0 10-1.06-1.06l-3.642 3.641z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.145 12.489a1 1 0 01-1.415-1.415l5.237-5.236a2.875 2.875 0 014.066 0l5.237 5.236a1 1 0 01-1.415 1.415L10 7.633V26a1 1 0 11-2 0V7.633L3.145 12.49zM24 24.367V6a1 1 0 10-2 0v18.367l-4.855-4.855a1 1 0 00-1.415 1.414l5.237 5.237a2.875 2.875 0 004.066 0l5.237-5.237a1 1 0 00-1.415-1.414L24 24.367z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.436 24.623a1.5 1.5 0 11-2.122-2.121l10.474-10.474a5.25 5.25 0 017.424 0l10.474 10.474a1.5 1.5 0 01-2.122 2.121L20.091 14.15c-.03-.03-.06-.059-.091-.086V52a1.5 1.5 0 11-3 0V14.064c-.03.027-.061.056-.091.086L6.436 24.623zM47 49.936V12a1.5 1.5 0 00-3 0v37.936a2.376 2.376 0 01-.091-.086L33.436 39.377a1.5 1.5 0 00-2.122 2.121l10.474 10.473a5.25 5.25 0 007.424 0l10.474-10.473a1.5 1.5 0 00-2.122-2.121L47.091 49.85c-.03.03-.06.058-.091.086z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowParallelVRegular)
