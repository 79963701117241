import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetCloudWireRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.375 1a4.376 4.376 0 00-4.27 3.422A3.876 3.876 0 004.25 12h3v2.25h-4.5a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5h-4.5V12h2.5a4.5 4.5 0 00-.2-9 4.371 4.371 0 00-3.675-2zM4.512 5.106a2.875 2.875 0 015.447-.994.75.75 0 00.77.414 3 3 0 11.465 5.974H4.25a2.375 2.375 0 01-.37-4.722.75.75 0 00.632-.672z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 15h-4a4.875 4.875 0 01-1.43-9.497A5.376 5.376 0 0113.948 4H14a5.5 5.5 0 110 11h-3.25v2.5h4.5a.75.75 0 010 1.5H4.75a.75.75 0 010-1.5h4.5V15zM5.257 6.136a3.875 3.875 0 017.507-1.098.75.75 0 00.787.487A4 4 0 1114 13.5H5.302a3.375 3.375 0 01-.638-6.675.75.75 0 00.592-.689z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 17.75H6.3A5.7 5.7 0 014.719 6.618a6.301 6.301 0 0111.925-1.766l.156-.002a6.45 6.45 0 010 12.9h-4.05v3.5h5.5a.75.75 0 010 1.5H5.75a.75.75 0 010-1.5h5.5v-3.5zM6.159 7.255a4.8 4.8 0 019.298-1.36.75.75 0 00.787.486 4.95 4.95 0 11.556 9.87l-4.8-.001H6.358a4.2 4.2 0 01-.79-8.307.75.75 0 00.59-.688z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24h6.5c.022 0 .043 0 .064-.002a8.625 8.625 0 00.432-17.226A8.874 8.874 0 0015.125 2c-4.283 0-7.857 3.034-8.691 7.07A7.625 7.625 0 008.5 24H14v4H7a1 1 0 100 2h17a1 1 0 100-2h-8v-4zM8.304 10.005a6.876 6.876 0 0113.134-1.858 1 1 0 00.919.603h.018a6.626 6.626 0 01.08 13.25H8.548l-.03-.001A5.625 5.625 0 017.51 10.86a1 1 0 00.795-.855z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 47h12.5C53.613 47 61 39.613 61 30.5S53.613 14 44.5 14c-.202 0-.403.004-.604.01C41.303 8.68 35.833 5 29.5 5c-7.988 0-14.608 5.853-15.806 13.505C7.532 20.177 3 25.808 3 32.5 3 40.508 9.492 47 17.5 47H29v9H13.5a1.5 1.5 0 000 3h35a1.5 1.5 0 000-3H32v-9zM16.55 19.86C17.125 13.214 22.704 8 29.5 8c5.459 0 10.136 3.365 12.062 8.14.25.62.879 1 1.544.931.457-.047.923-.071 1.394-.071C51.956 17 58 23.044 58 30.5S51.956 44 44.5 44h-27C11.149 44 6 38.851 6 32.5c0-5.61 4.019-10.285 9.335-11.297a1.5 1.5 0 001.214-1.344z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudWireRegular)
