import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeAUpRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 1.999c.966 0 1.75.784 1.75 1.75v8.802c0 .98-1.185 1.47-1.878.777l-2.251-2.25-2.753 2.752a1.5 1.5 0 01-2.121 0L2.17 11.002a1.5 1.5 0 010-2.12l2.752-2.754-2.251-2.252c-.693-.693-.202-1.878.778-1.878l8.8.001zm.75 1.75a.75.75 0 00-.75-.75l-8.801-.001a.093.093 0 00-.057.016.106.106 0 00-.036.046.106.106 0 00-.007.057c.002.014.008.031.029.052l2.605 2.605a.5.5 0 010 .708L2.877 9.588a.5.5 0 000 .707l2.827 2.828a.5.5 0 00.707 0l3.106-3.106a.5.5 0 01.708 0l2.604 2.604c.064.065.17.021.17-.07V3.749z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.031c1.087 0 1.969.882 1.969 1.969v10.491c0 .974-1.179 1.462-1.868.774l-2.46-2.461-3.613 3.612a1.719 1.719 0 01-2.431 0l-4.013-4.013a1.719 1.719 0 010-2.43L6.196 7.36l-2.46-2.462c-.69-.689-.202-1.867.773-1.867h10.49zM16.03 5c0-.57-.462-1.031-1.031-1.031H4.509a.156.156 0 00-.11.266L7.19 7.028a.469.469 0 010 .663l-3.944 3.944a.781.781 0 000 1.105l4.013 4.013c.305.305.8.305 1.105 0l3.944-3.944a.469.469 0 01.663 0l2.792 2.793a.156.156 0 00.267-.11V5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeAUpRightLight)
