import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCalculatorLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.5 9.078a.578.578 0 100-1.156.578.578 0 000 1.156zM6.078 10.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM7.5 9.078a.578.578 0 100-1.156.578.578 0 000 1.156zM8.078 10.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM9.5 9.078a.578.578 0 100-1.156.578.578 0 000 1.156zM10.078 10.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM5 5a.5.5 0 100 1h5a.5.5 0 100-1H5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1A2.5 2.5 0 002 3.5v8A2.5 2.5 0 004.5 14h6a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0010.5 1h-6zM3 3.5A1.5 1.5 0 014.5 2h6A1.5 1.5 0 0112 3.5v8a1.5 1.5 0 01-1.5 1.5h-6A1.5 1.5 0 013 11.5v-8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7.5 5a1 1 0 000 2h5a1 1 0 100-2h-5zM8.297 10.15a.85.85 0 11-1.698 0 .85.85 0 011.698 0zM7.445 14a.852.852 0 100-1.703.852.852 0 000 1.703zM10.852 10.148a.852.852 0 11-1.704 0 .852.852 0 011.704 0zM10 14a.852.852 0 100-1.703A.852.852 0 0010 14zM13.407 10.148a.852.852 0 11-1.704 0 .852.852 0 011.704 0zM12.555 14a.852.852 0 100-1.703.852.852 0 000 1.703z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a3 3 0 00-3 3v10a3 3 0 003 3h6a3 3 0 003-3V5a3 3 0 00-3-3H7zM5 5a2 2 0 012-2h6a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCalculatorLight)
