import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNotificationBellOffLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.354.146a.5.5 0 10-.708.708L3.913 3.12A4.977 4.977 0 003 6v2.862l-1.429 2.38A.5.5 0 002 12h4v.5a2 2 0 104 0V12h2.793l1.853 1.854a.5.5 0 00.708-.708l-13-13zM11.793 11l-7.16-7.16A3.981 3.981 0 004 6v3a.5.5 0 01-.071.257L2.883 11h8.91zM7 12v.5a1 1 0 102 0V12H7z"
          fill="currentColor"
        />
        <path
          d="M12 6v1.869l1.01 1.01-.01-.017V6a5 5 0 00-7.306-4.438l.75.752A4 4 0 0112 6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.354 1.646a.5.5 0 10-.708.708l2.979 2.978C4.225 6.135 4 7.042 4 8v3.682a.418.418 0 01-.09.259l-1.663 2.118C1.628 14.846 2.189 16 3.19 16h4.435v.375a2.375 2.375 0 104.75 0V16h2.918l2.353 2.354a.5.5 0 00.708-.708l-16-16zM14.293 15L5.379 6.086A4.985 4.985 0 005 8v3.682c0 .318-.107.627-.303.877l-1.664 2.117A.2.2 0 003.19 15h11.103zm-2.918 1v.375a1.375 1.375 0 11-2.75 0V16h2.75z"
        fill="currentColor"
      />
      <path
        d="M15.303 12.559l.475.605 2.127 2.126c.171-.38.149-.85-.152-1.231L16.09 11.94a.418.418 0 01-.089-.259V8a6 6 0 00-9.924-4.539l.71.71A5 5 0 0115 8v3.681c-.001.318.106.627.302.877z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNotificationBellOffLight)
