import { MoonLoader } from 'react-spinners'

import { COLORS } from '@ui/Theme/color'

interface ILoaderProps {
  size?: number
  className?: string
  color?: string
}

const Loader = ({
  size,
  className,
  color = COLORS.GRAY.GRAY_2,
}: ILoaderProps) => (
  <MoonLoader color={color} size={size} className={className} />
)

export default Loader
