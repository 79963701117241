import { useCallback, useState } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconDocsLight } from '@ui/icons'
import Toast from '@ui/Toast'

import PageWrapper from '@lib/components/Page/PageWrapper'

import TableEmployees from '@modules/company/components/TableEmployees/TableEmployees'
import TableWaitingEmployees from '@modules/company/components/TableWaitingEmployees/TableWaitingEmployees'
import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { useUserStore } from '@modules/user/store/UserStore'

import styles from './styles.module.scss'

const CompanyEmployeesPage = observer(() => {
  const companyStore = useCompanyStore()
  const userStore = useUserStore()

  const [watchToast, setWatchToast] = useState(false)

  const copy = useCallback(() => {
    companyStore.copyToClipboard(
      `${window.location.origin}/registration_of_a_new_employee/company/${companyStore.company.id}`,
    )
    setWatchToast(true)
  }, [])

  return (
    <div>
      {companyStore.waitingEmployees.length !== 0 && userStore.isPartner && (
        <PageWrapper>
          <TableWaitingEmployees data={companyStore.waitingEmployees} />
        </PageWrapper>
      )}
      <PageWrapper
        title="Список сотрудников"
        className={styles.containerEmployees}>
        {userStore.isPartner && (
          <Button
            type="fill"
            size="small"
            IconLeft={IconDocsLight}
            className={styles.button}
            onClick={copy}
            withBorder>
            Ссылка для регистрации сотрудника
          </Button>
        )}
        <Toast
          title="Ссылка скопирована"
          autoDeleteTime={2000}
          onVisible={watchToast}
          onCloseToast={(value) => setWatchToast(value)}
        />
        <div className={styles.table}>
          <TableEmployees data={companyStore.successEmployees} />
        </div>
      </PageWrapper>
    </div>
  )
})

export default CompanyEmployeesPage
