import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiWindowNewUpRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2a2 2 0 00-2 2v1H3a2 2 0 00-2 2v5a2 2 0 002 2h7a2 2 0 002-2v-1h1a2 2 0 002-2V4a2 2 0 00-2-2H6zm5 9v1a1 1 0 01-1 1H3a1 1 0 01-1-1V7a1 1 0 011-1h1v3a2 2 0 002 2h5zM5 9a1 1 0 001 1h7a1 1 0 001-1V4a1 1 0 00-1-1H6a1 1 0 00-1 1v5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3A2.5 2.5 0 005 5.5V7H3.5A2.5 2.5 0 001 9.5v5A2.5 2.5 0 003.5 17h8a2.5 2.5 0 002.5-2.5V13h1.5a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0015.5 3h-8zM13 13v1.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 14.5v-5A1.5 1.5 0 013.5 8H5v2.5A2.5 2.5 0 007.5 13H13zm-7-2.5A1.5 1.5 0 007.5 12h8a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0015.5 4h-8A1.5 1.5 0 006 5.5v5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiWindowNewUpRightLight)
