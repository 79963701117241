import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeTriangleARightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.922a1 1 0 011.545-.839l6.194 4.03a1 1 0 01.008 1.672L5.553 12.9A1 1 0 014 12.067V3.922zm7.194 4.03L5 3.922v8.145l6.194-4.115z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.121a1.1 1.1 0 011.71-.915l7.318 4.879a1.1 1.1 0 010 1.83l-7.318 4.88A1.1 1.1 0 016 14.878V5.121zm1.155-.083A.1.1 0 007 5.121v9.758a.1.1 0 00.155.083l7.319-4.878a.1.1 0 000-.167L7.155 5.038z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleARightLight)
