import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1LockRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M3.5 13.253c0-.68.179-1.15.43-1.488.256-.347.626-.61 1.087-.804.944-.4 2.123-.458 2.983-.458a.75.75 0 000-1.5c-.875 0-2.322.049-3.568.576-.635.27-1.252.676-1.709 1.294C2.261 11.5 2 12.29 2 13.253a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10.668v-.318a2.5 2.5 0 015 0v.318c.591.281 1 .884 1 1.582v2A1.75 1.75 0 0114.25 16h-3.5A1.75 1.75 0 019 14.25v-2c0-.698.409-1.3 1-1.582zm3.7-.168h-2.4v-.15a1.2 1.2 0 012.4 0v.15zM10.75 12a.25.25 0 00-.25.25v2c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25h-3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.956a4.55 4.55 0 100 9.1 4.55 4.55 0 000-9.1zm-3.05 4.55a3.05 3.05 0 116.1 0 3.05 3.05 0 01-6.1 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 17.246c0-.838.242-1.432.6-1.873.369-.454.901-.795 1.552-1.046 1.32-.509 2.947-.577 4.098-.577a.75.75 0 000-1.5c-1.163 0-3.036.06-4.637.677-.81.312-1.594.783-2.177 1.5-.592.728-.936 1.663-.936 2.819a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 14.168V13.5a2.5 2.5 0 015 0v.668c.591.281 1 .884 1 1.582v2.5A1.75 1.75 0 0117.25 20h-3.5A1.75 1.75 0 0112 18.25v-2.5c0-.698.409-1.3 1-1.582zM16.5 14h-2v-.5a1 1 0 112 0v.5zm-2.75 1.5a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM16 16a3 3 0 116 0v1.169c.591.28 1 .883 1 1.581v2.5A1.75 1.75 0 0121.25 23h-4.5A1.75 1.75 0 0115 21.25v-2.5c0-.698.409-1.3 1-1.581V16zm4.5 0v1h-3v-1a1.5 1.5 0 013 0zm-4 2.75a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-2.5z"
          fill="currentColor"
        />
        <path
          d="M3.75 21c0-1.267.336-2.186.841-2.87.511-.69 1.234-1.193 2.086-1.553 1.727-.732 3.843-.827 5.323-.827a.75.75 0 000-1.5c-1.495 0-3.879.086-5.908.945-1.026.435-1.995 1.08-2.707 2.043-.717.97-1.135 2.21-1.135 3.762a.75.75 0 001.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
        <path
          d="M5 28.5c0-1.557.439-2.684 1.102-3.525.675-.856 1.637-1.484 2.78-1.938C11.194 22.119 14.025 22 16 22a1 1 0 100-2c-1.991 0-5.16.108-7.856 1.178-1.362.54-2.657 1.347-3.612 2.558C3.566 24.961 3 26.534 3 28.5a1 1 0 102 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 23.05V22a4 4 0 018 0v1.05a2.5 2.5 0 012 2.45v4a2.5 2.5 0 01-2.5 2.5h-7a2.5 2.5 0 01-2.5-2.5v-4a2.5 2.5 0 012-2.45zm6-.05h-4v-1a2 2 0 114 0v1zm-5.5 2a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15zM32 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S38.627 9 32 9z"
        fill="currentColor"
      />
      <path
        d="M12.267 49.128C10.893 50.907 10 53.276 10 56.5a1.5 1.5 0 01-3 0c0-3.822 1.074-6.852 2.893-9.206 1.803-2.335 4.261-3.907 6.88-4.969C21.97 40.218 28.104 40 32 40a1.5 1.5 0 010 3c-3.87 0-9.487.235-14.099 2.105-2.284.926-4.245 2.224-5.634 4.023zM52 51.5c0 .818-.393 1.544-1 2v2a1.5 1.5 0 01-3 0v-2a2.5 2.5 0 114-2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 42.5v1.6a5.002 5.002 0 00-4 4.9v8a5 5 0 005 5h13a5 5 0 005-5v-8a5.002 5.002 0 00-4-4.9v-1.6a7.5 7.5 0 00-15 0zM56 47H43a2 2 0 00-2 2v8a2 2 0 002 2h13a2 2 0 002-2v-8a2 2 0 00-2-2zm-2-4.5V44h-9v-1.5a4.5 4.5 0 119 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1LockRegular)
