import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeTriangleARightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4.158a1.25 1.25 0 011.919-1.056l6.065 3.842a1.25 1.25 0 010 2.112L5.92 12.897A1.25 1.25 0 014 11.841V4.158zm1.5.455v6.774L10.848 8 5.5 4.613z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 5.38a1.35 1.35 0 012.106-1.118l6.83 4.62a1.35 1.35 0 010 2.236l-6.83 4.62A1.35 1.35 0 016 14.62V5.38zm1.5.283v8.675L13.912 10 7.5 5.663z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 5.436c0-1.386 1.535-2.222 2.7-1.47l10.157 6.564a1.75 1.75 0 010 2.94L8.95 20.033c-1.165.752-2.7-.084-2.7-1.47V5.436zm1.886-.21a.25.25 0 00-.386.21v13.127c0 .198.22.317.386.21l10.157-6.563a.25.25 0 000-.42L8.136 5.226z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 6.7c0-2.173 2.404-3.488 4.234-2.314l14.507 9.3c1.688 1.081 1.688 3.547 0 4.63l-14.507 9.299C9.404 28.788 7 27.474 7 25.3V6.7zm3.155-.63A.75.75 0 009 6.7v18.6a.75.75 0 001.155.631l14.507-9.3a.75.75 0 000-1.262l-14.507-9.3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12.326c0-4.351 4.814-6.98 8.474-4.626l30.603 19.673c3.368 2.165 3.368 7.088 0 9.253L22.474 56.3C18.814 58.653 14 56.025 14 51.672V12.326zm6.852-2.103C19.188 9.153 17 10.348 17 12.326v39.347c0 1.978 2.188 3.173 3.852 2.103l30.603-19.673c1.53-.985 1.53-3.222 0-4.206L20.852 10.223z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleARightRegular)
