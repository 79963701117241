import { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'

import { History } from 'history'

export interface INavBlockerControl {
  confirm: () => void
  cancel: () => void
}

interface INavBlocker {
  onBlock: (control: INavBlockerControl) => void
  enabled?: boolean
}

export const useNavBlocker = ({ onBlock, enabled }: INavBlocker) => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator as History

  const onBlockRef = useRef(onBlock)
  useLayoutEffect(() => {
    onBlockRef.current = onBlock
  })

  useEffect(() => {
    if (!enabled) {
      return
    }

    let isActive = false

    const unblock = block(({ retry }) => {
      if (isActive) {
        unblock()

        return retry()
      }

      onBlockRef.current({
        confirm: retry,
        cancel: () => {
          isActive = false
        },
      })

      isActive = true
    })

    return unblock
  }, [block, enabled])
}
