import { IPaginationResponse } from '@lib/types'

import { IMarketingMaterial } from '../models/Marketing'

export class MarketingMaterialAdapter {
  static transform(data: IPaginationResponse<any>): IMarketingMaterial[] {
    return data.data.map(MarketingMaterialAdapter.transformItem)
  }

  private static transformItem(data: any): IMarketingMaterial {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      cover: data.cover?.url,
      file: data.file?.url,
    }
  }
}
