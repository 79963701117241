import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMarketReceiptRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.128 4.95a.55.55 0 100 1.1h3.744a.55.55 0 100-1.1H6.128zM5.578 7.5a.55.55 0 01.55-.55h3.744a.55.55 0 110 1.1H6.128a.55.55 0 01-.55-.55zM7.532 8.95a.55.55 0 100 1.1h2.34a.55.55 0 100-1.1h-2.34z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.75 13.985a1.15 1.15 0 01-1.72.998l-1.743-.995-1.713.987a1.15 1.15 0 01-1.148 0l-1.713-.987-1.742.995a1.15 1.15 0 01-1.721-.998V2.015a1.15 1.15 0 011.72-.999l1.743.996 1.713-.988a1.15 1.15 0 011.148 0l1.713.988 1.742-.996a1.15 1.15 0 011.721.999v11.97zm-2.893-1.4l1.393.797V2.618l-1.393.796a1.15 1.15 0 01-1.145-.002L8 2.425l-1.712.987a1.15 1.15 0 01-1.145.002L3.75 2.618v10.764l1.393-.796a1.15 1.15 0 011.145.002L8 13.575l1.712-.987a1.15 1.15 0 011.145-.002z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.688 6.25a.75.75 0 000 1.5h4.625a.75.75 0 000-1.5H7.686zM6.938 10a.75.75 0 01.75-.75h4.625a.75.75 0 010 1.5H7.686a.75.75 0 01-.75-.75zM9.74 12.25a.75.75 0 000 1.5h2.572a.75.75 0 000-1.5H9.74z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.745 17.199l-2.107 1.251a1.25 1.25 0 01-1.276 0l-2.107-1.251-2.12 1.249a1.25 1.25 0 01-1.885-1.077V2.62a1.25 1.25 0 011.884-1.078l2.121 1.25L9.362 1.54a1.25 1.25 0 011.276 0l2.107 1.251 2.12-1.249A1.25 1.25 0 0116.75 2.62v14.75a1.25 1.25 0 01-1.884 1.078l-2.121-1.249zm-4.85-1.365L10 17.084l2.106-1.25a1.25 1.25 0 011.272-.003l1.872 1.103V3.058L13.378 4.16a1.25 1.25 0 01-1.272-.003L10 2.907l-2.106 1.25a1.25 1.25 0 01-1.272.003L4.75 3.058v13.876l1.872-1.103a1.25 1.25 0 011.272.003z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 8.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H9zM8.25 12a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM12 14.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.202 20.556l-2.493 1.538a1.35 1.35 0 01-1.418 0l-2.493-1.538-2.494 1.525A1.35 1.35 0 014.25 20.93V3.07A1.35 1.35 0 016.304 1.92l2.494 1.525 2.493-1.538a1.35 1.35 0 011.418 0l2.493 1.538 2.493-1.525A1.35 1.35 0 0119.75 3.07v17.86a1.35 1.35 0 01-2.055 1.151l-2.493-1.525zm.704-1.328l2.344 1.434V3.338l-2.344 1.434a1.35 1.35 0 01-1.414-.003L12 3.231 9.508 4.77a1.35 1.35 0 01-1.414.003L5.75 3.338v17.324l2.344-1.434a1.35 1.35 0 011.414.003L12 20.769l2.492-1.538a1.35 1.35 0 011.414-.003z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 11a1 1 0 100 2h10a1 1 0 000-2H11zM10 16a1 1 0 011-1h10a1 1 0 110 2H11a1 1 0 01-1-1zM16.5 19a1 1 0 100 2H21a1 1 0 000-2h-4.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.021 28.945a2.2 2.2 0 01-2.044-.004l-3.372-1.777a.2.2 0 00-.19.001L8.26 28.9C6.793 29.705 5 28.644 5 26.972V5.021C5 3.352 6.787 2.29 8.253 3.09l3.163 1.725a.2.2 0 00.189.002l3.377-1.763a2.2 2.2 0 012.036 0l3.377 1.763a.2.2 0 00.189-.002l3.163-1.725C25.212 2.29 27 3.352 27 5.022v21.957c0 1.67-1.788 2.73-3.253 1.931l-3.163-1.725a.2.2 0 00-.189-.002l-3.374 1.762zm-1.112-1.773a.2.2 0 00.186 0l3.374-1.762a2.2 2.2 0 012.072.019l3.163 1.725A.2.2 0 0025 26.98V5.022a.2.2 0 00-.296-.176L21.541 6.57a2.2 2.2 0 01-2.072.019l-3.376-1.763a.2.2 0 00-.186 0L12.531 6.59a2.2 2.2 0 01-2.072-.019L7.296 4.846A.2.2 0 007 5.022v21.95a.2.2 0 00.296.175l3.156-1.734a2.2 2.2 0 012.086-.018l3.371 1.777z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21.5 24a1.5 1.5 0 000 3h21a1.5 1.5 0 100-3h-21zM20 31.5a1.5 1.5 0 011.5-1.5h21a1.5 1.5 0 110 3h-21a1.5 1.5 0 01-1.5-1.5zM33.5 36a1.5 1.5 0 000 3h9a1.5 1.5 0 100-3h-9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.184 7.312C13.852 6.03 11 7.719 11 10.38v43.24c0 2.661 2.852 4.349 5.184 3.068l6.694-3.675a.5.5 0 01.468-.006l7.06 3.612a3.5 3.5 0 003.188 0l7.06-3.612a.5.5 0 01.468.007l6.694 3.674C50.148 57.97 53 56.281 53 53.62V10.38c0-2.661-2.852-4.349-5.184-3.068l-6.694 3.674a.5.5 0 01-.468.007l-7.06-3.612a3.5 3.5 0 00-3.188 0l-7.06 3.612a.5.5 0 01-.468-.007l-6.694-3.674zM14 10.38a.5.5 0 01.74-.439l6.694 3.675a3.5 3.5 0 003.28.048l7.058-3.612a.5.5 0 01.456 0l7.059 3.612a3.5 3.5 0 003.279-.048l6.693-3.675a.5.5 0 01.741.439v43.24a.5.5 0 01-.74.439l-6.694-3.675a3.5 3.5 0 00-3.28-.048l-7.058 3.612a.5.5 0 01-.456 0l-7.059-3.612a3.5 3.5 0 00-3.279.048l-6.693 3.675A.5.5 0 0114 53.62V10.38z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketReceiptRegular)
