import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocArrowSyncRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 2.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25H6V15H4.75A2.75 2.75 0 012 12.25v-8.5A2.75 2.75 0 014.75 1h3.597c.464 0 .91.184 1.237.513l2.904 2.903c.328.328.512.773.512 1.237V6.75H8.5c-.69 0-1.25-.56-1.25-1.25v-3h-2.5zm4 .3l2.45 2.45H8.75V2.8z"
          fill="currentColor"
        />
        <path
          d="M12.975 13.492a2.29 2.29 0 01-2.836-.24h.145a.625.625 0 000-1.25H8.6a.625.625 0 00-.625.625v1.823a.625.625 0 101.25 0v-.342a3.53 3.53 0 002.446 1.017 3.539 3.539 0 003.315-2.204.625.625 0 00-1.158-.471c-.173.425-.47.788-.853 1.042zM11.255 9.23a2.299 2.299 0 011.587.548h-.2a.625.625 0 000 1.25H14.4c.345 0 .625-.28.625-.625V8.5a.625.625 0 10-1.25 0v.435a3.548 3.548 0 00-5.706 1.238.625.625 0 001.155.477 2.299 2.299 0 012.03-1.42z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.249 3.501a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75H9v1.5H7.249a3.25 3.25 0 01-3.25-3.25v-9.5a3.25 3.25 0 013.25-3.25h3.12c.464 0 .909.185 1.237.513l3.88 3.88c.328.328.512.773.512 1.238V10h-1.5V8.75H11.5A2.25 2.25 0 019.25 6.5V3.501H7.249zm3.501.278l3.47 3.471H11.5a.75.75 0 01-.75-.75V3.78z"
          fill="currentColor"
        />
        <path
          d="M15.269 18.176a2.693 2.693 0 01-2.147-.946h.577a.625.625 0 100-1.25h-2.047a.625.625 0 00-.625.625v2.029a.625.625 0 101.25 0v-.473a3.944 3.944 0 006.528-1.14.625.625 0 10-1.15-.487 2.693 2.693 0 01-2.386 1.642zM14.424 12.909a2.78 2.78 0 012.373.849h-.657a.625.625 0 100 1.25h2.232c.346 0 .625-.28.625-.625v-2.217a.625.625 0 00-1.25 0v.778a4.033 4.033 0 00-6.81 1.491.625.625 0 101.19.382 2.782 2.782 0 012.297-1.909z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.318 17.017a2.754 2.754 0 01-1.753-.51.75.75 0 00-.068-1.497H8.745a.75.75 0 00-.75.75v1.897a.75.75 0 001.5 0v-.08a4.251 4.251 0 005.058.212 4.254 4.254 0 001.472-1.73.75.75 0 10-1.36-.631 2.754 2.754 0 01-2.347 1.589zM11.882 12.016a2.754 2.754 0 011.71.504h-.121a.75.75 0 000 1.5h1.752a.75.75 0 00.75-.75v-1.896a.75.75 0 00-1.497-.066 4.245 4.245 0 00-2.659-.79 4.255 4.255 0 00-3.664 2.433.75.75 0 101.357.64 2.754 2.754 0 012.372-1.575z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.75 18A3.75 3.75 0 0116 21.75H8A3.75 3.75 0 014.25 18V6A3.75 3.75 0 018 2.25h5.05c.729 0 1.428.29 1.944.805l3.95 3.951a2.75 2.75 0 01.806 1.945V18zm-14-12A2.25 2.25 0 018 3.75h4.25V7A2.75 2.75 0 0015 9.75h3.25V18A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18V6zm12.285 2.25H15c-.69 0-1.25-.56-1.25-1.25V3.965c.065.044.127.095.183.151l3.95 3.95c.057.057.108.119.152.184z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.351 23.707a3.424 3.424 0 01-2.976-1.207h1.072a.75.75 0 000-1.5H12.25c-.69 0-1.25.56-1.25 1.25v2.666a.75.75 0 001.5 0v-.804a5.17 5.17 0 004.03 1.336 5.175 5.175 0 004.419-3.634.875.875 0 10-1.674-.512 3.426 3.426 0 01-2.924 2.405zM15.636 16.359a3.535 3.535 0 013 1.16H17.55a.75.75 0 000 1.5h2.2c.69 0 1.25-.56 1.25-1.25V15.1a.75.75 0 00-1.5 0v.815a5.29 5.29 0 00-4.044-1.297 5.285 5.285 0 00-4.511 3.715.875.875 0 001.674.51 3.535 3.535 0 013.017-2.484z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 24.5a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 015 24.5v-17A5.5 5.5 0 0110.5 2h6.726a4 4 0 012.829 1.172l5.773 5.773A4 4 0 0127 11.774V24.5zM7 7.5A3.5 3.5 0 0110.5 4H16v5.5a3.5 3.5 0 003.5 3.5H25v11.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 24.5v-17zM24.844 11H19.5A1.5 1.5 0 0118 9.5V4.156a2 2 0 01.64.43l5.774 5.774c.185.185.33.403.43.64z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M36 12.5a1.5 1.5 0 00-3 0v6a6.5 6.5 0 006.5 6.5h7a1.5 1.5 0 000-3h-7a3.5 3.5 0 01-3.5-3.5v-6zM32.44 47.737a7.75 7.75 0 01-6.779-3.278H28.5a1.25 1.25 0 100-2.5H23a1.25 1.25 0 00-1.25 1.25v5.754a1.25 1.25 0 002.5 0v-2.255a10.25 10.25 0 0017.672-4.134 1.25 1.25 0 10-2.42-.628 7.75 7.75 0 01-7.062 5.791zM31.552 32.263a7.75 7.75 0 016.76 3.24H35.5a1.25 1.25 0 000 2.5h4.714c.121.019.246.02.372 0H41a1.25 1.25 0 001.25-1.25V31a1.25 1.25 0 10-2.5 0v2.292a10.25 10.25 0 00-17.677 4.155 1.25 1.25 0 102.421.623 7.75 7.75 0 017.058-5.807z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6C15.701 6 11 10.701 11 16.5v31C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5V23.048a7.5 7.5 0 00-2.197-5.303l-9.547-9.548A7.5 7.5 0 0035.951 6H21.5zM14 16.5A7.5 7.5 0 0121.5 9h14.452a4.5 4.5 0 013.182 1.318l9.548 9.548A4.5 4.5 0 0150 23.048V47.5a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocArrowSyncRegular)
