import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EStatusProcess, FormConstructor } from '@lib/form'
import { EServerError } from '@lib/services'

import { AuthStore } from '@modules/auth/store/AuthStore'

@singleton()
class RegistrationFormStore {
  private authStore = container.resolve(AuthStore)

  form = new FormConstructor({
    organizationName: {
      rule: 'required|regex:/[а-яА-ЯёЁa-zA-Z0-9 "\'-:#()№\\-.,!]+/',
      errorText: 'Введите корректное наименование',
      label: 'Наименование организации',
    },
    inn: {
      rule: 'required|regex:/^[0-9]{10,12}$/',
      errorText:
        'ИНН должен состоять из 10 (юр. лица) или из 12 (физ. лица) цифр',
      label: 'ИНН',
    },
    fullName: {
      rule: 'required|regex:/^[a-zA-Zа-яёА-ЯЁ ]+/',
      errorText: 'Введите корректное имя',
      label: 'ФИО',
    },
    email: {
      rule: 'required|email',
      errorText: 'Некорректный адрес электронной почты',
      label: 'Электронная почта',
    },
    phone: {
      rule: 'required|regex:/^\\+7 [\\d]{3} [\\d]{3} [\\d]{2} [\\d]{2}/',
      errorText: 'Телефон должен состоять из 10 цифр',
      label: 'Телефон',
    },
  })

  constructor() {
    makeAutoObservable<RegistrationFormStore, 'authStore'>(this, {
      authStore: false,
    })
  }

  get submitFormDisabled() {
    return (
      !this.form.fields.organizationName.value ||
      !this.form.fields.inn.value ||
      !this.form.fields.fullName.value ||
      !this.form.fields.email.value ||
      !this.form.fields.phone.value
    )
  }

  get registrationSuccess() {
    return this.form.meta.fetchStatus === EStatusProcess.SUCCESS
  }
  get registrationError() {
    return this.form.meta.fetchStatus === EStatusProcess.FAIL
  }
  get registrationIDLE() {
    return this.form.meta.fetchStatus === EStatusProcess.IDLE
  }
  get registrationRunning() {
    return this.form.meta.fetchStatus === EStatusProcess.RUNNING
  }

  handleSubmit = async () => {
    await this.form.handleSubmit(
      (fields) =>
        this.authStore.register({
          fullName: fields.fullName.value,
          email: fields.email.value,
          phone: fields.phone.value,
          companyName: fields.organizationName.value,
          companyInn: fields.inn.value,
        }),
      (errName) => {
        if (errName === EServerError.CompanyRegistered) {
          return 'organizationName'
        } else if (errName === EServerError.EmailRegistered) {
          return 'email'
        } else if (errName === EServerError.InvalidInn) {
          return 'inn'
        }
      },
    )
  }
}

export const useRegistrationFormStore = () =>
  container.resolve(RegistrationFormStore)
