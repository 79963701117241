import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiMenuDots8VRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 3.996A1.248 1.248 0 106 1.5a1.248 1.248 0 000 2.496zM6.002 11a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM7.248 6.245a1.248 1.248 0 11-2.496 0 1.248 1.248 0 012.496 0zM6 14.497A1.248 1.248 0 106 12a1.248 1.248 0 000 2.497zM11.248 2.748a1.248 1.248 0 11-2.496 0 1.248 1.248 0 012.496 0zM10.002 11a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11.248 6.245a1.248 1.248 0 11-2.496 0 1.248 1.248 0 012.496 0zM10 14.497A1.248 1.248 0 1010 12a1.248 1.248 0 000 2.497z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 5.003a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 9.335a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 12.168a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM7.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14 3.503a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM12.5 9.335a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14 12.168a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM12.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.303 5.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM9.303 11.168a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM11.178 14.71a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM9.303 22.003a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM16.572 3.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM14.678 11.168a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM16.553 14.71a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM14.678 22.003a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13 9a2 2 0 100-4 2 2 0 000 4zM13 15a2 2 0 100-4 2 2 0 000 4zM15 19a2 2 0 11-4 0 2 2 0 014 0zM13 27a2 2 0 100-4 2 2 0 000 4zM21 7a2 2 0 11-4 0 2 2 0 014 0zM19 15a2 2 0 100-4 2 2 0 000 4zM21 19a2 2 0 11-4 0 2 2 0 014 0zM19 27a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M25 17.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM25 29.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM28.5 38a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM25 53.502a3.5 3.5 0 100-7.002 3.5 3.5 0 000 7.002zM42.5 14a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM39 29.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM42.5 38a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM39 53.502a3.5 3.5 0 100-7.002 3.5 3.5 0 000 7.002z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiMenuDots8VRegular)
