import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiMenuDots3VRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.248 2.998a1.248 1.248 0 11-2.496 0 1.248 1.248 0 012.496 0zM9.248 8a1.248 1.248 0 11-2.496 0 1.248 1.248 0 012.496 0zM8 14.25a1.248 1.248 0 100-2.497 1.248 1.248 0 000 2.497z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.56 3.76a1.56 1.56 0 11-3.12 0 1.56 1.56 0 013.12 0zM11.56 10a1.56 1.56 0 11-3.12 0 1.56 1.56 0 013.12 0zM10 17.8a1.56 1.56 0 100-3.12 1.56 1.56 0 000 3.12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.878 4.5a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM13.877 12.003a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM12.002 21.38a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18 7a2 2 0 11-4 0 2 2 0 014 0zM18 16a2 2 0 11-4 0 2 2 0 014 0zM16 27a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M35.5 14a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM35.5 32a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM32 53.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiMenuDots3VRegular)
