import { observer } from 'mobx-react-lite'

import { Input } from '@ui/Input'

import CardForm from '@lib/components/CardForm'
import useCardForm from '@lib/hooks/useCardForm'

import { useCompanyBasicInformationFormStore } from '@modules/company/store/CompanyBasicInformationFormStore'
import { useCompanyStore } from '@modules/company/store/CompanyStore'

const BasicInformation = () => {
  const store = useCompanyBasicInformationFormStore()
  const companyStore = useCompanyStore()

  const { handleFocus, handleSubmit, handleCancel, isEditing, isSaved } =
    useCardForm(store.handleSubmit, store.reset)

  return (
    <CardForm
      title="Основная информация"
      direction="column"
      editable={isEditing}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      saved={isSaved}>
      <Input
        label="Название организации"
        value={companyStore.company.name}
        placeholder="Не заполнено"
        disabled
      />
      <Input
        label="ИНН"
        value={companyStore.company.inn}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        disabled
      />
      <Input
        label="Юридический адрес"
        name="legalAddress"
        value={store.form.fields.legalAddress.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.legalAddress.error}
        errorText={store.form.fields.legalAddress.errorText}
      />
      <Input
        label="Фактический адрес"
        name="actualAddress"
        value={store.form.fields.actualAddress.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.actualAddress.error}
        errorText={store.form.fields.actualAddress.errorText}
      />
    </CardForm>
  )
}

export default observer(BasicInformation)
