import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconAttachPinLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.588 1.021C10.103.536 9.31.908 9.31 1.555a3.427 3.427 0 01-3.427 3.44H3.485c-.89 0-1.337 1.077-.707 1.707l2.906 2.906-3.538 3.538a.5.5 0 00.707.707l3.538-3.538 2.902 2.902c.63.63 1.707.183 1.707-.707V10.11a3.427 3.427 0 013.44-3.427c.647 0 1.018-.792.533-1.277L10.588 1.02zM9.014 4.698a4.45 4.45 0 001.26-2.576l3.599 3.598a4.45 4.45 0 00-2.576 1.26A4.428 4.428 0 0010 10.112v2.399L3.485 5.995h2.399c1.174 0 2.3-.467 3.13-1.297z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.756 7.429L12.57 2.243c-.535-.535-1.45-.156-1.451.6v.45a3.25 3.25 0 01-.952 2.295l-.457.456a3.25 3.25 0 01-2.298.952H4.951c-1.247 0-1.872 1.508-.99 2.39l2.973 2.973-4.288 4.287a.5.5 0 00.708.708l4.287-4.288 2.97 2.97c.882.882 2.39.257 2.39-.99v-2.461c0-.862.342-1.689.952-2.298l.455-.455a3.25 3.25 0 012.298-.952h.449c.757 0 1.136-.916.6-1.451zm-5.638-4.135v-.088l4.675 4.674h-.087a4.25 4.25 0 00-3.005 1.245l-.455.455A4.25 4.25 0 0012 12.585v2.461a.4.4 0 01-.683.283l-6.65-6.65a.4.4 0 01.283-.683h2.461a4.25 4.25 0 003.005-1.245l.457-.456a4.25 4.25 0 001.244-3.001z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachPinLight)
