import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople3Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.85a2.15 2.15 0 100 4.3 2.15 2.15 0 000-4.3zM7.15 5a.85.85 0 111.7 0 .85.85 0 01-1.7 0zM10.85 6a2.15 2.15 0 114.3 0 2.15 2.15 0 01-4.3 0zM13 5.15a.85.85 0 100 1.7.85.85 0 000-1.7z"
          fill="currentColor"
        />
        <path
          d="M12.944 10.297c.536.079.89.247 1.114.433.292.244.442.582.442 1.02v.75a.75.75 0 001.5 0v-.75c0-.818-.3-1.605-.983-2.173-.65-.54-1.562-.813-2.68-.826l.013.019c.308.448.505.962.594 1.527zM3.661 8.75c-1.118.014-2.03.288-2.678.827C.3 10.145 0 10.932 0 11.75v.75a.75.75 0 001.5 0v-.75c0-.438.15-.776.442-1.02.224-.185.577-.354 1.111-.433a3.668 3.668 0 01.595-1.527l.013-.02z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3.85a2.15 2.15 0 100 4.3 2.15 2.15 0 000-4.3zM2.15 6a.85.85 0 111.7 0 .85.85 0 01-1.7 0z"
          fill="currentColor"
        />
        <path
          d="M5.502 10.048c-.14.205-.252.503-.252.963v1.49a.75.75 0 11-1.5 0v-1.49c0-.72.18-1.324.516-1.812a2.965 2.965 0 011.239-1.01c.889-.406 1.906-.439 2.494-.439.587 0 1.605.033 2.494.439.457.208.906.527 1.238 1.01.335.488.516 1.093.516 1.812v1.49a.75.75 0 11-1.5 0v-1.49c0-.46-.112-.758-.252-.963a1.47 1.47 0 00-.625-.494C9.3 9.294 8.568 9.25 8 9.25c-.57 0-1.301.043-1.872.304a1.47 1.47 0 00-.625.494z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.475a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zm-1.75 3.25a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zM13.75 7.5a2.749 2.749 0 115.497 0 2.749 2.749 0 01-5.497 0zm2.749-1.249a1.249 1.249 0 100 2.498 1.249 1.249 0 000-2.498z"
          fill="currentColor"
        />
        <path
          d="M16.07 12.517c.846.088 1.42.313 1.787.582.447.328.643.758.643 1.27v.88a.75.75 0 101.5 0v-.88c0-.964-.404-1.855-1.257-2.48-.796-.583-1.922-.891-3.34-.912.315.462.537.978.667 1.54zM4.514 10.979c-1.379.031-2.476.338-3.257.91A2.988 2.988 0 000 14.37v.88a.75.75 0 001.5 0v-.88c0-.512.196-.942.643-1.27.355-.26.903-.479 1.704-.573.13-.565.351-1.083.667-1.547z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.499 4.751a2.749 2.749 0 100 5.497 2.749 2.749 0 000-5.497zM2.25 7.5a1.249 1.249 0 112.497 0 1.249 1.249 0 01-2.497 0z"
          fill="currentColor"
        />
        <path
          d="M6.826 12.749c-.187.261-.327.632-.327 1.185v1.74a.75.75 0 01-1.5 0v-1.74c0-.825.215-1.51.606-2.057.386-.541.908-.897 1.441-1.13C8.088 10.29 9.29 10.25 10 10.25c.71 0 1.912.04 2.954.496.533.234 1.055.59 1.441 1.13.39.548.606 1.233.606 2.058v1.74a.75.75 0 11-1.5 0v-1.74c0-.553-.14-.924-.327-1.185-.19-.268-.469-.474-.823-.629-.732-.321-1.657-.37-2.351-.37s-1.619.049-2.351.37c-.354.155-.632.361-.823.629z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.75a4.523 4.523 0 100 9.047 4.523 4.523 0 000-9.047zM8.977 8.273a3.023 3.023 0 116.046 0 3.023 3.023 0 01-6.046 0zM.75 11a3 3 0 116 0 3 3 0 01-6 0zm3-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
          fill="currentColor"
        />
        <path
          d="M19.89 16.831c.393.054.787.141 1.141.282.387.153.686.356.886.61.192.244.333.582.333 1.09v.687a.75.75 0 101.5 0v-.687c0-.814-.236-1.488-.655-2.02-.41-.52-.959-.856-1.51-1.075-.936-.371-1.997-.45-2.752-.465a5.585 5.585 0 011.056 1.578zM5.04 15.256c-.745.021-1.74.111-2.624.462-.552.22-1.1.555-1.51 1.076-.42.531-.656 1.205-.656 2.019v.687a.75.75 0 101.5 0v-.687c0-.508.14-.846.333-1.09.2-.254.499-.457.886-.61.314-.125.66-.208 1.008-.262a5.62 5.62 0 011.062-1.595z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.25 8a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
          fill="currentColor"
        />
        <path
          d="M7.042 17.332c-.32.439-.542 1.04-.542 1.892V20A.75.75 0 015 20v-.776c0-1.131.301-2.05.83-2.776.524-.718 1.234-1.196 1.974-1.513 1.457-.626 3.155-.685 4.196-.685 1.04 0 2.739.06 4.195.685.74.317 1.451.795 1.975 1.513.529.725.83 1.645.83 2.776V20a.75.75 0 01-1.5 0v-.776c0-.852-.222-1.453-.542-1.892-.325-.446-.79-.777-1.354-1.019-1.152-.494-2.579-.563-3.604-.563s-2.452.069-3.604.563c-.564.242-1.029.573-1.354 1.019z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4a6 6 0 100 12 6 6 0 000-12zm-4 6a4 4 0 118 0 4 4 0 01-8 0zM1 13.75a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm4.25-2.25a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
          fill="currentColor"
        />
        <path
          d="M27.163 21.156c.42.072.833.177 1.212.327.516.205.914.475 1.181.814.256.325.444.776.444 1.453V25a1 1 0 102 0v-1.25c0-1.085-.315-1.983-.873-2.692-.548-.694-1.279-1.142-2.014-1.434-1.007-.4-2.123-.545-3.045-.597a6.764 6.764 0 011.095 2.129zM5.932 19.027c-.922.052-2.038.198-3.045.597-.735.292-1.466.74-2.014 1.434C.315 21.767 0 22.665 0 23.75V25a1 1 0 102 0v-1.25c0-.677.187-1.128.444-1.453.267-.34.665-.609 1.181-.814a6.12 6.12 0 011.212-.327 6.763 6.763 0 011.095-2.129zM13 26h.011-.022H13zM19 26h.012-.024H19z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.75 9.5a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm-2.25 4.25a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
          fill="currentColor"
        />
        <path
          d="M8.788 21.246C8.32 21.869 8 22.717 8 23.914V25a1 1 0 11-2 0v-1.086c0-1.581.433-2.862 1.188-3.868.747-.995 1.757-1.656 2.811-2.095C12.077 17.083 14.503 17 16 17c1.497 0 3.923.083 6 .95 1.055.44 2.065 1.1 2.812 2.096.755 1.006 1.188 2.287 1.188 3.868V25a1 1 0 11-2 0v-1.086c0-1.197-.32-2.045-.788-2.668-.476-.635-1.157-1.106-1.982-1.45-1.679-.7-3.752-.796-5.23-.796-1.478 0-3.551.095-5.23.796-.825.344-1.506.815-1.982 1.45z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 11.531c-6.386 0-11.563 5.177-11.563 11.563 0 6.386 5.177 11.562 11.563 11.562 6.386 0 11.562-5.176 11.562-11.562 0-6.386-5.176-11.563-11.562-11.563zm-8.563 11.563a8.563 8.563 0 1117.126 0 8.563 8.563 0 01-17.126 0zM3.5 30a8 8 0 1116 0 8 8 0 01-16 0zm8-5a5 5 0 100 10 5 5 0 000-10z"
        fill="currentColor"
      />
      <path
        d="M52.07 43.063c1.492.1 3.125.348 4.531.93 1.03.427 1.863 1.003 2.44 1.767.564.746.959 1.77.959 3.24v.5a1.5 1.5 0 103 0V49c0-2.053-.568-3.728-1.566-5.05-.985-1.303-2.314-2.16-3.685-2.728C55.052 40.105 51.914 40 50 40h-.093a12.484 12.484 0 012.163 3.063zM14.093 40H14c-1.915 0-5.052.105-7.75 1.222-1.37.568-2.699 1.425-3.684 2.729C1.567 45.27 1 46.947 1 49v.5a1.5 1.5 0 003 0V49c0-1.47.395-2.494.959-3.24.577-.764 1.41-1.34 2.44-1.767 1.406-.582 3.039-.83 4.53-.93A12.484 12.484 0 0114.094 40z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5 22a8 8 0 100 16 8 8 0 000-16zm-5 8a5 5 0 1110 0 5 5 0 01-10 0z"
        fill="currentColor"
      />
      <path
        d="M17.6 44.34c-.962 1.226-1.6 2.868-1.6 5.143V51.5a1.5 1.5 0 01-3 0v-2.017c0-2.884.827-5.195 2.241-6.996 1.399-1.78 3.294-2.965 5.285-3.758C24.466 37.157 29.096 37 32 37s7.533.157 11.474 1.729c1.991.793 3.886 1.978 5.285 3.758 1.414 1.801 2.24 4.111 2.24 6.996V51.5a1.5 1.5 0 01-3 0v-2.017c0-2.274-.637-3.917-1.6-5.143-.979-1.246-2.374-2.163-4.036-2.825C39 40.175 34.88 40 32 40c-2.88 0-7 .175-10.364 1.515-1.66.663-3.056 1.579-4.035 2.825z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople3Regular)
