import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDeliveryBoxFrontOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.921 3.299A1.75 1.75 0 012.606 2.02h10.735a1.75 1.75 0 011.688 1.285l.862 3.127a1.25 1.25 0 01-1.205 1.583H14v3.28a2.75 2.75 0 01-2.75 2.75h-6.5A2.75 2.75 0 012 11.297V8h-.749A1.25 1.25 0 01.047 6.412L.921 3.3zm1.685.222a.25.25 0 00-.24.183L1.58 6.5h5.193a.25.25 0 00.237-.17l.945-2.809h-5.35zM12.5 8.016h-1.29a1.75 1.75 0 01-1.66-1.2l-.556-1.677-.562 1.67A1.75 1.75 0 016.774 8H3.5v3.297c0 .69.56 1.25 1.25 1.25h3.5V9a.75.75 0 111.5 0v3.547h1.5c.69 0 1.25-.56 1.25-1.25V8.016zm-1.527-1.672l-.935-2.823h3.303a.25.25 0 01.242.184l.775 2.81H11.21a.25.25 0 01-.237-.17z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.08 3.336A1.75 1.75 0 012.776 2.02h14.439c.807 0 1.51.552 1.7 1.337l1 4.108A1.25 1.25 0 0118.7 9.01H18V13.8a3.25 3.25 0 01-3.25 3.25h-9.5A3.25 3.25 0 012 13.8V9h-.764a1.25 1.25 0 01-1.21-1.561L1.08 3.336zm1.695.185a.25.25 0 00-.242.188L1.56 7.5H8.73a.25.25 0 00.241-.184l1.045-3.795h-7.24zM16.5 9.011h-3.248a1.75 1.75 0 01-1.69-1.292l-.568-2.1-.577 2.095A1.75 1.75 0 018.73 9H3.5v4.8c0 .966.783 1.75 1.75 1.75h5V10a.75.75 0 011.5 0v5.55h3a1.75 1.75 0 001.75-1.75V9.011zm-3.49-1.684L11.98 3.52h5.234a.25.25 0 01.243.191l.925 3.8h-5.13a.25.25 0 01-.241-.185z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.554 3H3.377a2.25 2.25 0 00-2.175 1.673l-.927 3.5A2.25 2.25 0 002.449 11h8.457a2.25 2.25 0 002.201-1.783l.41-1.932.434 1.953A2.25 2.25 0 0016.148 11h5.379a2.25 2.25 0 002.168-2.852l-.973-3.5A2.25 2.25 0 0020.554 3zm-5.138 5.913L14.435 4.5h6.12a.75.75 0 01.722.55l.972 3.5a.75.75 0 01-.722.95h-5.38a.75.75 0 01-.731-.587zM12.574 4.5H3.377a.75.75 0 00-.725.558l-.928 3.5a.75.75 0 00.725.942h8.457a.75.75 0 00.734-.594l.934-4.406z"
          fill="currentColor"
        />
        <path
          d="M3.75 12.75a.75.75 0 00-1.5 0v4.125A4.125 4.125 0 006.375 21h11.25a4.125 4.125 0 004.125-4.125V12.75a.75.75 0 00-1.5 0v4.125a2.625 2.625 0 01-2.625 2.625H14.25v-6.75a.75.75 0 10-1.5 0v6.75H6.375a2.625 2.625 0 01-2.625-2.625V12.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.557 4H4.351a3 3 0 00-2.907 2.258l-1.276 5A3 3 0 003.075 15h11.454a3 3 0 002.94-2.4l.554-2.714.587 2.742A3 3 0 0021.543 15h7.352a3 3 0 002.898-3.775l-1.338-5A3 3 0 0027.557 4zm-6.992 8.21L19.237 6h8.32a1 1 0 01.966.742l1.338 5A1 1 0 0128.895 13h-7.352a1 1 0 01-.978-.79zM16.775 6H4.351a1 1 0 00-.969.753l-1.276 5A1 1 0 003.075 13h11.454a1 1 0 00.98-.8L16.775 6z"
          fill="currentColor"
        />
        <path
          d="M5 17a1 1 0 10-2 0v5.5A5.5 5.5 0 008.5 28h15a5.5 5.5 0 005.5-5.5V17a1 1 0 00-2 0v5.5a3.5 3.5 0 01-3.5 3.5H19v-9a1 1 0 10-2 0v9H8.5A3.5 3.5 0 015 22.5V17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.46 9H8.54a5.5 5.5 0 00-5.299 4.028l-2.778 10C-.51 26.533 2.125 30 5.763 30H29.79a5.5 5.5 0 005.37-4.307L37 17.415l1.84 8.278A5.5 5.5 0 0044.209 30h14.028c3.638 0 6.273-3.468 5.3-6.972l-2.778-10A5.5 5.5 0 0055.459 9zM41.768 25.042L38.87 12h16.59a2.5 2.5 0 012.408 1.83l2.778 10A2.5 2.5 0 0158.237 27H44.21a2.5 2.5 0 01-2.44-1.958zM8.54 12a2.5 2.5 0 00-2.408 1.83l-2.778 10A2.5 2.5 0 005.763 27H29.79a2.5 2.5 0 002.44-1.958L35.132 12H8.54z"
        fill="currentColor"
      />
      <path
        d="M9 34a1.5 1.5 0 00-3 0v11.5C6 51.299 10.701 56 16.5 56h31C53.299 56 58 51.299 58 45.5V34a1.5 1.5 0 10-3 0v11.5a7.5 7.5 0 01-7.5 7.5h-9V34a1.5 1.5 0 10-3 0v19h-19A7.5 7.5 0 019 45.5V34z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDeliveryBoxFrontOpenRegular)
