import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeDRadiusUpRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.998 2.557c0-.836 1.01-1.254 1.6-.663l5.223 5.222a1.25 1.25 0 010 1.768l-5.223 5.223c-.59.59-1.6.172-1.6-.663v-2.441c-1.027.012-1.86.078-2.694.383-.958.35-1.963 1.034-3.24 2.425a.601.601 0 01-1.043-.373c-.166-3.018.654-5.153 2.094-6.529 1.309-1.25 3.068-1.812 4.883-1.897V2.557zm1 .15V5.5a.5.5 0 01-.5.5c-1.822 0-3.506.499-4.693 1.632-1.038.992-1.755 2.527-1.803 4.805 1.058-1.029 2-1.64 2.958-1.99C6.11 10.027 7.238 10 8.497 10a.5.5 0 01.501.5v2.793l5.116-5.116a.25.25 0 000-.353L8.998 2.707z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.884c0-.933 1.128-1.4 1.787-.74l6.841 6.841a1.438 1.438 0 010 2.033l-6.84 6.84c-.66.66-1.787.193-1.787-.74V14c-1.562 0-2.715.095-3.854.541-1.14.447-2.311 1.265-3.858 2.794-.445.44-1.248.176-1.275-.496-.152-3.658.818-6.38 2.5-8.19C5.197 6.84 7.535 6 10 6V2.884zm1 0V6.5a.5.5 0 01-.5.5H10c-2.24 0-4.294.76-5.753 2.33-1.376 1.481-2.273 3.736-2.248 6.894 1.406-1.334 2.58-2.142 3.783-2.614C7.1 13.093 8.409 13 10 13h.5a.5.5 0 01.5.5v3.618c0 .043.05.064.08.034l6.841-6.841a.438.438 0 000-.619l-6.84-6.841a.046.046 0 00-.08.033z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusUpRightLight)
