import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowAUpRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.5 3a.5.5 0 000 1h5.793l-8.147 8.146a.5.5 0 00.708.708L12 4.707V10.5a.5.5 0 001 0v-6A1.5 1.5 0 0011.5 3h-6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.5 3a.5.5 0 000 1H15c.09 0 .176.012.26.034L3.645 15.646a.5.5 0 00.708.708L15.966 4.74A1 1 0 0116 5v8.5a.5.5 0 001 0V5a2 2 0 00-2-2H6.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpRightLight)
