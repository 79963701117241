import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechServerRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.374 9.5a.874.874 0 11-1.748 0 .874.874 0 011.748 0zM4.5 5.376a.876.876 0 100-1.752.876.876 0 000 1.752z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 3.75a2.5 2.5 0 012.5-2.5h8.5a2.5 2.5 0 012.5 2.5v1.5c0 .681-.273 1.3-.715 1.75.442.45.715 1.069.715 1.75v1.5a2.5 2.5 0 01-2.5 2.5h-3.5v1.5h3.5a.75.75 0 010 1.5h-8.5a.75.75 0 010-1.5h3.5v-1.5h-3.5a2.5 2.5 0 01-2.5-2.5v-1.5c0-.681.273-1.3.715-1.75a2.492 2.492 0 01-.715-1.75v-1.5zm11 7.5a1 1 0 001-1v-1.5a1 1 0 00-1-1h-8.5a1 1 0 00-1 1v1.5a1 1 0 001 1h8.5zm-8.5-8.5a1 1 0 00-1 1v1.5a1 1 0 001 1h8.5a1 1 0 001-1v-1.5a1 1 0 00-1-1h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 13a1 1 0 100-2 1 1 0 000 2zM7 6a1 1 0 11-2 0 1 1 0 012 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 4.75a2.5 2.5 0 012.5-2.5h10.5a2.5 2.5 0 012.5 2.5v2.5c0 .681-.273 1.3-.715 1.75.442.45.715 1.069.715 1.75v2.5a2.5 2.5 0 01-2.5 2.5h-4.5v2.5h4.5a.75.75 0 010 1.5H4.75a.75.75 0 010-1.5h4.5v-2.5h-4.5a2.5 2.5 0 01-2.5-2.5v-2.5c0-.681.273-1.3.715-1.75a2.492 2.492 0 01-.715-1.75v-2.5zm13 9.5a1 1 0 001-1v-2.5a1 1 0 00-1-1H4.75a1 1 0 00-1 1v2.5a1 1 0 001 1h10.5zm0-6H4.75a1 1 0 01-1-1v-2.5a1 1 0 011-1h10.5a1 1 0 011 1v2.5a1 1 0 01-1 1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.873 14.25a1.123 1.123 0 11-2.246 0 1.123 1.123 0 012.246 0zM6.75 7.876a1.126 1.126 0 100-2.252 1.126 1.126 0 000 2.252z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 5.625A3.375 3.375 0 015.625 2.25h12.75a3.375 3.375 0 013.375 3.375v2.25c0 1.06-.489 2.006-1.253 2.625a3.369 3.369 0 011.253 2.625v2.25a3.375 3.375 0 01-3.375 3.375H12.75V22h6a.75.75 0 010 1.5H5.25a.75.75 0 010-1.5h6v-3.25H5.625a3.375 3.375 0 01-3.375-3.375v-2.25c0-1.06.489-2.006 1.253-2.625A3.369 3.369 0 012.25 7.875v-2.25zM18.375 17.25c1.035 0 1.875-.84 1.875-1.875v-2.25c0-1.036-.84-1.875-1.875-1.875H5.625c-1.036 0-1.875.84-1.875 1.875v2.25c0 1.035.84 1.875 1.875 1.875h12.75zM5.625 3.75c-1.036 0-1.875.84-1.875 1.875v2.25c0 1.036.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875v-2.25c0-1.036-.84-1.875-1.875-1.875H5.625z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.498 19a1.498 1.498 0 11-2.996 0 1.498 1.498 0 012.996 0zM9 10.502a1.502 1.502 0 100-3.003 1.502 1.502 0 000 3.003z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 7.5A4.5 4.5 0 017.5 3h17A4.5 4.5 0 0129 7.5v3a4.491 4.491 0 01-1.671 3.5A4.491 4.491 0 0129 17.5v3a4.5 4.5 0 01-4.5 4.5H17v4h8a1 1 0 110 2H7a1 1 0 110-2h8v-4H7.5A4.5 4.5 0 013 20.5v-3c0-1.414.652-2.675 1.671-3.5A4.491 4.491 0 013 10.5v-3zM24.5 23a2.5 2.5 0 002.5-2.5v-3a2.5 2.5 0 00-2.5-2.5h-17A2.5 2.5 0 005 17.5v3A2.5 2.5 0 007.5 23h17zM7.5 5A2.5 2.5 0 005 7.5v3A2.5 2.5 0 007.5 13h17a2.5 2.5 0 002.5-2.5v-3A2.5 2.5 0 0024.5 5h-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M20.005 37.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM17.497 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.5A7.5 7.5 0 0113.5 6h37a7.5 7.5 0 017.5 7.5v8a7.488 7.488 0 01-3 6 7.488 7.488 0 013 6v8a7.5 7.5 0 01-7.5 7.5h-17v8h16a1.5 1.5 0 010 3h-35a1.5 1.5 0 010-3h16v-8h-17A7.5 7.5 0 016 41.5v-8a7.489 7.489 0 013-6 7.489 7.489 0 01-3-6v-8zM50.5 46a4.5 4.5 0 004.5-4.5v-8a4.5 4.5 0 00-4.5-4.5h-37A4.5 4.5 0 009 33.5v8a4.5 4.5 0 004.5 4.5h37zm0-20h-37A4.5 4.5 0 019 21.5v-8A4.5 4.5 0 0113.5 9h37a4.5 4.5 0 014.5 4.5v8a4.5 4.5 0 01-4.5 4.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechServerRegular)
