import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBuildingOfficeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 5.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM4.5 7a.5.5 0 100 1h1a.5.5 0 000-1h-1zM4 9.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM7.5 5a.5.5 0 100 1h1a.5.5 0 000-1h-1zM7 7.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM7.5 9a.5.5 0 100 1h1a.5.5 0 000-1h-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.107 1a1 1 0 00-.94 1.342l.758 2.083c.05.137.075.282.075.427v7.398A2.75 2.75 0 003.75 15h5.5A2.75 2.75 0 0012 12.25V4.852c0-.145.026-.29.076-.427l.757-2.083A1 1 0 0011.893 1H1.107zm1.228 2.913L1.82 2.5h9.359l-.514 1.413c-.11.3-.166.619-.166.94v7.397c0 .69-.56 1.25-1.25 1.25h-5.5c-.69 0-1.25-.56-1.25-1.25V4.852c0-.32-.056-.638-.165-.94z"
          fill="currentColor"
        />
        <path
          d="M14.28 4.97a.75.75 0 10-1.06 1.06l1.207 1.207a.25.25 0 01.073.177v5.836a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1A1.75 1.75 0 0016 13.25V7.414c0-.464-.184-.909-.512-1.237L14.28 4.97z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 6.5a.5.5 0 01.5-.5h1.25a.5.5 0 010 1H5.5a.5.5 0 01-.5-.5zM5.5 8a.5.5 0 000 1h1.25a.5.5 0 000-1H5.5zM5 10.5a.5.5 0 01.5-.5h1.25a.5.5 0 010 1H5.5a.5.5 0 01-.5-.5zM5.5 12a.5.5 0 000 1h1.25a.5.5 0 000-1H5.5zM8.75 6.5a.5.5 0 01.5-.5h1.25a.5.5 0 010 1H9.25a.5.5 0 01-.5-.5zM9.25 8a.5.5 0 000 1h1.25a.5.5 0 000-1H9.25zM8.75 10.5a.5.5 0 01.5-.5h1.25a.5.5 0 010 1H9.25a.5.5 0 01-.5-.5zM9.25 12a.5.5 0 000 1h1.25a.5.5 0 000-1H9.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.107 2a1 1 0 00-.94 1.342l.758 2.083c.05.137.075.282.075.427v8.898A3.25 3.25 0 005.25 18h5.5A3.25 3.25 0 0014 14.75V5.852c0-.145.026-.29.075-.427l.758-2.083A1 1 0 0013.893 2H2.107zm1.228 2.913L2.82 3.5H13.18l-.514 1.413a2.75 2.75 0 00-.166.94v8.897a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75V5.852c0-.32-.056-.638-.165-.94z"
          fill="currentColor"
        />
        <path
          d="M16.51 6.883a.75.75 0 10-.854 1.234l1.52 1.052a.75.75 0 01.324.617v5.964a.75.75 0 01-.75.75H15a.75.75 0 100 1.5h1.75A2.25 2.25 0 0019 15.75V9.786a2.25 2.25 0 00-.97-1.85l-1.52-1.053z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.4 8.75A.75.75 0 016.15 8h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM6.15 11a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5zM5.4 14.75a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM10.35 8a.75.75 0 100 1.5h1.5a.75.75 0 000-1.5h-1.5zM9.6 11.75a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM10.35 14a.75.75 0 100 1.5h1.5a.75.75 0 000-1.5h-1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.875 3.25A1.5 1.5 0 001.49 5.327l.673 1.615c.058.137.087.284.087.433V18A3.75 3.75 0 006 21.75h6A3.75 3.75 0 0015.75 18V7.375c0-.149.03-.296.086-.433l.674-1.615a1.5 1.5 0 00-1.385-2.077H2.875zm0 1.5h12.25l-.673 1.615c-.133.32-.202.663-.202 1.01V18A2.25 2.25 0 0112 20.25H6A2.25 2.25 0 013.75 18V7.375c0-.347-.069-.69-.202-1.01L2.875 4.75z"
          fill="currentColor"
        />
        <path
          d="M18.132 8.105a.75.75 0 10-.764 1.29l3.019 1.789c.38.225.613.634.613 1.075V19c0 .69-.56 1.25-1.25 1.25h-3a.75.75 0 000 1.5h3A2.75 2.75 0 0022.5 19v-6.74a2.75 2.75 0 00-1.349-2.367l-3.019-1.788z"
          fill="currentColor"
        />
        <path
          d="M17.25 13.25a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zM18 15a.75.75 0 000 1.5h1a.75.75 0 000-1.5h-1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.2 12a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM8.2 15a1 1 0 100 2h2a1 1 0 000-2h-2zM7.2 20a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM13.799 11a1 1 0 100 2h2a1 1 0 100-2h-2zM12.799 16a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zM13.799 19a1 1 0 100 2h2a1 1 0 100-2h-2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79 4a2 2 0 00-1.867 2.718L2.9 9.259c.066.172.1.354.1.539V24a5 5 0 005 5h8a5 5 0 005-5V9.798a1.5 1.5 0 01.1-.539l.977-2.541A2 2 0 0020.211 4H3.789zm0 2h16.42l-.977 2.541A3.5 3.5 0 0019 9.798V24a3 3 0 01-3 3H8a3 3 0 01-3-3V9.798a3.5 3.5 0 00-.233-1.257L3.789 6z"
          fill="currentColor"
        />
        <path
          d="M24.176 10.806a1 1 0 00-1.019 1.721l4.026 2.384c.506.3.817.846.817 1.434v8.988c0 .92-.746 1.667-1.667 1.667h-4a1 1 0 100 2h4A3.667 3.667 0 0030 25.333v-8.988a3.667 3.667 0 00-1.798-3.154l-4.026-2.385z"
          fill="currentColor"
        />
        <path
          d="M23 18a1 1 0 011-1h1.333a1 1 0 110 2H24a1 1 0 01-1-1zM24 21a1 1 0 100 2h1.333a1 1 0 100-2H24z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15 23.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 110 3h-4a1.5 1.5 0 01-1.5-1.5zM16.5 28a1.5 1.5 0 000 3h4a1.5 1.5 0 100-3h-4zM15 35.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 110 3h-4a1.5 1.5 0 01-1.5-1.5zM16.5 40a1.5 1.5 0 000 3h4a1.5 1.5 0 100-3h-4zM27 23.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 110 3h-4a1.5 1.5 0 01-1.5-1.5zM28.5 28a1.5 1.5 0 000 3h4a1.5 1.5 0 100-3h-4zM27 35.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 110 3h-4a1.5 1.5 0 01-1.5-1.5zM28.5 40a1.5 1.5 0 000 3h4a1.5 1.5 0 100-3h-4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.986 8c-2.49 0-4.184 2.529-3.236 4.833l1.986 4.824A3.5 3.5 0 017 18.989V49.5a8.5 8.5 0 008.5 8.5h18a8.5 8.5 0 008.5-8.5V18.99c0-.458.09-.91.264-1.333l1.986-4.824C45.199 10.529 43.505 8 41.014 8H7.986zm-.462 3.69a.5.5 0 01.462-.69h33.028a.5.5 0 01.462.69l-1.986 4.824A6.5 6.5 0 0039 18.99V49.5a5.5 5.5 0 01-5.5 5.5h-18a5.5 5.5 0 01-5.5-5.5V18.99a6.5 6.5 0 00-.49-2.476L7.524 11.69z"
        fill="currentColor"
      />
      <path
        d="M47.232 22.19a1.5 1.5 0 00-1.464 2.62l8.44 4.719A3.5 3.5 0 0156 32.584V49.5a5.5 5.5 0 01-5.5 5.5h-4a1.5 1.5 0 000 3h4a8.5 8.5 0 008.5-8.5V32.584a6.5 6.5 0 00-3.328-5.673l-8.44-4.72z"
        fill="currentColor"
      />
      <path
        d="M46.5 37.5A1.5 1.5 0 0148 36h3a1.5 1.5 0 110 3h-3a1.5 1.5 0 01-1.5-1.5zM48 42a1.5 1.5 0 000 3h3a1.5 1.5 0 100-3h-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBuildingOfficeRegular)
