import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { Typography } from '@ui/Typography'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EStatusProcess } from '@lib/form'

import FailTransactionCard from '@modules/sales/components/FailCard'
import SuccessTransactionCard from '@modules/sales/components/SuccessCard'
import {
  RegisterTransactionStepOne,
  RegisterTransactionStepTwo,
} from '@modules/sales/forms'
import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

import styles from './RegisterTransactionPage.module.scss'

const RegisterTransactionPage = () => {
  const transactionFormStore = useRegisterTransactionFormStore()

  useEffect(() => () => transactionFormStore.clear(), [])

  return (
    <PageWrapper
      title={
        transactionFormStore.status === EStatusProcess.IDLE &&
        'Регистрация сделок'
      }
      align="center"
      className={styles.page}
      fontSize={24}>
      {transactionFormStore.status === EStatusProcess.IDLE && (
        <>
          <Typography size={16} weight="light" className={styles.description}>
            {`${transactionFormStore.step}. Заполните все поля для регистрации сделки.`}
          </Typography>

          <div className={styles.forms}>
            {transactionFormStore.step === 1 ? (
              <RegisterTransactionStepOne />
            ) : (
              <RegisterTransactionStepTwo />
            )}
          </div>
        </>
      )}

      {transactionFormStore.status === EStatusProcess.SUCCESS && (
        <SuccessTransactionCard />
      )}
      {transactionFormStore.status === EStatusProcess.FAIL && (
        <FailTransactionCard />
      )}
    </PageWrapper>
  )
}

export default observer(RegisterTransactionPage)
