import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTagRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M5 8.5a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.18 2a2.75 2.75 0 00-2.103.979L.761 5.729a2.75 2.75 0 000 3.542l2.316 2.75A2.75 2.75 0 005.181 13h7.069A2.75 2.75 0 0015 10.25v-5.5A2.75 2.75 0 0012.25 2H5.18zm-.956 1.945a1.25 1.25 0 01.957-.445h7.069c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25H5.18a1.25 1.25 0 01-.956-.445L1.91 8.305a1.25 1.25 0 010-1.61l2.315-2.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 11.251a1.251 1.251 0 100-2.503 1.251 1.251 0 000 2.503z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.987 4.001a3.25 3.25 0 00-2.585 1.28L1.076 8.333a2.75 2.75 0 000 3.334l2.326 3.052a3.25 3.25 0 002.585 1.28h8.764a3.25 3.25 0 003.25-3.25V7.25a3.25 3.25 0 00-3.25-3.25H5.988zm-1.392 2.19a1.75 1.75 0 011.392-.69h8.764c.967 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H5.988a1.75 1.75 0 01-1.392-.69l-2.326-3.051a1.25 1.25 0 010-1.516l2.326-3.051z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 13.252a1.251 1.251 0 100-2.503 1.251 1.251 0 000 2.502z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.458 4.25a3.75 3.75 0 00-2.949 1.433l-3.143 4a3.75 3.75 0 000 4.634l3.143 4a3.75 3.75 0 002.949 1.433H18A3.75 3.75 0 0021.75 16V8A3.75 3.75 0 0018 4.25H7.458zm-1.77 2.36a2.25 2.25 0 011.77-.86H18A2.25 2.25 0 0120.25 8v8A2.25 2.25 0 0118 18.25H7.458a2.25 2.25 0 01-1.77-.86l-3.142-4a2.25 2.25 0 010-2.78l3.143-4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 17.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.163 5a5.5 5.5 0 00-4.295 2.064l-4.65 5.813a5 5 0 000 6.246l4.65 5.813A5.5 5.5 0 0010.163 27H24.5a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 5H10.163zM7.43 8.314A3.5 3.5 0 0110.163 7H24.5a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5H10.163a3.5 3.5 0 01-2.733-1.314l-4.65-5.812a3 3 0 010-3.748l4.65-5.812z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 26.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM16 32a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.757 11a10.5 10.5 0 00-8.117 3.84L4.504 25.974a9.5 9.5 0 000 12.052L13.64 49.16A10.5 10.5 0 0021.757 53H48.5C54.299 53 59 48.299 59 42.5v-21C59 15.701 54.299 11 48.5 11H21.757zm-5.798 5.743A7.5 7.5 0 0121.757 14H48.5a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5H21.757a7.5 7.5 0 01-5.798-2.743L6.823 36.123a6.5 6.5 0 010-8.246l9.136-11.134z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTagRegular)
