import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import SuccessCard from '@lib/components/SuccessCard'
import { EMainRoutes } from '@lib/navigation'

import { useSupportStore } from '@modules/support/store/SupportStore'

const SuccessSupportCard = () => {
  const navigate = useNavigate()
  const store = useSupportStore()

  const handleClick = useCallback(() => {
    store.clear()
    navigate(EMainRoutes.MainSupport)
  }, [])

  return (
    <SuccessCard
      title="Данные успешно отправлены!"
      description="Мы скоро проверим вашу заявку на поддержку.
      Ответ придет на вашу почту."
      handleClick={handleClick}
      titleButton="Перейти в поддержку"
    />
  )
}

export default memo(SuccessSupportCard)
