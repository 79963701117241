import { useCallback, useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'
import { Select } from '@ui/Select'
import { SelectValue } from '@ui/Select/types'
import { TextArea } from '@ui/TextArea'
import { Typography } from '@ui/Typography'

import { FileUploader } from '@lib/components/FileUploader'
import { EStatusProcess } from '@lib/form'
import { cx } from '@lib/styles'

import { problemOptions } from '@modules/support/models/Support'

import FailSupportCard from '../components/FailCard/FailSupportCard'
import SuccessSupportCard from '../components/SuccessCard/SuccessSupportCard'
import { useSupportStore } from '../store/SupportStore'

import styles from './SupportFormPage.module.scss'

const SupportFormPage = () => {
  const store = useSupportStore()

  useEffect(() => () => store.clear(), [])

  const onSelectHandler = useCallback((field: string, value: SelectValue) => {
    store.contactUsRequest = {
      ...store.contactUsRequest,
      [field]: value,
    }
  }, [])

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      store.form.handleFieldChange(event.target.name, event.target.value)
    },
    [],
  )

  const sendRequest = useCallback(async () => {
    await store.createContactUsRequest()
  }, [])

  return (
    <>
      {(store.status === EStatusProcess.IDLE ||
        store.status === EStatusProcess.RUNNING) && (
        <div className={styles.container}>
          <Typography size={24} className={styles.title}>
            Служба поддержки
          </Typography>
          <Typography size={16} weight="light" className={styles.description}>
            Сообщите о проблеме, служба поддержки свяжется с вами.
          </Typography>
          <form className={styles.form}>
            <Typography size={18}>Описание проблемы</Typography>
            <Select
              name="type"
              className={cx(styles.input, styles.firstChild)}
              label="Тип обращения"
              labelWeight="light"
              placeholder="Выбрать тип обращения"
              onSelect={onSelectHandler}
              value={store.contactUsRequest.type}
              options={problemOptions}
            />
            <Input
              name="theme"
              maxLength={100}
              className={styles.input}
              labelWeight="light"
              value={store.form.fields.theme.value}
              onChange={handleFieldChange}
              label="Тема сообщения"
              placeholder="Опишите проблему в двух словах"
            />
            <TextArea
              name="description"
              maxLength={1000}
              labelWeight="light"
              className={styles.input}
              onChange={handleFieldChange}
              value={store.form.fields.description.value}
              label="Описание проблемы"
              placeholder="Расскажите о проблеме более подробно"
            />
            <div className={styles.input}>
              <Typography size={16} weight="light">
                Скриншот или видео (PDF. PNG, JPG, GIF, MP4)
              </Typography>

              <FileUploader
                id="file"
                className={styles.file}
                iconPosition="left"
                label="Выберите файл"
                onSetFile={store.setFile}
              />
            </div>
            <div className={styles.button}>
              <Button disabled={store.isDisabledSend} onClick={sendRequest}>
                Отправить
              </Button>
            </div>
          </form>
        </div>
      )}

      <div className={styles.statusCards}>
        {store.status === EStatusProcess.SUCCESS && <SuccessSupportCard />}
        {store.status === EStatusProcess.FAIL && <FailSupportCard />}
      </div>
    </>
  )
}

export default observer(SupportFormPage)
