import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiFilterListRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 3.25a.75.75 0 000 1.5h12a.75.75 0 000-1.5H2zM3.25 8A.75.75 0 014 7.25h8a.75.75 0 010 1.5H4A.75.75 0 013.25 8zM5.25 12a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 4.25a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5H2.75zM14.25 10.75h-8.5a.75.75 0 010-1.5h8.5a.75.75 0 010 1.5zM7.25 15a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 4.25a.75.75 0 000 1.5h18.5a.75.75 0 000-1.5H2.75zM6 12a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 12zM14.25 19.75h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 6a1 1 0 000 2h24a1 1 0 100-2H4zM8 16a1 1 0 011-1h14a1 1 0 110 2H9a1 1 0 01-1-1zM12 25a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7.5 15h49a1.5 1.5 0 000-3h-49a1.5 1.5 0 000 3zM16 32.5a1.5 1.5 0 011.5-1.5h29a1.5 1.5 0 010 3h-29a1.5 1.5 0 01-1.5-1.5zM37.5 53h-11a1.5 1.5 0 010-3h11a1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiFilterListRegular)
