import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.53 2.97a.75.75 0 010 1.06L6.737 7.823a.25.25 0 000 .354l3.793 3.793a.75.75 0 11-1.06 1.06L5.677 9.237a1.75 1.75 0 010-2.474L9.47 2.97a.75.75 0 011.06 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.155 16.78a.75.75 0 01-1.06 0l-4.836-4.836a2.75 2.75 0 010-3.889l4.835-4.836a.75.75 0 011.061 1.061L8.32 9.116a1.25 1.25 0 000 1.768l4.836 4.836a.75.75 0 010 1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.119 20.617a.875.875 0 01-1.238 0L8.474 14.21a3.125 3.125 0 010-4.42l6.407-6.406A.875.875 0 1116.12 4.62l-6.407 6.407a1.375 1.375 0 000 1.944l6.407 6.407a.875.875 0 010 1.238z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.207 27.207a1 1 0 01-1.414 0l-8.379-8.379a4 4 0 010-5.656l8.379-8.38a1 1 0 111.414 1.415l-8.379 8.379a2 2 0 000 2.828l8.38 8.379a1 1 0 010 1.414z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M42.06 54.06a1.5 1.5 0 01-2.12 0L23.181 37.304a7.5 7.5 0 010-10.606L39.94 9.939a1.5 1.5 0 112.122 2.122L25.303 28.818a4.5 4.5 0 000 6.364l16.758 16.757a1.5 1.5 0 010 2.122z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCLeftRegular)
