import React from 'react'
import { Outlet } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import {
  IconBagBriefcaseRegular,
  IconBuildingHomeRegular,
  IconBuildingOfficeRegular,
  // IconCertRosetteRegular,
  IconMarketShoppingBasketRegular,
  IconMoneyTypeCoinsRegular,
  IconPeople1Regular,
  IconQuestionCircleRegular,
  IconTagRegular,
} from '@ui/icons'

import { EMainRoutes } from '@lib/navigation'

import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { useUserStore } from '@modules/user/store/UserStore'

import { SiderMenuItem } from './Sider/Sider'
import Header from './Header'
import Sider from './Sider'
import styles from './styles.scss'

/**
 * @prop [litePage] Флаг отображения страницы только с логотипом.
 */
interface IPageProps {
  litePage?: boolean
}

const navItems: SiderMenuItem[] = [
  {
    title: 'Главная',
    link: EMainRoutes.Main,
    Icon: IconBuildingHomeRegular,
  },
  {
    title: 'Маркетинг',
    link: EMainRoutes.MainMarketing,
    Icon: IconBagBriefcaseRegular,
  },
  // Скрыты ввиду ненадобности. Вернуть, если понадобится. Вся навигация с ним осталась.
  // {
  //   title: 'Обучение',
  //   link: EMainRoutes.MainTraining,
  //   Icon: IconCertRosetteRegular,
  // },
  {
    title: 'Продажи',
    link: EMainRoutes.MainSales,
    Icon: IconMoneyTypeCoinsRegular,
  },
  {
    title: 'Конфигуратор',
    link: EMainRoutes.MainConfigurator,
    Icon: IconMarketShoppingBasketRegular,
  },
  { title: 'Продукция', link: EMainRoutes.MainProducts, Icon: IconTagRegular },
]

const footerItems: SiderMenuItem[] = [
  {
    title: 'Поддержка',
    link: EMainRoutes.MainSupport,
    Icon: IconQuestionCircleRegular,
  },
]

/**
 * Компонент страницы.
 */
const Page: React.FC<IPageProps> = ({}) => {
  const userStore = useUserStore()
  const companyStore = useCompanyStore()

  const profileLink = EMainRoutes.MainUserInfo

  return (
    <section className={styles.page}>
      <Sider
        navItems={navItems}
        footerItems={[
          ...footerItems,
          {
            title: companyStore.company?.name,
            link: EMainRoutes.MainCompany,
            Icon: IconBuildingOfficeRegular,
          },
          {
            title: userStore.lastNameWithInitials,
            link: profileLink,
            Icon: IconPeople1Regular,
          },
        ]}
      />
      <section className={styles.main}>
        <Header />
        <main>
          <Outlet />
        </main>
      </section>
    </section>
  )
}

export default observer(Page)
