import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconHeartLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5.75A3.75 3.75 0 014.75 2C6.11 2 7.33 2.726 8 3.815A3.827 3.827 0 0111.25 2 3.75 3.75 0 0115 5.75c0 1.296-.422 2.22-1.362 3.33-.626.74-1.513 1.597-2.69 2.733-.523.506-1.104 1.067-1.745 1.698a1.716 1.716 0 01-2.406 0 247.91 247.91 0 00-1.745-1.698C3.875 10.677 2.988 9.82 2.362 9.08 1.422 7.97 1 7.046 1 5.75zM4.75 3A2.75 2.75 0 002 5.75c0 .999.294 1.703 1.125 2.684.592.7 1.423 1.502 2.572 2.612.53.512 1.128 1.09 1.801 1.752a.716.716 0 001.004 0c.673-.663 1.27-1.24 1.8-1.752 1.15-1.11 1.98-1.912 2.573-2.612C13.706 7.453 14 6.75 14 5.75A2.75 2.75 0 0011.25 3a2.817 2.817 0 00-2.628 1.808.61.61 0 01-.312.336.723.723 0 01-.62 0 .61.61 0 01-.313-.336A2.817 2.817 0 004.75 3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.002 7.25A4.25 4.25 0 016.25 3C7.838 3 9.256 3.871 10 5.167A4.337 4.337 0 0113.75 3a4.249 4.249 0 014.248 4.25c0 .724-.115 1.362-.38 1.987-.262.62-.662 1.205-1.201 1.837-.717.842-1.727 1.818-3.066 3.11-.64.618-1.353 1.308-2.146 2.09a1.72 1.72 0 01-2.41 0c-.793-.782-1.507-1.472-2.146-2.09-1.339-1.292-2.349-2.268-3.066-3.11-.539-.632-.939-1.217-1.201-1.837-.265-.625-.38-1.263-.38-1.988zM6.25 4A3.25 3.25 0 003 7.25c0 .61.096 1.112.302 1.597.208.492.539.99 1.041 1.579.685.804 1.64 1.726 2.95 2.99.645.623 1.376 1.33 2.203 2.146a.72.72 0 001.006 0c.827-.816 1.558-1.522 2.203-2.145 1.31-1.265 2.265-2.187 2.95-2.991.502-.59.833-1.087 1.041-1.579.206-.485.301-.987.301-1.598A3.249 3.249 0 0013.75 4c-1.435 0-2.688.93-3.13 2.21a.607.607 0 01-.303.343.703.703 0 01-.632 0 .607.607 0 01-.304-.343C8.94 4.93 7.686 4 6.25 4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHeartLight)
