import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMathFunctionOffLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1.883 1.477a.5.5 0 10-.707.707l12.26 12.26a.5.5 0 10.707-.707l-2.712-2.711 1.642-1.641a.5.5 0 10-.708-.708l-1.64 1.642L7.404 7H8.5a.5.5 0 000-1H6.406L6 5.595A2.643 2.643 0 018.643 3H9.5a.5.5 0 000-1h-.857a3.644 3.644 0 00-3.524 2.714L1.883 1.477zM2.5 6h.741l1 1H2.5a.5.5 0 010-1zM5 10.357V7.76l1 1v1.598A3.643 3.643 0 012.357 14H2a.5.5 0 010-1h.357A2.643 2.643 0 005 10.357zM7.896 13.146l1.246-1.245.707.707-1.246 1.245a.5.5 0 11-.707-.707z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M2.038 1.646a.5.5 0 10-.707.708l15.975 15.975a.5.5 0 00.707-.707L14.36 13.97l2.427-2.427a.5.5 0 00-.707-.707l-2.427 2.427-4.27-4.27H10.5a.5.5 0 000-1H8.384l-1.38-1.38v-.676A2.938 2.938 0 019.942 3h.889a.5.5 0 000-1h-.89a3.938 3.938 0 00-3.924 3.625l-3.98-3.979zM3.51 7.993h1.354l1 1H3.51a.5.5 0 010-1zM6.004 14.04V9.134l1 1v3.908a3.937 3.937 0 01-3.937 3.937H2.51a.5.5 0 110-1h.556a2.937 2.937 0 002.937-2.937zM10.477 16.44l1.417-1.417.707.707-1.416 1.416a.5.5 0 01-.708-.707z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathFunctionOffLight)
