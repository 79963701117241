import { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import { CardItem } from '@modules/configurator/components/CardItem'
import { IProductType } from '@modules/configurator/models/Configurator'
import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

import styles from './SelectProductType.module.scss'

const SelectProductType = () => {
  const configuratorStore = useConfiguratorStore()

  const handleSelectProductType = useCallback((product: IProductType) => {
    configuratorStore.selectProductType(product)
  }, [])

  return (
    <div className={styles.container}>
      {configuratorStore.productTypes?.map((productType) => (
        <CardItem
          key={productType.key}
          title={productType.name}
          description={productType.description}
          photoURL={productType.photoUrl}
          onClick={() => handleSelectProductType(productType)}
        />
      ))}
    </div>
  )
}

export default observer(SelectProductType)
