import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBuildingHomeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.232 3.225a2.5 2.5 0 013.536 0l4.878 4.879a.5.5 0 00.708-.708l-4.88-4.878a3.5 3.5 0 00-4.949 0L.646 7.396a.5.5 0 00.708.708l4.878-4.88z"
          fill="currentColor"
        />
        <path
          d="M4 8.5a.5.5 0 00-1 0v4A2.5 2.5 0 005.5 15h5a2.5 2.5 0 002.5-2.5v-4a.5.5 0 00-1 0v4a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 12.5v-4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7.914 3.73a2.95 2.95 0 014.172 0l6.31 6.311a.5.5 0 00.708-.707l-6.31-6.31a3.95 3.95 0 00-5.587 0l-6.31 6.31a.5.5 0 00.707.707l6.31-6.31z"
        fill="currentColor"
      />
      <path
        d="M5 10.5a.5.5 0 00-1 0V15a3 3 0 003 3h6a3 3 0 003-3v-4.5a.5.5 0 00-1 0V15a2 2 0 01-2 2H7a2 2 0 01-2-2v-4.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBuildingHomeLight)
