import { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import {
  LokalizedStages,
  LokalizedStatus,
  Stages,
  Statuses,
} from '@modules/sales/models/Transaction'

interface ITransactionTableStatusProps {
  status: Statuses
  stage: Stages
}

const TransactionTableStatus = ({
  status,
  stage,
}: ITransactionTableStatusProps) => {
  if (status !== Statuses.Concluded) {
    return (
      <Typography
        size={16}
        color={
          status === Statuses.Rejected
            ? COLORS.STATE.ATTENTION
            : COLORS.GRAY.GRAY_2
        }>
        {LokalizedStatus[status]}
      </Typography>
    )
  }

  return (
    <div>
      <Typography size={16} color={COLORS.STATE.SUCCESS_H}>
        {LokalizedStatus[status]}
      </Typography>
      <Typography size={12}>({LokalizedStages[stage]})</Typography>
    </div>
  )
}

export default memo(TransactionTableStatus)
