import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCheckALight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.718 3.771a.5.5 0 010 .708L6.885 11.31a2.088 2.088 0 01-2.952 0L1.396 8.774a.5.5 0 11.708-.707l2.536 2.537a1.087 1.087 0 001.538 0l6.833-6.833a.5.5 0 01.707 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M17.809 4.803a.5.5 0 010 .707l-8.54 8.54c-.97.97-2.544.97-3.514 0l-3.171-3.17a.5.5 0 01.707-.708l3.171 3.171c.58.58 1.52.58 2.1 0l8.54-8.54a.5.5 0 01.707 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckALight)
