import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiWindowNewDownRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5V4a2 2 0 00-2-2H3a2 2 0 00-2 2v5a2 2 0 002 2h1v1a2 2 0 002 2h7a2 2 0 002-2V7a2 2 0 00-2-2h-1zM2 9a1 1 0 001 1h1V7a2 2 0 012-2h5V4a1 1 0 00-1-1H3a1 1 0 00-1 1v5zm4-3a1 1 0 00-1 1v5a1 1 0 001 1h7a1 1 0 001-1V7a1 1 0 00-1-1H6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7V5.5A2.5 2.5 0 0011.5 3h-8A2.5 2.5 0 001 5.5v5A2.5 2.5 0 003.5 13H5v1.5A2.5 2.5 0 007.5 17h8a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0015.5 7H14zM2 10.5A1.5 1.5 0 003.5 12H5V9.5A2.5 2.5 0 017.5 7H13V5.5A1.5 1.5 0 0011.5 4h-8A1.5 1.5 0 002 5.5v5zM7.5 8A1.5 1.5 0 006 9.5v5A1.5 1.5 0 007.5 16h8a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0015.5 8h-8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiWindowNewDownRightLight)
