import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1PlusLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.502 3.502 0 100 7.003A3.502 3.502 0 008 1zM5.498 4.502a2.502 2.502 0 115.004 0 2.502 2.502 0 01-5.004 0z"
          fill="currentColor"
        />
        <path
          d="M3 13.505c0-.855.208-1.473.518-1.93.311-.46.749-.79 1.26-1.025 1.04-.481 2.32-.545 3.222-.545a.5.5 0 000-1c-.915 0-2.387.057-3.641.637-.637.294-1.233.729-1.67 1.372-.437.645-.689 1.466-.689 2.49a.5.5 0 001 0zM12.5 8.5a.5.5 0 01.5.5v2h2a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2h-2a.5.5 0 010-1h2V9a.5.5 0 01.5-.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.501 6.499a4.499 4.499 0 118.998 0 4.499 4.499 0 01-8.998 0zM10 3a3.499 3.499 0 100 6.997A3.499 3.499 0 0010 3z"
        fill="currentColor"
      />
      <path
        d="M10 12.495a.5.5 0 01-.49.51c-1.284.025-2.942.174-4.265.816-.653.318-1.209.748-1.602 1.33C3.252 15.729 3 16.488 3 17.5a.5.5 0 01-1 0c0-1.188.299-2.147.815-2.91.513-.76 1.221-1.293 1.993-1.668 1.529-.743 3.371-.891 4.682-.917a.5.5 0 01.51.49zM15 12a.5.5 0 00-1 0v3h-3a.5.5 0 000 1h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3v-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1PlusLight)
