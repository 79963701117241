import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookOpenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.435 3.285c-.987-.763-2.213-1.093-3.33-1.218a12.597 12.597 0 00-2.872.035C.493 2.192 0 2.83 0 3.53v7.89c0 .913.794 1.587 1.652 1.567.662-.016 1.539.013 2.423.184.888.172 1.75.48 2.41 1a1.683 1.683 0 002.03 0c.66-.52 1.522-.828 2.41-1 .884-.171 1.76-.2 2.423-.184.858.02 1.652-.654 1.652-1.568V3.53c0-.698-.493-1.336-1.233-1.427-.69-.086-1.749-.161-2.871-.035-1.118.125-2.344.455-3.33 1.218a.112.112 0 01-.131 0zm-6.08-.191a11.6 11.6 0 012.638-.033c1.022.114 2.045.408 2.83 1.015.056.043.115.08.177.111v9.12c-.814-.605-1.802-.937-2.735-1.118-.978-.189-1.931-.219-2.636-.202-.354.008-.629-.27-.629-.568V3.53c0-.24.162-.411.355-.435zM8 13.306c.814-.604 1.802-.936 2.735-1.117.978-.189 1.931-.219 2.636-.202.354.008.629-.27.629-.568V3.53c0-.24-.162-.411-.355-.435a11.6 11.6 0 00-2.638-.033c-1.022.114-2.045.408-2.83 1.015-.056.043-.115.08-.177.111v9.12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.442 4.514c-1.094-.879-2.464-1.27-3.729-1.425-1.268-.156-2.475-.078-3.282.017C1.572 3.208 1 3.95 1 4.764v8.779c0 1.133 1.04 1.934 2.091 1.85 1.588-.125 3.885-.013 5.333 1.28.592.527 1.56.53 2.154.003 1.455-1.294 3.746-1.407 5.33-1.283 1.052.083 2.092-.717 2.092-1.852V4.764c0-.815-.572-1.556-1.431-1.658-.807-.095-2.013-.173-3.282-.017-1.265.155-2.635.546-3.73 1.425a.092.092 0 01-.057.019.092.092 0 01-.058-.019zM2.55 4.099c.762-.09 1.881-.16 3.043-.018 1.166.143 2.33.495 3.224 1.213.058.046.12.086.184.119v10.435c-1.78-1.513-4.398-1.577-5.987-1.451-.55.043-1.013-.38-1.013-.854v-8.78c0-.359.243-.627.549-.664zM10 15.853c1.786-1.516 4.397-1.582 5.986-1.457.55.044 1.014-.38 1.014-.855V4.764c0-.36-.242-.628-.549-.665-.762-.09-1.881-.16-3.043-.018-1.166.143-2.33.495-3.224 1.213-.058.046-.12.086-.184.119v10.44z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookOpenLight)
