import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowAUpRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.25 3a.75.75 0 000 1.5h5.19l-7.47 7.47a.75.75 0 101.06 1.06l7.47-7.47v5.19a.75.75 0 001.5 0v-6A1.75 1.75 0 0011.25 3h-6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.5 3.25a.75.75 0 000 1.5h7.69L3.47 15.47a.75.75 0 101.06 1.06L15.25 5.81v7.69a.75.75 0 001.5 0v-8a2.25 2.25 0 00-2.25-2.25h-8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 3.125a.875.875 0 100 1.75h10.388L3.88 18.881A.875.875 0 105.12 20.12L19.125 6.112V16.5a.875.875 0 001.75 0V6A2.875 2.875 0 0018 3.125H7.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.5 5a1 1 0 000 2h13c.028 0 .056 0 .084.002L5.793 24.792a1 1 0 101.414 1.415l17.79-17.79c.002.027.003.055.003.083v13a1 1 0 102 0v-13A3.5 3.5 0 0023.5 5h-13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M20.5 10a1.5 1.5 0 100 3h26c.695 0 1.353.157 1.94.439l-36 36a1.5 1.5 0 002.12 2.122L50.562 15.56c.282.587.439 1.245.439 1.94v26a1.5 1.5 0 103 0v-26a7.5 7.5 0 00-7.5-7.5h-26z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpRightRegular)
