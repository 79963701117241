import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconWarningTriangleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 10.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM8 4.5a.5.5 0 10-1 0v3.4a.5.5 0 001 0V4.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.783 1.481c.776-1.298 2.658-1.298 3.434 0l5.08 8.502c.797 1.333-.163 3.026-1.716 3.026H2.419c-1.553 0-2.514-1.693-1.717-3.026l5.081-8.502zm2.575.513a1 1 0 00-1.716 0L1.56 10.496a1 1 0 00.859 1.513H12.58a1 1 0 00.858-1.513L8.36 1.994z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.25 12.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM10 6.11a.5.5 0 00-1 0V9.9a.5.5 0 001 0V6.11z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.846 2.684a2 2 0 013.38 0l6.495 10.26c.843 1.331-.114 3.07-1.69 3.07H3.04c-1.576 0-2.533-1.739-1.69-3.07l6.495-10.26zm2.535.535a1 1 0 00-1.69 0L2.196 13.48a1 1 0 00.845 1.535h12.99a1 1 0 00.845-1.535L10.38 3.219z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconWarningTriangleLight)
