import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconToolPencilLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.476 1.439a1.5 1.5 0 00-2.122 0L2.44 10.354A1.5 1.5 0 002 11.414V13.5a.5.5 0 00.5.5h2.086a1.5 1.5 0 001.06-.44l8.915-8.914a1.5 1.5 0 000-2.121l-1.085-1.086zm-1.415.707a.5.5 0 01.707 0l1.086 1.086a.5.5 0 010 .707L12.75 5.043 10.957 3.25l1.104-1.104zM10.25 3.957l-7.104 7.104a.5.5 0 00-.146.353V13h1.586a.5.5 0 00.353-.146l7.104-7.104-1.793-1.793z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.476 3.439a1.5 1.5 0 00-2.122 0L3.44 13.354A1.5 1.5 0 003 14.414V16.5a.5.5 0 00.5.5h2.086a1.5 1.5 0 001.06-.44l9.915-9.914a1.5 1.5 0 000-2.121l-1.085-1.086zm-1.415.707a.5.5 0 01.707 0l1.086 1.086a.5.5 0 010 .707L14.75 7.043 12.957 5.25l1.104-1.104zM12.25 5.957l-8.104 8.104a.5.5 0 00-.146.353V16h1.586a.5.5 0 00.353-.146l8.104-8.104-1.793-1.793z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconToolPencilLight)
