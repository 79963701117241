import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowFigureACircleDownLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 4a.5.5 0 01.5.5v5.119l1.616-1.94a.5.5 0 01.768.641l-1.732 2.078a1.5 1.5 0 01-2.304 0L4.616 8.32a.5.5 0 11.768-.64L7 9.619V4.5a.5.5 0 01.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a6.5 6.5 0 110 13 6.5 6.5 0 010-13zM13 7.5a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.5 5.5a.5.5 0 01.5.5v6.219l2.116-2.54a.5.5 0 11.768.641l-2.232 2.678a1.5 1.5 0 01-2.304 0L6.116 10.32a.5.5 0 01.768-.64L9 12.219V6a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM16 9.5a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleDownLight)
