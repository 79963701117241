import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCertRosetteRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.875a3.125 3.125 0 100 6.25 3.125 3.125 0 000-6.25zM6.125 6a1.875 1.875 0 113.75 0 1.875 1.875 0 01-3.75 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6a5.75 5.75 0 1110.115 3.742l1.643 2.346a1.25 1.25 0 01-.307 1.741l-2.458 1.72a1.25 1.25 0 01-1.74-.306L8 13.097l-1.503 2.146a1.25 1.25 0 01-1.74.307l-2.458-1.721a1.25 1.25 0 01-.307-1.741l1.642-2.345A5.727 5.727 0 012.25 6zM8 1.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm.845 9.938a5.718 5.718 0 002.395-.937l1.396 1.993-2.048 1.434-1.743-2.49zm-4.085-.937c.702.48 1.516.808 2.395.937l-1.743 2.49-2.048-1.434L4.76 10.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3.888a3.75 3.75 0 100 7.502 3.75 3.75 0 000-7.502zm-2.25 3.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.361 7.639a6.639 6.639 0 1111.611 4.398l1.902 2.716a1.5 1.5 0 01-.368 2.09l-2.63 1.84a1.5 1.5 0 01-2.09-.367L10 15.764l-1.786 2.55a1.5 1.5 0 01-2.09.369l-2.63-1.842a1.5 1.5 0 01-.368-2.089l1.902-2.716A6.613 6.613 0 013.362 7.64zM10 2.5a5.139 5.139 0 100 10.277A5.139 5.139 0 0010 2.5zm.76 11.734a6.6 6.6 0 003.089-1.186l1.796 2.566-2.63 1.841-2.255-3.22zM6.15 13.048a6.601 6.601 0 003.09 1.186l-2.256 3.221-2.63-1.841 1.796-2.566z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.75a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zM8.75 9.5a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.002 9.498a7.998 7.998 0 1113.88 5.42l2.186 3.121a1.75 1.75 0 01-.43 2.438l-2.992 2.095a1.75 1.75 0 01-2.437-.43L12 18.987l-2.209 3.155a1.75 1.75 0 01-2.437.43L4.36 20.477a1.75 1.75 0 01-.43-2.438l2.187-3.122a7.97 7.97 0 01-2.116-5.419zM12 3a6.498 6.498 0 100 12.996A6.498 6.498 0 0012 3zm.762 14.46a7.955 7.955 0 003.996-1.533L18.84 18.9a.25.25 0 01-.062.348l-2.992 2.095a.25.25 0 01-.348-.061l-2.676-3.822zm-5.52-1.533a7.955 7.955 0 003.996 1.533l-2.676 3.822a.25.25 0 01-.348.061l-2.992-2.095a.25.25 0 01-.062-.348l2.082-2.973z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6.25a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM11.25 13a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 13C5 6.925 9.925 2 16 2s11 4.925 11 11c0 2.884-1.11 5.509-2.926 7.47l2.967 4.239a2.5 2.5 0 01-.614 3.482l-3.935 2.755a2.5 2.5 0 01-3.482-.614L16 26.033l-3.01 4.3a2.5 2.5 0 01-3.482.613l-3.935-2.755a2.5 2.5 0 01-.614-3.482l2.967-4.238A10.961 10.961 0 015 13zm11-9a9 9 0 100 18 9 9 0 000-18zm.987 19.956a10.943 10.943 0 005.59-2.137l2.826 4.037a.5.5 0 01-.123.696l-3.935 2.756a.5.5 0 01-.697-.123l-3.66-5.229zM9.424 21.82a10.942 10.942 0 005.589 2.137l-3.662 5.23a.5.5 0 01-.696.122L6.72 26.552a.5.5 0 01-.123-.696l2.827-4.037z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 11.734C24.267 11.734 18 18.002 18 25.735S24.267 39.736 32 39.736s14.001-6.268 14.001-14.001-6.268-14.001-14-14.001zM21 25.735c0-6.076 4.925-11.001 11.001-11.001s11.001 4.925 11.001 11.001-4.925 11.001-11 11.001c-6.077 0-11.002-4.925-11.002-11.001z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.267 25.735c0-12.003 9.73-21.734 21.733-21.734s21.734 9.73 21.734 21.734a21.654 21.654 0 01-5.607 14.57l5.593 7.987a4.5 4.5 0 01-1.105 6.267l-7.898 5.53a4.5 4.5 0 01-6.267-1.105L32 49.773l-6.45 9.211a4.5 4.5 0 01-6.267 1.106l-7.898-5.53a4.5 4.5 0 01-1.105-6.268l5.593-7.988a21.654 21.654 0 01-5.606-14.569zM32 7.001c-10.346 0-18.733 8.388-18.733 18.734 0 10.346 8.387 18.734 18.733 18.734s18.734-8.388 18.734-18.734c0-10.346-8.388-18.734-18.734-18.734zm1.986 40.378A21.635 21.635 0 0045.94 42.41l5.323 7.603a1.5 1.5 0 01-.368 2.089l-7.899 5.53a1.5 1.5 0 01-2.089-.368l-6.92-9.885zM18.061 42.41a21.637 21.637 0 0011.953 4.97l-6.921 9.884a1.5 1.5 0 01-2.09.368l-7.898-5.53a1.5 1.5 0 01-.368-2.09l5.324-7.602z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCertRosetteRegular)
