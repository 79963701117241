import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowParallelHLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.756 1.572a.5.5 0 11.707-.707l2.618 2.619a1.437 1.437 0 010 2.032l-2.618 2.619a.5.5 0 11-.707-.707L12.183 5H3a.5.5 0 010-1h9.183L9.756 1.572zM6.244 8.572a.5.5 0 10-.707-.707l-2.618 2.618a1.438 1.438 0 000 2.034l2.618 2.618a.5.5 0 10.707-.707L3.816 12H13a.5.5 0 000-1H3.816l2.428-2.428z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.283 1.752a.5.5 0 11.707-.708l3.273 3.273a1.672 1.672 0 010 2.365L12.99 9.955a.5.5 0 11-.707-.707L15.531 6H3.75a.5.5 0 110-1h11.781l-3.248-3.248zM7.717 10.752a.5.5 0 10-.707-.707l-3.273 3.273a1.672 1.672 0 000 2.364l3.273 3.273a.5.5 0 10.707-.707L4.469 15h11.78a.5.5 0 100-1H4.47l3.248-3.248z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowParallelHLight)
