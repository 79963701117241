import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconShapeTriangleUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.325 2.193a2 2 0 013.35 0l5.69 8.713c.869 1.33-.085 3.094-1.674 3.094H2.309C.72 14-.234 12.237.635 10.906l5.69-8.713zm2.094.82a.5.5 0 00-.838 0l-5.69 8.714a.5.5 0 00.418.773h11.382a.5.5 0 00.418-.773L8.42 3.013z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.937 3.083c.963-1.506 3.163-1.506 4.127 0l6.493 10.146C19.601 14.86 18.43 17 16.494 17H3.506C1.57 17 .4 14.86 1.443 13.23L7.937 3.083zm2.863.809a.95.95 0 00-1.6 0L2.706 14.038a.95.95 0 00.8 1.462h12.988a.95.95 0 00.8-1.462L10.8 3.892z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7 4.446c1.085-1.659 3.515-1.659 4.6 0l8.051 12.298c1.198 1.829-.115 4.256-2.3 4.256H3.95c-2.186 0-3.498-2.427-2.301-4.256l8.05-12.298zm3.346.821a1.25 1.25 0 00-2.092 0l-8.05 12.299A1.25 1.25 0 003.95 19.5h16.1a1.25 1.25 0 001.046-1.934l-8.05-12.299z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.612 5.019c1.568-2.5 5.208-2.5 6.776 0l10.576 16.855C31.636 24.538 29.721 28 26.576 28H5.424c-3.145 0-5.06-3.462-3.388-6.126L12.612 5.02zm5.082 1.063a2 2 0 00-3.388 0L3.73 22.937C2.894 24.27 3.85 26 5.424 26h21.152c1.572 0 2.53-1.731 1.694-3.063L17.694 6.082z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.63 9.41c2.934-4.722 9.806-4.722 12.74 0l21.208 34.132C62.682 48.538 59.09 55 53.208 55H10.792c-5.882 0-9.475-6.462-6.37-11.458L25.63 9.41zm10.192 1.584c-1.76-2.834-5.884-2.834-7.644 0L6.97 45.125C5.107 48.123 7.263 52 10.792 52h42.416c3.529 0 5.685-3.877 3.822-6.875L35.822 10.994z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconShapeTriangleUpRegular)
