import { memo } from 'react'

import Button from '@ui/Button'
import { IconCheckCircleRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { cx } from '@lib/styles'

import styles from './styles.scss'
import { ISuccessCardProps } from './types'

const SuccessCard = ({
  title,
  description,
  titleButton,
  buttonClassName,
  handleClick,
  className,
  classDescriptionName,
  fullSize = false,
}: ISuccessCardProps) => {
  const classCardNames = cx(styles.card, className)
  const classDescriptionNames = cx(styles.description, classDescriptionName)
  return (
    <CardWrapper className={classCardNames}>
      <Typography size={24}>{title}</Typography>
      <IconCheckCircleRegular
        size={64}
        color={COLORS.STATE.SUCCESS}
        className={styles.icon}
      />
      <Typography size={18} className={classDescriptionNames}>
        {description}
      </Typography>

      {handleClick && (
        <Button
          fullSize={fullSize}
          className={buttonClassName}
          onClick={handleClick}>
          {titleButton}
        </Button>
      )}
    </CardWrapper>
  )
}

export default memo(SuccessCard)
