import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArchiveBoxOpenDocsLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.5 0a.5.5 0 000 1h7a.5.5 0 000-1h-7zM6.5 8a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3.5A1.5 1.5 0 014.5 2h7A1.5 1.5 0 0113 3.5V5h.5a.5.5 0 01.5.5V12a2 2 0 01-2 2H4a2 2 0 01-2-2V5.5a.5.5 0 01.5-.5H3V3.5zm9 0V5H4V3.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zM3 12V6h10v6a1 1 0 01-1 1H4a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7 1a.5.5 0 000 1h6a.5.5 0 000-1H7zM7.5 10a.5.5 0 000 1h5a.5.5 0 000-1h-5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.5A1.5 1.5 0 016.5 3h7A1.5 1.5 0 0115 4.5V7h1.5a.5.5 0 01.5.5v8.25A2.25 2.25 0 0114.75 18h-9.5A2.25 2.25 0 013 15.75V7.5a.5.5 0 01.5-.5H5V4.5zm9 0V7H6V4.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zM4 15.75V8h12v7.75c0 .69-.56 1.25-1.25 1.25h-9.5C4.56 17 4 16.44 4 15.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveBoxOpenDocsLight)
