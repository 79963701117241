import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconHeartRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.002 5.874A3.874 3.874 0 014.876 2C6.136 2 7.279 2.603 8 3.54A3.949 3.949 0 0111.124 2a3.874 3.874 0 013.874 3.874c0 .635-.1 1.204-.336 1.763-.232.55-.582 1.056-1.032 1.583-.589.687-1.42 1.485-2.501 2.525-.524.503-1.107 1.063-1.75 1.695a1.968 1.968 0 01-2.757 0 212.178 212.178 0 00-1.75-1.695C3.788 10.705 2.958 9.907 2.37 9.22c-.45-.527-.8-1.033-1.032-1.583a4.408 4.408 0 01-.336-1.763zM4.876 3.5a2.374 2.374 0 00-2.374 2.374c0 .465.071.831.218 1.18.15.356.395.729.79 1.19.54.632 1.287 1.349 2.323 2.344.533.511 1.142 1.097 1.84 1.782a.468.468 0 00.654 0c.698-.685 1.307-1.27 1.84-1.782 1.036-.995 1.783-1.712 2.323-2.344.395-.461.64-.834.79-1.19.147-.349.218-.715.218-1.18A2.374 2.374 0 0011.124 3.5a2.44 2.44 0 00-2.267 1.546.861.861 0 01-.442.471.979.979 0 01-.83 0 .861.861 0 01-.442-.47A2.44 2.44 0 004.876 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.499 7.603C1.499 5.056 3.574 3 6.124 3 7.714 3 9.15 3.8 10 5.027A4.726 4.726 0 0113.876 3c2.55 0 4.625 2.056 4.625 4.603 0 .774-.124 1.46-.414 2.133-.287.664-.72 1.28-1.287 1.933-.72.827-1.725 1.787-3.037 3.04-.705.674-1.5 1.433-2.386 2.301a1.97 1.97 0 01-2.754 0c-.886-.868-1.68-1.627-2.386-2.301-1.312-1.253-2.318-2.213-3.037-3.04-.567-.653-1-1.269-1.287-1.933-.29-.673-.414-1.36-.414-2.133zM6.124 4.5C4.394 4.5 3 5.893 3 7.603c0 .6.094 1.08.292 1.539.202.469.528.952 1.041 1.543.675.776 1.599 1.658 2.862 2.864.713.68 1.534 1.464 2.479 2.39a.47.47 0 00.654 0c.945-.926 1.766-1.71 2.479-2.39 1.263-1.206 2.187-2.088 2.862-2.864.513-.59.84-1.074 1.041-1.543.198-.46.292-.939.292-1.54 0-1.709-1.395-3.102-3.125-3.102-1.392 0-2.603.9-3.02 2.128-.175.512-.672.589-.856.589-.183 0-.68-.077-.855-.589C8.727 5.4 7.516 4.5 6.125 4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.427 9.162A5.662 5.662 0 017.088 3.5c2.057 0 3.9 1.098 4.912 2.748A5.779 5.779 0 0116.912 3.5a5.662 5.662 0 015.661 5.662c0 .945-.147 1.778-.49 2.593-.339.807-.853 1.561-1.54 2.366-.913 1.071-2.2 2.307-3.9 3.94-.804.772-1.701 1.634-2.695 2.61a2.785 2.785 0 01-3.896 0c-.994-.976-1.891-1.838-2.696-2.61-1.699-1.633-2.986-2.869-3.899-3.94-.687-.805-1.201-1.559-1.54-2.366-.343-.815-.49-1.648-.49-2.593zM7.088 5a4.162 4.162 0 00-4.161 4.162c0 .777.119 1.408.373 2.012.257.613.668 1.235 1.298 1.974.864 1.013 2.066 2.167 3.72 3.756.814.782 1.738 1.669 2.784 2.696a1.285 1.285 0 001.796 0c1.046-1.027 1.97-1.914 2.784-2.696 1.654-1.589 2.856-2.743 3.72-3.756.63-.74 1.04-1.36 1.298-1.974.254-.604.373-1.235.373-2.012A4.162 4.162 0 0016.912 5c-1.895 0-3.542 1.264-4.058 2.98-.156.515-.644.614-.854.614-.21 0-.698-.1-.854-.615C10.63 6.264 8.983 5 7.088 5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12a7.5 7.5 0 017.5-7.5c2.72 0 5.159 1.45 6.5 3.63a7.656 7.656 0 016.5-3.63A7.5 7.5 0 0130 12c0 1.239-.191 2.333-.635 3.402-.44 1.06-1.107 2.049-1.997 3.101-1.214 1.434-2.935 3.088-5.216 5.279-.997.957-2.1 2.017-3.315 3.206a4.063 4.063 0 01-5.674 0 468.298 468.298 0 00-3.315-3.206c-2.28-2.191-4.002-3.845-5.216-5.279-.89-1.052-1.558-2.041-1.997-3.1-.444-1.07-.636-2.164-.636-3.403zm7.5-5.5A5.5 5.5 0 004 12c0 1.017.154 1.845.482 2.636.333.802.863 1.613 1.677 2.575 1.144 1.352 2.75 2.894 4.974 5.031 1.01.97 2.148 2.063 3.429 3.317a2.063 2.063 0 002.876 0 479.938 479.938 0 013.429-3.317c2.224-2.137 3.83-3.679 4.974-5.03.814-.963 1.344-1.774 1.677-2.576.328-.791.483-1.619.483-2.636a5.5 5.5 0 00-5.5-5.5c-2.564 0-4.78 1.749-5.409 4.094-.178.665-.793.822-1.092.822-.299 0-.914-.157-1.093-.822C14.28 8.248 12.062 6.5 9.5 6.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 24.75C4 16.604 10.604 10 18.75 10c5.68 0 10.737 3.214 13.25 7.945C34.513 13.215 39.57 10 45.25 10 53.396 10 60 16.604 60 24.75c0 2.47-.382 4.63-1.259 6.739-.87 2.093-2.198 4.064-3.998 6.19-2.454 2.896-5.926 6.23-10.55 10.672a954.835 954.835 0 00-6.869 6.645c-2.95 2.89-7.698 2.89-10.648 0-2.526-2.474-4.812-4.669-6.87-6.645-4.623-4.442-8.095-7.776-10.549-10.673-1.8-2.125-3.128-4.096-3.998-6.189C4.382 29.38 4 27.219 4 24.75zM18.75 13C12.26 13 7 18.26 7 24.75c0 2.136.326 3.897 1.029 5.587.709 1.707 1.831 3.412 3.517 5.402 2.35 2.774 5.65 5.942 10.188 10.302a968.353 968.353 0 017.04 6.812 4.624 4.624 0 006.451 0 968.353 968.353 0 017.041-6.812c4.539-4.36 7.837-7.528 10.188-10.302 1.686-1.99 2.808-3.695 3.517-5.402.703-1.69 1.029-3.45 1.029-5.587C57 18.26 51.74 13 45.25 13c-5.474 0-10.212 3.737-11.548 8.762-.273 1.026-1.222 1.278-1.702 1.278s-1.429-.252-1.702-1.277C28.962 16.736 24.224 13 18.75 13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHeartRegular)
