import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDataChartBarsARegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.75 4a.75.75 0 00-1.5 0v9a.75.75 0 001.5 0V4zM6 5.25a.75.75 0 01.75.75v7a.75.75 0 01-1.5 0V6A.75.75 0 016 5.25zM9.75 8a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0V8zM3.75 10a.75.75 0 00-1.5 0v3a.75.75 0 001.5 0v-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.75 3.5a.75.75 0 00-1.5 0v13a.75.75 0 001.5 0v-13zM8.75 6.5a.75.75 0 00-1.5 0v10a.75.75 0 001.5 0v-10zM4.75 12a.75.75 0 00-1.5 0v4.5a.75.75 0 001.5 0V12zM12 8.25a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.75 5a.75.75 0 00-1.5 0v15a.75.75 0 001.5 0V5zM9.75 8.5a.75.75 0 00-1.5 0V20a.75.75 0 001.5 0V8.5zM4.75 15.5a.75.75 0 00-1.5 0V20a.75.75 0 001.5 0v-4.5zM14 11.25a.75.75 0 01.75.75v8a.75.75 0 01-1.5 0v-8a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M26 5a1 1 0 011 1v20a1 1 0 11-2 0V6a1 1 0 011-1zM13 10a1 1 0 10-2 0v16a1 1 0 102 0V10zM6 19a1 1 0 10-2 0v7a1 1 0 102 0v-7zM20 15a1 1 0 10-2 0v11a1 1 0 102 0V15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M51.5 9a1.5 1.5 0 011.5 1.5v41a1.5 1.5 0 01-3 0v-41A1.5 1.5 0 0151.5 9zM27 19.5a1.5 1.5 0 00-3 0v32a1.5 1.5 0 003 0v-32zM14 37.5a1.5 1.5 0 00-3 0v14a1.5 1.5 0 003 0v-14zM40 28.5a1.5 1.5 0 00-3 0v23a1.5 1.5 0 003 0v-23z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartBarsARegular)
