import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeALeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.427 8.883a1.25 1.25 0 010-1.768L7.293 1.25C7.923.619 9 1.065 9 1.956V4h4.5A1.5 1.5 0 0115 5.5v5a1.5 1.5 0 01-1.5 1.5H9v2.042c0 .891-1.077 1.337-1.707.707L1.427 8.883zm.707-1.06a.25.25 0 000 .353L8 14.042V11.5a.5.5 0 01.5-.5h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-5a.5.5 0 01-.5-.5V1.956L2.134 7.822z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.274 11.435a2.031 2.031 0 010-2.872l6.828-6.827c.689-.69 1.867-.201 1.867.773v2.523h5.281c.95 0 1.719.77 1.719 1.718v6.5c0 .95-.77 1.72-1.719 1.72h-5.281v2.521c0 .975-1.178 1.463-1.867.774l-6.828-6.83zm.663-2.21a1.094 1.094 0 000 1.547l6.828 6.83a.156.156 0 00.266-.11V14.5c0-.259.21-.468.47-.468h5.749c.431 0 .781-.35.781-.782v-6.5a.781.781 0 00-.781-.78H10.5a.469.469 0 01-.469-.47V2.51a.156.156 0 00-.266-.11L2.937 9.226z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeALeftLight)
