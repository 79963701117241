export const NotFoundImage = () => {
  return (
    <svg
      width="282"
      height="249"
      viewBox="0 0 282 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.693 108.615C209.862 100.807 212.446 90.6893 212.446 78.2071C212.446 59.2915 206.013 44.17 193.036 33.0076C180.169 21.7352 158.394 16.1265 134.364 16.1265C127.161 16.1265 121.112 16.6214 115.229 17.6111C109.345 18.6009 104.176 19.7556 99.7224 21.0753C96.7531 21.9551 90.8694 24.2096 86.9103 25.8042C84.6559 26.739 83.1162 29.1035 83.1162 31.7429V53.4629C83.1162 57.9718 87.4052 61.0511 91.3093 59.2365C91.6407 59.0873 91.9717 58.9374 92.3024 58.7867C93.6039 58.1962 94.7504 57.676 95.5433 57.3675C98.6776 56.1028 102.362 55.058 106.596 54.1782C110.83 53.2984 115.559 52.8585 120.728 52.8585C132.935 52.8585 144.427 55.6079 149.486 61.0517C154.545 66.5504 157.074 73.4233 157.074 81.6714C157.074 88.8197 155.59 94.9233 152.565 99.9271C149.541 104.931 145.362 109.825 139.973 114.499C135.629 118.293 131.615 121.922 127.876 125.386C124.137 128.85 120.947 132.645 118.253 136.769C115.559 140.893 113.469 145.567 112.04 150.68C111.16 153.76 110.555 161.843 110.225 167.727C110.06 171.356 112.699 174.435 116.109 174.435H144.262C147.231 174.435 149.761 172.016 150.091 168.826C150.421 165.692 151.191 161.953 153.115 159.039C155.315 155.794 157.899 152.55 161.198 149.526C164.497 146.501 168.347 143.477 172.691 140.343C177.09 137.264 181.599 133.689 186.382 129.675C193.421 123.407 199.524 116.423 204.693 108.615ZM112.534 225.573C117.483 230.797 123.587 233.381 130.9 233.381C134.364 233.381 137.719 232.667 140.908 231.182C144.097 229.752 146.902 227.883 149.266 225.518C151.63 223.154 153.5 220.349 154.875 217.16C156.249 213.971 156.909 210.507 156.909 206.713C156.909 202.973 156.249 199.454 154.875 196.265C153.5 193.076 151.63 190.271 149.266 187.797C146.902 185.322 144.097 183.453 140.908 182.133C137.719 180.813 134.364 180.154 130.9 180.154C123.587 180.154 117.483 182.683 112.534 187.797C107.586 192.911 105.111 199.234 105.111 206.768C105.111 214.136 107.586 220.404 112.534 225.573Z"
        fill="#E9F4FF"
      />
      <path
        d="M153.335 210.122H46.9344C41.4357 210.122 36.9268 205.613 36.9268 200.114V131.16C36.9268 125.661 41.4357 121.152 46.9344 121.152H153.335C158.834 121.152 163.343 125.661 163.343 131.16V200.114C163.343 205.668 158.834 210.122 153.335 210.122Z"
        stroke="#76CA66"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.2 5.5"
      />
      <path
        d="M47.2656 134.075C48.0239 134.075 48.7512 133.774 49.2874 133.238C49.8237 132.702 50.1249 131.974 50.1249 131.216C50.1249 130.458 49.8237 129.73 49.2874 129.194C48.7512 128.658 48.0239 128.357 47.2656 128.357C46.5072 128.357 45.78 128.658 45.2437 129.194C44.7075 129.73 44.4062 130.458 44.4062 131.216C44.4062 131.974 44.7075 132.702 45.2437 133.238C45.78 133.774 46.5072 134.075 47.2656 134.075ZM56.5579 134.075C57.3162 134.075 58.0435 133.774 58.5798 133.238C59.116 132.702 59.4172 131.974 59.4172 131.216C59.4172 130.458 59.116 129.73 58.5798 129.194C58.0435 128.658 57.3162 128.357 56.5579 128.357C55.7996 128.357 55.0723 128.658 54.536 129.194C53.9998 129.73 53.6986 130.458 53.6986 131.216C53.6986 131.974 53.9998 132.702 54.536 133.238C55.0723 133.774 55.7996 134.075 56.5579 134.075ZM65.8508 134.075C66.6091 134.075 67.3364 133.774 67.8726 133.238C68.4088 132.702 68.7101 131.974 68.7101 131.216C68.7101 130.458 68.4088 129.73 67.8726 129.194C67.3364 128.658 66.6091 128.357 65.8508 128.357C65.0924 128.357 64.3651 128.658 63.8289 129.194C63.2927 129.73 62.9914 130.458 62.9914 131.216C62.9914 131.974 63.2927 132.702 63.8289 133.238C64.3651 133.774 65.0924 134.075 65.8508 134.075Z"
        fill="#76CA66"
      />
      <path
        d="M37.0918 141.442H162.958"
        stroke="#76CA66"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.2 5.5"
      />
      <path
        d="M201.229 161.623L81.9618 168.221C77.9477 168.441 74.6484 165.362 74.6484 161.348V69.2992C74.6484 65.2851 77.9477 62.2058 81.9618 62.4258L201.229 69.0242C204.309 69.1892 206.783 72.1585 206.783 75.6227V155.024C206.783 158.489 204.309 161.458 201.229 161.623Z"
        fill="#1874CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.4255 75.3478C87.2951 75.4578 88.7797 74.0281 88.7797 72.1586C88.7797 70.289 87.2951 68.6944 85.4255 68.6394C83.6109 68.5294 82.0713 69.9591 82.0713 71.8286C82.0713 73.6982 83.5559 75.2928 85.4255 75.3478ZM96.313 75.7866C98.1276 75.8416 99.6122 74.4119 99.6122 72.5974C99.6122 70.7828 98.1276 69.2431 96.313 69.1331C94.4984 69.0782 93.0138 70.5078 93.0138 72.3224C93.0138 74.137 94.4984 75.7316 96.313 75.7866ZM110.17 73.0922C110.17 74.9068 108.74 76.3365 106.981 76.2265C105.221 76.1715 103.736 74.6319 103.736 72.8173C103.736 71.0027 105.166 69.573 106.981 69.683C108.74 69.738 110.17 71.2777 110.17 73.0922ZM146.462 107.021L156.965 95.5287L166.532 106.031L156.14 117.524L166.532 128.796L156.965 139.519L146.462 128.246L135.794 140.068L125.842 129.566L136.619 117.744L125.842 106.196L135.794 95.4737L146.462 107.021Z"
        fill="white"
      />
      <path
        d="M215.745 78.0422C215.745 62.4258 203.813 57.6419 197.324 57.202C197.269 57.202 178.903 58.4667 178.903 82.1113C178.903 96.133 190.561 107.131 204.197 106.636C208.486 106.471 212.391 105.206 215.745 103.116V78.0422Z"
        fill="#67B3FF"
      />
      <path
        d="M244.944 123.737C243.349 125.607 240.765 125.772 239.17 124.177L217.945 102.732L224.159 96.0237L244.944 117.469C246.539 119.118 246.539 121.868 244.944 123.737Z"
        fill="#FF5A49"
      />
      <path
        d="M224.214 96.0775L218 102.786L220.035 104.821C221.079 105.865 222.729 105.92 223.774 104.93C224.379 104.381 224.983 103.776 225.533 103.171C225.863 102.786 226.248 102.401 226.578 102.016C227.458 100.971 227.403 99.3768 226.413 98.387L224.214 96.0775Z"
        fill="#DA2128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.862 100.752C170.215 88.9841 170.215 69.2442 182.862 57.8068C194.904 46.9193 213.16 48.4589 223.773 60.1712C233.89 71.3881 233.89 88.5997 223.773 99.5422C213.16 110.98 194.904 111.969 182.862 100.752ZM188.031 94.8122C196.609 103.06 209.916 102.62 217.779 94.3173C225.367 86.2891 225.367 73.5321 217.779 65.284C209.916 56.7609 196.609 55.9361 188.031 63.9093C179.123 72.2124 179.123 86.2891 188.031 94.8122Z"
        fill="#031B4E"
      />
      <path
        d="M58.2625 39.496C58.2625 39.2043 58.1466 38.9246 57.9404 38.7183C57.7341 38.5121 57.4544 38.3962 57.1627 38.3962C56.8711 38.3962 56.5913 38.5121 56.3851 38.7183C56.1789 38.9246 56.063 39.2043 56.063 39.496H58.2625ZM56.063 44.0599C56.063 44.3516 56.1789 44.6313 56.3851 44.8376C56.5913 45.0438 56.8711 45.1597 57.1627 45.1597C57.4544 45.1597 57.7341 45.0438 57.9404 44.8376C58.1466 44.6313 58.2625 44.3516 58.2625 44.0599H56.063ZM58.2625 53.5178C58.2625 53.2261 58.1466 52.9464 57.9404 52.7401C57.7341 52.5339 57.4544 52.418 57.1627 52.418C56.8711 52.418 56.5913 52.5339 56.3851 52.7401C56.1789 52.9464 56.063 53.2261 56.063 53.5178H58.2625ZM56.063 58.4116C56.063 58.7033 56.1789 58.983 56.3851 59.1893C56.5913 59.3955 56.8711 59.5114 57.1627 59.5114C57.4544 59.5114 57.7341 59.3955 57.9404 59.1893C58.1466 58.983 58.2625 58.7033 58.2625 58.4116H56.063ZM66.6206 50.0536C66.9122 50.0536 67.1919 49.9377 67.3982 49.7314C67.6044 49.5252 67.7203 49.2455 67.7203 48.9538C67.7203 48.6621 67.6044 48.3824 67.3982 48.1762C67.1919 47.9699 66.9122 47.8541 66.6206 47.8541V50.0536ZM62.1116 47.8541C61.8199 47.8541 61.5402 47.9699 61.334 48.1762C61.1277 48.3824 61.0118 48.6621 61.0118 48.9538C61.0118 49.2455 61.1277 49.5252 61.334 49.7314C61.5402 49.9377 61.8199 50.0536 62.1116 50.0536V47.8541ZM52.2689 50.0536C52.5605 50.0536 52.8403 49.9377 53.0465 49.7314C53.2527 49.5252 53.3686 49.2455 53.3686 48.9538C53.3686 48.6621 53.2527 48.3824 53.0465 48.1762C52.8403 47.9699 52.5605 47.8541 52.2689 47.8541V50.0536ZM47.7599 47.8541C47.4682 47.8541 47.1885 47.9699 46.9823 48.1762C46.776 48.3824 46.6602 48.6621 46.6602 48.9538C46.6602 49.2455 46.776 49.5252 46.9823 49.7314C47.1885 49.9377 47.4682 50.0536 47.7599 50.0536V47.8541ZM56.063 39.496V44.0599H58.2625V39.496H56.063ZM56.063 53.5178V58.4116H58.2625V53.5178H56.063ZM66.6206 47.8541H62.1116V50.0536H66.6206V47.8541ZM52.2689 47.8541H47.7599V50.0536H52.2689V47.8541Z"
        fill="#76CA66"
      />
    </svg>
  )
}
