import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTrashCanLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 7.5a.5.5 0 00-1 0v4a.5.5 0 001 0v-4zM7.5 7a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM10 7.5a.5.5 0 00-1 0v4a.5.5 0 001 0v-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2.75V4H1a.5.5 0 000 1h1.048l.82 8.199A2 2 0 004.857 15h5.285a2 2 0 001.99-1.801L12.953 5H14a.5.5 0 000-1h-3V2.75A1.75 1.75 0 009.25 1h-3.5A1.75 1.75 0 004 2.75zM5.75 2a.75.75 0 00-.75.75V4h5V2.75A.75.75 0 009.25 2h-3.5zm6.197 3l-.81 8.1a1 1 0 01-.995.9H4.857a1 1 0 01-.995-.9L3.052 5h8.895z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8.5 8a.5.5 0 01.5.5v6a.5.5 0 11-1 0v-6a.5.5 0 01.5-.5zM12 8.5a.5.5 0 00-1 0v6a.5.5 0 101 0v-6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5V4a2 2 0 012-2h4a2 2 0 012 2v1h3.5a.5.5 0 010 1h-1.125l-.962 9.746A2.5 2.5 0 0112.925 18h-5.85a2.5 2.5 0 01-2.488-2.254L3.625 6H2.5a.5.5 0 010-1H6zm1-1a1 1 0 011-1h4a1 1 0 011 1v1H7V4zM4.63 6l.952 9.647A1.5 1.5 0 007.075 17h5.85a1.5 1.5 0 001.493-1.353L15.37 6H4.63z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTrashCanLight)
