import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M3.49 13.274c0-.686.18-1.16.432-1.501.257-.35.628-.614 1.09-.81.946-.402 2.126-.46 2.988-.46.862 0 2.042.058 2.988.46.462.197.833.46 1.09.81.252.342.432.815.432 1.5a.75.75 0 101.5 0c0-.967-.26-1.761-.724-2.39-.457-.62-1.074-1.03-1.71-1.3-1.25-.531-2.7-.58-3.576-.58-.877 0-2.326.049-3.575.58-.636.27-1.254.68-1.711 1.3-.463.629-.724 1.423-.724 2.39a.75.75 0 001.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.956a4.55 4.55 0 100 9.1 4.55 4.55 0 000-9.1zm-3.05 4.55a3.05 3.05 0 116.1 0 3.05 3.05 0 01-6.1 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 17.246c0-.838.242-1.432.6-1.873.369-.454.901-.795 1.552-1.046 1.32-.509 2.947-.577 4.098-.577 1.151 0 2.778.068 4.098.577.65.25 1.183.592 1.552 1.046.358.44.6 1.034.6 1.873a.75.75 0 101.5 0c0-1.156-.344-2.09-.936-2.82-.583-.716-1.367-1.187-2.177-1.499-1.601-.617-3.475-.677-4.637-.677-1.163 0-3.036.06-4.637.677-.81.312-1.594.783-2.177 1.5-.592.728-.936 1.663-.936 2.819a.75.75 0 101.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 21c0-1.267.336-2.186.841-2.87.511-.69 1.234-1.193 2.086-1.553 1.727-.732 3.843-.827 5.323-.827 1.48 0 3.596.095 5.323.827.852.36 1.575.862 2.086 1.553.505.684.841 1.603.841 2.87a.75.75 0 001.5 0c0-1.551-.418-2.791-1.135-3.762-.712-.962-1.68-1.608-2.707-2.043-2.03-.859-4.413-.945-5.908-.945s-3.879.086-5.908.945c-1.026.435-1.995 1.08-2.707 2.043-.717.97-1.135 2.21-1.135 3.762a.75.75 0 001.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
        <path
          d="M5 28.5c0-1.557.439-2.684 1.102-3.525.675-.856 1.637-1.484 2.78-1.938C11.194 22.119 14.025 22 16 22c1.975 0 4.806.119 7.118 1.037 1.143.454 2.105 1.082 2.78 1.938C26.56 25.816 27 26.943 27 28.5a1 1 0 102 0c0-1.966-.566-3.539-1.532-4.764-.955-1.211-2.25-2.017-3.612-2.558C21.16 20.108 17.991 20 16 20c-1.991 0-5.16.108-7.856 1.178-1.362.54-2.657 1.347-3.612 2.558C3.566 24.961 3 26.534 3 28.5a1 1 0 102 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM20 21c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"
        fill="currentColor"
      />
      <path
        d="M10 56.5c0-3.224.893-5.593 2.267-7.372 1.389-1.799 3.35-3.097 5.634-4.023C22.513 43.235 28.13 43 32 43c3.87 0 9.487.235 14.099 2.105 2.285.926 4.245 2.224 5.634 4.023C53.107 50.907 54 53.276 54 56.5a1.5 1.5 0 003 0c0-3.822-1.074-6.852-2.892-9.206-1.804-2.335-4.262-3.907-6.882-4.969C42.03 40.218 35.896 40 32 40c-3.897 0-10.03.218-15.226 2.325-2.62 1.062-5.078 2.634-6.882 4.969C8.075 49.648 7 52.678 7 56.5a1.5 1.5 0 003 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1Regular)
