import { memo, ReactNode, useCallback } from 'react'

import BadgeCount from '@ui/BadgeCount'
import { TPositionType, TSizeType } from '@ui/Tabs/types'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './TabsHeaderItem.module.scss'

type Item = ReactNode | string | number

export interface ITabsHeaderItemProps {
  label?: Item
  onClick: () => void
  typeLabel?: string
  active: boolean
  size?: TSizeType
  disabled?: boolean
  badgeCount?: string | number
  type?: TPositionType
  classNameLabel?: string
}

enum EFontSize {
  small = 12,
  medium = 14,
  large = 16,
}

const TabsHeaderItem = ({
  label,
  onClick,
  active,
  size,
  disabled,
  typeLabel,
  badgeCount,
  type,
  classNameLabel,
}: ITabsHeaderItemProps) => {
  const handleClick = useCallback(() => {
    !disabled && onClick()
  }, [disabled])

  const classNames = cx(
    styles.headerItem,
    styles[type],
    styles[size],

    {
      [styles.active]: active && !disabled,
      [styles.disabled]: disabled,
    },
    styles.title,
  )

  const textClassNames = cx({ [styles.margin]: badgeCount })

  return (
    <div className={classNames} onClick={handleClick}>
      {typeLabel ? (
        <img src={typeLabel} className={classNameLabel} />
      ) : (
        <Typography
          component="span"
          size={EFontSize[size]}
          disabled={disabled}
          className={textClassNames}>
          {label}
        </Typography>
      )}
      {badgeCount && (
        <BadgeCount disabled={disabled} size={size} count={badgeCount} />
      )}
    </div>
  )
}

export default memo(TabsHeaderItem)
