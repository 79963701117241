import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckSquareRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.45 6.558a.75.75 0 10-1.06-1.06L7.153 8.734a.25.25 0 01-.354 0L5.522 7.458a.75.75 0 00-1.06 1.06l1.277 1.278a1.75 1.75 0 002.474 0l3.238-3.238z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1.25A3.25 3.25 0 001.25 4.5v7a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25v-7a3.25 3.25 0 00-3.25-3.25h-7zM2.75 4.5c0-.966.784-1.75 1.75-1.75h7c.966 0 1.75.784 1.75 1.75v7a1.75 1.75 0 01-1.75 1.75h-7a1.75 1.75 0 01-1.75-1.75v-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.329 7.992a.75.75 0 00-1.06-1.06l-4.175 4.173a.5.5 0 01-.707 0L6.732 9.45a.75.75 0 10-1.061 1.061l1.655 1.655a2 2 0 002.829 0l4.174-4.174z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.25A3.75 3.75 0 002.25 6v8A3.75 3.75 0 006 17.75h8A3.75 3.75 0 0017.75 14V6A3.75 3.75 0 0014 2.25H6zM3.75 6A2.25 2.25 0 016 3.75h8A2.25 2.25 0 0116.25 6v8A2.25 2.25 0 0114 16.25H6A2.25 2.25 0 013.75 14V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.187 9.492a.75.75 0 00-1.06-1.06l-5.315 5.313a.75.75 0 01-1.06 0L7.576 11.57a.75.75 0 10-1.061 1.06l2.176 2.176a2.25 2.25 0 003.182 0l5.314-5.314z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 2.25A4.25 4.25 0 002.25 6.5v11a4.25 4.25 0 004.25 4.25h11a4.25 4.25 0 004.25-4.25v-11a4.25 4.25 0 00-4.25-4.25h-11zM3.75 6.5A2.75 2.75 0 016.5 3.75h11a2.75 2.75 0 012.75 2.75v11a2.75 2.75 0 01-2.75 2.75h-11a2.75 2.75 0 01-2.75-2.75v-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23.058 12.487a1 1 0 00-1.414-1.415L14.617 18.1a1 1 0 01-1.414 0l-2.847-2.847a1 1 0 00-1.414 1.414l2.847 2.847a3 3 0 004.243 0l7.026-7.026z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 3A5.5 5.5 0 003 8.5v15A5.5 5.5 0 008.5 29h15a5.5 5.5 0 005.5-5.5v-15A5.5 5.5 0 0023.5 3h-15zM5 8.5A3.5 3.5 0 018.5 5h15A3.5 3.5 0 0127 8.5v15a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 23.5v-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M47.047 24.183a1.5 1.5 0 10-2.121-2.121L29.759 37.229a2.5 2.5 0 01-3.536 0l-6.2-6.2a1.5 1.5 0 10-2.121 2.12l6.2 6.2a5.5 5.5 0 007.778 0l15.167-15.166z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7C11.701 7 7 11.701 7 17.5v29C7 52.299 11.701 57 17.5 57h29C52.299 57 57 52.299 57 46.5v-29C57 11.701 52.299 7 46.5 7h-29zM10 17.5a7.5 7.5 0 017.5-7.5h29a7.5 7.5 0 017.5 7.5v29a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5v-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckSquareRegular)
