import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowFigureACircleRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 7.5a.5.5 0 01.5-.5h5.119l-1.94-1.616a.5.5 0 11.641-.768l2.078 1.732a1.5 1.5 0 010 2.304L8.32 10.384a.5.5 0 01-.64-.768L9.619 8H4.5a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 7.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM7.5 2a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5.5 9.5A.5.5 0 016 9h6.219l-2.54-2.116a.5.5 0 11.641-.768l2.678 2.232a1.5 1.5 0 010 2.304l-2.678 2.232a.5.5 0 01-.64-.768L12.219 10H6a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM9.5 3a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleRightLight)
