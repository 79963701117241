import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import {
  ICategoryProduct,
  ICategorysProduct,
  IProduct,
} from '../models/Products'

@singleton()
export class ProductRest extends AbstractRest {
  async getCategorysProduct(offset, limit) {
    const { data } = await this.http.get<ICategorysProduct>(
      `/lk/manufacturingTypes?offset=${offset}&limit=${limit}`,
    )

    return data
  }

  async getCategoryProduct(id: number) {
    const { data } = await this.http.get<ICategoryProduct>(
      `/lk/manufacturingTypes/${id}`,
    )

    return data
  }

  async getProduct(id: number) {
    const { data } = await this.http.get<IProduct>(`/lk/manufacturing/${id}`)

    return data
  }
}
