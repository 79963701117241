import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseTrianglesVOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.237 2.427a1.75 1.75 0 00-2.475 0L3.811 5.378C3.212 5.977 3.636 7 4.483 7h7.034c.846 0 1.27-1.023.672-1.622L9.237 2.427zm-1.414 1.06a.25.25 0 01.354 0L10.189 5.5H5.81l2.013-2.012zM9.237 13.573a1.75 1.75 0 01-2.475 0l-2.951-2.951C3.212 10.023 3.636 9 4.483 9h7.034c.846 0 1.27 1.023.672 1.622l-2.952 2.951zm-1.414-1.06a.25.25 0 00.354 0l2.012-2.013H5.81l2.013 2.013z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.591 3.53a2.25 2.25 0 00-3.182 0L5.073 6.866C4.286 7.654 4.843 9 5.957 9h8.086c1.114 0 1.671-1.346.884-2.134L11.59 3.53zM9.47 4.591a.75.75 0 011.06 0L13.44 7.5H6.56l2.91-2.909zM11.591 16.47a2.25 2.25 0 01-3.182 0l-3.336-3.336c-.787-.787-.23-2.134.884-2.134h8.086c1.114 0 1.671 1.346.884 2.134L11.59 16.47zM9.47 15.409a.75.75 0 001.06 0l2.91-2.909H6.56l2.91 2.909z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.679 1.691a2.375 2.375 0 00-3.359 0L4.824 7.187c-.992.992-.29 2.689 1.114 2.689h12.123c1.404 0 2.106-1.697 1.114-2.689l-5.496-5.495zM11.558 2.93a.625.625 0 01.883 0l5.198 5.196H6.36l5.197-5.196zM13.679 22.309a2.375 2.375 0 01-3.359 0l-5.496-5.495c-.992-.992-.29-2.689 1.114-2.689h12.123c1.404 0 2.106 1.697 1.114 2.689l-5.496 5.495zm-2.121-1.238c.244.244.64.244.883 0l5.198-5.196H6.36l5.197 5.196z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.475 2.06a3.5 3.5 0 00-4.95 0L6 9.587C4.74 10.846 5.632 13 7.414 13h17.172c1.782 0 2.674-2.154 1.414-3.414L18.475 2.06zm-3.536 1.415a1.5 1.5 0 012.122 0L24.586 11H7.414l7.525-7.525zM18.475 29.94a3.5 3.5 0 01-4.95 0L6 22.413C4.74 21.154 5.632 19 7.414 19h17.172c1.782 0 2.674 2.154 1.414 3.414l-7.525 7.525zm-3.536-1.415a1.5 1.5 0 002.122 0L24.586 21H7.414l7.525 7.525z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.889 4.767a5.5 5.5 0 00-7.778 0L12.853 20.025C10.65 22.23 12.21 26 15.328 26h33.344c3.118 0 4.68-3.77 2.474-5.975L35.89 4.767zM30.232 6.89a2.5 2.5 0 013.536 0l15.257 15.257a.5.5 0 01-.353.854H15.328a.5.5 0 01-.353-.854L30.232 6.89zM35.889 59.232a5.5 5.5 0 01-7.778 0L12.853 43.975C10.65 41.77 12.21 38 15.328 38h33.344c3.118 0 4.68 3.77 2.474 5.975L35.89 59.232zm-5.657-2.121a2.5 2.5 0 003.536 0l15.257-15.257a.5.5 0 00-.353-.854H15.328a.5.5 0 00-.353.854L30.232 57.11z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesVOpenRegular)
