import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCalendarLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.078 8.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM4.5 11.078a.578.578 0 100-1.156.578.578 0 000 1.156zM7.078 8.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM6.5 11.078a.578.578 0 100-1.156.578.578 0 000 1.156zM9.078 8.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM10.5 9.078a.578.578 0 100-1.156.578.578 0 000 1.156zM9.078 10.5a.578.578 0 11-1.156 0 .578.578 0 011.156 0zM10.5 11.078a.578.578 0 100-1.156.578.578 0 000 1.156z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1a.5.5 0 01.5.5V3h5V1.5a.5.5 0 011 0V3h.5A2.5 2.5 0 0114 5.5v6a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 011 11.5v-6A2.5 2.5 0 013.5 3H4V1.5a.5.5 0 01.5-.5zm-1 3a1.5 1.5 0 00-1.415 1h10.83A1.5 1.5 0 0011.5 4h-8zM2 6h11v5.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 11.5V6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.125 10.5a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM5.5 14.125a.625.625 0 100-1.25.625.625 0 000 1.25zM9.125 10.5a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM8.5 14.125a.625.625 0 100-1.25.625.625 0 000 1.25zM12.125 10.5a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM14.5 11.125a.625.625 0 100-1.25.625.625 0 000 1.25zM12.125 13.5a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM14.5 14.125a.625.625 0 100-1.25.625.625 0 000 1.25z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.5a.5.5 0 00-1 0V4H5a3 3 0 00-3 3v7a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3h-1V2.5a.5.5 0 00-1 0V4H7V2.5zM5 5a2 2 0 00-2 2h14a2 2 0 00-2-2H5zm-2 9V8h14v6a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCalendarLight)
