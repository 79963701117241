import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookNotepadSpiralRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-4zM7 6V5h3v1H7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 10V9H1.5a.5.5 0 010-1h.75V7H1.5a.5.5 0 010-1h.75V5H1.5a.5.5 0 010-1h.75A2.75 2.75 0 015 1.25h6A2.75 2.75 0 0113.75 4v8A2.75 2.75 0 0111 14.75H5A2.75 2.75 0 012.25 12v-1H1.5a.5.5 0 010-1h.75zm1.5-6c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25v-1h.75a.5.5 0 000-1h-.75V9h.75a.5.5 0 000-1h-.75V7h.75a.5.5 0 000-1h-.75V5h.75a.5.5 0 000-1h-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.252 5.25c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25h4.498c.69 0 1.25-.56 1.25-1.25v-2c0-.69-.56-1.25-1.25-1.25H9.252zm4.248 3H9.502v-1.5H13.5v1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 1.25A3.25 3.25 0 003.25 4.5H2A.75.75 0 002 6h1.25v1.5H2A.75.75 0 002 9h1.25v1.5H2A.75.75 0 102 12h1.25v1.5H2A.75.75 0 102 15h1.25v.5a3.25 3.25 0 003.25 3.25h8a3.25 3.25 0 003.25-3.25v-11a3.25 3.25 0 00-3.25-3.25h-8zM4.75 15v.5c0 .967.784 1.75 1.75 1.75h8a1.75 1.75 0 001.75-1.75v-11a1.75 1.75 0 00-1.75-1.75h-8A1.75 1.75 0 004.75 4.5H6A.75.75 0 016 6H4.75v1.5H6A.75.75 0 016 9H4.75v1.5H6A.75.75 0 116 12H4.75v1.5H6A.75.75 0 116 15H4.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.25 6.25a1 1 0 00-1 1v2.5a1 1 0 001 1h6.5a1 1 0 001-1v-2.5a1 1 0 00-1-1h-6.5zm.5 3v-1.5h5.5v1.5h-5.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25A3.75 3.75 0 004.25 6v.5h-1.5a.75.75 0 000 1.5h1.5v1.5h-1.5a.75.75 0 000 1.5h1.5v1.5h-1.5a.75.75 0 000 1.5h1.5v1.5h-1.5a.75.75 0 000 1.5h1.5v1A3.75 3.75 0 008 21.75h9A3.75 3.75 0 0020.75 18V6A3.75 3.75 0 0017 2.25H8zM5.75 17v1A2.25 2.25 0 008 20.25h9A2.25 2.25 0 0019.25 18V6A2.25 2.25 0 0017 3.75H8A2.25 2.25 0 005.75 6v.5h1.5a.75.75 0 010 1.5h-1.5v1.5h1.5a.75.75 0 010 1.5h-1.5v1.5h1.5a.75.75 0 010 1.5h-1.5v1.5h1.5a.75.75 0 010 1.5h-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 8a2 2 0 00-2 2v3a2 2 0 002 2h8a2 2 0 002-2v-3a2 2 0 00-2-2h-8zm0 2h8v3h-8v-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2A5.5 5.5 0 005 7.5V9H3a1 1 0 000 2h2v2H3a1 1 0 100 2h2v2H3a1 1 0 100 2h2v2H3a1 1 0 100 2h2v1.5a5.5 5.5 0 005.5 5.5h11a5.5 5.5 0 005.5-5.5v-17A5.5 5.5 0 0021.5 2h-11zM7 23v1.5a3.5 3.5 0 003.5 3.5h11a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0021.5 4h-11A3.5 3.5 0 007 7.5V9h1a1 1 0 010 2H7v2h1a1 1 0 110 2H7v2h1a1 1 0 110 2H7v2h1a1 1 0 110 2H7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 17a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-7a2.5 2.5 0 00-2.5-2.5h-17zm.5 9v-6h16v6H25z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6C15.701 6 11 10.701 11 16.5v.5H8.5a1.5 1.5 0 000 3H11v6H8.5a1.5 1.5 0 000 3H11v6H8.5a1.5 1.5 0 000 3H11v6H8.5a1.5 1.5 0 000 3H11v.5C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5v-31C53 10.701 48.299 6 42.5 6h-21zm-5 41H14v.5a7.5 7.5 0 007.5 7.5h21a7.5 7.5 0 007.5-7.5v-31A7.5 7.5 0 0042.5 9h-21a7.5 7.5 0 00-7.5 7.5v.5h2.5a1.5 1.5 0 010 3H14v6h2.5a1.5 1.5 0 010 3H14v6h2.5a1.5 1.5 0 010 3H14v6h2.5a1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookNotepadSpiralRegular)
