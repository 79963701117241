import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeopleCircle1Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 4a2.5 2.5 0 100 5.001A2.5 2.5 0 007.5 4zM6 6.5a1.5 1.5 0 113.001 0 1.5 1.5 0 01-3 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 7.5a6.5 6.5 0 1113.002 0A6.5 6.5 0 011 7.5zM7.5 2a5.5 5.5 0 00-3.732 9.542c.388-.67 1.017-1.05 1.644-1.26.757-.254 1.565-.282 2.089-.282s1.331.028 2.089.282c.626.21 1.256.59 1.643 1.26A5.5 5.5 0 007.5 2zm0 11.002a5.475 5.475 0 01-2.927-.843c.224-.472.64-.756 1.157-.93C6.326 11.03 6.998 11 7.5 11c.504 0 1.176.03 1.772.23.517.173.932.457 1.156.929a5.475 5.475 0 01-2.927.843z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5a3 3 0 100 6.002A3 3 0 0010 5zM8 8.002a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8-7a7 7 0 00-5.148 11.743 3.18 3.18 0 01.493-1.067c.39-.543.92-.907 1.477-1.15 1.099-.48 2.384-.527 3.178-.527s2.08.046 3.178.526c.558.244 1.088.607 1.477 1.15.22.308.389.663.493 1.068A7 7 0 0010 3zm0 14a6.97 6.97 0 01-4.266-1.45c.035-.56.197-.976.424-1.292.258-.36.626-.625 1.064-.816.893-.39 1.995-.443 2.778-.443.783 0 1.885.053 2.778.443.438.191.806.455 1.064.816.226.316.389.731.424 1.293A6.97 6.97 0 0110 17z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeopleCircle1Light)
