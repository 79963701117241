import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechStorageUsbRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.128 7.117a1.25 1.25 0 01-.001 1.767l-4.95 4.95a4.25 4.25 0 01-6.01-6.01l4.95-4.95a1.25 1.25 0 011.765-.002L10.827.927a1.25 1.25 0 011.768 0l2.477 2.478a1.25 1.25 0 010 1.768l-1.944 1.944zM9.943 3.933l2.124 2.124 1.768-1.768-2.124-2.124-1.768 1.768zm-6.716 8.84a2.75 2.75 0 010-3.89L8 4.112 11.89 8l-4.774 4.773a2.75 2.75 0 01-3.889 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.919 9.201l.113.114a1.5 1.5 0 010 2.121l-5.457 5.458a5.281 5.281 0 11-7.47-7.469l5.459-5.458a1.5 1.5 0 012.121 0l.114.115 2.409-2.409a1.5 1.5 0 012.121 0l2.998 2.998a1.5 1.5 0 010 2.121L15.92 9.201zm-4.06-4.059l2.999 2.998 2.409-2.408-2.999-2.998-2.408 2.408zM4.167 15.834a3.781 3.781 0 010-5.348l5.458-5.458 5.348 5.348-5.458 5.458a3.781 3.781 0 01-5.348 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.629 11.47c.732.732.732 1.92 0 2.651l-5.966 5.967a6.187 6.187 0 11-8.75-8.75L9.878 5.37a1.875 1.875 0 012.651 0l3.182-3.182a1.875 1.875 0 012.652 0l3.447 3.447c.732.732.732 1.92 0 2.652l-3.182 3.182zm-5.038-5.038l3.977 3.977 3.182-3.182a.375.375 0 000-.53L17.303 3.25a.375.375 0 00-.53 0L13.59 6.432zM4.973 19.027a4.687 4.687 0 010-6.63l5.966-5.965a.375.375 0 01.53 0l6.1 6.098a.375.375 0 010 .53l-5.967 5.967a4.688 4.688 0 01-6.629 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.125 8.751a.75.75 0 010-1.06l.707-.708a.75.75 0 111.061 1.061l-.707.707a.75.75 0 01-1.06 0zM23.249 9.814a.75.75 0 001.06 1.06l.707-.707a.75.75 0 00-1.06-1.06l-.707.707z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.839 15.293a2.5 2.5 0 010 3.535l-7.955 7.955A8.25 8.25 0 015.216 15.116l7.955-7.955a2.5 2.5 0 013.536 0L20.95 2.92a2.5 2.5 0 013.535 0l4.596 4.596a2.5 2.5 0 010 3.535l-4.242 4.243zM18.12 8.575l5.303 5.304 4.243-4.243a.5.5 0 000-.707l-4.596-4.596a.5.5 0 00-.707 0L18.12 8.575zM6.631 25.37a6.25 6.25 0 010-8.839l7.955-7.955a.5.5 0 01.707 0l8.131 8.132a.5.5 0 010 .707L15.47 25.37a6.25 6.25 0 01-8.84 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M35.89 12.555a1.5 1.5 0 002.12 2.12l6.364-6.363a1.5 1.5 0 012.122 0l9.192 9.192a1.5 1.5 0 010 2.122l-6.364 6.364a1.5 1.5 0 102.122 2.12l6.364-6.363a4.5 4.5 0 000-6.364l-9.193-9.192a4.5 4.5 0 00-6.364 0l-6.364 6.364z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.787 30.586c-6.249 6.248-6.249 16.379 0 22.627 6.248 6.249 16.379 6.249 22.627 0l15.91-15.91a4.5 4.5 0 000-6.364L33.061 14.676a4.5 4.5 0 00-6.364 0l-15.91 15.91zm2.121 20.506c-5.077-5.077-5.077-13.308 0-18.385l15.91-15.91a1.5 1.5 0 012.121 0l16.264 16.264a1.5 1.5 0 010 2.121l-15.91 15.91c-5.077 5.077-13.308 5.077-18.385 0z"
        fill="currentColor"
      />
      <path
        d="M42.253 16.797a1.5 1.5 0 010-2.121l1.414-1.414a1.5 1.5 0 012.122 2.121l-1.415 1.414a1.5 1.5 0 01-2.12 0zM47.203 19.626a1.5 1.5 0 102.121 2.12l1.414-1.413a1.5 1.5 0 10-2.12-2.122l-1.415 1.415z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechStorageUsbRegular)
