import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBlockRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 100 14A7 7 0 008 1zM4.677 3.617a5.5 5.5 0 017.706 7.706L4.677 3.617zm-1.06 1.06a5.5 5.5 0 007.706 7.706L3.617 4.677z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zM5.966 4.905a6.499 6.499 0 019.13 9.13l-9.13-9.13zm-1.06 1.06a6.499 6.499 0 009.13 9.13l-9.13-9.13z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM6.72 5.66a8.25 8.25 0 0111.62 11.62L6.72 5.66zM5.66 6.72a8.25 8.25 0 0011.62 11.62L5.66 6.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM8.96 7.547A10.955 10.955 0 0116 5c6.075 0 11 4.925 11 11 0 2.677-.957 5.132-2.547 7.04L8.961 7.546zM7.548 8.96A10.956 10.956 0 005 16c0 6.075 4.925 11 11 11 2.677 0 5.131-.957 7.039-2.547L7.547 8.961z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zm-15.168 8.71A22.912 22.912 0 0132 9c12.703 0 23 10.297 23 23 0 5.811-2.155 11.12-5.71 15.168L16.832 14.71zm-2.122 2.121A22.912 22.912 0 009 32c0 12.703 10.297 23 23 23 5.812 0 11.12-2.155 15.169-5.71L14.71 16.83z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBlockRegular)
