import { useEffect } from 'react'

import { orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'

import { HeaderItem } from '@ui/Table/types'

import PageWrapper from '@lib/components/Page/PageWrapper'

import TableOrders from '../components/TableOrders'
import { useConfiguratorStore } from '../store/ConfiguratorStore'

const header: HeaderItem[] = [
  {
    align: 'left',
    data: 'номер заказа',
  },
  {
    data: 'дата создания',
  },
  {
    data: 'статус',
  },
  {
    data: 'сумма',
  },
  {
    data: 'детальная информация',
  },
]

const ConfiguratorOrdersPage = () => {
  const configuratorStore = useConfiguratorStore()

  useEffect(() => {
    configuratorStore.getOrders()
  }, [])

  return (
    <PageWrapper
      title="История заказов"
      isLoading={
        configuratorStore.isFetching && !configuratorStore.orders.length
      }>
      <TableOrders
        header={header}
        orders={orderBy(configuratorStore.orders, ['created_at'], ['desc'])}
      />
    </PageWrapper>
  )
}

export default observer(ConfiguratorOrdersPage)
