import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconQuestionCircleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 10.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6.012 5.838c0-.646.61-1.267 1.49-1.267s1.49.621 1.49 1.267c0 .477-.32.93-.851 1.145C7.53 7.231 7 7.718 7 8.489a.5.5 0 001 0c0-.215.123-.42.516-.58.842-.342 1.475-1.12 1.475-2.07 0-1.307-1.172-2.268-2.49-2.268-1.318 0-2.49.96-2.49 2.267a.5.5 0 001 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 7.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM7.5 2a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.25 13.307a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.625 7.578C7.625 6.768 8.399 6 9.5 6c1.1 0 1.875.767 1.875 1.578 0 .38-.11.617-.257.796-.161.196-.39.35-.684.522l-.12.07c-.248.143-.548.317-.783.534A1.57 1.57 0 009 10.695a.5.5 0 101 0c0-.202.07-.331.211-.462.146-.135.333-.244.587-.391l.143-.084c.3-.176.665-.404.949-.748.298-.362.485-.825.485-1.432C12.375 6.094 11.023 5 9.5 5S6.625 6.094 6.625 7.578a.5.5 0 101 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM9.5 3a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconQuestionCircleLight)
