import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyTypeCoinsRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.563c-1.649 0-3.18.348-4.325.946-1.109.58-2.05 1.51-2.05 2.742 0 1.23.941 2.162 2.05 2.742 1.146.598 2.676.945 4.325.945 1.65 0 3.18-.347 4.325-.945 1.109-.58 2.05-1.511 2.05-2.742s-.941-2.163-2.05-2.742C11.18 1.91 9.649 1.563 8 1.563zM3.125 5.251c0-.391.318-.929 1.245-1.413.89-.465 2.173-.775 3.63-.775 1.458 0 2.74.31 3.63.775.927.484 1.245 1.022 1.245 1.413 0 .39-.318.928-1.245 1.412-.89.465-2.172.775-3.63.775-1.457 0-2.74-.31-3.63-.775-.927-.484-1.245-1.021-1.245-1.412z"
          fill="currentColor"
        />
        <path
          d="M2.096 8.618a.65.65 0 01.872.292c.172.344.69.769 1.63 1.108.907.327 2.091.532 3.402.532 1.31 0 2.495-.205 3.402-.532.94-.34 1.458-.764 1.63-1.108a.65.65 0 111.163.58c-.399.8-1.313 1.376-2.352 1.75-1.07.387-2.407.61-3.843.61s-2.773-.223-3.843-.61c-1.039-.374-1.954-.95-2.352-1.75a.65.65 0 01.291-.872z"
          fill="currentColor"
        />
        <path
          d="M2.096 11.478a.65.65 0 01.872.292c.172.345.69.769 1.63 1.108.907.327 2.091.532 3.402.532 1.31 0 2.495-.205 3.402-.532.94-.34 1.458-.764 1.63-1.108a.65.65 0 111.163.58c-.399.8-1.313 1.376-2.352 1.75-1.07.387-2.407.61-3.843.61s-2.773-.223-3.843-.61c-1.039-.374-1.954-.95-2.352-1.75a.65.65 0 01.291-.872z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.142c-2.037 0-3.92.43-5.319 1.16C3.317 4.014 2.22 5.13 2.22 6.563c0 1.434 1.098 2.55 2.462 3.262 1.4.73 3.282 1.16 5.32 1.16 2.037 0 3.918-.43 5.318-1.16 1.364-.712 2.462-1.828 2.462-3.262s-1.098-2.549-2.462-3.26C13.92 2.57 12.038 2.141 10 2.141zM3.72 6.563c0-.593.475-1.314 1.656-1.931 1.145-.598 2.78-.99 4.625-.99 1.846 0 3.48.392 4.625.99 1.18.617 1.656 1.338 1.656 1.931 0 .594-.475 1.315-1.656 1.932-1.145.598-2.78.99-4.625.99-1.846 0-3.48-.392-4.625-.99-1.18-.617-1.656-1.338-1.656-1.932z"
          fill="currentColor"
        />
        <path
          d="M2.679 10.252a.75.75 0 01.99.381c.241.546.924 1.148 2.085 1.62C6.887 12.712 8.365 13 10 13c1.634 0 3.113-.287 4.246-.748 1.16-.471 1.844-1.073 2.085-1.619a.75.75 0 011.372.609c-.472 1.063-1.581 1.867-2.892 2.4-1.339.544-3.013.858-4.811.858s-3.472-.314-4.81-.858c-1.312-.532-2.421-1.337-2.893-2.4a.75.75 0 01.382-.99z"
          fill="currentColor"
        />
        <path
          d="M2.679 13.69a.75.75 0 01.99.381c.241.545.924 1.147 2.085 1.619 1.133.46 2.611.748 4.246.748 1.634 0 3.113-.288 4.246-.748 1.16-.472 1.844-1.074 2.085-1.619a.75.75 0 011.372.608c-.472 1.064-1.581 1.868-2.892 2.4-1.339.544-3.013.858-4.811.858s-3.472-.314-4.81-.858c-1.312-.532-2.421-1.336-2.893-2.4a.75.75 0 01.382-.99z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.72c-2.426 0-4.659.512-6.313 1.375-1.618.846-2.874 2.144-2.874 3.781s1.256 2.935 2.874 3.78c1.654.864 3.887 1.376 6.313 1.376 2.426 0 4.66-.512 6.314-1.376 1.618-.845 2.874-2.143 2.874-3.78s-1.256-2.935-2.874-3.78C16.659 3.231 14.426 2.72 12 2.72zM4.313 7.876c0-.797.633-1.701 2.068-2.45 1.4-.732 3.385-1.206 5.62-1.206 2.233 0 4.219.474 5.618 1.205 1.436.75 2.069 1.654 2.069 2.451 0 .797-.633 1.701-2.069 2.45-1.4.732-3.385 1.206-5.619 1.206s-4.22-.474-5.619-1.205c-1.435-.75-2.068-1.654-2.068-2.451z"
          fill="currentColor"
        />
        <path
          d="M3.275 12.44a.75.75 0 01.99.381c.313.706 1.175 1.449 2.583 2.02 1.38.562 3.174.909 5.152.909 1.978 0 3.771-.348 5.152-.908 1.408-.572 2.27-1.315 2.583-2.021a.75.75 0 111.371.608c-.543 1.225-1.831 2.17-3.39 2.803-1.585.643-3.574 1.018-5.716 1.018-2.142 0-4.13-.375-5.716-1.018-1.559-.633-2.847-1.578-3.39-2.803a.75.75 0 01.381-.99z"
          fill="currentColor"
        />
        <path
          d="M3.275 16.564a.75.75 0 01.99.382c.313.706 1.175 1.449 2.583 2.02 1.38.561 3.174.909 5.152.909 1.978 0 3.771-.348 5.152-.908 1.408-.572 2.27-1.315 2.583-2.021a.75.75 0 111.371.608c-.543 1.225-1.831 2.17-3.39 2.803-1.585.643-3.574 1.018-5.716 1.018-2.142 0-4.13-.375-5.716-1.018-1.559-.633-2.847-1.578-3.39-2.803a.75.75 0 01.381-.99z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3.626c-3.234 0-6.212.683-8.418 1.835C5.425 6.588 3.75 8.319 3.75 10.5c0 2.183 1.675 3.914 3.832 5.041 2.206 1.152 5.184 1.835 8.418 1.835 3.235 0 6.212-.683 8.418-1.835 2.158-1.127 3.832-2.858 3.832-5.04 0-2.183-1.674-3.914-3.832-5.041C22.212 4.309 19.235 3.626 16 3.626zM5.75 10.502c0-1.063.844-2.269 2.758-3.268 1.866-.975 4.513-1.608 7.492-1.608 2.98 0 5.626.633 7.492 1.608 1.914 1 2.758 2.205 2.758 3.268 0 1.062-.844 2.268-2.758 3.267-1.866.975-4.513 1.607-7.492 1.607s-5.626-.632-7.492-1.607c-1.914-1-2.758-2.205-2.758-3.268z"
          fill="currentColor"
        />
        <path
          d="M4.367 16.586a1 1 0 011.32.509c.417.941 1.567 1.932 3.444 2.694C10.97 20.537 13.363 21 16 21c2.637 0 5.028-.463 6.87-1.211 1.876-.762 3.026-1.753 3.443-2.694a1 1 0 111.829.81c-.724 1.633-2.442 2.893-4.52 3.737C21.508 22.501 18.856 23 16 23s-5.508-.5-7.622-1.358c-2.078-.844-3.796-2.104-4.52-3.737a1 1 0 01.51-1.32z"
          fill="currentColor"
        />
        <path
          d="M4.367 22.086a1 1 0 011.32.509c.417.941 1.567 1.932 3.444 2.694 1.84.748 4.232 1.211 6.869 1.211 2.637 0 5.028-.463 6.87-1.211 1.876-.762 3.026-1.753 3.443-2.694a1 1 0 111.829.81c-.724 1.633-2.442 2.893-4.52 3.737-2.114.859-4.766 1.358-7.622 1.358s-5.508-.5-7.622-1.358c-2.078-.844-3.796-2.104-4.52-3.737a1 1 0 01.51-1.32z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 7.753c-6.404 0-12.277 1.352-16.604 3.612C11.142 13.586 8 16.918 8 21.003c0 4.084 3.142 7.416 7.396 9.638 4.327 2.26 10.2 3.612 16.605 3.612 6.404 0 12.277-1.353 16.604-3.612C52.859 28.419 56 25.087 56 21.003c0-4.085-3.142-7.417-7.396-9.638-4.327-2.26-10.2-3.612-16.604-3.612zm-21 13.25c0-2.405 1.896-4.948 5.785-6.98 3.817-1.992 9.194-3.27 15.216-3.27 6.021 0 11.398 1.277 15.215 3.27 3.89 2.032 5.785 4.575 5.785 6.98 0 2.404-1.896 4.948-5.785 6.979-3.817 1.993-9.194 3.27-15.215 3.27-6.022 0-11.4-1.277-15.216-3.27C12.895 25.95 11 23.407 11 21.002z"
        fill="currentColor"
      />
      <path
        d="M8.937 33.629a1.5 1.5 0 011.98.763c.91 2.056 3.352 4.104 7.156 5.65C21.823 41.563 26.672 42.5 32 42.5c5.329 0 10.176-.936 13.926-2.459 3.805-1.545 6.246-3.593 7.158-5.649a1.5 1.5 0 012.742 1.216c-1.371 3.093-4.665 5.545-8.77 7.213-4.16 1.69-9.4 2.679-15.056 2.679-5.657 0-10.895-.99-15.055-2.68-4.106-1.667-7.4-4.119-8.771-7.212a1.5 1.5 0 01.763-1.98z"
        fill="currentColor"
      />
      <path
        d="M8.937 44.629a1.5 1.5 0 011.98.763c.91 2.056 3.352 4.104 7.156 5.65C21.823 52.563 26.672 53.5 32 53.5c5.329 0 10.176-.936 13.926-2.459 3.805-1.545 6.246-3.593 7.158-5.649a1.5 1.5 0 012.742 1.216c-1.371 3.093-4.665 5.545-8.77 7.213-4.16 1.69-9.4 2.679-15.056 2.679-5.657 0-10.895-.99-15.055-2.68-4.106-1.667-7.4-4.119-8.771-7.212a1.5 1.5 0 01.763-1.98z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyTypeCoinsRegular)
