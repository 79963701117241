import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeTriangleADownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.841 4a1.25 1.25 0 011.056 1.919l-3.841 6.065a1.25 1.25 0 01-2.112 0L3.102 5.92A1.25 1.25 0 014.158 4h7.683zm-.454 1.5H4.613L8 10.848 11.387 5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.62 6a1.35 1.35 0 011.118 2.106l-4.62 6.83a1.35 1.35 0 01-2.236 0l-4.62-6.83A1.35 1.35 0 015.38 6h9.24zm-.283 1.5H5.663L10 13.912 14.337 7.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.564 5.25c1.386 0 2.222 1.535 1.47 2.7L13.47 18.107a1.75 1.75 0 01-2.94 0L3.967 7.95c-.752-1.165.084-2.7 1.47-2.7h13.127zm.21 1.886a.25.25 0 00-.21-.386H5.437a.25.25 0 00-.21.386l6.563 10.157a.25.25 0 00.42 0l6.563-10.157z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3 7c2.173 0 3.488 2.404 2.314 4.234l-9.299 14.507c-1.082 1.688-3.548 1.688-4.63 0l-9.3-14.507C3.212 9.404 4.526 7 6.7 7h18.6zm.63 3.155A.75.75 0 0025.3 9H6.7a.75.75 0 00-.631 1.155l9.3 14.507a.75.75 0 001.262 0l9.3-14.507z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.673 13c4.352 0 6.98 4.814 4.627 8.474L36.627 52.077c-2.165 3.368-7.088 3.368-9.253 0L7.7 21.474C5.347 17.814 7.975 13 12.327 13h39.346zm2.103 6.852c1.07-1.664-.125-3.852-2.103-3.852H12.328c-1.978 0-3.173 2.188-2.103 3.852l19.673 30.603c.984 1.53 3.222 1.53 4.206 0l19.673-30.603z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleADownRegular)
