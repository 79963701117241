import React, { memo, useMemo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import { DROP_LIST_COLORS, DROP_LIST_SIZES } from '../constants'
import { IDropListItem } from '../types'

import styles from './DropItemList.module.scss'

const DropItemList: React.FC<IDropListItem> = ({
  label,
  comment,
  IconLeft,
  size = 'medium',
  disabled = false,
  onClick,
  color = 'default',
}) => {
  const classItemNames = cx(styles[size], styles.item)

  const sizes = useMemo(() => DROP_LIST_SIZES[size], [size])

  return (
    <button onClick={onClick} disabled={disabled} className={classItemNames}>
      <div className={styles.label}>
        {IconLeft && IconLeft !== 'indent' && (
          <IconLeft
            className={styles.icon}
            color={disabled ? COLORS.GRAY.GRAY_2 : DROP_LIST_COLORS[color]}
            size={sizes.icon}
          />
        )}
        <Typography
          className={cx({ [styles.indent]: IconLeft === 'indent' })}
          size={sizes.text}
          color={disabled ? COLORS.GRAY.GRAY_2 : DROP_LIST_COLORS[color]}>
          {label}
        </Typography>
      </div>
      {comment && (
        <Typography
          className={cx({ [styles.indent]: IconLeft })}
          size={sizes.text}
          color={COLORS.GRAY.GRAY_2}>
          {comment}
        </Typography>
      )}
    </button>
  )
}

export default memo(DropItemList)
