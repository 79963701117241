import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyCurrencyRubleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 9.75h-3v1.5H10a.75.75 0 110 1.5H6.75V14a.75.75 0 01-1.5 0v-1.25h-1.5a.75.75 0 010-1.5h1.5v-1.5h-1.5a.75.75 0 010-1.5h1.5V3.5c0-.69.56-1.25 1.25-1.25h3.25a3.75 3.75 0 110 7.5zm-3-1.5v-4.5h3a2.25 2.25 0 110 4.5h-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 11.75H7.75v1.5H12a.75.75 0 110 1.5H7.75V17a.75.75 0 01-1.5 0v-2.25h-1.5a.75.75 0 010-1.5h1.5v-1.5h-1.5a.75.75 0 010-1.5h1.5V3.5c0-.69.56-1.25 1.25-1.25H12a4.75 4.75 0 110 9.5zm-4.25-1.5v-6.5H12a3.25 3.25 0 010 6.5H7.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 3.25a1.5 1.5 0 00-1.5 1.5v8.5H5a.75.75 0 000 1.5h2.25v2.5H5a.75.75 0 000 1.5h2.25V21a.75.75 0 001.5 0v-2.25H14a.75.75 0 000-1.5H8.75v-2.5H14a5.75 5.75 0 000-11.5H8.75zm5.25 10H8.75v-8.5H14a4.25 4.25 0 010 8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4a2 2 0 00-2 2v11H7a1 1 0 100 2h3v3H7a1 1 0 100 2h3v3.5a1 1 0 102 0V24h7a1 1 0 100-2h-7v-3h6.5a7.5 7.5 0 000-15H12zm6.5 13H12V6h6.5a5.5 5.5 0 110 11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 39H23v6h13.5a1.5 1.5 0 010 3H23v7.5a1.5 1.5 0 01-3 0V48h-5.5a1.5 1.5 0 010-3H20v-6h-5.5a1.5 1.5 0 010-3H20V12.136A4.136 4.136 0 0124.136 8H36.5C45.06 8 52 14.94 52 23.5 52 32.06 45.06 39 36.5 39zM23 12.136c0-.627.509-1.136 1.136-1.136H36.5C43.404 11 49 16.596 49 23.5S43.404 36 36.5 36H23V12.136z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleRegular)
