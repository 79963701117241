import { memo } from 'react'

import { cx } from '@lib/styles'

import styles from './Table.module.scss'
import { ITableProps } from './types'

const Table = ({
  header,
  headerWithoutStyle = false,
  children,
  className,
}: ITableProps) => (
  <div className={cx(styles.wrapper, className)}>
    <table className={styles.table}>
      {header && (
        <thead
          className={cx({
            [styles.header]: !headerWithoutStyle,
          })}>
          <tr>
            {header.map((item, index) => {
              return (
                <th key={index} align={item.align}>
                  {item.data}
                </th>
              )
            })}
          </tr>
        </thead>
      )}

      <tbody>{children}</tbody>
    </table>
  </div>
)

export default memo(Table)
