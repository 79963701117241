import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSearchLoupeMinusLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M4.5 7a.5.5 0 000 1h4a.5.5 0 000-1h-4z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.751 7.5a5.749 5.749 0 1110.152 3.696l2.706 2.705a.5.5 0 01-.707.707l-2.706-2.705A5.749 5.749 0 01.751 7.5zm5.75-4.749a4.749 4.749 0 100 9.498 4.749 4.749 0 000-9.498z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path d="M6.5 9a.5.5 0 100 1h6a.5.5 0 000-1h-6z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.256 9.504a7.248 7.248 0 1112.714 4.76l3.376 3.375a.5.5 0 11-.708.707l-3.375-3.376A7.248 7.248 0 012.256 9.504zm7.248-6.248a6.248 6.248 0 100 12.496 6.248 6.248 0 000-12.496z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSearchLoupeMinusLight)
