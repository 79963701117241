import { observer } from 'mobx-react-lite'

import { Modal } from '@ui/Modal'
import { Typography } from '@ui/Typography'

import { IInfo } from '@modules/products/models/Products'

import styles from './styles.module.scss'

interface IProductsModalProps extends Omit<IInfo, 'value'> {
  handleCloseModal: () => void
  isShowModal: boolean
}

const ProductsModal = ({
  label,
  tooltip,
  handleCloseModal,
  isShowModal,
}: IProductsModalProps) => (
  <Modal
    title={label}
    onClose={handleCloseModal}
    open={isShowModal}
    className={styles.modal}
    content={
      <Typography size={16}>{tooltip ? tooltip : 'Нет данных'}</Typography>
    }
  />
)

export default observer(ProductsModal)
