import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { IUser } from '@modules/user/models/User'

@singleton()
export class CompanyManagerStore {
  manager?: IUser = undefined

  constructor() {
    makeAutoObservable(this)
  }

  setManager(data?: IUser) {
    this.manager = data
  }

  clear() {
    this.setManager(undefined)
  }
}

export const useCompanyManagerStore = () =>
  container.resolve(CompanyManagerStore)
