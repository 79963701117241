import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoOkLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.789A3.086 3.086 0 108 7.96 3.086 3.086 0 008 1.79zM6.164 4.875a1.836 1.836 0 113.673 0 1.836 1.836 0 01-3.673 0z"
          fill="currentColor"
        />
        <path
          d="M11.752 8.44a.625.625 0 01-.109.877c-.853.665-1.85 1.107-2.928 1.244l2.583 2.584a.625.625 0 11-.884.883L8 11.614l-2.414 2.414a.625.625 0 11-.884-.883l2.584-2.584c-1.079-.137-2.075-.58-2.929-1.244a.625.625 0 11.768-.986C5.967 8.986 6.953 9.356 8 9.356c1.048 0 2.033-.37 2.875-1.025a.625.625 0 01.877.11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79 6.677c-.03-1.24.982-2.23 2.223-2.23 1.225 0 2.223.998 2.223 2.223a2.227 2.227 0 01-2.223 2.224A2.227 2.227 0 017.79 6.677zm2.223-1.23c-.706 0-1.241.555-1.224 1.21v.013c0 .673.55 1.224 1.224 1.224.672 0 1.223-.55 1.223-1.224 0-.672-.55-1.223-1.223-1.223z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.317 6.67C5.317 4.095 7.416 2 10.013 2a4.678 4.678 0 014.67 4.67c0 1.053-.35 2.026-.941 2.808.47.078.904.348 1.17.783.52.805.28 1.89-.533 2.389a8.093 8.093 0 01-1.718.816l1.758 1.702c.67.67.67 1.76 0 2.43-.67.67-1.76.67-2.43 0l-1.944-1.944L8.1 17.598a1.793 1.793 0 01-1.228.496c-.437 0-.87-.139-1.228-.495-.67-.67-.67-1.761 0-2.43l1.723-1.723a8.096 8.096 0 01-1.733-.821c-.811-.497-1.047-1.546-.596-2.344a1.721 1.721 0 011.225-.798 4.635 4.635 0 01-.947-2.813zm1.593 3.908a.744.744 0 00-1.008.21.745.745 0 00.257.985l.008.005a7.39 7.39 0 002.269.934.5.5 0 01.24.84l-2.323 2.323a.72.72 0 000 1.016.687.687 0 00.52.204.79.79 0 00.53-.214l2.288-2.287a.5.5 0 01.707 0l2.298 2.297c.279.28.736.28 1.016 0m0 0a.721.721 0 00.002-1.013l-2.369-2.295a.5.5 0 01.233-.845 7.392 7.392 0 002.27-.935l.007-.005c.33-.2.439-.656.216-.998l-.008-.013a.716.716 0 00-.998-.216l-.006.004a5.803 5.803 0 01-6.15.003M10.014 3C7.964 3 6.317 4.651 6.317 6.67c0 2.02 1.647 3.67 3.696 3.67 2.021 0 3.67-1.648 3.67-3.67 0-2.02-1.649-3.67-3.67-3.67z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoOkLight)
