import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconShapeTriangleUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.313 2.304a2 2 0 013.374 0l5.492 8.631c.847 1.332-.11 3.074-1.688 3.074H2.51c-1.578 0-2.535-1.742-1.688-3.074l5.492-8.63zm2.53.537a1 1 0 00-1.687 0l-5.491 8.631a1 1 0 00.844 1.537H13.49a1 1 0 00.844-1.537L8.844 2.841z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32 2.927a2 2 0 013.36 0l6.464 10c.86 1.332-.095 3.087-1.68 3.087H3.536c-1.585 0-2.54-1.755-1.68-3.086l6.464-10zm2.52.543a1 1 0 00-1.68 0l-6.464 10a1 1 0 00.84 1.543h12.928a1 1 0 00.84-1.542l-6.464-10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconShapeTriangleUpLight)
