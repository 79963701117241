import React, { FC, memo } from 'react'

import Button from '@ui/Button'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { EPropsBanner } from '@modules/home/models/Banners'

import styles from './styles.module.scss'

const Banner: FC<EPropsBanner> = ({ title, url, buttonText, imageUrl }) => (
  <div
    className={styles['solutions-card']}
    style={{ backgroundImage: `url(${imageUrl})` }}>
    <Typography size={32} color={COLORS.GRAY.GANDALF}>
      {title}
    </Typography>
    {buttonText && (
      <Button className={styles.button} type="fill" href={url}>
        {buttonText}
      </Button>
    )}
  </div>
)

export default memo(Banner)
