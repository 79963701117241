import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookNotepadLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 4a.5.5 0 000 1h3a.5.5 0 000-1h-3zM7 6.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM7.5 8a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 14h6a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0010.5 1h-6A2.5 2.5 0 002 3.5v8A2.5 2.5 0 004.5 14zm0-12A1.5 1.5 0 003 3.5v8A1.5 1.5 0 004.5 13H5V2h-.5zM6 2v11h4.5a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0010.5 2H6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9 6a.5.5 0 000 1h4a.5.5 0 000-1H9zM8.5 8.5A.5.5 0 019 8h4a.5.5 0 010 1H9a.5.5 0 01-.5-.5zM9 10a.5.5 0 000 1h4a.5.5 0 000-1H9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2a3 3 0 013 3v10a3 3 0 01-3 3H6a3 3 0 01-3-3V5a3 3 0 013-3h7zM4 5a2 2 0 012-2v14a2 2 0 01-2-2V5zm3-2v14h6a2 2 0 002-2V5a2 2 0 00-2-2H7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookNotepadLight)
