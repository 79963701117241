import { CommonIconSize } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { TypographySizeType } from '@ui/Typography'

import { DropItemTSizeType } from './types'

export const DROP_LIST_SIZES: Record<
  DropItemTSizeType,
  { text: TypographySizeType; title: TypographySizeType; icon: CommonIconSize }
> = {
  small: {
    text: 14,
    title: 12,
    icon: 16,
  },
  medium: { text: 16, title: 14, icon: 20 },
  // @ts-expect-error (Light icons has no 24px size)
  large: { text: 18, title: 16, icon: 24 },
}

export const DROP_LIST_COLORS = {
  default: COLORS.GRAY.SAURON,
  danger: COLORS.STATE.ATTENTION,
}
