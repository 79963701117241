import React, { memo, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import Loader from '@ui/Loader'

import { cx } from '@lib/styles'

import { CommonIconSize } from '../icons/types'

import styles from './styles.module.scss'
import { ButtonProps, ButtonSize } from './types'

const ICON_SIZE_MAP: Record<ButtonSize, CommonIconSize> = {
  small: 16,
  medium: 20,
  // @ts-expect-error, when light case, there is will be 20px
  large: 24,
}

const Button = ({
  type = 'primary',
  size = 'medium',
  className,
  disabled = false,
  fullSize = false,
  onClick,
  withBorder = false,
  IconLeft,
  IconRight,
  loading,
  linkTo,
  href,
  children,
  download,
  noWrap,
  ...restProps
}: ButtonProps) => {
  const isDisabled = disabled || loading
  const buttonClassNames = cx(
    [styles.button],
    [styles[type]],
    [styles[size]],
    {
      [styles.withBorder]: withBorder,
      [styles.fullSize]: fullSize,
      [styles.noWrap]: noWrap,
    },
    className,
  )

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick?.()
  }

  if (href !== undefined) {
    return (
      <a
        href={href}
        download={download}
        className={cx(buttonClassNames, styles.linkDefault)}
        target="_blank"
        rel="noopener noreferrer">
        {IconLeft && (
          <IconLeft className={styles.iconLeft} size={ICON_SIZE_MAP[size]} />
        )}
        {children}
        {IconRight && (
          <IconRight className={styles.iconRight} size={ICON_SIZE_MAP[size]} />
        )}
      </a>
    )
  } else if (linkTo !== undefined) {
    return (
      <Link
        className={cx(buttonClassNames, styles.linkDefault)}
        to={linkTo}
        download={download}>
        {IconLeft && (
          <IconLeft className={styles.iconLeft} size={ICON_SIZE_MAP[size]} />
        )}
        {children}
        {IconRight && (
          <IconRight className={styles.iconRight} size={ICON_SIZE_MAP[size]} />
        )}
      </Link>
    )
  }

  const renderLeftIcon = () => {
    if (IconLeft) {
      return loading ? (
        <Loader className={styles.iconLeft} size={ICON_SIZE_MAP[size]} />
      ) : (
        <IconLeft className={styles.iconLeft} size={ICON_SIZE_MAP[size]} />
      )
    }
  }

  const renderRightIcon = () => {
    if (IconRight) {
      return loading ? (
        <Loader className={styles.iconRight} size={ICON_SIZE_MAP[size]} />
      ) : (
        <IconRight className={styles.iconRight} size={ICON_SIZE_MAP[size]} />
      )
    }
  }

  const renderContent = () => {
    if (loading && !IconLeft && !IconRight) {
      return (
        <>
          <Loader className={styles.iconLeft} size={ICON_SIZE_MAP[size]} />
          {children}
        </>
      )
    }
    return children
  }

  return (
    <button
      className={buttonClassNames}
      onClick={handleClick}
      disabled={isDisabled}
      {...restProps}>
      {renderLeftIcon()}
      {renderContent()}
      {renderRightIcon()}
    </button>
  )
}

export default memo(Button)
