import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSettingsSlidersH3Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.75h6.484a2.15 2.15 0 004.032 0H14a.75.75 0 000-1.5h-1.484a2.15 2.15 0 00-4.032 0H2a.75.75 0 100 1.5zm8.5-1.6a.85.85 0 100 1.7.85.85 0 000-1.7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7.25a.75.75 0 100 1.5h1.484a2.15 2.15 0 004.032 0H14a.75.75 0 000-1.5H7.516a2.15 2.15 0 00-4.032 0H2zM4.65 8a.85.85 0 111.7 0 .85.85 0 01-1.7 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 12a.75.75 0 01.75-.75h6.484a2.15 2.15 0 014.032 0H14a.75.75 0 010 1.5h-1.484a2.15 2.15 0 01-4.032 0H2a.75.75 0 01-.75-.75zm8.4 0a.85.85 0 111.7 0 .85.85 0 01-1.7 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.854 4.25H2.75a.75.75 0 000 1.5h8.104a2.751 2.751 0 005.293 0h1.103a.75.75 0 000-1.5h-1.104a2.751 2.751 0 00-5.293 0zM12.25 5a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM2.75 9.25a.75.75 0 000 1.5h1.104a2.751 2.751 0 005.292 0h8.104a.75.75 0 000-1.5H9.146a2.751 2.751 0 00-5.292 0H2.75zm5 .75a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM2 15a.75.75 0 01.75-.75h8.104a2.751 2.751 0 015.293 0h1.103a.75.75 0 010 1.5h-1.104a2.751 2.751 0 01-5.293 0H2.75A.75.75 0 012 15zm11.5-1.25a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.845 4.25H3a.75.75 0 000 1.5h9.845a3.001 3.001 0 005.81 0H21a.75.75 0 000-1.5h-2.345a3.001 3.001 0 00-5.81 0zM14.25 5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM3 11.25a.75.75 0 000 1.5h2.345a3.001 3.001 0 005.81 0H21a.75.75 0 000-1.5h-9.845a3.001 3.001 0 00-5.81 0H3zm5.25-.75a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM2.25 19a.75.75 0 01.75-.75h9.845a3.001 3.001 0 015.81 0H21a.75.75 0 010 1.5h-2.345a3.001 3.001 0 01-5.81 0H3a.75.75 0 01-.75-.75zm15 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.126 6H4a1 1 0 000 2h13.126a4.002 4.002 0 007.748 0H28a1 1 0 100-2h-3.126a4.002 4.002 0 00-7.748 0zM19 7a2 2 0 114 0 2 2 0 01-4 0zM11 12a4.002 4.002 0 013.874 3H28a1 1 0 110 2H14.874a4.002 4.002 0 01-7.748 0H4a1 1 0 110-2h3.126c.444-1.725 2.01-3 3.874-3zm0 2a2 2 0 110 4 2 2 0 010-4zM3 25a1 1 0 011-1h13.126a4.002 4.002 0 017.748 0H28a1 1 0 110 2h-3.126a4.002 4.002 0 01-7.748 0H4a1 1 0 01-1-1zm20 0a2 2 0 10-4 0 2 2 0 004 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.64 11H7.5a1.5 1.5 0 000 3h27.14a8.003 8.003 0 0015.72 0h6.14a1.5 1.5 0 000-3h-6.14a8.003 8.003 0 00-15.72 0zm2.86 1.5a5 5 0 1110 0 5 5 0 01-10 0zM21.5 23.5a8.003 8.003 0 017.86 6.5H56.5a1.5 1.5 0 010 3H29.36a8.003 8.003 0 01-15.72 0H7.5a1.5 1.5 0 010-3h6.14a8.003 8.003 0 017.86-6.5zm0 3a5 5 0 110 10 5 5 0 010-10zM6 50.5A1.5 1.5 0 017.5 49h27.14a8.003 8.003 0 0115.72 0h6.14a1.5 1.5 0 010 3h-6.14a8.003 8.003 0 01-15.72 0H7.5A1.5 1.5 0 016 50.5zm41.5 0a5 5 0 10-10 0 5 5 0 0010 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSettingsSlidersH3Regular)
