import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMailArrowInRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.75 2A2.75 2.75 0 002 4.75v.5a.75.75 0 101.5 0v-.5c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25h-.5a.75.75 0 000 1.5h.5A2.75 2.75 0 0016 10.25v-5.5A2.75 2.75 0 0013.25 2h-8.5z"
          fill="currentColor"
        />
        <path
          d="M5.712 4.852a.75.75 0 00-.89 1.207l2.586 1.908a2.75 2.75 0 003.3-.025l2.48-1.89a.75.75 0 00-.91-1.193l-2.48 1.89a1.25 1.25 0 01-1.5.011L5.712 4.852zM.75 7.5a.75.75 0 01.75.75v.5a2.75 2.75 0 002.75 2.75h3.16l-1.596-1.14a.75.75 0 01.872-1.22l2.36 1.686a1.75 1.75 0 010 2.848l-2.36 1.686a.75.75 0 11-.872-1.22L7.41 13H4.25A4.25 4.25 0 010 8.75v-.5a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.249 4.001a3.25 3.25 0 00-3.25 3.25v1.75a.75.75 0 101.5 0V7.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H13a.75.75 0 000 1.5h1.751a3.25 3.25 0 003.25-3.25V7.25a3.25 3.25 0 00-3.25-3.25H5.25z"
          fill="currentColor"
        />
        <path
          d="M6.036 7.105a.75.75 0 10-.931 1.176l2.878 2.279a3.25 3.25 0 004.034 0l2.878-2.279a.75.75 0 00-.93-1.176l-2.879 2.279a1.75 1.75 0 01-2.172 0L6.036 7.105zM.75 10.5a.75.75 0 01.75.75v.5a2.75 2.75 0 002.75 2.75h3.209l-1.903-1.395a.75.75 0 11.888-1.21l2.65 1.944a1.75 1.75 0 010 2.822l-2.65 1.944a.75.75 0 11-.888-1.21L7.46 16H4.25A4.25 4.25 0 010 11.75v-.5a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 4.25A3.75 3.75 0 002.25 8v3a.75.75 0 001.5 0V8A2.25 2.25 0 016 5.75h12A2.25 2.25 0 0120.25 8v8A2.25 2.25 0 0118 18.25h-3a.75.75 0 000 1.5h3A3.75 3.75 0 0021.75 16V8A3.75 3.75 0 0018 4.25H6z"
          fill="currentColor"
        />
        <path
          d="M6.742 7.934a.75.75 0 00-.984 1.132l3.781 3.288a3.75 3.75 0 004.922 0l3.781-3.288a.75.75 0 10-.984-1.132l-3.782 3.288a2.25 2.25 0 01-2.952 0L6.742 7.934zM1 13.25a.75.75 0 01.75.75v.5a3.75 3.75 0 003.75 3.75h4.16l-2.637-2.17a.75.75 0 01.954-1.159l3.312 2.728c.85.7.85 2.002 0 2.702l-3.312 2.728a.75.75 0 11-.954-1.158l2.636-2.17H5.5A5.25 5.25 0 01.25 14.5V14a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 5A5.5 5.5 0 002 10.5V14a1 1 0 102 0v-3.5A3.5 3.5 0 017.5 7h17a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5H20a1 1 0 100 2h4.5a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 5h-17z"
          fill="currentColor"
        />
        <path
          d="M8.659 10.247a1 1 0 00-1.317 1.506l5.036 4.407a5.5 5.5 0 007.244 0l5.037-4.407a1 1 0 10-1.317-1.506l-5.037 4.408a3.5 3.5 0 01-4.61 0L8.66 10.247zM1 18a1 1 0 011 1v1a5 5 0 005 5h6.086l-3.293-3.293a1 1 0 011.414-1.414l3.94 3.94a2.5 2.5 0 010 3.535l-3.94 3.94a1 1 0 01-1.414-1.415L13.086 27H7a7 7 0 01-7-7v-1a1 1 0 011-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16.5 9C10.701 9 6 13.701 6 19.5v8a1.5 1.5 0 003 0v-8a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5h-7a1.5 1.5 0 000 3h7C53.299 51 58 46.299 58 40.5v-21C58 13.701 53.299 9 47.5 9h-31z"
        fill="currentColor"
      />
      <path
        d="M16.47 18.856a1.5 1.5 0 00-1.94 2.288l10.677 9.06a10.5 10.5 0 0013.586 0l10.678-9.06a1.5 1.5 0 00-1.941-2.288l-10.678 9.06a7.5 7.5 0 01-9.704 0l-10.677-9.06zM1.5 34A1.5 1.5 0 013 35.5v2C3 43.299 7.701 48 13.5 48h14.604l-7.94-7.94a1.5 1.5 0 012.122-2.12l8.378 8.378a4.5 4.5 0 010 6.364l-8.378 8.379a1.5 1.5 0 01-2.122-2.122L28.104 51H13.5C6.044 51 0 44.956 0 37.5v-2A1.5 1.5 0 011.5 34z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMailArrowInRegular)
