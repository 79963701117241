import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'
import { Typography } from '@ui/Typography'

import FailCard from '@lib/components/FailCard'
import { Form } from '@lib/components/Form/Form'
import PhoneInput from '@lib/components/PhoneInput'
import SuccessCard from '@lib/components/SuccessCard'

import { useRegistrationEmployeeFormStore } from '@modules/auth/store/RegistrationEmployeeFormStore'

import { FormWindowLayout } from '../FormWindowLayout/FormWindowLayout'

import styles from './RegistrationEmployeeForm.scss'

/**
 * Форма регистрации.
 */
export const RegistrationEmployeeForm: React.FC = observer(() => {
  const registrationStore = useRegistrationEmployeeFormStore()

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      registrationStore.form.handleFieldChange(
        event.target.name,
        event.target.value,
      )
    },
    [],
  )

  const { companyId } = useParams()

  const handleToRegister = useCallback(() => {
    registrationStore.form.clear()
  }, [])

  const handleSubmit = useCallback(() => {
    registrationStore.handleSubmit(Number(companyId))
  }, [])

  return (
    <div className={styles.container}>
      <FormWindowLayout
        withoutPadding={
          registrationStore.registrationSuccess ||
          registrationStore.registrationError
        }>
        {(registrationStore.registrationIDLE ||
          registrationStore.registrationRunning) && (
          <Form title="Регистрация">
            <Input
              name="fullName"
              label="Контактная информация"
              placeholder="Фамилия Имя Отчество"
              className={styles.fullname}
              value={registrationStore.form.fields.fullName.value}
              error={registrationStore.form.fields.fullName.error}
              errorText={registrationStore.form.fields.fullName.errorText}
              onChange={handleFieldChange}
            />
            <Input
              name="email"
              id="contact-email"
              placeholder="Электронная почта"
              className={styles.email}
              value={registrationStore.form.fields.email.value}
              error={registrationStore.form.fields.email.error}
              errorText={registrationStore.form.fields.email.errorText}
              onChange={handleFieldChange}
            />
            <PhoneInput
              name="phone"
              id="contact-phone"
              placeholder="Телефон"
              className={styles.phone}
              value={registrationStore.form.fields.phone.value}
              error={registrationStore.form.fields.phone.error}
              errorText={registrationStore.form.fields.phone.errorText}
              onChange={handleFieldChange}
            />
            <Input
              name="position"
              label="Должность"
              placeholder="Должность"
              className={styles.position}
              id="position"
              value={registrationStore.form.fields.position.value}
              error={registrationStore.form.fields.position.error}
              errorText={registrationStore.form.fields.position.errorText}
              onChange={handleFieldChange}
            />
            <Button
              onClick={handleSubmit}
              fullSize
              className={styles.submitBtn}>
              Отправить заявку
            </Button>
          </Form>
        )}
        {registrationStore.registrationSuccess && (
          <SuccessCard
            className={styles.fitContentWidth}
            title="Данные успешно отправлены"
            description="Мы скоро проверим вашу заявку! Логин и пароль придут вам на указанный email."
          />
        )}
        {registrationStore.registrationError && (
          <FailCard
            className={styles.fitContentWidth}
            description="Во время регистрации произошла ошибка, попробуйте зарегистрироваться повторно"
            titleButton="Вернуться к регистрации"
            handleClick={handleToRegister}
            fullSize={true}
          />
        )}
      </FormWindowLayout>
      <div className={styles.text}>
        <Typography size={14}>Заполняя форму, вы соглашаетесь на</Typography>
        <Button
          className={styles.link}
          type="link"
          size="small"
          href="https://rdwcomp.ru/politic/">
          &nbsp;обработку персональных данных&nbsp;
        </Button>
        <Typography size={14}>
          и получение информационных сообщений от компании RDW Technology.
        </Typography>
      </div>
    </div>
  )
})
