import { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconPlusLight } from '@ui/icons'
import { Modal } from '@ui/Modal'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { declinationByNum } from '@lib/helpers'

import { CartItem } from '@modules/configurator/components/CartItem'
import { OrderSummary } from '@modules/configurator/components/OrderSummary'
import type { IProductType } from '@modules/configurator/models/Configurator'
import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

import styles from './Cart.module.scss'

const Cart = () => {
  const configuratorStore = useConfiguratorStore()

  const declinationText = `${
    configuratorStore.cart.complectations.length
  } ${declinationByNum(configuratorStore.cart.complectations.length, [
    'товар',
    'товара',
    'товаров',
  ])}`

  const handleChangeComment = useCallback((value: string) => {
    configuratorStore.setComment(value)
  }, [])

  const handleAddMore = useCallback(() => {
    configuratorStore.addMore()
  }, [])

  const handleEditProduct = useCallback((hash: string) => {
    configuratorStore.editProduct(hash)
  }, [])

  const handleAddProductType = useCallback((productType: IProductType) => {
    configuratorStore.addProductType(productType)
  }, [])

  const handleSendOrder = useCallback(() => {
    configuratorStore.sendOrder()
  }, [])

  const handleCloseModal = useCallback(() => {
    configuratorStore.closeModal('cart')
  }, [])

  const handleOpenModal = useCallback((hash: string) => {
    configuratorStore.openModal('cart')
    configuratorStore.setHashToDelete(hash)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography weight="medium" size={18}>
          Корзина
        </Typography>
        <Typography size={18} color={COLORS.GRAY.GRAY_2}>
          {declinationText}
        </Typography>
      </div>

      <div className={styles.cart}>
        <div className={styles.products}>
          <div className={styles.items}>
            {configuratorStore.cart.complectations
              .slice()
              .reverse()
              .map((order) => (
                <CartItem
                  key={order.hash}
                  order={order}
                  componentTypes={configuratorStore.componentTypes}
                  onIncrement={() =>
                    configuratorStore.incrementProduct(order.hash)
                  }
                  onDecrement={() =>
                    configuratorStore.decrementProduct(order.hash)
                  }
                  onEdit={() => handleEditProduct(order.hash)}
                  onDelete={() => handleOpenModal(order.hash)}
                />
              ))}
          </div>

          <div className={styles.controls}>
            <Button
              IconLeft={IconPlusLight}
              size="small"
              noWrap
              onClick={handleAddMore}>
              Добавить продукцию
            </Button>

            {configuratorStore.productTypes?.map((productType) => {
              return (
                <Button
                  key={productType.id}
                  type="secondary"
                  IconLeft={IconPlusLight}
                  size="small"
                  noWrap
                  onClick={() => handleAddProductType(productType)}>
                  {productType.name}
                </Button>
              )
            })}
          </div>
        </div>

        <div className={styles.orderSummary}>
          <OrderSummary
            cart={configuratorStore.cart}
            onChangeComment={handleChangeComment}
          />
          <Button fullSize onClick={handleSendOrder}>
            Отправить на оформление
          </Button>
        </div>
      </div>

      {configuratorStore.modals.cart && (
        <Modal
          open={configuratorStore.modals.cart}
          onClose={handleCloseModal}
          title="Удаление товара из корзины"
          content="Товар будет удален без возможности восстановления."
          footer={
            <>
              <Button type="link" withBorder onClick={handleCloseModal}>
                Отменить
              </Button>
              <Button
                onClick={() =>
                  configuratorStore.deleteProduct(
                    configuratorStore.hashToDeleteComplectation,
                  )
                }>
                Удалить
              </Button>
            </>
          }
        />
      )}
    </div>
  )
}

export default observer(Cart)
