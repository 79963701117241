import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyTypeCardRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M10 6a.5.5 0 110-1h1.5a.5.5 0 110 1H10z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.75A2.75 2.75 0 013.75 2h8.5A2.75 2.75 0 0115 4.75v5.5A2.75 2.75 0 0112.25 13h-8.5A2.75 2.75 0 011 10.25V9.025 4.75zm12.5 0V8h-11V4.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25zm-11 5.5V10h11v.25c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.5 8.25a.75.75 0 110-1.5h2a.75.75 0 110 1.5h-2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.999 7.251a3.25 3.25 0 013.25-3.25h9.502a3.25 3.25 0 013.25 3.25v5.498a3.25 3.25 0 01-3.25 3.25H5.25a3.25 3.25 0 01-3.25-3.25V7.25zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v3.75H16.5V7.25a1.75 1.75 0 00-1.75-1.75H5.25zm0 8.998A1.75 1.75 0 013.516 13h12.967a1.75 1.75 0 01-1.732 1.499H5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.25 15c0 .414.336.75.75.75h12.125a.75.75 0 000-1.5H6a.75.75 0 00-.75.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.125 7.25c-1.036 0-1.875.84-1.875 1.875v.75c0 1.036.84 1.875 1.875 1.875h1.75c1.035 0 1.875-.84 1.875-1.875v-.75c0-1.036-.84-1.875-1.875-1.875h-1.75zm-.375 1.875c0-.207.168-.375.375-.375h1.75c.207 0 .375.168.375.375v.75a.375.375 0 01-.375.375h-1.75a.375.375 0 01-.375-.375v-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 8A3.75 3.75 0 016 4.25h12A3.75 3.75 0 0121.75 8v8A3.75 3.75 0 0118 19.75H6A3.75 3.75 0 012.25 16V8zM6 5.75A2.25 2.25 0 003.75 8v8A2.25 2.25 0 006 18.25h12A2.25 2.25 0 0020.25 16V8A2.25 2.25 0 0018 5.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 22a1 1 0 110-2h2a1 1 0 110 2h-2zM11.5 21a1 1 0 001 1h2a1 1 0 000-2h-2a1 1 0 00-1 1zM17.5 22a1 1 0 110-2h2a1 1 0 110 2h-2zM21.5 21a1 1 0 001 1h2a1 1 0 000-2h-2a1 1 0 00-1 1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 9a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-1A2.5 2.5 0 0023.5 9h-3zm-.5 2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 5A5.5 5.5 0 002 10.5v11A5.5 5.5 0 007.5 27h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 5h-17zM4 10.5A3.5 3.5 0 017.5 7h17a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 21.5v-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14.5 43a1.5 1.5 0 110-3h5a1.5 1.5 0 110 3h-5zM23 41.5a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 000-3h-5a1.5 1.5 0 00-1.5 1.5zM34.5 43a1.5 1.5 0 110-3h5a1.5 1.5 0 110 3h-5zM43 41.5a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 000-3h-5a1.5 1.5 0 00-1.5 1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 19a4.5 4.5 0 00-4.5 4.5v2a4.5 4.5 0 004.5 4.5h6a4.5 4.5 0 004.5-4.5v-2a4.5 4.5 0 00-4.5-4.5h-6zM38 23.5a1.5 1.5 0 011.5-1.5h6a1.5 1.5 0 011.5 1.5v2a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 01-1.5-1.5v-2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 11C10.701 11 6 15.701 6 21.5v21C6 48.299 10.701 53 16.5 53h31C53.299 53 58 48.299 58 42.5v-21C58 15.701 53.299 11 47.5 11h-31zM9 21.5a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5h-31A7.5 7.5 0 019 42.5v-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyTypeCardRegular)
