import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocsRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.002 2.75A2.75 2.75 0 014.752 0h3.595c.464 0 .91.184 1.238.513l2.9 2.9c.329.329.513.774.513 1.238v5.599a2.75 2.75 0 01-2.75 2.75H4.752a2.75 2.75 0 01-2.75-2.75v-7.5zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v7.5c0 .69.56 1.25 1.25 1.25h5.496c.69 0 1.25-.56 1.25-1.25v-4.5H8.5c-.69 0-1.25-.56-1.25-1.25v-3H4.752zm3.998.3l2.45 2.45H8.75V1.8z"
          fill="currentColor"
        />
        <path
          d="M15.5 5.5a.75.75 0 00-1.5 0v5.247a3.25 3.25 0 01-3.25 3.25H6.5a.75.75 0 000 1.5h4.25a4.75 4.75 0 004.75-4.75V5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.999 4.251a3.25 3.25 0 013.25-3.25h3.12c.464 0 .909.185 1.237.513l3.88 3.88c.328.328.512.773.512 1.238v7.12a3.25 3.25 0 01-3.25 3.25h-5.5a3.25 3.25 0 01-3.25-3.25v-9.5zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75h5.5a1.75 1.75 0 001.75-1.75V7.75H10.5A2.25 2.25 0 018.25 5.5V2.501H6.249zm3.501.278l3.47 3.471H10.5a.75.75 0 01-.75-.75V2.78z"
          fill="currentColor"
        />
        <path
          d="M17.563 7.5a.75.75 0 00-1.5 0v6.781a3.75 3.75 0 01-3.75 3.75H7.5a.75.75 0 000 1.5h4.813c2.899 0 5.25-2.35 5.25-5.25V7.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.75 17V7.95c0-.729-.29-1.428-.805-1.944l-3.951-3.95a2.75 2.75 0 00-1.945-.806H7A3.75 3.75 0 003.25 5v12A3.75 3.75 0 007 20.75h8A3.75 3.75 0 0018.75 17zM7 2.75A2.25 2.25 0 004.75 5v12A2.25 2.25 0 007 19.25h8A2.25 2.25 0 0017.25 17V8.75H14A2.75 2.75 0 0111.25 6V2.75H7zm7 4.5h3.035a1.254 1.254 0 00-.151-.183l-3.95-3.95a1.253 1.253 0 00-.184-.152V6c0 .69.56 1.25 1.25 1.25z"
          fill="currentColor"
        />
        <path
          d="M21.75 9a.75.75 0 00-1.5 0v8.5a4.75 4.75 0 01-4.75 4.75H9a.75.75 0 000 1.5h6.5a6.25 6.25 0 006.25-6.25V9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 22.5V9.774a4 4 0 00-1.172-2.829l-5.773-5.773A4 4 0 0016.226 0H9.5A5.5 5.5 0 004 5.5v17A5.5 5.5 0 009.5 28h11a5.5 5.5 0 005.5-5.5zM9.5 2A3.5 3.5 0 006 5.5v17A3.5 3.5 0 009.5 26h11a3.5 3.5 0 003.5-3.5V11h-5.5A3.5 3.5 0 0115 7.5V2H9.5zm9 7h5.344a2 2 0 00-.43-.64L17.64 2.586a2 2 0 00-.64-.43V7.5A1.5 1.5 0 0018.5 9z"
          fill="currentColor"
        />
        <path
          d="M30 13a1 1 0 10-2 0v10a7 7 0 01-7 7h-9a1 1 0 100 2h9a9 9 0 009-9V13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32.5 9a1.5 1.5 0 011.5 1.5v6a3.5 3.5 0 003.5 3.5h7a1.5 1.5 0 010 3h-7a6.5 6.5 0 01-6.5-6.5v-6A1.5 1.5 0 0132.5 9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14.5C9 8.701 13.701 4 19.5 4h14.452a7.5 7.5 0 015.303 2.197l9.548 9.547A7.5 7.5 0 0151 21.049V45.5C51 51.299 46.299 56 40.5 56h-21C13.701 56 9 51.299 9 45.5v-31zM19.5 7a7.5 7.5 0 00-7.5 7.5v31a7.5 7.5 0 007.5 7.5h21a7.5 7.5 0 007.5-7.5V21.048a4.5 4.5 0 00-1.318-3.182l-9.548-9.548A4.5 4.5 0 0033.952 7H19.5z"
        fill="currentColor"
      />
      <path
        d="M58 24.5a1.5 1.5 0 00-3 0v23C55 54.404 49.404 60 42.5 60h-20a1.5 1.5 0 000 3h20C51.06 63 58 56.06 58 47.5v-23z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocsRegular)
