import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconToolPencilLineRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.882 1.515a1.75 1.75 0 012.475 0l1.128 1.127a1.75 1.75 0 01-.002 2.476l-8.386 8.369a1.75 1.75 0 01-1.236.511h-2.11a.75.75 0 01-.749-.75V11.12c0-.464.184-.91.513-1.238l8.367-8.367zm1.415 1.06a.25.25 0 00-.354 0L9.938 3.581l1.48 1.48 1.006-1.005a.25.25 0 000-.353l-1.127-1.128zm-8.722 8.368l6.302-6.302 1.479 1.48-6.318 6.304a.25.25 0 01-.177.073h-1.36l.001-1.378a.25.25 0 01.073-.177z"
          fill="currentColor"
        />
        <path
          d="M8.5 12.25a.75.75 0 000 1.5H15a.75.75 0 100-1.5H8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.882 3.515a1.75 1.75 0 012.475 0l1.128 1.127a1.75 1.75 0 01-.001 2.476l-9.387 9.369a1.75 1.75 0 01-1.236.511H2.752a.75.75 0 01-.75-.75V14.12c0-.464.184-.91.513-1.238l9.367-9.367zm1.415 1.06a.25.25 0 00-.354 0l-1.005 1.006 1.48 1.48 1.006-1.005a.25.25 0 000-.353l-1.127-1.128zm-9.722 9.368l7.302-7.302 1.479 1.48-7.318 7.304a.25.25 0 01-.177.073H3.502V14.12a.25.25 0 01.073-.177z"
          fill="currentColor"
        />
        <path
          d="M9.5 15.5a.75.75 0 000 1.5H17a.75.75 0 100-1.5H9.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.084 3.669a2.25 2.25 0 013.181 0l1.066 1.066a2.25 2.25 0 010 3.182L7.157 20.09a2.25 2.25 0 01-1.591.659H3a.75.75 0 01-.75-.75v-2.566c0-.596.237-1.169.659-1.59L15.084 3.668zm2.12 1.06a.75.75 0 00-1.06 0l-1.746 1.747 2.126 2.126 1.746-1.746a.75.75 0 000-1.06L17.205 4.73zM3.97 16.905l9.367-9.367 2.126 2.126-9.367 9.367a.75.75 0 01-.53.22H3.75v-1.816a.75.75 0 01.22-.53z"
          fill="currentColor"
        />
        <path
          d="M11 19.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.388 4.885a3 3 0 014.243 0l1.485 1.485a3 3 0 010 4.242l-16.51 16.51a3 3 0 01-2.12.878H4.5A1.5 1.5 0 013 26.5v-2.985a3 3 0 01.879-2.121l16.51-16.51zm2.829 1.414a1 1 0 00-1.415 0l-2.395 2.396 2.899 2.9 2.396-2.397a1 1 0 000-1.414l-1.485-1.485zM5.293 22.809l12.7-12.7 2.899 2.9-12.7 12.699a1 1 0 01-.707.293H5v-2.486a1 1 0 01.293-.707z"
          fill="currentColor"
        />
        <path d="M15 26a1 1 0 100 2h13a1 1 0 000-2H15z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.603 10.898a5.5 5.5 0 017.779 0l2.72 2.72a5.5 5.5 0 010 7.779L19.11 52.389A5.5 5.5 0 0115.221 54H9.5A2.5 2.5 0 017 51.5V45.78a5.5 5.5 0 011.61-3.89l30.993-30.992zm5.657 2.12a2.5 2.5 0 00-3.535 0l-4.3 4.3 6.256 6.258 4.3-4.3a2.5 2.5 0 000-3.536l-2.72-2.721zM10.732 44.012l24.571-24.57 6.257 6.256L16.99 50.268A2.5 2.5 0 0115.22 51H10V45.78a2.5 2.5 0 01.732-1.768z"
        fill="currentColor"
      />
      <path
        d="M28.5 51a1.5 1.5 0 100 3h27a1.5 1.5 0 000-3h-27z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconToolPencilLineRegular)
