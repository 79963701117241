import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.47 2.97a.75.75 0 011.06 0l3.793 3.793a1.75 1.75 0 010 2.474L6.53 13.03a.75.75 0 01-1.06-1.06l3.793-3.793a.25.25 0 000-.354L5.47 4.03a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.845 3.22a.75.75 0 011.06 0l4.836 4.836a2.75 2.75 0 010 3.889L7.905 16.78a.75.75 0 11-1.06-1.061l4.836-4.836a1.25 1.25 0 000-1.768L6.845 4.28a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.881 3.383a.875.875 0 011.238 0l6.406 6.407a3.125 3.125 0 010 4.42L9.12 20.616A.875.875 0 017.88 19.38l6.407-6.407a1.375 1.375 0 000-1.944L7.881 4.62a.875.875 0 010-1.238z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.793 4.793a1 1 0 011.414 0l8.379 8.379a4 4 0 010 5.656l-8.379 8.38a1 1 0 01-1.414-1.415l8.379-8.379a2 2 0 000-2.828l-8.38-8.379a1 1 0 010-1.414z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21.94 9.94a1.5 1.5 0 012.12 0l16.758 16.757a7.5 7.5 0 010 10.606L24.061 54.061a1.5 1.5 0 01-2.122-2.122l16.758-16.757a4.5 4.5 0 000-6.364L21.939 12.061a1.5 1.5 0 010-2.122z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCRightRegular)
