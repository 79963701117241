import { useCallback, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import {
  IconDocsLight,
  IconMarketShoppingBasketRegular,
  IconMoneyTypeCoinsRegular,
  IconPeople2Regular,
} from '@ui/icons'
import Toast from '@ui/Toast'

import PageWrapper from '@lib/components/Page/PageWrapper'
import StatisticsCard from '@lib/components/StatisticsCard'
import TaggedContainer from '@lib/components/TaggedContainer'
import { ITagOption } from '@lib/components/TaggedContainer/TaggedContainer'
import { EMainRoutes } from '@lib/navigation'

import { getCompanyStatusLabel } from '@modules/company/models/Company'
import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'
import { useTransactionStore } from '@modules/sales/store/TransactionStore'
import { useUserStore } from '@modules/user/store/UserStore'

import BasicInformation from '../../components/BasicInformation'
import CardManager from '../../components/CardManager'
import ContactInformation from '../../components/ContactInformation'

import styles from './styles.module.scss'

const CompanyPage = () => {
  const companyStore = useCompanyStore()
  const userStore = useUserStore()
  const configuratorStor = useConfiguratorStore()
  const transactionStore = useTransactionStore()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    Promise.all([
      configuratorStor.getOrders(),
      transactionStore.getTransactions(),
    ]).then(() => {
      setIsLoading(false)
    })
  }, [])

  const [watchToast, setWatchToast] = useState(false)

  const copy = useCallback(() => {
    companyStore.copyToClipboard(
      `${window.location.origin}/registration_of_a_new_employee/company/${companyStore.company.id}`,
    )
    setWatchToast(true)
  }, [])

  const countEmployee = useMemo(() => {
    if (companyStore.waitingEmployees.length > 0 && userStore.isPartner) {
      return {
        badgeCount: companyStore.waitingEmployees.length,
        description: 'На подтверждении',
      }
    }
  }, [companyStore.waitingEmployees.length, userStore.isPartner])

  const secondButton = useMemo(() => {
    if (userStore.isPartner) {
      return {
        secondButton: (
          <Button
            type="fill"
            size="small"
            IconLeft={IconDocsLight}
            withBorder
            onClick={copy}>
            Ссылка для регистрации сотрудника
          </Button>
        ),
      }
    }
  }, [userStore.isPartner])

  const tags: ITagOption[] = useMemo(
    () => [
      {
        label: 'ID организации',
        value: companyStore.company.id,
      },
      {
        label: 'Ваш статус',
        value: getCompanyStatusLabel(companyStore.company.status),
      },
    ],
    [companyStore.company.id, companyStore.company.status],
  )

  return (
    <PageWrapper
      isLoading={isLoading}
      title="Информация об организации"
      className={styles.container}>
      <TaggedContainer tags={tags} />

      <div className={styles.cards}>
        <StatisticsCard
          Icon={IconPeople2Regular}
          title="Количество сотрудников"
          count={companyStore.successEmployees.length}
          {...countEmployee}
          {...secondButton}
          firstButton={
            <Button
              linkTo={EMainRoutes.CompanyEmployees}
              size="small"
              className={styles.button}>
              Список сотрудников
            </Button>
          }
        />
        <Toast
          className={styles.toast}
          title="Ссылка скопирована"
          autoDeleteTime={2000}
          onVisible={watchToast}
          onCloseToast={(value) => setWatchToast(value)}
        />

        <StatisticsCard
          Icon={IconMoneyTypeCoinsRegular}
          title="Количество сделок"
          count={transactionStore.transactions.length}
          firstButton={
            <Button
              className={styles.button}
              linkTo={EMainRoutes.SalesRegisterTransaction}
              size="small">
              Зарегистрировать сделку
            </Button>
          }
          secondButton={
            <Button
              linkTo={EMainRoutes.SalesTransactions}
              type="fill"
              size="small"
              className={styles.button}
              withBorder>
              История сделок
            </Button>
          }
        />
        <StatisticsCard
          Icon={IconMarketShoppingBasketRegular}
          title="Заказы конфигураций"
          count={configuratorStor.orders.length}
          firstButton={
            <Button
              className={styles.button}
              linkTo={EMainRoutes.ConfiguratorCreate}
              size="small">
              Создать конфигурацию
            </Button>
          }
          secondButton={
            <Button
              className={styles.button}
              linkTo={EMainRoutes.ConfiguratorOrders}
              type="fill"
              size="small"
              withBorder>
              История заявок
            </Button>
          }
        />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.forms}>
          <BasicInformation />
          <ContactInformation />
        </div>

        <CardManager />
      </div>
    </PageWrapper>
  )
}

export default observer(CompanyPage)
