import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowRoundSyncForwardLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.954 2.027A5.5 5.5 0 0112.4 5H10.5a.5.5 0 000 1h3a.5.5 0 00.5-.5V2.252a.5.5 0 00-1 0v1.784A6.5 6.5 0 001.09 6.42a.5.5 0 10.986.166 5.5 5.5 0 014.878-4.56zM4.608 12.178a5.5 5.5 0 008.313-3.75.5.5 0 11.986.17A6.5 6.5 0 012 10.963v1.784a.5.5 0 11-1 0V9.5a.5.5 0 01.5-.5h3a.5.5 0 010 1H2.601a5.5 5.5 0 002.007 2.178z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.305 3.035A7 7 0 0116.325 7h-2.826a.5.5 0 000 1H17.5a.5.5 0 00.5-.5V3.275a.5.5 0 00-1 0v2.852A8 8 0 002.16 8.412a.5.5 0 00.98.199 7 7 0 016.165-5.576zM6.418 16.014a7 7 0 0010.439-4.605.5.5 0 01.98.202A8 8 0 013 13.873v2.85a.5.5 0 11-1 0V12.5a.5.5 0 01.5-.5h4a.5.5 0 010 1H3.674a7 7 0 002.743 3.014z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowRoundSyncForwardLight)
