import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1CheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.498 4.502a3.502 3.502 0 117.004 0 3.502 3.502 0 01-7.004 0zM8 2a2.502 2.502 0 100 5.003A2.502 2.502 0 008 2z"
          fill="currentColor"
        />
        <path
          d="M3.518 11.575c-.31.457-.518 1.075-.518 1.93a.5.5 0 01-1 0c0-1.025.252-1.846.69-2.491.436-.643 1.032-1.078 1.669-1.372 1.254-.58 2.726-.637 3.641-.637a.5.5 0 010 1c-.903 0-2.181.064-3.222.544-.511.237-.949.567-1.26 1.026zM15.853 9.853a.5.5 0 10-.706-.706L11.5 12.793l-1.647-1.646a.5.5 0 10-.706.706l1.858 1.859a.7.7 0 00.99 0l3.858-3.859z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.501 6.499a4.499 4.499 0 118.998 0 4.499 4.499 0 01-8.998 0zM10 3a3.499 3.499 0 100 6.997A3.499 3.499 0 0010 3z"
        fill="currentColor"
      />
      <path
        d="M10 12.495a.5.5 0 01-.49.51c-1.284.025-2.942.174-4.265.816-.653.318-1.209.748-1.602 1.33C3.252 15.729 3 16.488 3 17.5a.5.5 0 01-1 0c0-1.188.299-2.147.815-2.91.513-.76 1.221-1.293 1.993-1.668 1.529-.743 3.371-.891 4.682-.917a.5.5 0 01.51.49zM18.853 12.853a.5.5 0 10-.706-.706L13 17.293l-2.147-2.146a.5.5 0 10-.706.706L12.293 18a1 1 0 001.414 0l5.146-5.147z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1CheckLight)
