import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EUserRole, IUser, TInitials } from '@modules/user/models/User'
import { UserRest } from '@modules/user/services/UserRest'

export type TUpdateUser = Omit<Partial<IUser>, 'id' | 'company'>

@singleton()
export class UserStore {
  private userRest = container.resolve(UserRest)
  user?: IUser = undefined

  constructor() {
    makeAutoObservable<UserStore, 'userRest'>(this, {
      userRest: false,
    })
  }

  get lastNameWithInitials() {
    const fullNameSplitted = this.user?.fullName.split(' ')

    return `${fullNameSplitted?.[1]} ${fullNameSplitted?.[0][0]}.`
  }

  get initials(): TInitials {
    const fullNameSplitted = this.user.fullName.split(' ')

    return {
      firstName: fullNameSplitted[1],
      lastName: fullNameSplitted[0],
      patronymic: fullNameSplitted[2],
    }
  }

  get isPartner() {
    return this.user !== undefined && this.user.role === EUserRole.Partner
  }

  private setUser(data?: IUser) {
    this.user = data
  }

  async fetchUser() {
    const data = await this.userRest.getUser()
    this.setUser(data)
  }

  async updateUser(params: TUpdateUser) {
    const data = await this.userRest.updateUser({
      ...this.user,
      ...params,
    })

    this.setUser(data)
  }

  clear() {
    this.setUser(undefined)
  }
}

export const useUserStore = () => container.resolve(UserStore)
