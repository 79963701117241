import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSecurityShieldCheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.425 6.326a.5.5 0 10-.707-.707L7.494 8.842a.5.5 0 01-.707 0L5.454 7.51a.5.5 0 00-.707.707L6.08 9.549a1.5 1.5 0 002.122 0l3.223-3.223z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.568 1.317a1.5 1.5 0 00-1.136 0l-4.5 1.841c-.564.23-.936.783-.928 1.397.029 2.39.232 4.24 1.007 5.81.782 1.585 2.115 2.817 4.263 4.052a1.457 1.457 0 001.452 0c2.148-1.235 3.48-2.467 4.263-4.052.776-1.57.978-3.42 1.008-5.81a1.492 1.492 0 00-.928-1.397l-4.501-1.84zm-.757.926a.5.5 0 01.378 0l4.5 1.84c.19.078.31.261.308.46-.03 2.366-.235 4.024-.904 5.379-.662 1.34-1.81 2.446-3.866 3.628a.457.457 0 01-.454 0c-2.056-1.182-3.204-2.289-3.866-3.628-.67-1.355-.874-3.013-.904-5.38a.492.492 0 01.307-.458l4.5-1.841z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14.338 7.726a.5.5 0 00-.707-.708l-4.216 4.216a.75.75 0 01-1.06 0L6.64 9.52a.5.5 0 10-.707.707l1.715 1.714a1.75 1.75 0 002.474 0l4.216-4.215z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.614 1.728a1.75 1.75 0 00-1.228 0L3.136 4.07a1.738 1.738 0 00-1.13 1.648c.042 2.994.323 5.294 1.384 7.237 1.065 1.954 2.878 3.476 5.821 5.015a1.704 1.704 0 001.578 0c2.943-1.539 4.756-3.061 5.821-5.015 1.06-1.943 1.343-4.243 1.384-7.237a1.738 1.738 0 00-1.13-1.648l-6.25-2.343zm-.877.936a.75.75 0 01.526 0l6.25 2.344c.294.11.486.39.481.698-.04 2.965-.325 5.055-1.261 6.771-.93 1.706-2.546 3.111-5.407 4.608a.704.704 0 01-.652 0c-2.861-1.497-4.476-2.902-5.407-4.608-.936-1.716-1.22-3.806-1.261-6.771a.738.738 0 01.48-.698l6.25-2.344z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityShieldCheckLight)
