import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 4.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 11.235V2.25C13 1.56 12.44 1 11.75 1h-7A2.75 2.75 0 002 3.75v8.934a2.25 2.25 0 002.25 2.315h8a.75.75 0 000-1.5h-8a.75.75 0 110-1.499h8a.75.75 0 00.75-.75v-.015zM4.75 2.5c-.69 0-1.25.56-1.25 1.25v6.878c.234-.083.487-.128.75-.128h7.25v-8H4.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7 5a.75.75 0 000 1.5h6A.75.75 0 0013 5H7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 1A3.25 3.25 0 003 4.25v12.28c0 .028 0 .055.003.08A2.25 2.25 0 005.25 19H16.25a.75.75 0 000-1.5H5.249a.75.75 0 010-1.499H15a2 2 0 002-2V3a2 2 0 00-2-2H6.249zM5.248 14.5c-.263 0-.515.045-.75.128V4.251c0-.967.784-1.75 1.75-1.75h8.752a.5.5 0 01.5.5V14a.5.5 0 01-.5.5H5.249z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 6a.75.75 0 000 1.5h8A.75.75 0 0016 6H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.25A3.75 3.75 0 003.25 5v15A2.75 2.75 0 006 22.75h14a.75.75 0 000-1.5H6a1.25 1.25 0 110-2.5h12.5a2.25 2.25 0 002.25-2.25v-13a2.25 2.25 0 00-2.25-2.25H7zm-1 16c-.45 0-.875.108-1.25.3V5A2.25 2.25 0 017 2.75h11.5a.75.75 0 01.75.75v13a.75.75 0 01-.75.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M11 9a1 1 0 100 2h10a1 1 0 100-2H11z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2a5 5 0 00-5 5v19.5A3.5 3.5 0 008.5 30H26a1 1 0 100-2H8.5a1.5 1.5 0 010-3H24a3 3 0 003-3V5a3 3 0 00-3-3H10zM8.5 23c-.537 0-1.045.12-1.5.337V7a3 3 0 013-3h14a1 1 0 011 1v17a1 1 0 01-1 1H8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21.5 20a1.5 1.5 0 000 3h21a1.5 1.5 0 000-3h-21z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 55a3 3 0 110-6h31.5a4.5 4.5 0 004.5-4.5v-34A4.5 4.5 0 0048.5 6h-29a8.5 8.5 0 00-8.5 8.5V52a6 6 0 006 6h34.5a1.5 1.5 0 000-3H17zm2.5-46h29a1.5 1.5 0 011.5 1.5v34a1.5 1.5 0 01-1.5 1.5H17a5.972 5.972 0 00-3 .803V14.5A5.5 5.5 0 0119.5 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookClosedRegular)
