import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechPhoneClassicRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.82 1.52a1.762 1.762 0 012.493 0l1.533 1.534c.691.69.691 1.81 0 2.501-.305.306-.55.63-.678.934-.123.29-.12.509-.03.698.18.374.531.851 1.181 1.501.65.65 1.128 1.002 1.502 1.181.19.091.408.094.698-.029.303-.128.628-.373.934-.678.69-.691 1.81-.691 2.502 0l1.533 1.533a1.762 1.762 0 010 2.492c-1.227 1.228-3.036 2.132-4.922 1.718-1.544-.338-3.336-1.154-5.322-3.14-1.987-1.987-2.803-3.78-3.142-5.323-.413-1.886.491-3.694 1.719-4.922zm1.432 1.06a.262.262 0 00-.37 0c-1.015 1.015-1.58 2.33-1.314 3.54.273 1.25.942 2.79 2.736 4.584 1.794 1.794 3.334 2.462 4.583 2.736 1.211.266 2.526-.299 3.54-1.313a.262.262 0 000-.371l-1.533-1.534a.269.269 0 00-.38 0c-.378.378-.861.768-1.413 1-.565.239-1.248.327-1.93 0-.593-.286-1.21-.77-1.912-1.473-.703-.702-1.187-1.32-1.472-1.912-.328-.681-.24-1.365-.002-1.93.233-.551.623-1.035 1-1.412a.269.269 0 000-.38L4.253 2.58z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.8 2.27a1.97 1.97 0 012.785 0l1.87 1.87a1.977 1.977 0 010 2.797c-.38.381-.697.796-.866 1.197-.163.387-.171.709-.032.999.232.482.676 1.08 1.475 1.88.8.8 1.399 1.244 1.881 1.475.29.14.612.132 1-.031.4-.169.815-.486 1.196-.867a1.977 1.977 0 012.796 0l1.87 1.87c.77.77.77 2.017 0 2.786-1.47 1.471-3.617 2.533-5.836 2.047-1.848-.405-4.004-1.383-6.403-3.783-2.4-2.4-3.378-4.555-3.783-6.403-.486-2.22.575-4.365 2.047-5.837zm1.746 1.039a.5.5 0 00-.707 0c-1.263 1.263-1.992 2.925-1.65 4.484.34 1.56 1.174 3.467 3.385 5.678 2.212 2.212 4.12 3.045 5.68 3.387 1.558.341 3.22-.388 4.483-1.651a.5.5 0 000-.707l-1.87-1.87a.508.508 0 00-.72 0c-.451.45-1.022.91-1.664 1.18-.656.277-1.434.373-2.206.003-.697-.335-1.433-.91-2.283-1.76-.851-.851-1.426-1.587-1.76-2.284-.372-.772-.276-1.55.001-2.206.271-.643.73-1.213 1.182-1.665a.508.508 0 000-.719l-1.87-1.87z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.58 2.555a2.267 2.267 0 013.206 0l2.297 2.298c.889.889.889 2.33 0 3.22-.476.475-.88 1-1.097 1.518-.212.503-.23.946-.036 1.352.296.614.854 1.364 1.842 2.352.987.987 1.737 1.546 2.351 1.841.406.195.85.176 1.352-.036.517-.218 1.043-.621 1.519-1.097.889-.889 2.33-.889 3.22 0l2.297 2.298c.885.885.885 2.32 0 3.205-1.786 1.786-4.372 3.056-7.03 2.473-2.24-.49-4.861-1.677-7.79-4.605-2.927-2.928-4.113-5.55-4.604-7.79-.582-2.657.687-5.243 2.473-7.029zm2.145 1.06c-.3-.298-.785-.298-1.084 0C4.068 5.19 3.138 7.282 3.572 9.265c.426 1.945 1.465 4.314 4.2 7.05 2.736 2.736 5.105 3.774 7.05 4.2 1.983.435 4.075-.495 5.648-2.069.3-.299.3-.784 0-1.084l-2.297-2.297a.777.777 0 00-1.099 0c-.547.547-1.232 1.096-1.996 1.419-.778.328-1.686.436-2.585.005-.834-.4-1.723-1.093-2.762-2.133-1.04-1.04-1.732-1.929-2.133-2.763-.431-.898-.322-1.806.006-2.584.322-.764.87-1.449 1.418-1.996a.777.777 0 000-1.099L6.725 3.616z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.107 3.453a3.022 3.022 0 014.274 0l3.063 3.063a3.036 3.036 0 010 4.293c-.634.634-1.172 1.336-1.463 2.025-.283.67-.307 1.261-.047 1.803.393.819 1.138 1.819 2.455 3.135 1.316 1.317 2.316 2.062 3.136 2.455.54.26 1.132.235 1.802-.047.69-.29 1.391-.829 2.025-1.463a3.035 3.035 0 014.293 0l3.063 3.063a3.022 3.022 0 010 4.274c-2.381 2.382-5.829 4.074-9.372 3.298-2.986-.654-6.483-2.236-10.387-6.14-3.904-3.904-5.486-7.4-6.14-10.386-.776-3.544.916-6.992 3.298-9.373zm2.86 1.414c-.4-.399-1.047-.399-1.446 0-2.098 2.098-3.337 4.887-2.758 7.53.568 2.594 1.952 5.753 5.6 9.4 3.648 3.649 6.807 5.033 9.4 5.601 2.644.58 5.433-.66 7.53-2.758.4-.4.4-1.046 0-1.445l-3.062-3.064a1.035 1.035 0 00-1.465 0c-.73.73-1.643 1.462-2.662 1.892-1.037.437-2.248.582-3.446.007-1.112-.534-2.297-1.457-3.683-2.844-1.386-1.386-2.31-2.571-2.844-3.683-.575-1.197-.43-2.409.007-3.446.43-1.019 1.162-1.932 1.892-2.662a1.036 1.036 0 000-1.464L8.967 4.867z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.393 9.43a5.318 5.318 0 017.52 0l5.783 5.783a5.343 5.343 0 010 7.556c-1.215 1.215-2.269 2.58-2.844 3.946-.564 1.337-.635 2.573-.082 3.722.77 1.604 2.211 3.527 4.71 6.026 2.5 2.5 4.423 3.94 6.027 4.71 1.15.553 2.385.483 3.722-.081 1.365-.576 2.73-1.63 3.946-2.845a5.343 5.343 0 017.556 0l5.783 5.783a5.318 5.318 0 010 7.52c-4.44 4.441-10.821 7.55-17.337 6.122-5.561-1.219-12.097-4.166-19.418-11.487C11.438 38.863 8.49 32.328 7.272 26.767c-1.428-6.516 1.68-12.897 6.12-17.337zm5.399 2.121a2.318 2.318 0 00-3.278 0c-4.015 4.015-6.444 9.408-5.312 14.574 1.09 4.973 3.742 11.002 10.678 17.938C27.817 51 33.846 53.652 38.82 54.741c5.165 1.132 10.558-1.297 14.573-5.312a2.318 2.318 0 000-3.277l-5.783-5.783a2.343 2.343 0 00-3.313 0c-1.36 1.36-3.043 2.703-4.902 3.487-1.888.796-4.054 1.047-6.187.022-2.043-.981-4.246-2.69-6.85-5.293-2.602-2.604-4.311-4.806-5.293-6.849-1.024-2.133-.774-4.299.022-6.187.784-1.86 2.128-3.542 3.488-4.901a2.343 2.343 0 000-3.314l-5.783-5.783z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPhoneClassicRegular)
