import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextBulletChecklistRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.78 2.53a.75.75 0 00-1.06-1.06L3.5 3.69l-.72-.72a.75.75 0 00-1.06 1.06l.896.897a1.25 1.25 0 001.768 0L6.78 2.53zM8.25 4A.75.75 0 019 3.25h5a.75.75 0 010 1.5H9A.75.75 0 018.25 4zM9 7.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5H9zM14 11.25H9a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM6.78 5.72a.75.75 0 010 1.06L4.384 9.177a1.25 1.25 0 01-1.768 0L1.72 8.28a.75.75 0 011.06-1.06l.72.72 2.22-2.22a.75.75 0 011.06 0zM6.78 11.03a.75.75 0 10-1.06-1.06L3.5 12.19l-.72-.72a.75.75 0 00-1.06 1.06l.896.897a1.25 1.25 0 001.768 0L6.78 11.03z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.03 4.03a.75.75 0 00-1.06-1.06L3.75 5.19l-.72-.72a.75.75 0 00-1.06 1.06l.896.897a1.25 1.25 0 001.768 0L7.03 4.03zM16.25 5.75H9a.75.75 0 010-1.5h7.25a.75.75 0 010 1.5zM9 9.25a.75.75 0 000 1.5h7.25a.75.75 0 000-1.5H9zM16.25 14.25H9a.75.75 0 000 1.5h7.25a.75.75 0 000-1.5zM7.03 7.97a.75.75 0 010 1.06l-2.396 2.397a1.25 1.25 0 01-1.768 0l-.896-.897a.75.75 0 111.06-1.06l.72.72 2.22-2.22a.75.75 0 011.06 0zM7.03 14.03a.75.75 0 10-1.06-1.06l-2.22 2.22-.72-.72a.75.75 0 00-1.06 1.06l.896.897a1.25 1.25 0 001.768 0L7.03 14.03z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.405 3.53a.75.75 0 00-1.06-1.06L4.75 6.064 3.405 4.72a.75.75 0 00-1.06 1.06l1.344 1.345a1.5 1.5 0 002.122 0L9.405 3.53zM11 5a.75.75 0 01.75-.75h9.5a.75.75 0 010 1.5h-9.5A.75.75 0 0111 5zM11.75 11.25a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM11.75 18.25a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM9.405 9.22a.75.75 0 010 1.06l-3.594 3.595a1.5 1.5 0 01-2.122 0L2.345 12.53a.75.75 0 111.06-1.06l1.345 1.344L8.345 9.22a.75.75 0 011.06 0zM9.405 17.03a.75.75 0 10-1.06-1.06L4.75 19.564 3.405 18.22a.75.75 0 00-1.06 1.06l1.344 1.345a1.5 1.5 0 002.122 0l3.594-3.595z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.207 4.707a1 1 0 00-1.414-1.414L6 8.086 4.207 6.293a1 1 0 00-1.414 1.414L4.586 9.5a2 2 0 002.828 0l4.793-4.793zM28 8H16a1 1 0 110-2h12a1 1 0 110 2zM16 15a1 1 0 100 2h12a1 1 0 100-2H16zM28 24H16a1 1 0 100 2h12a1 1 0 100-2zM12.207 12.293a1 1 0 010 1.414L7.414 18.5a2 2 0 01-2.828 0l-1.793-1.793a1 1 0 111.414-1.414L6 17.086l4.793-4.793a1 1 0 011.414 0zM12.207 22.707a1 1 0 00-1.414-1.414L6 26.086l-1.793-1.793a1 1 0 00-1.414 1.414L4.586 27.5a2 2 0 002.828 0l4.793-4.793z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M23.06 8.06a1.5 1.5 0 00-2.12-2.12l-9.586 9.585a.5.5 0 01-.708 0L7.061 11.94a1.5 1.5 0 00-2.122 2.122l3.586 3.585a3.5 3.5 0 004.95 0l9.586-9.585zM28 12.5a1.5 1.5 0 011.5-1.5h25a1.5 1.5 0 010 3h-25a1.5 1.5 0 01-1.5-1.5zM29.5 30a1.5 1.5 0 000 3h25a1.5 1.5 0 000-3h-25zM29.5 49a1.5 1.5 0 000 3h25a1.5 1.5 0 000-3h-25zM23.06 24.94a1.5 1.5 0 010 2.12l-9.585 9.586a3.5 3.5 0 01-4.95 0L4.94 33.061a1.5 1.5 0 012.122-2.122l3.585 3.586a.5.5 0 00.708 0l9.585-9.586a1.5 1.5 0 012.122 0zM23.06 46.06a1.5 1.5 0 00-2.12-2.12l-9.586 9.585a.5.5 0 01-.708 0L7.061 49.94a1.5 1.5 0 00-2.122 2.122l3.586 3.585a3.5 3.5 0 004.95 0l9.586-9.585z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletChecklistRegular)
