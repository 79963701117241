import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeAUpLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1.999a2 2 0 00-2 2v8.534c0 1.025 1.239 1.538 1.963.814l1.85-1.85 2.7 2.7a1.75 1.75 0 002.475 0l3.21-3.21a1.75 1.75 0 000-2.475l-2.7-2.7 1.85-1.85c.724-.724.211-1.963-.813-1.963H4zm-.5 2a.5.5 0 01.5-.5h7.69L9.907 5.281a.75.75 0 000 1.061l3.23 3.23a.25.25 0 010 .354l-3.21 3.21a.25.25 0 01-.353 0l-3.231-3.23a.75.75 0 00-1.06 0L3.5 11.688V4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 3A2.25 2.25 0 003 5.25v10.176c0 1.203 1.454 1.805 2.305.955l2.117-2.117 3.351 3.351a2 2 0 002.829 0l4.012-4.014a2 2 0 000-2.828l-3.35-3.351 2.117-2.117c.85-.85.248-2.305-.954-2.305H5.25zM4.5 5.25a.75.75 0 01.75-.75h9.814l-2.391 2.392a.75.75 0 000 1.06l3.88 3.882a.5.5 0 010 .707l-4.012 4.013a.5.5 0 01-.707 0l-3.882-3.881a.75.75 0 00-1.06 0L4.5 15.064V5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.875 3.249A2.625 2.625 0 003.25 5.874v12.814c0 1.336 1.616 2.006 2.56 1.06l2.97-2.97 4.254 4.254a2.25 2.25 0 003.182 0l4.815-4.817a2.25 2.25 0 000-3.181L16.78 8.78l2.971-2.97c.945-.946.276-2.561-1.06-2.561L5.875 3.249zM4.75 5.874c0-.621.504-1.125 1.125-1.125H18.69l-3.5 3.502a.75.75 0 000 1.06l4.782 4.783a.75.75 0 010 1.06l-4.816 4.817a.75.75 0 01-1.06 0L9.31 15.188a.75.75 0 00-1.06 0l-3.501 3.5V5.874z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 5A3.5 3.5 0 005 8.5v16.085c0 1.782 2.155 2.674 3.415 1.414l3.46-3.46 5.504 5.503a3 3 0 004.242 0l6.42-6.422a3 3 0 000-4.242l-5.502-5.503L26 8.415C27.26 7.154 26.368 5 24.586 5H8.5zM7 8.5A1.5 1.5 0 018.5 7h16.086l-4.168 4.168a1 1 0 000 1.414l6.21 6.21a1 1 0 010 1.414l-6.42 6.422a1 1 0 01-1.415 0l-6.21-6.21a1 1 0 00-1.415 0L7 24.584V8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 10a6.5 6.5 0 00-6.5 6.5v32.671c0 3.118 3.77 4.68 5.975 2.475L23.5 44.12l11.61 11.61a5.5 5.5 0 007.779 0l12.84-12.843a5.5 5.5 0 000-7.777L44.121 23.5l7.525-7.526C53.851 13.77 52.29 10 49.171 10H16.5zM13 16.5a3.5 3.5 0 013.5-3.5H49.17a.5.5 0 01.354.854l-8.586 8.586a1.5 1.5 0 000 2.12l12.669 12.672a2.5 2.5 0 010 3.535L40.768 53.61a2.5 2.5 0 01-3.537 0l-12.67-12.67a1.5 1.5 0 00-2.122 0l-8.586 8.585A.5.5 0 0113 49.17v-32.67z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeAUpLeftRegular)
