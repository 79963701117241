import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconXLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.145 3.148a.5.5 0 01.707 0l3.645 3.645 3.646-3.645a.5.5 0 11.707.707L8.205 7.5l3.645 3.645a.5.5 0 11-.707.707L7.497 8.207l-3.645 3.645a.5.5 0 11-.707-.707L6.79 7.5 3.145 3.855a.5.5 0 010-.707z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.146 4.146a.5.5 0 01.708 0L10 9.293l5.146-5.147a.5.5 0 11.707.708L10.707 10l5.146 5.146a.5.5 0 11-.707.707L10 10.707l-5.146 5.146a.5.5 0 11-.708-.707L9.293 10 4.146 4.854a.5.5 0 010-.708z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconXLight)
