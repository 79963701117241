import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCheckCircleCutLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.022 2.202a5.5 5.5 0 013.301.11.5.5 0 00.332-.944 6.5 6.5 0 104.25 7.235.5.5 0 00-.985-.17 5.5 5.5 0 11-6.898-6.23z"
          fill="currentColor"
        />
        <path
          d="M14.117 3.497a.5.5 0 10-.707-.708L7.905 8.294a.75.75 0 01-1.06 0L4.854 6.303a.5.5 0 10-.708.707L6.138 9a1.75 1.75 0 002.474 0l5.505-5.503z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7.487 3.32a6.5 6.5 0 013.89-.043.5.5 0 10.288-.958 7.5 7.5 0 105.258 8.257.5.5 0 00-.99-.144A6.5 6.5 0 117.487 3.32z"
        fill="currentColor"
      />
      <path
        d="M16.83 5.02a.5.5 0 10-.707-.708l-6.372 6.372a.984.984 0 01-1.392 0L5.994 8.32a.5.5 0 10-.707.707l2.365 2.364a1.985 1.985 0 002.806 0L16.83 5.02z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckCircleCutLight)
