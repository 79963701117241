import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLocationPinLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4a3 3 0 100 6 3 3 0 000-6zM6 7a2 2 0 114 0 2 2 0 01-4 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 .998c-2.272 0-4.32 1.234-5.358 3.275-1.036 2.036-.706 3.993.19 5.764.885 1.749 2.347 3.368 3.656 4.788.81.879 2.211.87 3.015-.01 1.292-1.412 2.748-3.01 3.64-4.738.902-1.75 1.252-3.688.254-5.726C12.376 2.264 10.304.998 8 .998zM3.533 4.726C4.398 3.026 6.099 1.998 8 1.998c1.928 0 3.648 1.054 4.499 2.792.817 1.67.562 3.263-.245 4.828-.82 1.588-2.178 3.09-3.489 4.522a1.054 1.054 0 01-1.542.007C5.9 12.712 4.537 11.19 3.725 9.585c-.801-1.582-1.04-3.191-.192-4.859z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.969a3.781 3.781 0 100 7.562 3.781 3.781 0 000-7.562zM7.219 8.75a2.781 2.781 0 115.562 0 2.781 2.781 0 01-5.562 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.688c-2.685 0-5.105 1.455-6.33 3.863-1.215 2.385-.841 4.679.208 6.768C4.916 14.387 6.639 16.306 8.202 18a2.442 2.442 0 003.583-.011c1.544-1.686 3.26-3.58 4.306-5.623 1.057-2.065 1.455-4.336.284-6.723C15.168 3.18 12.722 1.688 10 1.688zM4.56 6.005C5.613 3.937 7.685 2.688 10 2.688c2.347 0 4.441 1.282 5.477 3.395.991 2.02.686 3.949-.276 5.828-.974 1.902-2.592 3.698-4.153 5.403a1.442 1.442 0 01-2.11.008c-1.576-1.708-3.201-3.529-4.166-5.451-.954-1.9-1.24-3.848-.212-5.866z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLocationPinLight)
