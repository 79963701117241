import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeADownLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 14A1.75 1.75 0 012 12.25v-8.8c0-.98 1.185-1.472 1.878-.779L6.13 4.923 8.882 2.17a1.5 1.5 0 012.121 0l2.827 2.828a1.5 1.5 0 010 2.12l-2.752 2.754 2.251 2.252c.693.693.202 1.878-.778 1.878L3.751 14zM3 12.25c0 .415.336.75.75.75l8.802.002c.029 0 .045-.008.056-.016a.106.106 0 00.036-.046.106.106 0 00.007-.058.093.093 0 00-.029-.05l-2.605-2.607a.5.5 0 010-.707l3.106-3.106a.5.5 0 000-.707l-2.827-2.828a.5.5 0 00-.707 0L6.483 5.983a.5.5 0 01-.708 0L3.171 3.38a.099.099 0 00-.17.07v8.802z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 16.968A1.969 1.969 0 013.031 15V4.509c0-.975 1.178-1.463 1.867-.774L7.36 6.196l3.613-3.613a1.719 1.719 0 012.43 0l4.014 4.014c.67.671.67 1.76 0 2.43l-3.613 3.613 2.462 2.461c.688.69.2 1.867-.774 1.867H5zM3.969 15c0 .57.461 1.031 1.031 1.031h10.491c.14 0 .209-.168.11-.267l-2.792-2.792a.469.469 0 010-.663l3.944-3.944a.781.781 0 000-1.105L12.74 3.246a.781.781 0 00-1.105 0L7.69 7.191a.469.469 0 01-.663 0L4.235 4.398a.156.156 0 00-.266.11V15z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownLeftLight)
