import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconEyeOpenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5a3 3 0 100 6 3 3 0 000-6zM6 8a2 2 0 114 0 2 2 0 01-4 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.75c-3.406 0-6.317 2.102-7.468 5.07a.5.5 0 000 .36c1.15 2.968 4.062 5.07 7.468 5.07 3.406 0 6.318-2.102 7.468-5.07a.5.5 0 000-.36C14.318 4.851 11.406 2.75 8 2.75zm0 9.5c-2.916 0-5.412-1.757-6.463-4.25C2.587 5.507 5.084 3.75 8 3.75S13.412 5.507 14.463 8c-1.05 2.493-3.547 4.25-6.463 4.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.376a3.624 3.624 0 100 7.248 3.624 3.624 0 000-7.248zM7.376 10a2.624 2.624 0 115.248 0 2.624 2.624 0 01-5.248 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.563C5.941 3.563 2.452 5.98.937 9.44a1.394 1.394 0 000 1.118c1.515 3.46 5.004 5.879 9.063 5.879 4.059 0 7.548-2.419 9.063-5.879a1.394 1.394 0 000-1.118C17.548 5.98 14.059 3.562 10 3.562zM1.853 9.841c1.36-3.104 4.494-5.28 8.147-5.28 3.653 0 6.788 2.176 8.147 5.28.044.1.044.215 0 .316-1.36 3.104-4.493 5.28-8.147 5.28-3.653 0-6.788-2.176-8.147-5.28a.394.394 0 010-.316z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeOpenLight)
