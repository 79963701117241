import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowUiAuthLogoutRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 2.75c-.69 0-1.25.56-1.25 1.25v8c0 .69.56 1.25 1.25 1.25h5a.75.75 0 010 1.5H5A2.75 2.75 0 012.25 12V4A2.75 2.75 0 015 1.25h5a.75.75 0 010 1.5H5z"
          fill="currentColor"
        />
        <path
          d="M10.942 4.497a.75.75 0 00.053 1.06l1.871 1.693H6.5a.75.75 0 000 1.5h6.366l-1.871 1.694A.75.75 0 0012 11.556l2.7-2.444a1.5 1.5 0 000-2.224l-2.7-2.444a.75.75 0 00-1.06.053z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1.999 5.251a3.25 3.25 0 013.25-3.25h5a.75.75 0 010 1.5h-5a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75h5a.75.75 0 010 1.5h-5a3.25 3.25 0 01-3.25-3.25v-9.5z"
          fill="currentColor"
        />
        <path
          d="M15.928 10.75l-2.822 2.821a.75.75 0 001.061 1.06l3.273-3.272c.75-.75.75-1.967 0-2.718l-3.273-3.273a.75.75 0 10-1.06 1.06l2.82 2.822H6.75a.75.75 0 000 1.5h9.178z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.25 6A3.75 3.75 0 017 2.25h6a.75.75 0 010 1.5H7A2.25 2.25 0 004.75 6v12A2.25 2.25 0 007 20.25h6a.75.75 0 010 1.5H7A3.75 3.75 0 013.25 18V6z"
          fill="currentColor"
        />
        <path
          d="M20.275 12.752l-3.641 3.642a.75.75 0 001.06 1.06l3.928-3.927a2.156 2.156 0 000-3.05L17.694 6.55a.75.75 0 00-1.06 1.06l3.641 3.642H9a.75.75 0 000 1.5h11.275z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 7.5A5.5 5.5 0 018.5 2H19a1 1 0 110 2H8.5A3.5 3.5 0 005 7.5v17A3.5 3.5 0 008.5 28H19a1 1 0 110 2H8.5A5.5 5.5 0 013 24.5v-17z"
          fill="currentColor"
        />
        <path
          d="M27.367 17l-4.855 4.855a1 1 0 001.414 1.415l5.237-5.237a2.875 2.875 0 000-4.066L23.925 8.73a1 1 0 00-1.414 1.415L27.367 15H12a1 1 0 100 2h15.367z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21.5 9a7.5 7.5 0 00-7.5 7.5v31a7.5 7.5 0 007.5 7.5H38a1.5 1.5 0 010 3H21.5C15.701 58 11 53.299 11 47.5v-31C11 10.701 15.701 6 21.5 6H38a1.5 1.5 0 010 3H21.5z"
        fill="currentColor"
      />
      <path
        d="M46.19 18.94a1.5 1.5 0 000 2.12l9.439 9.44H25a1.5 1.5 0 000 3h30.629l-9.44 9.44a1.5 1.5 0 002.122 2.12l9.878-9.878a4.5 4.5 0 000-6.364l-9.878-9.879a1.5 1.5 0 00-2.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiAuthLogoutRegular)
