import { memo } from 'react'

import Button from '@ui/Button'
import Tooltip from '@ui/Tooltip'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import { IMarketingMaterial } from '@modules/marketing/models/Marketing'

import styles from './MaterialCard.module.scss'

interface IMaterialsCardProps {
  data: IMarketingMaterial
}

const MaterialsCard = ({ data }: IMaterialsCardProps) => (
  <div className={styles.materialCard}>
    <img src={data.cover} className={styles.image} />
    <div className={styles.box}>
      <div className={styles.name}>
        {data.name.length > 22 ? (
          <Tooltip text={data.name}>
            <Typography size={22} className={cx(styles.cut, styles.bigDesktop)}>
              {data.name}
            </Typography>
          </Tooltip>
        ) : (
          <Typography size={22} className={styles.bigDesktop}>
            {data.name}
          </Typography>
        )}
        {data.description && (
          <Typography size={16} className={styles.cut}>
            {data.description}
          </Typography>
        )}
      </div>
      <Button download size="small" href={data.file}>
        Скачать в PDF
      </Button>
    </div>
  </div>
)

export default memo(MaterialsCard)
