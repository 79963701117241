import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSendPaperplaneDiagRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.24 7.609c-1.03-.705-.8-2.287.39-2.667l11.084-3.544c1.16-.37 2.256.725 1.885 1.886l-3.544 11.083c-.38 1.19-1.962 1.42-2.666.39l-2.904-4.245L1.24 7.609zm5.755 2.454l2.632 3.848L13.17 2.827 2.087 6.371l3.848 2.631L8.46 6.477a.75.75 0 011.06 1.06l-2.525 2.526z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.112 9.125c-1.105-.765-.84-2.467.444-2.86l12.908-3.95c1.232-.377 2.385.776 2.008 2.008l-3.95 12.907c-.393 1.285-2.095 1.55-2.86.445L7.166 12.62 2.112 9.125zm6.566 3.045l3.218 4.651a.1.1 0 00.045.04c.015.006.036.01.06.006a.115.115 0 00.056-.024.1.1 0 00.03-.052l3.95-12.907a.1.1 0 00.004-.058.113.113 0 00-.03-.05.114.114 0 00-.05-.03.1.1 0 00-.058.003L2.995 7.7a.1.1 0 00-.051.031.115.115 0 00-.024.056.115.115 0 00.006.06.1.1 0 00.04.045l4.65 3.218 2.96-2.958a.75.75 0 111.06 1.06l-2.958 2.96z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.474 11.119c-1.203-.83-.92-2.68.477-3.112l15.667-4.843c1.344-.415 2.604.845 2.189 2.189L15.963 21.02c-.431 1.396-2.282 1.68-3.112.477l-4.238-6.14-6.14-4.238zm7.652 3.787l3.96 5.739a.25.25 0 00.445-.068L19.373 4.91a.25.25 0 00-.312-.313L3.394 9.44a.25.25 0 00-.068.444l5.739 3.961 3.664-3.664a.75.75 0 111.06 1.06l-3.663 3.665z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.658 14.31c-1.933-1.307-1.462-4.276.78-4.92l21.919-6.305c2.078-.598 4 1.325 3.403 3.403l-6.305 21.919c-.645 2.242-3.613 2.713-4.92.78L10.53 20.31l-8.872-6zm10.883 5.403l5.65 8.354a.75.75 0 001.342-.213l6.305-21.92a.75.75 0 00-.928-.927L2.99 11.31a.75.75 0 00-.213 1.342l8.348 5.646 5.667-5.666a1 1 0 011.414 1.414l-5.666 5.666z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.596 29.026c-3.515-2.377-2.659-7.774 1.418-8.947l43.838-12.61c3.778-1.086 7.274 2.41 6.188 6.188L43.43 57.495c-1.173 4.077-6.57 4.934-8.947 1.42L22.42 41.08 4.596 29.026zm20.84 11.159l11.532 17.049c.951 1.405 3.11 1.063 3.579-.568l12.61-43.838c.434-1.511-.964-2.91-2.475-2.475L6.843 22.962c-1.631.469-1.973 2.628-.568 3.579l17.039 11.522L34.939 26.44a1.5 1.5 0 112.121 2.121L25.436 40.185z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSendPaperplaneDiagRegular)
