import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDataChartTrendXyUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 1.5a.5.5 0 00-1 0V12a2 2 0 002 2h11.5a.5.5 0 000-1H3a1 1 0 01-1-1V1.5z"
          fill="currentColor"
        />
        <path
          d="M14.354 4.354a.5.5 0 00-.708-.708L9.5 7.793 7.566 5.859a.8.8 0 00-1.132 0L3.646 8.646a.5.5 0 10.708.708L7 6.707l1.934 1.934a.8.8 0 001.132 0l4.288-4.287z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3 3.5a.5.5 0 00-1 0v12A2.5 2.5 0 004.5 18h14a.5.5 0 000-1h-14A1.5 1.5 0 013 15.5v-12z"
        fill="currentColor"
      />
      <path
        d="M18.287 6.291a.5.5 0 10-.707-.707l-5.536 5.536-2.568-2.567a.89.89 0 00-1.26 0L4.96 11.81a.5.5 0 10.707.707l3.18-3.18 2.568 2.568a.89.89 0 001.26 0l5.613-5.614z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartTrendXyUpLight)
