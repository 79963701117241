import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMailRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.712 4.852a.75.75 0 00-.89 1.207l2.585 1.908a2.75 2.75 0 003.3-.025l2.48-1.89a.75.75 0 10-.909-1.193l-2.48 1.89a1.25 1.25 0 01-1.5.011L4.712 4.852z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 2A2.75 2.75 0 001 4.75v5.5A2.75 2.75 0 003.75 13h8.5A2.75 2.75 0 0015 10.25v-5.5A2.75 2.75 0 0012.25 2h-8.5zM2.5 4.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.036 7.105a.75.75 0 00-.931 1.176l2.878 2.279a3.25 3.25 0 004.034 0l2.878-2.279a.75.75 0 00-.93-1.176l-2.879 2.279a1.75 1.75 0 01-2.172 0L6.036 7.105z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.249 4.001a3.25 3.25 0 00-3.25 3.25v5.498a3.25 3.25 0 003.25 3.25h9.502a3.25 3.25 0 003.25-3.25V7.25a3.25 3.25 0 00-3.25-3.25H5.25zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H5.25a1.75 1.75 0 01-1.75-1.75V7.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.742 7.934a.75.75 0 10-.984 1.132l3.781 3.288a3.75 3.75 0 004.922 0l3.781-3.288a.75.75 0 10-.984-1.132l-3.782 3.288a2.25 2.25 0 01-2.952 0L6.742 7.934z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4.25A3.75 3.75 0 002.25 8v8A3.75 3.75 0 006 19.75h12A3.75 3.75 0 0021.75 16V8A3.75 3.75 0 0018 4.25H6zM3.75 8A2.25 2.25 0 016 5.75h12A2.25 2.25 0 0120.25 8v8A2.25 2.25 0 0118 18.25H6A2.25 2.25 0 013.75 16V8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.659 10.247a1 1 0 00-1.318 1.506l5.037 4.407a5.5 5.5 0 007.244 0l5.037-4.407a1 1 0 00-1.317-1.506l-5.037 4.408a3.5 3.5 0 01-4.61 0l-5.037-4.408z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 5A5.5 5.5 0 002 10.5v11A5.5 5.5 0 007.5 27h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 5h-17zM4 10.5A3.5 3.5 0 017.5 7h17a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 21.5v-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16.47 20.856a1.5 1.5 0 00-1.94 2.288l10.677 9.06a10.5 10.5 0 0013.586 0l10.678-9.06a1.5 1.5 0 00-1.941-2.288l-10.678 9.06a7.5 7.5 0 01-9.704 0l-10.677-9.06z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 11C10.701 11 6 15.701 6 21.5v21C6 48.299 10.701 53 16.5 53h31C53.299 53 58 48.299 58 42.5v-21C58 15.701 53.299 11 47.5 11h-31zM9 21.5a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5h-31A7.5 7.5 0 019 42.5v-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMailRegular)
