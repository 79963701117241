import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBagBriefcaseLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.75 1A1.75 1.75 0 004 2.75V4h-.5A2.5 2.5 0 001 6.5v5A2.5 2.5 0 003.5 14h8a2.5 2.5 0 002.5-2.5V7.012 6.5A2.5 2.5 0 0011.5 4H11V2.75A1.75 1.75 0 009.25 1h-3.5zM10 4V2.75A.75.75 0 009.25 2h-3.5a.75.75 0 00-.75.75V4h5zM3.5 5A1.5 1.5 0 002 6.5v.14l4.71 1.57a2.5 2.5 0 001.58 0L13 6.64V6.5A1.5 1.5 0 0011.5 5h-8zM2 11.5V7.694l4.393 1.464a3.5 3.5 0 002.214 0L13 7.694V11.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 11.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2A1.75 1.75 0 006 3.75V5H3.5A2.5 2.5 0 001 7.5v1.241a1.5 1.5 0 001 1.414V14.7A3.3 3.3 0 005.3 18h9.4a3.3 3.3 0 003.3-3.3v-4.545a1.5 1.5 0 001-1.414V7.5A2.5 2.5 0 0016.5 5H14V3.75A1.75 1.75 0 0012.25 2h-4.5zM13 5V3.75a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75V5h6zm3.5 1A1.5 1.5 0 0118 7.5v1.241a.5.5 0 01-.365.482l-6.962 1.945c-.44.123-.906.123-1.346 0L2.365 9.223A.5.5 0 012 8.74V7.5A1.5 1.5 0 013.5 6h13zm-5.558 6.131L17 10.438V14.7a2.3 2.3 0 01-2.3 2.3H5.3A2.3 2.3 0 013 14.7v-4.262l6.058 1.693a3.501 3.501 0 001.884 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBagBriefcaseLight)
