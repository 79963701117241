import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { IconLocationPinLight } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { EMainRoutes, getRouteById } from '@lib/navigation/MainRoutes'

import HelperImage from '@modules/marketing/helpers/HelperImage'
import { ICalendarItemProps } from '@modules/marketing/models/Calendar'
import { useDatesFormatter } from '@modules/marketing/services/DatesFormatter'

import styles from './styles.module.scss'

const CalendarItem = ({
  id,
  file,
  address,
  date,
  shortTitle,
  timeEnd,
  timeStart,
}: ICalendarItemProps) => {
  const navigate = useNavigate()
  const { day, weekDay, monthRod } = useDatesFormatter(date)

  const handleClick = useCallback(() => {
    navigate(getRouteById(id, EMainRoutes.MarketingEventCalendar))
  }, [])

  return (
    <div onClick={handleClick} className={styles.card}>
      <div className={styles.eventDate}>
        <div className={styles.date}>
          <Typography weight="medium">{`${weekDay},`}</Typography>
          <Typography weight="light">{`${day} ${monthRod}`}</Typography>
        </div>
        <Typography
          color={COLORS.GRAY.GRAY_2}
          size={12}
          className={styles.time}>
          {timeStart} - {timeEnd}
        </Typography>
      </div>
      <HelperImage
        image={file?.url}
        className={styles.image}
        X2src="CalendarsDefaultImageX2.png"
        src="CalendarsDefaultImage.png"
      />
      <div className={styles.location}>
        <Typography className={styles.title} weight="medium" size={16}>
          {shortTitle?.length > 32
            ? `${shortTitle?.substring(0, 32)}...`
            : shortTitle}
        </Typography>
        <div className={styles.address}>
          <IconLocationPinLight color={COLORS.BRAND.OCEAN} />
          <Typography color={COLORS.GRAY.GRAY_1} size={12}>
            {address?.length > 38 ? `${address?.substring(0, 38)}...` : address}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default observer(CalendarItem)
