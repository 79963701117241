import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconShareNetworkLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 4a2.5 2.5 0 11.621 1.65L6.375 7.217a2.498 2.498 0 010 1.563l2.746 1.57a2.5 2.5 0 11-.497.868L5.88 9.648a2.5 2.5 0 110-3.299l2.746-1.569A2.498 2.498 0 018.5 4zM11 2.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-7 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM9.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5a3 3 0 11.68 1.902L7.858 9.085a2.998 2.998 0 010 1.83l3.822 2.183a3 3 0 11-.497.868l-3.785-2.163a3 3 0 110-3.606l3.785-2.163A2.996 2.996 0 0111 5zm3-2a2 2 0 100 4 2 2 0 000-4zM5 8a2 2 0 100 4 2 2 0 000-4zm7 7a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconShareNetworkLight)
