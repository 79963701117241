import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFoldersRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.25 6.75A.75.75 0 013 6h7a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2.75C0 1.784.784 1 1.75 1h2.634c.56 0 1.086.268 1.415.72l.856 1.177A.25.25 0 006.857 3h3.393A2.75 2.75 0 0113 5.75v4.5A2.75 2.75 0 0110.25 13h-7.5A2.75 2.75 0 010 10.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25v-4.5c0-.69-.56-1.25-1.25-1.25H6.857a1.75 1.75 0 01-1.415-.72l-.856-1.177a.25.25 0 00-.202-.103H1.75z"
          fill="currentColor"
        />
        <path
          d="M15.5 5.75a.75.75 0 00-1.5 0v4.747a3.5 3.5 0 01-3.5 3.5H3.75a.75.75 0 000 1.5h6.75a5 5 0 005-5V5.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 8.75A.75.75 0 014.5 8h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 5c0-.966.784-1.75 1.75-1.75h3.99c.56 0 1.087.268 1.416.72l.856 1.177a.25.25 0 00.202.103H14A2.75 2.75 0 0116.75 8v6A2.75 2.75 0 0114 16.75H4A2.75 2.75 0 011.25 14V5zM3 4.75a.25.25 0 00-.25.25v9c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V8c0-.69-.56-1.25-1.25-1.25H9.464a1.75 1.75 0 01-1.416-.72l-.855-1.177a.25.25 0 00-.202-.103H3z"
          fill="currentColor"
        />
        <path
          d="M19.75 8.75a.75.75 0 00-1.5 0v5.75a3.75 3.75 0 01-3.75 3.75H5.75a.75.75 0 000 1.5h8.75c2.9 0 5.25-2.35 5.25-5.25V8.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.25 10A.75.75 0 015 9.25h12a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 5.875A2.625 2.625 0 013.875 3.25h4.063c.922 0 1.777.484 2.251 1.274l.436.726H17A3.75 3.75 0 0120.75 9v8A3.75 3.75 0 0117 20.75H5A3.75 3.75 0 011.25 17V5.875zM3.875 4.75c-.621 0-1.125.504-1.125 1.125V17A2.25 2.25 0 005 19.25h12A2.25 2.25 0 0019.25 17V9A2.25 2.25 0 0017 6.75h-6.375a1.5 1.5 0 01-1.287-.728l-.435-.726a1.125 1.125 0 00-.965-.546H3.875z"
          fill="currentColor"
        />
        <path
          d="M23.75 9.75a.75.75 0 00-1.5 0v7.75a4.75 4.75 0 01-4.75 4.75H5.75a.75.75 0 000 1.5H17.5a6.25 6.25 0 006.25-6.25V9.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.5 12a1 1 0 011-1h17a1 1 0 110 2h-17a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.5A3.5 3.5 0 013.5 2h6.25a3.5 3.5 0 012.8 1.4l1.5 2a1.5 1.5 0 001.2.6h7.25a5.5 5.5 0 015.5 5.5v11a5.5 5.5 0 01-5.5 5.5h-17A5.5 5.5 0 010 22.5v-17zM3.5 4A1.5 1.5 0 002 5.5v17A3.5 3.5 0 005.5 26h17a3.5 3.5 0 003.5-3.5v-11A3.5 3.5 0 0022.5 8h-7.25a3.5 3.5 0 01-2.8-1.4l-1.5-2a1.5 1.5 0 00-1.2-.6H3.5z"
          fill="currentColor"
        />
        <path
          d="M32 11a1 1 0 10-2 0v12a7 7 0 01-7 7H7a1 1 0 100 2h16a9 9 0 009-9V11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13 24.5a1.5 1.5 0 011.5-1.5h33a1.5 1.5 0 010 3h-33a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.5A6.5 6.5 0 0111.5 6h11.324a6.5 6.5 0 015.408 2.894l2.292 3.438a1.5 1.5 0 001.248.668H47.5a9.5 9.5 0 019.5 9.5v22a9.5 9.5 0 01-9.5 9.5h-33A9.5 9.5 0 015 44.5v-32zM11.5 9A3.5 3.5 0 008 12.5v32a6.5 6.5 0 006.5 6.5h33a6.5 6.5 0 006.5-6.5v-22a6.5 6.5 0 00-6.5-6.5H31.772a4.5 4.5 0 01-3.744-2.004l-2.292-3.438A3.5 3.5 0 0022.824 9H11.5z"
        fill="currentColor"
      />
      <path
        d="M64 23.5a1.5 1.5 0 00-3 0v22C61 52.404 55.404 58 48.5 58h-30a1.5 1.5 0 000 3h30C57.06 61 64 54.06 64 45.5v-22z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFoldersRegular)
