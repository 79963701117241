import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import PageWrapper from '@lib/components/Page/PageWrapper/PageWrapper'

import WebinarsCard from '@modules/marketing/components/WebinarsCard/WebinarsCard'
import { useWebinarsStore } from '@modules/marketing/store/WebinarsStore'

import styles from './styles.module.scss'

const Webinars = () => {
  const webinarsStore = useWebinarsStore()

  useEffect(() => {
    webinarsStore.getWebinars()
  }, [])

  return (
    <PageWrapper isLoading={webinarsStore?.loading} title="Вебинары">
      <div className={styles.container}>
        {webinarsStore.webinars?.map(({ file, id, title }) => (
          <WebinarsCard image={file?.url} key={id} id={id} title={title} />
        ))}
      </div>
    </PageWrapper>
  )
}

export default observer(Webinars)
