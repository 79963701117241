import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconToolPencilRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.357 1.515a1.75 1.75 0 00-2.475 0L2.515 9.882a1.75 1.75 0 00-.513 1.238v2.128a.75.75 0 00.75.75H4.86a1.75 1.75 0 001.236-.511l8.386-8.369a1.75 1.75 0 00.002-2.476l-1.128-1.127zm-1.414 1.06a.25.25 0 01.354 0l1.127 1.128a.25.25 0 010 .353l-1.006 1.005-1.48-1.48 1.005-1.006zM9.877 4.641l-6.302 6.302a.25.25 0 00-.073.177v1.378H4.86a.25.25 0 00.177-.073l6.318-6.305-1.479-1.479z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.357 3.515a1.75 1.75 0 00-2.475 0l-9.367 9.367a1.75 1.75 0 00-.513 1.238v2.128a.75.75 0 00.75.75H5.86a1.75 1.75 0 001.236-.511l9.387-9.369a1.75 1.75 0 000-2.476l-1.127-1.127zm-1.414 1.06a.25.25 0 01.354 0l1.127 1.128a.25.25 0 010 .353l-1.006 1.005-1.48-1.48 1.005-1.006zm-2.066 2.066l-7.302 7.302a.25.25 0 00-.073.177v1.378H5.86a.25.25 0 00.177-.073l7.318-7.305-1.479-1.479z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.265 3.669a2.25 2.25 0 00-3.181 0L3.909 15.844a2.25 2.25 0 00-.659 1.59V20c0 .414.336.75.75.75h2.566a2.25 2.25 0 001.59-.659L20.332 7.917a2.25 2.25 0 000-3.182l-1.066-1.066zm-2.12 1.06a.75.75 0 011.06 0l1.065 1.066a.75.75 0 010 1.06l-1.746 1.747-2.126-2.126 1.746-1.746zm-2.808 2.808L4.97 16.904a.75.75 0 00-.22.53v1.816h1.816a.75.75 0 00.53-.22l9.367-9.367-2.126-2.126z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.63 4.885a3 3 0 00-4.242 0L4.878 21.394A3 3 0 004 23.515V26.5A1.5 1.5 0 005.5 28h2.985a3 3 0 002.122-.878l16.509-16.51a3 3 0 000-4.242l-1.485-1.485zm-2.828 1.414a1 1 0 011.415 0l1.485 1.485a1 1 0 010 1.414l-2.396 2.396-2.9-2.9L22.803 6.3zm-3.81 3.81l-12.7 12.7a1 1 0 00-.292.706V26h2.485a1 1 0 00.707-.292l12.7-12.7-2.9-2.9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.382 10.898a5.5 5.5 0 00-7.779 0L11.611 41.89A5.5 5.5 0 0010 45.779V51.5a2.5 2.5 0 002.5 2.5h5.721a5.5 5.5 0 003.89-1.61l30.992-30.993a5.5 5.5 0 000-7.778l-2.721-2.721zm-5.657 2.12a2.5 2.5 0 013.535 0l2.721 2.722a2.5 2.5 0 010 3.536l-4.3 4.3-6.256-6.257 4.3-4.3zm-6.422 6.422l-24.57 24.571A2.5 2.5 0 0013 45.78V51h5.221a2.5 2.5 0 001.768-.732L44.56 25.697l-6.257-6.257z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconToolPencilRegular)
