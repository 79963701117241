import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetCloudLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.257 5.888a4.125 4.125 0 017.91-1.388 4.25 4.25 0 01.083 8.5h-7a3.625 3.625 0 01-.993-7.112zM7.375 3a3.125 3.125 0 00-3.122 3.267.5.5 0 01-.42.516A2.626 2.626 0 004.25 12h6.955l.029-.002a3.25 3.25 0 10-.369-6.488.5.5 0 01-.515-.345A3.127 3.127 0 007.375 3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.045 6.944a5.126 5.126 0 019.747-1.44A5.25 5.25 0 1114 16H5.25a4.625 4.625 0 01-1.205-9.056zM9.125 3.5a4.125 4.125 0 00-4.117 3.871.5.5 0 01-.395.46A3.626 3.626 0 005.293 15H14a4.25 4.25 0 10-.477-8.474.5.5 0 01-.525-.324A4.127 4.127 0 009.125 3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudLight)
