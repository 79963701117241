import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMathDeltaRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.446 3l-4.373 8.445A1.75 1.75 0 003.627 14h8.746a1.75 1.75 0 001.554-2.555L9.554 3.001C8.9 1.74 7.099 1.74 6.446 3zm.707 2.072l-3.455 6.73a.5.5 0 00.445.728h6.909a.5.5 0 00.445-.728l-3.455-6.73a.5.5 0 00-.89 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.305 18.003c1.502 0 2.468-1.594 1.773-2.926L11.773 2.993c-.747-1.433-2.798-1.433-3.546 0L1.922 15.077c-.695 1.332.271 2.926 1.773 2.926h12.61zm-2.3-1.601a.75.75 0 00.664-1.097L9.861 6.093a.75.75 0 00-1.33 0l-4.808 9.212a.75.75 0 00.665 1.097h9.616z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.882 3.28c.904-1.695 3.332-1.695 4.236 0l8.1 15.188c.853 1.599-.305 3.53-2.117 3.53H3.899c-1.811 0-2.97-1.931-2.117-3.53l8.1-15.189zm.542 2.688a1 1 0 011.76 0l6.955 12.915a1 1 0 01-.88 1.475H4.349a1 1 0 01-.88-1.475l6.955-12.915z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.284 4.951l9.648 17.488C30.31 24.938 28.502 28 25.648 28H6.352c-2.854 0-4.662-3.062-3.283-5.561L12.717 4.95c1.426-2.584 5.14-2.584 6.567 0zM17.08 7.7l8.618 15.32c.75 1.334-.214 2.981-1.743 2.981H6.72c-1.53 0-2.494-1.647-1.744-2.98L13.594 7.7c.764-1.36 2.722-1.36 3.486 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.687 9.273l19.972 36.079C60.057 49.684 56.924 55 51.972 55H12.028c-4.952 0-8.085-5.316-5.687-9.648L26.313 9.273c2.474-4.47 8.9-4.47 11.374 0zm-3.805 3.437l18.88 34.108c1.292 2.333-.395 5.196-3.061 5.196H11.938c-2.666 0-4.353-2.863-3.062-5.196L27.757 12.71c1.332-2.406 4.792-2.406 6.125 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathDeltaRegular)
