import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureACircleLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.75 8a.75.75 0 00-.75-.75H6.572L7.98 6.076a.75.75 0 10-.96-1.152L4.942 6.656c-.84.7-.84 1.989 0 2.688l2.078 1.732a.75.75 0 00.96-1.152L6.572 8.75H11a.75.75 0 00.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 8A7 7 0 101 8a7 7 0 0014 0zM8 2.5a5.5 5.5 0 110 11 5.5 5.5 0 010-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14 10a.75.75 0 00-.75-.75H8.056l2.009-1.674a.75.75 0 00-.96-1.152L6.427 8.656c-.84.7-.84 1.989 0 2.688l2.679 2.232a.75.75 0 00.96-1.152L8.057 10.75h5.192A.75.75 0 0014 10z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.999 10A7.999 7.999 0 102 10 7.999 7.999 0 0018 10zM10 3.501A6.499 6.499 0 1110 16.5 6.499 6.499 0 0110 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 12a.75.75 0 00-.75-.75H8.81l2.72-2.72a.75.75 0 00-1.06-1.06l-3.006 3.005a2.156 2.156 0 000 3.05l3.006 3.005a.75.75 0 101.06-1.06l-2.72-2.72h7.69a.75.75 0 00.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75zM12 3.75a8.25 8.25 0 110 16.5 8.25 8.25 0 010-16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23 16a1 1 0 00-1-1H11.59l3.308-3.308a1 1 0 10-1.414-1.414l-3.6 3.6a2.99 2.99 0 00-.877 2.002 1.012 1.012 0 000 .24 2.99 2.99 0 00.876 2.001l3.601 3.601a1 1 0 001.414-1.414L11.591 17H22a1 1 0 001-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 16c0-7.18-5.82-13-13-13S3 8.82 3 16s5.82 13 13 13 13-5.82 13-13zM16 5c6.075 0 11 4.925 11 11s-4.925 11-11 11S5 22.075 5 16 9.925 5 16 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M46.5 32a1.5 1.5 0 00-1.5-1.5H21.896l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-8.378 8.378a4.5 4.5 0 000 6.364l8.378 8.379a1.5 1.5 0 002.122-2.122l-7.94-7.939H45a1.5 1.5 0 001.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 32C58 17.64 46.36 6 32 6S6 17.64 6 32s11.64 26 26 26 26-11.64 26-26zM32 9c12.703 0 23 10.297 23 23S44.703 55 32 55 9 44.703 9 32 19.297 9 32 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleLeftRegular)
