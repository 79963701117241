import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyWalletALight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.626 9.126a.626.626 0 100-1.252.626.626 0 000 1.252z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.25C2 3.56 2.56 3 3.25 3h8.25a.5.5 0 000-1H3.25A2.25 2.25 0 001 4.25v7.5A2.25 2.25 0 003.25 14h8.5A2.25 2.25 0 0014 11.75V11a1 1 0 001-1V7a1 1 0 00-1-1h-.014a2.25 2.25 0 00-2.236-2H3.5a.5.5 0 000 1h8.25c.605 0 1.11.43 1.225 1H10.5a2.5 2.5 0 000 5H13v.75c0 .69-.56 1.25-1.25 1.25h-8.5C2.56 13 2 12.44 2 11.75v-7.5zM9 8.5A1.5 1.5 0 0110.5 7H14v3h-3.5A1.5 1.5 0 019 8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14 11.875a.875.875 0 100-1.75.875.875 0 000 1.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.5A1.5 1.5 0 014.5 4h10a.5.5 0 000-1h-10A2.5 2.5 0 002 5.5v9A2.5 2.5 0 004.5 17h11a2.5 2.5 0 002.5-2.5v-.507c.563-.062 1-.539 1-1.118v-3.75c0-.58-.438-1.056-1-1.118V7.5A2.5 2.5 0 0015.5 5H5a.5.5 0 000 1h10.5A1.5 1.5 0 0117 7.5V8h-3a3 3 0 100 6h3v.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 14.5v-9zM14 9h3.875c.069 0 .125.056.125.125v3.75a.125.125 0 01-.125.125H14a2 2 0 110-4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyWalletALight)
