import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { ICompany } from '../models/Company'

export type IEditCompanyParams = Partial<
  Pick<
    ICompany,
    'legalAddress' | 'actualAddress' | 'phone' | 'email' | 'inn' | 'name'
  >
>

@singleton()
export class CompanyRest extends AbstractRest {
  /**
   * Запрос компании по токену авторизации.
   */
  async getCompany() {
    const { data } = await this.http.get<ICompany>('/lk/company/my')

    return data
  }

  async editCompany(params: IEditCompanyParams) {
    const { data } = await this.http.patch<ICompany>('/lk/company/my', params)

    return data
  }

  async getEmployee(id: number) {
    const { data } = await this.http.get(`/lk/employees/${id}`)

    return data
  }

  async confirmEmployee(id: number) {
    const { data } = await this.http.post(`/lk/employees/success/${id}`)

    return data
  }

  async deleteEmployee(id: number) {
    const { data } = await this.http.delete(`/lk/employees/${id}`)

    return data
  }
}
