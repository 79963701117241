import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseTrianglesHClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.573 9.238a1.75 1.75 0 000-2.475L3.622 3.81C3.023 3.213 2 3.637 2 4.483v7.034c0 .847 1.023 1.27 1.622.672l2.951-2.951zm-1.06-1.415a.25.25 0 010 .354L3.5 10.189V5.811l2.013 2.012zM9.427 9.238a1.75 1.75 0 010-2.475l2.951-2.952c.599-.598 1.622-.174 1.622.672v7.034c0 .847-1.023 1.27-1.622.672L9.427 9.238zm1.06-1.415a.25.25 0 000 .354l2.013 2.012V5.811l-2.013 2.012z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47 11.591a2.25 2.25 0 000-3.182L5.134 5.073C4.346 4.286 3 4.843 3 5.957v8.086c0 1.114 1.346 1.671 2.134.884L8.47 11.59zM7.409 9.47a.75.75 0 010 1.06L4.5 13.44V6.56l2.909 2.91zM11.53 11.591a2.25 2.25 0 010-3.182l3.336-3.336c.787-.787 2.134-.23 2.134.884v8.086c0 1.114-1.346 1.671-2.134.884L11.53 11.59zm1.061-2.121a.75.75 0 000 1.06l2.909 2.91V6.56l-2.909 2.91z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.309 13.679a2.375 2.375 0 000-3.359L4.814 4.825c-.992-.993-2.689-.29-2.689 1.113v12.124c0 1.403 1.697 2.106 2.689 1.113l5.495-5.496zM9.07 11.558a.625.625 0 010 .884l-5.196 5.197V6.361l5.196 5.197zM13.691 13.679a2.375 2.375 0 010-3.359l5.495-5.495c.992-.993 2.689-.29 2.689 1.113v12.124c0 1.403-1.697 2.106-2.689 1.113l-5.495-5.496zm1.238-2.121a.625.625 0 000 .884l5.196 5.197V6.361l-5.196 5.197z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.94 18.475a3.5 3.5 0 000-4.95L6.413 6C5.154 4.74 3 5.632 3 7.414v17.172C3 26.368 5.154 27.26 6.414 26l7.525-7.525zm-1.415-3.536a1.5 1.5 0 010 2.122L5 24.586V7.414l7.525 7.525zM18.06 18.475a3.5 3.5 0 010-4.95L25.587 6C26.846 4.74 29 5.632 29 7.414v17.172c0 1.782-2.154 2.674-3.414 1.414l-7.525-7.525zm1.415-3.536a1.5 1.5 0 000 2.122L27 24.586V7.414l-7.525 7.525z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.232 35.889a5.5 5.5 0 000-7.778L11.975 12.853C9.77 10.65 6 12.21 6 15.328v33.344c0 3.118 3.77 4.68 5.975 2.474L27.232 35.89zm-2.121-5.657a2.5 2.5 0 010 3.536L9.854 49.025A.5.5 0 019 48.672V15.328a.5.5 0 01.854-.353L25.11 30.232zM36.767 35.889a5.5 5.5 0 010-7.778l15.258-15.258c2.205-2.204 5.974-.643 5.974 2.475v33.344c0 3.118-3.77 4.68-5.974 2.474L36.767 35.89zm2.122-5.657a2.5 2.5 0 000 3.536l15.257 15.257a.5.5 0 00.853-.353V15.328a.5.5 0 00-.853-.353L38.889 30.232z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesHClosedRegular)
