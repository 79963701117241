import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBooksLibraryRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.944 3.33a1 1 0 00-1.888-.66l-3.5 10a1 1 0 001.888.66l3.5-10zM9 2a1 1 0 011 1v10a1 1 0 11-2 0V3a1 1 0 011-1zM12 2a1 1 0 011 1v10a1 1 0 11-2 0V3a1 1 0 011-1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zM7.939 3.647a1 1 0 01.512 1.318L3.415 16.403a1 1 0 11-1.83-.806L6.62 4.16a1 1 0 011.318-.512zM16 4a1 1 0 10-2 0v12a1 1 0 102 0V4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 5.25a2 2 0 012-2h1.5c.473 0 .908.164 1.25.439a1.992 1.992 0 011.25-.439h1.5a2 2 0 012 2v13.5a2 2 0 01-2 2h-1.5c-.473 0-.908-.164-1.25-.439a1.991 1.991 0 01-1.25.44h-1.5a2 2 0 01-2-2V6.884c-.027.086-.06.171-.098.255L6.446 19.376a2 2 0 01-2.658.967l-1.36-.634a2 2 0 01-.967-2.658L7.167 4.816a2 2 0 012.658-.967l1.36.633c.532.249.904.703 1.065 1.224V5.25zm4 13.5V5.25a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v13.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5zm1.5-13.5v13.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V5.25a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5zm-8.56-.042a.5.5 0 00-.664.242L2.821 17.685a.5.5 0 00.242.665l1.36.633a.5.5 0 00.664-.241l5.705-12.236a.5.5 0 00-.242-.664l-1.36-.634z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6.75A2.75 2.75 0 0118.75 4h2.5c.665 0 1.274.236 1.75.629a2.739 2.739 0 011.75-.63h2.5A2.75 2.75 0 0130 6.75v18.5A2.75 2.75 0 0127.25 28h-2.5a2.74 2.74 0 01-1.75-.628 2.739 2.739 0 01-1.75.629h-2.5A2.75 2.75 0 0116 25.25V9.563L8.246 26.19a2.75 2.75 0 01-3.654 1.33l-2.266-1.056a2.75 2.75 0 01-1.33-3.655L8.814 6.043a2.75 2.75 0 013.655-1.33l2.265 1.057c.563.262.995.69 1.266 1.2v-.22zm8 18.5V6.75a.75.75 0 01.75-.75h2.5a.75.75 0 01.75.75v18.5a.75.75 0 01-.75.75h-2.5a.75.75 0 01-.75-.75zM21.25 6a.75.75 0 01.75.75v18.5a.75.75 0 01-.75.75h-2.5a.75.75 0 01-.75-.75V6.75a.75.75 0 01.75-.75h2.5zm-10.623.889a.75.75 0 01.996-.363l2.266 1.056a.75.75 0 01.363.997L6.433 25.346a.75.75 0 01-.996.363L3.17 24.652a.75.75 0 01-.363-.997L10.627 6.89z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.098 13.011A5.002 5.002 0 0135 9h6c1.363 0 2.598.545 3.5 1.43A4.984 4.984 0 0148 9h6a5 5 0 015 5v36a5 5 0 01-5 5h-6a4.984 4.984 0 01-3.5-1.43A4.984 4.984 0 0141 55h-6a5 5 0 01-5-5V22.04L16.218 51.597a5 5 0 01-6.645 2.419L4.136 51.48a5 5 0 01-2.419-6.645l15.214-32.627a5 5 0 016.645-2.418l5.438 2.535c.398.186.76.418 1.084.686zM46 50V14a2 2 0 012-2h6a2 2 0 012 2v36a2 2 0 01-2 2h-6a2 2 0 01-2-2zm-5-38a2 2 0 012 2v36a2 2 0 01-2 2h-6a2 2 0 01-2-2V14a2 2 0 012-2h6zm-21.35 1.476a2 2 0 012.658-.967l5.438 2.535a2 2 0 01.967 2.658L13.5 50.33a2 2 0 01-2.658.968L5.403 48.76a2 2 0 01-.967-2.658L19.65 13.476z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBooksLibraryRegular)
