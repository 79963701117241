import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSendPaperplaneLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.794 2.774C1.6 1.754 2.664.956 3.59 1.427l10.73 5.459a1.25 1.25 0 010 2.228l-10.73 5.46c-.926.47-1.989-.328-1.795-1.348L2.788 8l-.994-5.226zM3.711 8.5l-.934 4.913a.25.25 0 00.359.27l10.73-5.46a.25.25 0 000-.446L3.135 2.318a.25.25 0 00-.36.27L3.712 7.5h3.91a.5.5 0 010 1h-3.91z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.889 3.88c-.215-1.106.93-1.977 1.938-1.475l12.811 6.38c1.003.5 1.004 1.93 0 2.43l-12.81 6.38c-1.008.502-2.154-.37-1.94-1.474L4.079 10l-1.19-6.12zm2.11 6.62L3.87 16.31a.358.358 0 00.511.39l12.811-6.38a.358.358 0 000-.642L4.382 3.3a.358.358 0 00-.512.39L5 9.499h4.72a.5.5 0 010 1H5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSendPaperplaneLight)
