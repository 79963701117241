import React, { memo, useCallback, useEffect, useState } from 'react'

import { IconArrowCDownRegular } from '@ui/icons'
import { CommonIconSize } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import { SelectDropdown } from './SelectDropdown'
import styles from './styles.module.scss'
import { ISelectOption, ISelectProps } from './types'

const ICON_SIZE = {
  small: 16,
  medium: 20,
  large: 24,
}

const clearOption = {
  label: 'Не выбрано',
  value: null,
}

const Select = ({
  id,
  name,
  options,
  className,
  onSelect,
  placeholder = 'Выберите вариант',
  value,
  labelWeight = 'regular',
  labelSize = 16,
  label,
  clearable,
  state = 'default',
  size = 'medium',
  disabled = false,
  error = false,
  errorText = '',
}: ISelectProps) => {
  const [visible, setVisible] = useState(false)
  const [selectOptions] = useState<ISelectOption[]>(
    clearable ? [clearOption, ...options] : options,
  )
  const isOpened = visible && selectOptions.length > 0
  const initialOption = selectOptions.find((option) =>
    option.value === value ? option : null,
  )
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    initialOption,
  )
  const containerClass = cx(styles.container, className)
  const iconClass = cx(styles.icon, isOpened && styles.iconOpened)
  const selectClass = cx(
    styles.select,
    styles[size],
    disabled ? styles.disabled : styles[state],
  )

  useEffect(() => setSelectedOption(initialOption), [value])

  const toggle = useCallback(() => !disabled && setVisible(!visible), [visible])

  const onSelectHandler = (option: ISelectOption) => {
    if (option.value !== selectedOption?.value) {
      onSelect(name, option.value)
      setSelectedOption(option)
    }
    setVisible(false)
  }

  const blur = useCallback(() => !disabled && setVisible(false), [visible])

  return (
    <div className={containerClass}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          <Typography size={labelSize} weight={labelWeight}>
            {label}
          </Typography>
        </label>
      )}
      <div tabIndex={0} onBlur={blur} onClick={toggle} className={selectClass}>
        <div className={styles.selectWrapper}>
          {initialOption?.tag && (
            <Typography
              className={styles.selectWrapperTag}
              color={COLORS.GRAY.GANDALF}>
              {initialOption.tag.name}
            </Typography>
          )}

          <Typography>
            {selectedOption ? selectedOption?.label : placeholder}
          </Typography>
        </div>
        <IconArrowCDownRegular
          className={iconClass}
          color={COLORS.GRAY.GRAY_2}
          size={ICON_SIZE[size] as CommonIconSize}
        />
      </div>
      <SelectDropdown
        options={selectOptions}
        optionClassName={styles[size]}
        selectedOption={selectedOption}
        onSelect={onSelectHandler}
        opened={isOpened}
      />
      {error && errorText && (
        <Typography
          weight={labelWeight}
          size={labelSize}
          color={COLORS.STATE.ATTENTION_P}>
          {errorText}
        </Typography>
      )}
    </div>
  )
}

export default memo(Select)
