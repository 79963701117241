import React, { memo } from 'react'

import Loader from '@ui/Loader'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'
import { TypographySizeType } from '@ui/Typography/types'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'

interface PageWrapperProps {
  children: React.ReactNode
  title?: string
  className?: string
  fontSize?: TypographySizeType
  align?: 'start' | 'end' | 'center'
  headerRight?: React.ReactNode
  isLoading?: boolean
}

const PageWrapper = ({
  children,
  title,
  className,
  fontSize = 24,
  align,
  headerRight,
  isLoading,
}: PageWrapperProps) => (
  <div
    className={cx(styles.wrapper, className, {
      [styles[`align-${align}`]]: align,
    })}>
    {title && (
      <div className={styles.header}>
        <Typography className={styles.title} size={fontSize}>
          {title}
        </Typography>

        {headerRight}
      </div>
    )}
    {isLoading ? (
      <div className={styles.spinner}>
        <Loader color={COLORS.BRAND.OCEAN} />
      </div>
    ) : (
      children
    )}
  </div>
)

export default memo(PageWrapper)
