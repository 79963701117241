import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import TextPage from '@modules/support/components/TextPage/TextPage'

import { useSupportStore } from '../store/SupportStore'

const PrivacyPolicyPage = observer(() => {
  const store = useSupportStore()

  useEffect(() => {
    store.fetchPrivacyPolicy()
  }, [])

  return (
    <TextPage title="Политика конфиденциальности" markup={store?.politics} />
  )
})

export default PrivacyPolicyPage
