import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDocTextLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM5.5 8a.5.5 0 000 1h5a.5.5 0 000-1h-5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 1A2.5 2.5 0 003 3.5v8A2.5 2.5 0 005.5 14h5a2.5 2.5 0 002.5-2.5V5.485a1.5 1.5 0 00-.44-1.06L9.577 1.438A1.5 1.5 0 008.516 1H5.5zM4 3.5A1.5 1.5 0 015.5 2H8v2.5A1.5 1.5 0 009.5 6H12v5.5a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 11.5v-8zM11.722 5L9 2.278V4.5a.5.5 0 00.5.5h2.222z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.5 13.5A.5.5 0 017 13h3.5a.5.5 0 010 1H7a.5.5 0 01-.5-.5zM7 11a.5.5 0 000 1h6a.5.5 0 000-1H7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15a3 3 0 01-3 3H7a3 3 0 01-3-3V5a3 3 0 013-3h3.622a1.5 1.5 0 011.06.44l3.879 3.877A1.5 1.5 0 0116 7.378V15zM5 5a2 2 0 012-2h3v3a2 2 0 002 2h3v7a2 2 0 01-2 2H7a2 2 0 01-2-2V5zm9.83 2H12a1 1 0 01-1-1V3.17L14.83 7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocTextLight)
