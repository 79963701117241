import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconShapeRectHRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.75A2.75 2.75 0 013.75 2h8.5A2.75 2.75 0 0115 4.75v5.5A2.75 2.75 0 0112.25 13h-8.5A2.75 2.75 0 011 10.25v-5.5zM3.75 3.5c-.69 0-1.25.56-1.25 1.25v5.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25v-5.5c0-.69-.56-1.25-1.25-1.25h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.999 7.251a3.25 3.25 0 013.25-3.25h9.502a3.25 3.25 0 013.25 3.25v5.498a3.25 3.25 0 01-3.25 3.25H5.25a3.25 3.25 0 01-3.25-3.25V7.25zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v5.498c0 .966.783 1.75 1.75 1.75h9.502a1.75 1.75 0 001.75-1.75V7.25a1.75 1.75 0 00-1.75-1.75H5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 8A3.75 3.75 0 016 4.25h12A3.75 3.75 0 0121.75 8v8A3.75 3.75 0 0118 19.75H6A3.75 3.75 0 012.25 16V8zM6 5.75A2.25 2.25 0 003.75 8v8A2.25 2.25 0 006 18.25h12A2.25 2.25 0 0020.25 16V8A2.25 2.25 0 0018 5.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 10.5A5.5 5.5 0 017.5 5h17a5.5 5.5 0 015.5 5.5v11a5.5 5.5 0 01-5.5 5.5h-17A5.5 5.5 0 012 21.5v-11zM7.5 7A3.5 3.5 0 004 10.5v11A3.5 3.5 0 007.5 25h17a3.5 3.5 0 003.5-3.5v-11A3.5 3.5 0 0024.5 7h-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21.5C6 15.701 10.701 11 16.5 11h31C53.299 11 58 15.701 58 21.5v21C58 48.299 53.299 53 47.5 53h-31C10.701 53 6 48.299 6 42.5v-21zM16.5 14A7.5 7.5 0 009 21.5v21a7.5 7.5 0 007.5 7.5h31a7.5 7.5 0 007.5-7.5v-21a7.5 7.5 0 00-7.5-7.5h-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconShapeRectHRegular)
