import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTimeClockMoveBackRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.43 2.687A5.503 5.503 0 003.177 5.35a.751.751 0 01-.112.154h1.2a.75.75 0 010 1.5H1.75a.75.75 0 01-.75-.75V2.766a.75.75 0 111.5 0v.9a7.003 7.003 0 11-.788 7.416.75.75 0 111.347-.66 5.503 5.503 0 106.37-7.735z"
          fill="currentColor"
        />
        <path
          d="M8.5 4.699a.75.75 0 00-1.5 0v2.675c0 .67.383 1.282.987 1.574l2.134 1.035a.75.75 0 10.654-1.35L8.641 7.6a.25.25 0 01-.141-.225V4.699z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.797 3.755a6.499 6.499 0 00-7.276 2.75H6a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75v-3.5a.75.75 0 111.5 0v1.583a7.999 7.999 0 11-.703 8.14.75.75 0 111.35-.652 6.499 6.499 0 107.65-9.071z"
          fill="currentColor"
        />
        <path
          d="M10.48 6.443a.75.75 0 00-1.5 0v3.123a2 2 0 001.085 1.78l2.693 1.383a.75.75 0 10.685-1.334l-2.692-1.384a.5.5 0 01-.272-.445V6.443z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.467 3.881A8.25 8.25 0 004.652 8.25H7.5a.75.75 0 110 1.5H3A.75.75 0 012.25 9V4.246a.75.75 0 111.5 0v2.558a9.75 9.75 0 11-.778 8.877.75.75 0 111.389-.566A8.25 8.25 0 1013.467 3.88z"
          fill="currentColor"
        />
        <path
          d="M11.75 6.563a.75.75 0 01.75.75v3.948c0 .284.16.543.415.67l3.295 1.648a.75.75 0 11-.67 1.342l-3.296-1.648A2.25 2.25 0 0111 11.26V7.313a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18.179 5.218A11 11 0 006.202 11H9.5a1 1 0 110 2h-5A1.5 1.5 0 013 11.5V6a1 1 0 012 0v3.072a13 13 0 11-.905 12.15 1 1 0 111.831-.803A11 11 0 1018.18 5.218z"
          fill="currentColor"
        />
        <path
          d="M15 8.75a1 1 0 011 1v5.264a1 1 0 00.553.894l4.394 2.198a1 1 0 11-.894 1.788l-4.395-2.197A3 3 0 0114 15.014V9.75a1 1 0 011-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M37.548 9.68A23 23 0 0011.8 21H19.5a1.5 1.5 0 010 3H9.75A2.75 2.75 0 017 21.25V11a1.5 1.5 0 013 0v7.144a26 26 0 11-.984 26.012 1.5 1.5 0 012.652-1.403 23 23 0 1025.88-33.074z"
        fill="currentColor"
      />
      <path
        d="M32 19.5a1.5 1.5 0 10-3 0v10.528a5.5 5.5 0 003.04 4.92l8.79 4.394a1.5 1.5 0 001.34-2.684l-8.788-4.394A2.5 2.5 0 0132 30.028V19.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeClockMoveBackRegular)
