import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArchiveBoxRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.75 9a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3A.75.75 0 015.75 9z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 2C1.56 2 1 2.56 1 3.25v2.5c0 .605.43 1.11 1 1.225v4.775A2.25 2.25 0 004.25 14h7.5A2.25 2.25 0 0014 11.75V6.975c.57-.116 1-.62 1-1.225v-2.5C15 2.56 14.44 2 13.75 2H2.25zM12.5 7h-9v4.75c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75V7zm1-1.5v-2h-11v2h11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.25 11a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25 3C2.56 3 2 3.56 2 4.25v3c0 .605.43 1.11 1 1.225v6.775A2.75 2.75 0 005.75 18h8.5A2.75 2.75 0 0017 15.25V8.475c.57-.116 1-.62 1-1.225v-3C18 3.56 17.44 3 16.75 3H3.25zM15.5 8.5h-11v6.75c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25V8.5zm1-1.5V4.5h-13V7h13z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 13a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.25A1.75 1.75 0 002.25 5v3c0 .698.409 1.3 1 1.582V18A3.75 3.75 0 007 21.75h10A3.75 3.75 0 0020.75 18V9.582c.591-.281 1-.884 1-1.582V5A1.75 1.75 0 0020 3.25H4zm15.25 6.5H4.75V18A2.25 2.25 0 007 20.25h10A2.25 2.25 0 0019.25 18V9.75zm.75-1.5a.25.25 0 00.25-.25V5a.25.25 0 00-.25-.25H4a.25.25 0 00-.25.25v3c0 .138.112.25.25.25h16z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 17a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 4A2.5 2.5 0 002 6.5v4a2.5 2.5 0 002 2.45V24.5A4.5 4.5 0 008.5 29h15a4.5 4.5 0 004.5-4.5V12.95a2.5 2.5 0 002-2.45v-4A2.5 2.5 0 0027.5 4h-23zM26 13H6v11.5A2.5 2.5 0 008.5 27h15a2.5 2.5 0 002.5-2.5V13zm1.5-2a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-23a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h23z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M23 33.5a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 010 3h-15a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 9A4.5 4.5 0 005 13.5v8a4.502 4.502 0 003 4.244V48.5a9.5 9.5 0 009.5 9.5h29a9.5 9.5 0 009.5-9.5V25.744a4.502 4.502 0 003-4.244v-8A4.5 4.5 0 0054.5 9h-45zM53 26H11v22.5a6.5 6.5 0 006.5 6.5h29a6.5 6.5 0 006.5-6.5V26zm1.5-3a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5h-45A1.5 1.5 0 008 13.5v8A1.5 1.5 0 009.5 23h45z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveBoxRegular)
