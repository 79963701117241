import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { ICalendarEventsData } from '../models/Calendar'
import { CalendarRest } from '../services/CalendarRest'

@singleton()
export class CalendarStore {
  private calendarRest = container.resolve(CalendarRest)

  calendarEventsData: ICalendarEventsData[] = []
  calendarEventData: ICalendarEventsData = undefined
  isLoading = true

  constructor() {
    makeAutoObservable<CalendarStore, 'calendarRest'>(this, {
      calendarRest: false,
    })
  }

  private setCalendarEvents(data: ICalendarEventsData[]) {
    this.calendarEventsData = data
  }

  private setCalendarEvent(data: ICalendarEventsData) {
    this.calendarEventData = data
  }

  clear() {
    this.calendarEventData = undefined
  }

  async getCalendarEvent(id: number) {
    this.isLoading = true
    const data = await this.calendarRest.fetchCalendarEvent(id)
    this.setCalendarEvent(data)
    this.isLoading = false
  }

  async getCalendarEvents() {
    this.isLoading = true
    const data = await this.calendarRest.fetchCalendarEvents()

    this.setCalendarEvents(data)
    this.isLoading = false
  }
}

export const useCalendarStore = () => container.resolve(CalendarStore)
