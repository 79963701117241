import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowUiAuthLoginLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.5 2A1.5 1.5 0 0113 3.5v8a1.5 1.5 0 01-1.5 1.5h-5a.5.5 0 000 1h5a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0011.5 1h-5a.5.5 0 000 1h5z"
          fill="currentColor"
        />
        <path
          d="M6.127 4.164a.5.5 0 00.035.707L8.515 7H1.5a.5.5 0 000 1h7.015l-2.353 2.13a.5.5 0 10.672.74l2.7-2.443a1.25 1.25 0 000-1.854l-2.7-2.444a.5.5 0 00-.707.035z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8 2.5a.5.5 0 01.5-.5H15a3 3 0 013 3v9a3 3 0 01-3 3H8.5a.5.5 0 010-1H15a2 2 0 002-2V5a2 2 0 00-2-2H8.5a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
      <path
        d="M11.784 9H1.5a.5.5 0 000 1h10.284l-3.248 3.247a.5.5 0 10.707.707l3.273-3.272a1.672 1.672 0 000-2.365L9.243 5.044a.5.5 0 00-.707.707L11.784 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiAuthLoginLight)
