import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconShareNetworkRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 4a2.75 2.75 0 11.825 1.964L6.67 7.338a2.756 2.756 0 010 1.324l2.405 1.374a2.75 2.75 0 11-.745 1.302L5.925 9.964a2.75 2.75 0 110-3.928L8.33 4.662A2.757 2.757 0 018.25 4zM11 2.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-7 4a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM9.75 12a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 5a3.25 3.25 0 11.876 2.22L8.152 9.205a3.256 3.256 0 010 1.59l3.475 1.985a3.25 3.25 0 11-.745 1.302l-3.423-1.956a3.25 3.25 0 110-4.252l3.422-1.956A3.253 3.253 0 0110.75 5zM14 3.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm-9 5a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM12.25 15a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.05 6a3.75 3.75 0 11.925 2.466L9.58 10.885a3.749 3.749 0 010 2.23l4.394 2.419a3.75 3.75 0 11-.741 1.304l-4.377-2.41a3.75 3.75 0 110-4.858l4.377-2.408A3.747 3.747 0 0113.05 6zm3.75-2.25a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM6 9.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM14.55 18a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4 8a5 5 0 111.233 3.288l-5.858 3.225c.146.47.225.97.225 1.487a5 5 0 01-.225 1.487l5.858 3.225a5 5 0 11-.989 1.739l-5.834-3.213a5 5 0 110-6.477l5.835-3.212A4.996 4.996 0 0117.4 8zm5-3a3 3 0 100 6 3 3 0 000-6zM8 13a3 3 0 100 6 3 3 0 000-6zm11.4 11a3 3 0 116 0 3 3 0 01-6 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3 16a9.5 9.5 0 112.086 5.94l-12.442 6.85A9.483 9.483 0 0125.5 32a9.484 9.484 0 01-.556 3.21l12.442 6.85A9.5 9.5 0 1135.3 48c0-1.173.213-2.296.601-3.333l-12.406-6.83a9.5 9.5 0 110-11.676l12.406-6.828A9.48 9.48 0 0135.3 16zm9.5-6.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM16 25.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM38.3 48a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconShareNetworkRegular)
