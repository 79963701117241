import { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'

interface BadgeCountProps {
  count: string | number
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  className?: string
}

enum EFontSize {
  small = 12,
  medium = 14,
  large = 16,
}

const BadgeCount = ({
  count,
  disabled,
  size = 'small',
  className,
}: BadgeCountProps) => {
  const classNames = cx(
    styles.badge,
    { [styles.disabled]: disabled },
    styles[size],
    className,
  )
  return (
    <div className={classNames}>
      <Typography color={COLORS.GRAY.GANDALF} size={EFontSize[size]}>
        {count}
      </Typography>
    </div>
  )
}

export default memo(BadgeCount)
