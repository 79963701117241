import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCalculatorRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.5 9.074a.574.574 0 100-1.148.574.574 0 000 1.148zM6.074 10.5a.574.574 0 11-1.148 0 .574.574 0 011.148 0zM7.5 9.074a.574.574 0 100-1.148.574.574 0 000 1.148zM8.074 10.5a.574.574 0 11-1.148 0 .574.574 0 011.148 0zM9.5 9.074a.574.574 0 100-1.148.574.574 0 000 1.148zM10.074 10.5a.574.574 0 11-1.148 0 .574.574 0 011.148 0zM5.5 4a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 1A2.75 2.75 0 002 3.75v8.5A2.75 2.75 0 004.75 15h5.5A2.75 2.75 0 0013 12.25v-8.5A2.75 2.75 0 0010.25 1h-5.5zM3.5 3.75c0-.69.56-1.25 1.25-1.25h5.5c.69 0 1.25.56 1.25 1.25v8.5c0 .69-.56 1.25-1.25 1.25h-5.5c-.69 0-1.25-.56-1.25-1.25v-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.906 5a1 1 0 100 2h5a1 1 0 100-2h-5zM7.753 10.146a.85.85 0 11-1.698 0 .85.85 0 011.698 0zM6.906 14a.852.852 0 100-1.703.852.852 0 000 1.703zM10.377 10.148a.852.852 0 11-1.704 0 .852.852 0 011.704 0zM9.525 14a.852.852 0 100-1.703.852.852 0 000 1.703zM12.996 10.148a.852.852 0 11-1.704 0 .852.852 0 011.704 0zM12.144 14a.852.852 0 100-1.703.852.852 0 000 1.703z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.25A2.75 2.75 0 003.25 5v10A2.75 2.75 0 006 17.75h7A2.75 2.75 0 0015.75 15V5A2.75 2.75 0 0013 2.25H6zM4.75 5c0-.69.56-1.25 1.25-1.25h7c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H6c-.69 0-1.25-.56-1.25-1.25V5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.5 7.25a.75.75 0 000 1.5h7a.75.75 0 000-1.5h-7zM10 13a1 1 0 11-2 0 1 1 0 012 0zM9 17a1 1 0 100-2 1 1 0 000 2zM13 13a1 1 0 11-2 0 1 1 0 012 0zM12 17a1 1 0 100-2 1 1 0 000 2zM16 13a1 1 0 11-2 0 1 1 0 012 0zM15 17a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25A3.75 3.75 0 004.25 6v12A3.75 3.75 0 008 21.75h8A3.75 3.75 0 0019.75 18V6A3.75 3.75 0 0016 2.25H8zM5.75 6A2.25 2.25 0 018 3.75h8A2.25 2.25 0 0118.25 6v12A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.5 9a1 1 0 100 2h11a1 1 0 100-2h-11zM12 17a1 1 0 11-2 0 1 1 0 012 0zM11 23a1 1 0 100-2 1 1 0 000 2zM17 17a1 1 0 11-2 0 1 1 0 012 0zM16 23a1 1 0 100-2 1 1 0 000 2zM22 17a1 1 0 11-2 0 1 1 0 012 0zM21 23a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2A5.5 5.5 0 005 7.5v17a5.5 5.5 0 005.5 5.5h11a5.5 5.5 0 005.5-5.5v-17A5.5 5.5 0 0021.5 2h-11zM7 7.5A3.5 3.5 0 0110.5 4h11A3.5 3.5 0 0125 7.5v17a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 24.5v-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21 20a1.5 1.5 0 000 3h22a1.5 1.5 0 000-3H21zM24 34a2 2 0 11-4 0 2 2 0 014 0zM22 45a2 2 0 100-4 2 2 0 000 4zM34 34a2 2 0 11-4 0 2 2 0 014 0zM32 45a2 2 0 100-4 2 2 0 000 4zM44 34a2 2 0 11-4 0 2 2 0 014 0zM42 45a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6C15.701 6 11 10.701 11 16.5v31C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5v-31C53 10.701 48.299 6 42.5 6h-21zM14 16.5A7.5 7.5 0 0121.5 9h21a7.5 7.5 0 017.5 7.5v31a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCalculatorRegular)
