import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCheckSquareCutLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 4a2 2 0 012-2h5.5a.5.5 0 000-1H4a3 3 0 00-3 3v7a3 3 0 003 3h7a3 3 0 003-3V8.5a.5.5 0 00-1 0V11a2 2 0 01-2 2H4a2 2 0 01-2-2V4z"
          fill="currentColor"
        />
        <path
          d="M14.117 3.497a.5.5 0 10-.707-.708L7.905 8.294a.75.75 0 01-1.06 0L4.854 6.303a.5.5 0 10-.708.707L6.138 9a1.75 1.75 0 002.474 0l5.505-5.503z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3 5.5A2.5 2.5 0 015.5 3H12a.5.5 0 000-1H5.5A3.5 3.5 0 002 5.5v8A3.5 3.5 0 005.5 17h8a3.5 3.5 0 003.5-3.5V10a.5.5 0 00-1 0v3.5a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 013 13.5v-8z"
        fill="currentColor"
      />
      <path
        d="M16.83 4.658a.5.5 0 10-.707-.707l-6.372 6.372a.984.984 0 01-1.392 0L5.994 7.96a.5.5 0 10-.707.707l2.365 2.364a1.984 1.984 0 002.806 0l6.372-6.372z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckSquareCutLight)
