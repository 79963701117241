import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCommentRect2TextLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.5 6a.5.5 0 000 1h4a.5.5 0 000-1h-4zM3 4.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 1A2.5 2.5 0 000 3.5v5a2.5 2.5 0 002 2.45v1.616a1 1 0 001.555.832L7.15 11H10.5A2.5 2.5 0 0013 8.5v-5A2.5 2.5 0 0010.5 1h-8zM1 3.5A1.5 1.5 0 012.5 2h8A1.5 1.5 0 0112 3.5v5a1.5 1.5 0 01-1.5 1.5H7a.5.5 0 00-.277.084L3 12.566V10.5a.5.5 0 00-.5-.5A1.5 1.5 0 011 8.5v-5z"
          fill="currentColor"
        />
        <path
          d="M14.75 3.335a.5.5 0 10-.5.865c.45.26.75.745.75 1.3v5a1.5 1.5 0 01-1.5 1.5.5.5 0 00-.5.5v2.066l-3.723-2.482A.5.5 0 009 12H7.5a.5.5 0 000 1h1.349l3.596 2.398A1 1 0 0014 14.566V12.95a2.5 2.5 0 002-2.45v-5a2.5 2.5 0 00-1.25-2.165z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4 9.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM4.5 6a.5.5 0 000 1h8a.5.5 0 000-1h-8z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2a3 3 0 00-3 3v6a3 3 0 002 2.83v2.445a1 1 0 001.584.812L8.878 14H13a3 3 0 003-3V5a3 3 0 00-3-3H4zM2 5a2 2 0 012-2h9a2 2 0 012 2v6a2 2 0 01-2 2H8.717a.5.5 0 00-.291.094L4 16.274V13.45a.5.5 0 00-.4-.49A2 2 0 012 11V5z"
        fill="currentColor"
      />
      <path
        d="M17.8 4.6a.5.5 0 00-.6.8c.487.365.8.946.8 1.6v6.463c-.123.763-.822 1.464-1.552 1.54A.5.5 0 0016 15.5v2.066l-3.723-2.482A.5.5 0 0012 15H9a.5.5 0 000 1h2.849l3.596 2.398A1 1 0 0017 17.566v-1.66c1.033-.313 1.85-1.295 1.995-2.337A.5.5 0 0019 13.5V7c0-.982-.472-1.853-1.2-2.4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRect2TextLight)
