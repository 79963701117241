import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckARegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.895 3.595a.75.75 0 010 1.06l-6.833 6.833a2.337 2.337 0 01-3.306 0L2.22 8.95a.75.75 0 011.06-1.06l2.537 2.536a.838.838 0 001.185 0l6.832-6.832a.75.75 0 011.06 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.986 4.626a.75.75 0 010 1.06l-8.54 8.541a2.734 2.734 0 01-3.868 0l-3.17-3.17a.75.75 0 111.06-1.061l3.17 3.17a1.234 1.234 0 001.746 0l8.541-8.54a.75.75 0 011.06 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.077 5.657a.75.75 0 010 1.06l-10.249 10.25a3.131 3.131 0 01-4.428 0L2.595 13.16a.75.75 0 111.06-1.06l3.806 3.805c.637.637 1.67.637 2.306 0l10.25-10.249a.75.75 0 011.06 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M28.436 7.543a1 1 0 010 1.414L14.77 22.622a4.175 4.175 0 01-5.905 0L3.793 17.55a1 1 0 011.414-1.415l5.074 5.074c.85.85 2.226.85 3.076 0L27.022 7.543a1 1 0 011.414 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M56.518 15.44a1.5 1.5 0 010 2.12l-27.33 27.33a7.85 7.85 0 01-11.101 0L7.939 34.744a1.5 1.5 0 112.122-2.12l10.147 10.146a4.85 4.85 0 006.859 0l27.33-27.33a1.5 1.5 0 012.121 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckARegular)
