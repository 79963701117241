import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeARightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.396 6.587a2 2 0 010 2.828l-5.689 5.689c-.63.63-1.707.183-1.707-.707V12H2.75A1.75 1.75 0 011 10.25v-4.5C1 4.783 1.784 4 2.75 4H7V1.604C7 .713 8.077.266 8.707.897l5.69 5.69zm-1.06 1.767a.5.5 0 000-.707L8.5 2.811V4.75a.75.75 0 01-.75.75h-5a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h5a.75.75 0 01.75.75v1.94l4.836-4.836z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.772 8.41a2.25 2.25 0 010 3.18l-6.967 6.977c-.85.852-2.306.25-2.306-.954V14.75H4a2 2 0 01-2-2v-5.5a2 2 0 012-2h4.5V2.388c0-1.203 1.456-1.805 2.306-.954l6.967 6.977zm-1.062 2.12a.75.75 0 000-1.06L10 2.75V6a.75.75 0 01-.75.75H4a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5h5.25A.75.75 0 0110 14v3.25l6.71-6.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.205 10.143a2.625 2.625 0 010 3.712l-8.73 8.73c-.913.913-2.475.266-2.475-1.026V18H4.25A2.25 2.25 0 012 15.75v-7.5A2.25 2.25 0 014.25 6H10V2.44C10 1.147 11.562.5 12.476 1.414l8.729 8.73zm-1.06 2.652c.439-.44.439-1.152 0-1.591L11.5 2.56v4.19a.75.75 0 01-.75.75h-6.5a.75.75 0 00-.75.75v7.5c0 .414.336.75.75.75h6.5a.75.75 0 01.75.75v4.189l8.644-8.644z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.44 13.527a3.5 3.5 0 010 4.95L17.414 29.502c-1.26 1.26-3.415.367-3.415-1.415V23H6a3 3 0 01-3-3v-8a3 3 0 013-3h8V3.914c0-1.782 2.154-2.674 3.414-1.414L28.44 13.527zm-1.415 3.535a1.5 1.5 0 000-2.121L16 3.915V10a1 1 0 01-1 1H6a1 1 0 00-1 1v8a1 1 0 001 1h9a1 1 0 011 1v6.088l11.026-11.025z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.028 27.408a6.5 6.5 0 010 9.193L33.976 58.65C31.77 60.855 28 59.294 28 56.176V46h-15.5A5.5 5.5 0 017 40.5v-17a5.5 5.5 0 015.5-5.5H28V7.831c0-3.118 3.77-4.68 5.975-2.475l22.052 22.052zm-2.121 7.071a3.5 3.5 0 000-4.95L31.855 7.478A.5.5 0 0031 7.83v11.67a1.5 1.5 0 01-1.5 1.5h-17a2.5 2.5 0 00-2.5 2.5v17a2.5 2.5 0 002.5 2.5h17a1.5 1.5 0 011.5 1.5v11.675a.5.5 0 00.854.353l22.052-22.05z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeARightRegular)
