import React, { memo } from 'react'
import useCollapse from 'react-collapsed'

import Divider from '@ui/Divider'
import { IconArrowCDownRegular, IconArrowCUpRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.scss'

interface ICollapse {
  title: string
  subtitle?: string
  description?: string
  className?: string
  children: React.ReactNode
}

const Collapse = ({
  title,
  subtitle,
  description,
  className,
  children,
}: ICollapse) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <div className={cx(styles.collapse, className)}>
      <div className={styles.header} {...getToggleProps()}>
        <div className={styles.text}>
          <div>
            <Typography size={16} weight="medium">
              {title}
            </Typography>
            {subtitle && <Typography>{subtitle}</Typography>}
          </div>
          {description && <Typography size={14}>Выбор платформы</Typography>}
        </div>

        <div className={styles.icon}>
          {isExpanded ? (
            <IconArrowCUpRegular color={COLORS.GRAY.SAURON} />
          ) : (
            <IconArrowCDownRegular color={COLORS.GRAY.SAURON} />
          )}
        </div>
      </div>

      <div {...getCollapseProps()}>
        <div className={styles.wrapper}>
          <Divider className={styles.divider} />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(Collapse)
