import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoVkLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.366 10.77c-1.473-.22-2.504-1.064-3.205-2.212-.814-1.334-1.173-3.072-1.173-4.652h-1.25c0 1.738.39 3.72 1.356 5.303.982 1.609 2.57 2.808 4.881 2.865l.64.016V8.731a5.693 5.693 0 013.413 1.532c.438.412.758.843.965 1.203.103.18.175.336.219.457a.967.967 0 01.05.171h1.25c0-.2-.057-.412-.126-.6a4.254 4.254 0 00-.31-.652 6.608 6.608 0 00-1.191-1.49 6.942 6.942 0 00-2.06-1.344 7.043 7.043 0 001.238-.782c1.08-.854 1.912-2.058 1.912-3.32h-1.25c0 .723-.508 1.604-1.437 2.34-.752.593-1.702 1.027-2.672 1.162V3.906h-1.25v6.864z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.893 4.65a.5.5 0 01.357-.15h2.738a.5.5 0 01.5.49c.074 3.782 1.451 5.558 2.66 6.207V5a.5.5 0 01.5-.5h2.602a.5.5 0 01.5.5v3.319c.476-.183.973-.528 1.432-1.008a5.99 5.99 0 001.409-2.447.5.5 0 01.48-.364h2.58a.5.5 0 01.492.587c-.387 2.196-1.793 3.894-3.044 4.855a8.46 8.46 0 013.633 4.926.5.5 0 01-.482.632h-2.838a.5.5 0 01-.477-.347c-.5-1.563-1.672-2.771-3.185-3.136V15a.5.5 0 01-.5.5h-.286c-2.856 0-5.149-.986-6.737-2.832-1.578-1.834-2.41-4.46-2.477-7.658a.5.5 0 01.143-.36zm.875.85c.137 2.804.915 5.003 2.217 6.516 1.332 1.549 3.26 2.434 5.765 2.482v-3.072a.5.5 0 01.55-.497c2.113.21 3.734 1.68 4.465 3.571h1.8c-.839-2.27-2.549-3.553-3.635-4.062a.5.5 0 01-.038-.886c1.082-.626 2.574-2.087 3.131-4.052h-1.585a7.04 7.04 0 01-1.534 2.502c-.734.768-1.652 1.347-2.6 1.45a.5.5 0 01-.554-.498V5.5H9.148v6.427a.5.5 0 01-.621.485C6.695 11.953 4.725 9.878 4.505 5.5H2.768z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoVkLight)
