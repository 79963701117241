import { FC, memo, useMemo } from 'react'

import { format } from 'date-fns'

import { IInputProps, Input } from '@ui/Input'

// [TODO]: Добавить библиотеку для работы с input type='date'

export interface IDateInputProps extends Omit<IInputProps, 'value' | 'type'> {
  value?: string
}
const DateInput: FC<IDateInputProps> = ({ ...rest }) => {
  const minDate = useMemo(() => format(new Date(), 'yyyy-MM-dd'), [])

  return <Input min={minDate} type="date" placeholder="__.__.____" {...rest} />
}

export default memo(DateInput)
