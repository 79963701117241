import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoInstagramRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.324 4.5a.824.824 0 11-1.648 0 .824.824 0 011.648 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4.635a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75zM6.025 8.01a1.975 1.975 0 113.95 0 1.975 1.975 0 01-3.95 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.95 1.004h.101c1.366 0 2.461 0 3.327.108.896.11 1.648.347 2.268.896.123.108.239.224.347.346.548.62.785 1.373.896 2.268.108.866.108 1.961.107 3.327v.102c0 1.366 0 2.461-.107 3.327-.111.896-.348 1.648-.896 2.268a3.99 3.99 0 01-.347.347c-.62.548-1.372.785-2.268.896-.866.108-1.961.108-3.327.107h-.102c-1.365 0-2.46 0-3.327-.107-.895-.111-1.648-.348-2.268-.896a3.995 3.995 0 01-.346-.347c-.549-.62-.785-1.372-.896-2.268-.108-.866-.108-1.961-.108-3.327v-.102c0-1.366 0-2.46.108-3.327.11-.895.347-1.648.896-2.268a4 4 0 01.346-.346c.62-.549 1.373-.785 2.268-.896.866-.108 1.961-.108 3.327-.108zM4.806 2.6c-.744.093-1.157.264-1.46.531-.076.068-.148.14-.216.217-.267.302-.438.715-.53 1.46-.096.762-.097 1.764-.097 3.192 0 1.429.001 2.43.096 3.193.093.745.264 1.158.531 1.46.068.076.14.149.217.216.302.267.715.439 1.46.531.762.095 1.764.096 3.192.096 1.429 0 2.43 0 3.193-.096.745-.092 1.158-.264 1.46-.53.076-.068.149-.14.216-.217.267-.302.439-.715.531-1.46.095-.762.096-1.764.096-3.193 0-1.428 0-2.43-.096-3.193-.092-.744-.264-1.157-.53-1.46a2.493 2.493 0 00-.217-.216c-.302-.267-.715-.438-1.46-.53-.762-.096-1.764-.097-3.193-.097-1.428 0-2.43.001-3.193.096z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.227 5.736a.958.958 0 11-1.917 0 .958.958 0 011.917 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5.94a4.06 4.06 0 100 8.12 4.06 4.06 0 000-8.12zM7.44 10a2.56 2.56 0 115.12 0 2.56 2.56 0 01-5.12 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.955 2h.09c1.486 0 2.663 0 3.598.103.96.105 1.755.327 2.437.832.375.278.707.61.985.985.505.681.727 1.477.832 2.437C18 7.292 18 8.469 18 9.955v.09c0 1.486 0 2.663-.103 3.598-.105.96-.327 1.755-.832 2.437-.278.375-.61.707-.985.985-.681.505-1.477.727-2.437.832-.935.103-2.112.103-3.598.103h-.09c-1.486 0-2.663 0-3.598-.103-.96-.105-1.756-.327-2.437-.832a4.75 4.75 0 01-.985-.985c-.505-.681-.727-1.477-.832-2.437C2 12.708 2 11.531 2 10.045v-.09c0-1.486 0-2.663.103-3.598.105-.96.327-1.756.832-2.437a4.75 4.75 0 01.985-.985c.681-.505 1.477-.727 2.437-.832C7.292 2 8.469 2 9.955 2zM6.52 3.594c-.83.091-1.327.264-1.707.546a3.25 3.25 0 00-.674.674c-.282.38-.455.877-.546 1.707C3.5 7.365 3.5 8.459 3.5 10c0 1.54.001 2.635.094 3.48.091.829.264 1.326.546 1.706.19.257.417.484.674.674.38.282.877.455 1.707.546.844.093 1.938.094 3.479.094 1.54 0 2.635-.001 3.48-.094.829-.091 1.326-.264 1.706-.546.257-.19.484-.417.674-.674.282-.38.455-.877.546-1.707.093-.844.094-1.938.094-3.479 0-1.54-.001-2.635-.094-3.48-.091-.829-.264-1.326-.546-1.706a3.248 3.248 0 00-.674-.674c-.38-.282-.877-.455-1.707-.546C12.635 3.5 11.541 3.5 10 3.5c-1.54 0-2.635.001-3.48.094z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18.534 6.67a1.198 1.198 0 11-2.396 0 1.198 1.198 0 012.396 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.032a4.968 4.968 0 100 9.936 4.968 4.968 0 000-9.936zM8.532 12a3.468 3.468 0 116.936 0 3.468 3.468 0 01-6.936 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.952 1.98h.097c1.963 0 3.502 0 4.717.14 1.242.144 2.242.442 3.078 1.106.345.273.657.585.93.93.664.837.962 1.836 1.106 3.078.14 1.216.14 2.754.14 4.718v.097c0 1.963 0 3.502-.14 4.717-.143 1.242-.442 2.242-1.105 3.078a5.749 5.749 0 01-.93.93c-.837.665-1.837.963-3.079 1.106-1.215.14-2.754.14-4.717.14h-.097c-1.964 0-3.502 0-4.718-.14-1.242-.143-2.241-.441-3.078-1.105a5.75 5.75 0 01-.93-.93c-.664-.837-.962-1.837-1.106-3.079-.14-1.215-.14-2.754-.14-4.717v-.097c0-1.963 0-3.502.14-4.718.144-1.242.442-2.241 1.106-3.078a5.75 5.75 0 01.93-.93c.837-.664 1.836-.962 3.078-1.106 1.216-.14 2.754-.14 4.718-.14zM7.406 3.61c-1.104.128-1.79.371-2.318.79a4.25 4.25 0 00-.687.688c-.42.529-.663 1.214-.79 2.318C3.48 8.526 3.48 9.978 3.48 12c0 2.022.001 3.475.13 4.594.128 1.104.371 1.79.79 2.318.203.255.434.485.688.688.529.419 1.214.662 2.318.79 1.12.13 2.572.13 4.594.13 2.022 0 3.475 0 4.594-.13 1.104-.128 1.79-.371 2.318-.79.255-.203.485-.433.688-.688.419-.528.662-1.214.79-2.318.129-1.12.13-2.572.13-4.594 0-2.022-.001-3.474-.13-4.594-.128-1.104-.371-1.79-.79-2.318a4.255 4.255 0 00-.688-.687c-.529-.42-1.214-.663-2.318-.79-1.12-.13-2.572-.13-4.594-.13-2.022 0-3.474 0-4.594.13z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.517 16a6.483 6.483 0 1112.966 0 6.483 6.483 0 01-12.966 0zM16 11.517a4.483 4.483 0 100 8.966 4.483 4.483 0 000-8.966z"
          fill="currentColor"
        />
        <path
          d="M22.75 10.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.936 3h.128c2.527 0 4.51 0 6.078.18 1.602.183 2.896.564 3.983 1.416.477.373.906.802 1.279 1.279.852 1.087 1.233 2.381 1.417 3.983C29 11.426 29 13.41 29 15.936v.128c0 2.527 0 4.51-.18 6.078-.183 1.602-.564 2.896-1.416 3.983a7.508 7.508 0 01-1.279 1.279c-1.087.852-2.381 1.233-3.983 1.417C20.574 29 18.59 29 16.064 29h-.128c-2.527 0-4.51 0-6.078-.18-1.602-.183-2.896-.564-3.983-1.416a7.504 7.504 0 01-1.279-1.279c-.852-1.087-1.233-2.381-1.417-3.983C3 20.574 3 18.59 3 16.064v-.128c0-2.527 0-4.51.18-6.078.183-1.602.564-2.896 1.416-3.983a7.5 7.5 0 011.279-1.279c1.087-.852 2.381-1.233 3.983-1.417C11.426 3 13.41 3 15.936 3zm-5.85 2.166c-1.42.163-2.3.473-2.978 1.004a5.5 5.5 0 00-.938.938c-.53.678-.841 1.557-1.004 2.978C5.002 11.526 5 13.396 5 16c0 2.605.002 4.473.166 5.914.163 1.42.473 2.3 1.004 2.978.274.35.589.664.938.938.678.53 1.557.841 2.978 1.004 1.44.165 3.31.166 5.914.166 2.605 0 4.473-.002 5.914-.166 1.42-.163 2.3-.473 2.978-1.005.35-.273.664-.588.938-.937.53-.678.841-1.557 1.004-2.978.165-1.44.166-3.31.166-5.914 0-2.605-.002-4.473-.166-5.914-.163-1.42-.473-2.3-1.005-2.978a5.504 5.504 0 00-.937-.938c-.678-.53-1.557-.841-2.978-1.004C20.474 5.002 18.604 5 16 5c-2.605 0-4.473.002-5.914.166z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.534 32c0-6.885 5.581-12.466 12.466-12.466 6.885 0 12.466 5.581 12.466 12.466 0 6.885-5.581 12.466-12.466 12.466-6.885 0-12.466-5.581-12.466-12.466zM32 22.534a9.466 9.466 0 100 18.932 9.466 9.466 0 000-18.932z"
        fill="currentColor"
      />
      <path d="M45.5 21a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.907 7h.186c4.844 0 8.603 0 11.575.325 3.02.33 5.407 1.01 7.426 2.496a14.5 14.5 0 013.085 3.085c1.485 2.019 2.166 4.406 2.496 7.426C57 23.304 57 27.063 57 31.907v.186c0 4.844 0 8.603-.325 11.575-.33 3.02-1.01 5.407-2.496 7.426a14.498 14.498 0 01-3.085 3.085c-2.019 1.485-4.406 2.166-7.426 2.496C40.696 57 36.937 57 32.093 57h-.186c-4.844 0-8.603 0-11.575-.325-3.02-.33-5.407-1.01-7.426-2.496a14.5 14.5 0 01-3.085-3.085c-1.485-2.019-2.166-4.406-2.496-7.426C7 40.696 7 36.937 7 32.093v-.186c0-4.844 0-8.603.325-11.575.33-3.02 1.01-5.407 2.496-7.426a14.5 14.5 0 013.085-3.085c2.019-1.485 4.406-2.166 7.426-2.496C23.304 7 27.063 7 31.907 7zm-11.25 3.307c-2.761.302-4.554.886-5.973 1.93a11.499 11.499 0 00-2.447 2.447c-1.044 1.42-1.628 3.212-1.93 5.974C10.002 23.448 10 27.044 10 32s.002 8.552.307 11.342c.302 2.762.886 4.555 1.93 5.974.688.934 1.513 1.76 2.447 2.447 1.42 1.044 3.212 1.628 5.974 1.93 2.79.305 6.386.307 11.342.307s8.552-.002 11.342-.307c2.762-.302 4.555-.886 5.974-1.93a11.499 11.499 0 002.447-2.447c1.044-1.42 1.628-3.212 1.93-5.974.305-2.79.307-6.386.307-11.342s-.002-8.552-.307-11.342c-.302-2.762-.886-4.555-1.93-5.974a11.499 11.499 0 00-2.447-2.447c-1.42-1.044-3.212-1.628-5.974-1.93C40.552 10.002 36.956 10 32 10s-8.552.002-11.342.307z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoInstagramRegular)
