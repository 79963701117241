import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechPcLaptopLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 2A2.5 2.5 0 002 4.5v4A2.5 2.5 0 004.5 11h7A2.5 2.5 0 0014 8.5v-4A2.5 2.5 0 0011.5 2h-7zM3 4.5A1.5 1.5 0 014.5 3h7A1.5 1.5 0 0113 4.5v4a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 8.5v-4z"
          fill="currentColor"
        />
        <path
          d="M2.42 11.315a.5.5 0 10-.776-.63L.606 11.962C-.058 12.778.523 14 1.576 14h12.848c1.053 0 1.634-1.222.97-2.038l-1.038-1.277a.5.5 0 00-.776.63l1.038 1.277a.25.25 0 01-.194.408H1.576a.25.25 0 01-.194-.408l1.038-1.277z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3a3 3 0 00-3 3v5a3 3 0 003 3h8a3 3 0 003-3V6a3 3 0 00-3-3H6zM4 6a2 2 0 012-2h8a2 2 0 012 2v5a2 2 0 01-2 2H6a2 2 0 01-2-2V6z"
        fill="currentColor"
      />
      <path
        d="M3.421 14.823a.5.5 0 00-.763-.646L1.512 15.53C.687 16.506 1.379 18 2.657 18h14.686c1.277 0 1.97-1.494 1.145-2.47l-1.146-1.353a.5.5 0 00-.763.646l1.146 1.354a.5.5 0 01-.382.823H2.657a.5.5 0 01-.382-.823l1.146-1.354z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPcLaptopLight)
