import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTrashCanRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.5 6.194c.345 0 .625.28.625.625v4.362a.625.625 0 11-1.25 0V6.819c0-.345.28-.625.625-.625zM10.125 6.82a.625.625 0 10-1.25 0v4.36a.625.625 0 101.25 0V6.82z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 3.25v-1a2 2 0 012-2h3.5a2 2 0 012 2v1h2.5a.75.75 0 010 1.5h-.571l-.798 7.974a2.25 2.25 0 01-2.239 2.026H5.357a2.25 2.25 0 01-2.238-2.026L2.32 4.75H1.75a.75.75 0 010-1.5h2.5zm1.5-1a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v1h-4.5v-1zm-1.921 2.5l.782 7.825a.75.75 0 00.746.675h5.285a.75.75 0 00.747-.675l.782-7.825H3.83z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.5 7.375c.345 0 .625.28.625.625v6.36a.625.625 0 11-1.25 0V8c0-.345.28-.625.625-.625zM12.125 8a.625.625 0 10-1.25 0v6.36a.625.625 0 101.25 0V8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4.25v-.99a2.25 2.25 0 012.25-2.25h3.5A2.25 2.25 0 0114 3.26v.99h3.5a.75.75 0 010 1.5h-.82l-.957 9.771a2.75 2.75 0 01-2.737 2.482H7.014a2.75 2.75 0 01-2.737-2.482L3.32 5.75H2.5a.75.75 0 010-1.5H6zm1.5-.99a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75v.99h-5v-.99zM5.77 15.374L4.827 5.75h10.346l-.943 9.625a1.25 1.25 0 01-1.244 1.128H7.014a1.25 1.25 0 01-1.244-1.128z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.349 9.665a.75.75 0 00-1.497.108l.537 7.468a.75.75 0 001.496-.107L9.35 9.665zM15.454 8.97a.75.75 0 01.694.803l-.536 7.468a.75.75 0 01-1.496-.107l.536-7.469a.75.75 0 01.802-.694zM12.75 9.719a.75.75 0 10-1.5 0v7.469a.75.75 0 001.5 0V9.719z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.007 3.627V4.5H3.21a.75.75 0 100 1.5h.783l1.154 12.63a3.75 3.75 0 003.734 3.408h6.239a3.75 3.75 0 003.734-3.409L20.008 6h.782a.75.75 0 000-1.5h-3.786v-.873a2.625 2.625 0 00-2.625-2.625H9.632a2.625 2.625 0 00-2.625 2.625zm2.625-1.125c-.622 0-1.125.504-1.125 1.125V4.5h6.997v-.873c0-.621-.504-1.125-1.125-1.125H9.632zM5.5 6l1.14 12.493a2.25 2.25 0 002.241 2.045h6.239a2.25 2.25 0 002.24-2.045L18.502 6H5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 8c-.023 0-.046 0-.07-.002H3.884a1 1 0 110-2H9V4.5A3.5 3.5 0 0112.5 1h7A3.5 3.5 0 0123 4.5v1.498h5.14a1 1 0 110 2h-6.07a1.012 1.012 0 01-.14 0H10.07A1.012 1.012 0 0110 8zm2.5-5A1.5 1.5 0 0011 4.5v1.498h10V4.5A1.5 1.5 0 0019.5 3h-7z"
          fill="currentColor"
        />
        <path
          d="M6.994 10.89a1 1 0 00-1.988.22l1.605 14.442A5 5 0 0011.58 30h8.839a5 5 0 004.97-4.448l1.604-14.442a1 1 0 00-1.988-.22L23.401 25.33A3 3 0 0120.42 28h-8.84a3 3 0 01-2.982-2.669L6.994 10.89z"
          fill="currentColor"
        />
        <path
          d="M12.246 13.917a1 1 0 10-1.993.166l.75 9a1 1 0 001.993-.166l-.75-9zM20.833 13.004a1 1 0 01.914 1.079l-.75 9a1 1 0 11-1.994-.166l.75-9a1 1 0 011.08-.913zM17 14a1 1 0 10-2 0v9a1 1 0 102 0v-9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10.5V13H7.5a1.5 1.5 0 000 3h49a1.5 1.5 0 000-3H45v-2.5A6.5 6.5 0 0038.5 4h-13a6.5 6.5 0 00-6.5 6.5zM25.5 7a3.5 3.5 0 00-3.5 3.5V13h20v-2.5A3.5 3.5 0 0038.5 7h-13z"
        fill="currentColor"
      />
      <path
        d="M13.49 21.334a1.5 1.5 0 10-2.98.332l3.208 28.883A9.5 9.5 0 0023.16 59h17.68a9.5 9.5 0 009.442-8.45l3.209-28.884a1.5 1.5 0 10-2.982-.332L47.3 50.218A6.5 6.5 0 0140.84 56H23.16a6.5 6.5 0 01-6.46-5.782l-3.21-28.884z"
        fill="currentColor"
      />
      <path
        d="M23.995 27.382a1.5 1.5 0 10-2.99.236l1.5 19a1.5 1.5 0 102.99-.236l-1.5-19zM41.618 26.005a1.5 1.5 0 011.377 1.613l-1.5 19a1.5 1.5 0 11-2.99-.236l1.5-19a1.5 1.5 0 011.613-1.377zM33.5 27.5a1.5 1.5 0 00-3 0v19a1.5 1.5 0 003 0v-19z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTrashCanRegular)
