import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1BadgeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M3.93 11.765c-.251.339-.43.808-.43 1.488a.75.75 0 01-1.5 0c0-.964.26-1.754.723-2.38.457-.618 1.074-1.025 1.709-1.294C5.678 9.052 7.125 9.003 8 9.003s2.322.049 3.568.576c.635.27 1.252.676 1.709 1.294.462.626.723 1.416.723 2.38a.75.75 0 01-1.5 0c0-.68-.179-1.15-.43-1.488-.256-.347-.626-.61-1.087-.804-.944-.4-2.123-.458-2.983-.458-.86 0-2.039.058-2.983.458-.46.195-.831.457-1.088.804z"
          fill="currentColor"
        />
        <path
          d="M7.25 12.75A.75.75 0 018 12h2.75a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45 6.506a4.55 4.55 0 119.1 0 4.55 4.55 0 01-9.1 0zM10 3.456a3.05 3.05 0 100 6.1 3.05 3.05 0 000-6.1z"
          fill="currentColor"
        />
        <path
          d="M4.35 15.372c-.358.442-.6 1.035-.6 1.874a.75.75 0 01-1.5 0c0-1.156.344-2.09.936-2.82.583-.716 1.367-1.187 2.177-1.499 1.601-.617 3.474-.677 4.637-.677 1.162 0 3.036.06 4.637.677.81.312 1.594.783 2.177 1.5.592.728.936 1.663.936 2.819a.75.75 0 01-1.5 0c0-.839-.242-1.432-.6-1.874-.369-.453-.901-.795-1.552-1.045-1.32-.509-2.947-.577-4.098-.577-1.151 0-2.778.068-4.098.577-.65.25-1.183.592-1.552 1.045z"
          fill="currentColor"
        />
        <path
          d="M10.75 16a.75.75 0 000 1.5H14a.75.75 0 000-1.5h-3.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
          fill="currentColor"
        />
        <path
          d="M4.591 18.13c-.505.684-.841 1.603-.841 2.87a.75.75 0 01-1.5 0c0-1.551.418-2.791 1.135-3.762.712-.962 1.68-1.608 2.707-2.043 2.03-.859 4.413-.945 5.908-.945s3.879.086 5.908.945c1.026.435 1.995 1.08 2.707 2.043.718.97 1.135 2.21 1.135 3.762a.75.75 0 01-1.5 0c0-1.267-.336-2.186-.841-2.87-.511-.69-1.234-1.193-2.086-1.553-1.727-.732-3.843-.827-5.323-.827-1.48 0-3.596.095-5.323.827-.852.36-1.575.862-2.086 1.553z"
          fill="currentColor"
        />
        <path
          d="M12 20a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5A.75.75 0 0112 20z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
        <path
          d="M6.102 24.975C5.44 25.816 5 26.943 5 28.5a1 1 0 11-2 0c0-1.966.566-3.539 1.532-4.764.955-1.211 2.25-2.017 3.612-2.558C10.84 20.108 14.009 20 16 20c1.991 0 5.16.108 7.856 1.178 1.362.54 2.657 1.347 3.612 2.558C28.434 24.961 29 26.534 29 28.5a1 1 0 11-2 0c0-1.557-.439-2.684-1.102-3.525-.675-.856-1.637-1.484-2.78-1.938C20.806 22.119 17.975 22 16 22c-1.975 0-4.806.119-7.118 1.037-1.143.454-2.105 1.082-2.78 1.938z"
          fill="currentColor"
        />
        <path
          d="M16 27a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15zM32 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S38.627 9 32 9z"
        fill="currentColor"
      />
      <path
        d="M12.267 49.128C10.893 50.907 10 53.276 10 56.5a1.5 1.5 0 01-3 0c0-3.822 1.074-6.852 2.893-9.206 1.803-2.335 4.261-3.907 6.88-4.969C21.97 40.218 28.104 40 32 40c3.897 0 10.03.218 15.226 2.325 2.62 1.062 5.078 2.634 6.882 4.969C55.925 49.648 57 52.678 57 56.5a1.5 1.5 0 01-3 0c0-3.224-.893-5.593-2.267-7.372-1.389-1.799-3.35-3.097-5.634-4.023C41.487 43.235 35.87 43 32 43c-3.87 0-9.487.235-14.099 2.105-2.284.926-4.245 2.224-5.634 4.023z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 49a2.5 2.5 0 00-2.5 2.5v3a2.5 2.5 0 002.5 2.5h11a2.5 2.5 0 002.5-2.5v-3a2.5 2.5 0 00-2.5-2.5h-11zm.5 5v-2h10v2H36z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1BadgeRegular)
