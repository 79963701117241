import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseCHOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.568 9.379a1.95 1.95 0 010-2.758L5.22 3.97a.75.75 0 011.06 1.06L3.63 7.682a.45.45 0 000 .636L6.28 10.97a.75.75 0 01-1.06 1.06L2.568 9.38zM13.432 9.379a1.95 1.95 0 000-2.758L10.78 3.97a.75.75 0 00-1.06 1.06l2.651 2.652a.45.45 0 010 .636L9.72 10.97a.75.75 0 001.06 1.06l2.652-2.651z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.742 11.803a2.55 2.55 0 010-3.606L6.72 4.22a.75.75 0 011.06 1.06L3.803 9.258a1.05 1.05 0 000 1.485L7.78 14.72a.75.75 0 11-1.06 1.06l-3.978-3.977zM17.258 11.803a2.55 2.55 0 000-3.606L13.28 4.22a.75.75 0 00-1.06 1.06l3.977 3.978c.41.41.41 1.074 0 1.485L12.22 14.72a.75.75 0 101.06 1.06l3.978-3.977z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.692 13.68a2.375 2.375 0 010-3.36l5.69-5.689A.875.875 0 019.619 5.87l-5.69 5.689a.625.625 0 000 .884l5.69 5.689a.875.875 0 11-1.237 1.237l-5.69-5.689zM21.308 13.68a2.375 2.375 0 000-3.36l-5.69-5.689a.875.875 0 10-1.236 1.238l5.689 5.689a.625.625 0 010 .884l-5.69 5.689a.875.875 0 101.238 1.237l5.69-5.689z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.06 18.475a3.5 3.5 0 010-4.95l8.233-8.232a1 1 0 111.414 1.414L3.475 14.94a1.5 1.5 0 000 2.122l8.232 8.232a1 1 0 01-1.414 1.414l-8.232-8.232zM29.94 18.475a3.5 3.5 0 000-4.95l-8.233-8.232a1 1 0 10-1.414 1.414l8.232 8.232a1.5 1.5 0 010 2.122l-8.232 8.232a1 1 0 001.414 1.414l8.232-8.232z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5.182 37.303a7.5 7.5 0 010-10.606l16.257-16.258a1.5 1.5 0 012.121 2.122L7.303 28.818a4.5 4.5 0 000 6.364L23.56 51.44a1.5 1.5 0 11-2.121 2.12L5.182 37.304zM58.818 37.303a7.5 7.5 0 000-10.606L42.561 10.439a1.5 1.5 0 00-2.122 2.122l16.258 16.257a4.5 4.5 0 010 6.364L40.439 51.44a1.5 1.5 0 102.122 2.12l16.257-16.257z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseCHOpenRegular)
