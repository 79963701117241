import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechPcLaptopRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 2A2.75 2.75 0 002 4.75v3.5A2.75 2.75 0 004.75 11h6.5A2.75 2.75 0 0014 8.25v-3.5A2.75 2.75 0 0011.25 2h-6.5zM3.5 4.75c0-.69.56-1.25 1.25-1.25h6.5c.69 0 1.25.56 1.25 1.25v3.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-3.5z"
          fill="currentColor"
        />
        <path
          d="M14.55 11.027a.75.75 0 10-1.164.946l1.038 1.277H1.576l1.038-1.277a.75.75 0 10-1.164-.946L.412 12.304c-.797.98-.1 2.446 1.164 2.446h12.848c1.263 0 1.96-1.466 1.164-2.446l-1.038-1.277z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 2.25A3.25 3.25 0 002.25 5.5v5a3.25 3.25 0 003.25 3.25h9a3.25 3.25 0 003.25-3.25v-5a3.25 3.25 0 00-3.25-3.25h-9zM3.75 5.5c0-.966.783-1.75 1.75-1.75h9c.966 0 1.75.784 1.75 1.75v5a1.75 1.75 0 01-1.75 1.75h-9a1.75 1.75 0 01-1.75-1.75v-5z"
          fill="currentColor"
        />
        <path
          d="M3.112 14.485a.75.75 0 10-1.145-.97L.821 14.87c-.963 1.138-.154 2.881 1.336 2.881h15.686c1.49 0 2.299-1.743 1.336-2.88l-1.146-1.355a.75.75 0 00-1.145.97l1.146 1.354a.25.25 0 01-.19.411H2.156a.25.25 0 01-.191-.412l1.146-1.353z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 3.25A3.25 3.25 0 003.25 6.5v6a3.25 3.25 0 003.25 3.25h11a3.25 3.25 0 003.25-3.25v-6a3.25 3.25 0 00-3.25-3.25h-11zM4.75 6.5c0-.966.783-1.75 1.75-1.75h11c.966 0 1.75.784 1.75 1.75v6a1.75 1.75 0 01-1.75 1.75h-11a1.75 1.75 0 01-1.75-1.75v-6z"
          fill="currentColor"
        />
        <path
          d="M4.303 17.507a.75.75 0 00-1.106-1.014l-1.214 1.325c-1.029 1.122-.232 2.932 1.29 2.932h17.454c1.522 0 2.319-1.81 1.29-2.933l-1.214-1.324a.75.75 0 00-1.106 1.014l1.214 1.324a.25.25 0 01-.184.419H3.273a.25.25 0 01-.184-.419l1.214-1.324z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3a5 5 0 00-5 5v10a5 5 0 005 5h16a5 5 0 005-5V8a5 5 0 00-5-5H8zM5 8a3 3 0 013-3h16a3 3 0 013 3v10a3 3 0 01-3 3H8a3 3 0 01-3-3V8z"
          fill="currentColor"
        />
        <path
          d="M4.84 24.63a1 1 0 00-1.553-1.26l-2.075 2.553C-.116 27.557 1.047 30 3.152 30h25.696c2.105 0 3.268-2.443 1.94-4.077l-2.075-2.554a1 1 0 00-1.553 1.262l2.076 2.554a.5.5 0 01-.388.815H3.152a.5.5 0 01-.388-.815L4.84 24.63z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 7A9.5 9.5 0 007 16.5v19a9.5 9.5 0 009.5 9.5h31a9.5 9.5 0 009.5-9.5v-19A9.5 9.5 0 0047.5 7h-31zM10 16.5a6.5 6.5 0 016.5-6.5h31a6.5 6.5 0 016.5 6.5v19a6.5 6.5 0 01-6.5 6.5h-31a6.5 6.5 0 01-6.5-6.5v-19z"
        fill="currentColor"
      />
      <path
        d="M9.647 47.966a1.5 1.5 0 10-2.294-1.932L3.506 50.6C1.042 53.528 3.122 58 6.948 58h50.104c3.826 0 5.906-4.472 3.442-7.399l-3.847-4.567a1.5 1.5 0 00-2.294 1.932l3.846 4.568c.822.975.128 2.466-1.147 2.466H6.948c-1.275 0-1.969-1.49-1.147-2.466l3.846-4.568z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPcLaptopRegular)
