import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.353 3.146a.5.5 0 010 .708L6.561 7.646a.5.5 0 000 .708l3.792 3.792a.5.5 0 01-.707.708L5.853 9.06a1.5 1.5 0 010-2.122l3.793-3.793a.5.5 0 01.707 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.978 16.604a.5.5 0 01-.707 0l-4.836-4.836a2.5 2.5 0 010-3.536l4.836-4.836a.5.5 0 11.707.707L8.142 8.94a1.5 1.5 0 000 2.121l4.836 4.836a.5.5 0 010 .708z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCLeftLight)
