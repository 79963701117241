import styles from './styles.scss'

const Cpu = () => (
  <div className={styles.container}>
    <p>
      Процессор — это своего рода мозг вашей конфигурации. Он отвечает за
      координацию всех компонентов, подключенных к вашему ПК, для обеспечения
      работы системы и, следовательно, влияет на скорость вашей работы.
      Профессиональный совет! Основные характеристики процессора:
    </p>
    <ul>
      <li> Количество ядер</li>
      <li> Тактовая частота ГГц</li>
    </ul>
    <p>
      Ядро — это логический процессор, который выполняет всю тяжелую работу,
      поэтому чем больше у вас ядер, тем больше может выполняться одновременная
      обработка. Для большинства программ нужен четырехъядерный или шестиядерный
      процессор для хорошей производительности. 8-10-ядерные процессоры лучше
      подходят для потоковой передачи. Тактовая частота ГГц (Гигагерц)
      определяет, насколько быстро ваш ЦП может обрабатывать задачу.
    </p>
  </div>
)

export default Cpu
