import { IUser } from '@modules/user/models/User'

export enum ECompanyStatus {
  AUTHORIZED = 1,
  SILVER = 2,
  GOLD = 3,
  PLATINUM = 4,
}

const STATUS_LABEL_MAP = {
  [ECompanyStatus.AUTHORIZED]: 'Authorized',
  [ECompanyStatus.SILVER]: 'Silver',
  [ECompanyStatus.GOLD]: 'Gold',
  [ECompanyStatus.PLATINUM]: 'Platinum',
}

export const getCompanyStatusLabel = (status: ECompanyStatus) =>
  STATUS_LABEL_MAP[status]
/**
 * @prop id Идентификатор.
 * @prop name Наименование.
 * @prop inn ИНН.
 * @prop users Пользователи.
 * @prop admin Контакное отлицо.
 * @prop manager Менеджер компании.
 * @prop role Роль.
 * @prop [status] Статус.
 * @prop [phone] Телефон.
 * @prop email Емейл.
 * @prop legalAddress Юридический адрес.
 * @prop actualAddress Фактический адрес.
 */
export interface ICompany {
  id: string
  name: string
  inn: string
  users: IUser[]
  employees: IEmployees[]
  admin: IUser
  manager: IUser
  status: ECompanyStatus
  phone?: string
  email?: string
  legalAddress?: string
  actualAddress?: string
}

/**
 * Поля для проверки показа онбординга.
 */
const fieldsToCheckOnboarding: Array<keyof ICompany> = [
  'email',
  'phone',
  'legalAddress',
  'actualAddress',
]

export const isNewCompany = (data?: ICompany) => {
  if (data !== undefined) {
    return fieldsToCheckOnboarding.some((field) => !data[field])
  }
  return true
}

export interface IEmployees {
  id: number
  email: string
  fullName: string
  phone: string
  position: string
  success: boolean
}
