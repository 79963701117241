import { observer } from 'mobx-react-lite'

import { IconPeopleCircle1Regular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'

import { useCompanyManagerStore } from '@modules/company/store/CompanyManagerStore'

import styles from './styles.module.scss'

const CardManager = observer(() => {
  const companyManagerStore = useCompanyManagerStore()

  const data = companyManagerStore.manager

  if (data) {
    return (
      <CardWrapper py={30} px={20} className={styles.card}>
        <Typography size={16}>Ваш менеджер</Typography>

        <div className={styles.iconContainer}>
          <IconPeopleCircle1Regular size={64} color={COLORS.BRAND.DARK_SKY} />
        </div>

        <Typography size={22} className={styles.name}>
          {data.fullName}
        </Typography>

        <Typography
          href={`tel:${data.phone}`}
          component="a"
          size={16}
          className={styles.phone}>
          {data.phone}
        </Typography>

        <Typography
          href={`mailto:${data.email}`}
          component="a"
          size={16}
          className={styles.mail}>
          {data.email}
        </Typography>
      </CardWrapper>
    )
  }
  return null
})

export default CardManager
