import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowUiShareAExportLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.254 1.146a.5.5 0 00-.708.708L14.693 4H11.45A4.45 4.45 0 007 8.45v.05a.5.5 0 001 0v-.05A3.45 3.45 0 0111.45 5h3.243l-2.147 2.146a.5.5 0 00.708.708l2.292-2.293a1.5 1.5 0 000-2.122l-2.292-2.293z"
          fill="currentColor"
        />
        <path
          d="M2 5a3 3 0 013-3h2a.5.5 0 010 1H5a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-1a.5.5 0 011 0v1a3 3 0 01-3 3H5a3 3 0 01-3-3V5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.354 1.646a.5.5 0 00-.708.708L17.294 5H13a5 5 0 00-5 5v.499a.5.5 0 001 0v-.498a4 4 0 014-4h4.294l-2.648 2.647a.5.5 0 00.708.707l2.794-2.794a1.5 1.5 0 000-2.121l-2.794-2.795z"
        fill="currentColor"
      />
      <path
        d="M5.5 3A2.5 2.5 0 003 5.5v8A2.5 2.5 0 005.5 16h8a2.5 2.5 0 002.5-2.5v-1a.5.5 0 011 0v1a3.5 3.5 0 01-3.5 3.5h-8A3.5 3.5 0 012 13.5v-8A3.5 3.5 0 015.5 2h2a.5.5 0 010 1h-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiShareAExportLight)
