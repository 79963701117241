import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import useBreadcrumbs from 'use-react-router-breadcrumbs'

import Button from '@ui/Button'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { EMainRoutes } from '@lib/navigation'
import { breadcrumbRoutes } from '@lib/navigation/breadcrumbRoutes'

import styles from './styles.module.scss'

const Header = () => {
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes)
  const navigate = useNavigate()
  const contactUsHandler = useCallback(
    () => navigate(EMainRoutes.SupportContact),
    [],
  )

  return (
    <header className={styles.header}>
      <div className={styles.breadcrumbs}>
        {breadcrumbs.map(({ breadcrumb, key }) => (
          <Typography key={key} color={COLORS.GRAY.SAURON}>
            <Link key={key} to={key}>
              {breadcrumb}
            </Link>
          </Typography>
        ))}
      </div>
      <Button
        onClick={contactUsHandler}
        size="small"
        className={styles.pureButton}>
        Связаться с нами
      </Button>
    </header>
  )
}

export default Header
