import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetUploadLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 2.127c.056.032.109.07.159.114l3.012 2.635a.5.5 0 00.658-.752L8.817 1.488a2 2 0 00-2.634 0L3.171 4.124a.5.5 0 10.658.752l3.012-2.635c.05-.044.103-.082.159-.114V10.5a.5.5 0 101 0V2.127zM1 13.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.997 2.46c.146.056.284.139.407.247L14.169 6a.5.5 0 00.659-.752l-3.766-3.295a2.375 2.375 0 00-3.127 0L4.169 5.25A.5.5 0 104.828 6l3.765-3.294c.124-.108.262-.19.407-.247a.508.508 0 00-.002.04L9 13.5a.5.5 0 101 0l-.002-11-.001-.04zM2.5 16.5A.5.5 0 013 16h13a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetUploadLight)
