import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNotificationBellAlarmRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.843.263a.75.75 0 01-.08 1.058A6.5 6.5 0 001.5 6.25a.75.75 0 01-1.5 0A8 8 0 012.785.182a.75.75 0 011.058.08z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.25a5 5 0 00-5 5v3.014L1.386 11.57A.75.75 0 002 12.75h3.5v.208a2.5 2.5 0 005 0v-.208H14a.75.75 0 00.614-1.18L13 9.264V6.25a5 5 0 00-5-5zm-3.5 5a3.5 3.5 0 117 0V9.5a.75.75 0 00.136.43l.924 1.32H3.44l.924-1.32A.75.75 0 004.5 9.5V6.25zm2.5 6.5v.208a1 1 0 102 0v-.208H7z"
          fill="currentColor"
        />
        <path
          d="M12.237 1.32a.75.75 0 01.978-1.137A8 8 0 0116 6.25a.75.75 0 01-1.5 0 6.5 6.5 0 00-2.263-4.93z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.554 2.305a.75.75 0 10-.99-1.127 9.749 9.749 0 00-3.31 7.065.75.75 0 101.5.04 8.249 8.249 0 012.8-5.978zM15.378 1.246a.75.75 0 00.069 1.059 8.249 8.249 0 012.799 5.978.75.75 0 101.5-.04 9.75 9.75 0 00-3.31-7.065.75.75 0 00-1.058.069z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2a6.5 6.5 0 00-6.5 6.5v3.314c0 .03-.009.06-.025.085l-1.287 1.976A1.375 1.375 0 003.34 16h4.035v.4a2.625 2.625 0 105.25 0V16h4.035a1.375 1.375 0 001.152-2.125l-1.287-1.976a.156.156 0 01-.025-.085V8.5A6.5 6.5 0 0010 2zM5 8.5a5 5 0 1110 0v3.314c0 .321.093.635.268.904L16.43 14.5H3.571l1.16-1.782c.176-.269.269-.583.269-.904V8.5zM8.875 16v.4a1.125 1.125 0 002.25 0V16h-2.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.735 2.28a.75.75 0 00-.964-1.15A11.25 11.25 0 00.75 9.75a.75.75 0 001.5 0 9.75 9.75 0 013.485-7.47zM18.172 1.223a.75.75 0 00.093 1.056 9.749 9.749 0 013.485 7.47.75.75 0 001.5 0 11.25 11.25 0 00-4.021-8.62.75.75 0 00-1.057.094z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2a7.75 7.75 0 00-7.75 7.75v4.377a.388.388 0 01-.075.23L2.52 16.613C1.794 17.603 2.501 19 3.73 19H9v.5a3 3 0 006 0V19h5.27c1.229 0 1.936-1.396 1.21-2.387l-1.655-2.256a.388.388 0 01-.075-.23V9.75A7.75 7.75 0 0012 2zM5.75 9.75a6.25 6.25 0 1112.5 0v4.377c0 .402.128.793.366 1.117L20.27 17.5H3.73l1.655-2.256c.237-.324.365-.715.365-1.117V9.75zM10.5 19v.5a1.5 1.5 0 003 0V19h-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.646 2.04A1 1 0 106.361.506 15 15 0 001 12a1 1 0 002 0 13 13 0 014.646-9.96zM24.23.63a1 1 0 00.123 1.41A13 13 0 0129 12a1 1 0 002 0A15 15 0 0025.639.507a1 1 0 00-1.41.123z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2C10.477 2 6 6.477 6 12v6.012c0 .106-.034.209-.096.295l-2.558 3.517C2.385 23.146 3.33 25 4.964 25h22.072c1.635 0 2.58-1.854 1.618-3.176l-2.558-3.517a.501.501 0 01-.096-.295V12c0-5.523-4.477-10-10-10zM8 12a8 8 0 1116 0v6.012c0 .529.168 1.043.478 1.471L27.036 23H4.964l2.558-3.517c.31-.428.478-.942.478-1.471V12z"
          fill="currentColor"
        />
        <path
          d="M19.882 27.215a1 1 0 10-1.941-.483 2 2 0 01-3.878.015 1 1 0 00-1.937.497 4 4 0 007.756-.03z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14.56 2.409a1.5 1.5 0 01-.16 2.115A27 27 0 005 25a1.5 1.5 0 01-3 0A30 30 0 0112.445 2.25a1.5 1.5 0 012.115.159z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 5c-11.046 0-20 8.954-20 20v11.524c0 .317-.1.627-.287.883l-5.116 7.035C4.914 46.755 6.567 50 9.427 50h45.145c2.861 0 4.514-3.245 2.831-5.559l-5.116-7.034a1.503 1.503 0 01-.287-.883V25c0-11.046-8.954-20-20-20zM15 25c0-9.389 7.611-17 17-17s17 7.611 17 17v11.524c0 .951.301 1.878.861 2.648l5.116 7.034a.5.5 0 01-.404.794H9.427a.5.5 0 01-.405-.794l5.116-7.034c.56-.77.861-1.697.861-2.648V25z"
        fill="currentColor"
      />
      <path
        d="M39.395 53.753a1.5 1.5 0 10-2.958-.501 4.5 4.5 0 01-8.873.004 1.5 1.5 0 10-2.957.505 7.5 7.5 0 0014.788-.008zM49.6 4.524a1.5 1.5 0 011.955-2.275A30 30 0 0162 25a1.5 1.5 0 01-3 0 27 27 0 00-9.4-20.476z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNotificationBellAlarmRegular)
