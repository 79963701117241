import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowAUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.608 7.574a.75.75 0 01-1.06-1.061l3.927-3.928a2.156 2.156 0 013.05 0l3.927 3.928a.75.75 0 11-1.06 1.06L8.75 3.933v9.353a.75.75 0 01-1.5 0V3.932L3.608 7.574z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.968 8.812a.75.75 0 11-1.06-1.061l5.236-5.237a2.625 2.625 0 013.712 0l5.237 5.237a.75.75 0 01-1.06 1.06l-5.238-5.236a1.142 1.142 0 00-.045-.043V17.5a.75.75 0 01-1.5 0V3.532l-.046.043-5.236 5.237z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.744 10.556A.875.875 0 113.506 9.32L9.79 3.035a3.125 3.125 0 014.42 0l6.284 6.284a.875.875 0 11-1.238 1.237l-6.284-6.284a1.403 1.403 0 00-.097-.088V21a.875.875 0 11-1.75 0V4.184a1.403 1.403 0 00-.097.088l-6.284 6.284z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.207 13.957a1 1 0 01-1.414-1.414l8.379-8.379a4 4 0 015.656 0l8.38 8.379a1 1 0 01-1.415 1.414l-8.379-8.379A2.003 2.003 0 0017 5.26V28a1 1 0 11-2 0V5.26a2.003 2.003 0 00-.414.318l-8.379 8.38z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.06 27.56a1.5 1.5 0 01-2.12-2.12L26.696 8.681a7.5 7.5 0 0110.606 0l16.758 16.757a1.5 1.5 0 01-2.122 2.122L35.182 10.803A4.483 4.483 0 0033.5 9.742V56a1.5 1.5 0 01-3 0V9.742c-.614.216-1.19.57-1.682 1.061L12.061 27.561z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpRegular)
