import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCollapseTrianglesHOpenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.505 9.202a1.7 1.7 0 000-2.404l-3.31-3.31A.7.7 0 009 3.983v8.034a.7.7 0 001.195.495l3.31-3.31zm-.707-1.697a.7.7 0 010 .99L10 11.293V4.707l2.798 2.798zM2.495 6.798a1.7 1.7 0 000 2.404l3.31 3.31A.7.7 0 007 12.017V3.983a.7.7 0 00-1.195-.495l-3.31 3.31zm.707.707a.7.7 0 000 .99L6 11.293V4.707L3.202 7.505z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.393 11.415a2 2 0 000-2.829L12.707 4.9c-.63-.63-1.707-.183-1.707.707v8.786c0 .891 1.077 1.337 1.707.707l3.686-3.685zm-.707-2.122a1 1 0 010 1.415L12 14.393V5.607l3.686 3.686zM3.607 8.586a2 2 0 000 2.829L7.293 15.1c.63.63 1.707.183 1.707-.708V5.607c0-.89-1.077-1.337-1.707-.707L3.607 8.586zm.707.707a1 1 0 000 1.415L8 14.393V5.607L4.314 9.293z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesHOpenLight)
