import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowFigureACircleUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 11a.5.5 0 01-.5-.5V5.38L5.384 7.32a.5.5 0 11-.768-.64L6.348 4.6a1.5 1.5 0 012.304 0l1.732 2.078a.5.5 0 01-.768.64L8 5.382v5.118a.5.5 0 01-.5.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 14a6.5 6.5 0 110-13 6.5 6.5 0 010 13zM2 7.5a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.5 13.5A.5.5 0 019 13V6.781l-2.116 2.54a.5.5 0 11-.768-.641l2.232-2.678a1.5 1.5 0 012.304 0l2.232 2.678a.5.5 0 01-.768.64L10 6.781V13a.5.5 0 01-.5.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 17a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM3 9.5a6.5 6.5 0 1013 0 6.5 6.5 0 00-13 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleUpLight)
