import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { IMarketingFundsRequest, IMarketingMaterial } from '../models/Marketing'
import { MarketingRest } from '../services/MarketingRest'

@singleton()
export class MarketingStore {
  marketingMaterial: IMarketingMaterial[] = []
  loading = true

  private marketingRest = container.resolve(MarketingRest)

  constructor() {
    makeAutoObservable<MarketingStore, 'marketingRest'>(this, {
      marketingRest: false,
    })
  }

  private setMarketingMaterial(data: IMarketingMaterial[]) {
    this.marketingMaterial = data

    this.loading = false
  }

  async fetchMarketingMaterial() {
    if (this.marketingMaterial.length === 0) {
      const data = await this.marketingRest.getMarketingMaterials()
      this.setMarketingMaterial(data)
    }
  }

  async sendMarketingFundsRequest(data: IMarketingFundsRequest) {
    await this.marketingRest.sendMarketingFundsRequest(data)
  }
}

export const useMarketingStore = () => container.resolve(MarketingStore)
