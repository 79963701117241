import { makeAutoObservable, runInAction } from 'mobx'
import { container, singleton } from 'tsyringe'

import {
  ICompany,
  IEmployees,
  isNewCompany,
} from '@modules/company/models/Company'
import {
  CompanyRest,
  IEditCompanyParams,
} from '@modules/company/services/CompanyRest'

import { CompanyManagerStore } from './CompanyManagerStore'

@singleton()
export class CompanyStore {
  private companyRest = container.resolve(CompanyRest)
  private managerStore = container.resolve(CompanyManagerStore)

  company?: ICompany = undefined
  employees: IEmployees[] = []

  constructor() {
    makeAutoObservable<CompanyStore, 'companyRest' | 'managerStore'>(this, {
      companyRest: false,
      managerStore: false,
    })
  }
  get waitingEmployees() {
    return this.employees.filter((item) => item.success === false)
  }

  get successEmployees() {
    return this.employees.filter((item) => item.success === true)
  }

  get isNewCompany() {
    return isNewCompany(this.company)
  }

  get admin() {
    return this.company?.admin || this.company?.users?.[0]
  }

  get hasCompany() {
    return this.company !== undefined
  }

  private setCompany(data?: ICompany) {
    this.company = data
  }

  private setEmployees(data: IEmployees[]) {
    this.employees = data
  }

  async fetchCompany() {
    const data = await this.companyRest.getCompany()
    this.setCompany(data)
    this.setEmployees(data.employees)
    this.managerStore.setManager(data.manager)
  }

  async editCompany(params: IEditCompanyParams) {
    if (this.company !== undefined) {
      const data = await this.companyRest.editCompany(params)

      this.setCompany(data)
    }
  }

  async confirmEmployee(id: number) {
    await this.companyRest.confirmEmployee(id)

    runInAction(() => {
      const idx = this.employees.findIndex((item) => item.id === id)

      if (idx > -1) {
        this.employees[idx].success = true
      }
    })
  }

  async getEmployee(id: number) {
    const data = await this.companyRest.getEmployee(id)
    return data
  }

  async deleteEmployee(id: number) {
    await this.companyRest.deleteEmployee(id)

    runInAction(() => {
      const idx = this.employees.findIndex((item) => item.id === id)

      if (idx > -1) {
        this.employees.splice(idx, 1)
      }
    })
  }

  copyToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      const textArea = document.createElement('textarea')

      textArea.value = text
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
    }
  }

  clear() {
    this.setCompany(undefined)
    this.employees = []
  }
}

export const useCompanyStore = () => container.resolve(CompanyStore)
