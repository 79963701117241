import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople3Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3a2 2 0 100 4 2 2 0 000-4zM7 5a1 1 0 112 0 1 1 0 01-2 0zM11 6a2 2 0 114 0 2 2 0 01-4 0zm2-1a1 1 0 100 2 1 1 0 000-2z"
          fill="currentColor"
        />
        <path
          d="M12.855 10.01c.776.05 1.291.26 1.613.528.357.297.532.71.532 1.212v.75a.5.5 0 101 0v-.75c0-.755-.275-1.467-.893-1.98C14.501 9.264 13.622 9 12.5 9h-.04c.18.31.311.649.395 1.01zM3.475 9c-1.11.004-1.98.269-2.582.77C.275 10.282 0 10.994 0 11.75v.75a.5.5 0 001 0v-.75c0-.502.175-.915.532-1.212.313-.26.807-.466 1.547-.523.083-.363.216-.703.396-1.015z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4a2 2 0 100 4 2 2 0 000-4zM2 6a1 1 0 112 0 1 1 0 01-2 0z"
          fill="currentColor"
        />
        <path
          d="M5.291 9.912c-.173.253-.297.606-.297 1.113v1.497a.5.5 0 11-1 0v-1.497c0-.68.17-1.235.472-1.677.3-.44.709-.734 1.136-.93C6.438 8.036 7.409 8 7.993 8c.584 0 1.555.035 2.391.419.427.195.836.49 1.136.929.303.442.472.998.472 1.677v1.497a.5.5 0 11-1 0v-1.497c0-.507-.124-.86-.297-1.113a1.721 1.721 0 00-.727-.584C9.344 9.042 8.565 9 7.993 9c-.572 0-1.35.042-1.975.328a1.721 1.721 0 00-.727.584z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3a3 3 0 100 6 3 3 0 000-6zM8 6a2 2 0 114 0 2 2 0 01-4 0zM13.751 7.5a2.499 2.499 0 114.998 0 2.499 2.499 0 01-4.998 0zm2.499-1.499a1.499 1.499 0 100 2.998 1.499 1.499 0 000-2.998z"
        fill="currentColor"
      />
      <path
        d="M15.923 12.01c1.1.057 1.846.332 2.32.701.516.403.757.955.757 1.601v.938a.5.5 0 001 0v-.938c0-.923-.36-1.778-1.142-2.39-.769-.598-1.892-.922-3.358-.922h-.081c.21.313.378.65.504 1.01zM4.5 11c-1.466 0-2.59.324-3.357.923C.359 12.533 0 13.389 0 14.313v.937a.5.5 0 001 0v-.938c0-.646.24-1.198.757-1.6.462-.36 1.183-.631 2.237-.697.126-.361.295-.7.506-1.015z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 5.001a2.499 2.499 0 100 4.998A2.499 2.499 0 003.75 5zM2.251 7.5a1.499 1.499 0 112.998 0 1.499 1.499 0 01-2.998 0z"
        fill="currentColor"
      />
      <path
        d="M6.407 12.233c-.241.353-.407.836-.407 1.512v1.925a.5.5 0 11-1 0v-1.925c0-.848.21-1.534.581-2.076.368-.539.87-.902 1.402-1.145 1.046-.48 2.266-.525 3.016-.525s1.97.046 3.015.525c.531.243 1.034.606 1.402 1.145.37.542.581 1.228.581 2.076v1.925a.5.5 0 11-1 0v-1.925c0-.676-.165-1.159-.406-1.512-.243-.355-.587-.613-.994-.8-.832-.382-1.861-.434-2.598-.434-.738 0-1.767.052-2.599.434-.407.187-.75.445-.993.8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople3Light)
