import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1GearRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M6.719 10.546a.75.75 0 10-.156-1.492c-.928.097-2.05.337-2.955.963C2.655 10.675 2 11.722 2 13.257a.75.75 0 001.5 0c0-1.036.408-1.625.96-2.006.6-.414 1.423-.617 2.259-.705z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.085 9.778a2.747 2.747 0 011.166-.674V8.25a.75.75 0 011.5 0v.854c.445.125.844.36 1.166.674l.74-.428a.75.75 0 11.75 1.3l-.739.426a2.755 2.755 0 010 1.348l.74.427a.75.75 0 01-.75 1.298l-.74-.427a2.748 2.748 0 01-1.167.675v.853a.75.75 0 01-1.5 0v-.854a2.747 2.747 0 01-1.166-.674l-.74.428a.75.75 0 01-.75-1.3l.74-.426a2.756 2.756 0 010-1.348l-.74-.427a.75.75 0 11.75-1.299l.74.428zm.826 1.36a1.244 1.244 0 000 1.225l.008.012.006.012a1.25 1.25 0 002.166-1.25l-.007-.012-.007-.012a1.25 1.25 0 00-2.152 0l-.006.012-.008.012z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.956a4.55 4.55 0 100 9.1 4.55 4.55 0 000-9.1zm-3.05 4.55a3.05 3.05 0 116.1 0 3.05 3.05 0 01-6.1 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 17.246c0-.838.242-1.432.6-1.873.369-.454.901-.795 1.552-1.046 1.32-.509 2.947-.577 4.098-.577a.75.75 0 000-1.5c-1.163 0-3.036.06-4.637.677-.81.312-1.594.783-2.177 1.5-.592.728-.936 1.663-.936 2.819a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.085 13.778a2.747 2.747 0 011.166-.675v-.853a.75.75 0 111.5 0v.854c.445.125.844.36 1.166.674l.74-.428a.75.75 0 11.75 1.3l-.74.426a2.755 2.755 0 010 1.348l.74.427a.75.75 0 11-.75 1.299l-.74-.428a2.747 2.747 0 01-1.166.674v.854a.75.75 0 01-1.5 0v-.854a2.747 2.747 0 01-1.166-.674l-.74.427a.75.75 0 01-.75-1.299l.74-.426a2.755 2.755 0 010-1.348l-.74-.427a.75.75 0 11.75-1.298l.74.427zm.826 1.36a1.243 1.243 0 000 1.225l.008.012.006.012a1.25 1.25 0 002.152 0l.007-.012a1.245 1.245 0 00.007-1.238l-.007-.012-.007-.012a1.25 1.25 0 00-2.152 0l-.007.012a.809.809 0 01-.007.012z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 21c0-1.267.336-2.186.841-2.87.511-.69 1.234-1.193 2.086-1.553 1.727-.732 3.843-.827 5.323-.827a.75.75 0 000-1.5c-1.495 0-3.879.086-5.908.945-1.026.435-1.995 1.08-2.707 2.043-.717.97-1.135 2.21-1.135 3.762a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.732 17.407a3.423 3.423 0 000 2.043l-1.158.669a.75.75 0 00.75 1.299l1.159-.67a3.42 3.42 0 001.768 1.022v1.338a.75.75 0 001.5 0V21.77a3.42 3.42 0 001.768-1.021l1.16.669a.75.75 0 00.75-1.3l-1.16-.668a3.424 3.424 0 000-2.042l1.16-.67a.75.75 0 00-.75-1.298l-1.16.67a3.42 3.42 0 00-1.768-1.022V13.75a.75.75 0 00-1.5 0v1.338a3.42 3.42 0 00-1.768 1.021l-1.16-.67a.75.75 0 00-.75 1.3l1.16.668zm1.592.079a1.916 1.916 0 00.023 1.926 1.923 1.923 0 003.578-.983c0-.347-.092-.672-.252-.952l-.006-.01-.005-.009a1.923 1.923 0 00-1.654-.953h-.014a1.923 1.923 0 00-1.649.944l-.01.018a.91.91 0 01-.011.019z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
        <path
          d="M5 28.5c0-1.557.439-2.684 1.102-3.525.675-.856 1.637-1.484 2.78-1.938C11.194 22.119 14.025 22 16 22a1 1 0 100-2c-1.991 0-5.16.108-7.856 1.178-1.362.54-2.657 1.347-3.612 2.558C3.566 24.961 3 26.534 3 28.5a1 1 0 102 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.919 21.508a2.997 2.997 0 100 5.994 2.997 2.997 0 000-5.994zm-1.247 2.997a1.247 1.247 0 112.494 0 1.247 1.247 0 01-2.494 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.42 18.753a1.875 1.875 0 011.87-1.728h1.258c.98 0 1.793.753 1.87 1.728l.033.43c.003.03.033.12.17.192.133.07.263.146.39.226.13.082.223.063.25.05l.39-.186a1.875 1.875 0 012.431.755l.63 1.09c.489.848.244 1.929-.563 2.483l-.356.245c-.025.017-.088.088-.082.241a6.038 6.038 0 010 .451c-.006.154.057.225.082.241l.357.246a1.875 1.875 0 01.562 2.483l-.63 1.09a1.875 1.875 0 01-2.431.755l-.39-.187c-.027-.012-.12-.032-.25.05a5.83 5.83 0 01-.39.226c-.137.072-.167.163-.17.193l-.033.43a1.875 1.875 0 01-1.87 1.728H24.29a1.875 1.875 0 01-1.87-1.729l-.034-.43c-.002-.029-.032-.12-.169-.192a5.804 5.804 0 01-.39-.225c-.13-.083-.223-.063-.25-.05l-.39.185a1.875 1.875 0 01-2.431-.754l-.63-1.09a1.875 1.875 0 01.563-2.483l.356-.245c.025-.017.088-.088.082-.242a6.029 6.029 0 010-.451c.006-.154-.057-.224-.082-.241l-.356-.245a1.875 1.875 0 01-.562-2.483l.629-1.09a1.875 1.875 0 012.431-.755l.39.186c.027.013.12.032.25-.05.127-.08.257-.155.39-.226.137-.072.167-.162.17-.192l.033-.43zm1.87.022c-.066 0-.12.05-.125.115l-.034.43c-.058.747-.545 1.312-1.097 1.603a3.982 3.982 0 00-.272.158c-.529.334-1.262.473-1.939.15l-.39-.187a.125.125 0 00-.161.05l-.63 1.09a.125.125 0 00.038.166l.356.245c.617.424.864 1.127.84 1.751a3.978 3.978 0 000 .317c.023.625-.223 1.327-.84 1.751l-.357.245a.125.125 0 00-.037.166l.63 1.09a.125.125 0 00.161.05l.39-.186c.677-.323 1.41-.184 1.939.15.088.056.18.108.272.157.552.292 1.039.856 1.097 1.604l.034.43c.005.065.06.115.125.115h1.258c.066 0 .12-.05.125-.115l.034-.43c.058-.748.545-1.312 1.097-1.604.093-.049.184-.101.272-.157.529-.334 1.262-.473 1.939-.15l.39.186c.058.028.13.006.162-.05l.63-1.09a.125.125 0 00-.038-.166l-.357-.245c-.617-.424-.864-1.126-.84-1.75a4.16 4.16 0 000-.318c-.024-.624.223-1.327.84-1.75l.356-.246a.125.125 0 00.038-.165l-.63-1.09a.125.125 0 00-.162-.05l-.39.185c-.677.324-1.41.185-1.938-.15a4.023 4.023 0 00-.272-.157c-.552-.291-1.039-.856-1.097-1.603l-.034-.43a.125.125 0 00-.125-.115H24.29z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM20 21c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"
        fill="currentColor"
      />
      <path
        d="M10 56.5c0-3.224.893-5.593 2.267-7.372 1.389-1.799 3.35-3.097 5.634-4.023C22.513 43.235 28.13 43 32 43a1.5 1.5 0 000-3c-3.897 0-10.03.218-15.226 2.325-2.62 1.062-5.078 2.634-6.882 4.969C8.075 49.648 7 52.678 7 56.5a1.5 1.5 0 003 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 42.989a6.01 6.01 0 100 12.021 6.01 6.01 0 000-12.021zm-3.01 6.01a3.01 3.01 0 116.02 0 3.01 3.01 0 01-6.02 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.42 38.227a3.5 3.5 0 013.488-3.226h2.184a3.5 3.5 0 013.489 3.226l.05.645c.013.161.138.413.48.596.2.108.396.222.588.341.328.204.609.186.754.117l.586-.28a3.5 3.5 0 014.539 1.409l1.091 1.89a3.5 3.5 0 01-1.05 4.636l-.535.368c-.133.091-.289.325-.277.71a11.033 11.033 0 010 .682c-.012.384.144.618.277.71l.536.368a3.5 3.5 0 011.05 4.635l-1.092 1.89a3.5 3.5 0 01-4.539 1.41l-.586-.28c-.146-.07-.426-.088-.754.116-.192.12-.389.233-.589.34-.34.184-.466.436-.478.596l-.051.646A3.5 3.5 0 0151.09 63h-2.183a3.5 3.5 0 01-3.489-3.227l-.05-.645c-.013-.16-.138-.413-.48-.596-.2-.108-.396-.221-.588-.34-.328-.205-.609-.187-.754-.117l-.586.28a3.5 3.5 0 01-4.538-1.41l-1.092-1.89a3.5 3.5 0 011.05-4.635l.535-.368c.133-.091.288-.325.276-.71a11.09 11.09 0 010-.683c.012-.384-.143-.618-.276-.71l-.536-.367a3.5 3.5 0 01-1.049-4.635l1.092-1.891a3.5 3.5 0 014.538-1.409l.585.28c.146.07.426.087.755-.117.192-.12.388-.233.589-.34.341-.184.466-.436.478-.597l.051-.645zm3.488-.226a.5.5 0 00-.498.46l-.05.646c-.11 1.394-1.019 2.45-2.05 3.004-.145.078-.287.16-.426.246-.997.62-2.367.879-3.63.276l-.585-.28a.5.5 0 00-.648.202l-1.092 1.89a.5.5 0 00.15.663l.535.368c1.151.79 1.613 2.103 1.577 3.275a7.857 7.857 0 000 .497c.036 1.172-.426 2.485-1.577 3.276l-.535.368a.5.5 0 00-.15.662l1.092 1.89a.5.5 0 00.648.202l.586-.28c1.262-.602 2.632-.343 3.629.276.139.086.28.168.425.246 1.032.554 1.941 1.61 2.05 3.004l.05.646a.5.5 0 00.5.46h2.183a.5.5 0 00.498-.46l.05-.646c.11-1.394 1.018-2.45 2.05-3.004.145-.078.287-.16.425-.246.997-.62 2.368-.879 3.63-.276l.586.28a.5.5 0 00.649-.201l1.091-1.891a.5.5 0 00-.15-.662l-.536-.369c-1.15-.79-1.613-2.103-1.576-3.275a7.862 7.862 0 000-.496c-.037-1.172.425-2.486 1.576-3.276l.536-.368a.5.5 0 00.15-.662l-1.091-1.891a.5.5 0 00-.649-.201l-.586.28c-1.262.602-2.632.343-3.63-.277a7.788 7.788 0 00-.424-.246c-1.033-.554-1.941-1.61-2.05-3.004l-.05-.645a.5.5 0 00-.5-.461h-2.183z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1GearRegular)
