import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

@singleton()
export class WebinarsRest extends AbstractRest {
  async fetchWebinars() {
    const { data } = await this.http.get('/lk/webinars')

    return data.data
  }
}
