import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowUiCornerOutUpRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 2a2 2 0 00-2 2v7a2 2 0 002 2h7a2 2 0 002-2V8.5a.5.5 0 011 0V11a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3h2.5a.5.5 0 010 1H4z"
          fill="currentColor"
        />
        <path
          d="M13 2.707L7.854 7.854a.5.5 0 11-.708-.708L12.293 2H9.5a.5.5 0 010-1h3A1.5 1.5 0 0114 2.5v3a.5.5 0 01-1 0V2.707z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5.5 3A2.5 2.5 0 003 5.5v8A2.5 2.5 0 005.5 16h8a2.5 2.5 0 002.5-2.5v-1a.5.5 0 011 0v1a3.5 3.5 0 01-3.5 3.5h-8A3.5 3.5 0 012 13.5v-8A3.5 3.5 0 015.5 2h1a.5.5 0 010 1h-1z"
        fill="currentColor"
      />
      <path
        d="M16 3.707L9.854 9.854a.5.5 0 01-.708-.708L15.293 3H10.5a.5.5 0 010-1h5A1.5 1.5 0 0117 3.5v5a.5.5 0 01-1 0V3.707z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiCornerOutUpRightLight)
