import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBagBriefcaseRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 2.25A2.25 2.25 0 016.5 0h3a2.25 2.25 0 012.25 2.25V3h.5A2.75 2.75 0 0115 5.75v5.5A2.75 2.75 0 0112.25 14h-8.5A2.75 2.75 0 011 11.25v-5.5A2.75 2.75 0 013.75 3h.5v-.75zm6 0V3h-4.5v-.75a.75.75 0 01.75-.75h3a.75.75 0 01.75.75zM3.75 4.5h8.5c.69 0 1.25.56 1.25 1.25v.626L8.71 7.972a2.25 2.25 0 01-1.423 0L2.5 6.376V5.75c0-.69.56-1.25 1.25-1.25zM2.5 7.957v3.293c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25V7.957L9.186 9.395a3.75 3.75 0 01-2.372 0L2.5 7.957z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 2a2.25 2.25 0 00-2.25 2.25v1H4A2.75 2.75 0 001.25 8v.754a1.75 1.75 0 001 1.581v3.852a3.563 3.563 0 003.563 3.563h8.375a3.563 3.563 0 003.562-3.563v-3.852a1.75 1.75 0 001-1.581V8A2.75 2.75 0 0016 5.25h-1.25v-1A2.25 2.25 0 0012.5 2h-5zm5.75 3.25v-1a.75.75 0 00-.75-.75h-5a.75.75 0 00-.75.75v1h6.5zm-2.183 7.09l5.183-1.538v3.386c0 1.139-.923 2.062-2.063 2.062H5.813a2.062 2.062 0 01-2.063-2.063v-3.385l5.183 1.539a3.75 3.75 0 002.134 0zM4 6.75c-.69 0-1.25.56-1.25 1.25v.754c0 .11.073.208.179.24l6.43 1.909a2.25 2.25 0 001.281 0l6.431-1.91a.25.25 0 00.179-.24V8c0-.69-.56-1.25-1.25-1.25H4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2.25A2.25 2.25 0 007.25 4.5v1.75H3.875A2.625 2.625 0 001.25 8.875v1.304c0 .825.384 1.576 1 2.062V18A3.75 3.75 0 006 21.75h12A3.75 3.75 0 0021.75 18v-5.76c.616-.485 1-1.236 1-2.061V8.875a2.625 2.625 0 00-2.625-2.625H16.75V4.5a2.25 2.25 0 00-2.25-2.25h-5zm5.75 4V4.5a.75.75 0 00-.75-.75h-5a.75.75 0 00-.75.75v1.75h6.5zm4.875 1.5c.621 0 1.125.504 1.125 1.125v1.304c0 .523-.36.977-.87 1.095l-6.676 1.558a7.5 7.5 0 01-3.408 0l-6.677-1.558a1.125 1.125 0 01-.869-1.095V8.875c0-.621.504-1.125 1.125-1.125h16.25zm-6.08 6.543l6.205-1.448V18A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18v-5.155l6.205 1.448a9 9 0 004.09 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.5 1A3.5 3.5 0 009 4.5V5a1 1 0 002 0v-.5A1.5 1.5 0 0112.5 3h7A1.5 1.5 0 0121 4.5V5a1 1 0 102 0v-.5A3.5 3.5 0 0019.5 1h-7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 10.5A3.5 3.5 0 015.5 7h21a3.5 3.5 0 013.5 3.5v2.94c0 .944-.377 1.817-1.005 2.456A.993.993 0 0129 16v7.5a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5V16c0-.035.002-.07.005-.104A3.498 3.498 0 012 13.441V10.5zm17.258 8.62L27 16.936V23.5a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 23.5v-6.564l7.742 2.184a12 12 0 006.515 0zM5.5 9A1.5 1.5 0 004 10.5v2.94a1.5 1.5 0 001.093 1.445l8.192 2.31a10 10 0 005.43 0l8.192-2.31A1.5 1.5 0 0028 13.44V10.5A1.5 1.5 0 0026.5 9h-21z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19 11.5A6.5 6.5 0 0125.5 5h13a6.5 6.5 0 016.5 6.5v1a1.5 1.5 0 01-3 0v-1A3.5 3.5 0 0038.5 8h-13a3.5 3.5 0 00-3.5 3.5v1a1.5 1.5 0 01-3 0v-1z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 32.25a6.426 6.426 0 01-2-4.656v-5.14C5 18.876 7.923 16 11.5 16h41c3.577 0 6.5 2.876 6.5 6.454v5.14a6.426 6.426 0 01-2 4.657V47.5C57 53.299 52.299 58 46.5 58h-29C11.701 58 7 53.299 7 47.5V32.25zm47 1.627V47.5a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5V33.877l15.783 4.293a23.697 23.697 0 0012.434 0L54 33.877zM8 22.454C8 20.559 9.554 19 11.5 19h41c1.946 0 3.5 1.56 3.5 3.454v5.14a3.461 3.461 0 01-2.57 3.33l-16 4.351a20.697 20.697 0 01-10.86 0l-16-4.352A3.461 3.461 0 018 27.593v-5.14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBagBriefcaseRegular)
