import { memo } from 'react'
import { Link } from 'react-router-dom'

import Button from '@ui/Button'
import { IconWarningCircleRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { EMainRoutes } from '@lib/navigation'
import { cx } from '@lib/styles'

import { useAuthStore } from '@modules/auth/store/AuthStore'

import styles from './styles.scss'
import { IFailCardProps } from './types'

const FailCard = ({
  title = 'Произошла непредвиденная ошибка!',
  description,
  buttonClassName,
  titleButton,
  handleClick,
  className,
  classDescriptionName,
  fullSize = false,
}: IFailCardProps) => {
  const classCardNames = cx(styles.card, className)
  const classDescriptionNames = cx(styles.description, classDescriptionName)
  const authStore = useAuthStore()
  return (
    <CardWrapper className={classCardNames}>
      <Typography size={24}>{title}</Typography>
      <IconWarningCircleRegular
        size={64}
        color={COLORS.STATE.ATTENTION}
        className={styles.icon}
      />
      <Typography size={18} className={classDescriptionNames}>
        {description}
      </Typography>

      <Button
        fullSize={fullSize}
        className={buttonClassName}
        onClick={handleClick}>
        {titleButton}
      </Button>

      {authStore.isAuthenticated ? (
        <Link to={EMainRoutes.MainSupport} className={styles.link}>
          Сообщить о проблеме
        </Link>
      ) : (
        <Button
          className={styles.link}
          type="link"
          href="mailto:info@rdwcomp.ru">
          Написать в поддержку
        </Button>
      )}
    </CardWrapper>
  )
}

export default memo(FailCard)
