import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.45 6.558a.75.75 0 10-1.06-1.06L7.153 8.734a.25.25 0 01-.354 0L5.522 7.458a.75.75 0 10-1.06 1.06l1.277 1.278a1.75 1.75 0 002.474 0l3.238-3.238z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 100 14A7 7 0 008 1zM2.5 8a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.33 7.992a.75.75 0 10-1.062-1.06l-4.174 4.173a.5.5 0 01-.707 0L6.732 9.45a.75.75 0 10-1.06 1.061l1.654 1.655a2 2 0 002.829 0l4.174-4.174z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zm-6.499 8A6.499 6.499 0 1116.5 10 6.499 6.499 0 013.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.187 9.492a.75.75 0 00-1.06-1.06l-5.315 5.313a.75.75 0 01-1.06 0L7.576 11.57a.75.75 0 10-1.061 1.06l2.176 2.176a2.25 2.25 0 003.182 0l5.314-5.314z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23.058 12.487a1 1 0 00-1.414-1.415L14.617 18.1a1 1 0 01-1.414 0l-2.847-2.847a1 1 0 00-1.414 1.414l2.847 2.847a3 3 0 004.243 0l7.026-7.026z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM5 16C5 9.925 9.925 5 16 5s11 4.925 11 11-4.925 11-11 11S5 22.075 5 16z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M47.047 24.183a1.5 1.5 0 10-2.121-2.121L29.759 37.229a2.5 2.5 0 01-3.536 0l-6.2-6.2a1.5 1.5 0 10-2.121 2.12l6.2 6.2a5.5 5.5 0 007.778 0l15.167-15.166z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zM9 32C9 19.297 19.297 9 32 9s23 10.297 23 23-10.297 23-23 23S9 44.703 9 32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckCircleRegular)
