import { memo, useCallback } from 'react'

import Button from '@ui/Button'
import { Table } from '@ui/Table'
import TableItem from '@ui/Table/components/TableItem'
import TableRow from '@ui/Table/components/TableRow'
import { HeaderItem } from '@ui/Table/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { getCostOfComplactations, getCurrencyString } from '@lib/helpers'
import { EMainRoutes } from '@lib/navigation'
import { getRouteById } from '@lib/navigation/MainRoutes'

import { EOrderStatus } from '@modules/configurator/enums'
import {
  IOrder,
  lokalizedOrderStatus,
} from '@modules/configurator/models/Configurator'

import TablePlacholder from '../TablePlacholder'

import styles from './TableOrders.module.scss'
interface ITableOrders {
  header: HeaderItem[]
  orders: IOrder[]
}

const TableOrders = ({ orders, header }: ITableOrders) => {
  const getTextColorForStatus = useCallback((status: EOrderStatus) => {
    switch (status) {
      case EOrderStatus.SUCCESS:
        return COLORS.STATE.SUCCESS_H
      case EOrderStatus.PROCESSING:
        return COLORS.BRAND.OCEAN
      default:
        return COLORS.STATE.ATTENTION
    }
  }, [])

  if (orders?.length === 0) {
    return <TablePlacholder header={header} className={styles.table} />
  }

  return (
    <Table header={header} className={styles.table}>
      {orders.map((order) => (
        <TableRow key={order.id}>
          <TableItem align="left">{order.id}</TableItem>
          <TableItem align="center">
            {new Date(order.created_at).toLocaleDateString()}
          </TableItem>
          <TableItem align="center">
            <Typography size={16} color={getTextColorForStatus(order.status)}>
              {lokalizedOrderStatus[order.status]}
            </Typography>
          </TableItem>
          <TableItem align="center">
            {getCurrencyString(getCostOfComplactations(order.complectations))}
          </TableItem>
          <TableItem align="center">
            <Button
              size="small"
              className={styles.button}
              linkTo={getRouteById(order.id, EMainRoutes.ConfiguratorOrder)}>
              Смотреть
            </Button>
          </TableItem>
        </TableRow>
      ))}
    </Table>
  )
}

export default memo(TableOrders)
