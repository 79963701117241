import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFlagALight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 15.5a.5.5 0 001 0V11h10.5a.5.5 0 00.4-.8l-2.775-3.7L13.9 2.8a.5.5 0 00-.4-.8h-10A1.5 1.5 0 002 3.5v12zM3.5 3a.5.5 0 00-.5.5V10h9.5l-2.4-3.2a.5.5 0 010-.6L12.5 3h-9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19.5a.5.5 0 001 0V14h13.5a.5.5 0 00.41-.787L14.61 8.5l3.3-4.713A.5.5 0 0017.5 3h-13A1.5 1.5 0 003 4.5v15zM4.5 4a.5.5 0 00-.5.5V13h12.54l-2.95-4.213a.5.5 0 010-.574L16.54 4H4.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFlagALight)
