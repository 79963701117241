import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoFacebookLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.125 4.5c0-.76.616-1.375 1.375-1.375h1a.625.625 0 100-1.25h-1A2.625 2.625 0 006.875 4.5v1.375H5a.625.625 0 100 1.25h1.875V13a.625.625 0 101.25 0V7.125H10a.625.625 0 100-1.25H8.125V4.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.143 2.195C9.89 1.406 10.943.99 12.16.99c.552 0 1.152.049 1.607.097a22.693 22.693 0 01.725.088l.044.006.012.002h.004l-.073.495.073-.495a.5.5 0 01.427.495V4.5a.5.5 0 01-.5.5h-1.214c-.493 0-.803.148-.986.322A.907.907 0 0012 5.99V7h2.48a.5.5 0 01.494.57l-.427 3a.5.5 0 01-.495.43H12v6.531a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V11H5.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H8V5.397c0-1.307.398-2.416 1.143-3.202zm4.836-.078a21.902 21.902 0 00-.317-.036c-.44-.046-1-.091-1.502-.091-.982 0-1.758.33-2.291.893C9.333 3.448 9 4.293 9 5.397V7.5a.5.5 0 01-.5.5H6v2h2.5a.5.5 0 01.5.5v6.531h2V10.5a.5.5 0 01.5-.5h2.118l.285-2H11.5a.5.5 0 01-.5-.5V5.99c0-.506.19-1.013.59-1.393.4-.38.974-.597 1.675-.597h.714V2.117z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoFacebookLight)
