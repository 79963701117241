import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiWindowNewUpRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 1.25A2.25 2.25 0 003.25 3.5v.75H2.5A2.25 2.25 0 00.25 6.5v5a2.25 2.25 0 002.25 2.25h7a2.25 2.25 0 002.25-2.25v-.75h.75a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25h-7zm4.75 9.5v.75a.75.75 0 01-.75.75h-7a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75h.75V8.5a2.25 2.25 0 002.25 2.25h4.75zm2.25-1.5a.75.75 0 00.75-.75v-5a.75.75 0 00-.75-.75h-7a.75.75 0 00-.75.75v5c0 .414.336.75.75.75h7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25A2.75 2.75 0 005.25 5v1.25H4A2.75 2.75 0 001.25 9v5A2.75 2.75 0 004 16.75h8A2.75 2.75 0 0014.75 14v-1.25H16A2.75 2.75 0 0018.75 10V5A2.75 2.75 0 0016 2.25H8zm5.25 10.5H8A2.75 2.75 0 015.25 10V7.75H4c-.69 0-1.25.56-1.25 1.25v5c0 .69.56 1.25 1.25 1.25h8c.69 0 1.25-.56 1.25-1.25v-1.25zM6.75 5c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v5c0 .69-.56 1.25-1.25 1.25H8c-.69 0-1.25-.56-1.25-1.25V5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2.25A3.25 3.25 0 006.25 5.5v7a3.25 3.25 0 003.25 3.25h10a3.25 3.25 0 003.25-3.25v-7a3.25 3.25 0 00-3.25-3.25h-10zM7.75 5.5c0-.966.784-1.75 1.75-1.75h10c.966 0 1.75.784 1.75 1.75v7a1.75 1.75 0 01-1.75 1.75h-10a1.75 1.75 0 01-1.75-1.75v-7z"
          fill="currentColor"
        />
        <path
          d="M2.75 10.05a1.3 1.3 0 011.3-1.3.75.75 0 000-1.5 2.8 2.8 0 00-2.8 2.8v7.45a3.25 3.25 0 003.25 3.25h10.45a2.8 2.8 0 002.8-2.8.75.75 0 00-1.5 0 1.3 1.3 0 01-1.3 1.3H4.5a1.75 1.75 0 01-1.75-1.75v-7.45z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 4A4.5 4.5 0 008 8.5v9a4.5 4.5 0 004.5 4.5h13a4.5 4.5 0 004.5-4.5v-9A4.5 4.5 0 0025.5 4h-13zM10 8.5A2.5 2.5 0 0112.5 6h13A2.5 2.5 0 0128 8.5v9a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5v-9z"
          fill="currentColor"
        />
        <path
          d="M5.68 12.033a1 1 0 10-.36-1.967A4.051 4.051 0 002 14.05v9.45A4.5 4.5 0 006.5 28h13.45a4.05 4.05 0 003.984-3.32 1 1 0 10-1.967-.36A2.051 2.051 0 0119.95 26H6.5A2.5 2.5 0 014 23.5v-9.45c0-1.005.724-1.843 1.68-2.017z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 8a7.5 7.5 0 00-7.5 7.5v20a7.5 7.5 0 007.5 7.5h28a7.5 7.5 0 007.5-7.5v-20A7.5 7.5 0 0052.5 8h-28zM20 15.5a4.5 4.5 0 014.5-4.5h28a4.5 4.5 0 014.5 4.5v20a4.5 4.5 0 01-4.5 4.5h-28a4.5 4.5 0 01-4.5-4.5v-20z"
        fill="currentColor"
      />
      <path
        d="M7 28.5a4.5 4.5 0 014.5-4.5h1.1a1.5 1.5 0 000-3h-1.1A7.5 7.5 0 004 28.5v20a7.5 7.5 0 007.5 7.5h28a7.5 7.5 0 007.5-7.5v-1.1a1.5 1.5 0 00-3 0v1.1a4.5 4.5 0 01-4.5 4.5h-28A4.5 4.5 0 017 48.5v-20z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiWindowNewUpRightRegular)
