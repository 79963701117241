import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { TextArea } from '@ui/TextArea'
import { Typography } from '@ui/Typography'

import CardForm from '@lib/components/CardForm'
import DateInput from '@lib/components/DateInput'
import PriceInput from '@lib/components/PriceInput'

import { useMarketingFundsFormStore } from '@modules/marketing/store/MarketingFundsFormStore'

import styles from './styles.module.scss'

const MarketingFundsForm = () => {
  const marketingFundsFormStore = useMarketingFundsFormStore()

  const sendRequest = useCallback(async () => {
    await marketingFundsFormStore.handleSubmit()
  }, [])

  return (
    <div className={styles.container}>
      <CardForm direction="column">
        <Typography size={18}>Основная информация о мероприятии</Typography>
        <TextArea
          labelPosition="left"
          label="Расскажите о мероприятии"
          placeholder="Опишите предполагаемое мероприятие"
          rows={3}
          className={styles.area}
          size="medium"
          name="event"
          error={marketingFundsFormStore.form.fields.event.error}
          errorText={marketingFundsFormStore.form.fields.event.errorText}
          value={marketingFundsFormStore.form.fields.event.value}
          onChange={marketingFundsFormStore.form.handleFieldChange2}
          hasCount
          maxLength={1000}
        />
        <TextArea
          labelPosition="left"
          label="Результат мероприятия"
          placeholder="Опишите ожидаемый результат"
          rows={3}
          className={styles.area}
          size="medium"
          name="result"
          value={marketingFundsFormStore.form.fields.result.value}
          onChange={marketingFundsFormStore.form.handleFieldChange2}
          error={marketingFundsFormStore.form.fields.result.error}
          errorText={marketingFundsFormStore.form.fields.result.errorText}
          hasCount
          maxLength={1000}
        />
        <Typography size={18}>Дополнительная информация</Typography>
        <PriceInput
          className={styles.budget}
          label="Бюджет на мероприятие"
          name="budget"
          value={marketingFundsFormStore.form.fields.budget.value}
          onChange={marketingFundsFormStore.form.handleFieldChange2}
          error={marketingFundsFormStore.form.fields.budget.error}
          errorText={marketingFundsFormStore.form.fields.budget.errorText}
        />
        <DateInput
          className={styles.date}
          label="Дата проведения"
          name="date"
          value={marketingFundsFormStore.form.fields.date.value}
          onChange={marketingFundsFormStore.form.handleFieldChange2}
          error={marketingFundsFormStore.form.fields.date.error}
          errorText={marketingFundsFormStore.form.fields.date.errorText}
        />
        <Button
          className={styles.button}
          disabled={marketingFundsFormStore.isDisabledSend}
          onClick={sendRequest}>
          Отправить
        </Button>
      </CardForm>
    </div>
  )
}

export default observer(MarketingFundsForm)
