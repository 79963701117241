import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyCurrencyRubleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2a1 1 0 00-1 1v5H3.5a.5.5 0 000 1H5v2H3.5a.5.5 0 000 1H5v1.75a.5.5 0 001 0V12h3.5a.5.5 0 000-1H6V9h3.5a3.5 3.5 0 100-7H6zm3.5 6H6V3h3.5a2.5 2.5 0 010 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a1 1 0 00-1 1v7H4.5a.5.5 0 000 1H6v2H4.5a.5.5 0 000 1H6v2.5a.5.5 0 001 0V15h4.5a.5.5 0 000-1H7v-2h4.5a4.5 4.5 0 100-9H7zm4.5 8H7V4h4.5a3.5 3.5 0 110 7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleLight)
