import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowUiAuthLoginRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 2.75c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H6a.75.75 0 000 1.5h5A2.75 2.75 0 0013.75 12V4A2.75 2.75 0 0011 1.25H6a.75.75 0 000 1.5h5z"
          fill="currentColor"
        />
        <path
          d="M5.942 4.497a.75.75 0 00.053 1.06L7.866 7.25H1.5a.75.75 0 000 1.5h6.366l-1.871 1.694A.75.75 0 007 11.556l2.7-2.444a1.5 1.5 0 000-2.224l-2.7-2.444a.75.75 0 00-1.06.053z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.999 2.751a.75.75 0 01.75-.75h7a3.25 3.25 0 013.25 3.25v9.5a3.25 3.25 0 01-3.25 3.25h-7a.75.75 0 010-1.5h7a1.75 1.75 0 001.75-1.75v-9.5a1.75 1.75 0 00-1.75-1.75h-7a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          d="M10.928 10.75l-2.822 2.821a.75.75 0 001.061 1.06l3.273-3.272c.75-.75.75-1.967 0-2.718L9.167 5.368a.75.75 0 10-1.06 1.06l2.82 2.822H1.75a.75.75 0 000 1.5h9.178z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.25 3A.75.75 0 018 2.25h8A3.75 3.75 0 0119.75 6v12A3.75 3.75 0 0116 21.75H8a.75.75 0 010-1.5h8A2.25 2.25 0 0018.25 18V6A2.25 2.25 0 0016 3.75H8A.75.75 0 017.25 3z"
          fill="currentColor"
        />
        <path
          d="M12.275 12.752l-3.641 3.642a.75.75 0 001.06 1.06l3.928-3.927a2.156 2.156 0 000-3.05L9.694 6.55a.75.75 0 00-1.06 1.06l3.641 3.642H1a.75.75 0 000 1.5h11.275z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 3a1 1 0 011-1h10.5A5.5 5.5 0 0128 7.5v17a5.5 5.5 0 01-5.5 5.5H12a1 1 0 110-2h10.5a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0022.5 4H12a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          d="M17.367 17l-4.855 4.855a1 1 0 001.414 1.415l5.237-5.237a2.875 2.875 0 000-4.066L13.926 8.73a1 1 0 00-1.414 1.415L17.367 15H2a1 1 0 100 2h15.367z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M42.5 9H22a1.5 1.5 0 010-3h20.5C48.299 6 53 10.701 53 16.5v31C53 53.299 48.299 58 42.5 58H22a1.5 1.5 0 010-3h20.5a7.5 7.5 0 007.5-7.5v-31A7.5 7.5 0 0042.5 9z"
        fill="currentColor"
      />
      <path
        d="M25.19 18.94a1.5 1.5 0 000 2.12l9.439 9.44H4a1.5 1.5 0 000 3h30.629l-9.44 9.44a1.5 1.5 0 002.122 2.12l9.878-9.878a4.5 4.5 0 000-6.364l-9.878-9.879a1.5 1.5 0 00-2.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiAuthLoginRegular)
