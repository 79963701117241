import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

const BIG_DESKTOP_MEDIA_QUERY = '(min-width: 1920px)'
@singleton()
class DeviceStore {
  isBigDesktop: boolean
  constructor() {
    makeAutoObservable(this)

    const matchMediaRef = window.matchMedia(BIG_DESKTOP_MEDIA_QUERY)
    matchMediaRef.addEventListener('change', (evt) => {
      this.setIsBigDesktop(evt.matches)
    })

    this.setIsBigDesktop(matchMediaRef.matches)
  }

  private setIsBigDesktop(val: boolean) {
    this.isBigDesktop = val
  }
}

const useDeviceStore = () => container.resolve(DeviceStore)

// Do it via mobx way, to prevent unnecessary addEventListener calls
// cuz we're using it in icon components
export const useIsBigDesktop = () => {
  const deviceStore = useDeviceStore()
  return deviceStore.isBigDesktop
}
