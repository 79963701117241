import { makeAutoObservable, runInAction } from 'mobx'
import { container, singleton } from 'tsyringe'

import { CompanyManagerStore } from '@modules/company/store/CompanyManagerStore'
import { CompanyStore } from '@modules/company/store/CompanyStore'
import { UserStore } from '@modules/user/store/UserStore'

import {
  AuthRest,
  ILoginParams,
  IRegisterEmployeeParams,
  IRegisterParams,
  IRestoreParams,
} from '../services/AuthRest'

@singleton()
export class AuthStore {
  private authRest = container.resolve(AuthRest)
  private userStore = container.resolve(UserStore)
  private companyStore = container.resolve(CompanyStore)
  private companyManagerStore = container.resolve(CompanyManagerStore)
  // undefined means not-determined
  isAuthenticated?: boolean = undefined

  constructor() {
    makeAutoObservable<
      AuthStore,
      'authRest' | 'companyStore' | 'userStore' | 'companyManagerStore'
    >(this, {
      authRest: false,
      userStore: false,
      companyStore: false,
      companyManagerStore: false,
    })
  }

  checkIsAuthenticated = async () => {
    try {
      const isAuthenticated = await this.authRest.checkIsAuthenticated()
      runInAction(() => {
        this.isAuthenticated = isAuthenticated
      })
    } catch (err) {
      this.isAuthenticated = false
      console.error(err)
    }
  }

  async login(params: ILoginParams) {
    await this.authRest.login(params)
    runInAction(() => {
      this.isAuthenticated = true
    })
  }

  async register(params: IRegisterParams) {
    await this.authRest.register(params)
  }

  async registerEmployee(params: IRegisterEmployeeParams) {
    await this.authRest.registerEmployee(params)
  }

  async restore(params: IRestoreParams) {
    await this.authRest.restore(params)
  }

  logout() {
    this.authRest.logout()
    this.isAuthenticated = false
    this.userStore.clear()
    this.companyStore.clear()
    this.companyManagerStore.clear()
  }
}

export const useAuthStore = () => container.resolve(AuthStore)
