import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Input } from '@ui/Input'

import CardForm from '@lib/components/CardForm'
import NotFoundPage from '@lib/components/NotFoundPage'
import PageWrapper from '@lib/components/Page/PageWrapper'
import TaggedContainer, {
  ITagOption,
} from '@lib/components/TaggedContainer/TaggedContainer'

import { useCompanyStore } from '@modules/company/store/CompanyStore'

import styles from './styles.module.scss'

const EmployeePage = observer(() => {
  const companyStore = useCompanyStore()
  const { id } = useParams()

  const [data, setData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    companyStore
      .getEmployee(+id)
      .then((employee) => {
        setData(employee)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [id])

  const parseFullName = (str: string) => {
    const [name, surname, patronymic] = str.split(' ')

    return { name, surname, patronymic }
  }

  const tags: ITagOption[] = useMemo(
    () => [
      {
        label: 'Организация',
        value: companyStore.company.name,
      },
      {
        label: 'Должность',
        value: data?.position,
      },
      {
        label: 'Роль',
        value: 'Сотрудник',
      },
    ],
    [id, companyStore.company.name, data?.position],
  )
  return (
    <PageWrapper
      title="Информация о сотруднике"
      className={styles.container}
      isLoading={isLoading}>
      {!data ? (
        <NotFoundPage />
      ) : (
        <>
          <TaggedContainer tags={tags} />
          <CardForm title="Основная информация" direction="row">
            <Input
              label="Имя"
              value={parseFullName(data.fullName).name}
              disabled
            />
            <Input
              label="Фамилия"
              value={parseFullName(data.fullName).surname}
              disabled
            />
            <Input
              label="Отчество"
              value={parseFullName(data.fullName).patronymic}
              disabled
            />
          </CardForm>
          <CardForm title="Контактная информация" direction="column">
            <Input label="Мобильный телефон" value={data.phone} disabled />
            <Input label="Email" value={data.email} disabled />
          </CardForm>
        </>
      )}
    </PageWrapper>
  )
})

export default EmployeePage
