import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureASquareUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 11.75a.75.75 0 01-.75-.75V6.572L6.076 7.98a.75.75 0 01-1.152-.96l1.732-2.078c.7-.84 1.989-.84 2.688 0l1.732 2.078a.75.75 0 01-1.152.96L8.75 6.572V11a.75.75 0 01-.75.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 14.75a3.25 3.25 0 01-3.25-3.25v-7A3.25 3.25 0 014.5 1.25h7a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-7zM2.75 11.5c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-7A1.75 1.75 0 002.75 4.5v7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 14a.75.75 0 01-.75-.75V8.056l-1.674 2.009a.75.75 0 01-1.152-.96l2.232-2.679c.7-.84 1.989-.84 2.688 0l2.232 2.679a.75.75 0 01-1.152.96L10.75 8.057v5.192A.75.75 0 0110 14z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 17.75A3.75 3.75 0 012.25 14V6A3.75 3.75 0 016 2.25h8A3.75 3.75 0 0117.75 6v8A3.75 3.75 0 0114 17.75H6zM3.75 14A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14V6A2.25 2.25 0 0014 3.75H6A2.25 2.25 0 003.75 6v8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 17.25a.75.75 0 01-.75-.75V8.81l-2.72 2.72a.75.75 0 01-1.06-1.06l3.005-3.006a2.156 2.156 0 013.05 0l3.005 3.006a.75.75 0 11-1.06 1.06l-2.72-2.72v7.69a.75.75 0 01-.75.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 21.75a4.25 4.25 0 01-4.25-4.25v-11A4.25 4.25 0 016.5 2.25h11a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-11zM3.75 17.5a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-11a2.75 2.75 0 00-2.75-2.75h-11A2.75 2.75 0 003.75 6.5v11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 23a1 1 0 01-1-1V11.59L11.692 14.9a1 1 0 01-1.414-1.415l3.6-3.6a2.99 2.99 0 012.001-.877 1.01 1.01 0 01.242 0 2.99 2.99 0 012 .876l3.601 3.601a1 1 0 01-1.414 1.415L17 11.59V22a1 1 0 01-1 1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 29A5.5 5.5 0 013 23.5v-15A5.5 5.5 0 018.5 3h15A5.5 5.5 0 0129 8.5v15a5.5 5.5 0 01-5.5 5.5h-15zM5 23.5A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5v-15A3.5 3.5 0 0023.5 5h-15A3.5 3.5 0 005 8.5v15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 46.5a1.5 1.5 0 01-1.5-1.5V21.896l-7.94 7.94a1.5 1.5 0 01-2.12-2.122l8.378-8.378a4.5 4.5 0 016.364 0l8.379 8.378a1.5 1.5 0 01-2.122 2.122l-7.939-7.94V45a1.5 1.5 0 01-1.5 1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 57C11.701 57 7 52.299 7 46.5v-29C7 11.701 11.701 7 17.5 7h29C52.299 7 57 11.701 57 17.5v29C57 52.299 52.299 57 46.5 57h-29zM10 46.5a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29a7.5 7.5 0 00-7.5-7.5h-29a7.5 7.5 0 00-7.5 7.5v29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareUpRegular)
