import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { IUser } from '@modules/user/models/User'

type UpdateUserParams = Omit<IUser, 'company'>

@singleton()
export class UserRest extends AbstractRest {
  async getUser() {
    const { data } = await this.http.get<IUser>('/users/info')
    return data
  }

  async updateUser(userData: UpdateUserParams) {
    const { data } = await this.http.patch<IUser>(`/users/info`, userData)
    return data
  }
}
