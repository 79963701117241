import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNotificationBellOffRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.218.47a.75.75 0 00-1.06 1.06l2.407 2.408A4.978 4.978 0 003 6.25v3.014L1.386 11.57A.75.75 0 002 12.75h3.5v.208a2.5 2.5 0 005 0v-.208h1.877l1.78 1.78a.75.75 0 101.06-1.06l-13-13zm8.659 10.78L4.702 5.075c-.13.367-.202.763-.202 1.175V9.5a.75.75 0 01-.136.43l-.924 1.32h7.437zM7 12.75v.208a1 1 0 102 0v-.208H7z"
          fill="currentColor"
        />
        <path
          d="M11.5 6.25v1.502l1.5 1.5V6.25a5 5 0 00-7.393-4.391l1.127 1.127A3.5 3.5 0 0111.5 6.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.53 1.47a.75.75 0 00-1.06 1.06l2.835 2.835A6.47 6.47 0 003.5 8.5v3.314c0 .03-.009.06-.025.085l-1.287 1.976A1.375 1.375 0 003.34 16h4.035v.4a2.625 2.625 0 005.25 0V16h2.314l2.53 2.53a.75.75 0 101.061-1.06l-16-16zM13.44 14.5L5.422 6.484A4.983 4.983 0 005 8.5v3.314c0 .321-.093.635-.268.904L3.57 14.5h9.868zM8.874 16v.4a1.125 1.125 0 002.25 0V16h-2.25z"
          fill="currentColor"
        />
        <path
          d="M15 11.814c0 .1.01.2.027.299l2.94 2.94c.123-.373.09-.8-.155-1.178l-1.287-1.976a.156.156 0 01-.025-.085V8.5A6.5 6.5 0 006.165 3.251l1.078 1.077A5 5 0 0115 8.5v3.314z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.821 1.645a.75.75 0 00-1.06 1.061l3.398 3.399A7.716 7.716 0 004.25 9.75v4.377a.388.388 0 01-.075.23l-1.654 2.256C1.794 17.603 2.5 19 3.73 19H9v.5a3 3 0 106 0V19h3.053l3.206 3.206a.75.75 0 001.061-1.06l-19.5-19.5zM16.554 17.5L6.281 7.226a6.229 6.229 0 00-.53 2.524v4.377c-.001.402-.129.793-.366 1.117L3.73 17.5h12.824zM10.5 19v.5a1.5 1.5 0 103 0V19h-3z"
          fill="currentColor"
        />
        <path
          d="M18.616 15.244l.254.347 2.684 2.684a1.486 1.486 0 00-.074-1.662l-1.655-2.256a.387.387 0 01-.075-.23V9.75A7.75 7.75 0 007.06 3.78l1.066 1.066A6.25 6.25 0 0118.25 9.75v4.377c0 .402.128.793.366 1.117z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.707 2.293a1 1 0 00-1.414 1.414l4.466 4.466A9.97 9.97 0 006 12v6.012a.5.5 0 01-.096.295l-2.558 3.517C2.385 23.146 3.33 25 4.964 25h18.622l4.707 4.707a1 1 0 001.414-1.414l-26-26zM21.586 23L8.324 9.738A8.002 8.002 0 008 12v6.012c0 .528-.167 1.043-.478 1.471L4.964 23h16.622z"
          fill="currentColor"
        />
        <path
          d="M24.478 19.483l2.331 3.205 1.685 1.684c.613-.658.77-1.71.16-2.548l-2.558-3.517a.501.501 0 01-.096-.295V12c0-5.523-4.477-10-10-10a9.968 9.968 0 00-7.01 2.869l1.414 1.414A8 8 0 0124 12v6.012c0 .528.168 1.043.478 1.471zM19.882 27.215a1 1 0 00-1.941-.483 2 2 0 01-3.878.015 1 1 0 10-1.937.497 4 4 0 007.756-.03z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.06 3.94a1.5 1.5 0 10-2.12 2.12l8.81 8.812A19.907 19.907 0 0012 25v11.524c0 .317-.1.627-.287.883l-5.116 7.034C4.914 46.755 6.567 50 9.427 50H49.88l8.06 8.06a1.5 1.5 0 002.122-2.12l-52-52zM46.88 47L16.955 17.077A16.926 16.926 0 0015 25v11.524c0 .951-.302 1.878-.861 2.648l-5.116 7.034a.5.5 0 00.405.794h37.45z"
        fill="currentColor"
      />
      <path
        d="M49.861 39.172l4.962 6.823 2.574 2.574c.838-1.153.975-2.795.006-4.128l-5.116-7.034a1.503 1.503 0 01-.287-.883V25c0-11.046-8.954-20-20-20a19.924 19.924 0 00-13.198 4.973l2.126 2.127A16.933 16.933 0 0132 8c9.389 0 17 7.611 17 17v11.524c0 .951.301 1.878.861 2.648zM39.395 53.753a1.5 1.5 0 10-2.958-.501 4.5 4.5 0 01-8.873.004 1.5 1.5 0 10-2.957.505 7.5 7.5 0 0014.788-.008z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNotificationBellOffRegular)
