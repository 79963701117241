import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSecurityKeyBRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M5 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3.25a4.75 4.75 0 104.17 7.025l.42.446a1.25 1.25 0 001.82 0l.59-.627.537.57c.54.574 1.47.511 1.927-.13l1.296-1.816a1.25 1.25 0 00.018-1.426L14.77 5.8a1.25 1.25 0 00-1.036-.55h-4.86A4.745 4.745 0 005 3.25zM1.75 8a3.25 3.25 0 015.941-1.823 1.29 1.29 0 001.062.573h4.849l.836 1.238-1.018 1.427-.51-.542a1.25 1.25 0 00-1.82 0l-.59.626-.923-.98a.854.854 0 00-1.446.358A3.252 3.252 0 011.75 8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.155 6.906a3.094 3.094 0 100 6.188 3.094 3.094 0 000-6.188zM4.56 10a1.594 1.594 0 113.187 0 1.594 1.594 0 01-3.187 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.155 4.002a5.998 5.998 0 105.437 8.532s.003-.005.011-.011l.004-.003.84.87c.538.559 1.432.561 1.973.005l.972-.996.801.89c.567.63 1.563.602 2.094-.06l1.363-1.697c.4-.497.404-1.203.012-1.706l-1.336-1.713c-.26-.334-.66-.53-1.084-.53h-5.596a6 6 0 00-5.491-3.581zM1.656 10a4.498 4.498 0 018.627-1.788c.211.486.696.871 1.3.871h5.598l1.238 1.587-1.21 1.506-.791-.88a1.375 1.375 0 00-2.007-.04l-.975 1-.796-.825a1.411 1.411 0 00-1.36-.38c-.43.103-.836.399-1.047.849A4.5 4.5 0 011.656 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 6.563a5.437 5.437 0 104.914 7.768 1.727 1.727 0 011.157-.93c.482-.114 1.061-.023 1.485.416l1.067 1.106 1.278-1.31a1.5 1.5 0 012.189.043l1.068 1.187 1.636-2.037-1.604-2.056h-6.796c-.655 0-1.184-.416-1.418-.946A5.439 5.439 0 007.5 6.562zM.562 12a6.937 6.937 0 0113.286-2.803c.01.024.025.04.037.048a.036.036 0 00.01.005h6.795a1.5 1.5 0 011.183.577l1.603 2.056a1.5 1.5 0 01-.013 1.862l-1.635 2.037a1.5 1.5 0 01-2.285.064l-1.068-1.187-1.278 1.311a1.5 1.5 0 01-2.153-.006l-1.067-1.105-.003-.002a.126.126 0 00-.055.004.228.228 0 00-.15.114A6.938 6.938 0 01.562 12zm13.332-2.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 9.938a2.062 2.062 0 100 4.124 2.062 2.062 0 000-4.124zM3.937 12a3.563 3.563 0 117.126 0 3.563 3.563 0 01-7.126 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 9a7 7 0 106.421 9.792 2.34 2.34 0 011.547-1.311c.651-.172 1.454-.068 2.041.54l1.488 1.543 1.704-1.748a2 2 0 012.919.059l1.424 1.582 2.18-2.716L27.588 14h-9.494c-.831 0-1.513-.505-1.846-1.161A7 7 0 0010 9zm-9 7a9 9 0 0117.031-4.066c.016.03.036.05.05.06a.05.05 0 00.012.006h9.494a2 2 0 011.577.77l2.138 2.74a2 2 0 01-.018 2.483l-2.18 2.716a2 2 0 01-3.046.086l-1.425-1.583-1.703 1.748a2 2 0 01-2.872-.008L18.57 19.41l-.004-.002a.198.198 0 00-.09.007.342.342 0 00-.221.176A9 9 0 011 16zm17.095-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 13.751a2.249 2.249 0 100 4.498 2.249 2.249 0 000-4.498zM5.751 16a4.249 4.249 0 118.498 0 4.249 4.249 0 01-8.498 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 23a9 9 0 100 18 9 9 0 000-18zm-6 9a6 6 0 1112 0 6 6 0 01-12 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 14c-9.941 0-18 8.059-18 18s8.059 18 18 18c7.455 0 13.848-4.532 16.581-10.985.124-.293.416-.541.764-.634.332-.089.547-.001.67.126l2.461 2.55a3.5 3.5 0 005.025.014l3.408-3.495a.5.5 0 01.73.014l2.848 3.165a3.5 3.5 0 005.33-.15l4.362-5.432a3.5 3.5 0 00.03-4.344l-4.276-5.482A3.5 3.5 0 0055.173 24H36.49c-.178 0-.414-.113-.56-.39C32.915 17.9 26.914 14 20 14zM5 32c0-8.284 6.716-15 15-15 5.758 0 10.76 3.244 13.276 8.01.6 1.139 1.79 1.99 3.213 1.99h18.684a.5.5 0 01.395.192l4.276 5.483a.5.5 0 01-.005.62l-4.361 5.432a.5.5 0 01-.761.021l-2.849-3.165a3.5 3.5 0 00-5.107-.102l-3.408 3.495a.5.5 0 01-.718-.001l-2.461-2.551c-1.025-1.062-2.435-1.253-3.601-.942-1.152.307-2.234 1.135-2.754 2.363C31.539 43.228 26.209 47 20 47c-8.284 0-15-6.716-15-15z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityKeyBRegular)
