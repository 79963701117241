import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDataChartPieA2Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a.75.75 0 00-.75.75V8c0 .414.336.75.75.75h6.25A.75.75 0 0015 8a7.002 7.002 0 00-7-7zm.75 6.25V2.551a5.5 5.5 0 014.699 4.699H8.75z"
          fill="currentColor"
        />
        <path
          d="M5.586 3.058a.75.75 0 00-.658-1.348 7 7 0 109.354 9.377.75.75 0 00-1.346-.661 5.5 5.5 0 11-7.35-7.368z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001a.75.75 0 00-.75.75v7.25c0 .413.336.75.75.75h7.249a.75.75 0 00.75-.75A7.998 7.998 0 0010 2zm.75 7.25V3.544a6.499 6.499 0 015.705 5.705H10.75z"
          fill="currentColor"
        />
        <path
          d="M7.186 4.142a.75.75 0 00-.65-1.352 7.999 7.999 0 1010.645 10.733.75.75 0 10-1.347-.66 6.498 6.498 0 11-8.648-8.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h9a.75.75 0 00.75-.75A9.75 9.75 0 0012 2.25zm.75 9V3.784a8.25 8.25 0 017.466 7.466H12.75z"
          fill="currentColor"
        />
        <path
          d="M9.026 4.305a.75.75 0 10-.54-1.4A9.75 9.75 0 1021.09 15.524a.75.75 0 00-1.399-.542A8.25 8.25 0 119.026 4.305z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.541 3.011A1.484 1.484 0 0015 4.5v11a1.5 1.5 0 001.5 1.5h11c.817 0 1.525-.67 1.489-1.541A12.998 12.998 0 0016.54 3.01zM17 15V5.046A11 11 0 0126.954 15H17z"
          fill="currentColor"
        />
        <path
          d="M11.416 6a1 1 0 00-.834-1.817 13 13 0 1017.671 16.16 1 1 0 00-1.885-.669A11 11 0 1111.416 6.001z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.562 6.022C32.117 5.962 31 7.137 31 8.5v22a2.5 2.5 0 002.5 2.5h22c1.363 0 2.538-1.117 2.478-2.562A25.498 25.498 0 0033.562 6.022zM34 30V9.05A22.5 22.5 0 0154.95 30H34z"
        fill="currentColor"
      />
      <path
        d="M24.964 10.103a1.5 1.5 0 00-.918-2.856 26 26 0 1032.691 32.758 1.5 1.5 0 10-2.854-.924 23.001 23.001 0 11-28.92-28.978z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartPieA2Regular)
