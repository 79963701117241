import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconToolPencilSquareRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.931 2.011L5.763 7.18a1.75 1.75 0 00-.513 1.237v1.588c0 .414.336.75.75.75h1.588c.464 0 .91-.184 1.237-.512l6.363-6.362a1.75 1.75 0 000-2.475L14.6.818a1.75 1.75 0 00-2.475 0L11.01 1.93a.77.77 0 00-.08.08zm2.608-.133a.25.25 0 00-.354 0l-.623.624.942.941.623-.623a.25.25 0 000-.354l-.588-.588zm-1.096 2.626l-.941-.942-4.679 4.679a.25.25 0 00-.073.176v.838h.838a.25.25 0 00.177-.073l4.678-4.678z"
          fill="currentColor"
        />
        <path
          d="M4.5 2.75A1.75 1.75 0 002.75 4.5v7c0 .967.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-3a.75.75 0 011.5 0v3a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7A3.25 3.25 0 014.5 1.25h3a.75.75 0 110 1.5h-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.273 1.576a1.75 1.75 0 012.475 0l.681.681a1.75 1.75 0 010 2.475l-7.752 7.752a1.75 1.75 0 01-1.238.513H7.76a.75.75 0 01-.75-.75v-1.681c0-.465.184-.91.512-1.238l7.752-7.752zm1.414 1.06a.25.25 0 00-.353 0l-.819.819L16.55 4.49l.819-.819a.25.25 0 000-.353l-.682-.682zM15.49 5.55l-1.035-1.034-5.874 5.873a.25.25 0 00-.073.177v.931h.931a.25.25 0 00.177-.073L15.49 5.55z"
          fill="currentColor"
        />
        <path
          d="M6 3.75A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14v-4a.75.75 0 011.5 0v4A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6A3.75 3.75 0 016 2.25h4a.75.75 0 010 1.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.988 1.494a1.75 1.75 0 012.475 0l1.043 1.043a1.75 1.75 0 010 2.475l-10.08 10.08a2.25 2.25 0 01-1.59.658H9a.75.75 0 01-.75-.75v-1.836c0-.596.237-1.169.659-1.59l10.08-10.08zm1.415 1.06a.25.25 0 00-.354 0l-1.241 1.242 1.396 1.396 1.241-1.241a.25.25 0 000-.353l-1.042-1.043zm-1.26 3.699l-1.396-1.397-7.777 7.778a.75.75 0 00-.22.53v1.086h1.086a.75.75 0 00.53-.22l7.778-7.777z"
          fill="currentColor"
        />
        <path
          d="M6.5 3.75A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75V12a.75.75 0 011.5 0v5.5a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11A4.25 4.25 0 016.5 2.25H12a.75.75 0 010 1.5H6.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.832 2.351a2.5 2.5 0 013.535 0l1.282 1.282a2.5 2.5 0 010 3.535L17.695 19.121a3 3 0 01-2.12.879H13.5a1.5 1.5 0 01-1.5-1.5v-2.074a3 3 0 01.879-2.121L24.832 2.35zm2.121 1.415a.5.5 0 00-.707 0L24.742 5.27l1.988 1.988 1.504-1.504a.5.5 0 000-.707l-1.28-1.281zm-1.637 4.906l-1.988-1.988-9.035 9.035a1 1 0 00-.293.707V18h1.574a1 1 0 00.707-.293l9.035-9.035z"
          fill="currentColor"
        />
        <path
          d="M8.5 5A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5V16a1 1 0 112 0v7.5a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15A5.5 5.5 0 018.5 3H16a1 1 0 110 2H8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.811 4.617a5.5 5.5 0 017.779 0l1.798 1.799a5.5 5.5 0 010 7.778L33.192 40.391a5.5 5.5 0 01-3.89 1.61h-4.798a2.5 2.5 0 01-2.5-2.5v-4.798a5.5 5.5 0 011.61-3.89L49.812 4.618zm5.657 2.121a2.5 2.5 0 00-3.535 0l-3.237 3.237 5.334 5.335 3.237-3.237a2.5 2.5 0 000-3.536l-1.799-1.799zm-3.56 10.693l-5.334-5.335-20.838 20.839a2.5 2.5 0 00-.732 1.768v4.299h4.299a2.5 2.5 0 001.767-.733L51.91 17.431z"
        fill="currentColor"
      />
      <path
        d="M17.5 10a7.5 7.5 0 00-7.5 7.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5V32a1.5 1.5 0 013 0v14.5C57 52.299 52.299 57 46.5 57h-29C11.701 57 7 52.299 7 46.5v-29C7 11.7 11.701 7 17.5 7H32a1.5 1.5 0 010 3H17.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconToolPencilSquareRegular)
