import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconDocsLight, IconUiMenuDots3HRegular } from '@ui/icons'
import { Table } from '@ui/Table'
import TableItem from '@ui/Table/components/TableItem'
import TableRow from '@ui/Table/components/TableRow'
import { HeaderItem } from '@ui/Table/types'
import Toast from '@ui/Toast'
import { Typography } from '@ui/Typography'

import Modal from '@lib/components/Modal'
import { EMainRoutes, getRouteById } from '@lib/navigation/MainRoutes'

import DropListEmployee from '@modules/company/components/TableEmployees/DropListEmployee'
import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { useUserStore } from '@modules/user/store/UserStore'

import styles from './styles.module.scss'

const header: HeaderItem[] = [
  {
    data: 'ФИО',
  },
  {
    data: 'ДОЛЖНОСТЬ',
  },
  {
    data: 'ПОЧТА',
  },
  {
    data: 'ТЕЛЕФОН',
  },
  {
    data: <IconUiMenuDots3HRegular />,
  },
]

const TableEmployees = ({ data }) => {
  const navigate = useNavigate()
  const userStore = useUserStore()
  const companyStore = useCompanyStore()
  const [openModal, setOpenModal] = useState(false)
  const [userId, setUserId] = useState(0)

  const [watchToast, setWatchToast] = useState(false)

  const copy = useCallback(() => {
    companyStore.copyToClipboard(
      `${window.location.origin}/registration_of_a_new_employee/company/${companyStore.company.id}`,
    )
    setWatchToast(true)
  }, [])

  const goToEmployee = useCallback((id) => {
    navigate(getRouteById(id, EMainRoutes.CompanyEmployee))
  }, [])

  const deleteEmployee = useCallback((id) => {
    companyStore.deleteEmployee(id).then(onClose)
  }, [])

  const onOpenModal = useCallback((event, id) => {
    event.preventDefault()
    event.stopPropagation()
    setUserId(id)
    console.log(id)
    setOpenModal(true)
  }, [])

  const onClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  return (
    <div>
      {data.length !== 0 ? (
        <Table className={styles.table}>
          {data.map((item) => (
            <TableRow
              className={styles.tableRow}
              withoutDivider={true}
              key={item.id}
              onClick={() => goToEmployee(item.id)}>
              <TableItem align="left">{item.fullName}</TableItem>
              <TableItem align="center">{item.position}</TableItem>
              <TableItem align="center">{item.email}</TableItem>
              <TableItem align="center">{item.phone}</TableItem>
              {userStore.isPartner && (
                <TableItem className={styles.dots} align="center">
                  <DropListEmployee
                    id={item.id}
                    goToEmployee={goToEmployee}
                    onOpenModal={onOpenModal}
                  />
                </TableItem>
              )}
            </TableRow>
          ))}
        </Table>
      ) : (
        <Table header={header} className={styles.emptyTable}>
          <TableRow>
            <TableItem align="center" colSpan={5}>
              <Typography size={18} className={styles.description}>
                Здесь будут отображаться сотрудники
              </Typography>
              {userStore.isPartner && (
                <Button
                  size="small"
                  IconLeft={IconDocsLight}
                  className={styles.button}
                  onClick={copy}>
                  Ссылка для регистрации сотрудника
                </Button>
              )}
            </TableItem>
          </TableRow>
        </Table>
      )}
      <Toast
        className={styles.toast}
        title="Ссылка скопирована"
        autoDeleteTime={2000}
        onVisible={watchToast}
        onCloseToast={(value) => setWatchToast(value)}
      />
      <Modal
        open={openModal}
        title="Удаление сотрудника"
        description="Сотрудник будет удален без возможности восстановления."
        firstButton={
          <Button withBorder type="fill" size="small" onClick={onClose}>
            Отменить
          </Button>
        }
        secondButton={
          <Button size="small" onClick={() => deleteEmployee(userId)}>
            Удалить
          </Button>
        }
        onClose={onClose}
      />
    </div>
  )
}

export default observer(TableEmployees)
