import { memo, useCallback, useState } from 'react'

import Button from '@ui/Button'
import { IconNetDownloadRegular } from '@ui/icons'
import { Typography } from '@ui/Typography'

import { useSupportStore } from '@modules/support/store/SupportStore'

import styles from './styles.module.scss'

const DownloadPartnercertificate = () => {
  const supportStore = useSupportStore()

  const [loading, setLoading] = useState(false)

  const handleDownload = useCallback(() => {
    setLoading(true)
    supportStore.downloadCertificate().finally(() => {
      setLoading(false)
    })
  }, [])
  return (
    <div className={styles.download}>
      <Typography weight="medium" size={18}>
        Партнерский сертификат
      </Typography>
      <div className={styles.button}>
        <Button
          size="small"
          loading={loading}
          IconLeft={IconNetDownloadRegular}
          download
          onClick={handleDownload}>
          Скачать партнерский сертификат
        </Button>
      </div>
    </div>
  )
}

export default memo(DownloadPartnercertificate)
