import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPlusSquareRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.75 5a.75.75 0 00-1.5 0v2.25H5a.75.75 0 000 1.5h2.25V11a.75.75 0 001.5 0V8.75H11a.75.75 0 000-1.5H8.75V5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1.25A3.25 3.25 0 001.25 4.5v7a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25v-7a3.25 3.25 0 00-3.25-3.25h-7zM2.75 4.5c0-.966.784-1.75 1.75-1.75h7c.966 0 1.75.784 1.75 1.75v7a1.75 1.75 0 01-1.75 1.75h-7a1.75 1.75 0 01-1.75-1.75v-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 100 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.25A3.75 3.75 0 002.25 6v8A3.75 3.75 0 006 17.75h8A3.75 3.75 0 0017.75 14V6A3.75 3.75 0 0014 2.25H6zM3.75 6A2.25 2.25 0 016 3.75h8A2.25 2.25 0 0116.25 6v8A2.25 2.25 0 0114 16.25H6A2.25 2.25 0 013.75 14V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.75 7.5a.75.75 0 00-1.5 0v3.75H7.5a.75.75 0 000 1.5h3.75v3.75a.75.75 0 001.5 0v-3.75h3.75a.75.75 0 000-1.5h-3.75V7.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 2.25A4.25 4.25 0 002.25 6.5v11a4.25 4.25 0 004.25 4.25h11a4.25 4.25 0 004.25-4.25v-11a4.25 4.25 0 00-4.25-4.25h-11zM3.75 6.5A2.75 2.75 0 016.5 3.75h11a2.75 2.75 0 012.75 2.75v11a2.75 2.75 0 01-2.75 2.75h-11a2.75 2.75 0 01-2.75-2.75v-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17 10a1 1 0 10-2 0v5h-5a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5v-5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 3A5.5 5.5 0 003 8.5v15A5.5 5.5 0 008.5 29h15a5.5 5.5 0 005.5-5.5v-15A5.5 5.5 0 0023.5 3h-15zM5 8.5A3.5 3.5 0 018.5 5h15A3.5 3.5 0 0127 8.5v15a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 23.5v-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M33.5 19a1.5 1.5 0 00-3 0v11.5H19a1.5 1.5 0 000 3h11.5V45a1.5 1.5 0 003 0V33.5H45a1.5 1.5 0 000-3H33.5V19z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7C11.701 7 7 11.701 7 17.5v29C7 52.299 11.701 57 17.5 57h29C52.299 57 57 52.299 57 46.5v-29C57 11.701 52.299 7 46.5 7h-29zM10 17.5a7.5 7.5 0 017.5-7.5h29a7.5 7.5 0 017.5 7.5v29a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5v-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPlusSquareRegular)
