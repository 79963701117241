import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconStarHalfLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.311.9A.75.75 0 018.69.9l1.764 4.096 4.442.412a.75.75 0 01.425 1.31l-3.35 2.944.98 4.352a.75.75 0 01-1.114.81L8 12.545l-3.835 2.277a.75.75 0 01-1.115-.81l.98-4.35L.68 6.717a.75.75 0 01.425-1.31l4.442-.412L7.31.899zm2.224 4.492a1 1 0 00.826.6l3.863.358-2.915 2.56a1 1 0 00-.315.972l.853 3.785-3.336-1.98a1 1 0 00-.51-.14v-9.72l1.534 3.565z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.139 1.7A.925.925 0 0110 1.133c.35 0 .698.19.861.567l2.03 4.713 5.11.474c.819.076 1.15 1.095.532 1.638l-3.856 3.387 1.129 5.006c.18.802-.686 1.432-1.393 1.012L10 15.31l-4.412 2.62c-.644.382-1.418-.104-1.418-.799 0-.07.008-.14.024-.214l1.129-5.005-3.856-3.387a.918.918 0 01-.32-.7c.001-.451.326-.89.853-.938l5.11-.474L9.138 1.7zM10 14.31a1 1 0 01.51.14l4.288 2.546-1.096-4.864a1 1 0 01.315-.972l3.747-3.29-4.965-.461a1 1 0 01-.827-.6L10 2.229v12.08z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconStarHalfLight)
