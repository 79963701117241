import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDivideCircleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.426 7a.5.5 0 100 1h6.148a.5.5 0 000-1H4.426zM8.5 10a1 1 0 11-2 0 1 1 0 012 0zM7.5 6a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM2 7.5a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6 9a.5.5 0 000 1h7a.5.5 0 000-1H6zM10.5 12.5a1 1 0 11-2 0 1 1 0 012 0zM9.5 7.5a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM3 9.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDivideCircleLight)
