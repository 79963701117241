import { memo, ReactNode } from 'react'

import { cx } from '@lib/styles'

import styles from './TableItem.module.scss'

interface ITableItem extends React.ComponentProps<'td'> {
  children: ReactNode
}

const TableItem = ({ children, className, ...restProps }: ITableItem) => (
  <td {...restProps} className={cx(className, styles.item)}>
    {children}
  </td>
)

export default memo(TableItem)
