import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconEyeOffRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.03.97A.75.75 0 10.97 2.03l1.924 1.925A8.55 8.55 0 00.05 7.733a.75.75 0 000 .534C1.272 11.471 4.369 13.75 8 13.75a8.46 8.46 0 003.796-.893l2.174 2.173a.75.75 0 101.06-1.06l-13-13zm8.634 10.755l-.958-.958a3.25 3.25 0 01-4.473-4.473L3.968 5.028A7.05 7.05 0 001.559 8a7.011 7.011 0 009.105 3.725zM6.351 7.412a1.75 1.75 0 002.237 2.237L6.351 7.412z"
          fill="currentColor"
        />
        <path
          d="M7.549 4.781l3.67 3.67a3.25 3.25 0 00-3.67-3.67z"
          fill="currentColor"
        />
        <path
          d="M14.44 8a7.041 7.041 0 01-1.482 2.19l1.06 1.06a8.538 8.538 0 001.932-2.983.75.75 0 000-.534C14.729 4.529 11.631 2.25 8 2.25c-.9 0-1.768.14-2.582.4l1.23 1.23A7.011 7.011 0 0114.44 8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.53 1.47a.75.75 0 00-1.06 1.06l2.435 2.435A10.506 10.506 0 00.39 9.351a1.65 1.65 0 000 1.298C1.987 14.38 5.687 17 10 17c1.723 0 3.349-.418 4.781-1.158l2.689 2.688a.75.75 0 001.06-1.06l-16-16zm11.128 13.249l-1.5-1.5a3.875 3.875 0 01-5.377-5.377l-1.8-1.8a9 9 0 00-3.21 3.9.15.15 0 000 .117C3.139 13.261 6.31 15.5 10 15.5a8.9 8.9 0 003.658-.781zM7.876 8.937a2.375 2.375 0 003.188 3.188L7.875 8.936z"
          fill="currentColor"
        />
        <path
          d="M9.564 6.15l4.287 4.287A3.875 3.875 0 009.564 6.15z"
          fill="currentColor"
        />
        <path
          d="M18.23 10.059a8.99 8.99 0 01-1.923 2.834l1.06 1.06c.945-.94 1.71-2.06 2.242-3.304a1.65 1.65 0 000-1.298C18.013 5.62 14.313 3 10 3c-1.084 0-2.13.166-3.113.473L8.114 4.7c.608-.131 1.24-.2 1.886-.2 3.69 0 6.86 2.24 8.23 5.441a.15.15 0 010 .118z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.03 1.97a.75.75 0 00-1.06 1.06l2.947 2.948A12.46 12.46 0 00.74 10.983a2.437 2.437 0 000 2.034C2.697 17.284 7.002 20.25 12 20.25c2.082 0 4.044-.515 5.766-1.424l3.204 3.204a.75.75 0 101.06-1.06l-19-19zm13.618 15.739l-2.04-2.04a4.5 4.5 0 01-6.275-6.276L5.996 7.057a10.954 10.954 0 00-3.895 4.552.937.937 0 000 .782C3.826 16.146 7.611 18.75 12 18.75c1.662 0 3.238-.373 4.648-1.041zm-7.233-7.233a3 3 0 004.109 4.109l-4.109-4.109z"
          fill="currentColor"
        />
        <path
          d="M11.021 7.607l1.426 1.426a3.002 3.002 0 012.52 2.52l1.426 1.426a4.5 4.5 0 00-5.372-5.372z"
          fill="currentColor"
        />
        <path
          d="M21.898 12.39a10.948 10.948 0 01-2.573 3.52l1.062 1.062a12.45 12.45 0 002.874-3.956 2.437 2.437 0 000-2.033C21.303 6.716 16.998 3.75 12 3.75c-1.448 0-2.838.249-4.13.706l1.195 1.195A10.873 10.873 0 0112 5.25c4.389 0 8.174 2.604 9.898 6.36a.937.937 0 010 .78z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.707 2.293a1 1 0 00-1.414 1.414l4.263 4.264a16.615 16.615 0 00-5.514 6.551c-.439.936-.439 2.02 0 2.956C3.68 23.102 9.384 27 16 27c2.776 0 5.392-.686 7.687-1.898l4.606 4.605a1 1 0 001.414-1.414l-26-26zm18.49 21.318l-2.72-2.72a6 6 0 01-8.367-8.367L7.995 9.41a14.607 14.607 0 00-5.143 5.961c-.187.399-.187.86 0 1.258C5.173 21.579 10.19 25 16 25c2.216 0 4.317-.498 6.197-1.389zm-9.643-9.643a4 4 0 005.478 5.478l-5.478-5.478z"
          fill="currentColor"
        />
        <path
          d="M14.632 10.157l1.875 1.875a4.002 4.002 0 013.461 3.461l1.875 1.875a6 6 0 00-7.212-7.212z"
          fill="currentColor"
        />
        <path
          d="M29.147 16.629a14.598 14.598 0 01-3.42 4.622l1.415 1.416a16.601 16.601 0 003.816-5.189c.439-.936.439-2.02 0-2.956C28.32 8.898 22.616 5 16 5c-1.952 0-3.825.34-5.563.962l1.59 1.59A14.496 14.496 0 0116 7c5.81 0 10.826 3.422 13.147 8.371.187.399.187.86 0 1.258z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06 6.94a1.5 1.5 0 10-2.12 2.12l6.952 6.953A32.73 32.73 0 002.71 28.895a7.128 7.128 0 000 6.21C7.977 45.99 19.11 53.5 32 53.5a32.332 32.332 0 0015.466-3.913l7.473 7.474a1.5 1.5 0 002.122-2.122l-48-48zm36.183 40.424l-5.885-5.884A11.948 11.948 0 0132 44c-6.627 0-12-5.373-12-12 0-2.773.94-5.326 2.52-7.358l-6.462-6.463A29.718 29.718 0 005.41 30.202a4.128 4.128 0 000 3.596C10.197 43.688 20.307 50.5 32 50.5c4.76 0 9.26-1.13 13.243-3.136zm-20.58-20.579a9 9 0 0012.55 12.55l-12.55-12.55z"
        fill="currentColor"
      />
      <path
        d="M28.162 20.627l2.476 2.475a9 9 0 0110.26 10.26l2.475 2.476C43.78 34.633 44 33.342 44 32c0-6.627-5.373-12-12-12-1.342 0-2.633.22-3.838.627z"
        fill="currentColor"
      />
      <path
        d="M58.59 33.798a29.711 29.711 0 01-7.537 9.72l2.127 2.127a32.717 32.717 0 008.11-10.54c.95-1.96.95-4.25 0-6.21C56.023 18.01 44.89 10.5 32 10.5c-4.15 0-8.118.779-11.767 2.198l2.342 2.341A29.44 29.44 0 0132 13.5c11.693 0 21.803 6.811 26.59 16.702.55 1.135.55 2.46 0 3.596z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeOffRegular)
