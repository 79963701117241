import { IArticle } from '../models/Article'

export class ArticleAdapter {
  static transform(data: any): IArticle {
    return {
      id: data.id,
      key: data.key,
      file: data.file?.url,
      markup: data.markup,
    }
  }
}
