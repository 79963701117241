import React, { ComponentProps, memo } from 'react'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'

/**
 * @prop [label] Лейбл.
 * @prop [id] Идентификатор.
 */
interface IProps extends ComponentProps<'input'> {
  label?: string
  id?: string
}

/** Компонент радио кнопки */
const RadioButton: React.FC<IProps> = ({ className, label, id, ...rest }) => {
  const classNames = cx(styles.radioContainer, className)
  return (
    <label htmlFor={id} className={classNames}>
      <input type="radio" id={id} {...rest} />
      <div className={styles.radioText}>{label}</div>
    </label>
  )
}
export default memo(RadioButton)
