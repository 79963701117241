import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowADownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.25 12.068V2.715a.75.75 0 111.5 0v9.353l3.642-3.642a.75.75 0 011.06 1.061l-3.927 3.928a2.156 2.156 0 01-3.05 0L2.548 9.487a.75.75 0 111.06-1.06l3.642 3.641z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.25 16.468V2.5a.75.75 0 011.5 0v13.968l.046-.043 5.236-5.237a.75.75 0 111.06 1.061l-5.236 5.237a2.625 2.625 0 01-3.712 0l-5.237-5.237a.75.75 0 111.06-1.06l5.237 5.236c.015.015.03.03.046.043z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.125 19.816V3a.875.875 0 011.75 0v16.816c.034-.027.066-.057.097-.088l6.284-6.284a.875.875 0 111.238 1.237l-6.284 6.284a3.125 3.125 0 01-4.42 0l-6.284-6.284a.875.875 0 111.238-1.237l6.284 6.284c.031.031.063.06.097.088z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15 26.74V4a1 1 0 112 0v22.74c.148-.086.288-.192.414-.318l8.379-8.38a1 1 0 011.414 1.415l-8.379 8.379a4 4 0 01-5.656 0l-8.38-8.379a1 1 0 111.415-1.414l8.379 8.379c.127.126.266.232.414.318z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M30.5 54.258V8a1.5 1.5 0 013 0v46.258a4.483 4.483 0 001.682-1.061l16.757-16.758a1.5 1.5 0 012.122 2.122L37.303 55.318a7.5 7.5 0 01-10.606 0L9.939 38.561a1.5 1.5 0 112.122-2.122l16.757 16.758a4.483 4.483 0 001.682 1.061z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowADownRegular)
