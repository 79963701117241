import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMathSumLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.285 3.464C2.73 2.934 3.105 2 3.872 2H12.5a.5.5 0 010 1H4.246l4.585 4.386a.85.85 0 010 1.228L4.246 13H12.5a.5.5 0 010 1H3.872c-.766 0-1.141-.934-.587-1.464L8.027 8 3.285 3.464z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3.883 5.23c-.93-.734-.41-2.23.775-2.23H15.5a.5.5 0 010 1H4.658a.25.25 0 00-.155.446l5.785 4.573c.633.5.633 1.461 0 1.962l-5.785 4.573a.25.25 0 00.155.446H15.5a.5.5 0 010 1H4.658c-1.185 0-1.705-1.496-.775-2.23l5.785-4.574a.25.25 0 000-.392L3.883 5.23z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathSumLight)
