import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowALeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.574 12.392a.75.75 0 11-1.06 1.06L2.585 9.525a2.156 2.156 0 010-3.05l3.927-3.927a.75.75 0 111.06 1.06L3.934 7.25h9.352a.75.75 0 010 1.5H3.932l3.642 3.642z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.812 16.032a.75.75 0 11-1.06 1.06l-5.238-5.236a2.625 2.625 0 010-3.712l5.237-5.237a.75.75 0 111.06 1.06L3.576 9.205c-.015.015-.03.03-.043.046H17.5a.75.75 0 010 1.5H3.532l.043.045 5.237 5.237z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.556 19.256a.875.875 0 11-1.237 1.238L3.035 14.21a3.125 3.125 0 010-4.42l6.284-6.284a.875.875 0 011.237 1.238l-6.284 6.284c-.031.031-.06.064-.088.097H21a.875.875 0 110 1.75H4.184c.028.034.057.066.088.097l6.284 6.284z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.957 25.793a1 1 0 11-1.414 1.414l-8.379-8.379a4 4 0 010-5.656l8.379-8.38a1 1 0 111.414 1.415l-8.379 8.379A2.003 2.003 0 005.26 15H28a1 1 0 110 2H5.26c.086.148.192.288.318.414l8.38 8.379z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M27.56 51.94a1.5 1.5 0 11-2.12 2.12L8.681 37.304a7.5 7.5 0 010-10.606L25.44 9.939a1.5 1.5 0 112.122 2.122L10.803 28.818A4.481 4.481 0 009.742 30.5H56a1.5 1.5 0 110 3H9.742c.216.614.57 1.19 1.061 1.682l16.758 16.757z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowALeftRegular)
