import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCollapseCVClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.202 6.505a1.7 1.7 0 01-2.404 0L3.646 3.354a.5.5 0 11.708-.708l3.151 3.152a.7.7 0 00.99 0l3.151-3.152a.5.5 0 01.708.708L9.202 6.505zM9.202 9.495a1.7 1.7 0 00-2.404 0l-3.152 3.151a.5.5 0 00.708.708l3.151-3.152a.7.7 0 01.99 0l3.151 3.152a.5.5 0 00.708-.708L9.202 9.495z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M11.414 8.293a2 2 0 01-2.828 0l-4.44-4.44a.5.5 0 11.708-.707l4.439 4.44a1 1 0 001.414 0l4.44-4.44a.5.5 0 01.707.708l-4.44 4.439zM11.414 11.707a2 2 0 00-2.828 0l-4.44 4.44a.5.5 0 00.708.707l4.439-4.44a1 1 0 011.414 0l4.44 4.44a.5.5 0 00.707-.708l-4.44-4.439z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseCVClosedLight)
