import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCommentRect2TextRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.5 7a.5.5 0 000 1h3a.5.5 0 000-1h-3zM3 5.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 1A2.75 2.75 0 000 3.75v4.5c0 1.348.97 2.47 2.25 2.705v1.48a1.25 1.25 0 001.97 1.021L7.7 11h2.55A2.75 2.75 0 0013 8.25v-4.5A2.75 2.75 0 0010.25 1h-7.5zM1.5 3.75c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v4.5c0 .69-.56 1.25-1.25 1.25H7.462a.75.75 0 00-.432.137l-3.28 2.316V10.25A.75.75 0 003 9.5h-.25c-.69 0-1.25-.56-1.25-1.25v-4.5z"
          fill="currentColor"
        />
        <path
          d="M14.97 3.146a.75.75 0 00-.94 1.17c.288.23.47.581.47.976v4.958c0 .69-.56 1.25-1.25 1.25H13a.75.75 0 00-.75.75v1.703l-3.28-2.316a.75.75 0 10-.865 1.226l3.674 2.593a1.25 1.25 0 001.97-1.021v-1.48A2.75 2.75 0 0016 10.25V5.292c0-.869-.403-1.644-1.03-2.146z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 8.5A.75.75 0 005 10h4.5a.75.75 0 000-1.5H5zM4.25 6.25A.75.75 0 015 5.5h7A.75.75 0 0112 7H5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.249 2.001a3.25 3.25 0 00-3.25 3.25v5.498a3.25 3.25 0 002.251 3.093v2.906c0 1.04 1.196 1.625 2.017.986L10.07 14h2.678a3.25 3.25 0 003.25-3.25V5.25a3.25 3.25 0 00-3.25-3.25h-8.5zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h8.5c.966 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H9.813a.75.75 0 00-.46.158l-4.603 3.58v-3a.75.75 0 00-.676-.747 1.75 1.75 0 01-1.575-1.741V5.25z"
          fill="currentColor"
        />
        <path
          d="M18.375 5.436a.75.75 0 10-.75 1.299c.525.303.876.87.876 1.516v5.498c0 .831-.73 1.623-1.615 1.758a.75.75 0 00-.636.742v1.987l-3.287-2.578a.75.75 0 00-.463-.16h-1.74a.75.75 0 000 1.5h1.481l3.49 2.736.002.002c.82.639 2.017.054 2.017-.986v-1.925C18.992 16.37 20 15.18 20 13.75V8.25a3.249 3.249 0 00-1.626-2.815z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.25 10.5a.75.75 0 000 1.5h5.25a.75.75 0 000-1.5H5.25zM4.5 8.25a.75.75 0 01.75-.75H15A.75.75 0 0115 9H5.25a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.12 2A4.125 4.125 0 00.995 6.125v6.75a4.126 4.126 0 002.97 3.961v2.707c0 1.22 1.38 1.93 2.373 1.22L11.606 17h4.265a4.125 4.125 0 004.125-4.125v-6.75A4.125 4.125 0 0015.871 2H5.121zM2.495 6.125A2.625 2.625 0 015.12 3.5h10.751a2.625 2.625 0 012.625 2.625v6.75a2.625 2.625 0 01-2.625 2.625h-4.505a.75.75 0 00-.436.14l-5.464 3.903v-3.317a.75.75 0 00-.661-.745 2.626 2.626 0 01-2.31-2.606v-6.75z"
          fill="currentColor"
        />
        <path
          d="M22.468 5.952a.75.75 0 00-.945 1.164c.62.503.925 1.364.925 2.02v6.75c0 1.35-1.02 2.464-2.332 2.61a.75.75 0 00-.668.745v2.313l-4.064-2.903a.75.75 0 00-.436-.14h-3.191a.75.75 0 000 1.5h2.951l3.869 2.763c.992.71 2.371 0 2.371-1.22v-1.698c1.732-.49 3-2.081 3-3.97v-6.75c0-1.012-.442-2.342-1.48-3.184z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7 14a1 1 0 100 2h7a1 1 0 100-2H7zM6 11a1 1 0 011-1h13a1 1 0 110 2H7a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 3A5.5 5.5 0 001 8.5v9a5.502 5.502 0 004 5.293v3.264c0 1.627 1.839 2.573 3.162 1.627L14.72 23h5.78a5.5 5.5 0 005.5-5.5v-9A5.5 5.5 0 0020.5 3h-14zM3 8.5A3.5 3.5 0 016.5 5h14A3.5 3.5 0 0124 8.5v9a3.5 3.5 0 01-3.5 3.5h-6.1a1 1 0 00-.581.186L7 26.056v-4.084a1 1 0 00-.89-.993A3.5 3.5 0 013 17.5v-9z"
          fill="currentColor"
        />
        <path
          d="M28.958 8.223A1 1 0 0027.7 9.777 3.491 3.491 0 0129 12.5v9a3.5 3.5 0 01-3.11 3.479 1 1 0 00-.89.994v3.084l-5.419-3.87A1 1 0 0019 25h-3.5a1 1 0 100 2h3.18l5.157 3.684c1.324.946 3.163 0 3.163-1.627v-2.264a5.502 5.502 0 004-5.293v-9c0-1.728-.798-3.27-2.042-4.277z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13 29.5a1.5 1.5 0 011.5-1.5h14a1.5 1.5 0 010 3h-14a1.5 1.5 0 01-1.5-1.5zM14.5 21a1.5 1.5 0 000 3h26a1.5 1.5 0 000-3h-26z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 7A9.5 9.5 0 003 16.5v19c0 4.736 3.466 8.663 8 9.382v7.892c0 2.724 3.08 4.31 5.297 2.725L30.997 45H42.5a9.5 9.5 0 009.5-9.5v-19A9.5 9.5 0 0042.5 7h-30zM6 16.5a6.5 6.5 0 016.5-6.5h30a6.5 6.5 0 016.5 6.5v19a6.5 6.5 0 01-6.5 6.5H30.516a1.5 1.5 0 00-.872.28l-15.09 10.778a.35.35 0 01-.554-.285V43.5a1.5 1.5 0 00-1.5-1.5A6.5 6.5 0 016 35.5v-19z"
        fill="currentColor"
      />
      <path
        d="M57.251 15.272a1.5 1.5 0 10-1.502 2.597A6.497 6.497 0 0159 23.5v19a6.5 6.5 0 01-6.5 6.5h-1a1.5 1.5 0 00-1.5 1.5v6.274a.35.35 0 01-.553.284l-10.89-7.779a1.5 1.5 0 00-.873-.279h-7.187a1.5 1.5 0 100 3h6.707l10.499 7.5C49.92 61.083 53 59.498 53 56.774v-4.787c5.014-.26 9-4.408 9-9.487v-19a9.497 9.497 0 00-4.749-8.228z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRect2TextRegular)
