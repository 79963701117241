import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFolderPlusRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.75 6.5a.75.75 0 00-1.5 0v1.25H6a.75.75 0 000 1.5h1.25v1.25a.75.75 0 001.5 0V9.25H10a.75.75 0 000-1.5H8.75V6.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1.25A1.75 1.75 0 001.25 3v8A2.75 2.75 0 004 13.75h8A2.75 2.75 0 0014.75 11V6A2.75 2.75 0 0012 3.25H8.464a.25.25 0 01-.202-.103l-.856-1.176a1.75 1.75 0 00-1.415-.721H3zM2.75 3A.25.25 0 013 2.75h2.99a.25.25 0 01.203.103l.855 1.176c.33.453.856.721 1.416.721H12c.69 0 1.25.56 1.25 1.25v5c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25V3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.75 8.75a.75.75 0 00-1.5 0v1.5h-1.5a.75.75 0 000 1.5h1.5v1.5a.75.75 0 001.5 0v-1.5h1.5a.75.75 0 000-1.5h-1.5v-1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.25A1.75 1.75 0 002.25 5v9A2.75 2.75 0 005 16.75h10A2.75 2.75 0 0017.75 14V8A2.75 2.75 0 0015 5.25h-4.536a.25.25 0 01-.202-.103l-.856-1.176a1.75 1.75 0 00-1.415-.721H4zM3.75 5A.25.25 0 014 4.75h3.99a.25.25 0 01.203.103l.855 1.176c.33.453.856.721 1.416.721H15c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.75 9.69a.75.75 0 10-1.5 0v2.56H8.69a.75.75 0 000 1.5h2.56v2.56a.75.75 0 001.5 0v-2.56h2.56a.75.75 0 100-1.5h-2.56V9.69z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.875 3.25A2.625 2.625 0 002.25 5.875V17A3.75 3.75 0 006 20.75h12A3.75 3.75 0 0021.75 17V9A3.75 3.75 0 0018 5.25h-6.375l-.436-.726A2.625 2.625 0 008.94 3.25H4.874zM3.75 5.875c0-.621.504-1.125 1.125-1.125h4.063c.396 0 .762.207.965.546l.435.726a1.5 1.5 0 001.287.728H18A2.25 2.25 0 0120.25 9v8A2.25 2.25 0 0118 19.25H6A2.25 2.25 0 013.75 17V5.875z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17 13a1 1 0 10-2 0v4h-4a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4v-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 3A3.5 3.5 0 002 6.5v17A5.5 5.5 0 007.5 29h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 7h-7.25a1.5 1.5 0 01-1.2-.6l-1.5-2a3.5 3.5 0 00-2.8-1.4H5.5zM4 6.5A1.5 1.5 0 015.5 5h6.25a1.5 1.5 0 011.2.6l1.5 2a3.5 3.5 0 002.8 1.4h7.25a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 23.5v-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M33.5 25.5a1.5 1.5 0 00-3 0V34H22a1.5 1.5 0 000 3h8.5v8.5a1.5 1.5 0 003 0V37H42a1.5 1.5 0 000-3h-8.5v-8.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 8A6.5 6.5 0 006 14.5v32a9.5 9.5 0 009.5 9.5h33a9.5 9.5 0 009.5-9.5v-22a9.5 9.5 0 00-9.5-9.5H32.772a1.5 1.5 0 01-1.248-.668l-2.292-3.438A6.5 6.5 0 0023.824 8H12.5zM9 14.5a3.5 3.5 0 013.5-3.5h11.324a3.5 3.5 0 012.912 1.559l2.292 3.437A4.5 4.5 0 0032.772 18H48.5a6.5 6.5 0 016.5 6.5v22a6.5 6.5 0 01-6.5 6.5h-33A6.5 6.5 0 019 46.5v-32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFolderPlusRegular)
