import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconAttachLinkOffRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.28 1.22a.75.75 0 00-1.06 1.06l12.5 12.5a.75.75 0 101.06-1.06l-1.533-1.534A4.75 4.75 0 0011 3.25H9.75a.75.75 0 000 1.5H11a3.25 3.25 0 011.114 6.304L9.811 8.75h1.439a.75.75 0 000-1.5H8.31l-2.5-2.5h.44a.75.75 0 000-1.5H5c-.22 0-.435.015-.646.044L2.28 1.22zM.25 8c0-1.37.58-2.606 1.51-3.473L2.82 5.59A3.25 3.25 0 005 11.25h1.25a.75.75 0 010 1.5H5A4.75 4.75 0 01.25 8z"
          fill="currentColor"
        />
        <path
          d="M3.75 8a.75.75 0 01.732-.75l1.5 1.5H4.5A.75.75 0 013.75 8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.53 1.47a.75.75 0 00-1.06 1.06l16 16a.75.75 0 101.06-1.06l-2.208-2.208A5.751 5.751 0 0014 4.25h-1.75a.75.75 0 000 1.5H14a4.25 4.25 0 011.152 8.342l-3.341-3.342H14a.75.75 0 000-1.5h-3.69l-3.5-3.5h.94a.75.75 0 000-1.5H6c-.22 0-.438.012-.653.037L2.53 1.47zM2.238 5.652A5.75 5.75 0 006 15.75h1.75a.75.75 0 000-1.5H6a4.25 4.25 0 01-2.698-7.534L2.238 5.652z"
          fill="currentColor"
        />
        <path
          d="M5.836 9.25H5.5a.75.75 0 000 1.5h1.836l-1.5-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.03 1.97a.75.75 0 00-1.06 1.06l19 19a.75.75 0 101.06-1.06l-3.24-3.241A6 6 0 0017 6h-2.75a.75.75 0 000 1.5H17a4.5 4.5 0 01.53 8.97l-3.72-3.72h2.44a.75.75 0 000-1.5h-3.94L8.56 7.5h1.19a.75.75 0 000-1.5H7.06L3.03 1.97zM1 12a5.994 5.994 0 012.625-4.961L4.71 8.125A4.5 4.5 0 007 16.5h2.749a.75.75 0 010 1.5H7a6 6 0 01-6-6z"
          fill="currentColor"
        />
        <path
          d="M7 11.25h.836l1.5 1.5H7a.75.75 0 010-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.707 2.293a1 1 0 00-1.414 1.414l26 26a1 1 0 001.414-1.414l-4.579-4.58A8 8 0 0023 8h-4a1 1 0 100 2.001h4a6 6 0 01.401 11.987L18.414 17H22a1 1 0 100-2h-5.586l-5-5H13a1 1 0 100-2H9.414L3.707 2.293zM4.748 9.223A8 8 0 009 24h4a1 1 0 100-2H9a6 6 0 01-2.789-11.314L4.748 9.223z"
          fill="currentColor"
        />
        <path d="M10.525 15H9a1 1 0 100 2h3.525l-2-2z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8.06 5.94a1.5 1.5 0 10-2.12 2.12l50 50a1.5 1.5 0 002.12-2.12l-8.576-8.577C56.138 45.424 61 39.28 61 32c0-8.837-7.163-16-16-16h-6.5a1.5 1.5 0 000 3H45c7.18 0 13 5.82 13 13 0 6.51-4.784 11.9-11.027 12.851L35.62 33.5H44.5a1.5 1.5 0 000-3H32.621L21.121 19H25.5a1.5 1.5 0 000-3H19c-.287 0-.573.008-.856.023L8.06 5.939zM11.199 18.027C6.307 20.764 3 25.996 3 32c0 8.837 7.163 16 16 16h6.5a1.5 1.5 0 000-3H19c-7.18 0-13-5.82-13-13 0-5.184 3.035-9.66 7.425-11.747l-2.226-2.226z"
        fill="currentColor"
      />
      <path
        d="M23.672 30.5H18.5a1.5 1.5 0 000 3h8.172l-3-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachLinkOffRegular)
