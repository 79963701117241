import axios from 'axios'

import { handleError } from './handleError'

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

Api.interceptors.response.use((next) => Promise.resolve(next), handleError)

export abstract class AbstractRest {
  protected readonly http = Api
}
