import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetCloudCheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.602 7.604a.5.5 0 10-.707-.708L7.012 9.78l-1.41-1.41a.5.5 0 00-.707.707l1.41 1.41a1 1 0 001.414 0l2.883-2.883z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.375 2a4.125 4.125 0 00-4.118 3.888A3.626 3.626 0 004.25 13h7a4.25 4.25 0 00-.082-8.5A4.126 4.126 0 007.375 2zM4.25 6.125a3.125 3.125 0 016.1-.96.5.5 0 00.515.345 3.25 3.25 0 11.34 6.49H4.25a2.625 2.625 0 01-.418-5.217.5.5 0 00.421-.516 3.18 3.18 0 01-.003-.142z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.579 8.899a.5.5 0 00-.708-.707l-3.835 3.835a.5.5 0 01-.707 0l-1.574-1.573a.5.5 0 00-.707.707l1.573 1.574a1.5 1.5 0 002.122 0l3.835-3.836z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.125 2.5a5.126 5.126 0 00-5.08 4.444A4.627 4.627 0 005.25 16H14a5.25 5.25 0 10-.208-10.496A5.126 5.126 0 009.125 2.5zM5.008 7.371a4.125 4.125 0 017.99-1.169.5.5 0 00.525.324A4.25 4.25 0 1114 15H5.293a3.625 3.625 0 01-.68-7.17.5.5 0 00.395-.459z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudCheckLight)
