import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import {
  IParamsTransaction,
  IRegisterTransaction,
} from '../models/RegisterTransaction/RegisterTransaction'
import { ITransaction } from '../models/Transaction'
import { TransactionRest } from '../services/TransactionRest'

@singleton()
export class TransactionStore {
  private transactionRest = container.resolve(TransactionRest)

  transaction?: ITransaction = undefined
  transactions: ITransaction[] = []

  isFetchingTransaction = true
  isFetchingTransactions = true

  constructor() {
    makeAutoObservable<TransactionStore, 'transactionRest'>(this, {
      transactionRest: false,
    })
  }

  async createTransaction(data: IRegisterTransaction) {
    await this.transactionRest.createTransaction(data)
  }

  async editTransaction(data: IParamsTransaction) {
    await this.transactionRest.editTransaction({
      ...data,
      id: this.transaction.id,
    })
  }

  async getTransaction(id: string) {
    this.isFetchingTransaction = true

    try {
      const data = await this.transactionRest.getTransaction(id)
      this.setTransaction(data)
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetchingTransaction = false
    }
  }

  async getTransactions() {
    this.isFetchingTransactions = true

    try {
      const data = await this.transactionRest.getTransactions()
      this.setTransactions(data)
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetchingTransactions = false
    }
  }

  resetTransactionFetching() {
    this.isFetchingTransaction = true
  }

  private setTransaction(data?: ITransaction) {
    this.transaction = data
  }

  private setTransactions(data?: ITransaction[]) {
    this.transactions = data
  }

  clearTransaction() {
    this.setTransaction(undefined)
  }
}

export const useTransactionStore = () => container.resolve(TransactionStore)
