import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconXRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.47 3.47a.75.75 0 011.06 0L8 6.94l3.47-3.47a.75.75 0 111.06 1.06L9.06 8l3.47 3.47a.75.75 0 11-1.06 1.06L8 9.06l-3.47 3.47a.75.75 0 01-1.06-1.06L6.94 8 3.47 4.53a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.981 3.981a.75.75 0 011.061 0L10 8.94l4.958-4.958a.75.75 0 011.06 1.061L11.062 10l4.958 4.958a.75.75 0 01-1.06 1.06L10 11.062l-4.958 4.958a.75.75 0 11-1.06-1.06L8.938 10 3.981 5.042a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.47 4.47a.75.75 0 011.06 0L12 10.94l6.47-6.47a.75.75 0 111.06 1.06L13.06 12l6.47 6.47a.75.75 0 11-1.06 1.06L12 13.06l-6.47 6.47a.75.75 0 01-1.06-1.06L10.94 12 4.47 5.53a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.747 5.747a1 1 0 011.414 0L16 14.586l8.84-8.84a1 1 0 111.413 1.415L17.414 16l8.84 8.84a1 1 0 01-1.415 1.413L16 17.414l-8.84 8.84a1 1 0 01-1.413-1.415L14.586 16l-8.84-8.84a1 1 0 010-1.413z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.862 12.862a1.5 1.5 0 012.121 0L32 29.88l17.017-17.017a1.5 1.5 0 112.121 2.121L34.121 32l17.017 17.017a1.5 1.5 0 11-2.121 2.121L32 34.121 14.983 51.138a1.5 1.5 0 11-2.12-2.121L29.878 32 12.862 14.983a1.5 1.5 0 010-2.12z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconXRegular)
