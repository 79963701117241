import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDocCheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.897 8.035a.5.5 0 00-.707-.707L7.662 9.856a.5.5 0 01-.707 0l-.973-.973a.5.5 0 10-.707.707l.972.973a1.5 1.5 0 002.122 0l2.528-2.528z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3.5A2.5 2.5 0 015.5 1h3.015a1.5 1.5 0 011.06.44l2.986 2.984A1.5 1.5 0 0113 5.484V11.5a2.5 2.5 0 01-2.5 2.5h-5A2.5 2.5 0 013 11.5v-8zM5.5 2A1.5 1.5 0 004 3.5v8A1.5 1.5 0 005.5 13h5a1.5 1.5 0 001.5-1.5V6H9.5A1.5 1.5 0 018 4.5V2H5.5zm3.5.278L11.722 5H9.5a.5.5 0 01-.5-.5V2.278z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.51 11.272a.5.5 0 10-.707-.707L9.508 13.86a.5.5 0 01-.707 0l-1.332-1.332a.5.5 0 10-.707.707l1.332 1.332a1.5 1.5 0 002.12 0l3.296-3.295z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15V7.378a1.5 1.5 0 00-.44-1.06l-3.877-3.879A1.5 1.5 0 0010.622 2H7a3 3 0 00-3 3v10a3 3 0 003 3h6a3 3 0 003-3zM7 3a2 2 0 00-2 2v10a2 2 0 002 2h6a2 2 0 002-2V8h-3a2 2 0 01-2-2V3H7zm5 4h2.83L11 3.17V6a1 1 0 001 1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocCheckLight)
