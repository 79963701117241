import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMarketReceiptLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 5a.5.5 0 000 1h4a.5.5 0 000-1H6zM5.5 7.5A.5.5 0 016 7h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5zM7.5 9a.5.5 0 100 1H10a.5.5 0 000-1H7.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.492 14.844a.9.9 0 01-.984 0l-1.566-1.023-1.553 1.006A.9.9 0 013 14.07V1.93a.9.9 0 011.39-.756L5.941 2.18l1.566-1.023a.9.9 0 01.984 0l1.566 1.023 1.553-1.006A.9.9 0 0113 1.93V14.07a.9.9 0 01-1.39.756l-1.552-1.006-1.566 1.023zm-2.058-1.896L8 13.972l1.566-1.024a.9.9 0 01.981-.002l1.453.941V2.113l-1.453.94a.9.9 0 01-.981-.001L8 2.028 6.434 3.052a.9.9 0 01-.981.002L4 2.113v11.774l1.453-.94a.9.9 0 01.981.001z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7.5 7a.5.5 0 000 1h5a.5.5 0 000-1h-5zM7 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zM10 11a.5.5 0 100 1h2.5a.5.5 0 000-1H10z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.515 16.655l-1.989 1.23a1 1 0 01-1.052 0l-1.99-1.23-1.961 1.203A1 1 0 014 17.005V3.041a1 1 0 011.523-.852l1.962 1.203 1.989-1.23a1 1 0 011.052 0l1.99 1.23 1.961-1.203A1 1 0 0116 3.04v13.964a1 1 0 01-1.523.853l-1.962-1.203zm-4.504-.85L10 17.035l1.99-1.23a1 1 0 011.048-.002L15 17.005V3.041l-1.962 1.203a1 1 0 01-1.049-.002L10 3.012l-1.99 1.23a1 1 0 01-1.048.002L5 3.041v13.964l1.962-1.202a1 1 0 011.049.002z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketReceiptLight)
