export enum ETubs {
  PRODUCT_TYPE,
  SERIES,
  PLATFORM,
  CART,
}

export enum EOrderStatus {
  PROCESSING,
  CANCEL,
  SUCCESS,
}
