import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'

import { CompanyStore } from '@modules/company/store/CompanyStore'

@singleton()
export class CompanyContactInformationFormStore {
  private companyStore = container.resolve(CompanyStore)

  form = new FormConstructor({
    phone: {
      label: 'Телефон организации',
      rule: 'required|phone',
      value: this.companyStore.company.phone,
    },
    email: {
      label: 'Email организации',
      rule: 'required|email',
      value: this.companyStore.company.email,
    },
  })

  constructor() {
    makeAutoObservable<CompanyContactInformationFormStore, 'companyStore'>(
      this,
      {
        companyStore: false,
      },
    )
  }

  handleSubmit = async () => {
    await this.form.handleSubmit((fields) =>
      this.companyStore.editCompany({
        phone: fields.phone.value,
        email: fields.email.value,
      }),
    )
  }

  reset = () => {
    this.form.fields.phone.value = this.companyStore.company.phone
    this.form.fields.email.value = this.companyStore.company.email
  }
}

export const useCompanyContactInformationFormStore = () =>
  container.resolve(CompanyContactInformationFormStore)
