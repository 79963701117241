import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechScreenGenericRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2.25A2.75 2.75 0 001.25 5v4A2.75 2.75 0 004 11.75h8A2.75 2.75 0 0014.75 9V5A2.75 2.75 0 0012 2.25H4zM2.75 5c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v4c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25V5z"
          fill="currentColor"
        />
        <path
          d="M3.75 13a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.249 3.001a3.25 3.25 0 00-3.25 3.25v5.498a3.25 3.25 0 003.25 3.25h9.502a3.25 3.25 0 003.25-3.25V6.25a3.25 3.25 0 00-3.25-3.25H5.25zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H5.25a1.75 1.75 0 01-1.75-1.75V6.25z"
          fill="currentColor"
        />
        <path
          d="M5.5 16.25a.75.75 0 100 1.5h9a.75.75 0 000-1.5h-9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3.25A3.75 3.75 0 002.25 7v7A3.75 3.75 0 006 17.75h12A3.75 3.75 0 0021.75 14V7A3.75 3.75 0 0018 3.25H6zM3.75 7A2.25 2.25 0 016 4.75h12A2.25 2.25 0 0120.25 7v7A2.25 2.25 0 0118 16.25H6A2.25 2.25 0 013.75 14V7z"
          fill="currentColor"
        />
        <path
          d="M6 19.25a.75.75 0 000 1.5h12a.75.75 0 000-1.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 3A5.5 5.5 0 002 8.5v11A5.5 5.5 0 007.5 25h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 3h-17zM4 8.5A3.5 3.5 0 017.5 5h17A3.5 3.5 0 0128 8.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 19.5v-11z"
          fill="currentColor"
        />
        <path d="M8 27a1 1 0 100 2h16a1 1 0 100-2H8z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 9C10.701 9 6 13.701 6 19.5v21C6 46.299 10.701 51 16.5 51h31C53.299 51 58 46.299 58 40.5v-21C58 13.701 53.299 9 47.5 9h-31zM9 19.5a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5h-31A7.5 7.5 0 019 40.5v-21z"
        fill="currentColor"
      />
      <path
        d="M16 54a1.5 1.5 0 000 3h32a1.5 1.5 0 000-3H16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechScreenGenericRegular)
