import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import {
  ICart,
  IComponentsTypes,
  IOrder,
  IProductType,
} from '../models/Configurator'

@singleton()
export class ConfiguratorRest extends AbstractRest {
  async getProducts(): Promise<IProductType[]> {
    const { data } = await this.http.get<HTTPResponse<IProductType[]>>(
      'lk/products',
    )

    return data.data
  }

  async sendOrder(cart: ICart): Promise<void> {
    await this.http.post('lk/orders', cart)
  }

  async getOrders(): Promise<IOrder[]> {
    const { data } = await this.http.get<HTTPResponse<IOrder[]>>('lk/orders')

    return data.data
  }

  async getOrder(id: number): Promise<IOrder> {
    const { data } = await this.http.get<IOrder>(`lk/orders/${id}`)

    return data
  }

  async getComponentsTypes(): Promise<IComponentsTypes[]> {
    const { data } = await this.http.get<HTTPResponse<IComponentsTypes[]>>(
      'lk/componentsTypes',
    )

    return data.data
  }
}
