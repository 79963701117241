import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSecurityShieldRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.75 6a.75.75 0 10-1.5 0c0 1.342.15 2.498.697 3.467.558.992 1.469 1.682 2.763 2.225a.75.75 0 00.58-1.383c-1.106-.465-1.695-.972-2.037-1.578-.353-.628-.503-1.468-.503-2.73z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.673.968a1.75 1.75 0 00-1.346 0l-5 2.083a1.741 1.741 0 00-1.073 1.627c.031 2.48.251 4.412 1.14 6.054.897 1.657 2.416 2.912 4.795 4.19a1.715 1.715 0 001.622 0c2.38-1.278 3.898-2.533 4.795-4.19.889-1.642 1.109-3.574 1.14-6.054a1.741 1.741 0 00-1.073-1.627l-5-2.083zm-.77 1.385a.25.25 0 01.193 0l5 2.083c.094.04.151.13.15.223-.03 2.444-.256 4.06-.959 5.359-.695 1.283-1.916 2.363-4.186 3.582a.215.215 0 01-.202 0c-2.27-1.219-3.491-2.299-4.186-3.582-.703-1.3-.928-2.915-.96-5.359a.241.241 0 01.15-.223l5-2.083z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.526 6.931a.75.75 0 10-1.5 0c0 1.93.222 3.538.986 4.869.776 1.351 2.046 2.309 3.91 3.077a.75.75 0 10.572-1.387c-1.681-.692-2.627-1.472-3.181-2.437-.566-.986-.787-2.274-.787-4.122z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.715 1.502a2 2 0 00-1.43 0L3.19 3.836a1.988 1.988 0 00-1.28 1.88c.041 2.998.316 5.348 1.38 7.344 1.073 2.015 2.892 3.568 5.79 5.118a1.95 1.95 0 001.839 0c2.897-1.55 4.716-3.103 5.79-5.118 1.063-1.996 1.338-4.346 1.378-7.344a1.988 1.988 0 00-1.279-1.88l-6.094-2.334zm-.894 1.4a.5.5 0 01.358 0l6.094 2.334c.194.075.318.26.315.46-.04 2.958-.317 4.998-1.202 6.66-.876 1.643-2.403 3.017-5.174 4.5a.451.451 0 01-.424 0c-2.771-1.483-4.298-2.857-5.174-4.5-.885-1.662-1.162-3.702-1.202-6.66a.488.488 0 01.315-.46l6.094-2.333z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 8.318a.75.75 0 10-1.5 0c0 2.385.276 4.342 1.2 5.951.935 1.63 2.472 2.798 4.764 3.742a.75.75 0 10.572-1.387c-2.108-.868-3.321-1.857-4.036-3.101-.726-1.265-1-2.902-1-5.205z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.805 1.943a2.25 2.25 0 00-1.61 0l-7.312 2.8a2.236 2.236 0 00-1.439 2.115c.049 3.593.378 6.382 1.637 8.744 1.268 2.381 3.422 4.227 6.886 6.08.645.345 1.42.345 2.066 0 3.464-1.853 5.618-3.699 6.886-6.08 1.259-2.362 1.588-5.15 1.637-8.744a2.236 2.236 0 00-1.439-2.115l-7.312-2.8zm-1.073 1.4a.75.75 0 01.536 0l7.313 2.801c.29.111.48.39.475.693-.048 3.554-.38 6.033-1.46 8.06-1.071 2.01-2.933 3.677-6.27 5.462a.691.691 0 01-.652 0c-3.337-1.785-5.199-3.453-6.27-5.462-1.08-2.027-1.412-4.506-1.46-8.06a.736.736 0 01.475-.693l7.313-2.8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 11a1 1 0 00-2 0c0 3.176.35 5.778 1.523 7.918 1.19 2.173 3.151 3.736 6.08 5a1 1 0 00.793-1.836c-2.67-1.153-4.21-2.465-5.12-4.125C10.35 16.265 10 14.075 10 11z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.073 2.59a3 3 0 00-2.146 0l-9.75 3.734a2.981 2.981 0 00-1.918 2.82c.065 4.79.504 8.509 2.182 11.659 1.691 3.174 4.563 5.635 9.182 8.106.86.46 1.894.46 2.754 0 4.62-2.47 7.49-4.932 9.182-8.106 1.678-3.15 2.117-6.868 2.182-11.66a2.981 2.981 0 00-1.918-2.819l-9.75-3.734zm-1.43 1.868a1 1 0 01.715 0l9.75 3.734c.387.148.639.52.633.924-.064 4.739-.508 8.044-1.948 10.747-1.427 2.679-3.909 4.902-8.36 7.282a.922.922 0 01-.867 0c-4.45-2.38-6.932-4.603-8.36-7.282-1.44-2.703-1.883-6.008-1.947-10.747a.981.981 0 01.633-.924l9.75-3.734z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19 22.18a1.5 1.5 0 00-3 0c0 6.545.751 11.818 3.195 16.124 2.47 4.35 6.542 7.503 12.728 10.08a1.5 1.5 0 101.154-2.769c-5.814-2.422-9.242-5.214-11.272-8.792C19.748 33.202 19 28.564 19 22.18z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.967 5.647a5.5 5.5 0 00-3.934 0l-19.5 7.468a5.463 5.463 0 00-3.515 5.165c.13 9.57 1.009 16.902 4.306 23.09 3.316 6.226 8.962 11.088 18.157 16.007a5.343 5.343 0 005.038 0c9.195-4.92 14.84-9.781 18.157-16.006 3.297-6.19 4.177-13.522 4.306-23.091a5.463 5.463 0 00-3.515-5.165l-19.5-7.468zm-2.861 2.802a2.5 2.5 0 011.788 0l19.5 7.468a2.463 2.463 0 011.589 2.322c-.129 9.49-1.015 16.204-3.955 21.721-2.92 5.482-7.981 9.988-16.924 14.771a2.342 2.342 0 01-2.208 0c-8.943-4.783-14.004-9.289-16.924-14.77-2.94-5.518-3.826-12.232-3.955-21.722a2.463 2.463 0 011.589-2.322l19.5-7.468z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityShieldRegular)
