import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoWhatsappRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 8a5.25 5.25 0 112.57 4.515l-.269-.16-1.783.458.466-1.736-.176-.277A5.222 5.222 0 012.75 8zM8 1.25a6.75 6.75 0 00-5.882 10.062l-.964 3.593 3.677-.944A6.75 6.75 0 108 1.25zm1.868 7.587c.15.054.954.45 1.117.532l.09.043c.114.055.19.093.223.148.041.068.041.395-.095.777s-.79.73-1.104.778c-.281.042-.638.06-1.03-.065a9.403 9.403 0 01-.932-.345c-1.533-.662-2.568-2.147-2.764-2.428-.014-.02-.024-.034-.03-.04v-.002c-.087-.116-.667-.89-.667-1.69 0-.753.37-1.148.54-1.33l.033-.034a.6.6 0 01.436-.205 6.555 6.555 0 01.35.006c.095 0 .214-.001.33.28l.181.438c.14.342.297.722.324.777.04.081.068.177.014.286-.009.016-.016.032-.023.047-.041.083-.071.145-.14.226l-.084.1c-.057.069-.113.138-.162.186-.082.082-.167.17-.072.334.096.164.424.7.91 1.133.522.466.977.663 1.207.763l.108.048c.163.082.259.069.354-.04.095-.11.409-.478.518-.642.109-.163.218-.136.368-.081z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.616 10.856c.183.067 1.166.55 1.366.65l.109.054c.14.067.234.113.274.18.05.083.05.483-.117.95-.166.467-.965.893-1.349.95a2.74 2.74 0 01-1.259-.079c-.29-.092-.662-.215-1.139-.42-1.873-.81-3.14-2.625-3.379-2.969a2.586 2.586 0 00-.035-.05l-.002-.002c-.106-.14-.814-1.086-.814-2.065 0-.92.452-1.403.66-1.625l.04-.042c.183-.2.4-.25.532-.25a8.017 8.017 0 01.428.007c.117 0 .262-.001.405.343l.22.534c.172.42.363.883.396.95.05.1.084.217.017.35l-.028.057c-.05.102-.087.177-.172.276l-.102.123c-.069.084-.138.168-.198.227-.1.1-.204.208-.087.408.116.2.517.855 1.111 1.385a5.16 5.16 0 001.476.932c.055.024.099.043.132.06.2.1.316.083.433-.05.116-.134.5-.584.632-.784.134-.2.267-.167.45-.1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.334 1.75a8.083 8.083 0 00-7.011 12.11L2.2 18.04l4.28-1.098A8.083 8.083 0 1010.334 1.75zM3.75 9.833a6.583 6.583 0 113.222 5.662l-.268-.16-2.39.614.625-2.327-.175-.277A6.549 6.549 0 013.75 9.833z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.853 13.278c.23.084 1.458.688 1.707.813l.137.067c.174.084.292.141.342.225.063.104.063.604-.146 1.188-.208.584-1.206 1.116-1.686 1.188-.43.064-.975.091-1.573-.1a14.317 14.317 0 01-1.424-.525c-2.342-1.011-3.925-3.28-4.224-3.71a3.067 3.067 0 00-.044-.063l-.002-.002C7.808 12.182 6.922 11 6.922 9.777c0-1.15.565-1.754.825-2.031a6.54 6.54 0 00.05-.053c.228-.25.499-.312.665-.312.167 0 .334.001.48.008l.055.001c.146 0 .327-.002.506.428.069.165.17.41.275.668.216.524.454 1.103.495 1.187.063.125.105.271.021.438l-.035.071a1.418 1.418 0 01-.215.346l-.127.153c-.086.105-.172.21-.247.284-.125.125-.255.26-.11.51.146.25.647 1.068 1.39 1.73.798.713 1.492 1.014 1.844 1.166.069.03.124.054.165.075.25.125.396.104.541-.063.146-.167.625-.73.791-.98.167-.25.333-.208.562-.125z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.477 2 2 6.477 2 12c0 1.827.49 3.542 1.348 5.017l-.997 3.72a.833.833 0 001.012 1.022l3.836-.985A9.959 9.959 0 0012 22c5.523 0 10-4.477 10-10S17.523 2 12 2zM3.667 12a8.333 8.333 0 114.078 7.167.833.833 0 00-.633-.091l-2.782.714.72-2.684a.833.833 0 00-.1-.661A8.29 8.29 0 013.667 12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.084 16c0-6.03 4.888-10.917 10.917-10.917S26.917 9.971 26.917 16 22.03 26.917 16.001 26.917c-2.039 0-3.944-.558-5.575-1.529a1 1 0 00-.76-.109l-3.754.964.973-3.627a1 1 0 00-.12-.793A10.861 10.861 0 015.084 16zM16.001 3.083C8.867 3.083 3.084 8.866 3.084 16c0 2.373.64 4.598 1.759 6.51l-1.306 4.868a1 1 0 001.214 1.228l5.02-1.289a12.863 12.863 0 006.23 1.6c7.133 0 12.916-5.783 12.916-12.917 0-7.134-5.783-12.917-12.916-12.917zm-1.968 6.882a1.32 1.32 0 00-.836-.772 4.657 4.657 0 00-1.398-.205c-.594.01-1.34.165-1.955.731-.892.82-1.645 2.14-1.427 3.96.212 1.77 1.328 3.877 3.819 6.369 2.49 2.49 4.588 3.573 6.336 3.72 1.803.152 3.065-.704 3.78-1.672.677-.915.592-2.046.536-2.467a1.314 1.314 0 00-.644-.96L19.05 16.79a1.375 1.375 0 00-1.67.213l-.46.46c-.918-.588-1.556-1.233-2.217-2.239l.399-.4c.39-.39.51-.977.301-1.49l-1.369-3.369zm-2.203.773c.24-.004.475.033.66.076l1.198 2.95-.44.44a1.366 1.366 0 00-.196 1.688c.913 1.463 1.823 2.394 3.23 3.234.55.327 1.231.218 1.661-.212l.469-.468 2.76 1.623c.005.117.004.252-.014.39-.03.238-.099.442-.213.597-.418.566-1.128 1.06-2.227.968-1.153-.097-2.885-.854-5.245-3.214-2.359-2.358-3.173-4.121-3.319-5.339-.14-1.169.323-1.957.874-2.464.194-.178.464-.263.802-.269z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 9.667C19.666 9.667 9.666 19.666 9.666 32c0 4.383 1.261 8.466 3.44 11.913a1.5 1.5 0 01.18 1.19l-2.168 8.08 8.337-2.14a1.5 1.5 0 011.14.163A22.222 22.222 0 0032 54.333c12.334 0 22.333-9.999 22.333-22.333 0-12.334-9.999-22.333-22.333-22.333zM6.666 32C6.666 18.009 18.01 6.667 32 6.667S57.333 18.009 57.333 32c0 13.991-11.342 25.333-25.333 25.333A25.224 25.224 0 0119.608 54.1L9.377 56.728a1.5 1.5 0 01-1.822-1.842l2.666-9.938A25.223 25.223 0 016.667 32z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.32 18.626a2.39 2.39 0 011.514 1.4l2.739 6.738a2.5 2.5 0 01-.549 2.709l-.942.942c1.42 2.198 2.778 3.57 4.792 4.83l1.06-1.061a2.5 2.5 0 013.036-.388l6.39 3.759c.578.34 1.064.945 1.168 1.735.109.832.266 3.006-1.025 4.753-1.389 1.879-3.834 3.54-7.34 3.244-3.41-.287-7.554-2.407-12.516-7.368-4.963-4.964-7.152-9.128-7.566-12.59-.424-3.548 1.04-6.111 2.775-7.707 1.17-1.077 2.594-1.375 3.746-1.395 1.149-.02 2.165.23 2.719.399zm-1.159 2.79c-.4-.101-.943-.2-1.507-.19-.712.013-1.32.193-1.767.604-1.151 1.06-2.118 2.711-1.827 5.142.3 2.514 1.972 6.089 6.709 10.825 4.738 4.738 8.254 6.3 10.646 6.5 2.297.194 3.796-.848 4.675-2.037.478-.646.544-1.578.494-2.27l-5.802-3.412-1.074 1.074c-.782.782-2.02.98-3.016.385-2.778-1.656-4.57-3.49-6.378-6.385a2.482 2.482 0 01.357-3.069l.997-.997-2.507-6.17z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoWhatsappRegular)
