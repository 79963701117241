import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { Input } from '@ui/Input'

import CardForm from '@lib/components/CardForm'
import PhoneInput from '@lib/components/PhoneInput'
import useCardForm from '@lib/hooks/useCardForm'

import { useUserContactInformationFormStore } from '@modules/user/store/UserContactInformationFormStore'
import { useUserStore } from '@modules/user/store/UserStore'

const ContactInformation = () => {
  const userStore = useUserStore()
  const store = useUserContactInformationFormStore()

  useEffect(() => {
    store.reset()
  }, [])

  const { handleFocus, handleSubmit, handleCancel, isEditing, isSaved } =
    useCardForm(store.handleSubmit, store.reset)

  return (
    <CardForm
      title="Контактная информация"
      direction="column"
      editable={isEditing}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      saved={isSaved}
      disabled={!store.isAvailableSubmit}>
      <PhoneInput
        label="Мобильный телефон"
        name="phone"
        value={store.form.fields.phone.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.phone.error}
        errorText={store.form.fields.phone.errorText}
      />
      <Input
        label="Email"
        value={userStore.user?.email}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        disabled
      />
    </CardForm>
  )
}

export default observer(ContactInformation)
