import React, { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
export type PopupState = 'attention' | 'warning'
export type Size = 48 | 32 | 24 | 22 | 18 | 16 | 14 | 12

interface IProps {
  title: string
  description?: string
  className?: string
  state: PopupState
  isShown?: boolean
  titleSize?: Size
  descriptioSize?: Size
}

const Popup: React.FC<IProps> = ({
  title,
  description,
  state = 'attention',
  className,
  titleSize = 14,
  descriptioSize = 12,
  isShown = false,
}) => {
  const popupClassNames = cx(styles.popup, [styles[state]], className)
  const color = {
    attention: COLORS.GRAY.GANDALF,
  }

  return isShown ? (
    <div className={popupClassNames}>
      {title && (
        <div className={styles.popupTitle}>
          <Typography color={color[state]} size={titleSize}>
            {title}
          </Typography>
        </div>
      )}
      {description && (
        <div className={styles.popupDescription}>
          <Typography color={color[state]} size={descriptioSize}>
            {description}
          </Typography>
        </div>
      )}
    </div>
  ) : null
}

export default memo(Popup)
