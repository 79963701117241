import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureACircleRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.25 8A.75.75 0 015 7.25h4.428L8.02 6.076a.75.75 0 11.96-1.152l2.078 1.732c.84.7.84 1.989 0 2.688L8.98 11.076a.75.75 0 01-.96-1.152L9.428 8.75H5A.75.75 0 014.25 8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 10a.75.75 0 01.75-.75h5.193L9.934 7.576a.75.75 0 11.96-1.152l2.679 2.232c.84.7.84 1.989 0 2.688l-2.678 2.232a.75.75 0 01-.96-1.152l2.008-1.674H6.751A.75.75 0 016 10z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.001 10A7.999 7.999 0 1118 10 7.999 7.999 0 012 10zm8-6.499A6.499 6.499 0 1010 16.5 6.499 6.499 0 0010 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.75 12a.75.75 0 01.75-.75h7.69l-2.72-2.72a.75.75 0 011.06-1.06l3.006 3.005a2.156 2.156 0 010 3.05L13.53 16.53a.75.75 0 11-1.06-1.06l2.72-2.72H7.5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 16a1 1 0 011-1h10.41l-3.308-3.308a1 1 0 111.414-1.414l3.6 3.6a2.99 2.99 0 01.877 2.002 1.012 1.012 0 010 .24 2.99 2.99 0 01-.876 2.001l-3.601 3.601a1 1 0 01-1.414-1.414L20.409 17H10a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16zM16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M17.5 32a1.5 1.5 0 011.5-1.5h23.104l-7.94-7.94a1.5 1.5 0 012.122-2.12l8.378 8.378a4.5 4.5 0 010 6.364l-8.378 8.379a1.5 1.5 0 01-2.122-2.122l7.94-7.939H19a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 32C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26S6 46.36 6 32zM32 9C19.297 9 9 19.297 9 32s10.297 23 23 23 23-10.297 23-23S44.703 9 32 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleRightRegular)
