import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeDRadiusDownRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.998 13.443c0 .835 1.01 1.254 1.6.663l5.223-5.223a1.25 1.25 0 000-1.768L9.598 1.893c-.59-.59-1.6-.173-1.6.663v2.441c-1.027-.012-1.86-.079-2.694-.384-.958-.35-1.963-1.034-3.24-2.425a.601.601 0 00-1.043.373c-.166 3.019.654 5.154 2.094 6.53 1.309 1.25 3.068 1.812 4.883 1.897v2.455zm1-.15V10.5a.5.5 0 00-.5-.5c-1.822 0-3.506-.5-4.693-1.633C2.767 7.375 2.05 5.84 2.002 3.562c1.058 1.03 2 1.64 2.958 1.99C6.11 5.972 7.238 6 8.497 6a.5.5 0 00.501-.5V2.706l5.116 5.117a.25.25 0 010 .353l-5.116 5.116z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.116c0 .933 1.128 1.4 1.787.74l6.841-6.84a1.438 1.438 0 000-2.034l-6.84-6.84c-.66-.66-1.787-.193-1.787.74V6c-1.562 0-2.715-.095-3.854-.541-1.14-.447-2.311-1.265-3.858-2.794-.445-.44-1.248-.176-1.275.497-.152 3.658.818 6.379 2.5 8.19C5.197 13.16 7.535 14 10 14v3.116zm1 0V13.5a.5.5 0 00-.5-.5H10c-2.24 0-4.294-.76-5.753-2.33-1.376-1.48-2.273-3.736-2.248-6.894C3.404 5.11 4.579 5.918 5.781 6.39 7.1 6.907 8.409 7 10 7h.5a.5.5 0 00.5-.5V2.882c0-.043.05-.063.08-.033l6.841 6.84c.171.171.171.448 0 .62l-6.84 6.84c-.03.03-.08.01-.08-.033z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusDownRightLight)
