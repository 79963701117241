import { container, singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

@singleton()
export class UploadsRest extends AbstractRest {
  async uploadFileRequest(file: File, onUploadProgress): Promise<number> {
    const formData = new FormData()
    formData.append('files', file)

    try {
      const response = await this.http.post('/uploads', formData, {
        onUploadProgress,
      })

      return response.data[0].id
    } catch (e) {
      throw new Error(e)
    }
  }
}

export const useUploadsRest = () => container.resolve(UploadsRest)
