import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetDownloadLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 1a.5.5 0 01.5.5v8.373c.056-.032.109-.07.159-.114l3.012-2.635a.5.5 0 11.658.752l-3.012 2.636a2 2 0 01-2.634 0L3.171 7.876a.5.5 0 11.658-.752l3.013 2.635c.05.044.102.082.158.114V1.5a.5.5 0 01.5-.5zM1 13.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.498 2a.5.5 0 01.5.5L10 12.928a1.37 1.37 0 00.404-.246l3.765-3.295a.5.5 0 01.659.753l-3.766 3.295a2.375 2.375 0 01-3.127 0L4.169 10.14a.5.5 0 11.659-.753l3.765 3.295c.123.108.261.19.407.247L8.998 2.5a.5.5 0 01.5-.5zM2.5 16.5A.5.5 0 013 16h13a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetDownloadLight)
