import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureASquareDownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 4.25a.75.75 0 01.75.75v4.428L9.924 8.02a.75.75 0 111.152.96l-1.732 2.078c-.7.84-1.989.84-2.688 0L4.924 8.98a.75.75 0 011.152-.96L7.25 9.428V5A.75.75 0 018 4.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 1.25a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7A3.25 3.25 0 014.5 1.25h7zm1.75 3.25a1.75 1.75 0 00-1.75-1.75h-7A1.75 1.75 0 002.75 4.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 6a.75.75 0 01.75.75v5.193l1.674-2.009a.75.75 0 011.152.96l-2.232 2.679c-.7.84-1.989.84-2.688 0l-2.232-2.679a.75.75 0 111.152-.96l1.674 2.009V6.75A.75.75 0 0110 6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 2.25A3.75 3.75 0 0117.75 6v8A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6A3.75 3.75 0 016 2.25h8zM16.25 6A2.25 2.25 0 0014 3.75H6A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 6.75a.75.75 0 01.75.75v7.69l2.72-2.72a.75.75 0 111.06 1.06l-3.005 3.006a2.156 2.156 0 01-3.05 0L7.47 13.53a.75.75 0 111.06-1.06l2.72 2.72V7.5a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 2.25a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11A4.25 4.25 0 016.5 2.25h11zm2.75 4.25a2.75 2.75 0 00-2.75-2.75h-11A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 9a1 1 0 011 1v10.41l3.308-3.308a1 1 0 011.414 1.414l-3.6 3.6a2.99 2.99 0 01-2.001.877 1.024 1.024 0 01-.242 0 2.99 2.99 0 01-2-.877l-3.601-3.6a1 1 0 111.414-1.414L15 20.409V10a1 1 0 011-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 3A5.5 5.5 0 0129 8.5v15a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15A5.5 5.5 0 018.5 3h15zM27 8.5A3.5 3.5 0 0023.5 5h-15A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5v-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 17.5a1.5 1.5 0 011.5 1.5v23.104l7.94-7.94a1.5 1.5 0 012.12 2.122l-8.378 8.378a4.5 4.5 0 01-6.364 0l-8.379-8.378a1.5 1.5 0 012.122-2.122l7.939 7.94V19a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5 7C52.299 7 57 11.701 57 17.5v29C57 52.299 52.299 57 46.5 57h-29C11.701 57 7 52.299 7 46.5v-29C7 11.701 11.701 7 17.5 7h29zM54 17.5a7.5 7.5 0 00-7.5-7.5h-29a7.5 7.5 0 00-7.5 7.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareDownRegular)
