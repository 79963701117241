import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconHandThumbDownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.862 15.75c.662 0 1.274-.352 1.608-.924l2.905-4.981c.019-.032.037-.064.054-.097l.071.002h2c.69 0 1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25H4.062a2.75 2.75 0 00-2.668 2.083L.238 7.954a2.25 2.25 0 002.183 2.796H5v3.138c0 1.029.833 1.862 1.862 1.862zm3.388-13H4.062a1.25 1.25 0 00-1.213.947L1.694 8.318a.75.75 0 00.727.932H5.75a.75.75 0 01.75.75v3.888a.362.362 0 00.674.183l2.906-4.982a1.25 1.25 0 00.17-.63V2.75zm1.5 0v5.5h1.5v-5.5h-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.55 18.75a2.3 2.3 0 002.05-1.256l2.824-5.548c.032-.064.063-.13.091-.197l.047.001h2.876c.724 0 1.312-.588 1.312-1.313V3.564c0-.725-.588-1.313-1.313-1.313H5.223a3 3 0 00-2.896 2.216l-1.41 5.21a2.438 2.438 0 002.352 3.074H6.25v3.7a2.3 2.3 0 002.3 2.3zm3.7-15H5.222a1.5 1.5 0 00-1.448 1.108l-1.411 5.21a.938.938 0 00.905 1.182H7a.75.75 0 01.75.75v4.45a.8.8 0 001.513.363l2.824-5.547a1.5 1.5 0 00.163-.68V10 3.75zm1.5 0v6.5h2.5v-6.5H14c.001 0 0 0 0 0h-.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.126 23c.824 0 1.574-.477 1.924-1.223l2.67-5.698c.31-.658.971-1.079 1.699-1.079h2.831A1.75 1.75 0 0022 13.25v-8.5A1.75 1.75 0 0020.25 3H7.254A4.125 4.125 0 003.28 6.023L1.568 12.2A3 3 0 004.458 16H9v4.874C9 22.048 9.952 23 11.126 23zm4.892-18.5H7.254c-1.18 0-2.214.787-2.53 1.924l-1.711 6.175A1.5 1.5 0 004.459 14.5h4.729c.724 0 1.312.588 1.312 1.313v5.061a.626.626 0 001.192.266l2.67-5.697A3.374 3.374 0 0116 13.813V4.75c0-.085.006-.168.018-.25zm1.732 0a.25.25 0 00-.25.25v8.75h2.75a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.844 30.333c1.09 0 2.085-.623 2.56-1.605l3.54-7.317A2.5 2.5 0 0123.194 20h3.473A2.333 2.333 0 0029 17.667V6.333A2.333 2.333 0 0026.667 4H22a1 1 0 00-1 1v13.571a4.497 4.497 0 00-1.856 1.969l-3.54 7.317A.844.844 0 0114 27.49v-6.74A1.75 1.75 0 0012.25 19H5.968a2 2 0 01-1.924-2.545l2.24-7.91A3.5 3.5 0 019.653 6H18a1 1 0 100-2H9.652A5.5 5.5 0 004.36 8l-2.24 7.91C1.395 18.463 3.313 21 5.967 21H12v6.49a2.844 2.844 0 002.844 2.843zM26.667 18c.184 0 .333-.15.333-.333V6.333A.333.333 0 0026.667 6H23v12h3.667z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.484 60a5.485 5.485 0 004.952-3.126l7.018-14.739A5.5 5.5 0 0146.42 39h7.08a4.5 4.5 0 004.5-4.5v-21A4.5 4.5 0 0053.5 9h-6a4.5 4.5 0 00-4.5 4.5v23.218a8.497 8.497 0 00-4.255 4.128l-7.018 14.738A2.484 2.484 0 0127 54.516V42a3 3 0 00-3-3H12.353c-2.953 0-5.106-2.797-4.35-5.652l4.174-15.767A7.5 7.5 0 0119.427 12H38.5a1.5 1.5 0 000-3H19.427a10.5 10.5 0 00-10.15 7.813L5.103 32.58C3.843 37.339 7.43 42 12.353 42H24v12.516A5.484 5.484 0 0029.484 60zM53.5 36a1.5 1.5 0 001.5-1.5v-21a1.5 1.5 0 00-1.5-1.5h-6a1.5 1.5 0 00-1.5 1.5V36h7.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHandThumbDownRegular)
