import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import {
  IconInfoCircleLight,
  IconLocationPinRegular,
  IconLogoInstagramRegular,
  IconLogoOkRegular,
  IconLogoTelegramRegular,
  IconLogoVkRegular,
} from '@ui/icons'
import { Modal } from '@ui/Modal'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { getImageUrl } from '@lib/helpers'

import EventItem from '@modules/marketing/components/EventsCalendar/EventItem'
import {
  ICalendarEventsData,
  ISocials,
} from '@modules/marketing/models/Calendar'
import { useDatesFormatter } from '@modules/marketing/services/DatesFormatter'
import { useCalendarStore } from '@modules/marketing/store/CalendarStore'

import styles from './styles.module.scss'

const icons = {
  vk: <IconLogoVkRegular size={24} color={COLORS.GRAY.GANDALF} />,
  telegram: <IconLogoTelegramRegular size={24} color={COLORS.GRAY.GANDALF} />,
  ok: <IconLogoOkRegular size={24} color={COLORS.GRAY.GANDALF} />,
  instagram: <IconLogoInstagramRegular size={24} color={COLORS.GRAY.GANDALF} />,
}

const EventPage = () => {
  const { id } = useParams()
  const calendarStore = useCalendarStore()
  const dates = useDatesFormatter(calendarStore.calendarEventData?.date)
  const [isShowModal, setIsShowModal] = useState(false)
  const [socials, setSocials] = useState<ISocials[]>([])
  const [otherEvents, setOtherEvents] = useState<ICalendarEventsData[]>([])

  useEffect(() => {
    if (calendarStore.calendarEventData) {
      const socials = []

      for (const key in calendarStore.calendarEventData.socials) {
        if (calendarStore.calendarEventData.socials[key] !== '') {
          socials?.push({
            key: {
              link: calendarStore.calendarEventData.socials[key],
              icon: icons[key],
            },
          })
        }
      }
      setSocials(socials)
    }
  }, [calendarStore.calendarEventData])

  useEffect(() => {
    if (calendarStore.calendarEventData) {
      const newArr = calendarStore.calendarEventsData.filter(
        (item) => item.id !== +id,
      )

      setOtherEvents(newArr)
    }
  }, [calendarStore.calendarEventData])

  useEffect(() => {
    if (calendarStore.calendarEventsData?.length === 0) {
      Promise.all([
        calendarStore.getCalendarEvents(),
        calendarStore.getCalendarEvent(+id),
      ])
    } else {
      calendarStore.getCalendarEvent(+id)
    }

    return () => {
      calendarStore.clear()
    }
  }, [])

  const handleClick = useCallback(() => {
    setIsShowModal(!isShowModal)
  }, [isShowModal])

  return (
    <PageWrapper isLoading={calendarStore.isLoading}>
      <div className={styles.pageContainer}>
        {calendarStore.calendarEventData?.file?.url ? (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${calendarStore.calendarEventData?.file?.url})`,
            }}
          />
        ) : (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${getImageUrl(
                'WebinarsDefaultImage.png',
              )})`,
            }}
          />
        )}
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <Typography size={32} color={COLORS.GRAY.GANDALF}>
                {calendarStore.calendarEventData?.title}
              </Typography>
              <div role="button" className={styles.more} onClick={handleClick}>
                <Typography size={14} color={COLORS.GRAY.GANDALF}>
                  Подробнее о мероприятии
                </Typography>
                <IconInfoCircleLight color={COLORS.GRAY.GANDALF} />
              </div>
              {socials?.length > 0 && (
                <div className={styles.social}>
                  {socials?.map(({ key }, i) => (
                    <a
                      key={i}
                      className={styles.link}
                      href={key.link}
                      target="_blank">
                      {key.icon}
                    </a>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.rightSide}>
              <div className={styles.eventDate}>
                <div>
                  <Typography size={12}>Начало</Typography>
                  <Typography className={styles.time} size={22}>
                    {calendarStore.calendarEventData?.timeStart}
                  </Typography>
                </div>
                <div className={styles.date}>
                  <Typography className={styles.dateLabel} size={16}>
                    {dates?.monthIm}
                  </Typography>
                  <Typography size={48} className={styles.day}>
                    {dates?.day}
                  </Typography>
                  <Typography className={styles.dateLabel} size={16}>
                    {dates?.weekDay}
                  </Typography>
                </div>
                <div>
                  <Typography size={12}>Конец</Typography>
                  <Typography className={styles.time} size={22}>
                    {calendarStore.calendarEventData?.timeEnd}
                  </Typography>
                </div>
              </div>
              <div className={styles.address}>
                <IconLocationPinRegular
                  adaptive={false}
                  color={COLORS.BRAND.OCEAN}
                  size={32}
                />
                <Typography className={styles.addressLabel} size={14}>
                  {calendarStore.calendarEventData?.address}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.other}>
        <Typography size={22} weight="medium">
          Другие мероприятия
        </Typography>
        <div className={styles.otherContainer}>
          {otherEvents?.map(
            ({ id, address, file, shortTitle, timeStart, timeEnd, date }) => (
              <EventItem
                id={id}
                address={address}
                image={file?.url}
                key={id}
                shortTitle={shortTitle}
                timeStart={timeStart}
                timeEnd={timeEnd}
                date={date}
              />
            ),
          )}
        </div>
      </div>

      <Modal
        title="Информация по мероприятию"
        titleSize={18}
        open={isShowModal}
        onClose={handleClick}
        className={styles.modal}
        content={
          <div className={styles.modalContent}>
            <Typography size={24}>
              {calendarStore.calendarEventData?.title}
            </Typography>
            <Typography size={16}>
              {calendarStore.calendarEventData?.description
                ? calendarStore.calendarEventData?.description
                : 'Описание отсутствует'}
            </Typography>
          </div>
        }
      />
    </PageWrapper>
  )
}

export default observer(EventPage)
