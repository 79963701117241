import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiFilterSortALowToHighRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.25 4A.75.75 0 013 3.25h2.5a.75.75 0 010 1.5H3A.75.75 0 012.25 4zM2.25 8A.75.75 0 013 7.25h6a.75.75 0 010 1.5H3A.75.75 0 012.25 8zM3 11.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 4.25a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5zM3 10a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 10zM3 15a.75.75 0 01.75-.75h12.5a.75.75 0 010 1.5H3.75A.75.75 0 013 15z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 4.25a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zM3 12a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zM3 19a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 19z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 6a1 1 0 000 2h5a1 1 0 100-2H5zM4 16a1 1 0 011-1h13a1 1 0 110 2H5a1 1 0 01-1-1zM4 25a1 1 0 011-1h22a1 1 0 110 2H5a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.5 11a1.5 1.5 0 000 3h10a1.5 1.5 0 000-3h-10zM8 31.5A1.5 1.5 0 019.5 30h23a1.5 1.5 0 010 3h-23A1.5 1.5 0 018 31.5zM8 50.5A1.5 1.5 0 019.5 49h45a1.5 1.5 0 010 3h-45A1.5 1.5 0 018 50.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiFilterSortALowToHighRegular)
