import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextActionPlusRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.25 4A.75.75 0 013 3.25h10a.75.75 0 010 1.5H3A.75.75 0 012.25 4zM3 7.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zM3 11.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H3zM12.75 10.75a.75.75 0 00-1.5 0v1.5h-1.5a.75.75 0 000 1.5h1.5v1.5a.75.75 0 001.5 0v-1.5h1.5a.75.75 0 000-1.5h-1.5v-1.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 5a.75.75 0 01.75-.75h12.5a.75.75 0 010 1.5H3.75A.75.75 0 013 5zM3.75 8.25a.75.75 0 000 1.5h12.5a.75.75 0 000-1.5H3.75zM3.75 12.25a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zM15.75 12a.75.75 0 00-1.5 0v2.25H12a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H18a.75.75 0 000-1.5h-2.25V12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 4a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 4zM3.75 8.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3.75 13.25a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zM18.75 13.5a.75.75 0 00-1.5 0v3.75H13.5a.75.75 0 000 1.5h3.75v3.75a.75.75 0 001.5 0v-3.75h3.75a.75.75 0 000-1.5h-3.75V13.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 6a1 1 0 000 2h22a1 1 0 100-2H5zM4 14a1 1 0 011-1h22a1 1 0 110 2H5a1 1 0 01-1-1zM25 19a1 1 0 10-2 0v4h-4a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4v-4zM5 20a1 1 0 100 2h9a1 1 0 100-2H5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8 12.5A1.5 1.5 0 019.5 11h45a1.5 1.5 0 010 3h-45A1.5 1.5 0 018 12.5zM9.5 25a1.5 1.5 0 000 3h45a1.5 1.5 0 000-3h-45zM9.5 39a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3h-19zM49 37a1.5 1.5 0 00-3 0v9h-9a1.5 1.5 0 000 3h9v9a1.5 1.5 0 003 0v-9h9a1.5 1.5 0 000-3h-9v-9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextActionPlusRegular)
