import React from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import DateInput from '@lib/components/DateInput'
import PriceInput from '@lib/components/PriceInput'

import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

import styles from './RegisterTransactionStepOne.module.scss'

const RegisterTransactionStepOne = () => {
  const transactionFormStore = useRegisterTransactionFormStore()

  return (
    <form className={styles.form}>
      <CardWrapper fullWidth>
        <Typography size={18} weight="medium" className={styles.typography}>
          Заказчик
        </Typography>
        <Input
          label={transactionFormStore.form.fields.companyName.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.companyName.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="companyName"
          className={styles.input}
          error={transactionFormStore.form.fields.companyName.error}
          errorText={transactionFormStore.form.fields.companyName.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.inn.label}
          type="number"
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.inn.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="inn"
          className={styles.input}
          error={transactionFormStore.form.fields.inn.error}
          errorText={transactionFormStore.form.fields.inn.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.companyUrl.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.companyUrl.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="companyUrl"
          className={styles.input}
          error={transactionFormStore.form.fields.companyUrl.error}
          errorText={transactionFormStore.form.fields.companyUrl.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.address.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.address.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="address"
          className={styles.input}
          error={transactionFormStore.form.fields.address.error}
          errorText={transactionFormStore.form.fields.address.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.index.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.index.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          type="number"
          name="index"
          className={styles.input}
          error={transactionFormStore.form.fields.index.error}
          errorText={transactionFormStore.form.fields.index.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.distributor.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.distributor.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="distributor"
          className={styles.input}
          error={transactionFormStore.form.fields.distributor.error}
          errorText={transactionFormStore.form.fields.distributor.errorText}
        />

        <Typography size={18} weight="medium" className={styles.typography}>
          Конкурс
        </Typography>
        <Input
          label={transactionFormStore.form.fields.name.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.name.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="name"
          className={styles.input}
          error={transactionFormStore.form.fields.name.error}
          errorText={transactionFormStore.form.fields.name.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.url.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.url.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="url"
          className={styles.input}
          error={transactionFormStore.form.fields.url.error}
          errorText={transactionFormStore.form.fields.url.errorText}
        />
        <PriceInput
          label={transactionFormStore.form.fields.amount.label}
          value={transactionFormStore.form.fields.amount.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="amount"
          className={styles.input}
          error={transactionFormStore.form.fields.amount.error}
          errorText={transactionFormStore.form.fields.amount.errorText}
        />
        <DateInput
          label={transactionFormStore.form.fields.date.label}
          value={transactionFormStore.form.fields.date.value}
          onChange={transactionFormStore.form.handleFieldChange2}
          name="date"
          className={styles.input}
          error={transactionFormStore.form.fields.date.error}
          errorText={transactionFormStore.form.fields.date.errorText}
        />

        <div className={styles.footer}>
          <Button
            disabled={!transactionFormStore.isAvailableNextStep}
            onClick={transactionFormStore.nextStep}>
            Далее
          </Button>
        </div>
      </CardWrapper>
    </form>
  )
}

export default observer(RegisterTransactionStepOne)
