import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { IMarketingFundsRequest } from '../models/Marketing'
import { MarketingMaterialAdapter } from '../services/MarketingMaterialAdapter'

@singleton()
export class MarketingRest extends AbstractRest {
  async getMarketingMaterials() {
    const { data } = await this.http.get(`/lk/marketing/materials`)
    return MarketingMaterialAdapter.transform(data)
  }

  async sendMarketingFundsRequest(params: IMarketingFundsRequest) {
    await this.http.post('/lk/marketing/funds', params)
  }
}
