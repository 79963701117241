import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowRoundTimeBackRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.843 2.937a5.503 5.503 0 11-2.784 7.484.75.75 0 10-1.347.66A7.003 7.003 0 102.5 3.665V2.128a.75.75 0 10-1.5 0v4.127c0 .414.336.75.75.75h3.516a.75.75 0 100-1.5H3.065a.75.75 0 00.112-.155 5.503 5.503 0 012.666-2.413z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.569 3.974a6.499 6.499 0 11-3.421 8.852.75.75 0 00-1.35.652 7.999 7.999 0 10.702-8.14V2.756a.75.75 0 00-1.5 0v4.5c0 .415.336.75.75.75H7a.75.75 0 100-1.5H4.521A6.499 6.499 0 017.57 3.974z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.956 4.81A8.25 8.25 0 114.36 15.114a.75.75 0 10-1.39.566 9.75 9.75 0 10.779-8.877V3.246a.75.75 0 00-1.5 0V9c0 .414.336.75.75.75h5.5a.75.75 0 000-1.5H4.652a8.25 8.25 0 013.304-3.44z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.882 6.263a11 11 0 11-4.956 14.156 1 1 0 10-1.831.803A13 13 0 105 9.072V5a1 1 0 10-2 0v6.5A1.5 1.5 0 004.5 13h6a1 1 0 100-2H6.202a11 11 0 014.68-4.737z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M22.62 11a23 23 0 11-10.952 31.753 1.5 1.5 0 10-2.652 1.403A26 26 0 1010 18.144V8a1.5 1.5 0 10-3 0v13.25A2.75 2.75 0 009.75 24H22.5a1.5 1.5 0 000-3H11.801a23 23 0 0110.818-10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowRoundTimeBackRegular)
