import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'

import { UserStore } from './UserStore'

@singleton()
export class UserContactInformationFormStore {
  private userStore = container.resolve(UserStore)

  form = new FormConstructor({
    phone: {
      rule: 'required|phone',
      value: this.userStore.user.phone,
    },
  })

  constructor() {
    makeAutoObservable<UserContactInformationFormStore, 'userStore'>(this, {
      userStore: false,
    })
  }

  handleSubmit = async () => {
    await this.form.handleSubmit((fields) =>
      this.userStore.updateUser({
        phone: fields.phone.value,
      }),
    )
  }

  get isAvailableSubmit() {
    return !!(!this.form.fields.phone.error && this.form.fields.phone.value)
  }

  reset = () => {
    this.form.fields.phone.value = this.userStore.user.phone
  }
}

export const useUserContactInformationFormStore = () =>
  container.resolve(UserContactInformationFormStore)
