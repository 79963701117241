import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconEyeClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.7 6.387a.75.75 0 10-1.401-.535A6.745 6.745 0 018 10.197a6.745 6.745 0 01-6.299-4.345.75.75 0 00-1.401.535c.31.812.743 1.562 1.278 2.228L.454 9.809a.75.75 0 101.092 1.029l1.075-1.142a8.235 8.235 0 002.451 1.465l-.566 1.385a.75.75 0 101.388.568l.634-1.548a8.278 8.278 0 002.944 0l.634 1.548a.75.75 0 001.388-.568l-.566-1.385a8.234 8.234 0 002.451-1.466l1.074 1.142a.75.75 0 001.093-1.027l-1.124-1.195A8.257 8.257 0 0015.7 6.387z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.45 7.917a.75.75 0 00-1.401-.535c-1.24 3.249-4.378 5.552-8.049 5.552-3.672 0-6.81-2.303-8.049-5.552a.75.75 0 00-1.401.535c.4 1.048.968 2.013 1.672 2.86L.704 12.39a.75.75 0 001.092 1.028l1.468-1.559c.96.86 2.086 1.54 3.324 1.984l-.782 1.91a.75.75 0 001.388.569l.85-2.077a10.142 10.142 0 003.912 0l.85 2.077a.75.75 0 001.388-.568l-.782-1.911a10.102 10.102 0 003.324-1.984l1.467 1.56a.75.75 0 001.093-1.028l-1.518-1.614a10.135 10.135 0 001.672-2.86z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23.2 9.447a.75.75 0 00-1.401-.535C20.29 12.866 16.47 15.671 12 15.671s-8.29-2.805-9.799-6.759A.75.75 0 10.8 9.447a12.013 12.013 0 002.067 3.492L.954 14.97a.75.75 0 001.092 1.028l1.862-1.978c1.2 1.1 2.624 1.96 4.196 2.501l-.998 2.44a.75.75 0 101.388.567l1.067-2.607c.787.163 1.603.249 2.439.249.836 0 1.652-.086 2.44-.249l1.066 2.607a.75.75 0 001.388-.568l-.998-2.439a11.974 11.974 0 004.196-2.502L21.952 16a.75.75 0 101.093-1.028l-1.912-2.033A12.011 12.011 0 0023.2 9.447z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M30.934 12.596a1 1 0 10-1.869-.713C27.053 17.155 21.96 20.895 16 20.895c-5.96 0-11.054-3.74-13.065-9.012a1 1 0 10-1.869.713 16.018 16.018 0 002.757 4.656l-2.551 2.71a1 1 0 001.456 1.37l2.483-2.637a15.965 15.965 0 005.594 3.334l-1.33 3.253a1 1 0 001.85.757l1.423-3.476c1.05.217 2.138.332 3.252.332 1.115 0 2.202-.115 3.252-.332l1.422 3.476a1 1 0 001.852-.757l-1.33-3.253a15.965 15.965 0 005.594-3.335l2.48 2.638a1 1 0 001.457-1.37l-2.549-2.711a16.016 16.016 0 002.756-4.655z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M61.4 25.014a1.5 1.5 0 10-2.802-1.07C54.503 34.676 44.135 42.29 32 42.29S9.497 34.676 5.402 23.944a1.5 1.5 0 00-2.802 1.07 31.543 31.543 0 005.718 9.504l-5.41 5.748a1.5 1.5 0 102.184 2.056l5.307-5.638a31.432 31.432 0 0011.876 7.07l-2.863 6.999a1.5 1.5 0 102.776 1.136l3.003-7.34c2.192.485 4.47.74 6.809.74 2.338 0 4.617-.255 6.81-.74l3.002 7.34a1.5 1.5 0 102.776-1.136l-2.863-6.999a31.43 31.43 0 0011.877-7.071l5.303 5.638a1.5 1.5 0 002.185-2.055l-5.406-5.75a31.543 31.543 0 005.717-9.502z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeClosedRegular)
