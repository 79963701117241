import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMathInfinityRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 10.25c.987 0 1.667-.452 2.303-1.19l.033.05.117.176c.204.309.443.67.726 1.042-.775.8-1.772 1.422-3.179 1.422A3.744 3.744 0 01.25 8C.25 5.93 1.918 4.25 4 4.25c2.404 0 3.614 1.816 4.558 3.235l.066.099C9.669 9.152 10.46 10.25 12 10.25c1.25 0 2.25-1.006 2.25-2.25s-1-2.25-2.25-2.25c-.988 0-1.667.452-2.303 1.19l-.033-.05-.117-.176c-.204-.309-.443-.67-.726-1.042.775-.8 1.772-1.422 3.179-1.422 2.082 0 3.75 1.68 3.75 3.75 0 2.07-1.668 3.75-3.75 3.75-2.404 0-3.614-1.816-4.558-3.235l-.066-.099C6.331 6.848 5.54 5.75 4 5.75A2.244 2.244 0 001.75 8c0 1.244 1 2.25 2.25 2.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.315 11.075c-.788.977-1.624 1.604-2.886 1.604a2.673 2.673 0 01-2.68-2.68 2.672 2.672 0 012.68-2.679c1.826 0 2.76 1.313 3.949 3.096l.067.102c1.086 1.63 2.439 3.661 5.13 3.661a4.173 4.173 0 004.18-4.18 4.173 4.173 0 00-4.18-4.18c-1.7 0-2.866.811-3.768 1.82.278.375.52.74.733 1.06.05.078.1.152.148.224.788-.976 1.624-1.604 2.886-1.604 1.49 0 2.68 1.199 2.68 2.68 0 1.482-1.19 2.68-2.68 2.68-1.825 0-2.76-1.312-3.948-3.095l-.068-.102C9.472 7.852 8.119 5.82 5.428 5.82A4.172 4.172 0 001.25 10a4.173 4.173 0 004.18 4.179c1.7 0 2.865-.811 3.767-1.82-.278-.375-.52-.74-.733-1.06l-.148-.223z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.005 13.513c-1.03 1.275-2.157 2.131-3.862 2.131A3.635 3.635 0 012.499 12a3.634 3.634 0 013.644-3.643c2.469 0 3.725 1.795 5.235 4.06l.071.107c1.405 2.108 3.079 4.62 6.411 4.62A5.135 5.135 0 0023.004 12a5.135 5.135 0 00-5.144-5.144c-2.143 0-3.6 1.04-4.741 2.342.332.46.622.901.88 1.29 1.03-1.276 2.157-2.132 3.861-2.132A3.635 3.635 0 0121.504 12a3.635 3.635 0 01-3.644 3.645c-2.468 0-3.724-1.795-5.234-4.06l-.072-.108c-1.404-2.108-3.078-4.62-6.411-4.62A5.134 5.134 0 00.999 12a5.135 5.135 0 005.144 5.144c2.144 0 3.601-1.04 4.742-2.342-.332-.46-.622-.901-.88-1.29z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 11c-2.779 0-5 2.236-5 5s2.221 5 5 5c2.041 0 3.475-.896 4.743-2.277a1 1 0 111.473 1.353C12.698 21.728 10.767 23 8 23c-3.888 0-7-3.136-7-7s3.112-7 7-7c4.538 0 6.817 3.42 8.736 6.301l.096.144C18.892 18.536 20.614 21 24 21c2.779 0 5-2.236 5-5s-2.221-5-5-5c-2.037 0-3.468.892-4.734 2.267a1 1 0 11-1.471-1.355C19.312 10.265 21.239 9 24 9c3.888 0 7 3.136 7 7s-3.112 7-7 7c-4.538 0-6.817-3.42-8.736-6.301l-.096-.144C13.108 13.464 11.386 11 8 11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.715 21.286A10.688 10.688 0 005.002 31.999c0 5.92 4.76 10.714 10.713 10.714 4.94 0 8.223-2.487 11.145-6.078a1.5 1.5 0 012.327 1.893c-3.208 3.944-7.26 7.185-13.472 7.185A13.688 13.688 0 012.002 31.999c0-7.57 6.097-13.713 13.713-13.713 8.949 0 13.438 6.737 17.381 12.654l.152.227c4.162 6.243 7.815 11.546 15.037 11.546a10.688 10.688 0 0010.713-10.714c0-5.92-4.76-10.713-10.713-10.713-4.919 0-8.194 2.465-11.105 6.029a1.5 1.5 0 11-2.323-1.898c3.201-3.92 7.244-7.131 13.428-7.131a13.688 13.688 0 0113.713 13.713c0 7.57-6.097 13.714-13.713 13.714-8.949 0-13.438-6.737-17.381-12.654l-.152-.227c-4.162-6.244-7.815-11.546-15.037-11.546z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathInfinityRegular)
