import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconHandThumbDownLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.736 16c.658 0 1.259-.371 1.553-.96l2.52-5.04H14a1 1 0 001-1V3a1 1 0 00-1-1H4.562a2.5 2.5 0 00-2.426 1.894L.981 8.514A2 2 0 002.92 11H6v3.264C6 15.223 6.777 16 7.736 16zM11 3H4.562a1.5 1.5 0 00-1.456 1.136L1.951 8.757A1 1 0 002.921 10H6.5a.5.5 0 01.5.5v3.764a.736.736 0 001.394.33L11 9.381V3zm1 0v6h2V3h-2z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.705 19c.682 0 1.299-.407 1.568-1.034L13.83 12h3.107c.587 0 1.063-.476 1.063-1.063V4.064C18 3.476 17.524 3 16.937 3H6.21a2.75 2.75 0 00-2.65 2.014l-1.443 5.197A2.2 2.2 0 004.237 13H8v4.295C8 18.236 8.763 19 9.705 19zm3.297-15H6.21a1.75 1.75 0 00-1.686 1.282L3.08 10.479A1.2 1.2 0 004.237 12H8a1 1 0 011 1v4.295a.705.705 0 001.354.278L13 11.397V4.062c0-.02 0-.041.002-.062zm1.06 0a.063.063 0 00-.062.063V11h2.938a.063.063 0 00.062-.063V4.064A.063.063 0 0016.937 4h-2.875z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHandThumbDownLight)
