import { useIsBigDesktop } from '@lib/device'

import { LightIconSize, RegularIconSize } from './types'

function useIconSize<T>(sizes: T[], originalSize: T, adaptive?: boolean) {
  const isBigDesktop = useIsBigDesktop()

  if (adaptive && isBigDesktop) {
    const sizeIdx = sizes.findIndex((size) => size === originalSize)
    if (sizeIdx !== -1 && sizeIdx !== sizes.length - 1) {
      return sizes[sizeIdx + 1]
    }
  }
  return originalSize
}

const LIGHT_ICON_SIZES: LightIconSize[] = [16, 20]
export const useLightIconSize = (
  originalSize: LightIconSize,
  adaptive?: boolean,
): LightIconSize => {
  return useIconSize(LIGHT_ICON_SIZES, originalSize, adaptive)
}

const REGULAR_ICON_SIZES: RegularIconSize[] = [16, 20, 24, 32, 64]
export const useRegularIconSize = (
  originalSize: RegularIconSize,
  adaptive?: boolean,
): RegularIconSize => {
  return useIconSize(REGULAR_ICON_SIZES, originalSize, adaptive)
}
