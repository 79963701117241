import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDevBugLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3.5A2.5 2.5 0 017.5 1h1A2.5 2.5 0 0111 3.5v.768c.304.175.557.428.732.732H13a1 1 0 001-1v-.5a.5.5 0 011 0V4a2 2 0 01-2 2h-1v2h2.5a.5.5 0 010 1H12v1c0 .345-.044.68-.126 1H13a2 2 0 012 2v1.5a.5.5 0 01-1 0V13a1 1 0 00-1-1h-1.5l-.034-.001A3.998 3.998 0 018 14a3.998 3.998 0 01-3.466-2.001A.491.491 0 014.5 12H3a1 1 0 00-1 1v1.5a.5.5 0 01-1 0V13a2 2 0 012-2h1.126A4.007 4.007 0 014 10V9H1.5a.5.5 0 010-1H4V6H3a2 2 0 01-2-2v-.5a.5.5 0 011 0V4a1 1 0 001 1h1.268A2.01 2.01 0 015 4.268V3.5zM11 6v4a3 3 0 11-6 0V6a1 1 0 011-1h1.5v6a.5.5 0 001 0V5H10a1 1 0 011 1zm-1-2v-.5A1.5 1.5 0 008.5 2h-1A1.5 1.5 0 006 3.5V4h4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.5A2.5 2.5 0 019.5 2h1A2.5 2.5 0 0113 4.5v.53c.643.101 1.2.461 1.562.97h1.063C16.385 6 17 5.384 17 4.625V3.5a.5.5 0 011 0v1.125A2.375 2.375 0 0115.625 7h-.654c.019.122.029.247.029.375V9h3a.5.5 0 010 1h-3v2h.625A2.375 2.375 0 0118 14.375V16.5a.5.5 0 11-1 0v-2.125c0-.76-.616-1.375-1.375-1.375H14.9a5.002 5.002 0 01-9.8 0h-.725C3.615 13 3 13.616 3 14.375V16.5a.5.5 0 01-1 0v-2.125A2.375 2.375 0 014.375 12H5v-2H2a.5.5 0 010-1h3V7.375c0-.128.01-.253.03-.375h-.655A2.375 2.375 0 012 4.625V3.5a.5.5 0 011 0v1.125C3 5.385 3.616 6 4.375 6h1.063A2.375 2.375 0 017 5.03V4.5zm7 2.875V12a4 4 0 01-8 0V7.375C6 6.615 6.616 6 7.375 6H9.5v8a.5.5 0 001 0V6h2.125C13.385 6 14 6.616 14 7.375zM12 5v-.5A1.5 1.5 0 0010.5 3h-1A1.5 1.5 0 008 4.5V5h4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDevBugLight)
