import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowAUpLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 10.5a.5.5 0 001 0V4.707l8.146 8.147a.5.5 0 00.708-.708L4.707 4H10.5a.5.5 0 000-1h-6A1.5 1.5 0 003 4.5v6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.5 3a.5.5 0 010 1H5c-.09 0-.176.012-.26.034l11.614 11.612a.5.5 0 01-.708.708L4.034 4.74C4.012 4.824 4 4.911 4 5v8.5a.5.5 0 01-1 0V5a2 2 0 012-2h8.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpLeftLight)
