import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFlagBRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 10.462c1.472-.676 2.912-.61 4.39.196 2.058 1.123 4.162 1.123 6.219 0a.75.75 0 00.39-.658V2a.75.75 0 00-1.108-.658c-1.61.877-3.172.877-4.782 0C6.905.276 4.902.219 2.932 1.19a1.23 1.23 0 00-.682 1.107V15a.75.75 0 001.5 0v-4.538zm0-8v6.385c1.7-.592 3.42-.427 5.109.495 1.479.806 2.918.872 4.39.196V3.153c-1.7.592-3.42.427-5.108-.495-1.485-.81-2.924-.87-4.391-.196z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 13.448c1.971-.994 3.901-.926 5.878.203 2.467 1.41 4.988 1.466 7.469.152.415-.22.653-.65.653-1.093V3a.75.75 0 00-1.122-.651c-2.103 1.201-4.153 1.201-6.256 0C8.003.995 5.58.886 3.184 2.054c-.605.295-.934.91-.934 1.526V19a.75.75 0 001.5 0v-5.552zM9.628 3.65c-1.956-1.117-3.856-1.19-5.786-.249-.04.02-.092.078-.092.178v8.222c2.202-.887 4.431-.705 6.622.547 1.982 1.132 3.911 1.194 5.878.203V4.198c-2.202.887-4.431.705-6.622-.547z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 16.451c2.64-1.333 5.24-1.265 7.886.205 3.13 1.739 6.312 1.793 9.453.148.417-.218.661-.648.661-1.098V3.425c0-.775-.82-1.223-1.466-.893-2.647 1.348-5.258 1.291-7.92-.188C9.33.66 6.244.554 3.191 2.054c-.602.295-.941.907-.941 1.534V23a.75.75 0 001.5 0v-6.549zm7.886-12.795C9.017 2.2 6.45 2.124 3.852 3.4c-.05.025-.102.09-.102.188v11.21c2.865-1.222 5.758-1.04 8.614.546 2.65 1.472 5.249 1.536 7.886.205V4.203c-2.869 1.224-5.763 1.036-8.614-.547z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 22.12c3.36-1.577 6.673-1.489 10.037.267 3.823 1.994 7.704 2.145 11.553.422.9-.403 1.41-1.3 1.41-2.218V5.325c0-1.147-1.192-1.811-2.155-1.374-3.298 1.499-6.56 1.396-9.883-.338-3.72-1.94-7.5-2.137-11.255-.552C4.607 3.526 4 4.614 4 5.712V31a1 1 0 102 0v-8.88zM16.037 5.387c-3.215-1.678-6.37-1.826-9.552-.483-.273.115-.485.417-.485.808v14.23c3.647-1.45 7.326-1.226 10.962.671 3.3 1.721 6.539 1.835 9.81.37.12-.052.228-.193.228-.392V6.059c-3.655 1.452-7.334 1.22-10.963-.672z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.999 44.433c7.127-3.373 14.186-3.239 21.319.403 7.668 3.915 15.42 4.204 23.126.825 1.624-.711 2.558-2.327 2.558-3.998V12.95c0-2.283-2.363-3.609-4.297-2.808-6.67 2.761-13.304 2.453-20.023-.978-7.468-3.813-15.018-4.19-22.534-1.077C8.136 8.92 7 10.91 7 12.949l-.001 30.498a1.49 1.49 0 000 .109l-.001 16.942a1.5 1.5 0 103 0V44.433zm21.319-32.597c-6.719-3.43-13.352-3.74-20.022-.978-.764.317-1.296 1.123-1.296 2.09l-.001 28.2c7.547-3.17 15.148-2.831 22.683 1.016 6.893 3.52 13.703 3.756 20.558.75.44-.193.762-.667.762-1.25V12.946l-.004-.004a.152.152 0 00-.085-.038.12.12 0 00-.06.009c-7.517 3.111-15.068 2.735-22.535-1.078z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFlagBRegular)
