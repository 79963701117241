import { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconArrowCLeftLight } from '@ui/icons'
import { Input } from '@ui/Input'
import { TextArea } from '@ui/TextArea'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { FileUploader } from '@lib/components/FileUploader'

import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

import styles from './RegisterTransactionStepTwo.module.scss'

const RegisterTransactionStepTwo = () => {
  const transactionFormStore = useRegisterTransactionFormStore()

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      transactionFormStore.form.handleFieldChange(
        event.target.name,
        event.target.value,
      )
    },
    [],
  )

  const sendRequest = useCallback(async () => {
    await transactionFormStore.handleSubmit()
  }, [])

  const handlePreviousStep = useCallback(() => {
    transactionFormStore.previousStep()
  }, [])

  return (
    <form className={styles.form}>
      <CardWrapper fullWidth>
        <Typography size={18} weight="medium" className={styles.typography}>
          Информация участника конкурса
        </Typography>
        <Input
          label={transactionFormStore.form.fields.customer.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.customer.value}
          onChange={handleFieldChange}
          name="customer"
          className={styles.input}
          error={transactionFormStore.form.fields.customer.error}
          errorText={transactionFormStore.form.fields.customer.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.manager.label}
          placeholder="Не заполнено"
          value={transactionFormStore.form.fields.manager.value}
          onChange={handleFieldChange}
          name="manager"
          className={styles.input}
          error={transactionFormStore.form.fields.manager.error}
          errorText={transactionFormStore.form.fields.manager.errorText}
        />

        <Typography size={18} weight="medium" className={styles.typography}>
          Дополнительная информация
        </Typography>
        <TextArea
          label={transactionFormStore.form.fields.сompetitors.label}
          labelPosition="left"
          placeholder="Не обязательно к заполнению"
          value={transactionFormStore.form.fields.сompetitors.value}
          rows={3}
          className={styles.input}
          resize="vertical"
          onChange={handleFieldChange}
          name="сompetitors"
          error={transactionFormStore.form.fields.сompetitors.error}
          errorText={transactionFormStore.form.fields.сompetitors.errorText}
        />
        <TextArea
          label={transactionFormStore.form.fields.comments.label}
          labelPosition="left"
          placeholder="Не обязательно к заполнению"
          value={transactionFormStore.form.fields.comments.value}
          rows={3}
          className={styles.input}
          resize="vertical"
          onChange={handleFieldChange}
          name="comments"
          error={transactionFormStore.form.fields.comments.error}
          errorText={transactionFormStore.form.fields.comments.errorText}
        />
        <Input
          label={transactionFormStore.form.fields.percent.label}
          placeholder="0"
          type="number"
          className={styles.input}
          value={transactionFormStore.form.fields.percent.value}
          onChange={handleFieldChange}
          rightIcon="%"
          name="percent"
          error={transactionFormStore.form.fields.percent.error}
          errorText={transactionFormStore.form.fields.percent.errorText}
        />
        <Typography size={18} weight="medium" className={styles.typography}>
          Спецификация
        </Typography>
        <div className={styles.footer}>
          <div className={styles.fileWrapper}>
            <Typography size={16} weight="light">
              Загрузить спецификацию (PDF)
            </Typography>
            <FileUploader
              id="file"
              width="330px"
              className={styles.file}
              iconPosition="left"
              label="Выберите файл"
              accept={{
                'text/html': ['.pdf'],
              }}
              onSetFile={transactionFormStore.setFileId}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={handlePreviousStep}
              IconLeft={IconArrowCLeftLight}
              type="link"
              withBorder>
              Назад
            </Button>

            <Button
              disabled={!transactionFormStore.isAvailableSend}
              onClick={sendRequest}>
              Отправить
            </Button>
          </div>
        </div>
      </CardWrapper>
    </form>
  )
}

export default observer(RegisterTransactionStepTwo)
