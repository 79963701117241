import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyTypeCoinsLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.813c-1.617 0-3.106.341-4.209.917-1.079.564-1.916 1.43-1.916 2.52 0 1.092.837 1.958 1.916 2.521 1.103.576 2.592.917 4.21.917 1.616 0 3.105-.341 4.208-.917 1.079-.563 1.916-1.43 1.916-2.52 0-1.091-.837-1.957-1.916-2.52-1.103-.577-2.592-.918-4.209-.918zM2.875 5.251c0-.531.422-1.134 1.38-1.634C5.186 3.13 6.51 2.813 8 2.813c1.49 0 2.813.317 3.746.804.957.5 1.38 1.103 1.38 1.634 0 .53-.423 1.134-1.38 1.634-.933.487-2.256.803-3.746.803-1.49 0-2.813-.316-3.746-.803-.957-.5-1.379-1.103-1.379-1.634z"
          fill="currentColor"
        />
        <path
          d="M2.184 8.293a.5.5 0 01.66.254c.208.471.783.966 1.721 1.347.92.374 2.116.606 3.435.606s2.514-.232 3.435-.606c.938-.38 1.513-.876 1.722-1.347a.5.5 0 01.914.406c-.362.816-1.221 1.446-2.26 1.868-1.057.43-2.383.679-3.811.679s-2.754-.25-3.81-.679c-1.04-.422-1.899-1.052-2.26-1.868a.5.5 0 01.254-.66z"
          fill="currentColor"
        />
        <path
          d="M2.184 11.043a.5.5 0 01.66.254c.208.471.783.966 1.721 1.348.92.373 2.116.605 3.435.605s2.514-.232 3.435-.605c.938-.382 1.513-.877 1.722-1.348a.5.5 0 01.914.406c-.362.816-1.221 1.446-2.26 1.868-1.057.43-2.383.679-3.811.679s-2.754-.25-3.81-.679c-1.04-.422-1.899-1.052-2.26-1.868a.5.5 0 01.254-.66z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.992c-2.115 0-4.054.446-5.483 1.192-1.405.734-2.444 1.835-2.444 3.186s1.04 2.452 2.444 3.186c1.43.746 3.368 1.193 5.483 1.193 2.115 0 4.054-.447 5.483-1.193 1.405-.734 2.444-1.835 2.444-3.186 0-1.35-1.04-2.452-2.444-3.186-1.429-.746-3.368-1.192-5.483-1.192zM3.073 6.37c0-.79.624-1.63 1.907-2.3C6.24 3.414 8.013 2.993 10 2.993c1.987 0 3.761.421 5.02 1.079 1.283.67 1.907 1.508 1.907 2.3 0 .79-.624 1.628-1.907 2.298-1.259.658-3.033 1.08-5.02 1.08-1.987 0-3.76-.422-5.02-1.08C3.697 8 3.073 7.161 3.073 6.37z"
        fill="currentColor"
      />
      <path
        d="M2.385 10.533a.5.5 0 01.66.255c.3.676 1.105 1.352 2.36 1.862 1.237.502 2.837.81 4.595.81 1.758 0 3.358-.308 4.595-.81 1.255-.51 2.06-1.186 2.36-1.862a.5.5 0 11.914.405c-.453 1.023-1.542 1.833-2.898 2.383-1.373.558-3.103.885-4.971.885-1.868 0-3.597-.327-4.971-.885-1.356-.55-2.445-1.36-2.898-2.383a.5.5 0 01.254-.66z"
        fill="currentColor"
      />
      <path
        d="M2.385 14.082a.5.5 0 01.66.254c.3.677 1.105 1.353 2.36 1.862 1.237.503 2.837.812 4.595.812 1.758 0 3.358-.309 4.595-.812 1.255-.51 2.06-1.185 2.36-1.862a.5.5 0 01.914.406c-.453 1.022-1.542 1.832-2.898 2.383-1.373.558-3.103.885-4.971.885-1.868 0-3.597-.327-4.971-.885-1.356-.55-2.445-1.36-2.898-2.383a.5.5 0 01.254-.66z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyTypeCoinsLight)
