import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckA2Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.768 4.924a.75.75 0 00-1.06-1.06l-6.872 6.87a.75.75 0 001.061 1.06l6.871-6.87zM11.799 4.924a.75.75 0 00-1.061-1.06l-6.392 6.39a.837.837 0 01-1.184 0L1.299 8.393a.75.75 0 10-1.06 1.06L2.1 11.316a2.338 2.338 0 003.306 0l6.392-6.391z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.39 5.687a.75.75 0 10-1.06-1.061l-8.542 8.54a1.234 1.234 0 01-1.745 0l-2.71-2.709a.75.75 0 10-1.06 1.06l2.71 2.71a2.734 2.734 0 003.866 0l8.54-8.54zM19.767 5.687a.75.75 0 00-1.06-1.061l-9.093 9.092a.75.75 0 001.06 1.061l9.093-9.092z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18.445 6.718a.75.75 0 10-1.06-1.06L7.134 15.905a1.631 1.631 0 01-2.306 0l-3.493-3.493a.75.75 0 10-1.061 1.06l3.493 3.494a3.131 3.131 0 004.428 0l10.249-10.25zM23.678 6.718a.75.75 0 10-1.06-1.06l-10.911 10.91a.75.75 0 101.06 1.06L23.68 6.719z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M24.936 8.957a1 1 0 10-1.414-1.414L9.857 21.208c-.85.85-2.227.85-3.076 0l-5.074-5.074A1 1 0 00.293 17.55l5.073 5.073a4.175 4.175 0 005.905 0L24.936 8.957zM31.572 8.957a1 1 0 00-1.415-1.414L15.61 22.09a1 1 0 001.414 1.414L31.572 8.957z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M50.518 17.56a1.5 1.5 0 00-2.121-2.12l-27.33 27.33a4.85 4.85 0 01-6.86 0L4.062 32.621a1.5 1.5 0 10-2.122 2.121l10.147 10.148a7.85 7.85 0 0011.102 0l27.33-27.33zM63.518 17.56a1.5 1.5 0 00-2.121-2.12L32.3 44.534a1.5 1.5 0 102.121 2.122L63.518 17.56z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckA2Regular)
