import { useCallback, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Typography } from '@ui/Typography'

import HelperImage from '@modules/marketing/helpers/HelperImage'
import { IWebinarCardProps } from '@modules/marketing/models/Webinars'

import styles from './styles.module.scss'
import VideoPlayer from './VideoPlayer'

const WebinarsCard = ({ id, image, title }: IWebinarCardProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const handleClick = useCallback(() => {
    setIsShowModal(!isShowModal)
  }, [isShowModal])

  return (
    <>
      {isShowModal && (
        <VideoPlayer
          title={title}
          id={id}
          open={isShowModal}
          handleClick={handleClick}
        />
      )}
      <div onClick={handleClick} className={styles.card}>
        <HelperImage
          image={image}
          className={styles.image}
          src="WebinarsDefaultImage.png"
          X2src="WebinarsDefaultImageX2.png"
        />
        <div className={styles.title}>
          <Typography className={styles.text} weight="medium" size={16}>
            {title.length > 60 ? `${title.slice(0, 60)}...` : title}
          </Typography>
        </div>
      </div>
    </>
  )
}

export default observer(WebinarsCard)
