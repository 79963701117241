import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoTwitterRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.325 5.295a3.402 3.402 0 013.391-3.16c.813 0 1.561.287 2.148.766.386-.108.75-.264 1.083-.463a.75.75 0 011.098.876c-.015.045-.03.09-.047.134a.748.748 0 01.313 1.1 6.19 6.19 0 01-1.19 1.32c-.012 3.814-2.926 8.262-8.286 8.262a8.284 8.284 0 01-4.457-1.3.75.75 0 01.259-1.368l.005-.001.018-.004.078-.017a16.01 16.01 0 001.278-.334c.33-.101.667-.218.982-.348a5.937 5.937 0 01-1.134-1.004C1.722 8.439.954 6.322 1.96 3.145a.75.75 0 011.296-.248 6.786 6.786 0 004.069 2.398zM5.806 10.02a.75.75 0 01.245 1.302c-.57.456-1.366.801-2.077 1.049.592.168 1.216.26 1.861.26 4.37 0 6.786-3.612 6.786-6.786 0-.116 0-.213-.005-.307a.75.75 0 01.092-.394.749.749 0 01-.286-.706.749.749 0 01-.32-.202 1.902 1.902 0 00-3.233 1.749.75.75 0 01-.772.904 8.242 8.242 0 01-5.015-2.023c-.317 1.949.267 3.159.915 3.904a4.548 4.548 0 001.809 1.25zm.001 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.59 7.153a3.814 3.814 0 013.81-3.775c.95 0 1.822.349 2.491.927.516-.134 1-.336 1.444-.6a.675.675 0 01.987.789c-.04.123-.086.244-.138.362a.674.674 0 01.479 1.046 7.102 7.102 0 01-1.445 1.58V7.556c0 4.429-3.38 9.594-9.594 9.594-1.901 0-3.677-.559-5.162-1.505a.675.675 0 01.446-1.24 5.612 5.612 0 002.835-.397 3.812 3.812 0 01-1.85-2.206.675.675 0 01.068-.56 3.805 3.805 0 01-1.2-2.775v-.043a.675.675 0 01.381-.608 3.797 3.797 0 01-.357-1.611c0-.696.186-1.35.513-1.916a.675.675 0 011.107-.088A8.24 8.24 0 009.59 7.153zM4.31 9.436A2.467 2.467 0 006.08 10.88a.675.675 0 01.049 1.312c-.144.04-.29.07-.437.093.442.49 1.078.8 1.784.813a.675.675 0 01.404 1.206c-.7.55-1.506.967-2.381 1.219.678.18 1.39.277 2.124.277 5.314 0 8.244-4.396 8.244-8.244 0-.137 0-.256-.005-.373a.675.675 0 01.213-.524.675.675 0 01-.38-.94.675.675 0 01-.5-.214 2.454 2.454 0 00-1.795-.777 2.464 2.464 0 00-2.461 2.46c0 .19.023.38.065.577a.674.674 0 01-.694.813 9.554 9.554 0 01-6.159-2.664 2.468 2.468 0 001.078 2.34.675.675 0 01-.4 1.236 3.865 3.865 0 01-.52-.054z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.55 8.343a4.806 4.806 0 014.803-4.804c1.22 0 2.336.457 3.186 1.21a7.259 7.259 0 001.96-.804.75.75 0 011.098.876c-.07.216-.155.426-.253.627a.75.75 0 01.708 1.163 9.046 9.046 0 01-1.888 2.051v.155c0 5.67-4.327 12.277-12.278 12.277-2.432 0-4.704-.715-6.605-1.927a.75.75 0 01.496-1.376c.279.034.57.05.871.05a7.37 7.37 0 003.097-.678 4.806 4.806 0 01-2.563-2.896.75.75 0 01.134-.702 4.798 4.798 0 01-1.592-3.571v-.055a.75.75 0 01.539-.72 4.786 4.786 0 01-.507-2.148c0-.878.234-1.703.646-2.415a.75.75 0 011.23-.098 10.77 10.77 0 006.919 3.88l-.001-.095zm-7.141 2.74a3.312 3.312 0 002.46 2.15.75.75 0 01.054 1.457c-.251.07-.508.119-.77.145a3.3 3.3 0 002.541 1.267.75.75 0 01.45 1.34 8.892 8.892 0 01-3.464 1.665 10.76 10.76 0 003.206.487c6.95 0 10.778-5.75 10.778-10.777 0-.177 0-.334-.007-.488a.75.75 0 01.31-.643 7.04 7.04 0 00.14-.103.75.75 0 01-.51-1.307l-.145.03a.75.75 0 01-.69-.223 3.294 3.294 0 00-2.41-1.044 3.306 3.306 0 00-3.302 3.304c0 .253.031.508.086.77a.75.75 0 01-.77.903 12.23 12.23 0 01-8.05-3.576 3.312 3.312 0 001.41 3.381.75.75 0 01-.445 1.373 4.856 4.856 0 01-.872-.11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.729 8.515a4.493 4.493 0 00-3.038-1.178 4.515 4.515 0 00-4.509 4.508c0 .352.044.695.115 1.029a12.784 12.784 0 01-7.794-3.161 12.948 12.948 0 01-1.496-1.55 4.508 4.508 0 00-.606 2.315 4.505 4.505 0 002.003 3.705 4.56 4.56 0 01-2.039-.562v.061a4.49 4.49 0 00.853 2.638A4.55 4.55 0 009.08 17.82c.285.124.586.22.897.282a4.393 4.393 0 01-1.186.158c-.29 0-.571-.026-.844-.079a4.477 4.477 0 001.737 2.336 4.49 4.49 0 002.473.793 9.08 9.08 0 01-2.735 1.472c-.9.3-1.863.461-2.863.461-.36 0-.721-.017-1.073-.061a12.893 12.893 0 003.976 1.676c.94.22 1.918.337 2.924.337 8.288 0 12.823-6.873 12.823-12.823l-.001-.235c0-.115-.003-.23-.008-.345a9.22 9.22 0 002.25-2.338 9.136 9.136 0 01-2.593.712 4.503 4.503 0 001.902-2.26c.03-.078.058-.157.084-.236l-.15.087a8.899 8.899 0 01-2.715 1.002 4.561 4.561 0 00-.249-.245zm.76-1.954a6.484 6.484 0 00-3.798-1.224 6.515 6.515 0 00-6.383 5.23 10.842 10.842 0 01-5.752-3.669 2 2 0 00-3.28.263 6.508 6.508 0 00-.876 3.27c0 .713.116 1.4.328 2.041a2 2 0 00-.363 1.149v.06c0 1.632.602 3.125 1.596 4.269-.033.278-.007.564.08.84a6.48 6.48 0 001.391 2.401 7.139 7.139 0 01-.873.053c-.298 0-.573-.014-.825-.046a2 2 0 00-1.322 3.672 14.82 14.82 0 007.974 2.326c9.445 0 14.63-7.722 14.817-14.447.729-.642 1.37-1.379 1.908-2.181a2 2 0 00-.294-2.573 2 2 0 00-2.998-2.042 6.83 6.83 0 01-1.33.608z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.349 22.85a17.935 17.935 0 01-.95.735c.017.387.017.774.017 1.16 0 11.9-9.07 25.646-25.646 25.646a25.607 25.607 0 01-11.654-2.799 25.79 25.79 0 01-2.145-1.226c.703.088 1.424.123 2.145.123a18 18 0 007.35-1.552 18.211 18.211 0 003.847-2.315 8.978 8.978 0 01-3.938-.984 9.05 9.05 0 01-3.59-3.323 8.913 8.913 0 01-.892-1.95 8.859 8.859 0 001.688.158c.535 0 1.055-.045 1.56-.133a8.42 8.42 0 00.813-.184 8.857 8.857 0 01-2.373-.84 9.015 9.015 0 01-2.47-1.898 9.058 9.058 0 01-1.708-2.682 8.961 8.961 0 01-.674-3.421v-.123a9.107 9.107 0 004.078 1.125 9.061 9.061 0 01-2.925-3.22A8.99 8.99 0 0112.8 20.86a8.988 8.988 0 011.213-4.535c.68.833 1.41 1.622 2.186 2.363a25.575 25.575 0 0013.289 6.716c1.016.176 2.052.292 3.105.343a9.958 9.958 0 01-.229-2.056c0-.324.017-.643.05-.958.48-4.526 4.317-8.06 8.968-8.06a8.996 8.996 0 016.574 2.848 17.83 17.83 0 005.73-2.18 9.162 9.162 0 01-.988 2.094 9.026 9.026 0 01-2.984 2.899 18.273 18.273 0 005.185-1.424 18.507 18.507 0 01-3.55 3.94zm5.154-6.475l.035-.106a3 3 0 00-4.389-3.505 14.733 14.733 0 01-3.36 1.467 11.973 11.973 0 00-7.408-2.557c-6.176 0-11.272 4.67-11.943 10.67-5.241-1.047-9.836-3.914-13.102-7.915a3 3 0 00-4.92.394 12.015 12.015 0 00-1.617 6.038c0 1.508.28 2.952.787 4.281a3 3 0 00-.857 2.1v.122c0 3.181 1.24 6.08 3.264 8.232a2.998 2.998 0 00.042 1.68 11.998 11.998 0 003.939 5.717 15.156 15.156 0 01-3.858.496c-.626 0-1.22-.03-1.773-.1a3 3 0 00-1.984 5.507 28.639 28.639 0 0015.41 4.495c18.443 0 28.5-15.232 28.645-28.357a21.518 21.518 0 003.976-4.455 3 3 0 00-.887-4.204z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoTwitterRegular)
