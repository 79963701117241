import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { omit } from 'lodash'
import { observer } from 'mobx-react-lite'

import { Collapse } from '@ui/Collapse'
import Divider from '@ui/Divider'
import { TextArea } from '@ui/TextArea'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import NotFoundPage from '@lib/components/NotFoundPage'
import PageWrapper from '@lib/components/Page/PageWrapper'
import TaggedContainer from '@lib/components/TaggedContainer'
import {
  declinationByNum,
  getCostOfAccessories,
  getCostOfComplactations,
  getCurrencyString,
} from '@lib/helpers'

import { lokalizedOrderStatus } from '../models/Configurator'
import { useConfiguratorStore } from '../store/ConfiguratorStore'

import styles from './ConfiguratorOrderPage.module.scss'

const ConfiguratorOrderPage = () => {
  const { id } = useParams()
  const configuratorStore = useConfiguratorStore()

  useEffect(() => {
    configuratorStore.getOrder(Number(id))
  }, [])

  const tags = useMemo(() => {
    return configuratorStore.order
      ? [
          {
            label: 'Номер заказа (ID)',
            value: id,
          },
          {
            label: 'Дата создания',
            value: new Date(
              configuratorStore.order.created_at,
            ).toLocaleDateString(),
          },
          {
            label: 'Статус',
            value: lokalizedOrderStatus[configuratorStore.order.status],
          },
        ]
      : []
  }, [configuratorStore.order])

  const [total, count] = useMemo(() => {
    const count = configuratorStore.order
      ? configuratorStore.order.complectations.reduce(
          (acc, cur) => acc + cur.count,
          0,
        )
      : 0
    const total = configuratorStore.order
      ? getCostOfComplactations(configuratorStore.order.complectations)
      : 0

    return [total, count]
  }, [configuratorStore.order])

  return (
    <PageWrapper
      title={configuratorStore.order ? 'Детали заказа' : ''}
      isLoading={configuratorStore.isFetching}>
      {configuratorStore.order ? (
        <div className={styles.container}>
          <TaggedContainer tags={tags} />
          <CardWrapper className={styles.details} px={20} py={20}>
            <div className={styles.header}>
              <Typography size={18} weight="medium">
                Ваш заказ
              </Typography>
              <div className={styles.productHeader}>
                <Typography>Товары</Typography>
                <Typography>
                  {count}{' '}
                  {declinationByNum(count, ['товар', 'товара', 'товаров'])}
                </Typography>
              </div>
            </div>

            <div className={styles.complectations}>
              {configuratorStore.order?.complectations.map(
                (complectation, index) => (
                  <div className={styles.complectation} key={complectation.id}>
                    <Divider className={styles.divider} />

                    <div className={styles.complectationHeader}>
                      <Typography
                        size={16}
                        color={COLORS.GRAY.GRAY_2}
                        className={styles.headerItem}>
                        {index + 1}
                      </Typography>

                      <Typography
                        size={16}
                        weight="medium"
                        className={styles.headerItem}>
                        {complectation.components.productType.name}{' '}
                        {complectation.components.productSeries.name}
                      </Typography>

                      <div className={styles.headerItem}>
                        <Typography>Количество, шт.</Typography>
                        <Typography size={16} weight="medium">
                          {complectation.count}
                        </Typography>
                      </div>

                      <div className={styles.headerItem}>
                        <Typography>Цена за 1 шт.</Typography>
                        <Typography size={16} weight="medium">
                          {getCurrencyString(
                            getCostOfAccessories(
                              complectation.components.accessories,
                              1,
                            ),
                          )}
                        </Typography>
                      </div>

                      <div className={styles.headerItem}>
                        <Typography>Стоимость</Typography>
                        <Typography size={16} weight="medium">
                          {getCurrencyString(
                            getCostOfAccessories(
                              complectation.components.accessories,
                              complectation.count,
                            ),
                          )}
                        </Typography>
                      </div>
                    </div>

                    <Collapse
                      title="Спецификация"
                      className={styles.specification}>
                      <div className={styles.specificationWrapper}>
                        <div className={styles.specificationContent}>
                          {Object.values(
                            omit(
                              complectation.components.platforms,
                              'processor',
                            ),
                          ).map((value) => {
                            return (
                              <div key={value.name}>
                                <Typography weight="medium" size={12}>
                                  {value.title}
                                </Typography>
                                <Typography size={12}>{value.name}</Typography>
                              </div>
                            )
                          })}
                          {Object.entries(
                            complectation.components.accessories,
                          ).map(([key, value]) => (
                            <div key={key}>
                              <Typography weight="medium" size={12}>
                                {
                                  configuratorStore.componentTypes.find(
                                    (type) => type.key === value.type,
                                  )?.name
                                }
                              </Typography>
                              <Typography size={12}>
                                {value.fullName}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                ),
              )}
            </div>

            <div className={styles.footer}>
              <div className={styles.comment}>
                <Typography size={18} weight="medium">
                  Комментарий к заказу
                </Typography>
                <TextArea disabled value={configuratorStore.order.comment} />
              </div>

              <div className={styles.total}>
                <Typography size={22} weight="medium">
                  Итоговая сумма
                </Typography>
                <Typography size={22} weight="medium">
                  {getCurrencyString(total)}
                </Typography>
              </div>
            </div>
          </CardWrapper>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </PageWrapper>
  )
}

export default observer(ConfiguratorOrderPage)
