import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { IFile } from '@lib/components/FileUploader/types'
import { EStatusProcess, FormConstructor } from '@lib/form'

import { ITransaction } from '@modules/sales/models/Transaction'

import { TransactionStore } from '../TransactionStore'

@singleton()
export class RegisterTransactionFormStore {
  private transactionStore = container.resolve(TransactionStore)

  fileId = null
  isEditing = false
  step = 1
  status: EStatusProcess = EStatusProcess.IDLE

  form = new FormConstructor({
    companyName: {
      rule: 'required|regex:/^[0-9a-zA-ZА-ЯЁа-яё \'"()-]+$/',
      errorText: 'Введите корректное название компании',
      label: 'Компания',
    },
    inn: {
      rule: 'required|regex:/^[0-9]{10,12}$/',
      errorText:
        'ИНН должен состоять из 10 (юр. лица) или из 12 (физ. лица) цифр',
      label: 'ИНН',
    },
    companyUrl: {
      rule: 'required|url',
      label: 'Сайт Компании',
    },
    address: {
      rule: 'required|regex:/[a-zA-ZА-ЯЁа-яё0-9 \'".,()-]+/',
      errorText: 'Введите корректный юридический адрес',
      label: 'Юридический адрес',
    },
    index: {
      rule: 'required|digits:6',
      errorText: 'Индекс должен состоять из 6 цифр',
      label: 'Индекс',
    },
    distributor: {
      rule: 'required|regex:/^[0-9a-zA-ZА-ЯЁа-яё \'"()-]+$/',
      errorText: 'Введите корректное наименование',
      label: 'Дистрибьютор',
    },
    name: {
      rule: 'required|regex:/^[0-9a-zA-ZА-ЯЁа-яё \'"():-]+$/',
      errorText: 'Введите корректное наименование',
      label: 'Название конкурса',
    },
    url: {
      rule: 'required|url',
      label: 'Ссылка на конкурс',
    },
    amount: {
      valueType: 'number',
      rule: 'required',
      label: 'Сумма сделки',
    },
    date: {
      rule: 'required|min_date',
      label: 'Дата конкурса',
    },
    customer: {
      rule: 'required|regex:/^[0-9a-zA-ZА-ЯЁа-яё ]+$/',
      errorText: 'Введите корректное имя юр. лица',
      label: 'Юр.лицо, участника',
    },
    manager: {
      rule: 'required|regex:/^[a-zA-ZА-ЯЁа-яё ]+$/',
      errorText: 'Введите корректное имя ведущего менеджера',
      label: 'Ведущий менеджер',
    },
    сompetitors: {
      rule: 'regex:/^[a-zA-ZА-ЯЁа-яё \'"()-]+$/',
      errorText: 'Введите корректные данные',
      label: 'Конкуренты',
    },
    comments: {
      rule: 'regex:/[a-zA-ZА-ЯЁа-яё0-9-:"\'@. ?!№]+/',
      errorText: 'Введите корректные данные',
      label: 'Комментарий',
    },
    percent: {
      rule: 'regex:/^[0-9][0-9]?0?$/',
      errorText: 'Вероятность выигрыша не может быть больше 100%',
      label: 'Выигрыш сделки',
    },
  })

  constructor() {
    makeAutoObservable<
      RegisterTransactionFormStore,
      'transactionStore' | 'uploadsRest'
    >(this, {
      transactionStore: false,
      uploadsRest: false,
    })
  }

  nextStep = (): void => {
    this.form.validate()

    if (this.isAvailableNextStep) {
      this.step = 2
      this.form.clearErrors()
    }
  }

  previousStep(): void {
    this.step -= 1
  }

  async handleSubmit() {
    this.form.validate()

    if (this.isAvailableSend) {
      await this.form.handleSubmit((fields) => {
        const data = {
          companyName: fields.companyName.value,
          inn: fields.inn.value,
          companyUrl: fields.companyUrl.value,
          address: fields.address.value,
          index: fields.index.value,
          distributor: fields.distributor.value,
          name: fields.name.value,
          url: fields.url.value,
          amount: fields.amount.value,
          date: fields.date.value,
          customer: fields.customer.value,
          manager: fields.manager.value,
          сompetitors: fields.сompetitors.value,
          comments: fields.comments.value,
          percent: +fields.percent.value,
          file: this.fileId,
        }

        if (this.isEditing) {
          this.isEditing = false
          return this.transactionStore.editTransaction(data)
        }

        return this.transactionStore.createTransaction(data)
      })

      this.status = this.form.meta.fetchStatus
    }
  }

  setFileId = (file?: IFile) => {
    if (file) {
      this.fileId = file.fileId
    } else {
      this.fileId = null
    }
  }

  clear() {
    this.step = 1
    this.isEditing = false
    this.status = EStatusProcess.IDLE
    this.fileId = null
    this.form.clear()
  }

  setEditing(value: boolean) {
    this.isEditing = value

    if (value) {
      this.autofillTransactionForm()
    }
  }

  autofillTransactionForm() {
    const { transaction } = this.transactionStore

    if (transaction !== undefined && this.isEditing) {
      Object.keys(this.form.fields).forEach((key) => {
        const val = transaction[key as keyof ITransaction]
        if (val) {
          this.form.fields[key].value = val
        }
      })
    }
  }

  get isAvailableNextStep(): boolean {
    return !!(
      !this.form.fields.companyName.error &&
      this.form.fields.companyName.value &&
      !this.form.fields.inn.error &&
      this.form.fields.inn.value &&
      !this.form.fields.companyUrl.error &&
      this.form.fields.companyUrl.value &&
      !this.form.fields.address.error &&
      this.form.fields.address.value &&
      !this.form.fields.index.error &&
      this.form.fields.index.value &&
      !this.form.fields.distributor.error &&
      this.form.fields.distributor.value &&
      !this.form.fields.name.error &&
      this.form.fields.name.value &&
      !this.form.fields.url.error &&
      this.form.fields.url.value &&
      !this.form.fields.amount.error &&
      this.form.fields.amount.value &&
      !this.form.fields.date.error &&
      this.form.fields.date.value
    )
  }

  get isAvailableSend(): boolean {
    return !!(
      !this.form.fields.customer.error &&
      this.form.fields.customer.value &&
      !this.form.fields.manager.error &&
      this.form.fields.manager.value &&
      this.fileId
    )
  }
}

export const useRegisterTransactionFormStore = () =>
  container.resolve(RegisterTransactionFormStore)
