import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureASquareLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.75 8a.75.75 0 00-.75-.75H6.572L7.98 6.076a.75.75 0 00-.96-1.152L4.942 6.656c-.84.7-.84 1.989 0 2.688l2.078 1.732a.75.75 0 00.96-1.152L6.572 8.75H11a.75.75 0 00.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 4.5a3.25 3.25 0 00-3.25-3.25h-7A3.25 3.25 0 001.25 4.5v7a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25v-7zM11.5 2.75c.966 0 1.75.784 1.75 1.75v7a1.75 1.75 0 01-1.75 1.75h-7a1.75 1.75 0 01-1.75-1.75v-7c0-.966.784-1.75 1.75-1.75h7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14 10a.75.75 0 00-.75-.75H8.056l2.009-1.674a.75.75 0 00-.96-1.152L6.427 8.656c-.84.7-.84 1.989 0 2.688l2.679 2.232a.75.75 0 00.96-1.152L8.057 10.75h5.192A.75.75 0 0014 10z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 6A3.75 3.75 0 0014 2.25H6A3.75 3.75 0 002.25 6v8A3.75 3.75 0 006 17.75h8A3.75 3.75 0 0017.75 14V6zM14 3.75A2.25 2.25 0 0116.25 6v8A2.25 2.25 0 0114 16.25H6A2.25 2.25 0 013.75 14V6A2.25 2.25 0 016 3.75h8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 12a.75.75 0 00-.75-.75H8.81l2.72-2.72a.75.75 0 00-1.06-1.06l-3.006 3.005a2.156 2.156 0 000 3.05l3.006 3.005a.75.75 0 101.06-1.06l-2.72-2.72h7.69a.75.75 0 00.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.75 6.5a4.25 4.25 0 00-4.25-4.25h-11A4.25 4.25 0 002.25 6.5v11a4.25 4.25 0 004.25 4.25h11a4.25 4.25 0 004.25-4.25v-11zM17.5 3.75a2.75 2.75 0 012.75 2.75v11a2.75 2.75 0 01-2.75 2.75h-11a2.75 2.75 0 01-2.75-2.75v-11A2.75 2.75 0 016.5 3.75h11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23 16a1 1 0 00-1-1H11.59l3.309-3.308a1 1 0 00-1.415-1.414l-3.6 3.6a2.99 2.99 0 00-.877 2.001 1.01 1.01 0 000 .242 2.99 2.99 0 00.876 2l3.601 3.601a1 1 0 001.415-1.414L11.59 17H22a1 1 0 001-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 8.5A5.5 5.5 0 0023.5 3h-15A5.5 5.5 0 003 8.5v15A5.5 5.5 0 008.5 29h15a5.5 5.5 0 005.5-5.5v-15zM23.5 5A3.5 3.5 0 0127 8.5v15a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 23.5v-15A3.5 3.5 0 018.5 5h15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M46.5 32a1.5 1.5 0 00-1.5-1.5H21.896l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-8.378 8.378a4.5 4.5 0 000 6.364l8.378 8.379a1.5 1.5 0 002.122-2.122l-7.94-7.939H45a1.5 1.5 0 001.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57 17.5C57 11.701 52.299 7 46.5 7h-29C11.701 7 7 11.701 7 17.5v29C7 52.299 11.701 57 17.5 57h29C52.299 57 57 52.299 57 46.5v-29zM46.5 10a7.5 7.5 0 017.5 7.5v29a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5v-29a7.5 7.5 0 017.5-7.5h29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareLeftRegular)
