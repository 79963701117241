import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconInfoCircleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 7.1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V10h.5a.5.5 0 010 1h-2a.5.5 0 110-1H7V7.6h-.5a.5.5 0 01-.5-.5zM7.25 5.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 7.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM7.5 2a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10 6a.75.75 0 11-1.5 0A.75.75 0 0110 6zM8.5 8.011a.5.5 0 000 1H9v2.999H8a.5.5 0 100 1h3a.5.5 0 000-1h-1V8.761a.75.75 0 00-.75-.75H8.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM9.5 3a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconInfoCircleLight)
