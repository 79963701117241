import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTimeHourglassLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1a.5.5 0 000 1h1v2.259a3.5 3.5 0 001.764 3.039L6.992 8l-1.228.702A3.5 3.5 0 004 11.741V14H3a.5.5 0 000 1h10a.5.5 0 000-1h-1v-2.259a3.5 3.5 0 00-1.764-3.039L9.008 8l1.229-.702A3.5 3.5 0 0012 4.259V2h1a.5.5 0 000-1H3zm8 1H5v2.259a2.5 2.5 0 001.26 2.17L8 7.425l1.74-.994A2.5 2.5 0 0011 4.259V2zM8 8.576l-1.74.994A2.5 2.5 0 005 11.741V14h6v-2.259a2.5 2.5 0 00-1.26-2.17L8 8.575z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5a.5.5 0 01.5-.5h13a.5.5 0 010 1H15v2.146a4.5 4.5 0 01-2.315 3.934L11.03 10l1.655.92A4.5 4.5 0 0115 14.854V17h1.5a.5.5 0 010 1h-13a.5.5 0 010-1H5v-2.146a4.5 4.5 0 012.315-3.934L8.97 10l-1.655-.92A4.5 4.5 0 015 5.146V3H3.5a.5.5 0 01-.5-.5zM6 17h8v-2.146a3.5 3.5 0 00-1.8-3.06L10 10.572l-2.2 1.222a3.5 3.5 0 00-1.8 3.06V17zM6 5.146V3h8v2.146a3.5 3.5 0 01-1.8 3.06L10 9.428 7.8 8.206A3.5 3.5 0 016 5.146z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeHourglassLight)
