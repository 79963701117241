import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextAlignCenterRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 2.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zM4.25 6A.75.75 0 015 5.25h6a.75.75 0 010 1.5H5A.75.75 0 014.25 6zM3 8.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zM5 11.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 3.25a.75.75 0 000 1.5h14a.75.75 0 000-1.5H3zM5.25 8A.75.75 0 016 7.25h8a.75.75 0 010 1.5H6A.75.75 0 015.25 8zM3 11.25a.75.75 0 000 1.5h14a.75.75 0 000-1.5H3zM6 15.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 3.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM6.75 8.25a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75zM6.75 18.25a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75zM3 14a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 14z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 6a1 1 0 000 2h22a1 1 0 100-2H5zM9 13a1 1 0 011-1h12a1 1 0 110 2H10a1 1 0 01-1-1zM5 18a1 1 0 100 2h22a1 1 0 100-2H5zM10 24a1 1 0 100 2h12a1 1 0 100-2H10z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.5 11a1.5 1.5 0 000 3h45a1.5 1.5 0 000-3h-45zM18 25.5a1.5 1.5 0 011.5-1.5h25a1.5 1.5 0 010 3h-25a1.5 1.5 0 01-1.5-1.5zM9.5 37a1.5 1.5 0 000 3h45a1.5 1.5 0 000-3h-45zM19.5 50a1.5 1.5 0 000 3h25a1.5 1.5 0 000-3h-25z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextAlignCenterRegular)
