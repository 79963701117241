import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1EditLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.502 3.502 0 100 7.003A3.502 3.502 0 008 1zM5.498 4.502a2.502 2.502 0 115.004 0 2.502 2.502 0 01-5.004 0z"
          fill="currentColor"
        />
        <path
          d="M3 13.505c0-.855.208-1.473.518-1.93.311-.46.749-.79 1.26-1.025 1.04-.481 2.32-.545 3.222-.545a.5.5 0 000-1c-.915 0-2.387.057-3.641.637-.637.294-1.233.729-1.67 1.372-.437.645-.689 1.466-.689 2.49a.5.5 0 001 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.08 7.462a1.3 1.3 0 011.84 0l.618.619a1.3 1.3 0 010 1.838l-4.788 4.788a1 1 0 01-.707.293H8.5a.5.5 0 01-.5-.5v-1.543a1 1 0 01.293-.707l4.788-4.788zm1.132.707a.3.3 0 00-.424 0l-.957.957a.52.52 0 01.023.02l1 1a.613.613 0 01.02.022l.957-.956a.3.3 0 000-.424l-.619-.619zm-1.043 2.705a.613.613 0 01-.023-.02l-1-1a.526.526 0 01-.02-.023L9 12.957V14h1.043l3.126-3.126z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a4.499 4.499 0 100 8.997A4.499 4.499 0 0010 2zM6.501 6.499a3.499 3.499 0 116.998 0 3.499 3.499 0 01-6.998 0z"
        fill="currentColor"
      />
      <path
        d="M9.51 13.005a.5.5 0 00-.02-1c-1.31.026-3.153.174-4.682.917-.772.375-1.48.909-1.993 1.668C2.299 15.353 2 16.312 2 17.5a.5.5 0 001 0c0-1.012.252-1.771.643-2.35.393-.581.949-1.011 1.602-1.329 1.323-.642 2.98-.791 4.265-.816z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.08 10.462a1.3 1.3 0 011.84 0l.618.619a1.3 1.3 0 010 1.838l-5.788 5.788a1 1 0 01-.707.293H10.5a.5.5 0 01-.5-.5v-1.543a1 1 0 01.293-.707l5.788-5.788zm1.132.707a.3.3 0 00-.424 0l-.957.957a.539.539 0 01.023.02l1 1a.613.613 0 01.02.022l.957-.956a.3.3 0 000-.424l-.619-.619zm-1.043 2.705a.613.613 0 01-.023-.02l-1-1a.539.539 0 01-.02-.022L11 16.956V18h1.043l4.126-4.126z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1EditLight)
