import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeAUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.587 1.603a2 2 0 012.828 0l5.689 5.69c.63.63.183 1.707-.707 1.707H12v4.25A1.75 1.75 0 0110.25 15h-4.5A1.75 1.75 0 014 13.25V9H1.604C.713 9 .266 7.923.897 7.293l5.69-5.69zm1.767 1.061a.5.5 0 00-.707 0L2.811 7.5H4.75a.75.75 0 01.75.75v5c0 .138.112.25.25.25h4.5a.25.25 0 00.25-.25v-5a.75.75 0 01.75-.75h1.94L8.354 2.664z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.411 2.228a2.25 2.25 0 013.18 0l6.977 6.967c.851.85.249 2.306-.954 2.306H14.75V16a2 2 0 01-2 2h-5.5a2 2 0 01-2-2v-4.5H2.388c-1.203 0-1.805-1.456-.954-2.306l6.977-6.967zm2.12 1.062a.75.75 0 00-1.06 0L2.751 10H6a.75.75 0 01.75.75V16a.5.5 0 00.5.5h5.5a.5.5 0 00.5-.5v-5.25A.75.75 0 0114 10h3.251l-6.72-6.71z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.144 2.795a2.625 2.625 0 013.712 0l8.73 8.73c.913.913.266 2.475-1.026 2.475H18v5.75A2.25 2.25 0 0115.75 22h-7.5A2.25 2.25 0 016 19.75V14H2.44C1.148 14 .5 12.438 1.414 11.524l8.73-8.729zm2.651 1.06a1.125 1.125 0 00-1.59 0L2.56 12.5h4.19a.75.75 0 01.75.75v6.5c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-6.5a.75.75 0 01.75-.75h4.19l-8.645-8.644z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.527 3.56a3.5 3.5 0 014.95 0l11.025 11.026c1.26 1.26.368 3.414-1.414 3.414H23v8a3 3 0 01-3 3h-8a3 3 0 01-3-3v-8H3.916C2.134 18 1.24 15.846 2.5 14.586L13.527 3.56zm3.536 1.415a1.5 1.5 0 00-2.121 0L3.915 16H10a1 1 0 011 1v8.998a1 1 0 001 1h8a1 1 0 001-1v-8.998a1 1 0 011-1h6.088L17.063 4.974z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.408 7.972a6.5 6.5 0 019.193 0l22.05 22.052C60.855 32.23 59.294 36 56.176 36H46v15.5a5.5 5.5 0 01-5.5 5.5h-17a5.5 5.5 0 01-5.5-5.5V36H7.831c-3.118 0-4.68-3.77-2.475-5.975L27.408 7.972zm7.071 2.122a3.5 3.5 0 00-4.95 0L7.478 32.145a.5.5 0 00.353.853h11.67a1.5 1.5 0 011.5 1.5v17a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-17a1.5 1.5 0 011.5-1.5h11.675a.5.5 0 00.353-.854L34.48 10.095z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeAUpRegular)
