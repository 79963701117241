import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetCloudWireLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 12h3.25a4.25 4.25 0 00-.082-8.5 4.126 4.126 0 00-7.911 1.388A3.626 3.626 0 004.25 12H7v2H3.5a.5.5 0 000 1h9a.5.5 0 000-1H8v-2zM4.25 5.125a3.125 3.125 0 016.1-.96.5.5 0 00.515.345 3.25 3.25 0 11.34 6.49H4.25a2.625 2.625 0 01-.418-5.217.5.5 0 00.421-.516 3.18 3.18 0 01-.003-.142z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15H5.25a4.625 4.625 0 01-1.205-9.056 5.126 5.126 0 019.747-1.44A5.25 5.25 0 1114 15h-4v3h5.5a.5.5 0 010 1h-11a.5.5 0 010-1H9v-3zM5.008 6.371a4.125 4.125 0 017.99-1.169.5.5 0 00.525.324A4.25 4.25 0 1114 14H5.293a3.625 3.625 0 01-.68-7.17.5.5 0 00.395-.459z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudWireLight)
