import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArchiveCabinetLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 4.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM6.5 10a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 1A2.5 2.5 0 003 3.5v8A2.5 2.5 0 005.5 14h5a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0010.5 1h-5zM12 7V3.5A1.5 1.5 0 0010.5 2h-5A1.5 1.5 0 004 3.5V7h8zM4 8h8v3.5a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 11.5V8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8 6.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM8.5 13a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a3 3 0 00-3 3v10a3 3 0 003 3h6a3 3 0 003-3V5a3 3 0 00-3-3H7zm8 3a2 2 0 00-2-2H7a2 2 0 00-2 2v4h10V5zM5 10h10v5a2 2 0 01-2 2H7a2 2 0 01-2-2v-5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveCabinetLight)
