import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBuildingOfficeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 5.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM4.5 7a.5.5 0 000 1h1a.5.5 0 000-1h-1zM4 9.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM7.5 5a.5.5 0 000 1h1a.5.5 0 000-1h-1zM7 7.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM7.5 9a.5.5 0 000 1h1a.5.5 0 000-1h-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.239 1A1 1 0 00.31 2.371l.583 1.457A1.5 1.5 0 011 4.385V12.5A2.5 2.5 0 003.5 15h6a2.5 2.5 0 002.5-2.5V4.385c0-.19.036-.38.107-.557l.583-1.457A1 1 0 0011.762 1H1.239zm0 1H11.76l-.582 1.457a2.5 2.5 0 00-.179.928V12.5A1.5 1.5 0 019.5 14h-6A1.5 1.5 0 012 12.5V4.385a2.5 2.5 0 00-.179-.928L1.24 2z"
          fill="currentColor"
        />
        <path
          d="M13.854 5.646a.5.5 0 10-.707.708l1.707 1.707a.5.5 0 01.146.353V13.5a.5.5 0 01-.5.5H13a.5.5 0 000 1h1.5a1.5 1.5 0 001.5-1.5V8.414a1.5 1.5 0 00-.44-1.06l-1.706-1.708z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6 6.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM6.5 8a.5.5 0 000 1h1a.5.5 0 000-1h-1zM6 10.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM6.5 12a.5.5 0 000 1h1a.5.5 0 000-1h-1zM9 6.5a.5.5 0 01.5-.5h1a.5.5 0 110 1h-1a.5.5 0 01-.5-.5zM9.5 8a.5.5 0 000 1h1a.5.5 0 100-1h-1zM9 10.5a.5.5 0 01.5-.5h1a.5.5 0 110 1h-1a.5.5 0 01-.5-.5zM9.5 12a.5.5 0 000 1h1a.5.5 0 100-1h-1z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.239 2a1 1 0 00-.929 1.371l.583 1.457A1.5 1.5 0 013 5.385V14.5A2.5 2.5 0 005.5 17h6a2.5 2.5 0 002.5-2.5V5.385c0-.19.036-.38.107-.557l.583-1.457A1 1 0 0013.762 2H3.239zm0 1H13.76l-.582 1.457a2.5 2.5 0 00-.179.928V14.5a1.5 1.5 0 01-1.5 1.5h-6A1.5 1.5 0 014 14.5V5.385a2.5 2.5 0 00-.179-.928L3.24 3z"
        fill="currentColor"
      />
      <path
        d="M15.99 6.593a.5.5 0 10-.58.814l2.171 1.551a1 1 0 01.419.814V15a1 1 0 01-1 1h-2a.5.5 0 000 1h2a2 2 0 002-2V9.772a2 2 0 00-.837-1.628l-2.172-1.55z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBuildingOfficeLight)
