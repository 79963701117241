import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookClosedHelpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.167 9.508a.667.667 0 11-1.334 0 .667.667 0 011.334 0zM6.22 5.503c0-.412.464-.924 1.28-.924.816 0 1.28.512 1.28.924 0 .306-.235.65-.712.825C7.534 6.524 7 6.932 7 7.638a.5.5 0 101 0 .23.23 0 01.07-.169.871.871 0 01.341-.202c.75-.274 1.368-.92 1.368-1.764 0-1.16-1.13-1.924-2.279-1.924s-2.28.763-2.28 1.924a.5.5 0 101 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2.25C13 1.56 12.44 1 11.749 1l-7.002.008A2.75 2.75 0 002 3.758v9.246C2 14.107 2.893 15 3.994 15h8.256a.75.75 0 000-1.5H3.994a.494.494 0 010-.989l8.256-.005a.75.75 0 00.75-.751V2.251zm-1.5 8.755V2.501l-6.751.007c-.69 0-1.249.56-1.249 1.25v7.314c.158-.04.323-.062.493-.062l7.507-.005z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 12a1 1 0 11-2 0 1 1 0 012 0zM8.707 6.774c0-.47.476-1.024 1.293-1.024s1.293.554 1.293 1.024c0 .25-.069.378-.146.471-.1.12-.252.227-.504.374l-.094.054c-.21.12-.507.29-.738.503-.315.29-.561.697-.561 1.258a.75.75 0 001.5 0c0-.065.009-.092.077-.155.097-.09.223-.162.443-.29l.13-.075c.258-.15.617-.37.9-.71.306-.368.493-.837.493-1.43 0-1.49-1.354-2.524-2.793-2.524-1.44 0-2.793 1.034-2.793 2.524a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4.25A3.25 3.25 0 016.25 1H15a2 2 0 012 2v11a2 2 0 01-2 2H5.25a.75.75 0 000 1.5H16.25a.75.75 0 010 1.5H5.249a2.25 2.25 0 01-2.246-2.39.758.758 0 01-.004-.08V4.25zm1.5 10.378c.234-.083.486-.128.749-.128H15a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5H6.249A1.75 1.75 0 004.5 4.25v10.378z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13 14a1 1 0 11-2 0 1 1 0 012 0zM10.09 8.083c0-.765.752-1.56 1.91-1.56s1.91.795 1.91 1.56c0 .377-.107.596-.243.759-.157.19-.386.345-.715.537l-.129.074c-.276.158-.635.364-.916.622-.372.343-.657.816-.657 1.472a.75.75 0 001.5 0c0-.16.048-.253.174-.369.146-.134.334-.243.62-.408l.164-.095c.336-.197.772-.464 1.113-.875.363-.437.589-.996.589-1.717 0-1.785-1.63-3.06-3.41-3.06-1.78 0-3.41 1.275-3.41 3.06a.75.75 0 101.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25 5A3.75 3.75 0 017 1.25h11.5a2.25 2.25 0 012.25 2.25v13a2.25 2.25 0 01-2.25 2.25H6a1.25 1.25 0 100 2.5h14a.75.75 0 010 1.5H6A2.75 2.75 0 013.25 20V5zm1.5 12.55c.375-.192.8-.3 1.25-.3h12.5a.75.75 0 00.75-.75v-13a.75.75 0 00-.75-.75H7A2.25 2.25 0 004.75 5v12.55z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 19a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM13.498 11.048c0-1.027.994-2.074 2.502-2.074 1.508 0 2.502 1.047 2.502 2.074 0 .505-.142.797-.32 1.013-.207.252-.507.458-.939.713l-.17.099c-.363.21-.837.485-1.207.83-.492.458-.866 1.087-.866 1.957a1 1 0 102 0c0-.216.064-.34.229-.494.191-.178.438-.323.814-.542l.216-.127c.443-.262 1.018-.618 1.468-1.166.48-.583.775-1.326.775-2.283 0-2.368-2.142-4.074-4.502-4.074s-4.502 1.706-4.502 4.074a1 1 0 002 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7a5 5 0 015-5h14a3 3 0 013 3v17a3 3 0 01-3 3H8.5a1.5 1.5 0 000 3H26a1 1 0 110 2H8.5A3.5 3.5 0 015 26.5V7zm2 16.337A3.486 3.486 0 018.5 23H24a1 1 0 001-1V5a1 1 0 00-1-1H10a3 3 0 00-3 3v16.337z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 37a2 2 0 11-4 0 2 2 0 014 0zM27 22.207C27 20.06 29.05 18 32 18s5 2.06 5 4.207c0 1.017-.291 1.64-.675 2.107-.427.52-1.033.93-1.837 1.403l-.323.188c-.68.394-1.517.88-2.172 1.49-.855.796-1.493 1.873-1.493 3.371a1.5 1.5 0 003 0c0-.516.174-.838.538-1.177.386-.36.884-.65 1.58-1.056l.394-.232c.821-.484 1.84-1.12 2.632-2.083C39.478 25.2 40 23.902 40 22.207 40 18.049 36.229 15 32 15s-8 3.049-8 7.207a1.5 1.5 0 003 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 52a3 3 0 003 3h34.5a1.5 1.5 0 010 3H17a6 6 0 01-6-6V14.5A8.5 8.5 0 0119.5 6h29a4.5 4.5 0 014.5 4.5v34a4.5 4.5 0 01-4.5 4.5H17a3 3 0 00-3 3zM48.5 9h-29a5.5 5.5 0 00-5.5 5.5v32.303A5.972 5.972 0 0117 46h31.5a1.5 1.5 0 001.5-1.5v-34A1.5 1.5 0 0048.5 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookClosedHelpRegular)
