import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetCloudCheckRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.652 7.47a.675.675 0 00-.956-.954L6.998 9.22l-1.12-1.122a.675.675 0 10-.956.953l1.244 1.247c.46.46 1.204.46 1.663 0l2.823-2.828z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.375 1.5A4.376 4.376 0 003.02 5.45 3.876 3.876 0 004.25 13h7a4.5 4.5 0 00.08-8.995A4.375 4.375 0 007.376 1.5zM4.5 5.875a2.875 2.875 0 015.612-.883.75.75 0 00.773.517 3 3 0 11.31 5.99H4.25a2.375 2.375 0 01-.378-4.72.75.75 0 00.63-.773 2.938 2.938 0 01-.002-.131z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.53 10.15a.75.75 0 00-1.06-1.06l-3.288 3.293a.25.25 0 01-.354 0l-1.297-1.3a.75.75 0 00-1.062 1.06l1.298 1.3a1.75 1.75 0 002.477 0l3.287-3.293z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.125 3A5.376 5.376 0 003.82 7.503 4.877 4.877 0 005.25 17H14a5.5 5.5 0 100-11h-.052a5.375 5.375 0 00-4.823-3zM5.257 8.136a3.875 3.875 0 017.507-1.098.75.75 0 00.787.487A4 4 0 1114 15.5H5.302a3.375 3.375 0 01-.638-6.675.75.75 0 00.592-.689z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.263 11.28a.75.75 0 10-1.06-1.06l-4.634 4.633a.25.25 0 01-.354 0l-2.004-2.004a.75.75 0 10-1.06 1.06l2.004 2.005a1.75 1.75 0 002.475 0l4.633-4.634z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.95 3.25a6.301 6.301 0 00-6.231 5.368A5.702 5.702 0 006.3 19.75h10.5a6.45 6.45 0 10-.156-12.898A6.3 6.3 0 0010.95 3.25zM6.159 9.255a4.8 4.8 0 019.298-1.36.75.75 0 00.787.486 4.95 4.95 0 11.556 9.87H6.358a4.2 4.2 0 01-.79-8.307.75.75 0 00.59-.69z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.536 14.683a1 1 0 00-1.414-1.414l-5.454 5.455a1 1 0 01-1.415 0L11.2 16.67a1 1 0 00-1.414 1.414l2.053 2.054a3 3 0 004.243 0l5.454-5.455z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 26c.022 0 .043 0 .064-.002a8.625 8.625 0 00.432-17.226A8.874 8.874 0 0015.125 4c-4.283 0-7.857 3.034-8.691 7.07A7.625 7.625 0 008.5 26h14zM8.304 12.005a6.876 6.876 0 0113.134-1.858 1 1 0 00.919.603h.018a6.625 6.625 0 01.08 13.25H8.548l-.03-.001A5.625 5.625 0 017.51 12.86a1 1 0 00.795-.855z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M44.06 29.583a1.5 1.5 0 00-2.12-2.12L29.431 39.97a1.5 1.5 0 01-2.121 0l-5.22-5.219a1.5 1.5 0 00-2.12 2.121l5.218 5.219a4.5 4.5 0 006.364 0l12.508-12.508z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 10c-7.988 0-14.608 5.853-15.806 13.505C7.532 25.177 3 30.808 3 37.5 3 45.508 9.492 52 17.5 52h27C53.613 52 61 44.613 61 35.5S53.613 19 44.5 19c-.202 0-.403.004-.604.01C41.303 13.68 35.833 10 29.5 10zM16.55 24.86C17.125 18.214 22.704 13 29.5 13c5.459 0 10.136 3.365 12.062 8.14.25.62.879 1 1.544.931.457-.047.923-.071 1.394-.071C51.956 22 58 28.044 58 35.5S51.956 49 44.5 49h-27C11.149 49 6 43.851 6 37.5c0-5.61 4.019-10.285 9.335-11.297a1.5 1.5 0 001.214-1.344z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudCheckRegular)
