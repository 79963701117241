import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechPrinterRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.495 9.249a.749.749 0 100-1.498.749.749 0 000 1.498z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 3v2.25h.75a2.25 2.25 0 012.25 2.25v4a2.25 2.25 0 01-2.25 2.25h-.75v.5A1.75 1.75 0 0111 16H5a1.75 1.75 0 01-1.75-1.75v-.5H2.5A2.25 2.25 0 01.25 11.5v-4A2.25 2.25 0 012.5 5.25h.75V3c0-.966.784-1.75 1.75-1.75h6c.966 0 1.75.784 1.75 1.75zm-8 0A.25.25 0 015 2.75h6a.25.25 0 01.25.25v2.25h-6.5V3zm8.75 3.75a.75.75 0 01.75.75v4a.75.75 0 01-.75.75h-.75v-.5A1.75 1.75 0 0011 10H5a1.75 1.75 0 00-1.75 1.75v.5H2.5a.75.75 0 01-.75-.75v-4a.75.75 0 01.75-.75h11zm-2.25 5v2.5a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25v-2.5A.25.25 0 015 11.5h6a.25.25 0 01.25.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.75 11a.752.752 0 100-1.505.752.752 0 000 1.505z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.75 4v3h1A2.25 2.25 0 0119 9.25v4.5A2.25 2.25 0 0116.75 16h-1v1a2 2 0 01-2 2h-7.5a2 2 0 01-2-2v-1H3.249a2.25 2.25 0 01-2.25-2.25v-4.5A2.25 2.25 0 013.249 7h1V4a2 2 0 012-2h7.5a2 2 0 012 2zm-10 0a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5v3h-8.5V4zm11 4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75h-1V14a2 2 0 00-2-2h-7.5a2 2 0 00-2 2v.5H3.249a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75h13.502zm-2.5 5.5v3a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.75 4.75a1 1 0 011-1h8.5a1 1 0 011 1V7a.75.75 0 001.5 0V4.75a2.5 2.5 0 00-2.5-2.5h-8.5a2.5 2.5 0 00-2.5 2.5V7a.75.75 0 001.5 0V4.75zM19 13a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 19.75h-1.25v.5a2.5 2.5 0 01-2.5 2.5h-8.5a2.5 2.5 0 01-2.5-2.5v-.5H4A2.75 2.75 0 011.25 17v-6A2.75 2.75 0 014 8.25h16A2.75 2.75 0 0122.75 11v6A2.75 2.75 0 0120 19.75zm-13.25-3a1 1 0 011-1h8.5a1 1 0 011 1v3.5a1 1 0 01-1 1h-8.5a1 1 0 01-1-1v-3.5zm12 0v1.5H20c.69 0 1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25H4c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h1.25v-1.5a2.5 2.5 0 012.5-2.5h8.5a2.5 2.5 0 012.5 2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 6.5A1.5 1.5 0 0110.5 5h11A1.5 1.5 0 0123 6.5V9a1 1 0 102 0V6.5A3.5 3.5 0 0021.5 3h-11A3.5 3.5 0 007 6.5V9a1 1 0 002 0V6.5zM25.333 17a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.5 26H25v.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 26.5V26H5.5A3.5 3.5 0 012 22.5v-8A3.5 3.5 0 015.5 11h21a3.5 3.5 0 013.5 3.5v8a3.5 3.5 0 01-3.5 3.5zM9 22.5a1.5 1.5 0 011.5-1.5h11a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 019 26.5v-4zm16 0V24h1.5a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 004 14.5v8A1.5 1.5 0 005.5 24H7v-1.5a3.5 3.5 0 013.5-3.5h11a3.5 3.5 0 013.5 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M18 11.5A2.5 2.5 0 0120.5 9h23a2.5 2.5 0 012.5 2.5v7a1.5 1.5 0 003 0v-7A5.5 5.5 0 0043.5 6h-23a5.5 5.5 0 00-5.5 5.5v7a1.5 1.5 0 003 0v-7zM50 33a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5 51H49v2.5a5.5 5.5 0 01-5.5 5.5h-23a5.5 5.5 0 01-5.5-5.5V51h-3.5A6.5 6.5 0 015 44.5v-16a6.5 6.5 0 016.5-6.5h41a6.5 6.5 0 016.5 6.5v16a6.5 6.5 0 01-6.5 6.5zM18 43.5a2.5 2.5 0 012.5-2.5h23a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5h-23a2.5 2.5 0 01-2.5-2.5v-10zm31 0V48h3.5a3.5 3.5 0 003.5-3.5v-16a3.5 3.5 0 00-3.5-3.5h-41A3.5 3.5 0 008 28.5v16a3.5 3.5 0 003.5 3.5H15v-4.5a5.5 5.5 0 015.5-5.5h23a5.5 5.5 0 015.5 5.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPrinterRegular)
