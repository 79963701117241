import React, { useCallback } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'

import { getImageUrl } from '@lib/helpers'
import { EAuthRoutes } from '@lib/navigation'

import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'

import styles from './AuthLayout.module.scss'

/**
 * Компонент авторизации.
 */

export const AuthLayout: React.FC = observer(() => {
  const animatedStore = useAnimatedFormStore()
  const navigate = useNavigate()

  const handleToRegistration = useCallback(() => {
    animatedStore.setAnimatedState('none')
    navigate(EAuthRoutes.Registration)
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/">
          <img
            alt="Логотип"
            src={getImageUrl('logo.svg')}
            width={128}
            height={34}
          />
        </Link>
        <div className={styles.navigation}>
          <span className={styles.hasAccount}>У вас нет аккаунта?</span>
          <Button
            className={styles.registration}
            type="link"
            onClick={handleToRegistration}>
            Регистрация
          </Button>
        </div>
      </header>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
})
