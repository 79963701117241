import { memo } from 'react'

import { IconMinusLight, IconPlusLight } from '@ui/icons'
import { Typography } from '@ui/Typography'

import styles from './ButtonCounter.module.scss'

interface IButtonCounter {
  count: number
  onIncrement: () => void
  onDecrement: () => void
}

const ButtonCounter = ({ count, onIncrement, onDecrement }: IButtonCounter) => (
  <div className={styles.container}>
    <IconMinusLight onClick={onDecrement} />
    <Typography className={styles.count}>{count}</Typography>
    <IconPlusLight onClick={onIncrement} />
  </div>
)

export default memo(ButtonCounter)
