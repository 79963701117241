import { ReactNode, useCallback, useState } from 'react'

import { IconInfoCircleRegular } from '@ui/icons'
import { Modal } from '@ui/Modal'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import styles from './CollapseItem.module.scss'

interface ICollapseItem {
  title?: string
  titleRight?: string
  description?: string
  tooltipTitle?: string
  tooltipContent?: ReactNode
  tooltipFooter?: ReactNode[]
  children: React.ReactNode
}

const CollapseItem = ({
  title,
  titleRight,
  description,
  tooltipTitle,
  tooltipContent,
  children,
}: ICollapseItem) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const handleCloseTooltip = useCallback(() => {
    setIsOpenTooltip(false)
  }, [])

  const handleOpenTooltip = useCallback(() => {
    setIsOpenTooltip(true)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Typography size={18} weight="medium">
            {title}
          </Typography>
          {tooltipTitle && (
            <IconInfoCircleRegular
              color={COLORS.GRAY.GRAY_1}
              className={styles.tooltip}
              onClick={handleOpenTooltip}
            />
          )}
        </div>

        {titleRight && <Typography size={14}>{titleRight}</Typography>}
      </div>

      <div className={styles.description}>
        <Typography size={16}>{description}</Typography>
      </div>

      <div className={styles.content}>{children}</div>

      {tooltipTitle && isOpenTooltip && (
        <Modal
          open={isOpenTooltip}
          onClose={handleCloseTooltip}
          title={tooltipTitle}
          content={tooltipContent}
        />
      )}
    </div>
  )
}

export default CollapseItem
