import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookOpenTextRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 6.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM4.5 8a.5.5 0 000 1H7a.5.5 0 100-1H4.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.987 2.034a10.76 10.76 0 012.614.027c.855.11 1.404.85 1.404 1.634v7.592c0 1.056-.918 1.803-1.874 1.787-1.18-.02-2.867.147-3.948 1.012-.67.535-1.697.535-2.366 0-1.081-.865-2.768-1.032-3.948-1.012-.956.017-1.874-.731-1.874-1.787V3.695c0-.784.55-1.524 1.405-1.634a10.759 10.759 0 012.613-.027c.98.119 2.074.424 2.987 1.123.913-.7 2.007-1.004 2.987-1.123zm.181 1.489c-.858.104-1.688.364-2.322.877a1.352 1.352 0 01-1.692 0c-.634-.513-1.463-.773-2.322-.877a9.264 9.264 0 00-2.24.026.087.087 0 00-.06.033.176.176 0 00-.037.113v7.592c0 .062.027.13.092.19.068.062.161.099.256.097 1.227-.021 3.389.123 4.91 1.34.122.097.372.097.493 0 1.522-1.217 3.684-1.361 4.911-1.34a.374.374 0 00.256-.097.256.256 0 00.092-.19V3.695a.176.176 0 00-.037-.113.087.087 0 00-.06-.033 9.264 9.264 0 00-2.24-.026z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.25 7.75A.75.75 0 015 7h5a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zM5 10a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5H5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.777 2.345c1.302-.17 2.534-.081 3.35.025.986.128 1.623.983 1.623 1.897v9.757c0 1.292-1.17 2.182-2.336 2.114-1.553-.091-3.734.091-5.118 1.428A1.86 1.86 0 0110 18.07c-.448 0-.93-.155-1.294-.508-1.377-1.335-3.563-1.516-5.122-1.424-1.164.069-2.334-.822-2.334-2.113V4.267c0-.914.637-1.769 1.624-1.897.815-.106 2.047-.195 3.35-.025 1.26.164 2.645.577 3.776 1.507 1.131-.93 2.516-1.343 3.777-1.507zm.193 1.487c-1.134.148-2.245.511-3.092 1.242a1.35 1.35 0 01-1.756 0c-.847-.73-1.958-1.094-3.092-1.242-1.13-.147-2.22-.07-2.963.026-.161.02-.317.167-.317.41v9.757c0 .307.316.64.746.616 1.627-.096 4.392.04 6.254 1.844a.37.37 0 00.253.085c.123 0 .21-.043.251-.083 1.868-1.804 4.623-1.942 6.248-1.847.43.025.748-.31.748-.616V4.267c0-.242-.156-.389-.317-.41-.742-.096-1.834-.172-2.963-.025z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.75 8.25a.75.75 0 100 1.5h5.5a.75.75 0 000-1.5h-5.5zM5 12a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5A.75.75 0 015 12zM5.75 14.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.893 5.148c-1.338-1.065-2.974-1.56-4.492-1.766-1.522-.208-2.978-.135-4.01-.021C2.098 3.503 1.25 4.62 1.25 5.83v11.104c0 1.61 1.407 2.785 2.903 2.79 1.919.006 4.546.326 6.285 1.71.882.701 2.242.701 3.123 0 1.74-1.384 4.367-1.704 6.286-1.71 1.496-.005 2.903-1.18 2.903-2.79V5.83c0-1.211-.849-2.328-2.142-2.47-1.03-.114-2.487-.187-4.01.02-1.517.208-3.153.702-4.491 1.767a.182.182 0 01-.214 0zm-8.338-.296c.96-.106 2.284-.17 3.644.016 1.363.186 2.708.616 3.76 1.453a1.681 1.681 0 002.082 0c1.052-.837 2.397-1.267 3.76-1.453 1.36-.186 2.685-.122 3.644-.016.445.049.805.442.805.979v11.104c0 .672-.622 1.287-1.409 1.29-1.976.006-5.052.317-7.213 2.036-.335.266-.92.266-1.256 0-2.16-1.72-5.237-2.03-7.213-2.036-.787-.003-1.409-.618-1.409-1.29V5.83c0-.537.36-.93.805-.98z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 11a1 1 0 100 2H15a1 1 0 100-2H7.5zM6.5 16a1 1 0 011-1H15a1 1 0 110 2H7.5a1 1 0 01-1-1zM7.5 19a1 1 0 100 2h4a1 1 0 100-2h-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.851 6.51c-1.826-1.477-4.075-2.124-6.128-2.374-2.059-.25-4-.11-5.292.057C2.96 4.383 2 5.655 2 7.033v15.782c0 1.844 1.614 3.184 3.325 3.155 2.471-.043 6.154.296 8.566 2.225 1.186.948 3.032.948 4.218 0 2.412-1.929 6.095-2.268 8.566-2.225C28.387 26 30 24.66 30 22.815V7.034c0-1.379-.96-2.652-2.43-2.841-1.293-.167-3.234-.307-5.294-.057-2.052.25-4.3.897-6.127 2.374a.252.252 0 01-.298 0zM4.686 6.176c1.197-.154 2.96-.278 4.796-.054 1.844.224 3.683.788 5.112 1.943a2.25 2.25 0 002.812 0c1.43-1.155 3.268-1.72 5.112-1.943 1.837-.224 3.6-.1 4.796.054.37.048.686.379.686.858v15.78c0 .596-.562 1.169-1.29 1.156-2.534-.044-6.85.265-9.85 2.663-.456.364-1.264.364-1.72 0-3-2.398-7.316-2.707-9.85-2.663-.728.013-1.29-.56-1.29-1.155V7.034c0-.48.315-.81.686-.858z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14.5 24a1.5 1.5 0 000 3h16a1.5 1.5 0 100-3h-16zM13 31.5a1.5 1.5 0 011.5-1.5h16a1.5 1.5 0 110 3h-16a1.5 1.5 0 01-1.5-1.5zM14.5 36a1.5 1.5 0 000 3h9a1.5 1.5 0 100-3h-9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.383 13.786c-3.476-2.792-7.764-4.035-11.72-4.519-3.966-.485-7.716-.222-10.234.099C6.76 9.706 5 12.018 5 14.55v30.742c0 3.375 2.96 5.863 6.136 5.814 4.846-.075 12.12.592 16.96 4.414 2.193 1.733 5.616 1.733 7.81 0 4.84-3.822 12.114-4.489 16.96-4.414C56.038 51.155 59 48.667 59 45.29V14.55c0-2.532-1.76-4.845-4.43-5.184-2.518-.32-6.268-.584-10.234-.099-3.956.484-8.244 1.727-11.72 4.519a1.013 1.013 0 01-1.233 0zM9.808 12.342c2.374-.302 5.857-.542 9.491-.097 3.644.446 7.323 1.564 10.206 3.88a4.012 4.012 0 004.99 0c2.883-2.316 6.561-3.434 10.205-3.88 3.634-.445 7.117-.205 9.491.097C55.207 12.47 56 13.366 56 14.55v30.742c0 1.503-1.385 2.84-3.09 2.814-4.943-.076-13.152.549-18.864 5.06-1.104.872-2.988.872-4.092 0-5.712-4.511-13.921-5.136-18.865-5.06-1.704.026-3.09-1.31-3.09-2.815V14.55c0-1.184.793-2.08 1.809-2.208z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookOpenTextRegular)
