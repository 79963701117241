import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowRoundSyncForwardRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.46 2.778a5.25 5.25 0 015.16 2.727h-1.885a.75.75 0 000 1.5h3.515a.75.75 0 00.75-.75V2.128a.75.75 0 00-1.5 0v1.958A6.75 6.75 0 001.544 6.031a.75.75 0 101.435.437 5.25 5.25 0 014.48-3.69zM2.5 13.872a.75.75 0 01-1.5 0V9.745a.75.75 0 01.75-.75h3.516a.75.75 0 110 1.5H3.38l.019.033a5.25 5.25 0 009.621-.992.75.75 0 011.435.439A6.75 6.75 0 012.5 11.914v1.958z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.642 3.645a6.499 6.499 0 016.837 2.86H13a.75.75 0 000 1.5h4.25a.75.75 0 00.75-.75v-4.5a.75.75 0 00-1.5 0v2.583A7.999 7.999 0 002.044 9.181a.75.75 0 001.492.154 6.499 6.499 0 015.106-5.69zM7.356 15.936a6.498 6.498 0 009.106-5.251.75.75 0 011.492.158A7.998 7.998 0 013.5 14.662v2.583a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75H7a.75.75 0 010 1.5H4.521a6.498 6.498 0 002.835 2.441z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.276 3.782a8.25 8.25 0 018.072 4.468H15.5a.75.75 0 000 1.5H21a.75.75 0 00.75-.75V3.246a.75.75 0 00-1.5 0v3.558a9.75 9.75 0 00-17.821 3.338.75.75 0 001.472.286 8.25 8.25 0 017.375-6.646zM7.625 18.994a8.25 8.25 0 0012.466-5.383.75.75 0 111.471.293A9.75 9.75 0 013.75 17.196v3.558a.75.75 0 01-1.5 0V15a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5H4.652a8.25 8.25 0 002.973 3.244z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.683 5.08A11 11 0 0125.798 11H21.5a1 1 0 100 2h6a1.5 1.5 0 001.5-1.5V5a1 1 0 10-2 0v4.072a13 13 0 00-23.826 4.81 1 1 0 001.973.326 11 11 0 019.536-9.129zM10.51 25.532a11 11 0 0016.35-7.782 1 1 0 011.974.318A13 13 0 015 22.928V27a1 1 0 11-2 0v-6.5A1.5 1.5 0 014.5 19h6a1 1 0 110 2H6.202a11 11 0 004.307 4.532z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M27.12 9.524A23 23 0 0152.2 21h-10.7a1.5 1.5 0 100 3h12.75A2.75 2.75 0 0057 21.25V8a1.5 1.5 0 00-3 0v10.144A26 26 0 006.047 30.456a1.5 1.5 0 102.994.178 23 23 0 0118.08-21.11zM22.29 52.85a23 23 0 0032.665-19.407 1.5 1.5 0 112.994.188 26 26 0 01-47.95 12.225V56a1.5 1.5 0 01-3 0V42.75A2.75 2.75 0 019.75 40H22.5a1.5 1.5 0 010 3H11.802a22.999 22.999 0 0010.489 9.85z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowRoundSyncForwardRegular)
