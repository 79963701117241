import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeAUpRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.999a2 2 0 012 2v8.534c0 1.025-1.239 1.538-1.963.814l-1.85-1.85-2.7 2.7a1.75 1.75 0 01-2.475 0l-3.21-3.21a1.75 1.75 0 010-2.475l2.7-2.7-1.85-1.85C1.928 3.238 2.441 2 3.465 2H12zm.5 2a.5.5 0 00-.5-.5H4.31l1.783 1.782a.75.75 0 010 1.061l-3.23 3.23a.25.25 0 000 .354l3.21 3.21a.25.25 0 00.353 0l3.231-3.23a.75.75 0 011.06 0l1.783 1.782V4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 3A2.25 2.25 0 0117 5.25v10.176c0 1.203-1.454 1.805-2.305.955l-2.117-2.117-3.351 3.351a2 2 0 01-2.829 0l-4.013-4.014a2 2 0 010-2.828l3.351-3.351L3.62 5.305C2.769 4.455 3.37 3 4.573 3H14.75zm.75 2.25a.75.75 0 00-.75-.75H4.935l2.392 2.392a.75.75 0 010 1.06l-3.88 3.882a.5.5 0 000 .707l4.012 4.013a.5.5 0 00.707 0l3.882-3.881a.75.75 0 011.06 0l2.392 2.391V5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.124 3.249a2.625 2.625 0 012.625 2.625v12.814c0 1.336-1.615 2.006-2.56 1.06l-2.97-2.97-4.253 4.254a2.25 2.25 0 01-3.182 0l-4.816-4.817a2.25 2.25 0 010-3.181L7.221 8.78 4.25 5.811c-.945-.946-.276-2.561 1.06-2.561l12.814-.001zm1.125 2.625c0-.621-.503-1.125-1.125-1.125H5.31l3.502 3.502a.75.75 0 010 1.06l-4.783 4.783a.75.75 0 000 1.06l4.815 4.817a.75.75 0 001.061 0l4.784-4.783a.75.75 0 011.06 0l3.5 3.5V5.874z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 5A3.5 3.5 0 0127 8.5v16.085c0 1.782-2.154 2.674-3.415 1.414l-3.46-3.46-5.503 5.503a3 3 0 01-4.243 0l-6.42-6.422a3 3 0 010-4.242l5.502-5.503L6 8.415C4.74 7.154 5.632 5 7.414 5H23.5zM25 8.5A1.5 1.5 0 0023.5 7H7.414l4.168 4.168a1 1 0 010 1.414l-6.21 6.21a1 1 0 000 1.414l6.421 6.422a1 1 0 001.414 0l6.21-6.21a1 1 0 011.415 0L25 24.584V8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5 10a6.5 6.5 0 016.5 6.5v32.671c0 3.118-3.77 4.68-5.975 2.475L40.5 44.12l-11.61 11.61a5.5 5.5 0 01-7.779 0L8.271 42.888a5.5 5.5 0 010-7.777L19.879 23.5l-7.525-7.526C10.149 13.77 11.71 10 14.829 10H47.5zm3.5 6.5a3.5 3.5 0 00-3.5-3.5H14.83a.5.5 0 00-.354.854l8.586 8.586a1.5 1.5 0 010 2.12L10.392 37.233a2.5 2.5 0 000 3.535l12.84 12.843a2.5 2.5 0 003.536 0L39.44 40.94a1.5 1.5 0 012.121 0l8.586 8.585A.5.5 0 0051 49.17v-32.67z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeAUpRightRegular)
