import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { Input } from '@ui/Input'

import CardForm from '@lib/components/CardForm'
import useCardForm from '@lib/hooks/useCardForm'

import { useUserBasicInformationFormStore } from '@modules/user/store/UserBasicInformationFormStore'

const BasicInformation = () => {
  const store = useUserBasicInformationFormStore()

  const { handleFocus, handleSubmit, handleCancel, isEditing, isSaved } =
    useCardForm(store.handleSubmit, store.reset)

  useEffect(() => {
    store.reset()
  }, [])

  return (
    <CardForm
      title="Основная информация"
      direction="row"
      editable={isEditing}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      saved={isSaved}>
      <Input
        label="Имя"
        name="firstName"
        value={store.form.fields.firstName.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.firstName.error}
        errorText={store.form.fields.firstName.errorText}
      />
      <Input
        label="Фамилия"
        name="lastName"
        value={store.form.fields.lastName.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.lastName.error}
        errorText={store.form.fields.lastName.errorText}
      />
      <Input
        label="Отчество"
        name="patronymic"
        value={store.form.fields.patronymic.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.patronymic.error}
        errorText={store.form.fields.patronymic.errorText}
      />
    </CardForm>
  )
}

export default observer(BasicInformation)
