import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

export type AnimatedSlideType = 'backward' | 'forward' | 'none'

@singleton()
class AnimatedFormStore {
  private animated: AnimatedSlideType = 'none'

  constructor() {
    makeAutoObservable<AnimatedFormStore>(this)
  }

  setAnimatedState(state: AnimatedSlideType) {
    this.animated = state
  }

  get animatedState() {
    return this.animated
  }
}

export const useAnimatedFormStore = () => container.resolve(AnimatedFormStore)
