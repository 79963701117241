import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFoldersLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 7.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3.5A1.5 1.5 0 012.5 2h3a1.5 1.5 0 011.2.6l.9 1.2A.5.5 0 008 4h3.5A2.5 2.5 0 0114 6.5v5a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 011 11.5v-8zM2.5 3a.5.5 0 00-.5.5v8A1.5 1.5 0 003.5 13h8a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0011.5 5H8a1.5 1.5 0 01-1.2-.6l-.9-1.2a.5.5 0 00-.4-.2h-3z"
          fill="currentColor"
        />
        <path
          d="M16 6.5a.5.5 0 00-1 0V12a3 3 0 01-3 3H4.5a.5.5 0 000 1H12a4 4 0 004-4V6.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4 8.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.5A1.5 1.5 0 013.5 3h3.99c.48 0 .932.23 1.214.618l.855 1.176A.5.5 0 009.964 5H15a3 3 0 013 3v6a3 3 0 01-3 3H5a3 3 0 01-3-3V4.5zM3.5 4a.5.5 0 00-.5.5V14a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2H9.964a1.5 1.5 0 01-1.213-.618l-.856-1.176A.5.5 0 007.491 4H3.5z"
        fill="currentColor"
      />
      <path
        d="M20 8.5a.5.5 0 00-1 0v6a3.5 3.5 0 01-3.5 3.5h-10a.5.5 0 000 1h10a4.5 4.5 0 004.5-4.5v-6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFoldersLight)
