import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFolderSharedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.01 11.004c.254-.072.533-.092.752-.092.285 0 .671.034.97.171a.798.798 0 01.324.245c.067.093.12.223.12.422a.425.425 0 00.85 0 1.53 1.53 0 00-.284-.923 1.642 1.642 0 00-.656-.517c-.468-.214-1.001-.248-1.324-.248-.306 0-.802.03-1.251.217a2.127 2.127 0 01.499.725z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.819 6.59a1.41 1.41 0 100 2.82 1.41 1.41 0 000-2.82zM9.259 8a.56.56 0 111.119 0 .56.56 0 01-1.119 0z"
          fill="currentColor"
        />
        <path
          d="M4.271 11.41c-.087.107-.152.256-.152.487a.425.425 0 01-.85 0c0-.41.123-.753.342-1.023.214-.263.497-.43.777-.538.549-.211 1.181-.23 1.556-.23s1.008.019 1.557.23c.28.108.563.275.776.538.22.27.342.612.342 1.023a.425.425 0 01-.85 0c0-.231-.065-.38-.152-.487a.987.987 0 00-.422-.28c-.39-.15-.882-.174-1.25-.174-.37 0-.862.023-1.252.174a.987.987 0 00-.422.28z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.947 5.981a1.831 1.831 0 100 3.663 1.831 1.831 0 000-3.663zm-.981 1.832a.981.981 0 111.962 0 .981.981 0 01-1.962 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3.5A1.5 1.5 0 012.5 2h3a1.5 1.5 0 011.2.6l.9 1.2A.5.5 0 008 4h3.5A2.5 2.5 0 0114 6.5v5a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 011 11.5v-8zM2.5 3a.5.5 0 00-.5.5v8A1.5 1.5 0 003.5 13h8a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0011.5 5H8a1.5 1.5 0 01-1.2-.6l-.9-1.2a.5.5 0 00-.4-.2h-3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M11.594 13.082a3.5 3.5 0 01.78-.082c.326 0 .766.038 1.107.188a.891.891 0 01.361.264c.072.096.129.23.129.438a.5.5 0 001 0c0-.41-.119-.76-.331-1.041a1.883 1.883 0 00-.756-.576c-.534-.235-1.142-.273-1.51-.273-.329 0-.85.03-1.338.205a2.402 2.402 0 01.558.877z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.502 7.83a1.686 1.686 0 100 3.372 1.686 1.686 0 000-3.371zm-.686 1.686a.686.686 0 111.372 0 .686.686 0 01-1.372 0z"
        fill="currentColor"
      />
      <path
        d="M6.16 13.489c-.09.11-.16.266-.16.511a.5.5 0 01-1 0c0-.46.14-.844.388-1.146.242-.294.56-.48.874-.6C6.877 12.02 7.583 12 8 12c.417 0 1.123.02 1.738.254.313.12.632.306.874.6.248.302.388.686.388 1.146a.5.5 0 11-1 0c0-.245-.07-.4-.16-.511-.099-.12-.25-.22-.458-.3C8.953 13.025 8.409 13 8 13c-.41 0-.953.025-1.382.189-.208.08-.36.18-.457.3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.022 7.09a2.104 2.104 0 100 4.208 2.104 2.104 0 000-4.208zM6.918 9.194a1.104 1.104 0 112.208 0 1.104 1.104 0 01-2.208 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.5A1.5 1.5 0 013.5 3h3.99c.48 0 .932.23 1.214.618l.855 1.176A.5.5 0 009.964 5H15a3 3 0 013 3v6a3 3 0 01-3 3H5a3 3 0 01-3-3V4.5zM3.5 4a.5.5 0 00-.5.5V14a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2H9.964a1.5 1.5 0 01-1.213-.618l-.856-1.176A.5.5 0 007.491 4H3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFolderSharedLight)
