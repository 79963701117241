import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookClosedGovLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 4a.5.5 0 00-.5.5v3.25C5 8.44 5.56 9 6.25 9h.268l.613.67a.5.5 0 00.74-.003L8.474 9h.275C9.44 9 10 8.44 10 7.75V4.5a.5.5 0 00-.5-.5h-4zM6 7.75V5h3v2.75a.25.25 0 01-.25.25h-.377a.768.768 0 00-.57.252l-.305.337-.312-.34A.768.768 0 006.62 8h-.37A.25.25 0 016 7.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.25V13.5A1.5 1.5 0 003.5 15h9a.5.5 0 000-1h-9a.5.5 0 010-1h9a.5.5 0 00.5-.5V2a1 1 0 00-1-1H4.25A2.25 2.25 0 002 3.25zM4.25 2C3.56 2 3 2.56 3 3.25v8.835c.156-.055.325-.085.5-.085H12V2H4.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4a1 1 0 00-1 1v4.25c0 .966.783 1.75 1.75 1.75h.543l.853.854a.5.5 0 00.708 0l.853-.854h.543A1.75 1.75 0 0013 9.25V5a1 1 0 00-1-1H7zm0 1h5v4.25a.75.75 0 01-.75.75h-.543a1 1 0 00-.707.293l-.5.5-.5-.5A1 1 0 008.293 10H7.75A.75.75 0 017 9.25V5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.998 1a3 3 0 00-3 3v12a2 2 0 002 2h10.504a.5.5 0 100-1H4.998a1 1 0 110-2h9.254a1.75 1.75 0 001.75-1.75V2.75A1.75 1.75 0 0014.252 1H5.998zm-1 13a1.99 1.99 0 00-1 .268V4a2 2 0 012-2h8.254a.75.75 0 01.75.75v10.5a.75.75 0 01-.75.75H4.998z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookClosedGovLight)
