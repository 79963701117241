import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTextBulletNumbersLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.717 2.05A.5.5 0 014 2.5v4a.5.5 0 11-1 0V3.54l-.438.35a.5.5 0 01-.624-.78l1.25-1a.5.5 0 01.529-.06zM7.5 4a.5.5 0 000 1h6a.5.5 0 100-1h-6zM7.5 10a.5.5 0 000 1h6a.5.5 0 100-1h-6zM2.931 9.344c.097-.164.184-.24.255-.28A.615.615 0 013.5 9c.145 0 .272.05.357.127.076.07.147.187.147.396v.122c-.038.304-.268.462-.807.792C2.728 10.725 2 11.172 2 12.16v.341a.5.5 0 00.5.5h2.1a.5.5 0 000-1H3.015c.057-.276.277-.448.705-.71l.089-.054c.433-.262 1.112-.67 1.193-1.517a.5.5 0 00.002-.047v-.15c0-.47-.176-.863-.474-1.134A1.533 1.533 0 003.505 8a1.606 1.606 0 00-.806.191c-.261.146-.466.367-.63.647a.5.5 0 00.862.506z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.712 3.047A.5.5 0 015 3.5v5a.5.5 0 01-1 0V4.568l-.68.566a.5.5 0 11-.64-.768l1.5-1.25a.5.5 0 01.532-.069zM8.5 5a.5.5 0 100 1h8a.5.5 0 100-1h-8zM8.5 13a.5.5 0 000 1h8a.5.5 0 100-1h-8zM3.556 12.492c.13-.22.253-.333.365-.396A.892.892 0 014.375 12c.21.001.399.072.53.192.123.111.225.293.225.586v.16c-.053.457-.406.684-1.069 1.09-.59.362-1.436.888-1.436 2.046v.426a.5.5 0 00.5.5H5.75a.5.5 0 000-1H3.627c.03-.497.36-.753.957-1.12l.109-.065c.546-.331 1.34-.81 1.435-1.803a.501.501 0 00.002-.048v-.186c0-.556-.207-1.012-.552-1.326A1.791 1.791 0 004.38 11a1.883 1.883 0 00-.946.223c-.302.169-.543.427-.74.763a.5.5 0 00.862.506z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletNumbersLight)
