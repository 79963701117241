import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import TextPage from '@modules/support/components/TextPage/TextPage'

import { useSupportStore } from '../store/SupportStore'

const WarrantyPage = observer(() => {
  const store = useSupportStore()

  useEffect(() => {
    store.fetchWarranty()
  }, [])

  return <TextPage title="Гарантии" markup={store?.warranty} />
})

export default WarrantyPage
