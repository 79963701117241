import { AccentType } from '@ui/Select/types'

import { EOrderStatus } from '../enums'

export interface ITabs {
  currentTab: number
  disabledTabs: number[]
}

export interface IModals {
  cart: boolean
  existComplectation: boolean
}

export interface IAccessory {
  id: number
  key: string
  name: string
  fullName: string
  description: string
  photoUrl: string
  price: number
  active: boolean
  accent: AccentType[]
  type: IComponentsTypes['key']
}

export interface IComponent {
  accessories: IAccessory[]
  name: string
  value: string
}

export interface ISavedComponent {
  parent: string
  name: string
  value: string
  title: string
}

export interface IPlatformType {
  components: IComponent[]
  description: string
  title: string
  key: string
}

export interface IPlatform {
  key: string
  title: string
  types: IPlatformType[]
}

export interface IProductSeriesBase {
  id: number
  key: string
  name: string
  photoUrl: string
  shortDescription: string
  description: string
  sort: string
}

export interface IProductSeries extends IProductSeriesBase {
  platforms: IPlatform[]
}

export interface IProductTypeBase {
  id: number
  key: string
  name: string
  description: string
  photoUrl: string
}

export interface IProductType extends IProductTypeBase {
  types: IProductSeries[]
}

export interface ISavedPlatformTypes {
  [key: IPlatform['title']]: IComponent['accessories']
}

export interface ISavedPlatformValues {
  [key: IPlatform['title']]: ISavedComponent
}

export interface IComponentsTypes {
  id: string
  key: string
  name: string
}

export interface IMapAccessories {
  [key: IComponentsTypes['key']]: IAccessory[]
}

export interface IMapAccessory {
  [key: IComponentsTypes['key']]: IAccessory
}

export interface IProduct {
  productType?: IProductTypeBase
  productSeries?: IProductSeriesBase
  accessories?: IMapAccessory
  platforms?: ISavedPlatformValues
}

export interface IComplectation {
  hash: string
  count: number
  product: IProduct
}

export interface ICart {
  complectations: IComplectation[]
  comment?: string
}

export interface IInitData {
  tabs: ITabs
  cart: ICart
  accessories: IMapAccessories | undefined
  selectedData: IProduct
  platformTypes: ISavedPlatformTypes
}

export interface IOrderComplectation {
  id: number
  count: number
  components: IProduct
}

export interface IOrder {
  id: number
  created_at: string
  status: EOrderStatus
  comment: string
  complectations: IOrderComplectation[]
}

export type TLokalizedOrderStatus = {
  [key in EOrderStatus]: string
}

export const lokalizedOrderStatus: TLokalizedOrderStatus = {
  [EOrderStatus.PROCESSING]: 'В обработке',
  [EOrderStatus.SUCCESS]: 'Выполнено',
  [EOrderStatus.CANCEL]: 'Отменено',
} as const
