import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseCVClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.379 6.432a1.95 1.95 0 01-2.758 0L3.97 3.78a.75.75 0 011.06-1.06L7.682 5.37a.45.45 0 00.636 0L10.97 2.72a.75.75 0 011.06 1.06L9.38 6.432zM9.379 9.568a1.95 1.95 0 00-2.758 0L3.97 12.22a.75.75 0 001.06 1.06l2.652-2.651a.45.45 0 01.636 0l2.652 2.651a.75.75 0 001.06-1.06L9.38 9.568z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.803 8.258a2.55 2.55 0 01-3.606 0L4.22 4.28a.75.75 0 011.06-1.06l3.978 3.977c.41.41 1.074.41 1.485 0L14.72 3.22a.75.75 0 111.06 1.06l-3.977 3.978zM11.803 11.742a2.55 2.55 0 00-3.606 0L4.22 15.72a.75.75 0 101.06 1.06l3.978-3.977a1.05 1.05 0 011.485 0l3.977 3.977a.75.75 0 101.06-1.06l-3.977-3.978z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.68 10.058a2.375 2.375 0 01-3.36 0l-5.689-5.69A.875.875 0 115.87 3.132l5.689 5.69c.244.244.64.244.884 0l5.689-5.69a.875.875 0 111.237 1.238l-5.689 5.689zM13.68 13.942a2.375 2.375 0 00-3.36 0l-5.689 5.69a.875.875 0 101.238 1.237l5.689-5.69a.625.625 0 01.884 0l5.689 5.69a.875.875 0 101.237-1.238l-5.689-5.689z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18.475 12.94a3.5 3.5 0 01-4.95 0L5.293 4.706a1 1 0 011.414-1.414l8.232 8.232a1.5 1.5 0 002.122 0l8.232-8.232a1 1 0 111.414 1.414l-8.232 8.232zM18.475 19.06a3.5 3.5 0 00-4.95 0l-8.232 8.233a1 1 0 101.414 1.414l8.232-8.232a1.5 1.5 0 012.122 0l8.232 8.232a1 1 0 001.414-1.414l-8.232-8.232z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M37.303 24.818a7.5 7.5 0 01-10.606 0L10.439 8.561a1.5 1.5 0 012.122-2.121l16.257 16.257a4.5 4.5 0 006.364 0L51.44 6.44a1.5 1.5 0 112.12 2.12L37.304 24.819zM37.303 39.182a7.5 7.5 0 00-10.606 0L10.439 55.439a1.5 1.5 0 002.122 2.121l16.257-16.257a4.5 4.5 0 016.364 0L51.44 57.56a1.5 1.5 0 102.12-2.12L37.304 39.181z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseCVClosedRegular)
