import { memo, useCallback, useState } from 'react'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
import { ITooltipProps } from './types'

const Tooltip = ({
  children,
  text,
  className,
  position = 'center',
  direction = 'top',
}: ITooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const onMouseEnter = useCallback(() => setShowTooltip(true), [])

  const onMouseLeave = useCallback(() => setShowTooltip(false), [])

  const classNames = cx(
    styles.text,
    styles[direction],
    styles[`${direction}_${position}`],
  )

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(styles.tooltip, styles[direction], className)}>
      <div className={styles.content}>{children}</div>
      {showTooltip && <div className={classNames}>{text}</div>}
    </div>
  )
}

export default memo(Tooltip)
