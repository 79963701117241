import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import FailCard from '@lib/components/FailCard'
import { EMainRoutes } from '@lib/navigation'

import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

const FailTransactionCard = () => {
  const navigate = useNavigate()
  const transactionFormStore = useRegisterTransactionFormStore()

  const handleClick = useCallback(() => {
    transactionFormStore.clear()
    navigate(EMainRoutes.SalesRegisterTransaction)
  }, [])

  return (
    <FailCard
      description="Произошла непредвиденная ошибка!"
      titleButton="Вернуться к регистрации сделки"
      handleClick={handleClick}
      fullSize={false}
    />
  )
}

export default memo(FailTransactionCard)
