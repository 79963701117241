import { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Divider from '@ui/Divider'
import { TextArea } from '@ui/TextArea'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { getCostOfAccessories, getCurrencyString } from '@lib/helpers'

import { ICart } from '@modules/configurator/models/Configurator'

import styles from './OrderSummary.module.scss'

interface IOrderSummary {
  cart: ICart
  onChangeComment: (value: string) => void
}

const OrderSummary = ({ cart, onChangeComment }: IOrderSummary) => {
  const count = cart.complectations.reduce((acc, cur) => (acc += cur.count), 0)

  const total = cart.complectations.reduce(
    (acc, cur) =>
      (acc += getCostOfAccessories(cur.product.accessories, cur.count)),
    0,
  )

  const handleChangeTextArea = useCallback((e) => {
    onChangeComment(e.target.value)
  }, [])

  return (
    <div className={styles.container}>
      <CardWrapper px={20} py={20} fullWidth>
        <div className={styles.header}>
          <Typography size={16}>Ваш заказ ({count})</Typography>

          <Typography>{getCurrencyString(total)}</Typography>
        </div>

        <Divider className={styles.divider} />

        <div className={styles.comment}>
          <Typography size={16} weight="medium">
            Комментарий к заказу
          </Typography>
          <TextArea
            value={cart.comment}
            onChange={handleChangeTextArea}
            placeholder="Комментарий"
          />
        </div>

        <div className={styles.total}>
          <Typography size={18} weight="medium">
            Итого
          </Typography>
          <Typography size={18} weight="medium">
            {getCurrencyString(total)}
          </Typography>
        </div>
      </CardWrapper>
    </div>
  )
}

export default observer(OrderSummary)
