type Message = {
  rule: string
  message: string
}

/**
 * Тип поля формы.
 *
 * @prop value Значение.
 * @prop error Ошибка.
 * @prop rule Правило.
 * @prop label Label для полей ввода.
 * @prop messages Кастомные сообщения.
 */

export interface ISharedFormField {
  errorText: string
  error: boolean
  rule: string
  label: string
  messages: Message[]
}

export type FieldValueType = 'number' | 'string' | 'boolean'
export interface IBooleanFormField extends ISharedFormField {
  valueType: 'boolean'
  value: boolean
}

export interface INumberFormField extends ISharedFormField {
  valueType: 'number'
  value?: number
}

export interface IStringFormField extends ISharedFormField {
  valueType?: 'string'
  value: string
}

export type IFormField = IBooleanFormField | INumberFormField | IStringFormField

export interface IFormMeta {
  fetchStatus: EStatusProcess
}

/**
 * Статусы процессов.
 *
 * IDLE - Простой.
 * RUNNING - В процессе.
 * SUCCESS - Успех.
 * FAIL - Ошибка.
 */
export enum EStatusProcess {
  IDLE = 'IDLE',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}
