import React, { FC, memo, useCallback } from 'react'
import InputMask from 'react-input-mask'

import { IconXRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
import { IInputProps } from './types'

/** Компонент инпута */
const Input: FC<IInputProps> = ({
  className,
  label,
  type = 'text',
  contentSize = 'medium',
  labelWeight = 'regular',
  labelSize = 16,
  state = 'default',
  disabled = false,
  rightIcon,
  leftIcon,
  id,
  error = false,
  errorText = '',
  mask,
  value,
  clearButton = false,
  maskPlaceholder = ' ',
  onChange,
  name,
  ref,
  ...rest
}) => {
  const classNames = cx(styles.inputContainer, className)
  const inputClass = cx(
    styles.input,
    clearButton && styles.inputWithClearButton,
    styles[state],
    error && styles.error,
    rightIcon && styles.inputRightIcon,
    leftIcon && styles.inputLeftIcon,
    styles[contentSize],
  )

  const leftIconClass = cx(styles.leftIcon, styles[contentSize])
  const rightIconClass = cx(styles.rightIcon, styles[contentSize])

  const onClear = useCallback(() => {
    onChange({ target: { value: '', name } })
  }, [onChange, name])

  return (
    <div className={classNames}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          <Typography size={labelSize} weight={labelWeight}>
            {label}
          </Typography>
        </label>
      )}
      <div className={styles.inputWrapper}>
        {leftIcon && <div className={leftIconClass}>{leftIcon}</div>}
        {mask ? (
          <InputMask
            id={id}
            className={inputClass}
            disabled={disabled}
            type={type}
            mask={mask}
            inputRef={ref}
            value={value}
            maskPlaceholder={maskPlaceholder}
            name={name}
            onChange={onChange}
            {...rest}
          />
        ) : (
          <input
            id={id}
            className={inputClass}
            disabled={disabled}
            type={type}
            ref={ref}
            value={value}
            name={name}
            onChange={onChange}
            {...rest}
          />
        )}
        <div role="button" className={rightIconClass}>
          {clearButton && value && (
            <IconXRegular
              onClick={onClear}
              adaptive={false}
              className={styles.iconPointer}
              size={20}
            />
          )}
          {rightIcon}
        </div>
      </div>
      {error && errorText && (
        <Typography
          weight={labelWeight}
          size={labelSize}
          color={COLORS.STATE.ATTENTION_P}>
          {errorText}
        </Typography>
      )}
    </div>
  )
}

export default memo(Input)
