import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFolderCheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.268 7.842a.5.5 0 10-.707-.708L6.68 10.016 5.354 8.69a.5.5 0 00-.708.707l1.326 1.326a1 1 0 001.415 0l2.881-2.881z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 2A1.5 1.5 0 001 3.5v8A2.5 2.5 0 003.5 14h8a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0011.5 4H8a.5.5 0 01-.4-.2l-.9-1.2A1.5 1.5 0 005.5 2h-3zM2 3.5a.5.5 0 01.5-.5h3a.5.5 0 01.4.2l.9 1.2A1.5 1.5 0 008 5h3.5A1.5 1.5 0 0113 6.5v5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 11.5v-8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.957 9.134a.5.5 0 00-.707-.707l-3.987 3.988a.75.75 0 01-1.061 0l-1.598-1.599a.5.5 0 10-.708.707l1.599 1.599a1.75 1.75 0 002.475 0l3.987-3.988z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3A1.5 1.5 0 002 4.5V14a3 3 0 003 3h10a3 3 0 003-3V8a3 3 0 00-3-3H9.964a.5.5 0 01-.405-.206l-.855-1.176A1.5 1.5 0 007.49 3H3.5zM3 4.5a.5.5 0 01.5-.5h3.99a.5.5 0 01.405.206l.856 1.176A1.5 1.5 0 009.964 6H15a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2V4.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFolderCheckLight)
