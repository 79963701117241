import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * @param   onSubmit        Колбек, который выполнится при сохранении формы
 * @param   onCancel        Колбек, который выполнится при отмене формы
 * @param   {number} delay  Задержка
 * @return  [ handleFocus, handleSubmit, handleCancel, isEditing, isSaved ]
 */

const useCardForm = (
  onSubmit: () => void,
  onCancel: () => void,
  delay = 1000,
) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const timeoutRef = useRef(null)

  const handleFocus = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleSubmit = useCallback(() => {
    if (isEditing) {
      onSubmit()
      setIsEditing(false)
      setIsSaved(true)
    }
  }, [isEditing])

  const handleCancel = useCallback(() => {
    if (isEditing) {
      setIsEditing(false)
      onCancel()
    }
  }, [isEditing])

  useEffect(() => {
    if (isSaved) {
      timeoutRef.current = setTimeout(() => {
        setIsSaved(false)
      }, delay)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [isSaved])

  return {
    handleFocus,
    handleSubmit,
    handleCancel,
    isEditing,
    isSaved,
  }
}

export default useCardForm
