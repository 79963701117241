import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoYoutubeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.726 5.385a.5.5 0 00-.73.444v4.342a.5.5 0 00.73.444l4.181-2.171a.5.5 0 000-.888l-4.181-2.17zm.27 3.963V6.652L9.592 8 6.996 9.348z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.968 3c-.96 0-1.723 0-2.329.072-.623.073-1.143.229-1.579.586a2.9 2.9 0 00-.402.402c-.357.436-.513.956-.586 1.58C1 6.244 1 7.008 1 7.967v.064c0 .96 0 1.723.072 2.329.073.623.229 1.143.586 1.579.12.147.255.281.402.402.436.357.956.513 1.58.586C4.244 13 5.008 13 5.967 13h4.065c.959 0 1.723 0 2.328-.072.623-.073 1.143-.229 1.58-.586.146-.12.28-.255.401-.402.358-.436.513-.956.587-1.58C15 9.756 15 8.992 15 8.033v-.064c0-.96 0-1.723-.071-2.329-.074-.623-.23-1.143-.587-1.579a2.896 2.896 0 00-.402-.402c-.436-.357-.956-.513-1.579-.586C11.756 3 10.991 3 10.033 3H5.968zM2.695 4.431c.228-.187.533-.304 1.062-.366C4.297 4 5.002 4 6 4h4c.999 0 1.704 0 2.244.065.529.062.834.18 1.062.366.096.08.184.167.263.264.187.228.304.533.367 1.062C14 6.297 14 7.002 14 8c0 .998 0 1.703-.064 2.243-.063.53-.18.834-.367 1.062a1.89 1.89 0 01-.263.264c-.228.187-.533.304-1.062.366C11.704 12 10.999 12 10 12H6c-.998 0-1.703 0-2.243-.065-.53-.062-.834-.18-1.062-.366a1.892 1.892 0 01-.264-.264c-.187-.228-.304-.533-.366-1.062C2 9.703 2 8.998 2 8c0-.998 0-1.703.065-2.243.062-.53.18-.834.366-1.062a1.9 1.9 0 01.264-.264z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.707 7.064A.5.5 0 007 7.519v5a.5.5 0 00.707.455l5.5-2.5a.5.5 0 000-.91l-5.5-2.5zM8 11.743V8.296l3.792 1.723L8 11.743z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.969 4c-1.147 0-2.05 0-2.765.08-.732.083-1.33.255-1.835.643a3.5 3.5 0 00-.646.646c-.388.506-.56 1.103-.642 1.835C2 7.92 2 8.822 2 9.97v.062c0 1.147 0 2.05.08 2.765.083.732.255 1.33.643 1.835.186.242.404.46.646.646.506.388 1.103.56 1.835.643.716.08 1.618.08 2.765.08h4.062c1.147 0 2.05 0 2.765-.08.732-.083 1.33-.255 1.835-.643a3.5 3.5 0 00.646-.646c.388-.506.56-1.103.642-1.835C18 12.08 18 11.178 18 10.03V9.97c0-1.147 0-2.05-.08-2.765-.083-.732-.255-1.33-.643-1.835a3.501 3.501 0 00-.646-.646c-.506-.388-1.103-.56-1.835-.642C14.08 4 13.178 4 12.03 4H7.97zm-3.99 1.517c.301-.232.694-.37 1.337-.443C5.97 5.001 6.816 5 8 5h4c1.184 0 2.031 0 2.684.074.643.073 1.036.21 1.338.443.173.133.329.288.462.461.231.302.37.695.442 1.338.073.653.074 1.5.074 2.684 0 1.184 0 2.031-.074 2.684-.073.643-.21 1.036-.442 1.338a2.501 2.501 0 01-.462.461c-.302.232-.695.37-1.338.443-.653.073-1.5.074-2.684.074H8c-1.184 0-2.03 0-2.684-.074-.643-.073-1.036-.21-1.338-.443a2.5 2.5 0 01-.461-.46c-.232-.303-.37-.696-.443-1.34C3.001 12.032 3 11.186 3 10c0-1.184 0-2.03.074-2.684.073-.643.21-1.036.443-1.338a2.5 2.5 0 01.461-.461z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoYoutubeLight)
