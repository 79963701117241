import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowUiShareAExportRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.03.47a.75.75 0 10-1.06 1.06l1.72 1.72h-2.74a4.7 4.7 0 00-4.7 4.7V8a.75.75 0 001.5 0v-.05a3.2 3.2 0 013.2-3.2h2.74l-1.72 1.72a.75.75 0 001.06 1.06l2.293-2.293a1.75 1.75 0 000-2.474L13.03.47z"
          fill="currentColor"
        />
        <path
          d="M4.5 2.75A1.75 1.75 0 002.75 4.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75V10a.75.75 0 011.5 0v1.5a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7A3.25 3.25 0 014.5 1.25h1.75a.75.75 0 010 1.5H4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.03 1.97a.75.75 0 10-1.06 1.06l2.22 2.221H13.5a5.25 5.25 0 00-5.25 5.25v.169a.75.75 0 001.5 0v-.168a3.75 3.75 0 013.75-3.75h3.69l-2.22 2.22a.75.75 0 001.06 1.06l2.794-2.793a1.75 1.75 0 000-2.475L16.03 1.97z"
          fill="currentColor"
        />
        <path
          d="M6 3.75A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14v-1a.75.75 0 011.5 0v1A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6A3.75 3.75 0 016 2.25h2a.75.75 0 010 1.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.78 1.97a.75.75 0 10-1.06 1.06l3.22 3.22H16.5a6.25 6.25 0 00-6.25 6.25v.5a.75.75 0 001.5 0v-.5a4.75 4.75 0 014.75-4.75h5.44l-3.22 3.22a.75.75 0 101.06 1.06l3.44-3.439a2.25 2.25 0 000-3.182l-3.44-3.44z"
          fill="currentColor"
        />
        <path
          d="M6.5 3.75A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-1.75a.75.75 0 011.5 0v1.75a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11A4.25 4.25 0 016.5 2.25h4.75a.75.75 0 010 1.5H6.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.5 5A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5V21a1 1 0 112 0v2.5a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15A5.5 5.5 0 018.5 3H15a1 1 0 110 2H8.5z"
          fill="currentColor"
        />
        <path
          d="M21 11a6 6 0 00-6 6v1a1 1 0 11-2 0v-1a8 8 0 018-8h7.586l-4.293-4.293a1 1 0 011.414-1.414l4.586 4.586a3 3 0 010 4.242l-4.586 4.586a1 1 0 01-1.414-1.414L28.586 11H21z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M51.06 6.44a1.5 1.5 0 00-2.12 2.12l9.17 9.172c.086.085.163.175.233.268H42c-8.56 0-15.5 6.94-15.5 15.5v2a1.5 1.5 0 003 0v-2C29.5 26.596 35.096 21 42 21h16.343c-.07.093-.147.183-.232.268l-9.172 9.171a1.5 1.5 0 002.122 2.122l9.171-9.172a5.5 5.5 0 000-7.778l-9.171-9.172z"
        fill="currentColor"
      />
      <path
        d="M16.5 10A7.5 7.5 0 009 17.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5V41a1.5 1.5 0 013 0v5.5C56 52.299 51.299 57 45.5 57h-29C10.701 57 6 52.299 6 46.5v-29C6 11.701 10.701 7 16.5 7H29a1.5 1.5 0 010 3H16.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiShareAExportRegular)
