import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCalendarRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 11.048a.75.75 0 100-1.501.75.75 0 000 1.501zM8.75 10.297a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM11 11.048a.75.75 0 100-1.501.75.75 0 000 1.501zM5.75 7.673a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM8 8.423a.75.75 0 100-1.501.75.75 0 000 1.501zM11.751 7.673a.75.75 0 11-1.501 0 .75.75 0 011.501 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.85 2a.85.85 0 00-1.7 0v1H4a2.75 2.75 0 00-2.75 2.75v5.5A2.75 2.75 0 004 14h8a2.75 2.75 0 002.75-2.75v-5.5A2.75 2.75 0 0012 3h-.15V2a.85.85 0 10-1.7 0v1h-4.3V2zm7.4 4H2.75v5.25c0 .69.56 1.25 1.25 1.25h8c.69 0 1.25-.56 1.25-1.25V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.75 13.85a.75.75 0 11-1.5-.001.75.75 0 011.5 0zM10 14.6a.75.75 0 100-1.501.75.75 0 000 1.5zM13.751 13.85a.75.75 0 11-1.5-.001.75.75 0 011.5 0zM7 11.89a.75.75 0 100-1.5.75.75 0 000 1.5zM10.75 11.14a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM13 11.89a.75.75 0 10.001-1.5.75.75 0 000 1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2a.75.75 0 01.75.75v1.5h4.5v-1.5a.75.75 0 011.5 0v1.5H15A2.75 2.75 0 0117.75 7v8A2.75 2.75 0 0115 17.75H5A2.75 2.75 0 012.25 15V7A2.75 2.75 0 015 4.25h1.25v-1.5A.75.75 0 017 2zm9.25 5.25V7c0-.69-.56-1.25-1.25-1.25H5c-.69 0-1.25.56-1.25 1.25v.25h12.5zm-12.5 1.5h12.5V15c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V8.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7 15a1 1 0 100-2 1 1 0 000 2zM8 17a1 1 0 11-2 0 1 1 0 012 0zM10.35 15a1 1 0 100-2 1 1 0 000 2zM11.35 17a1 1 0 11-2 0 1 1 0 012 0zM13.65 15a1 1 0 100-2 1 1 0 000 2zM14.65 17a1 1 0 11-2 0 1 1 0 012 0zM17 15a1 1 0 100-2 1 1 0 000 2zM18 17a1 1 0 11-2 0 1 1 0 012 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 2.5a.75.75 0 00-1.5 0v1.75H6A3.75 3.75 0 002.25 8v10A3.75 3.75 0 006 21.75h12A3.75 3.75 0 0021.75 18V8A3.75 3.75 0 0018 4.25h-1.25V2.5a.75.75 0 00-1.5 0v1.75h-6.5V2.5zm11.5 6.75V8A2.25 2.25 0 0018 5.75H6A2.25 2.25 0 003.75 8v1.25h16.5zm-16.5 1.5h16.5V18A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18v-7.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.323 17.5a1.073 1.073 0 11-2.146 0 1.073 1.073 0 012.146 0zM9.25 23.073a1.073 1.073 0 100-2.146 1.073 1.073 0 000 2.146zM14.824 17.5a1.074 1.074 0 11-2.148 0 1.074 1.074 0 012.148 0zM13.75 23.074a1.074 1.074 0 100-2.148 1.074 1.074 0 000 2.148zM19.323 17.5a1.073 1.073 0 11-2.146 0 1.073 1.073 0 012.146 0zM22.75 18.573a1.073 1.073 0 100-2.146 1.073 1.073 0 000 2.146zM19.323 22a1.073 1.073 0 11-2.146 0 1.073 1.073 0 012.146 0zM22.75 23.073a1.073 1.073 0 100-2.146 1.073 1.073 0 000 2.146z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.5a1 1 0 011 1V5h10V2.5a1 1 0 112 0V5h1.5a5.5 5.5 0 015.5 5.5v13a5.5 5.5 0 01-5.5 5.5h-17A5.5 5.5 0 012 23.5v-13A5.5 5.5 0 017.5 5H9V2.5a1 1 0 011-1zM7.5 7A3.5 3.5 0 004 10.5v.5h24v-.5A3.5 3.5 0 0024.5 7h-17zM4 13h24v10.5a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 23.5V13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M21.5 6A1.5 1.5 0 0123 7.5v8a1.5 1.5 0 01-3 0v-8A1.5 1.5 0 0121.5 6zM26 10h12v3H26v-3z"
        fill="currentColor"
      />
      <path
        d="M47.5 13H47v-3h.5C53.299 10 58 14.701 58 20.5v25C58 51.299 53.299 56 47.5 56h-31C10.701 56 6 51.299 6 45.5v-25C6 14.701 10.701 10 16.5 10h.5v3h-.5A7.5 7.5 0 009 20.5v25a7.5 7.5 0 007.5 7.5h31a7.5 7.5 0 007.5-7.5v-25a7.5 7.5 0 00-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="M16 23a1.5 1.5 0 000 3h32a1.5 1.5 0 000-3H16zM44 7.5a1.5 1.5 0 00-3 0v8a1.5 1.5 0 003 0v-8zM20.5 35a2 2 0 11-4 0 2 2 0 014 0zM18.5 46a2 2 0 100-4 2 2 0 000 4zM29.5 35a2 2 0 11-4 0 2 2 0 014 0zM27.5 46a2 2 0 100-4 2 2 0 000 4zM38.5 35a2 2 0 11-4 0 2 2 0 014 0zM45.5 37a2 2 0 100-4 2 2 0 000 4zM38.5 44a2 2 0 11-4 0 2 2 0 014 0zM45.5 46a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCalendarRegular)
