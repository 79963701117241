import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconAttachPaperclipLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.07 2.571a2.888 2.888 0 114.085 4.085l-6.202 6.202A4.083 4.083 0 112.18 7.084l4.316-4.317a.5.5 0 11.707.708L2.887 7.79a3.083 3.083 0 004.36 4.36l6.2-6.202a1.888 1.888 0 10-2.67-2.67L4.674 9.382a.694.694 0 00.981.982l4.219-4.219a.5.5 0 11.707.707l-4.218 4.219a1.694 1.694 0 01-2.396-2.396L10.07 2.57z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.45 3.956a3.245 3.245 0 014.59 4.589l-7.128 7.128a4.618 4.618 0 01-6.53-6.53l4.96-4.962a.5.5 0 01.708.707L4.089 9.85a3.618 3.618 0 105.116 5.117l7.128-7.128a2.245 2.245 0 10-3.175-3.175l-7.015 7.015a.872.872 0 101.234 1.234l4.848-4.849a.5.5 0 11.707.707L8.084 13.62a1.873 1.873 0 11-2.648-2.648l7.014-7.015z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachPaperclipLight)
