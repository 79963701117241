import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseTrianglesVClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.237 6.573a1.75 1.75 0 01-2.475 0L3.811 3.622C3.212 3.023 3.636 2 4.483 2h7.034c.846 0 1.27 1.023.672 1.622L9.237 6.573zm-1.414-1.06a.25.25 0 00.354 0L10.189 3.5H5.81l2.013 2.013zM9.237 9.427a1.75 1.75 0 00-2.475 0l-2.951 2.951c-.599.599-.175 1.622.672 1.622h7.034c.846 0 1.27-1.023.672-1.622L9.237 9.427zm-1.414 1.06a.25.25 0 01.354 0l2.012 2.013H5.81l2.013-2.013z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.591 8.47a2.25 2.25 0 01-3.182 0L5.073 5.134C4.286 4.346 4.843 3 5.957 3h8.086c1.114 0 1.671 1.346.884 2.134L11.59 8.47zM9.47 7.409a.75.75 0 001.06 0L13.44 4.5H6.56l2.91 2.909zM11.591 11.53a2.25 2.25 0 00-3.182 0l-3.336 3.336c-.787.787-.23 2.134.884 2.134h8.086c1.114 0 1.671-1.346.884-2.134L11.59 11.53zM9.47 12.591a.75.75 0 011.06 0l2.91 2.909H6.56l2.91-2.909z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.679 10.309a2.375 2.375 0 01-3.359 0L4.824 4.814c-.992-.992-.29-2.689 1.114-2.689h12.123c1.404 0 2.106 1.697 1.114 2.689l-5.496 5.495zM11.558 9.07c.244.244.64.244.883 0l5.198-5.196H6.36l5.197 5.196zM13.679 13.691a2.375 2.375 0 00-3.359 0l-5.496 5.495c-.992.992-.29 2.689 1.114 2.689h12.123c1.404 0 2.106-1.697 1.114-2.689l-5.496-5.495zm-2.121 1.238a.625.625 0 01.883 0l5.198 5.196H6.36l5.197-5.196z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.475 13.94a3.5 3.5 0 01-4.95 0L6 6.413C4.74 5.154 5.632 3 7.414 3h17.172C26.368 3 27.26 5.154 26 6.414l-7.525 7.525zm-3.536-1.415a1.5 1.5 0 002.122 0L24.586 5H7.414l7.525 7.525zM18.475 18.06a3.5 3.5 0 00-4.95 0L6 25.587C4.74 26.846 5.632 29 7.414 29h17.172c1.782 0 2.674-2.154 1.414-3.414l-7.525-7.525zm-3.536 1.415a1.5 1.5 0 012.122 0L24.586 27H7.414l7.525-7.525z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.889 27.233a5.5 5.5 0 01-7.778 0L12.853 11.975C10.65 9.77 12.21 6 15.328 6h33.344c3.118 0 4.68 3.77 2.474 5.975L35.89 27.233zm-5.657-2.122a2.5 2.5 0 003.536 0L49.025 9.854A.5.5 0 0048.672 9H15.328a.5.5 0 00-.353.854L30.232 25.11zM35.889 36.767a5.5 5.5 0 00-7.778 0L12.853 52.025C10.65 54.23 12.21 58 15.328 58h33.344c3.118 0 4.68-3.77 2.474-5.975L35.89 36.767zm-5.657 2.122a2.5 2.5 0 013.536 0l15.257 15.257a.5.5 0 01-.353.854H15.328a.5.5 0 01-.353-.854L30.232 38.89z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesVClosedRegular)
