import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMathFunctionRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.181 7v3.096a2.393 2.393 0 01-2.393 2.393h-.055a.75.75 0 000 1.5h.055a3.893 3.893 0 003.893-3.893V7H8.75a.75.75 0 000-1.5H6.715a2.394 2.394 0 012.36-1.989h.186a.75.75 0 000-1.5h-.187A3.893 3.893 0 005.202 5.5H3.626a.75.75 0 000 1.5h1.555z"
          fill="currentColor"
        />
        <path
          d="M13.843 9.454a.75.75 0 10-1.06-1.06l-1.627 1.626-1.625-1.626a.75.75 0 00-1.061 1.06l1.626 1.626-1.626 1.626a.75.75 0 001.06 1.06l1.627-1.625 1.625 1.626a.75.75 0 001.061-1.06l-1.626-1.627 1.626-1.626z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.206 3.75A2.688 2.688 0 007.52 6.438v1.095h2.91a.75.75 0 010 1.5H7.52v4.508a4.187 4.187 0 01-4.188 4.187h-.556a.75.75 0 110-1.5h.556a2.687 2.687 0 002.688-2.687V9.033H3.773a.75.75 0 010-1.5H6.02V6.437a4.188 4.188 0 014.187-4.187h.89a.75.75 0 010 1.5h-.89zM17.229 11.72a.75.75 0 10-1.06-1.06l-2.272 2.27-2.271-2.27a.75.75 0 10-1.061 1.06l2.271 2.271-2.271 2.271a.75.75 0 001.06 1.061l2.272-2.271 2.271 2.271a.75.75 0 001.061-1.06l-2.271-2.272 2.271-2.271z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.33 4.556a3.375 3.375 0 00-3.376 3.375V9.25h3.542a.75.75 0 010 1.5H8.954v5.319a4.875 4.875 0 01-4.875 4.875h-.557a.75.75 0 110-1.5h.557a3.375 3.375 0 003.375-3.375V10.75H4.693a.75.75 0 010-1.5h2.761V7.931a4.875 4.875 0 014.875-4.875h.947a.75.75 0 110 1.5h-.947zM20.376 13.938a.75.75 0 00-1.06-1.06l-2.755 2.754-2.754-2.755a.75.75 0 00-1.06 1.06l2.754 2.755-2.755 2.754a.75.75 0 001.06 1.061l2.755-2.754 2.754 2.754a.75.75 0 001.061-1.06l-2.754-2.755 2.754-2.754z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.75 6a4.5 4.5 0 00-4.5 4.5V12h4.5a1 1 0 110 2h-4.5v6.5a6.5 6.5 0 01-6.5 6.5h-.5a1 1 0 110-2h.5a4.5 4.5 0 004.5-4.5V14h-3.5a1 1 0 110-2h3.5v-1.5a6.5 6.5 0 016.5-6.5h1a1 1 0 110 2h-1zM26.509 18.261a1 1 0 00-1.414-1.414l-3.502 3.502-3.502-3.502a1 1 0 00-1.415 1.414l3.502 3.502-3.502 3.502a1 1 0 001.415 1.414l3.502-3.502 3.502 3.502a1 1 0 001.414-1.414l-3.502-3.502 3.502-3.502z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M33.5 12a9.5 9.5 0 00-9.5 9.5V25h9.5a1.5 1.5 0 110 3H24v13.5C24 48.404 18.404 54 11.5 54h-1a1.5 1.5 0 010-3h1a9.5 9.5 0 009.5-9.5V28h-7.5a1.5 1.5 0 010-3H21v-3.5C21 14.597 26.596 9 33.5 9h2a1.5 1.5 0 010 3h-2zM52.664 36.168a1.5 1.5 0 10-2.121-2.12l-7.358 7.356-7.357-7.357a1.5 1.5 0 10-2.121 2.121l7.357 7.358-7.357 7.357a1.5 1.5 0 102.12 2.122l7.358-7.358 7.358 7.358a1.5 1.5 0 102.121-2.122l-7.357-7.357 7.357-7.358z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathFunctionRegular)
