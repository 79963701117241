import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMathSumRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.41 3.912C2.67 3.235 3.149 2 4.154 2h8.097a.75.75 0 010 1.5H5.184l4.039 3.688a1.1 1.1 0 010 1.624L5.183 12.5h7.067a.75.75 0 010 1.5H4.153c-1.005 0-1.484-1.235-.742-1.912L7.888 8 3.41 3.912z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.487 4.624C2.452 3.709 3.099 2 4.481 2H15.25a.75.75 0 010 1.5H4.48l6.081 5.376a1.5 1.5 0 010 2.248L4.481 16.5H15.25a.75.75 0 010 1.5H4.48c-1.38 0-2.028-1.709-.993-2.624L9.567 10l-6.08-5.376z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.657 5.347C3.395 4.302 4.135 2.25 5.774 2.25H20a.75.75 0 010 1.5H5.774a.25.25 0 00-.16.442l7.796 6.46a1.75 1.75 0 010 2.696l-7.796 6.46a.25.25 0 00.16.442H20a.75.75 0 010 1.5H5.774c-1.64 0-2.379-2.052-1.117-3.098l7.796-6.46a.25.25 0 000-.385l-7.796-6.46z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.846 8.25C5.117 6.813 6.132 4.001 8.38 4.001H25a1 1 0 010 2H8.38a.4.4 0 00-.256.708l8.968 7.443a2.4 2.4 0 010 3.694L8.123 25.29a.4.4 0 00.256.708H25a1 1 0 010 2H8.38c-2.247 0-3.262-2.812-1.533-4.247l8.968-7.443a.4.4 0 000-.616L6.846 8.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.623 14.994C9.333 12.324 11.22 7 15.46 7h37.04a1.5 1.5 0 110 3H15.46c-1.413 0-2.043 1.775-.945 2.665l19.523 15.84a4.5 4.5 0 010 6.99l-19.523 15.84c-1.098.89-.468 2.665.945 2.665h37.04a1.5 1.5 0 110 3H15.46c-4.239 0-6.127-5.324-2.836-7.994l19.524-15.841a1.5 1.5 0 000-2.33l-19.524-15.84z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathSumRegular)
