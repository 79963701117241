import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoInstagramLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.649 4.174a.824.824 0 11-1.649 0 .824.824 0 011.649 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.501 4.368a3.133 3.133 0 100 6.266 3.133 3.133 0 000-6.266zM5.368 7.501a2.133 2.133 0 114.266 0 2.133 2.133 0 01-4.266 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.469 1.001h.064c1.263 0 2.255 0 3.039.09.8.091 1.448.282 1.992.708.238.187.452.401.639.64.426.543.617 1.19.708 1.991.09.784.09 1.776.09 3.039v.064c0 1.263 0 2.255-.09 3.039-.091.8-.282 1.448-.708 1.992a3.746 3.746 0 01-.64.639c-.543.426-1.19.617-1.991.708-.784.09-1.776.09-3.04.09H7.47c-1.263 0-2.255 0-3.039-.09-.8-.091-1.448-.282-1.992-.708a3.748 3.748 0 01-.639-.64c-.426-.543-.617-1.19-.708-1.991C1 9.788 1 8.796 1 7.532V7.47c0-1.263 0-2.255.09-3.039.091-.8.282-1.448.708-1.992a3.75 3.75 0 01.64-.639c.543-.426 1.19-.617 1.991-.708C5.214 1 6.206 1 7.47 1zM4.544 2.084c-.71.081-1.15.237-1.489.502a2.75 2.75 0 00-.469.469c-.265.34-.42.779-.502 1.489-.082.72-.083 1.655-.083 2.957 0 1.302 0 2.237.083 2.957.081.71.237 1.15.502 1.489.137.175.294.332.469.469.34.265.779.42 1.489.502C5.264 13 6.199 13 7.5 13c1.302 0 2.237 0 2.957-.083.71-.082 1.15-.237 1.489-.502.175-.137.332-.295.469-.469.265-.34.42-.779.502-1.489C13 9.738 13 8.803 13 7.501c0-1.302 0-2.237-.083-2.957-.082-.71-.237-1.15-.502-1.489a2.754 2.754 0 00-.469-.469c-.34-.265-.779-.42-1.489-.502-.72-.082-1.655-.083-2.957-.083-1.302 0-2.237 0-2.957.083z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19 10a3.81 3.81 0 117.62 0 3.81 3.81 0 01-7.62 0zM10 7.19a2.81 2.81 0 100 5.62 2.81 2.81 0 000-5.62z"
        fill="currentColor"
      />
      <path
        d="M14.269 6.485a.749.749 0 100-1.498.749.749 0 000 1.498z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.969 2.25h.062c1.498 0 2.663 0 3.585.101.938.104 1.684.317 2.315.785.355.263.67.578.933.933.468.631.681 1.377.785 2.315.101.922.101 2.087.101 3.585v.062c0 1.498 0 2.663-.101 3.585-.104.938-.317 1.684-.785 2.315-.263.355-.578.67-.933.933-.631.468-1.377.681-2.315.785-.922.101-2.087.101-3.585.101h-.062c-1.498 0-2.663 0-3.585-.101-.938-.104-1.684-.317-2.315-.785a4.498 4.498 0 01-.933-.933c-.468-.631-.681-1.377-.785-2.315-.101-.922-.101-2.087-.101-3.585v-.062c0-1.498 0-2.663.101-3.585.104-.938.317-1.684.785-2.315a4.5 4.5 0 01.933-.933c.631-.468 1.377-.681 2.315-.785.922-.101 2.087-.101 3.585-.101zM6.493 3.345c-.85.094-1.398.275-1.828.594a3.5 3.5 0 00-.726.726c-.32.43-.5.977-.594 1.828-.094.861-.095 1.972-.095 3.507 0 1.535 0 2.646.095 3.507.094.85.275 1.398.594 1.828.205.276.45.521.726.726.43.32.977.5 1.828.594.861.094 1.972.095 3.507.095 1.535 0 2.646 0 3.507-.095.85-.094 1.398-.274 1.828-.594.276-.205.521-.45.726-.726.32-.43.5-.977.594-1.828.094-.861.095-1.972.095-3.507 0-1.535 0-2.646-.095-3.507-.094-.85-.274-1.398-.594-1.828a3.498 3.498 0 00-.726-.726c-.43-.32-.977-.5-1.828-.594-.861-.094-1.972-.095-3.507-.095-1.535 0-2.646 0-3.507.095z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoInstagramLight)
