import styles from './styles.scss'

const Ssd = () => (
  <div className={styles.container}>
    <p>
      Твердотельный диск (SSD) – это тип накопителя данных, который использует
      флэш-память для хранения данных, в отличие от жестких дисков, которые
      используют вращающиеся магнитные диски.
    </p>
    <p>
      SSD быстрее загружает операционную систему и приложения, чем жесткие
      диски, и позволяет существенно ускорить работу компьютера. Кроме того, SSD
      имеет более быстрый доступ к данным, что делает его идеальным для операций
      чтения/записи больших объемов данных, таких как видео и аудиофайлы, а
      также для игр и других приложений, требующих быстрого доступа к данным.
    </p>
    <p>При выборе SSD следует обратить внимание на несколько факторов:</p>
    <ul>
      <li>Объем хранилища</li>
      <li>Интерфейс</li>
      <li>Скорость</li>
      <li>Тип памяти</li>
    </ul>
    <p></p>
  </div>
)

export default Ssd
