import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1EditRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M3.5 13.253c0-.68.179-1.15.43-1.488.256-.347.626-.61 1.087-.804.944-.4 2.123-.458 2.983-.458a.75.75 0 000-1.5c-.875 0-2.322.049-3.568.576-.635.27-1.252.676-1.709 1.294C2.261 11.5 2 12.29 2 13.253a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.935 7.43a1.425 1.425 0 012.016 0l.618.62a1.425 1.425 0 010 2.014L9.965 15.67c-.21.21-.497.33-.795.33H7.627a.625.625 0 01-.625-.626V13.83c0-.298.118-.584.33-.795l5.603-5.604zm1.132.885a.175.175 0 00-.248 0l-.81.81.866.866.81-.81a.175.175 0 000-.248l-.618-.618zm-1.076 2.56l-.866-.866-3.873 3.873v.866h.866l3.873-3.873z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.956a4.55 4.55 0 100 9.1 4.55 4.55 0 000-9.1zm-3.05 4.55a3.05 3.05 0 116.1 0 3.05 3.05 0 01-6.1 0z"
          fill="currentColor"
        />
        <path
          d="M3.75 17.246c0-.838.242-1.432.6-1.873.369-.454.901-.795 1.552-1.046 1.32-.509 2.947-.577 4.098-.577a.75.75 0 000-1.5c-1.163 0-3.036.06-4.637.677-.81.312-1.594.783-2.177 1.5-.592.728-.936 1.663-.936 2.819a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.366 16.573a1.25 1.25 0 00-.366.884v1.793c0 .414.336.75.75.75h1.793c.331 0 .65-.132.884-.366l4.602-4.603h.001l1.576-1.576a1.35 1.35 0 000-1.91l-1.151-1.151a1.35 1.35 0 00-1.91 0l-6.179 6.18zm5.134-3.012l-4 4v.939h.94l4-4-.94-.94zm1.06-1.061l.94.94.94-.94-.94-.94-.94.94z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM13.366 19.573l7.18-7.18a1.35 1.35 0 011.909 0l1.151 1.152a1.35 1.35 0 010 1.91L22.03 17.03l-5.603 5.604a1.25 1.25 0 01-.884.366H13.75a.75.75 0 01-.75-.75v-1.793c0-.331.132-.65.366-.884zm1.134.988l5-5 .94.939-5 5h-.94v-.94zm7-5.122l-.94-.939.94-.94.94.94-.94.94z"
          fill="currentColor"
        />
        <path
          d="M3.75 21c0-1.267.336-2.186.841-2.87.511-.69 1.234-1.193 2.086-1.553 1.727-.732 3.843-.827 5.323-.827a.75.75 0 000-1.5c-1.495 0-3.879.086-5.908.945-1.026.435-1.995 1.08-2.707 2.043-.717.97-1.135 2.21-1.135 3.762a.75.75 0 001.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
        <path
          d="M5 28.5c0-1.557.439-2.684 1.102-3.525.675-.856 1.637-1.484 2.78-1.938C11.194 22.119 14.025 22 16 22a1 1 0 100-2c-1.991 0-5.16.108-7.856 1.178-1.362.54-2.657 1.347-3.612 2.558C3.566 24.961 3 26.534 3 28.5a1 1 0 102 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.79 19.796L16.586 27A2 2 0 0016 28.414V30.5a1.5 1.5 0 001.5 1.5h2.086A2 2 0 0021 31.414l9.33-9.33a2.25 2.25 0 000-3.183L29.1 17.67a2.25 2.25 0 00-3.182 0l-2.12 2.12-.004.004-.003.003zm3.894-.713a.25.25 0 00-.353 0L25.914 20.5l1.586 1.586 1.417-1.417a.25.25 0 000-.353l-1.233-1.233zM26.086 23.5L24.5 21.914l-6.5 6.5V30h1.586l6.5-6.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM20 21c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"
        fill="currentColor"
      />
      <path
        d="M10 56.5c0-3.224.893-5.593 2.267-7.372 1.389-1.799 3.35-3.097 5.634-4.023C22.513 43.235 28.13 43 32 43a1.5 1.5 0 000-3c-3.897 0-10.03.218-15.226 2.325-2.62 1.062-5.078 2.634-6.882 4.969C8.075 49.648 7 52.678 7 56.5a1.5 1.5 0 003 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.318 35.06a4.5 4.5 0 016.364 0l1.257 1.258a4.5 4.5 0 010 6.364l-17 17A4.5 4.5 0 0139.759 61l-3.755.002a2 2 0 01-2-2L34 55.246a4.5 4.5 0 011.318-3.185l16.999-17zm4.243 2.122a1.5 1.5 0 00-2.122 0l-2.568 2.568 3.379 3.379 2.568-2.568a1.5 1.5 0 000-2.122l-1.257-1.257zm-3.432 8.068l-3.379-3.379-12.31 12.31a1.5 1.5 0 00-.44 1.062L37.004 58h2.754a1.5 1.5 0 001.06-.44L53.13 45.25z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1EditRegular)
