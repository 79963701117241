import { useCallback, useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { omit, random } from 'lodash'
import { observer } from 'mobx-react-lite'

import { ButtonCounter } from '@ui/ButtonCounter'
import {
  IconInfoCircleLight,
  IconSettingsGearLight,
  IconTrashCanLight,
} from '@ui/icons'
import { Modal } from '@ui/Modal'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { getCostOfAccessories, getCurrencyString } from '@lib/helpers'

import {
  IComplectation,
  IComponentsTypes,
} from '@modules/configurator/models/Configurator'

import styles from './CartItem.module.scss'

interface ICartItem {
  order: IComplectation
  componentTypes: IComponentsTypes[]
  onIncrement: () => void
  onDecrement: () => void
  onDelete: () => void
  onEdit?: () => void
}

const CartItem = ({
  order,
  componentTypes,
  onIncrement,
  onDecrement,
  onDelete,
  onEdit,
}: ICartItem) => {
  const [modalOpen, setModalOpen] = useState(false)

  const uniqId = useMemo(() => random(0, 10000), [])

  const cost = useMemo(
    () => getCostOfAccessories(order.product.accessories, order.count),
    [order.count],
  )

  const handleEdit = useCallback(() => {
    onEdit()
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [modalOpen])

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [modalOpen])

  return (
    <CardWrapper py={10} px={16} fullWidth>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.settings}>
            <div className={styles.tooltipWrapper}>
              <Tooltip
                variant="dark"
                id={`settings-tooltip ${uniqId}`}
                className={styles.tooltip}
              />
            </div>
            <div
              data-tooltip-id={`settings-tooltip ${uniqId}`}
              data-tooltip-content="Изменить конфигурацию"
              data-tooltip-place="top">
              <IconSettingsGearLight onClick={handleEdit} />
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={order.product.productType.photoUrl}
              width={79}
              height={79}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              <div className={styles.title}>
                <Typography size={16}>
                  {order.product.productType.name}
                </Typography>{' '}
                <Typography size={16} color={COLORS.GRAY.GRAY_2}>
                  {order.product.productSeries.name}
                </Typography>
              </div>

              <div className={styles.description} onClick={handleOpenModal}>
                <Typography>Подробнее</Typography>
                <IconInfoCircleLight className={styles.info} />
              </div>
            </div>
            <div className={styles.controls}>
              <div className={styles.cost}>
                <ButtonCounter
                  count={order.count}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                />
                <Typography
                  color={COLORS.GRAY.GRAY_1}
                  size={12}
                  className={styles.costPerItem}>
                  {getCurrencyString(cost / order.count)} / шт.
                </Typography>
              </div>

              <Typography
                size={16}
                weight="medium"
                className={styles.totalCost}>
                {getCurrencyString(cost)}
              </Typography>

              <div className={styles.delete}>
                <div className={styles.iconContainer}>
                  <IconTrashCanLight
                    color={COLORS.GRAY.SAURON}
                    onClick={onDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        title="Подробная информация"
        className={styles.modal}
        content={
          <div className={styles.specificationContent}>
            {Object.entries(omit(order.product.platforms, 'processor')).map(
              ([key, value]) => (
                <div key={key} className={styles.item}>
                  <Typography weight="medium" size={12}>
                    {value.title}
                  </Typography>
                  <Typography size={12}>{value.name}</Typography>
                </div>
              ),
            )}
            {Object.entries(order.product.accessories).map(([key, value]) => (
              <div key={key} className={styles.item}>
                <Typography weight="medium" size={12}>
                  {componentTypes.find((type) => type.key === value.type)?.name}
                </Typography>
                <Typography size={12}>{value.fullName}</Typography>
              </div>
            ))}
          </div>
        }
      />
    </CardWrapper>
  )
}

export default observer(CartItem)
