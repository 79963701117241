import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowRoundTimeBackLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 1.253a.5.5 0 00-1 0V5.5a.5.5 0 00.5.5h4a.5.5 0 100-1H2.6a5.5 5.5 0 11-.286 4.333.5.5 0 00-.943.333A6.5 6.5 0 102 4.036V1.253z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.737 3.807a7 7 0 11-3.22 8.834.5.5 0 00-.926.377A8 8 0 103 6.127V2.748a.5.5 0 00-1 0V7.5a.5.5 0 00.5.5H7a.5.5 0 000-1H3.675a7 7 0 013.062-3.193z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowRoundTimeBackLight)
