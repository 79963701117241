import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMailLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.8 5.6a.5.5 0 00-.6.8L6 8.5a2.5 2.5 0 003 0l2.8-2.1a.5.5 0 00-.6-.8L8.4 7.7a1.5 1.5 0 01-1.8 0L3.8 5.6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 3A2.5 2.5 0 001 5.5v5A2.5 2.5 0 003.5 13h8a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0011.5 3h-8zM2 5.5A1.5 1.5 0 013.5 4h8A1.5 1.5 0 0113 5.5v5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 10.5v-5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5.312 6.81a.5.5 0 10-.624.78l3.375 2.701a3.1 3.1 0 003.874 0l3.375-2.7a.5.5 0 10-.624-.781l-3.376 2.7a2.1 2.1 0 01-2.624 0l-3.376-2.7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zM3 7a2 2 0 012-2h10a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2V7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMailLight)
