import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTextActionCheckLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2 3.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zM2.5 7a.5.5 0 000 1h11a.5.5 0 000-1h-11zM2.5 11a.5.5 0 000 1h4a.5.5 0 000-1h-4zM11.707 14.5l3.147-3.146a.5.5 0 00-.708-.708L11 13.793l-1.646-1.647a.5.5 0 00-.708.708l1.647 1.646a1 1 0 001.414 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3 4.5a.5.5 0 01.5-.5h13a.5.5 0 010 1h-13a.5.5 0 01-.5-.5zM3.5 8a.5.5 0 000 1h13a.5.5 0 000-1h-13zM3.5 12a.5.5 0 000 1h5a.5.5 0 000-1h-5zM15.392 16.878l4.024-4.024a.5.5 0 00-.707-.708l-4.025 4.025a.438.438 0 01-.618 0l-1.837-1.837a.5.5 0 00-.708.707l1.837 1.837a1.438 1.438 0 002.034 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextActionCheckLight)
