import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeADownLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 14.001a2 2 0 01-2-2V3.467c0-1.025 1.239-1.538 1.963-.814l1.85 1.85 2.7-2.7a1.75 1.75 0 012.475 0l3.21 3.21a1.75 1.75 0 010 2.475l-2.7 2.7 1.85 1.85c.724.724.211 1.963-.813 1.963H4zm-.5-2a.5.5 0 00.5.5h7.69l-1.783-1.782a.75.75 0 010-1.061l3.23-3.23a.25.25 0 000-.354l-3.21-3.21a.25.25 0 00-.353 0l-3.231 3.23a.75.75 0 01-1.06 0L3.5 4.312V12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 17A2.25 2.25 0 013 14.75V4.573c0-1.202 1.454-1.805 2.305-.954l2.117 2.117 3.351-3.351a2 2 0 012.829 0l4.012 4.013a2 2 0 010 2.828l-3.35 3.352 2.117 2.117c.85.85.248 2.305-.954 2.305H5.25zm-.75-2.25c0 .414.336.75.75.75h9.814l-2.391-2.392a.75.75 0 010-1.06l3.88-3.882a.5.5 0 000-.707l-4.012-4.014a.5.5 0 00-.707 0L7.952 7.327a.75.75 0 01-1.06 0L4.5 4.936v9.814z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.875 20.751a2.625 2.625 0 01-2.625-2.625V5.313c0-1.337 1.616-2.006 2.56-1.061l2.97 2.97 4.254-4.253a2.25 2.25 0 013.182 0l4.815 4.816a2.25 2.25 0 010 3.182l-4.252 4.252 2.971 2.971c.945.945.276 2.56-1.06 2.56l-12.815.001zM4.75 18.126c0 .622.504 1.125 1.125 1.125H18.69l-3.5-3.501a.75.75 0 010-1.06l4.782-4.784a.75.75 0 000-1.06l-4.816-4.817a.75.75 0 00-1.06 0L9.31 8.813a.75.75 0 01-1.06 0l-3.501-3.5v12.813z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 27A3.5 3.5 0 015 23.5V7.415C5 5.633 7.155 4.74 8.415 6l3.46 3.46 5.504-5.503a3 3 0 014.242 0l6.42 6.422a3 3 0 010 4.242l-5.502 5.503L26 23.585c1.26 1.26.368 3.415-1.414 3.415H8.5zM7 23.5A1.5 1.5 0 008.5 25h16.086l-4.168-4.168a1 1 0 010-1.414l6.21-6.21a1 1 0 000-1.414l-6.42-6.422a1 1 0 00-1.415 0l-6.21 6.21a1 1 0 01-1.415 0L7 7.415V23.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 54a6.5 6.5 0 01-6.5-6.5V14.829c0-3.118 3.77-4.68 5.975-2.475l7.525 7.525 11.61-11.61a5.5 5.5 0 017.779 0l12.84 12.843a5.5 5.5 0 010 7.777L44.121 40.5l7.525 7.526C53.851 50.23 52.29 54 49.171 54H16.5zM13 47.5a3.5 3.5 0 003.5 3.5H49.17a.5.5 0 00.354-.854l-8.586-8.586a1.5 1.5 0 010-2.12l12.669-12.672a2.5 2.5 0 000-3.535L40.768 10.39a2.5 2.5 0 00-3.537 0l-12.67 12.67a1.5 1.5 0 01-2.122 0l-8.586-8.585a.5.5 0 00-.853.354v32.67z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownLeftRegular)
