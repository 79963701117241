import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyWalletAOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.15 10.4a.7.7 0 100-1.4.7.7 0 000 1.4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 7v.12c.37.183.625.566.625 1.007v3.123c0 .442-.255.824-.625 1.008v.24a2.5 2.5 0 01-2.5 2.5h-9a2.5 2.5 0 01-2.5-2.5V5.032a2.5 2.5 0 011.747-2.384L9.723.444A1.75 1.75 0 0112 2.114V4.5h.5A2.5 2.5 0 0115 7zm-4.825-5.125a.25.25 0 01.325.238V4.5H4.75a.75.75 0 000 1.5h7.75a1 1 0 011 1v.002h-2.85a2.625 2.625 0 00-2.625 2.625v.123a2.625 2.625 0 002.625 2.625h2.85v.124a1 1 0 01-1 1h-9a1 1 0 01-1-1V5.03a1 1 0 01.699-.953l6.976-2.203zm.475 6.377c-.76 0-1.375.616-1.375 1.375v.123c0 .76.616 1.375 1.375 1.375h3.725V8.252H10.65z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M14 11.9a.9.9 0 100-1.8.9.9 0 000 1.8z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8 5.25a2.95 2.95 0 012.944 2.757A1.4 1.4 0 0119 9.4v3.2a1.4 1.4 0 01-1.25 1.392v.808a2.95 2.95 0 01-2.95 2.95H4.2a2.95 2.95 0 01-2.95-2.95V5.68c0-1.33.89-2.496 2.174-2.846L11.54.621a1.75 1.75 0 012.21 1.688V5.25h1.05zm-2.866-3.182a.25.25 0 01.316.241V5.25H5a.75.75 0 000 1.5h9.8A1.45 1.45 0 0116.236 8H13.5a3 3 0 100 6h2.75v.8c0 .8-.65 1.45-1.45 1.45H4.2c-.8 0-1.45-.65-1.45-1.45V5.68a1.45 1.45 0 011.068-1.398l8.116-2.214zM13.5 9.5h4v3h-4a1.5 1.5 0 010-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M17 15a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 9.5v.835a1.5 1.5 0 011 1.415v4.5a1.5 1.5 0 01-1 1.415v.835a3.5 3.5 0 01-3.5 3.5h-13A3.5 3.5 0 012 18.5V6.86a3.5 3.5 0 012.594-3.38l8.888-2.383A2 2 0 0116 3.03V6h2.5A3.5 3.5 0 0122 9.5zm-8.13-6.954a.5.5 0 01.63.483V6H6a.75.75 0 000 1.5h12.5a2 2 0 012 2v.75H17a3.75 3.75 0 100 7.5h3.5v.75a2 2 0 01-2 2h-13a2 2 0 01-2-2V6.86a2 2 0 011.482-1.932l8.889-2.382zM17 11.75h4.5v4.5H17a2.25 2.25 0 010-4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M22 19.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.744 3.132A1 1 0 0120 4.098V6a1 1 0 102 0V4.098a3 3 0 00-3.768-2.9L6.348 4.344A4.5 4.5 0 003 8.694V24.5A4.5 4.5 0 007.5 29h17a4.5 4.5 0 004.5-4.5v-1.768A2 2 0 0030 21v-5a2 2 0 00-1-1.732V12.5A4.5 4.5 0 0024.5 8H8a1 1 0 100 2h16.5a2.5 2.5 0 012.5 2.5V14h-5.5a4.5 4.5 0 100 9H27v1.5a2.5 2.5 0 01-2.5 2.5h-17A2.5 2.5 0 015 24.5V8.694a2.5 2.5 0 011.86-2.417l11.884-3.145zM21.5 16H28v5h-6.5a2.5 2.5 0 010-5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path d="M46 39.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8 4.933a2.5 2.5 0 013.2 2.4V12.5a1.5 1.5 0 003 0V7.333c0-3.666-3.52-6.306-7.04-5.28L12.12 9.59A8.5 8.5 0 006 17.75V49.5a8.5 8.5 0 008.5 8.5h35a8.5 8.5 0 008.5-8.5v-3.535a3.5 3.5 0 003-3.465v-11a3.5 3.5 0 00-3-3.465V24.5a8.5 8.5 0 00-8.5-8.5h-35a1.5 1.5 0 100 3h35a5.5 5.5 0 015.5 5.5V28h-9a9 9 0 100 18h9v3.5a5.5 5.5 0 01-5.5 5.5h-35A5.5 5.5 0 019 49.5V17.75a5.5 5.5 0 013.96-5.28L38.8 4.933zM46 31h11.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H46a6 6 0 010-12z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyWalletAOpenRegular)
