import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMathDeltaLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.446 3l-4.373 8.445A1.75 1.75 0 003.627 14h8.746a1.75 1.75 0 001.554-2.555L9.554 3.001C8.9 1.74 7.099 1.74 6.446 3zm.54 1.094l-4.012 7.817a.75.75 0 00.667 1.093h8.024a.75.75 0 00.667-1.092L8.32 4.094a.75.75 0 00-1.334 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7 17c1.502 0 2.468-1.594 1.774-2.925L11.773 3.149c-.748-1.434-2.799-1.434-3.546 0L2.526 14.075C1.83 15.406 2.797 17 4.299 17H15.7zm-.991-1a1 1 0 00.886-1.463L10.397 4.58a1 1 0 00-1.773 0l-5.198 9.958A1 1 0 004.312 16H14.71z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathDeltaLight)
