import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDevBugRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3.5A2.5 2.5 0 017.5 1h1A2.5 2.5 0 0111 3.5v.75h1.75a.75.75 0 00.75-.75v-.75a.75.75 0 011.5 0v.75a2.25 2.25 0 01-2.25 2.25h-.128c.083.235.128.487.128.75v.75h2a.75.75 0 010 1.5h-2V10c0 .084-.002.167-.007.25h.007A2.25 2.25 0 0115 12.5v1.75a.75.75 0 01-1.5 0V12.5a.75.75 0 00-.75-.75h-.333a4.751 4.751 0 01-8.834 0H3.25a.75.75 0 00-.75.75v1.75a.75.75 0 01-1.5 0V12.5a2.25 2.25 0 012.25-2.25h.006A4.819 4.819 0 013.25 10V8.75h-2a.75.75 0 010-1.5h2V6.5c0-.263.045-.515.128-.75H3.25A2.25 2.25 0 011 3.5v-.75a.75.75 0 011.5 0v.75c0 .414.336.75.75.75H5V3.5zm5.5 2.25a.75.75 0 01.75.75V10a3.25 3.25 0 01-6.5 0V6.5a.75.75 0 01.75-.75h1.75V11a.75.75 0 001.5 0V5.75h1.75zm-1-1.5V3.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v.75h3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 5.25A3.25 3.25 0 019.5 2h1a3.25 3.25 0 013.25 3.25v.138c.35.216.646.512.861.862h1.514c.621 0 1.125-.504 1.125-1.125V4a.75.75 0 011.5 0v1.125a2.625 2.625 0 01-2.625 2.625H15v1.5h3.25a.75.75 0 010 1.5H15v1.5h1.125a2.625 2.625 0 012.625 2.625V17a.75.75 0 01-1.5 0v-2.125c0-.621-.504-1.125-1.125-1.125h-1.18a5.001 5.001 0 01-9.89 0h-1.18c-.621 0-1.125.504-1.125 1.125V17a.75.75 0 01-1.5 0v-2.125a2.625 2.625 0 012.625-2.625H5v-1.5H1.75a.75.75 0 010-1.5H5v-1.5H3.875A2.625 2.625 0 011.25 5.125V4a.75.75 0 011.5 0v1.125c0 .621.504 1.125 1.125 1.125h1.513c.216-.35.512-.646.862-.862V5.25zM10.5 3.5A1.75 1.75 0 0112.232 5H7.768A1.75 1.75 0 019.5 3.5h1zm-2.875 3c-.621 0-1.125.504-1.125 1.125V13a3.5 3.5 0 107 0V7.625c0-.621-.504-1.125-1.125-1.125H10.75V14a.75.75 0 01-1.5 0V6.5H7.625z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5.75a3.25 3.25 0 013.25-3.25h1.5A3.25 3.25 0 0116 5.75v.42a3.01 3.01 0 011.599 1.33H19A1.5 1.5 0 0020.5 6V4.75a.75.75 0 011.5 0V6a3 3 0 01-3 3h-1v2.25h3.5a.75.75 0 010 1.5H18V15h1a3 3 0 013 3v2.75a.75.75 0 01-1.5 0V18a1.5 1.5 0 00-1.5-1.5h-1.189a6.003 6.003 0 01-11.622 0H5A1.5 1.5 0 003.5 18v2.75a.75.75 0 01-1.5 0V18a3 3 0 013-3h1v-2.25H2.5a.75.75 0 010-1.5H6V9H5a3 3 0 01-3-3V4.75a.75.75 0 011.5 0V6A1.5 1.5 0 005 7.5h1.401A3.01 3.01 0 018 6.17v-.42zm6.5 0V6h-5v-.25c0-.966.784-1.75 1.75-1.75h1.5c.966 0 1.75.784 1.75 1.75zM9 7.5A1.5 1.5 0 007.5 9v6a4.5 4.5 0 109 0V9A1.5 1.5 0 0015 7.5h-2.25V17a.75.75 0 01-1.5 0V7.5H9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 7.25A4.25 4.25 0 0115.25 3h1.5A4.25 4.25 0 0121 7.25v.876c1.051.27 1.935.957 2.465 1.874H25a2 2 0 002-2V6a1 1 0 112 0v2a4 4 0 01-4 4h-1v3h4.5a1 1 0 110 2H24v3h1a4 4 0 014 4v4a1 1 0 11-2 0v-4a2 2 0 00-2-2h-1.252a8.003 8.003 0 01-15.496 0H7a2 2 0 00-2 2v4a1 1 0 11-2 0v-4a4 4 0 014-4h1v-3H3.5a1 1 0 110-2H8v-3H7a4 4 0 01-4-4V6a1 1 0 012 0v2a2 2 0 002 2h1.535A4.008 4.008 0 0111 8.126V7.25zM22 12v8a6 6 0 01-12 0v-8a2 2 0 012-2h3v13a1 1 0 102 0V10h3a2 2 0 012 2zm-3-4v-.75A2.25 2.25 0 0016.75 5h-1.5A2.25 2.25 0 0013 7.25V8h6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 15.5A9.5 9.5 0 0130.5 6h3a9.5 9.5 0 019.5 9.5v.927A7.524 7.524 0 0147.135 20H50.5a4.5 4.5 0 004.5-4.5v-3a1.5 1.5 0 013 0v3a7.5 7.5 0 01-7.5 7.5h-2.516c.01.165.016.332.016.5V30h9.5a1.5 1.5 0 010 3H48v6c0 .336-.01.67-.03 1h2.53a7.5 7.5 0 017.5 7.5v7a1.5 1.5 0 01-3 0v-7a4.5 4.5 0 00-4.5-4.5h-3.004C45.72 49.9 39.456 55 32 55c-7.455 0-13.72-5.1-15.496-12H13.5A4.5 4.5 0 009 47.5v7a1.5 1.5 0 01-3 0v-7a7.5 7.5 0 017.5-7.5h2.53c-.02-.33-.03-.664-.03-1v-6H6.5a1.5 1.5 0 010-3H16v-6.5c0-.168.006-.335.016-.5H13.5A7.5 7.5 0 016 15.5v-3a1.5 1.5 0 013 0v3a4.5 4.5 0 004.5 4.5h3.365A7.524 7.524 0 0121 16.427V15.5zm24 8V39c0 7.18-5.82 13-13 13s-13-5.82-13-13V23.5a4.5 4.5 0 014.5-4.5h7v26.5a1.5 1.5 0 003 0V19h7a4.5 4.5 0 014.5 4.5zM40 16v-.5A6.5 6.5 0 0033.5 9h-3a6.5 6.5 0 00-6.5 6.5v.5h16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDevBugRegular)
