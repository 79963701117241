import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'

import { CompanyStore } from '@modules/company/store/CompanyStore'
import { UserStore } from '@modules/user/store/UserStore'

@singleton()
class OnboardingFormStore {
  private userStore = container.resolve(UserStore)
  private companyStore = container.resolve(CompanyStore)

  form = new FormConstructor({
    email: {
      rule: 'required|email',
      label: 'Адрес электронной почты',
    },
    legalAddress: {
      rule: 'required|regex:/[а-яА-ЯёЁa-zA-Z0-9 "\'-:#()№\\-.,!]+/',
      errorText: 'Введите корректное наименование',
      label: 'Юридический адрес',
    },
    actualAddress: {
      rule: 'required|regex:/[а-яА-ЯёЁa-zA-Z0-9 "\'-:#()№\\-.,!]+/',
      errorText: 'Введите корректное наименование',
      label: 'Фактический адрес',
    },
    phone: {
      rule: 'required|regex:/^\\+7 [\\d]{3} [\\d]{3} [\\d]{2} [\\d]{2}/',
      errorText: 'Телефон должен состоять из 10 цифр',
      label: 'Телефон',
    },
  })

  constructor() {
    makeAutoObservable<OnboardingFormStore, 'companyStore' | 'userStore'>(
      this,
      {
        companyStore: false,
        userStore: false,
      },
    )
  }

  get showOnboarding() {
    return this.companyStore.isNewCompany && this.userStore.isPartner
  }

  get isDisabled() {
    const { legalAddress, actualAddress, email, phone } = this.form.fields

    return (
      !legalAddress.value ||
      !actualAddress.value ||
      !email.value ||
      !phone.value
    )
  }

  handleSubmit = async () => {
    await this.form.handleSubmit((fields) =>
      this.companyStore.editCompany({
        email: fields.email.value,
        legalAddress: fields.legalAddress.value,
        actualAddress: fields.actualAddress.value,
        phone: fields.phone.value,
      }),
    )
  }
}

export const useOnboardingFormStore = () =>
  container.resolve(OnboardingFormStore)
