import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'

import FailCard from '@lib/components/FailCard'
import { Form } from '@lib/components/Form/Form'
import SuccessCard from '@lib/components/SuccessCard'
import { EAuthRoutes } from '@lib/navigation'

import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'
import { useForgotPasswordFormStore } from '@modules/auth/store/ForgotPasswordFormStore'

import { FormAnimation } from '../FormAnimation/FormAnimation'
import { FormWindowLayout } from '../FormWindowLayout/FormWindowLayout'

import styles from './ForgotPasswordForm.module.scss'

export const ForgotPasswordForm: React.FC = observer(() => {
  const forgotPasswordFormStore = useForgotPasswordFormStore()
  const animatedStore = useAnimatedFormStore()

  const navigate = useNavigate()

  const handleToAuth = useCallback(() => {
    navigate(EAuthRoutes.Auth)
  }, [])

  const handleToRestore = useCallback(() => {
    animatedStore.setAnimatedState('backward')
    forgotPasswordFormStore.form.clear()
  }, [])

  const handleForgotPassword = useCallback(() => {
    animatedStore.setAnimatedState('forward')
    forgotPasswordFormStore.handleSubmit()
  }, [])

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      forgotPasswordFormStore.form.handleFieldChange(
        event.target.name,
        event.target.value,
      )
    },
    [],
  )

  const formContent = () => (
    <Form title="Забыли пароль?" titleClassName={styles.titleForm}>
      <Input
        name="email"
        label="Введите ваш почтовый адрес"
        placeholder="Электронная почта"
        id="email"
        className={styles.email}
        value={forgotPasswordFormStore.form.fields.email.value}
        error={forgotPasswordFormStore.form.fields.email.error}
        errorText={forgotPasswordFormStore.form.fields.email.errorText}
        onChange={handleFieldChange}
      />
      <Button
        disabled={!forgotPasswordFormStore.form.fields.email.value}
        fullSize
        onClick={handleForgotPassword}>
        Восстановить пароль
      </Button>
    </Form>
  )

  return (
    <FormWindowLayout
      withoutPadding={
        forgotPasswordFormStore.restoreSuccess ||
        forgotPasswordFormStore.restoreError
      }>
      <FormAnimation
        animationDirection={
          forgotPasswordFormStore.restoreRunning
            ? 'none'
            : animatedStore.animatedState
        }>
        {(forgotPasswordFormStore.restoreIDLE ||
          forgotPasswordFormStore.restoreRunning) &&
          formContent()}
        {forgotPasswordFormStore.restoreSuccess && (
          <SuccessCard
            className={styles.fitContentWidth}
            titleButton="Войти"
            title="Данные успешно отправлены"
            description="Данные для восстановления пароля придут на ваш электронный адрес"
            handleClick={handleToAuth}
          />
        )}
        {forgotPasswordFormStore.restoreError && (
          <FailCard
            className={styles.fitContentWidth}
            titleButton="Вернуться к восстановлению пароля"
            title="Произошла непредвиденная ошибка"
            description="Во время восстановления пароля произошла ошибка, попробуйте восстановить пароль повторно"
            handleClick={handleToRestore}
          />
        )}
      </FormAnimation>
    </FormWindowLayout>
  )
})
