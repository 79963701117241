import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconToolPencilLineLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.354 1.439a1.5 1.5 0 012.122 0l1.085 1.086a1.5 1.5 0 010 2.121L4.646 13.56a1.5 1.5 0 01-1.06.44H1.5a.5.5 0 01-.5-.5v-2.087c0-.397.158-.779.44-1.06l8.914-8.915zm1.414.707a.5.5 0 00-.707 0L9.957 3.25l1.793 1.793 1.104-1.104a.5.5 0 000-.707l-1.086-1.086zm-9.622 8.915L9.25 3.957l1.793 1.793-7.104 7.104a.5.5 0 01-.353.146H2v-1.586a.5.5 0 01.146-.353z"
          fill="currentColor"
        />
        <path d="M7.5 13a.5.5 0 100 1h7a.5.5 0 000-1h-7z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.354 3.439a1.5 1.5 0 012.122 0l1.085 1.086a1.5 1.5 0 010 2.12l-9.915 9.916a1.5 1.5 0 01-1.06.44H3.5a.5.5 0 01-.5-.5v-2.087c0-.397.158-.779.44-1.06l9.914-9.915zm1.414.707a.5.5 0 00-.707 0L12.957 5.25l1.793 1.793 1.104-1.104a.5.5 0 000-.707l-1.086-1.086zM4.146 14.061l8.104-8.104 1.793 1.793-8.104 8.104a.5.5 0 01-.353.146H4v-1.586a.5.5 0 01.146-.353z"
        fill="currentColor"
      />
      <path d="M9.5 16a.5.5 0 100 1h8a.5.5 0 000-1h-8z" fill="currentColor" />
    </svg>
  )
}

export default observer(IconToolPencilLineLight)
