import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTagLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M4 9a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.478 3a2.5 2.5 0 00-1.973.965L.56 6.465a2.5 2.5 0 000 3.07l1.945 2.5A2.5 2.5 0 004.478 13H11.5a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0011.5 3H4.478zM3.294 4.58A1.5 1.5 0 014.478 4H11.5A1.5 1.5 0 0113 5.5v5a1.5 1.5 0 01-1.5 1.5H4.478a1.5 1.5 0 01-1.184-.58L1.35 8.92a1.5 1.5 0 010-1.84l1.944-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path d="M5 11a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.185 4a3 3 0 00-2.322 1.1L1.149 8.417a2.5 2.5 0 000 3.166L3.863 14.9A3 3 0 006.185 16H15a3 3 0 003-3V7a3 3 0 00-3-3H6.185zM4.637 5.734A2 2 0 016.185 5H15a2 2 0 012 2v6a2 2 0 01-2 2H6.185a2 2 0 01-1.548-.733L1.923 10.95a1.5 1.5 0 010-1.9l2.714-3.316z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTagLight)
