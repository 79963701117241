import React, { memo, ReactElement } from 'react'

import Button from '@ui/Button'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import CardWrapper from '../CardWrapper/CardWrapper'

import styles from './CardForm.module.scss'

interface CardFormProps {
  editable?: boolean
  title?: string
  children: ReactElement | ReactElement[]
  onSubmit?: () => void
  onCancel?: () => void
  disabled?: boolean
  saved?: boolean
  direction?: 'column' | 'row'
  inputDirection?: 'column' | 'row'
}

const CardForm = ({
  editable,
  title,
  children,
  onSubmit,
  onCancel,
  disabled,
  saved,
  direction = 'column',
  inputDirection = 'row',
}: CardFormProps) => (
  <CardWrapper fullWidth>
    <div className={styles.card}>
      {title !== undefined && (
        <div className={styles.header}>
          <Typography size={18} className={styles.title}>
            {title}
          </Typography>
          {editable && (
            <div className={styles.buttons}>
              <Button size="small" onClick={onSubmit} disabled={disabled}>
                Сохранить
              </Button>
              <Button size="small" type="fill" withBorder onClick={onCancel}>
                Отмена
              </Button>
            </div>
          )}
          {!editable && saved && (
            <Typography size={14}>Изменения сохранены.</Typography>
          )}
        </div>
      )}
      <div
        className={cx(
          styles.inputs,
          styles[direction],
          [styles[`input-direction-${inputDirection}`]],
          {
            [styles.disabled]: disabled,
          },
        )}>
        {children}
      </div>
    </div>
  </CardWrapper>
)

export default memo(CardForm)
