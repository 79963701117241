import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPlusSquareLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 4.413a.5.5 0 10-1 0V7H4.413a.5.5 0 100 1H7v2.587a.5.5 0 001 0V8h2.587a.5.5 0 000-1H8V4.413z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1a3 3 0 00-3 3v7a3 3 0 003 3h7a3 3 0 003-3V4a3 3 0 00-3-3H4zM2 4a2 2 0 012-2h7a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10 6a.5.5 0 00-1 0v3H6a.5.5 0 000 1h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3V6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2A3.5 3.5 0 002 5.5v8A3.5 3.5 0 005.5 17h8a3.5 3.5 0 003.5-3.5v-8A3.5 3.5 0 0013.5 2h-8zM3 5.5A2.5 2.5 0 015.5 3h8A2.5 2.5 0 0116 5.5v8a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 013 13.5v-8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPlusSquareLight)
