import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { IContactUs } from '../models/Support'
import { ArticleAdapter } from '../services/ArticleAdapter'

/**
 * @prop id Идентификатор.
 * @prop key Ключ страницы.
 * @prop markup Контент страницы.
 */

export interface IArticle {
  id: number
  key: string
  file?: string
  markup: string
}

@singleton()
export class SupportRest extends AbstractRest {
  /**
   * Запрос текста политики
   */
  async getPrivacyPolicyText() {
    const { data } = await this.http.get(`/lk/articles/politics`)
    return ArticleAdapter.transform(data)
  }

  async createContactUsRequest(params: IContactUs) {
    try {
      await this.http.post('/lk/support/contactUs', params)
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   * Запрос текста гарантии
   */
  async getWarrantyText() {
    const { data } = await this.http.get<IArticle>(`/lk/articles/warranty`)
    return ArticleAdapter.transform(data)
  }
  /**
   * Запрос текста партнерской программы
   */
  async getPartnersText() {
    const { data } = await this.http.get<IArticle>(`/lk/articles/partners`)
    return ArticleAdapter.transform(data)
  }

  /**
   * Запрос партнерского сертификата
   */
  async downloadCertificate() {
    const { data } = await this.http.post(
      '/lk/certificates',
      {},
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      },
    )
    const blob = new Blob([data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `Партнерский сертификат.pdf`
    link.click()
  }
}
