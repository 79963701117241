import React from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'
import { BrowserRouter } from 'react-router-dom'

import EventLogger from '@lib/error'

import '@abraham/reflection'
import './styles/index.global.scss'

import App from './app/App'

EventLogger.init()
export const rootElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)

Modal.setAppElement(rootElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
