import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconStarLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.689.9A.75.75 0 007.31.9L5.547 4.995l-4.442.412a.75.75 0 00-.425 1.31l3.35 2.944-.98 4.352a.75.75 0 001.115.81L8 12.545l3.835 2.277a.75.75 0 001.115-.81l-.98-4.35 3.35-2.945a.75.75 0 00-.425-1.31l-4.442-.412L8.69.899zm1.672 5.092a1 1 0 01-.826-.6L8 1.828 6.465 5.392a1 1 0 01-.826.6l-3.863.358L4.69 8.91a1 1 0 01.315.972l-.853 3.785 3.337-1.98a1 1 0 011.02 0l3.337 1.98-.853-3.785a1 1 0 01.315-.971l2.915-2.56-3.863-.36z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.861 1.7c-.325-.756-1.397-.756-1.722 0l-2.03 4.713L2 6.887C1.18 6.963.85 7.98 1.468 8.524l3.856 3.387-1.129 5.006c-.18.803.686 1.433 1.394 1.013L10 15.31l4.412 2.62c.708.42 1.575-.21 1.394-1.013l-1.129-5.006 3.856-3.387c.618-.542.287-1.561-.532-1.637l-5.11-.474L10.86 1.7zM12.8 7.408a1 1 0 01-.827-.6L10 2.228l-1.972 4.58a1 1 0 01-.826.6l-4.966.461 3.747 3.291a1 1 0 01.315.971l-1.096 4.865 4.287-2.546a1 1 0 011.022 0l4.287 2.546-1.096-4.865a1 1 0 01.315-.971l3.747-3.291-4.965-.46z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconStarLight)
