import colors from '../../styles/theme/_colors.scss'

type COLOR_KEYS = keyof typeof COLORS
type COLOR_VALUES = typeof COLORS[COLOR_KEYS]
export type TColorGroup = COLOR_VALUES

export const COLORS = {
  BRAND: {
    OCEAN: colors['color-brand-ocean'],
    CARROT: colors['color-brand-carrot'],
    RUBY: colors['color-brand-ruby'],
    MARSHMALLOW: colors['color-brand-marshmallow'],
    DEEP_OCEAN: colors['color-brand-deep-ocean'],
    DEEPSET_OCEAN: colors['color-brand-deepset-ocean'],
    SKY: colors['color-brand-sky'],
    LIGHT_SKY: colors['color-brand-light-sky'],
    MEDIUM_SKY: colors['color-brand-medium-sky'],
    DARK_SKY: colors['color-brand-dark-sky'],
  },
  STATE: {
    INFO: colors['color-state-info'],
    SUCCESS: colors['color-state-success'],
    SUCCESS_H: colors['color-state-success_H'],
    SUCCESS_P: colors['color-state-success_P'],
    WARNING: colors['color-state-warning'],
    WARNING_H: colors['color-state-warning_H'],
    WARNING_P: colors['color-state-warning_P'],
    ATTENTION: colors['color-state-attention'],
    ATTENTION_H: colors['color-state-attention_H'],
    ATTENTION_P: colors['color-state-attention_P'],
  },
  GRAY: {
    GANDALF: colors['color-gray-gandalf'],
    SAURON: colors['color-gray-sauron'],
    GRAY_1: colors['color-gray-1'],
    GRAY_2: colors['color-gray-2'],
    GRAY_3: colors['color-gray-3'],
    GRAY_4: colors['color-gray-4'],
    OBSIDIAN: colors['color-gray-obsidian'],
  },
} as const
