import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNewsFeedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 1A2.75 2.75 0 001 3.75v8.5A2.75 2.75 0 003.75 15h8.5a3.25 3.25 0 003.25-3.25v-6a.75.75 0 00-1.5 0v6a1.75 1.75 0 01-1.75 1.75h-8.5c-.69 0-1.25-.56-1.25-1.25v-8.5c0-.69.56-1.25 1.25-1.25h6.5c.69 0 1.25.56 1.25 1.25v7.5a.75.75 0 001.5 0v-7.5A2.75 2.75 0 0010.25 1h-6.5z"
          fill="currentColor"
        />
        <path
          d="M4.5 10a.5.5 0 000 1h5a.5.5 0 000-1h-5zM4 8.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zM7.5 6a.5.5 0 000 1h2a.5.5 0 000-1h-2zM7 4.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM4.5 4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.999 5.251a3.25 3.25 0 013.25-3.25h6.5a3.25 3.25 0 013.25 3.25v8.999a.75.75 0 01-1.5 0V5.251a1.75 1.75 0 00-1.75-1.75h-6.5a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75h9.5a1.75 1.75 0 001.75-1.75V7.75a.75.75 0 011.5 0v7.002a3.25 3.25 0 01-3.25 3.25h-9.5a3.25 3.25 0 01-3.25-3.25v-9.5z"
          fill="currentColor"
        />
        <path
          d="M10 6.75a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM10.75 8.5a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5zM6 12a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5A.75.75 0 016 12zM6.65 6a.65.65 0 00-.65.65v2.7c0 .359.291.65.65.65h1.7A.65.65 0 009 9.35v-2.7A.65.65 0 008.35 6h-1.7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.5 2A3.5 3.5 0 003 5.5v13A3.5 3.5 0 006.5 22h13a3.5 3.5 0 003.5-3.5V8.75a.75.75 0 00-1.5 0v9.75a2 2 0 01-2 2h-13a2 2 0 01-2-2v-13a2 2 0 012-2h10a2 2 0 012 2v12.688a.75.75 0 001.5 0V5.5A3.5 3.5 0 0016.5 2h-10z"
          fill="currentColor"
        />
        <path
          d="M12.75 6a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5zM12 9.75a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75zM6.75 12a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM6 15.75a.75.75 0 01.75-.75h9.5a.75.75 0 010 1.5h-9.5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 6C6.56 6 6 6.56 6 7.25v2.5c0 .69.56 1.25 1.25 1.25h2.5c.69 0 1.25-.56 1.25-1.25v-2.5C11 6.56 10.44 6 9.75 6h-2.5zm.25 3.5v-2h2v2h-2z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.5 3A4.5 4.5 0 004 7.5v17A4.5 4.5 0 008.5 29h17a4.5 4.5 0 004.5-4.5V12a1 1 0 10-2 0v12.5a2.5 2.5 0 01-2.5 2.5h-17A2.5 2.5 0 016 24.5v-17A2.5 2.5 0 018.5 5h13A2.5 2.5 0 0124 7.5V24a1 1 0 102 0V7.5A4.5 4.5 0 0021.5 3h-13z"
          fill="currentColor"
        />
        <path
          d="M16.75 8a1 1 0 100 2H21a1 1 0 100-2h-4.25zM15.75 13a1 1 0 011-1H21a1 1 0 110 2h-4.25a1 1 0 01-1-1zM9 16a1 1 0 100 2h12a1 1 0 100-2H9zM8 21a1 1 0 011-1h12a1 1 0 110 2H9a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 8A1.75 1.75 0 008 9.75v2.5c0 .966.784 1.75 1.75 1.75h2.5A1.75 1.75 0 0014 12.25v-2.5A1.75 1.75 0 0012.25 8h-2.5zm.25 4v-2h2v2h-2z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16.5 6A8.5 8.5 0 008 14.5v35a8.5 8.5 0 008.5 8.5h35a8.5 8.5 0 008.5-8.5V28a6 6 0 00-6-6v3a3 3 0 013 3v21.5a5.5 5.5 0 01-5.5 5.5h-35a5.5 5.5 0 01-5.5-5.5v-35A5.5 5.5 0 0116.5 9h27a5.5 5.5 0 015.5 5.5v34a1.5 1.5 0 003 0v-34A8.5 8.5 0 0043.5 6h-27z"
        fill="currentColor"
      />
      <path
        d="M33.5 17a1.5 1.5 0 000 3h9a1.5 1.5 0 000-3h-9zM32 26.5a1.5 1.5 0 011.5-1.5h9a1.5 1.5 0 010 3h-9a1.5 1.5 0 01-1.5-1.5zM17.5 33a1.5 1.5 0 000 3h25a1.5 1.5 0 000-3h-25zM16 42.5a1.5 1.5 0 011.5-1.5h25a1.5 1.5 0 010 3h-25a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25 17A3.25 3.25 0 0016 20.25v5.5A3.25 3.25 0 0019.25 29h5.5A3.25 3.25 0 0028 25.75v-5.5A3.25 3.25 0 0024.75 17h-5.5zM19 20.25a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-5.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNewsFeedRegular)
