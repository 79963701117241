import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconInfoSquareRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 5a1 1 0 11-2 0 1 1 0 012 0zM7.268 6.945a.75.75 0 100 1.5h.25v2.043H7a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-.482V7.695a.75.75 0 00-.75-.75h-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 4.5A3.25 3.25 0 014.5 1.25h7a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7zM4.5 2.75A1.75 1.75 0 002.75 4.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 7a1 1 0 11-2 0 1 1 0 012 0zM9.268 8.945a.75.75 0 100 1.5h.25v2.043H9a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-.482V9.695a.75.75 0 00-.75-.75h-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6A3.75 3.75 0 016 2.25h8A3.75 3.75 0 0117.75 6v8A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6zM6 3.75A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14V6A2.25 2.25 0 0014 3.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.014 7.84a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM10.732 10.5a.75.75 0 000 1.5h.75v4h-1.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-1.25v-4.25c0-.69-.56-1.25-1.25-1.25h-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6.5A4.25 4.25 0 016.5 2.25h11a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11zM6.5 3.75A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-11a2.75 2.75 0 00-2.75-2.75h-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.014 10.763a1.376 1.376 0 11-2.752 0 1.376 1.376 0 012.752 0zM14.5 14.125a.875.875 0 100 1.75h.71v5.25h-1.462a.875.875 0 100 1.75h4.504a.875.875 0 000-1.75h-1.293V15.5c0-.76-.615-1.375-1.374-1.375H14.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8.5A5.5 5.5 0 018.5 3h15A5.5 5.5 0 0129 8.5v15a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15zM8.5 5A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5v-15A3.5 3.5 0 0023.5 5h-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34.003 20.752a2.252 2.252 0 11-4.503 0 2.252 2.252 0 014.503 0zM28.5 27a1.5 1.5 0 000 3H31v12h-4a1.5 1.5 0 000 3h11a1.5 1.5 0 000-3h-4V30a3 3 0 00-3-3h-2.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17.5C7 11.701 11.701 7 17.5 7h29C52.299 7 57 11.701 57 17.5v29C57 52.299 52.299 57 46.5 57h-29C11.701 57 7 52.299 7 46.5v-29zM17.5 10a7.5 7.5 0 00-7.5 7.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29a7.5 7.5 0 00-7.5-7.5h-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconInfoSquareRegular)
