import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { IIconProps } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'
import { TypographySizeType } from '@ui/Typography/types'

import styles from './styles.module.scss'

interface INavItemProps {
  children: string
  fontSize?: TypographySizeType
  LeftIcon?: React.FC<IIconProps>
  RightIcon?: React.FC<IIconProps>
  link?: string
}

const NavItem = ({
  children,
  LeftIcon,
  RightIcon,
  link,
  fontSize,
}: INavItemProps) => (
  <Link to={link} className={styles.item}>
    {LeftIcon && (
      <LeftIcon
        className={styles['before-icon']}
        color={COLORS.BRAND.OCEAN}
        size={20}
      />
    )}

    <Typography
      size={fontSize}
      className={styles.content}
      color={COLORS.GRAY.SAURON}>
      {children}
    </Typography>

    {RightIcon && (
      <RightIcon
        className={styles['after-icon']}
        color={COLORS.GRAY.SAURON}
        size={20}
      />
    )}
  </Link>
)

export default memo(NavItem)
