import { memo } from 'react'

import Button from '@ui/Button'

import styles from './ContentBlock.module.scss'

interface IContentBlock {
  onClickBack: () => void
  onClickNext: () => void
  disabledBack?: boolean
  disabledNext?: boolean
  children?: React.ReactNode
}

const ContentBlock = ({
  onClickBack,
  onClickNext,
  disabledBack,
  disabledNext,
  children,
}: IContentBlock) => {
  return (
    <div className={styles.content}>
      <div className={styles.controlling}>
        <Button
          withBorder
          type="link"
          fullSize
          onClick={onClickBack}
          disabled={disabledBack}>
          Назад
        </Button>
        <Button fullSize onClick={onClickNext} disabled={disabledNext}>
          Далее
        </Button>
      </div>

      <div className={styles.block}>{children}</div>
    </div>
  )
}
export default memo(ContentBlock)
