import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSearchLoupePlusRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.75 4.75a.75.75 0 10-1.5 0v1.5h-1.5a.75.75 0 100 1.5h1.5v1.5a.75.75 0 001.5 0v-1.5h1.5a.75.75 0 100-1.5h-1.5v-1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.996 13.246a6.223 6.223 0 003.857-1.332l2.854 2.853a.75.75 0 101.06-1.06l-2.853-2.854a6.25 6.25 0 10-4.918 2.393zm0-11a4.75 4.75 0 103.313 8.153.785.785 0 01.09-.09 4.75 4.75 0 00-3.403-8.063z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.75 6.25a.75.75 0 10-1.5 0v2h-2a.75.75 0 100 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.502 9a7.498 7.498 0 1113.303 4.745l3.526 3.526a.75.75 0 01-1.06 1.06l-3.526-3.526A7.497 7.497 0 011.503 9zM9 3.002a5.998 5.998 0 100 11.996A5.998 5.998 0 009 3.002z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.75 7a.75.75 0 10-1.5 0v3.25H7a.75.75 0 100 1.5h3.25V15a.75.75 0 001.5 0v-3.25H15a.75.75 0 100-1.5h-3.25V7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.503 10.995a9.492 9.492 0 1118.985 0 9.455 9.455 0 01-2.271 6.161l4.326 4.326a.75.75 0 01-1.061 1.06l-4.326-4.325a9.455 9.455 0 01-6.16 2.27 9.492 9.492 0 01-9.493-9.492zm9.492-7.992a7.992 7.992 0 100 15.984 7.992 7.992 0 000-15.984z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 10a1 1 0 10-2 0v4h-4a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4v-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 15C2.5 8.096 8.096 2.5 15 2.5S27.5 8.096 27.5 15a12.45 12.45 0 01-2.982 8.104l6.19 6.189a1 1 0 01-1.415 1.414l-6.19-6.19A12.45 12.45 0 0115 27.5C8.096 27.5 2.5 21.904 2.5 15zM15 4.5C9.201 4.5 4.5 9.201 4.5 15S9.201 25.5 15 25.5 25.5 20.799 25.5 15 20.799 4.5 15 4.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M31.5 19.5a1.5 1.5 0 00-3 0v9h-9a1.5 1.5 0 000 3h9v9a1.5 1.5 0 003 0v-9h9a1.5 1.5 0 000-3h-9v-9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 30C6 16.745 16.745 6 30 6s24 10.745 24 24a23.909 23.909 0 01-6.002 15.877l11.063 11.062a1.5 1.5 0 01-2.122 2.122L45.877 47.998A23.909 23.909 0 0130 54C16.745 54 6 43.255 6 30zM30 9C18.402 9 9 18.402 9 30s9.402 21 21 21 21-9.402 21-21S41.598 9 30 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSearchLoupePlusRegular)
