import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoVkRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.232 10.714C5.738 10.467 4.69 9.608 3.97 8.45c-.856-1.378-1.235-3.18-1.235-4.822h-1.5c0 1.835.418 3.934 1.46 5.613 1.062 1.71 2.778 2.984 5.27 3.044l.767.019V8.79a5.918 5.918 0 013.49 1.633c.46.444.797.907 1.015 1.293.109.193.183.36.23.488.034.1.045.153.047.169l.002.005v-.007h1.5c0-.226-.063-.464-.137-.67a4.721 4.721 0 00-.335-.72 7.243 7.243 0 00-1.283-1.639 7.485 7.485 0 00-2.063-1.41c.42-.217.815-.47 1.172-.747 1.16-.902 2.074-2.19 2.074-3.558h-1.5c0 .711-.513 1.61-1.495 2.374a6.025 6.025 0 01-2.717 1.189V3.628h-1.5v7.086z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.553 4.563A.625.625 0 012 4.375h2.738c.34 0 .618.272.625.613.07 3.555 1.296 5.289 2.41 5.991V5c0-.345.28-.625.625-.625H11c.345 0 .625.28.625.625v3.13c.403-.186.821-.492 1.216-.906a5.866 5.866 0 001.38-2.394.625.625 0 01.6-.455h2.58a.625.625 0 01.615.733c-.379 2.153-1.717 3.831-2.952 4.824a8.588 8.588 0 013.539 4.903.625.625 0 01-.603.79h-2.838a.625.625 0 01-.596-.434c-.472-1.474-1.55-2.616-2.941-3.012V15c0 .345-.28.625-.625.625h-.286c-2.886 0-5.217-.998-6.832-2.875-1.602-1.863-2.44-4.52-2.507-7.737a.625.625 0 01.178-.45zM2.65 5.625c.154 2.722.922 4.847 2.18 6.31 1.284 1.492 3.133 2.36 5.546 2.435v-2.944a.625.625 0 01.687-.621c2.138.213 3.779 1.68 4.538 3.57h1.533c-.846-2.128-2.47-3.337-3.506-3.824a.625.625 0 01-.048-1.107c1.032-.598 2.447-1.972 3.026-3.819h-1.329a7.174 7.174 0 01-1.531 2.463c-.747.781-1.69 1.38-2.678 1.487a.625.625 0 01-.692-.621V5.625H9.023v6.302a.625.625 0 01-.776.606c-1.887-.472-3.859-2.576-4.11-6.908H2.649z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.822 5.475a.75.75 0 01.535-.225H5.61a.75.75 0 01.75.735c.083 4.256 1.568 6.34 2.903 7.186V6a.75.75 0 01.75-.75h2.954a.75.75 0 01.75.75v3.754c.485-.224.993-.592 1.474-1.088.758-.782 1.375-1.809 1.671-2.868a.75.75 0 01.722-.548h3.062a.75.75 0 01.74.879c-.45 2.582-2.041 4.598-3.51 5.79a10.296 10.296 0 014.206 5.885.75.75 0 01-.724.946h-3.37a.75.75 0 01-.715-.523c-.557-1.758-1.889-3.133-3.556-3.61V18a.75.75 0 01-.75.75h-.26c-3.433 0-6.203-1.199-8.122-3.453-1.903-2.235-2.897-5.423-2.976-9.282a.75.75 0 01.214-.54zM3.136 6.75c.183 3.268 1.095 5.819 2.59 7.575 1.508 1.77 3.672 2.809 6.49 2.916v-3.53a.75.75 0 01.825-.745c2.534.255 4.558 2.009 5.473 4.284h1.807c-1.005-2.556-2.934-4.007-4.163-4.59a.75.75 0 01-.057-1.325c1.224-.717 2.905-2.366 3.593-4.585h-1.565a8.525 8.525 0 01-1.862 2.96c-.905.934-2.045 1.652-3.22 1.78a.75.75 0 01-.83-.745V6.75h-1.455v7.562a.75.75 0 01-.934.727c-2.24-.566-4.637-3.086-4.94-8.289H3.136z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.015 8.55C1.985 7.7 2.666 7 3.51 7h3.62a1.51 1.51 0 011.504 1.454c.226 5.83 2.437 8.693 4.366 9.83V8.5A1.5 1.5 0 0114.5 7h3A1.5 1.5 0 0119 8.5v4.92c.78-.336 1.6-.924 2.356-1.714.976-1.02 1.782-2.31 2.22-3.654A1.519 1.519 0 0125.016 7h3.377c.916 0 1.674.843 1.45 1.809-.783 3.384-2.939 6.015-4.899 7.58a14.522 14.522 0 015.821 7.676c.32.99-.443 1.935-1.42 1.935h-3.77a1.518 1.518 0 01-1.423-1.005c-.864-2.381-2.797-4.239-5.152-4.873v4.508A1.37 1.37 0 0117.63 26c-4.781 0-8.634-1.637-11.324-4.72-2.67-3.058-4.099-7.428-4.29-12.73zM4.037 9c.254 4.756 1.58 8.45 3.776 10.965 2.143 2.456 5.202 3.889 9.187 4.024v-4.511c0-.867.767-1.628 1.717-1.464 3.297.567 5.974 2.94 7.196 5.986h2.708a12.487 12.487 0 00-5.224-6.219c-.97-.572-1.03-1.98-.12-2.636 1.67-1.204 3.608-3.369 4.447-6.145h-2.36c-.556 1.53-1.476 2.953-2.563 4.089-1.156 1.207-2.566 2.156-4.017 2.508-1.008.244-1.784-.582-1.784-1.432V9h-2v10.134c0 .93-.922 1.784-1.97 1.38C10.088 19.38 7.05 15.845 6.658 9h-2.62z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.171 17.592a2.492 2.492 0 012.49-2.58h6.845c1.379 0 2.457 1.107 2.508 2.428.458 12.048 5.034 17.734 8.95 19.766V17.512a2.5 2.5 0 012.5-2.5H34.5a2.5 2.5 0 012.5 2.5v10.161c1.712-.624 3.515-1.896 5.145-3.654 1.864-2.012 3.41-4.564 4.269-7.237a2.537 2.537 0 012.407-1.77h6.381c1.526 0 2.783 1.4 2.415 3-1.544 6.721-5.895 11.896-9.71 14.83 4.03 2.542 9.046 7.354 11.48 14.979.524 1.64-.74 3.209-2.364 3.209h-7.13a2.536 2.536 0 01-2.381-1.691c-1.764-4.931-5.68-8.719-10.512-9.842v9.324c0 1.22-.989 2.209-2.208 2.209-9.054 0-16.34-3.14-21.432-9.01-5.062-5.835-7.816-14.203-8.189-24.428zm3.02.42c.43 9.53 3.04 16.976 7.435 22.042 4.303 4.96 10.447 7.79 18.374 7.967v-9.184c0-1.446 1.28-2.712 2.86-2.43 6.236 1.113 11.157 5.743 13.367 11.623h6.067c-2.335-6.645-6.866-10.779-10.365-12.883-1.605-.966-1.707-3.313-.203-4.419 3.354-2.466 7.266-6.949 8.8-12.716h-5.36c-1.017 2.999-2.749 5.81-4.82 8.046-2.131 2.3-4.716 4.088-7.381 4.763-1.669.422-2.965-.95-2.965-2.37V18.01h-5.037v20.084c0 1.555-1.543 2.974-3.278 2.28-5.468-2.188-11.024-9.007-11.65-22.363H8.191z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoVkRegular)
