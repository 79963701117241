import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoTwitterLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.567 5.535a3.152 3.152 0 013.149-3.15c.798 0 1.529.299 2.085.791.456-.114.884-.29 1.274-.523a.5.5 0 01.732.584 3.19 3.19 0 01-.159.395.5.5 0 01.455.777 5.924 5.924 0 01-1.232 1.34v.096c0 3.711-2.832 8.035-8.036 8.035a8.033 8.033 0 01-4.322-1.26.5.5 0 01.169-.912h.001l.005-.002.022-.004a15.14 15.14 0 00.406-.092c.27-.065.642-.16 1.046-.28.45-.135.915-.294 1.32-.47a5.327 5.327 0 01-1.465-1.083c-1.15-1.218-1.91-3.27-.817-6.563a.5.5 0 01.862-.159 7.03 7.03 0 004.505 2.53v-.05zM5.76 10.287a.5.5 0 01.201.882c-.632.495-1.685.872-2.512 1.12l-.26.075a7.024 7.024 0 002.646.516c4.537 0 7.036-3.753 7.036-7.035 0-.115 0-.218-.005-.318a.5.5 0 01.207-.428l.069-.05a.5.5 0 01-.337-.848l-.061.012a.5.5 0 01-.46-.148 2.143 2.143 0 00-1.568-.68 2.152 2.152 0 00-2.093 2.652.5.5 0 01-.514.602 8.002 8.002 0 01-5.202-2.275c-.61 2.48.06 3.905.837 4.727.447.472.956.774 1.358.958a4.353 4.353 0 00.653.237l.005.001z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.765 7.189A3.639 3.639 0 0113.4 3.553c.938 0 1.796.357 2.444.944a5.704 5.704 0 001.58-.641.5.5 0 01.73.584 3.672 3.672 0 01-.276.638l.02-.009a.5.5 0 01.619.735 6.918 6.918 0 01-1.475 1.594v.158c0 4.353-3.32 9.42-9.42 9.42a9.418 9.418 0 01-5.067-1.479.5.5 0 01.33-.918 5.781 5.781 0 003.274-.57 3.639 3.639 0 01-2.1-2.26.5.5 0 01.143-.525 3.633 3.633 0 01-1.267-2.757v-.043a.5.5 0 01.457-.498 3.623 3.623 0 01-.432-1.721c0-.665.177-1.29.489-1.828a.5.5 0 01.82-.066 8.411 8.411 0 005.499 3.043 3.506 3.506 0 01-.004-.165zm-5.726 2.01a2.644 2.644 0 002.008 1.853.5.5 0 01.036.971 3.5 3.5 0 01-.724.122 2.635 2.635 0 002.115 1.128.5.5 0 01.3.894A6.794 6.794 0 014.834 15.5a8.405 8.405 0 002.789.474c5.431 0 8.42-4.495 8.42-8.42 0-.136 0-.259-.006-.38a.5.5 0 01.207-.429c.114-.082.225-.168.333-.26a7.32 7.32 0 01-.217.031.5.5 0 01-.318-.926c.093-.055.183-.116.268-.182-.173.048-.349.09-.527.125a.5.5 0 01-.46-.148 2.629 2.629 0 00-1.923-.833 2.639 2.639 0 00-2.636 2.636c0 .203.025.406.069.612a.5.5 0 01-.514.603 9.386 9.386 0 01-6.28-2.848A2.643 2.643 0 005.133 8.4a.5.5 0 01-.296.915 3.68 3.68 0 01-.798-.117z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoTwitterLight)
