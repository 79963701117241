import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureACircleUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 11.75a.75.75 0 01-.75-.75V6.572L6.076 7.98a.75.75 0 11-1.152-.96l1.732-2.078c.7-.84 1.989-.84 2.688 0l1.732 2.078a.75.75 0 01-1.152.96L8.75 6.572V11a.75.75 0 01-.75.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15A7 7 0 118 1a7 7 0 010 14zM2.5 8a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 14a.75.75 0 01-.75-.75V8.056l-1.674 2.009a.75.75 0 01-1.152-.96l2.232-2.679c.7-.84 1.989-.84 2.688 0l2.232 2.679a.75.75 0 01-1.152.96L10.75 8.057v5.192A.75.75 0 0110 14z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 17.999A7.999 7.999 0 1110 2 7.999 7.999 0 0110 18zM3.501 10A6.499 6.499 0 1016.5 10 6.499 6.499 0 003.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 17.25a.75.75 0 01-.75-.75V8.81l-2.72 2.72a.75.75 0 01-1.06-1.06l3.005-3.006a2.156 2.156 0 013.05 0l3.005 3.006a.75.75 0 11-1.06 1.06l-2.72-2.72v7.69a.75.75 0 01-.75.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zM3.75 12a8.25 8.25 0 1016.5 0 8.25 8.25 0 00-16.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 23a1 1 0 01-1-1V11.59l-3.308 3.308a1 1 0 11-1.414-1.414l3.6-3.6a2.99 2.99 0 012.002-.877 1.012 1.012 0 01.24 0 2.99 2.99 0 012.001.876l3.601 3.601a1 1 0 01-1.414 1.414L17 11.591V22a1 1 0 01-1 1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 29C8.82 29 3 23.18 3 16S8.82 3 16 3s13 5.82 13 13-5.82 13-13 13zM5 16c0 6.075 4.925 11 11 11s11-4.925 11-11S22.075 5 16 5 5 9.925 5 16z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 46.5a1.5 1.5 0 01-1.5-1.5V21.896l-7.94 7.94a1.5 1.5 0 01-2.12-2.122l8.378-8.378a4.5 4.5 0 016.364 0l8.379 8.378a1.5 1.5 0 01-2.122 2.122l-7.939-7.94V45a1.5 1.5 0 01-1.5 1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 58C17.64 58 6 46.36 6 32S17.64 6 32 6s26 11.64 26 26-11.64 26-26 26zM9 32c0 12.703 10.297 23 23 23s23-10.297 23-23S44.703 9 32 9 9 19.297 9 32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleUpRegular)
