import { memo, useCallback } from 'react'

import FailCard from '@lib/components/FailCard'

import { useSupportStore } from '@modules/support/store/SupportStore'

const FailSupportCard = () => {
  const store = useSupportStore()

  const handleClick = useCallback(() => {
    store.clear()
  }, [])

  return (
    <FailCard
      description="Во время запроса произошла ошибка, попробуйте выполнить запрос повторно."
      titleButton="Повторить запрос"
      handleClick={handleClick}
    />
  )
}

export default memo(FailSupportCard)
