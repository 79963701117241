import { memo, useCallback, useState } from 'react'

import TabsHeader from '@ui/Tabs/components/TabsHeader/TabsHeader'

import { cx } from '@lib/styles'

import styles from './Tabs.module.scss'
import { ITabsProps } from './types'

const Tabs = ({
  tabs,
  size = 'medium',
  type = 'horizontal',
  className,
  activeTabIndex,
  onTabClick,
  defaultActiveKey,
}: ITabsProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(defaultActiveKey)

  const handleTabClick = useCallback((index: number) => {
    onTabClick(index)
    setActiveIndex(index)
  }, [])

  const currentIndex =
    (typeof defaultActiveKey !== 'undefined' && defaultActiveKey) ||
    (typeof activeTabIndex !== 'undefined' ? activeTabIndex : activeIndex)

  return (
    <div className={cx(styles.tab, styles[type])}>
      <TabsHeader
        size={size}
        type={type}
        activeIndex={currentIndex}
        tabs={tabs}
        onTabClick={handleTabClick}
      />
      {tabs.map((tab, index) => (
        <div
          className={cx(className, styles.container, {
            [styles.hidden]: index !== currentIndex,
          })}
          key={index}>
          {tab.item}
        </div>
      ))}
    </div>
  )
}

export default memo(Tabs)
