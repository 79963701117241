import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { IWebinars } from '../models/Webinars'
import { WebinarsRest } from '../services/WebinarsRest'
@singleton()
export class WebinarsStore {
  webinars?: IWebinars[] = undefined
  loading = true

  webinarRest = container.resolve(WebinarsRest)

  constructor() {
    makeAutoObservable(this)
  }

  private setWebinars(data: IWebinars[]) {
    this.webinars = data
  }

  async getWebinars() {
    if (!this.webinars) {
      this.loading = true
      const data = await this.webinarRest.fetchWebinars()

      this.setWebinars(data)
      this.loading = false
    }
  }
}

export const useWebinarsStore = () => container.resolve(WebinarsStore)
