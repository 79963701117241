import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@ui/Button'
import { IconWarningCircleRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { EMainRoutes } from '@lib/navigation'

import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

import styles from './TransactionRejected.module.scss'

const TransactionRejected = () => {
  const navigate = useNavigate()
  const transactionFormStore = useRegisterTransactionFormStore()

  const handleClick = useCallback(() => {
    transactionFormStore.clear()
    transactionFormStore.setEditing(true)
    navigate(EMainRoutes.SalesRegisterTransaction)
  }, [])

  return (
    <CardWrapper py={20} px={20} className={styles.container}>
      <div className={styles.header}>
        <IconWarningCircleRegular size={32} color={COLORS.STATE.ATTENTION} />
        <Typography size={22} color={COLORS.GRAY.OBSIDIAN}>
          Сделка отклонена
        </Typography>
      </div>

      <Button size="small" onClick={handleClick}>
        Отредактировать сделку
      </Button>
    </CardWrapper>
  )
}

export default memo(TransactionRejected)
