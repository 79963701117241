import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextBulletNumbersRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.77 1.937a.625.625 0 01.355.563v4a.625.625 0 01-1.25 0V3.8l-.235.188a.625.625 0 01-.78-.976l1.25-1a.625.625 0 01.66-.075zM7.5 4.25a.75.75 0 100 1.5H13a.75.75 0 000-1.5H7.5zM7.5 10.25a.75.75 0 000 1.5H13a.75.75 0 000-1.5H7.5zM3.04 10.157c.087-.15.16-.207.207-.233a.494.494 0 01.252-.049.41.41 0 01.274.095c.048.044.106.127.106.303v.113c-.032.23-.2.359-.748.695l-.007.004c-.465.285-1.249.766-1.249 1.824v.341c0 .345.28.625.625.625h2.1a.625.625 0 100-1.25H3.196c.087-.148.266-.28.589-.479a8.2 8.2 0 01.091-.055c.429-.257 1.163-.698 1.25-1.61a.626.626 0 00.003-.06v-.148c0-.504-.19-.931-.515-1.228a1.658 1.658 0 00-1.108-.42 1.73 1.73 0 00-.868.207 1.82 1.82 0 00-.677.693.625.625 0 001.078.632z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.023 3.5a.75.75 0 00-1.23-.576l-1.5 1.25a.75.75 0 00.96 1.152l.27-.225V8.5a.75.75 0 001.5 0v-5zM9 5.25a.75.75 0 000 1.5h8a.75.75 0 100-1.5H9zM9 13.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H9zM3.691 12.315c-.064.036-.159.112-.27.303a.75.75 0 11-1.295-.758c.214-.365.485-.66.834-.855a2.13 2.13 0 011.07-.255 2.04 2.04 0 011.364.517c.4.365.634.89.634 1.511v.186a.75.75 0 01-.003.072c-.108 1.123-1.012 1.666-1.549 1.989l-.113.069c-.436.267-.67.444-.773.656h1.808a.75.75 0 010 1.5H2.773a.75.75 0 01-.75-.75v-.426c0-1.305.966-1.897 1.548-2.254l.008-.005c.683-.419.908-.587.95-.892v-.145c0-.228-.077-.34-.144-.401a.544.544 0 00-.363-.127.648.648 0 00-.33.065z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.878 4.2a.75.75 0 00-1.23-.576l-1.8 1.5a.75.75 0 00.96 1.152l.57-.475V10.2a.75.75 0 001.5 0v-6zM10.75 6.25a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM10.75 16.25a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM4.357 14.647c-.107.059-.238.172-.382.42a.75.75 0 11-1.294-.76c.246-.42.554-.752.945-.97.39-.218.804-.29 1.209-.287.54.003 1.102.186 1.537.581.448.408.712.996.712 1.703v.223a.749.749 0 01-.003.072c-.122 1.269-1.14 1.883-1.79 2.274l-.001.001a16.7 16.7 0 00-.133.08c-.642.394-.971.652-1.057 1.066h2.378a.75.75 0 010 1.5h-3.15a.75.75 0 01-.75-.75v-.512c0-1.48 1.092-2.151 1.795-2.582.808-.496 1.154-.732 1.211-1.19v-.182c0-.313-.107-.49-.221-.593a.802.802 0 00-.536-.191c-.222-.001-.364.037-.47.097z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.9 3a.9.9 0 00-1.44-.72l-2 1.5a.9.9 0 101.08 1.44l.56-.42V9a.9.9 0 101.8 0V3zM12 7a1 1 0 011-1h14a1 1 0 110 2H13a1 1 0 01-1-1zM13 15a1 1 0 100 2h14a1 1 0 100-2H13zM13 24a1 1 0 100 2h14a1 1 0 100-2H13zM5.094 13.227c-.078.043-.19.131-.322.352a.9.9 0 01-1.544-.924c.258-.431.584-.778 1.002-1.007.417-.228.855-.3 1.275-.298.567.003 1.162.19 1.628.606.482.43.767 1.053.767 1.794v.219c0 .029-.001.058-.004.087-.07.718-.417 1.237-.825 1.618-.34.317-.756.565-1.076.756l-.097.058c-.502.302-.774.503-.902.73H7.25a.9.9 0 110 1.8H4a.9.9 0 01-.9-.9v-.5c0-1.549 1.165-2.249 1.856-2.664l.08-.048c.363-.218.615-.369.807-.549.16-.148.233-.277.257-.44v-.167c0-.256-.087-.38-.165-.45a.669.669 0 00-.44-.15c-.206-.001-.322.034-.401.077zM8.015 26.536c0 1.238-.914 2.047-1.837 2.343-.926.297-2.117.174-2.942-.685a.9.9 0 111.298-1.247c.251.261.676.352 1.095.218.423-.136.586-.404.586-.629v-.061l.001-.016c-.044-.241-.325-.559-.832-.559h-.422a.9.9 0 01-.635-1.538c.66-.656 1.056-1.105 1.31-1.462H4a.9.9 0 110-1.8h3a.9.9 0 01.88 1.09 4.412 4.412 0 01-.67 1.598c-.138.207-.3.42-.49.648.674.364 1.18 1.003 1.284 1.812.011.085.012.157.012.204V26.536z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14 6a1.5 1.5 0 00-2.4-1.2l-4 3a1.5 1.5 0 001.8 2.4L11 9v9a1.5 1.5 0 003 0V6zM23 12.5a1.5 1.5 0 011.5-1.5h30a1.5 1.5 0 010 3h-30a1.5 1.5 0 01-1.5-1.5zM24.5 30a1.5 1.5 0 000 3h30a1.5 1.5 0 000-3h-30zM24.5 49a1.5 1.5 0 000 3h30a1.5 1.5 0 000-3h-30zM10.536 26.193c-.21.118-.472.344-.76.838a1.5 1.5 0 01-2.59-1.515c.492-.84 1.105-1.505 1.886-1.942.78-.436 1.608-.578 2.417-.574 1.08.006 2.202.372 3.07 1.164.894.814 1.421 1.992 1.421 3.404v.447c0 .047-.002.095-.006.143-.243 2.537-2.275 3.764-3.573 4.548l-.267.162c-1.28.787-1.938 1.303-2.108 2.132h4.954a1.5 1.5 0 010 3h-6.5a1.5 1.5 0 01-1.5-1.5v-1.023c0-2.96 2.18-4.302 3.583-5.165 1.613-.99 2.303-1.463 2.417-2.378v-.366c0-.628-.214-.98-.442-1.187-.252-.23-.63-.379-1.066-.381-.442-.003-.724.075-.936.193zM8.5 43a1.5 1.5 0 000 3h3.787a7.702 7.702 0 01-.292.465c-.53.784-1.321 1.678-2.638 2.96A1.5 1.5 0 0010.403 52h.846c1.151 0 1.864.74 1.964 1.395 0 .026-.002.081-.002.177 0 .62-.451 1.246-1.381 1.544-.923.296-1.9.108-2.498-.515a1.5 1.5 0 10-2.164 2.079c1.556 1.618 3.808 1.86 5.578 1.293 1.762-.565 3.464-2.093 3.464-4.401 0-.032.003-.103.003-.169 0-.087-.002-.218-.022-.37-.211-1.633-1.304-2.907-2.736-3.557.394-.46.733-.898 1.026-1.332.725-1.073 1.14-2.075 1.464-3.242A1.5 1.5 0 0014.5 43h-6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletNumbersRegular)
