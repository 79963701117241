import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSettingsSlidersH3Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.563 3H1.5a.5.5 0 000 1h7.063a2 2 0 003.874 0H13.5a.5.5 0 000-1h-1.063a2 2 0 00-3.874 0zm.937.5a1 1 0 112 0 1 1 0 01-2 0zM4.5 5.5A2 2 0 016.437 7H13.5a.5.5 0 010 1H6.437a2 2 0 01-3.874 0H1.5a.5.5 0 010-1h1.063A2 2 0 014.5 5.5zm0 1a1 1 0 110 2 1 1 0 010-2zM1 11.5a.5.5 0 01.5-.5h7.063a2 2 0 013.874 0H13.5a.5.5 0 010 1h-1.063a2 2 0 01-3.874 0H1.5a.5.5 0 01-.5-.5zm10.5 0a1 1 0 10-2 0 1 1 0 002 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.05 4H2.5a.5.5 0 000 1h8.55a2.5 2.5 0 004.9 0h1.55a.5.5 0 000-1h-1.55a2.5 2.5 0 00-4.9 0zm.95.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM2 14.5a.5.5 0 01.5-.5h8.55a2.5 2.5 0 014.9 0h1.55a.5.5 0 010 1h-1.55a2.5 2.5 0 01-4.9 0H2.5a.5.5 0 01-.5-.5zM13.5 13a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM2.5 9a.5.5 0 000 1h1.55a2.5 2.5 0 004.9 0h8.55a.5.5 0 000-1H8.95a2.5 2.5 0 00-4.9 0H2.5zm5.5.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSettingsSlidersH3Light)
