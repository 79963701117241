import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCopyRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.752 0a2.75 2.75 0 00-2.75 2.75v7.5A2.75 2.75 0 004.752 13h5.496a2.75 2.75 0 002.75-2.75v-7.5A2.75 2.75 0 0010.248 0H4.752zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h5.496c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25H4.752c-.69 0-1.25-.56-1.25-1.25v-7.5z"
          fill="currentColor"
        />
        <path
          d="M15.5 5.5a.75.75 0 00-1.5 0v5.247a3.25 3.25 0 01-3.25 3.25H6.5a.75.75 0 000 1.5h4.25a4.75 4.75 0 004.75-4.75V5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.249 1.001a3.25 3.25 0 00-3.25 3.25v9.5a3.25 3.25 0 003.25 3.25h5.5a3.25 3.25 0 003.25-3.25v-9.5a3.25 3.25 0 00-3.25-3.25h-5.5zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h5.5c.966 0 1.75.784 1.75 1.75v9.5a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75v-9.5z"
          fill="currentColor"
        />
        <path
          d="M17.563 7.5a.75.75 0 00-1.5 0v6.781a3.75 3.75 0 01-3.75 3.75H7.5a.75.75 0 000 1.5h4.813c2.899 0 5.25-2.35 5.25-5.25V7.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.25A3.75 3.75 0 003.25 5v12A3.75 3.75 0 007 20.75h8A3.75 3.75 0 0018.75 17V5A3.75 3.75 0 0015 1.25H7zM4.75 5A2.25 2.25 0 017 2.75h8A2.25 2.25 0 0117.25 5v12A2.25 2.25 0 0115 19.25H7A2.25 2.25 0 014.75 17V5z"
          fill="currentColor"
        />
        <path
          d="M21.75 9a.75.75 0 00-1.5 0v8.5a4.75 4.75 0 01-4.75 4.75H9a.75.75 0 000 1.5h6.5a6.25 6.25 0 006.25-6.25V9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 0A5.5 5.5 0 004 5.5v17A5.5 5.5 0 009.5 28h11a5.5 5.5 0 005.5-5.5v-17A5.5 5.5 0 0020.5 0h-11zM6 5.5A3.5 3.5 0 019.5 2h11A3.5 3.5 0 0124 5.5v17a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 016 22.5v-17z"
          fill="currentColor"
        />
        <path
          d="M30 13a1 1 0 10-2 0v10a7 7 0 01-7 7h-9a1 1 0 100 2h9a9 9 0 009-9V13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 4C13.701 4 9 8.701 9 14.5v31C9 51.299 13.701 56 19.5 56h21C46.299 56 51 51.299 51 45.5v-31C51 8.701 46.299 4 40.5 4h-21zM12 14.5A7.5 7.5 0 0119.5 7h21a7.5 7.5 0 017.5 7.5v31a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-31z"
        fill="currentColor"
      />
      <path
        d="M58 24.5a1.5 1.5 0 00-3 0v23C55 54.404 49.404 60 42.5 60h-20a1.5 1.5 0 000 3h20C51.06 63 58 56.06 58 47.5v-23z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCopyRegular)
