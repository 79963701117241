import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople2Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 1.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM3.75 5a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zM12.07 3.02a2.5 2.5 0 100 4.999 2.5 2.5 0 000-5zm-1 2.5a1 1 0 112 0 1 1 0 01-2 0z"
          fill="currentColor"
        />
        <path
          d="M11.024 10.505l-.153.001a3.682 3.682 0 00-.44-.712 3.85 3.85 0 00-.686-.676 6.962 6.962 0 011.28-.114c.57 0 1.512.062 2.338.442.423.194.844.484 1.159.919.32.443.5.99.5 1.634v.749a.75.75 0 01-1.5 0V12c0-.355-.094-.589-.214-.755-.126-.173-.316-.32-.57-.436-.53-.243-1.21-.304-1.713-.304zM2.524 11.34c-.159.201-.279.485-.279.921v.493a.75.75 0 01-1.5 0v-.493c0-.741.215-1.36.6-1.849.377-.479.877-.785 1.377-.984.98-.39 2.106-.424 2.773-.424.668 0 1.794.034 2.773.424.5.199 1.001.505 1.378.984.384.49.6 1.107.6 1.849v.493a.75.75 0 11-1.5 0v-.493c0-.436-.12-.72-.28-.921-.166-.213-.417-.385-.752-.519-.69-.274-1.564-.317-2.219-.317-.654 0-1.528.043-2.218.317-.335.134-.586.306-.753.518z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM4.75 6.5a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM14.896 4.917a2.833 2.833 0 100 5.667 2.833 2.833 0 000-5.667zM13.562 7.75a1.333 1.333 0 112.667 0 1.333 1.333 0 01-2.666 0z"
          fill="currentColor"
        />
        <path
          d="M14.675 13.26a7.69 7.69 0 00-.57.02 4.245 4.245 0 00-.844-1.407c.461-.079.94-.114 1.414-.114 1.072 0 2.17.178 3.03.64.894.48 1.561 1.298 1.561 2.478v.704a.75.75 0 01-1.5 0v-.704c0-.508-.247-.875-.77-1.156-.559-.3-1.38-.462-2.321-.462zM2.675 13.701c-.254.306-.43.72-.43 1.324v.561a.75.75 0 01-1.5 0v-.561c0-.93.283-1.69.777-2.283.482-.58 1.125-.954 1.777-1.2 1.287-.485 2.783-.53 3.698-.53s2.411.045 3.698.53c.652.246 1.295.62 1.778 1.2.493.593.777 1.353.777 2.283v.561a.75.75 0 11-1.5 0v-.561c0-.603-.176-1.018-.43-1.324-.266-.319-.657-.568-1.154-.755-1.01-.38-2.265-.434-3.169-.434-.904 0-2.159.053-3.169.434-.496.187-.888.436-1.153.755z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 4.25a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm-3 4.5a3 3 0 116 0 3 3 0 01-6 0zM17.875 7.25a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zm-1.75 3.25a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z"
          fill="currentColor"
        />
        <path
          d="M17.5 16.75c-.278 0-.592.007-.918.034a5.088 5.088 0 00-.866-1.403 10.656 10.656 0 011.784-.131c.751 0 2.01.04 3.1.502.557.236 1.103.595 1.509 1.144.412.557.641 1.258.641 2.104v1a.75.75 0 01-1.5 0v-1c0-.563-.147-.942-.347-1.212-.205-.278-.505-.492-.888-.654-.788-.334-1.779-.384-2.515-.384zM3.309 17.326c-.332.431-.559 1.019-.559 1.852V20a.75.75 0 01-1.5 0v-.822c0-1.13.317-2.046.868-2.765.545-.71 1.28-1.18 2.043-1.49 1.503-.614 3.258-.673 4.339-.673 1.08 0 2.836.059 4.34.673.762.31 1.497.78 2.042 1.49.551.719.868 1.635.868 2.765V20a.75.75 0 01-1.5 0v-.822c0-.833-.227-1.42-.559-1.852-.338-.442-.825-.772-1.419-1.015-1.21-.493-2.705-.561-3.772-.561-1.067 0-2.563.068-3.772.561-.594.243-1.08.573-1.42 1.015z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 5a6 6 0 100 12 6 6 0 000-12zm-4 6a4 4 0 118 0 4 4 0 01-8 0zM23.75 9a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm-2.25 4.25a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
          fill="currentColor"
        />
        <path
          d="M23.5 21c-.518 0-1.136.02-1.748.114a6.828 6.828 0 00-1.247-1.78c1.118-.303 2.26-.334 2.995-.334.919 0 2.47.049 3.819.614.689.29 1.37.732 1.879 1.413.516.692.802 1.561.802 2.606V24a1 1 0 11-2 0v-.367c0-.662-.174-1.1-.405-1.41-.239-.32-.592-.572-1.05-.764C25.597 21.06 24.399 21 23.5 21zM4.732 22.093C4.3 22.67 4 23.455 4 24.57V25a1 1 0 11-2 0v-.43c0-1.501.413-2.72 1.134-3.68.713-.947 1.677-1.574 2.678-1.99 1.973-.822 4.274-.9 5.688-.9 1.414 0 3.715.078 5.688.9 1.001.416 1.965 1.043 2.678 1.99.721.96 1.134 2.179 1.134 3.68V25a1 1 0 11-2 0v-.43c0-1.115-.299-1.9-.732-2.477-.442-.587-1.076-1.026-1.849-1.347C14.844 20.09 12.895 20 11.5 20c-1.395 0-3.344.09-4.92.746-.772.321-1.406.76-1.848 1.347z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 11.5c-6.351 0-11.5 5.149-11.5 11.5S16.649 34.5 23 34.5 34.5 29.351 34.5 23 29.351 11.5 23 11.5zM14.5 23a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0zM47.5 20a8 8 0 100 16 8 8 0 000-16zm-5 8a5 5 0 1110 0 5 5 0 01-10 0z"
        fill="currentColor"
      />
      <path
        d="M47 41c-1.362 0-3.103.064-4.757.427-.18-.258-.37-.51-.57-.754a12.817 12.817 0 00-1.711-1.72C42.483 38.087 45.252 38 47 38c1.914 0 5.052.105 7.75 1.222 1.37.568 2.699 1.425 3.684 2.729C59.432 43.27 60 44.947 60 47v.5a1.5 1.5 0 01-3 0V47c0-1.47-.395-2.494-.959-3.24-.577-.764-1.41-1.34-2.44-1.767C51.498 41.123 48.886 41 47 41zM8.6 44.34C7.639 45.566 7 47.208 7 49.483V50.5a1.5 1.5 0 01-3 0v-1.017c0-2.884.827-5.195 2.241-6.996 1.399-1.78 3.294-2.965 5.285-3.758C15.466 37.157 20.096 37 23 37c2.905 0 7.534.157 11.474 1.729 1.991.793 3.886 1.978 5.285 3.758C41.173 44.288 42 46.598 42 49.483V50.5a1.5 1.5 0 01-3 0v-1.017c0-2.274-.638-3.917-1.6-5.143-.98-1.246-2.375-2.163-4.036-2.825C30 40.175 25.879 40 23 40c-2.88 0-7 .175-10.364 1.515-1.66.663-3.056 1.579-4.035 2.825z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople2Regular)
