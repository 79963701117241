import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiMenuDots3HCircleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 8.901A.901.901 0 1011 7.1a.901.901 0 000 1.802zM8.901 8A.901.901 0 117.1 8 .901.901 0 018.9 8zM5 8.901A.901.901 0 105 7.1a.901.901 0 000 1.802z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.004a7 7 0 100 14 7 7 0 000-14zm-6 7a6 6 0 1112 0 6 6 0 01-12 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14 11a1 1 0 100-2 1 1 0 000 2zM11 10a1 1 0 11-2 0 1 1 0 012 0zM6 11a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 100 16 8 8 0 000-16zm-7 8a7 7 0 1114 0 7 7 0 01-14 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiMenuDots3HCircleLight)
