import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTextAlignCenterLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.5 3a.5.5 0 000 1h11a.5.5 0 000-1h-11zM4 6.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM2.5 9a.5.5 0 000 1h11a.5.5 0 000-1h-11zM4.5 12a.5.5 0 000 1h7a.5.5 0 000-1h-7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3.5 3a.5.5 0 100 1h13a.5.5 0 000-1h-13zM5 7.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zM3.5 11a.5.5 0 000 1h13a.5.5 0 000-1h-13zM5.5 15a.5.5 0 000 1h9a.5.5 0 000-1h-9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextAlignCenterLight)
