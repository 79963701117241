import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookOpenTextLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.25 6.5a.5.5 0 01.5-.5H8a.5.5 0 110 1H3.75a.5.5 0 01-.5-.5zM3.75 8a.5.5 0 100 1H6.5a.5.5 0 000-1H3.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.862 2.068c1.026.125 2.15.449 3.064 1.187.04.032.108.032.148 0 .914-.738 2.038-1.062 3.064-1.187 1.03-.125 2-.055 2.646.028.736.095 1.216.732 1.216 1.42v7.891c0 .923-.807 1.593-1.662 1.578-1.236-.022-3.077.148-4.283 1.112-.594.475-1.516.475-2.11 0-1.206-.964-3.047-1.134-4.283-1.112C1.807 13 1 12.33 1 11.407v-7.89c0-.69.48-1.326 1.215-1.42a10.903 10.903 0 012.647-.029zm-.12.993a9.906 9.906 0 00-2.399.027c-.185.024-.343.19-.343.429v7.89c0 .298.28.584.645.578 1.267-.022 3.425.132 4.925 1.331.228.183.632.183.86 0 1.5-1.199 3.658-1.353 4.925-1.331.364.006.645-.28.645-.578v-7.89c0-.24-.158-.405-.343-.429a9.906 9.906 0 00-2.398-.027c-.922.112-1.841.394-2.556.971-.407.33-1 .33-1.406 0-.715-.577-1.634-.86-2.556-.971z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.5 8.5A.5.5 0 015 8h5a.5.5 0 010 1H5a.5.5 0 01-.5-.5zM5 10a.5.5 0 100 1h3.5a.5.5 0 000-1H5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.471 3.09c1.174.156 2.443.542 3.464 1.398A.1.1 0 0010 4.51a.1.1 0 00.065-.022c1.02-.856 2.29-1.242 3.463-1.397 1.177-.156 2.296-.084 3.057.01.854.104 1.415.843 1.415 1.648v8.769c0 1.146-1.061 1.944-2.114 1.866-1.45-.107-3.456.028-4.774 1.217a1.657 1.657 0 01-1.112.408 1.66 1.66 0 01-1.11-.411c-1.312-1.188-3.323-1.321-4.777-1.213C3.06 15.461 2 14.664 2 13.518V4.75c0-.805.561-1.544 1.415-1.648.761-.094 1.88-.166 3.056-.01zm-.13.992c-1.064-.14-2.092-.076-2.805.011-.298.037-.536.3-.536.656v8.77c0 .476.474.91 1.039.868 1.502-.11 3.885-.012 5.522 1.47.101.09.26.152.441.152a.66.66 0 00.44-.15c1.642-1.482 4.018-1.583 5.518-1.472.566.041 1.04-.393 1.04-.87V4.75c0-.355-.238-.62-.536-.656-.713-.087-1.741-.151-2.804-.01-1.067.14-2.13.482-2.953 1.172-.406.34-1.008.34-1.414 0-.824-.69-1.886-1.032-2.953-1.173z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookOpenTextLight)
