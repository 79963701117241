import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCheckSquareLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.04 6.009a.5.5 0 10-.707-.707L6.845 8.79a.5.5 0 01-.707 0L4.667 7.32a.5.5 0 10-.708.707l1.472 1.471a1.5 1.5 0 002.121 0l3.489-3.488z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1a3 3 0 00-3 3v7a3 3 0 003 3h7a3 3 0 003-3V4a3 3 0 00-3-3H4zM2 4a2 2 0 012-2h7a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.744 7.333a.5.5 0 10-.707-.707l-4.216 4.216a.75.75 0 01-1.06 0L6.045 9.127a.5.5 0 10-.707.708l1.714 1.714a1.75 1.75 0 002.475 0l4.216-4.216z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2A3.5 3.5 0 002 5.5v8A3.5 3.5 0 005.5 17h8a3.5 3.5 0 003.5-3.5v-8A3.5 3.5 0 0013.5 2h-8zM3 5.5A2.5 2.5 0 015.5 3h8A2.5 2.5 0 0116 5.5v8a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 013 13.5v-8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckSquareLight)
