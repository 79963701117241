import * as Sentry from '@sentry/react'

export default class EventLogger {
  static isProduction = process.env.NODE_ENV !== 'development'

  static init() {
    if (this.isProduction)
      Sentry.init({
        dsn: 'https://b1d7ef01431f47e7bd7c9dd90cc5dfb7@o967827.ingest.sentry.io/6695451',
      })
  }

  static logError(
    error: Error,
    properties?: Record<string, string | number | boolean>,
  ) {
    if (this.isProduction) {
      Sentry.configureScope((scope) =>
        scope.setLevel('error').setExtra('properties', properties),
      )

      return Sentry.captureException(error)
    }
  }
}
