import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import PageWrapper from '@lib/components/Page/PageWrapper'

import CalendarItem from '@modules/marketing/components/EventsCalendar'
import { useCalendarStore } from '@modules/marketing/store/CalendarStore'

import styles from './styles.module.scss'

const EventsCalendar = () => {
  const calendarStore = useCalendarStore()

  useEffect(() => {
    calendarStore.getCalendarEvents()
  }, [])

  return (
    <PageWrapper
      isLoading={calendarStore.isLoading}
      title="Календарь мероприятий">
      <div className={styles.calendar}>
        {calendarStore.calendarEventsData?.map(
          ({ id, address, date, file, shortTitle, timeStart, timeEnd }) => {
            return (
              <CalendarItem
                id={id}
                address={address}
                date={date}
                file={file}
                key={id}
                shortTitle={shortTitle}
                timeStart={timeStart}
                timeEnd={timeEnd}
              />
            )
          },
        )}
      </div>
    </PageWrapper>
  )
}
export default observer(EventsCalendar)
