import { observer } from 'mobx-react-lite'

import Card from '@ui/Card'
import Divider from '@ui/Divider'
import {
  IconArrowCRightLight,
  IconBagBriefcaseLight,
  IconBagBriefcaseRegular,
  IconCalendarLight,
  IconMarketReceiptLight,
  IconMarketShoppingBasketLight,
  IconMarketShoppingBasketRegular,
  IconMoneyTypeCoinsLight,
  IconMoneyTypeCoinsRegular,
  IconPeople3Light,
  IconPlusSquareLight,
  IconQuestionCircleLight,
} from '@ui/icons'
import { Typography } from '@ui/Typography'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import StatusCard from '@modules/company/components/StatusCard'
import Banner from '@modules/home/components/Banner'
import NavItem from '@modules/home/components/NavItem'
import { useBannerStore } from '@modules/home/store/bannerStore'

import styles from './styles.module.scss'

const MainPage = () => {
  const bannerStore = useBannerStore()

  return (
    <PageWrapper title="Главная">
      <div className={styles.container}>
        {bannerStore.bannerData?.view && (
          <div className={styles['start-cards']}>
            <Banner
              url={bannerStore.bannerData?.url}
              buttonText={bannerStore.bannerData?.buttonText}
              title={bannerStore.bannerData?.title}
              imageUrl={bannerStore.bannerData?.file?.url}
            />
            <StatusCard />
          </div>
        )}

        <div className={styles.cards}>
          <Card
            fullWidth
            Icon={IconMoneyTypeCoinsRegular}
            title="Продажи"
            description="Управление вашими продажами"
            link={EMainRoutes.MainSales}
          />
          <Card
            fullWidth
            Icon={IconBagBriefcaseRegular}
            title="Маркетинг"
            description="Управление вашими продажами"
            link={EMainRoutes.MainMarketing}
          />
          <Card
            fullWidth
            Icon={IconMarketShoppingBasketRegular}
            title="Конфигуратор"
            description="Настроить и оформить конфигурацию"
            link={EMainRoutes.MainConfigurator}
          />
        </div>

        <div className={styles.services}>
          <Typography size={18} weight="medium">
            Все сервисы
          </Typography>

          <div className={styles.row}>
            <div className={styles.column}>
              <NavItem
                LeftIcon={IconPlusSquareLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.SalesRegisterTransaction}>
                Зарегистрировать сделку
              </NavItem>
              <Divider />
              <NavItem
                LeftIcon={IconMoneyTypeCoinsLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MainSales}>
                Продажи
              </NavItem>
              <Divider />
              <NavItem
                LeftIcon={IconCalendarLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MarketingEventsCalendar}>
                Календарь событий
              </NavItem>
            </div>

            <div className={styles.column}>
              <NavItem
                LeftIcon={IconMarketReceiptLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MarketingFunds}>
                Заявка на маркетинговые средства
              </NavItem>
              <Divider />
              <NavItem
                LeftIcon={IconPeople3Light}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MarketingWebinars}>
                Вебинары
              </NavItem>
              <Divider />
              <NavItem
                LeftIcon={IconBagBriefcaseLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MainMarketing}>
                Маркетинг
              </NavItem>
            </div>

            <div className={styles.column}>
              <NavItem
                LeftIcon={IconMarketShoppingBasketLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MainConfigurator}>
                Конфигуратор
              </NavItem>
              <Divider />
              <NavItem
                LeftIcon={IconQuestionCircleLight}
                RightIcon={IconArrowCRightLight}
                link={EMainRoutes.MainSupport}>
                Поддержка
              </NavItem>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default observer(MainPage)
