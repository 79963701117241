import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiFilterFunnelLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3.25C1 2.56 1.56 2 2.25 2h11.5c.69 0 1.25.56 1.25 1.25v1.708a1.5 1.5 0 01-.636 1.226L10 9.259v3.473a1 1 0 01-.445.833l-2 1.333A1 1 0 016 14.066V9.259L1.636 6.184A1.5 1.5 0 011 4.958V3.25zM2.25 3a.25.25 0 00-.25.25v1.708a.5.5 0 00.212.408l4.364 3.076A1 1 0 017 9.259v4.807l2-1.334V9.26a1 1 0 01.424-.817l4.364-3.076A.5.5 0 0014 4.958V3.25a.25.25 0 00-.25-.25H2.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.25C2 3.56 2.56 3 3.25 3h12.5c.69 0 1.25.56 1.25 1.25v1.77a1.5 1.5 0 01-.563 1.17L12 10.74v4.483a1 1 0 01-.47.848l-3 1.875A1 1 0 017 17.098V10.74L2.563 7.19A1.5 1.5 0 012 6.02V4.25zM3.25 4a.25.25 0 00-.25.25v1.77a.5.5 0 00.188.39l4.437 3.55a1 1 0 01.375.78v6.358l3-1.875V10.74a1 1 0 01.375-.78l4.437-3.55A.5.5 0 0016 6.02V4.25a.25.25 0 00-.25-.25H3.25z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiFilterFunnelLight)
