import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSendPaperplaneRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.674 2.934C1.437 1.714 2.702.751 3.815 1.305l10.748 5.352c1.108.552 1.108 2.134 0 2.686L3.815 14.695c-1.113.554-2.378-.408-2.141-1.629L2.658 8l-.984-5.066zM4.041 8.75l-.895 4.602L13.894 8 3.146 2.648l.895 4.602h3.722a.75.75 0 110 1.5H4.041z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.643 3.927C2.39 2.62 3.746 1.587 4.94 2.181l12.81 6.38c1.189.591 1.189 2.287 0 2.878L4.94 17.82c-1.193.594-2.55-.438-2.296-1.746L3.823 10l-1.18-6.073zm2.563 6.823l-1.09 5.609a.1.1 0 00.003.06.114.114 0 00.037.047.115.115 0 00.055.023.1.1 0 00.059-.013l12.81-6.38a.1.1 0 00.046-.038.113.113 0 00.015-.058.114.114 0 00-.015-.058.1.1 0 00-.045-.039L4.27 3.523a.1.1 0 00-.059-.012.115.115 0 00-.055.023.115.115 0 00-.037.047.1.1 0 00-.003.06l1.09 5.609H9.72a.75.75 0 010 1.5H5.206z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.307 4.634c-.276-1.425 1.202-2.547 2.5-1.898l15.397 7.699c1.29.645 1.29 2.485 0 3.13L5.807 21.264c-1.298.649-2.776-.473-2.5-1.898L4.732 12 3.307 4.634zm2.808 8.116l-1.336 6.9a.25.25 0 00.358.272l15.396-7.698a.25.25 0 000-.447L5.137 4.078a.25.25 0 00-.358.271l1.336 6.901h5.51a.75.75 0 110 1.5h-5.51z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33 5.896c-.501-2.286 1.9-4.113 3.97-3.02l20.238 10.692c1.954 1.033 1.954 3.831 0 4.864L8.3 29.123c-2.07 1.094-4.471-.733-3.97-3.02L6.54 16 4.33 5.896zM8.37 17l-2.087 9.532a.75.75 0 001.083.823l20.238-10.692a.75.75 0 000-1.326L7.366 4.645a.75.75 0 00-1.083.824L8.369 15h7.753a1 1 0 110 2H8.37z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.147 11.686c-.91-4.157 3.457-7.478 7.22-5.49l40.476 21.383c3.552 1.877 3.552 6.965 0 8.842L16.367 57.805c-3.763 1.987-8.13-1.334-7.22-5.49L13.594 32 9.147 11.686zm7.19 21.814l-4.26 19.456c-.364 1.663 1.383 2.991 2.889 2.196L55.441 33.77c1.42-.751 1.42-2.786 0-3.537L14.965 8.848c-1.505-.795-3.252.534-2.887 2.196L16.335 30.5h15.91a1.5 1.5 0 010 3h-15.91z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSendPaperplaneRegular)
