import { memo, useCallback } from 'react'

import TabsHeaderItem from '@ui/Tabs/components/TabsHeaderItem/TabsHeaderItem'
import { ITabItem, TPositionType, TSizeType } from '@ui/Tabs/types'

import styles from './TabsHeader.module.scss'

export interface ITabHeaderItem {
  tabs: ITabItem[]
  activeIndex: number
  onTabClick: (index: number) => void
  size?: TSizeType
  type?: TPositionType
}

const TabsHeader = ({
  tabs,
  activeIndex,
  onTabClick,
  size,
  type,
}: ITabHeaderItem) => {
  const handleClick = useCallback((index: number) => {
    onTabClick(index)
  }, [])

  return (
    <div className={styles[type]}>
      {tabs.map((tab, index) => (
        <TabsHeaderItem
          key={index}
          size={size}
          type={type}
          badgeCount={tab.badgeCount}
          disabled={tab.disabled}
          label={tab.label}
          typeLabel={tab.typeLabel}
          onClick={() => handleClick(index)}
          active={index === activeIndex}
          classNameLabel={tab.classNameLabel}
        />
      ))}
    </div>
  )
}

export default memo(TabsHeader)
