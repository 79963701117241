import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'

import { Form } from '@lib/components/Form/Form'
import PhoneInput from '@lib/components/PhoneInput'

import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'
import { useOnboardingFormStore } from '@modules/auth/store/OnboardingFormStore'

import { FormAnimation } from '../FormAnimation/FormAnimation'
import { FormWindowLayout } from '../FormWindowLayout/FormWindowLayout'

import styles from './OnboardingForm.module.scss'

export const OnboardingForm: React.FC = observer(() => {
  const onboardingStore = useOnboardingFormStore()
  const animatedStore = useAnimatedFormStore()

  const handleFieldChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      onboardingStore.form.handleFieldChange(name, value)
    },
    [],
  )

  return (
    onboardingStore.showOnboarding && (
      <FormWindowLayout>
        <FormAnimation animationDirection={animatedStore.animatedState}>
          <Form
            title="Последний шаг регистрации"
            titleClassName={styles.titleClassName}>
            <Input
              name="legalAddress"
              label="Данные организации"
              placeholder="Юридический адрес"
              id="legal-address"
              className={styles.mb20}
              value={onboardingStore.form.fields.legalAddress.value}
              error={onboardingStore.form.fields.legalAddress.error}
              errorText={onboardingStore.form.fields.legalAddress.errorText}
              onChange={handleFieldChange}
            />
            <Input
              name="actualAddress"
              placeholder="Фактический адрес"
              id="actual-address"
              className={styles.mb20}
              value={onboardingStore.form.fields.actualAddress.value}
              error={onboardingStore.form.fields.actualAddress.error}
              errorText={onboardingStore.form.fields.actualAddress.errorText}
              onChange={handleFieldChange}
            />
            <Input
              name="email"
              placeholder="Электронная почта"
              className={styles.mb20}
              id="email"
              value={onboardingStore.form.fields.email.value}
              error={onboardingStore.form.fields.email.error}
              errorText={onboardingStore.form.fields.email.errorText}
              onChange={handleFieldChange}
            />
            <PhoneInput
              name="phone"
              placeholder="Телефон"
              id="phone"
              className={styles.mb20}
              value={onboardingStore.form.fields.phone.value}
              error={onboardingStore.form.fields.phone.error}
              errorText={onboardingStore.form.fields.phone.errorText}
              onChange={handleFieldChange}
            />
            <Button
              fullSize
              disabled={onboardingStore.isDisabled}
              onClick={onboardingStore.handleSubmit}>
              Отправить
            </Button>
          </Form>
        </FormAnimation>
      </FormWindowLayout>
    )
  )
})
