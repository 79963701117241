import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import { cx } from '@lib/styles'

import { COLORS } from '../Theme/color'
import { Typography } from '../Typography'

import { DROP_LIST_SIZES } from './constants'
import { DropItemList } from './DropItemList'
import styles from './DropList.module.scss'
import { IDropListProps } from './types'

const DropList: React.FC<IDropListProps> = ({
  items,
  className,
  size = 'medium',
  renderOpener,
}) => {
  const [visible, setVisible] = useState(false)

  const classNames = cx(styles.container, styles[size], className)

  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose()
    }
  }

  const onToggle = useCallback((event) => {
    event.stopPropagation()
    setVisible((value) => !value)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <div ref={visible === true ? wrapperRef : null} className={styles.content}>
      {renderOpener({ onOpen, visible, onClose, onToggle })}
      {visible && (
        <div className={classNames}>
          {items.map(
            (
              {
                lineTop,
                title,
                label,
                color,
                onClick,
                comment,
                disabled,
                IconLeft,
                lineDown,
              },
              index,
            ) => (
              <React.Fragment key={index}>
                {lineTop && <span className={styles.line} />}
                {title && (
                  <Typography
                    size={DROP_LIST_SIZES[size].title}
                    color={COLORS.GRAY.GRAY_2}
                    className={styles[`title_${size}`]}>
                    {title}
                  </Typography>
                )}
                {label && (
                  <DropItemList
                    color={color}
                    size={size}
                    label={label}
                    onClick={onClick}
                    comment={comment}
                    disabled={disabled}
                    IconLeft={IconLeft}
                  />
                )}
                {lineDown && <span className={styles.line} />}
              </React.Fragment>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export default memo(DropList)
