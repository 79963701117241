import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconQuestionSquareRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 11a1 1 0 11-2 0 1 1 0 012 0zM6.76 6.348c0-.48.47-1.018 1.24-1.018s1.24.537 1.24 1.018c0 .359-.242.73-.695.913-.525.214-1.045.606-1.23 1.258a.75.75 0 101.444.409c.013-.046.07-.163.35-.277.92-.374 1.63-1.234 1.63-2.303C10.74 4.877 9.428 3.83 8 3.83c-1.427 0-2.74 1.047-2.74 2.518a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 4.5A3.25 3.25 0 014.5 1.25h7a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7zM4.5 2.75A1.75 1.75 0 002.75 4.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 14a1 1 0 11-2 0 1 1 0 012 0zM8.375 8.078c0-.642.63-1.328 1.625-1.328s1.625.686 1.625 1.328c0 .324-.09.504-.2.637-.13.16-.325.293-.617.466l-.114.065c-.246.142-.576.332-.833.571-.346.321-.611.765-.611 1.378a.75.75 0 001.5 0c0-.12.032-.186.131-.278.124-.115.284-.208.538-.357l.149-.086c.3-.177.7-.424 1.015-.805.336-.407.542-.927.542-1.59 0-1.654-1.496-2.829-3.125-2.829S6.875 6.425 6.875 8.078a.75.75 0 101.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6A3.75 3.75 0 016 2.25h8A3.75 3.75 0 0117.75 6v8A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6zM6 3.75A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14V6A2.25 2.25 0 0014 3.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13 17a1 1 0 11-2 0 1 1 0 012 0zM9.75 9.887c0-.945.91-1.875 2.25-1.875 1.34 0 2.25.93 2.25 1.875 0 .453-.129.726-.297.93-.19.23-.462.414-.833.632-.046.028-.096.056-.148.086-.312.18-.706.408-1.013.693-.405.375-.709.886-.709 1.596a.75.75 0 001.5 0c0-.217.07-.35.23-.497.172-.16.396-.29.717-.477l.183-.107c.38-.223.858-.52 1.23-.972.395-.479.64-1.09.64-1.884 0-1.955-1.776-3.375-3.75-3.375s-3.75 1.42-3.75 3.375a.75.75 0 101.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6.5A4.25 4.25 0 016.5 2.25h11a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11zM6.5 3.75A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-11a2.75 2.75 0 00-2.75-2.75h-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 22.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM13 13.5c0-1.26 1.213-2.5 3-2.5s3 1.24 3 2.5c0 .605-.172.968-.396 1.24-.253.307-.616.553-1.11.844l-.198.114c-.417.24-.941.544-1.351.924-.54.5-.945 1.182-.945 2.128a1 1 0 102 0c0-.29.094-.466.305-.662.231-.215.53-.388.958-.637l.244-.143c.505-.297 1.143-.693 1.64-1.296.526-.638.854-1.453.854-2.512 0-2.607-2.369-4.5-5-4.5C13.367 9 11 10.893 11 13.5a1 1 0 102 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8.5A5.5 5.5 0 018.5 3h15A5.5 5.5 0 0129 8.5v15a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15zM8.5 5A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5v-15A3.5 3.5 0 0023.5 5h-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 44a2 2 0 11-4 0 2 2 0 014 0zM25.5 26c0-2.855 2.715-5.5 6.5-5.5s6.5 2.645 6.5 5.5c0 1.323-.383 2.163-.907 2.798-.567.689-1.36 1.217-2.353 1.8-.13.077-.266.155-.407.237-.837.484-1.83 1.057-2.603 1.775-.996.925-1.73 2.162-1.73 3.89a1.5 1.5 0 003 0c0-.743.267-1.223.77-1.691.506-.47 1.158-.848 2.012-1.345l.479-.28c1.008-.592 2.214-1.346 3.147-2.478.977-1.184 1.593-2.702 1.593-4.706 0-4.876-4.448-8.5-9.5-8.5-5.053 0-9.502 3.624-9.502 8.5a1.5 1.5 0 103 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17.5C7 11.701 11.701 7 17.5 7h29C52.299 7 57 11.701 57 17.5v29C57 52.299 52.299 57 46.5 57h-29C11.701 57 7 52.299 7 46.5v-29zM17.5 10a7.5 7.5 0 00-7.5 7.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29a7.5 7.5 0 00-7.5-7.5h-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconQuestionSquareRegular)
