import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EStatusProcess, FormConstructor } from '@lib/form'
import { EServerError } from '@lib/services'

import { AuthStore } from '@modules/auth/store/AuthStore'

@singleton()
class RegistrationEmployeeFormStore {
  private authStore = container.resolve(AuthStore)

  form = new FormConstructor({
    fullName: {
      rule: 'required|regex:/^[a-zA-Zа-яёА-ЯЁ ]+//',
      errorText: 'Введите корректное имя',
      label: 'ФИО',
    },
    email: {
      rule: 'required|email',
      errorText: 'Некорректный адрес электронной почты',
      label: 'Электронная почта',
    },
    phone: {
      rule: 'required|regex:/^\\+7 [\\d]{3} [\\d]{3} [\\d]{2} [\\d]{2}/',
      errorText: 'Телефон должен состоять из 10 цифр',
      label: 'Телефон',
    },
    position: {
      rule: 'required|regex:/^[a-zA-Zа-яёА-ЯЁ\\D-\'"()]+$/',
      errorText: 'Введите корректное наименование',
      label: 'Должность',
    },
  })

  constructor() {
    makeAutoObservable<RegistrationEmployeeFormStore, 'authStore'>(this, {
      authStore: false,
    })
  }

  get registrationSuccess() {
    return this.form.meta.fetchStatus === EStatusProcess.SUCCESS
  }
  get registrationError() {
    return this.form.meta.fetchStatus === EStatusProcess.FAIL
  }
  get registrationIDLE() {
    return this.form.meta.fetchStatus === EStatusProcess.IDLE
  }
  get registrationRunning() {
    return this.form.meta.fetchStatus === EStatusProcess.RUNNING
  }

  handleSubmit = async (id) => {
    await this.form.handleSubmit(
      (fields) =>
        this.authStore.registerEmployee({
          fullName: fields.fullName.value,
          email: fields.email.value,
          phone: fields.phone.value,
          position: fields.position.value,
          companyId: id,
        }),
      (errName) => {
        if (errName === EServerError.EmailRegistered) {
          return 'email'
        }
      },
    )
  }
}

export const useRegistrationEmployeeFormStore = () =>
  container.resolve(RegistrationEmployeeFormStore)
