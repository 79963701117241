import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

interface IToken {
  accessToken: string
  refreshToken: string
}

const TOKEN = 'TOKEN'
const REFRESH_TOKEN = 'REFRESH_TOKEN'

export interface IRegisterParams {
  fullName: string
  email: string
  phone: string
  companyName: string
  companyInn: string
}

export interface IRegisterEmployeeParams {
  fullName: string
  email: string
  phone: string
  companyId: number
  position: string
}

export interface ILoginParams {
  email: string
  password: string
}

export interface IRestoreParams {
  email: string
}
@singleton()
export class AuthRest extends AbstractRest {
  // Auth Token
  private setAccessToken(token?: string) {
    this.http.defaults.headers.common['Authorization'] = token
      ? `Bearer ${token}`
      : ''
  }

  private setToken(token: IToken) {
    this.setAccessToken(token.accessToken)

    localStorage.setItem(TOKEN, token.accessToken)
    localStorage.setItem(REFRESH_TOKEN, token.refreshToken)
  }

  // Rest Methods
  async login(params: ILoginParams) {
    const response = await this.http.post<IToken>('/lk/auth/login', params)
    this.setToken(response.data)
  }

  async restore(params: IRestoreParams) {
    await this.http.post('/lk/auth/restore', params)
  }

  async register(params: IRegisterParams) {
    await this.http.post('/lk/partners', params)
  }

  async registerEmployee(params: IRegisterEmployeeParams) {
    await this.http.post('/lk/employees', params)
  }

  private async refreshToken() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)
    if (!refreshToken) {
      throw new Error('Refresh token is not exist')
    }

    const response = await this.http.post<IToken>('/lk/auth/refresh-tokens', {
      refresh_token: refreshToken,
    })
    this.setToken(response.data)
  }

  // Check if token exist, if it's expired automatically refresh it
  async checkIsAuthenticated() {
    const accessToken = localStorage.getItem(TOKEN)
    if (accessToken) {
      this.setAccessToken(accessToken)

      const response = await this.http.post<boolean>('/lk/auth/check-tokens')
      const isTokenValid = response.data

      if (!isTokenValid) {
        await this.refreshToken()
      }
      return true
    }
    return false
  }

  logout() {
    this.setAccessToken(undefined)
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
  }
}
