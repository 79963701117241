import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDataChartBarsALight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13 3.5a.5.5 0 00-1 0v9a.5.5 0 001 0v-9zM6.5 5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zM10 7.5a.5.5 0 00-1 0v5a.5.5 0 001 0v-5zM4 9.5a.5.5 0 00-1 0v3a.5.5 0 001 0v-3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16 3.5a.5.5 0 00-1 0v13a.5.5 0 001 0v-13zM8 6.5a.5.5 0 00-1 0v10a.5.5 0 001 0v-10zM4 12a.5.5 0 00-1 0v4.5a.5.5 0 001 0V12zM11.5 8.5a.5.5 0 01.5.5v7.5a.5.5 0 01-1 0V9a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartBarsALight)
