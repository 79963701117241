import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArchiveCabinetRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.25 5A.75.75 0 016 4.25h3a.75.75 0 110 1.5H6A.75.75 0 015.25 5zM6 10.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5H6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 1A2.75 2.75 0 002 3.75v8.5A2.75 2.75 0 004.75 15h5.5A2.75 2.75 0 0013 12.25v-8.5A2.75 2.75 0 0010.25 1h-5.5zM3.5 3.75c0-.69.56-1.25 1.25-1.25h5.5c.69 0 1.25.56 1.25 1.25v3.5h-8v-3.5zm0 5v3.5c0 .69.56 1.25 1.25 1.25h5.5c.69 0 1.25-.56 1.25-1.25v-3.5h-8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.75 6.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM8.5 12.5a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.249 2.001a3.25 3.25 0 00-3.25 3.25v9.5a3.25 3.25 0 003.25 3.25h5.5a3.25 3.25 0 003.25-3.25v-9.5a3.25 3.25 0 00-3.25-3.25h-5.5zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h5.5c.966 0 1.75.784 1.75 1.75V9.25h-9V5.251zm0 5.499v4.002c0 .966.783 1.75 1.75 1.75h5.5a1.75 1.75 0 001.75-1.75V10.75h-9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.25 7.75A.75.75 0 0110 7h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM10 15.5a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25A3.75 3.75 0 004.25 6v12A3.75 3.75 0 008 21.75h8A3.75 3.75 0 0019.75 18V6A3.75 3.75 0 0016 2.25H8zm10.25 9V6A2.25 2.25 0 0016 3.75H8A2.25 2.25 0 005.75 6v5.25h12.5zm-12.5 1.5h12.5V18A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18v-5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 10a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM13 21a1 1 0 100 2h6a1 1 0 100-2h-6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2A5.5 5.5 0 005 7.5v17a5.5 5.5 0 005.5 5.5h11a5.5 5.5 0 005.5-5.5v-17A5.5 5.5 0 0021.5 2h-11zM25 15V7.5A3.5 3.5 0 0021.5 4h-11A3.5 3.5 0 007 7.5V15h18zM7 17h18v7.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 24.5V17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M23 20.5a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 010 3h-15a1.5 1.5 0 01-1.5-1.5zM24.5 43a1.5 1.5 0 000 3h15a1.5 1.5 0 000-3h-15z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6C15.701 6 11 10.701 11 16.5v31C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5v-31C53 10.701 48.299 6 42.5 6h-21zM50 31V16.5A7.5 7.5 0 0042.5 9h-21a7.5 7.5 0 00-7.5 7.5V31h36zm-36 3h36v13.5a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5V34z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveCabinetRegular)
