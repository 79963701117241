import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSecurityLockClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.5 9a1 1 0 01-.5.866V11a.5.5 0 01-1 0V9.866A1 1 0 118.5 9z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.5a3.5 3.5 0 117 0v1.55a2.5 2.5 0 012 2.45v4a2.5 2.5 0 01-2.5 2.5h-6A2.5 2.5 0 012 11.5v-4a2.5 2.5 0 012-2.45V3.5zm6 0V5H5V3.5a2.5 2.5 0 015 0zm-7 4A1.5 1.5 0 014.5 6h6A1.5 1.5 0 0112 7.5v4a1.5 1.5 0 01-1.5 1.5h-6A1.5 1.5 0 013 11.5v-4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.751 12.251c0 .514-.309.955-.751 1.148V15a.5.5 0 01-1 0v-1.601a1.252 1.252 0 111.751-1.148z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.5a4.5 4.5 0 019 0v1.55a2.5 2.5 0 012 2.45v5a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 013 15.5v-5a2.5 2.5 0 012-2.45V6.5zm8 0V8H6V6.5a3.5 3.5 0 117 0zm-9 4A1.5 1.5 0 015.5 9h8a1.5 1.5 0 011.5 1.5v5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 014 15.5v-5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityLockClosedLight)
