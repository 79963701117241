import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowARightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.73 2.854a.5.5 0 11.708-.708l4.006 4.006a1.906 1.906 0 010 2.696l-4.006 4.006a.5.5 0 01-.707-.708l4.005-4.005A.914.914 0 0012.852 8H2.5a.5.5 0 010-1h10.352a.914.914 0 00-.116-.14L8.732 2.853z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M11.365 3.291a.5.5 0 11.707-.707l5.237 5.237a2.375 2.375 0 010 3.358l-5.237 5.237a.5.5 0 11-.707-.707l5.237-5.237c.139-.139.242-.3.309-.472H2.5a.5.5 0 110-1h14.41a1.37 1.37 0 00-.308-.472L11.365 3.29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowARightLight)
