import { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '../CardWrapper/CardWrapper'

import styles from './styles.module.scss'

export interface ITagOption {
  label: string
  value: string
}

interface IProps {
  tags: ITagOption[]
}

const TaggedContainer = ({ tags }: IProps) => (
  <CardWrapper py={20}>
    <div className={styles.container}>
      {tags.map((tag, index) => (
        <div key={index} className={styles.tag}>
          <div className={styles.label}>
            <Typography size={14} color={COLORS.GRAY.GANDALF}>
              {tag.label}
            </Typography>
          </div>

          <Typography size={14}>{tag.value}</Typography>
        </div>
      ))}
    </div>
  </CardWrapper>
)

export default memo(TaggedContainer)
