import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeALeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.603 9.413a2 2 0 010-2.828L7.293.896C7.923.266 9 .713 9 1.604V4h4.25c.966 0 1.75.784 1.75 1.75v4.5A1.75 1.75 0 0113.25 12H9v2.396c0 .891-1.077 1.337-1.707.707l-5.69-5.69zm1.061-1.767a.5.5 0 000 .707L7.5 13.189V11.25a.75.75 0 01.75-.75h5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-5a.75.75 0 01-.75-.75V2.81L2.664 7.647z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.228 11.588a2.25 2.25 0 010-3.18l6.967-6.976c.85-.851 2.306-.25 2.306.954V5.25H16a2 2 0 012 2v5.5a2 2 0 01-2 2h-4.5v2.861c0 1.203-1.456 1.806-2.306.954l-6.967-6.977zm1.062-2.12a.75.75 0 000 1.06l6.71 6.72V14a.75.75 0 01.75-.75H16a.5.5 0 00.5-.5v-5.5a.5.5 0 00-.5-.5h-5.25A.75.75 0 0110 6V2.748l-6.71 6.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.795 13.856a2.625 2.625 0 010-3.712l8.73-8.73C12.437.502 14 1.149 14 2.44V6h5.75A2.25 2.25 0 0122 8.25v7.5A2.25 2.25 0 0119.75 18H14v3.56c0 1.292-1.562 1.94-2.476 1.026l-8.729-8.73zm1.06-2.651c-.438.44-.438 1.151 0 1.59L12.5 21.44v-4.19a.75.75 0 01.75-.75h6.5a.75.75 0 00.75-.75v-7.5a.75.75 0 00-.75-.75h-6.5a.75.75 0 01-.75-.75V2.56l-8.644 8.645z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.56 18.473a3.5 3.5 0 010-4.95L14.586 2.498C15.846 1.238 18 2.13 18 3.912V9h8a3 3 0 013 3v8a3 3 0 01-3 3h-8v5.084c0 1.782-2.154 2.675-3.414 1.415L3.56 18.473zm1.415-3.536a1.5 1.5 0 000 2.122L16 28.084V22a1 1 0 011-1h8.998a1 1 0 001-1v-8a1 1 0 00-1-1h-8.998a1 1 0 01-1-1V3.912L4.974 14.937z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.972 36.592a6.5 6.5 0 010-9.192L30.024 5.35C32.23 3.144 36 4.705 36 7.823V18h15.5a5.5 5.5 0 015.5 5.5v17a5.5 5.5 0 01-5.5 5.5H36v10.169c0 3.118-3.77 4.68-5.975 2.475L7.972 36.592zm2.122-7.071a3.5 3.5 0 000 4.95l22.052 22.051a.5.5 0 00.853-.353V44.5a1.5 1.5 0 011.5-1.5h17a2.5 2.5 0 002.5-2.5v-17a2.5 2.5 0 00-2.5-2.5h-17a1.5 1.5 0 01-1.5-1.5V7.824a.5.5 0 00-.854-.353L10.095 29.52z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeALeftRegular)
