import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoWhatsappLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8a5 5 0 112.464 4.309l-.178-.105-2.027.52.539-2.007-.107-.18A4.974 4.974 0 013.001 8zm5-6a6 6 0 00-5.276 8.859l-.874 3.26 3.289-.845A6 6 0 107.999 2zm1.867 6.836c.15.055.954.45 1.118.532l.09.044c.113.055.19.093.223.147.041.069.041.396-.095.778s-.79.73-1.104.777a2.24 2.24 0 01-1.03-.064 9.403 9.403 0 01-.932-.345c-1.533-.662-2.569-2.147-2.765-2.428l-.028-.04-.002-.003c-.086-.115-.666-.889-.666-1.69 0-.752.37-1.147.54-1.329l.032-.034a.6.6 0 01.436-.205c.11 0 .218.001.314.006h.036c.096 0 .214-.001.332.28l.18.438c.14.342.296.722.324.777.04.081.068.177.013.286l-.023.047a.928.928 0 01-.14.226 6.445 6.445 0 00-.084.1c-.056.069-.112.137-.161.186-.082.082-.167.17-.072.334.095.164.423.7.91 1.133.522.466.976.663 1.206.763.045.02.082.035.109.048.163.082.258.069.354-.04.095-.11.409-.478.518-.642.109-.163.218-.136.367-.082z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12.616 10.856c.183.067 1.166.55 1.366.65l.109.054c.14.067.233.113.274.18.05.083.05.483-.117.95-.166.467-.965.893-1.349.95-.344.052-.78.073-1.259-.079-.29-.092-.662-.215-1.139-.42-1.873-.81-3.14-2.625-3.379-2.969a2.6 2.6 0 00-.035-.05l-.002-.002c-.106-.14-.814-1.086-.814-2.065 0-.92.452-1.403.66-1.625l.04-.042c.183-.2.399-.25.532-.25a8.017 8.017 0 01.428.007c.117 0 .262-.001.405.343l.22.534c.172.42.363.883.396.95.05.1.083.217.017.35l-.028.057c-.05.102-.087.177-.172.276l-.102.123a3.3 3.3 0 01-.198.227c-.1.1-.204.208-.087.408.116.2.517.855 1.111 1.385.639.57 1.194.81 1.475.932.055.024.1.043.133.06.2.1.316.083.432-.05.117-.134.5-.584.633-.784.134-.2.267-.167.45-.1z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.333 2a7.833 7.833 0 00-6.742 11.823l-1.038 3.87 3.962-1.018A7.833 7.833 0 1010.333 2zM3.5 9.833a6.833 6.833 0 113.344 5.877l-.179-.106-2.703.694.707-2.635-.117-.185A6.8 6.8 0 013.5 9.833z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoWhatsappLight)
