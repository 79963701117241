import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocArrowConvertRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3.856V3.75A2.75 2.75 0 015.75 1h3.597c.464 0 .91.184 1.238.513l2.903 2.903c.328.328.512.773.512 1.237v6.597A2.75 2.75 0 0111.25 15h-5.5A2.75 2.75 0 013 12.25v-2.106a3.15 3.15 0 010-6.288zm1.5-.106c0-.69.56-1.25 1.25-1.25h2.5v3c0 .69.56 1.25 1.25 1.25h3v5.5c0 .69-.56 1.25-1.25 1.25h-5.5c-.69 0-1.25-.56-1.25-1.25v-2.1h3.37l-1.55 1.362a.65.65 0 10.86.976l2.202-1.936a1.4 1.4 0 000-2.104L7.18 6.512a.65.65 0 10-.858.976L7.87 8.85H4.5v-5.1zM3 8.84V5.16a1.85 1.85 0 000 3.68zm9.2-3.59L9.75 2.8v2.45h2.45z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.999 5.85v-.599a3.25 3.25 0 013.25-3.25h3.12c.464 0 .909.185 1.237.513l3.88 3.88c.328.328.513.773.513 1.238v7.12a3.25 3.25 0 01-3.25 3.25h-5.5a3.25 3.25 0 01-3.25-3.25V12.15H3.8a3.15 3.15 0 010-6.3h.199zm1.5-.599c0-.966.783-1.75 1.75-1.75H9.25V6.5a2.25 2.25 0 002.25 2.25h2.999v6.002a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75V12.15H9.02l-1.549 1.362a.65.65 0 10.858.976l2.204-1.936a1.4 1.4 0 000-2.104L8.329 8.512a.65.65 0 10-.858.976l1.55 1.362H5.498V5.251zm-1.5 5.6V7.15H3.8a1.85 1.85 0 100 3.7h.199zM14.22 7.25L10.75 3.78V6.5c0 .414.336.75.75.75h2.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 18v-2.5H4A3.75 3.75 0 114 8h.25V6A3.75 3.75 0 018 2.25h5.05c.729 0 1.428.29 1.944.805l3.95 3.951a2.75 2.75 0 01.806 1.945V18A3.75 3.75 0 0116 21.75H8A3.75 3.75 0 014.25 18zm1.5-12A2.25 2.25 0 018 3.75h4.25V7A2.75 2.75 0 0015 9.75h3.25V18A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18v-2.5h5.226L8.97 17.506a.75.75 0 001.06 1.061l2.757-2.756a1.5 1.5 0 000-2.122l-2.757-2.756a.75.75 0 10-1.06 1.06L10.976 14H5.75V6zM4 9.5h.25V14H4a2.25 2.25 0 010-4.5zm14.035-1.25H15c-.69 0-1.25-.56-1.25-1.25V3.965c.065.044.127.095.183.151l3.95 3.95c.057.057.108.119.152.184z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 16a5 5 0 015-5V7.5A5.5 5.5 0 0110.5 2h6.726a4 4 0 012.829 1.172l5.773 5.773A4 4 0 0127 11.774V24.5a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 015 24.5V21a5 5 0 01-5-5zm7-8.5A3.5 3.5 0 0110.5 4H16v5.5a3.5 3.5 0 003.5 3.5H25v11.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 24.5V21h7.586l-2.793 2.793a1 1 0 001.414 1.414l3.44-3.44a2.5 2.5 0 000-3.535l-3.44-3.44a1 1 0 00-1.414 1.415L14.586 19H7V7.5zM2 16a3 3 0 013-3v6a3 3 0 01-3-3zm22.844-5H19.5A1.5 1.5 0 0118 9.5V4.156a2 2 0 01.64.43l5.774 5.774c.185.185.33.403.43.64z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M36 12.5a1.5 1.5 0 00-3 0v6a6.5 6.5 0 006.5 6.5h7a1.5 1.5 0 000-3h-7a3.5 3.5 0 01-3.5-3.5v-6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 47.5V41h-.5a9.5 9.5 0 010-19h.5v-5.5C11 10.701 15.701 6 21.5 6h14.452a7.5 7.5 0 015.303 2.197l9.548 9.547A7.5 7.5 0 0153 23.048V47.5C53 53.299 48.299 58 42.5 58h-21C15.701 58 11 53.299 11 47.5zm3-31A7.5 7.5 0 0121.5 9h14.452a4.5 4.5 0 013.182 1.318l9.548 9.548A4.5 4.5 0 0150 23.048V47.5a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5V41h15.379l-6.44 6.44a1.5 1.5 0 002.122 2.12l6.878-6.878a4.5 4.5 0 000-6.364l-6.878-6.879a1.5 1.5 0 00-2.122 2.122L29.38 38H14V16.5zM10.5 25h.5v13h-.5a6.5 6.5 0 110-13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocArrowConvertRegular)
