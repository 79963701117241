import { memo } from 'react'

import Card from '@ui/Card'
import {
  IconArrowUiCornerOutUpRightLight,
  IconFaceAHappyRegular,
  IconQuestionSquareRegular,
  // IconSettingsGearRegular,
} from '@ui/icons'
import LinkItem from '@ui/LinkItem'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation/MainRoutes'

import DownloadPartnerCertificate from '../components/DownloadPartnerCertificate'

import styles from './SupportPage.module.scss'

const SupportPage = () => (
  <PageWrapper title="Поддержка">
    <div className={styles.container}>
      <Card
        fullWidth
        Icon={IconFaceAHappyRegular}
        title="Партнерская программа"
        description="Помощь и ответы на вопросы"
        link={EMainRoutes.SupportPartnersProgram}
      />
      {/*Скрыты ввиду ненадобности. Вернуть, если понадобится. Вся навигация с ним осталась.
      <Card
        fullWidth
        Icon={IconSettingsGearRegular}
        title="Драйверы"
        description="Скачать или обновить драйвера"
        link={EMainRoutes.SupportDrivers}
      /> */}
      <Card
        fullWidth
        Icon={IconQuestionSquareRegular}
        title="Служба поддержки"
        description="Сообщить о проблеме"
        link={EMainRoutes.SupportContact}
      />
    </div>

    <div className={styles.other}>
      <Typography size={18} weight="medium" color={COLORS.GRAY.OBSIDIAN}>
        Другие ссылки
      </Typography>
      <div className={styles.links}>
        <LinkItem
          title="Политика конфиденциальности"
          icon={<IconArrowUiCornerOutUpRightLight />}
          link={EMainRoutes.SupportPrivacyPolicy}
        />
        <LinkItem
          title="Гарантии"
          icon={<IconArrowUiCornerOutUpRightLight />}
          link={EMainRoutes.SupportWarranty}
        />
      </div>
    </div>

    <DownloadPartnerCertificate />
  </PageWrapper>
)

export default memo(SupportPage)
