import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCopyLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1A2.5 2.5 0 002 3.5v8A2.5 2.5 0 004.5 14h5a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 009.5 1h-5zM3 3.5A1.5 1.5 0 014.5 2h5A1.5 1.5 0 0111 3.5v8A1.5 1.5 0 019.5 13h-5A1.5 1.5 0 013 11.5v-8z"
          fill="currentColor"
        />
        <path
          d="M14 6.5a.5.5 0 00-1 0V12a3 3 0 01-3 3H5.5a.5.5 0 000 1H10a4 4 0 004-4V6.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a3 3 0 00-3 3v10a3 3 0 003 3h6a3 3 0 003-3V5a3 3 0 00-3-3H7zM5 5a2 2 0 012-2h6a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V5z"
        fill="currentColor"
      />
      <path
        d="M18 8.5a.5.5 0 00-1 0v7a3.5 3.5 0 01-3.5 3.5h-5a.5.5 0 000 1h5a4.5 4.5 0 004.5-4.5v-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCopyLight)
