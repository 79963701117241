import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookNotepadSpiralLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-4zM7 6V5h3v1H7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 10V9h-.5a.5.5 0 010-1H2V7h-.5a.5.5 0 010-1H2V5h-.5a.5.5 0 010-1H2v-.5A2.5 2.5 0 014.5 1h6A2.5 2.5 0 0113 3.5v8a2.5 2.5 0 01-2.5 2.5h-6A2.5 2.5 0 012 11.5V11h-.5a.5.5 0 010-1H2zm1-6.5A1.5 1.5 0 014.5 2h6A1.5 1.5 0 0112 3.5v8a1.5 1.5 0 01-1.5 1.5h-6A1.5 1.5 0 013 11.5V11h1.5a.5.5 0 000-1H3V9h1.5a.5.5 0 000-1H3V7h1.5a.5.5 0 000-1H3V5h1.5a.5.5 0 000-1H3v-.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5a1 1 0 00-1 1v2a1 1 0 001 1h4a1 1 0 001-1V6a1 1 0 00-1-1H8zm0 1h4v2H8V6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14v-2H1.5a.5.5 0 010-1H3V9H1.5a.5.5 0 010-1H3V6H1.5a.5.5 0 010-1H3a3 3 0 013-3h7a3 3 0 013 3v10a3 3 0 01-3 3H6a3 3 0 01-3-3H1.5a.5.5 0 010-1H3zM6 3h7a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2h.5a.5.5 0 000-1H4v-2h.5a.5.5 0 000-1H4V9h.5a.5.5 0 000-1H4V6h.5a.5.5 0 000-1H4a2 2 0 012-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookNotepadSpiralLight)
