import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetCloudRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.02 5.45a4.376 4.376 0 018.31-1.445A4.5 4.5 0 0111.25 13h-7a3.875 3.875 0 01-1.23-7.55zM7.375 3a2.875 2.875 0 00-2.872 3.006.75.75 0 01-.631.774 2.376 2.376 0 00.378 4.72h6.944a.75.75 0 01-1.082-6.508A2.877 2.877 0 007.375 3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.82 7.503A5.376 5.376 0 0113.948 6H14a5.5 5.5 0 110 11H5.25a4.875 4.875 0 01-1.43-9.497zM9.125 4.5a3.875 3.875 0 00-3.868 3.636.75.75 0 01-.592.689 3.376 3.376 0 00.638 6.675H14a4 4 0 10-.45-7.975.75.75 0 01-.787-.487A3.877 3.877 0 009.126 4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.719 8.618a6.301 6.301 0 0111.925-1.766l.156-.002a6.45 6.45 0 010 12.9H6.3A5.7 5.7 0 014.719 8.618zM10.95 4.75a4.8 4.8 0 00-4.791 4.505.75.75 0 01-.592.688 4.202 4.202 0 00.791 8.307H16.8a4.95 4.95 0 10-.556-9.87.75.75 0 01-.787-.486A4.802 4.802 0 0010.95 4.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.564 25.998A1.06 1.06 0 0122.5 26h-14a7.625 7.625 0 01-2.066-14.93C7.268 7.034 10.842 4 15.125 4a8.874 8.874 0 017.871 4.772 8.626 8.626 0 01-.431 17.226zM15.125 6a6.876 6.876 0 00-6.82 6.005 1 1 0 01-.796.855A5.625 5.625 0 008.548 24h13.936a6.625 6.625 0 00-.108-13.25h-.02a1 1 0 01-.918-.603A6.877 6.877 0 0015.125 6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.694 23.505C14.892 15.853 21.511 10 29.5 10c6.333 0 11.803 3.679 14.396 9.01.2-.006.402-.01.604-.01C53.613 19 61 26.387 61 35.5S53.613 52 44.5 52h-27C9.492 52 3 45.508 3 37.5c0-6.692 4.532-12.323 10.694-13.995zM29.5 13c-6.795 0-12.374 5.215-12.95 11.86a1.5 1.5 0 01-1.215 1.343C10.02 27.215 6 31.89 6 37.5 6 43.851 11.149 49 17.5 49h27C51.956 49 58 42.956 58 35.5S51.956 22 44.5 22c-.471 0-.937.024-1.395.071a1.5 1.5 0 01-1.543-.931C39.636 16.365 34.959 13 29.5 13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudRegular)
