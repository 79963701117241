import { memo } from 'react'

import { getImageUrl } from '@lib/helpers'

interface IHelperImageProps {
  image: string
  className: string
  src: string
  X2src: string
}

const HelperImage = ({ image, className, src, X2src }: IHelperImageProps) => {
  return (
    <>
      {image ? (
        <img className={className} src={image} alt="Превью вебинара" />
      ) : (
        <picture className={className}>
          <source srcSet={`${getImageUrl(X2src)} 2x`} />
          <img
            src={`${getImageUrl(src)}`}
            alt="Превью вебинара"
            className={className}
          />
        </picture>
      )}
    </>
  )
}

export default memo(HelperImage)
