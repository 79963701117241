import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTimeHourglassRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1.25a.75.75 0 000 1.5h1v1.574a3.75 3.75 0 001.785 3.193L6.569 8l-.784.483A3.75 3.75 0 004 11.676v1.574H3a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-1v-1.574a3.75 3.75 0 00-1.785-3.193L9.431 8l.784-.483A3.75 3.75 0 0012 4.324V2.75h1a.75.75 0 000-1.5H3zm7.5 1.5h-5v1.574A2.25 2.25 0 006.57 6.24L8 7.12l1.43-.88a2.25 2.25 0 001.07-1.916V2.75zM8 8.88l-1.43.88a2.25 2.25 0 00-1.07 1.916v1.574h5v-1.574A2.25 2.25 0 009.43 9.76L8 8.88z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2a.75.75 0 000 1.5h1v1.998c0 1.506.797 2.9 2.095 3.663L8.521 10l-1.426.839A4.25 4.25 0 005 14.502V16.5H4A.75.75 0 004 18h12a.75.75 0 000-1.5h-1v-1.998a4.25 4.25 0 00-2.095-3.663L11.479 10l1.426-.839A4.25 4.25 0 0015 5.498V3.5h1A.75.75 0 0016 2H4zm9.5 1.5h-7v1.998a2.75 2.75 0 001.356 2.37L10 9.13l2.144-1.261a2.75 2.75 0 001.356-2.37V3.5zM10 10.87l-2.144 1.262a2.75 2.75 0 00-1.356 2.37V16.5h7v-1.998a2.75 2.75 0 00-1.356-2.37L10 10.87z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 2.25a.75.75 0 000 1.5H6v2.929a4.75 4.75 0 002.393 4.124L10.488 12l-2.095 1.197A4.75 4.75 0 006 17.321v2.929H4.5a.75.75 0 000 1.5h15a.75.75 0 000-1.5H18v-2.929a4.75 4.75 0 00-2.393-4.124L13.512 12l2.095-1.197A4.75 4.75 0 0018 6.679V3.75h1.5a.75.75 0 000-1.5h-15zm12 1.5h-9v2.929A3.25 3.25 0 009.138 9.5L12 11.136l2.863-1.635A3.25 3.25 0 0016.5 6.679V3.75zM12 12.864L9.138 14.5A3.25 3.25 0 007.5 17.32v2.929h9v-2.929a3.25 3.25 0 00-1.637-2.821L12 12.864z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3a1 1 0 000 2h2v3.808a6.5 6.5 0 003.275 5.644L13.985 16l-2.71 1.548A6.5 6.5 0 008 23.192V27H6a1 1 0 100 2h20a1 1 0 100-2h-2v-3.808a6.5 6.5 0 00-3.275-5.644L18.015 16l2.71-1.548A6.5 6.5 0 0024 8.808V5h2a1 1 0 100-2H6zm16 2H10v3.808a4.5 4.5 0 002.267 3.907L16 14.848l3.733-2.133A4.5 4.5 0 0022 8.808V5zm-6 12.152l-3.733 2.133A4.5 4.5 0 0010 23.192V27h12v-3.808a4.5 4.5 0 00-2.267-3.907L16 17.152z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 6a1.5 1.5 0 000 3H16v7.915a13.5 13.5 0 006.979 11.82L28.895 32l-5.916 3.264A13.5 13.5 0 0016 47.084V55h-4.5a1.5 1.5 0 000 3h41a1.5 1.5 0 000-3H48v-7.916a13.5 13.5 0 00-6.978-11.82L35.105 32l5.917-3.265A13.5 13.5 0 0048 16.915V9h4.5a1.5 1.5 0 000-3h-41zM45 9H19v7.915a10.5 10.5 0 005.428 9.194L32 30.286l7.572-4.177A10.5 10.5 0 0045 16.915V9zM32 33.713l-7.572 4.178A10.5 10.5 0 0019 47.084V55h26v-7.916a10.5 10.5 0 00-5.428-9.193L32 33.713z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeHourglassRegular)
