import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1PlusRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.498 3.498 0 100 6.996A3.498 3.498 0 008 1zM6.002 4.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          d="M3.5 13.253c0-.68.179-1.15.43-1.488.256-.347.626-.61 1.087-.804.944-.4 2.123-.458 2.983-.458a.75.75 0 000-1.5c-.875 0-2.322.049-3.568.576-.635.27-1.252.676-1.709 1.294C2.261 11.5 2 12.29 2 13.253a.75.75 0 001.5 0zM12 8.75a.75.75 0 01.75.75v1.75h1.75a.75.75 0 010 1.5h-1.75v1.75a.75.75 0 01-1.5 0v-1.75H9.5a.75.75 0 010-1.5h1.75V9.5a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45 6.506a4.55 4.55 0 119.1 0 4.55 4.55 0 01-9.1 0zM10 3.456a3.05 3.05 0 100 6.1 3.05 3.05 0 000-6.1z"
          fill="currentColor"
        />
        <path
          d="M4.35 15.373c-.358.44-.6 1.034-.6 1.873a.75.75 0 01-1.5 0c0-1.156.344-2.09.936-2.82.583-.716 1.367-1.187 2.177-1.499 1.601-.617 3.474-.677 4.637-.677a.75.75 0 010 1.5c-1.151 0-2.778.068-4.098.577-.65.25-1.183.592-1.552 1.046zM15.75 12.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1a6 6 0 100 12 6 6 0 000-12zM7.5 7a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
          fill="currentColor"
        />
        <path
          d="M4.591 18.13c-.505.684-.841 1.603-.841 2.87a.75.75 0 01-1.5 0c0-1.551.418-2.791 1.135-3.762.712-.962 1.68-1.608 2.707-2.043 2.03-.859 4.413-.945 5.908-.945a.75.75 0 010 1.5c-1.48 0-3.596.095-5.323.827-.852.36-1.575.862-2.086 1.553zM18 14.25a.75.75 0 01.75.75v3.25H22a.75.75 0 010 1.5h-3.25V23a.75.75 0 01-1.5 0v-3.25H14a.75.75 0 010-1.5h3.25V15a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 10a8 8 0 1116 0 8 8 0 01-16 0zm8-6a6 6 0 100 12 6 6 0 000-12z"
          fill="currentColor"
        />
        <path
          d="M6.102 24.975C5.44 25.816 5 26.943 5 28.5a1 1 0 11-2 0c0-1.966.566-3.539 1.532-4.764.955-1.211 2.25-2.017 3.612-2.558C10.84 20.108 14.009 20 16 20a1 1 0 110 2c-1.975 0-4.806.119-7.118 1.037-1.143.454-2.105 1.082-2.78 1.938zM26 21a1 1 0 10-2 0v4h-4a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4v-4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15zM32 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S38.627 9 32 9z"
        fill="currentColor"
      />
      <path
        d="M12.267 49.128C10.893 50.907 10 53.276 10 56.5a1.5 1.5 0 01-3 0c0-3.822 1.074-6.852 2.893-9.206 1.803-2.335 4.261-3.907 6.88-4.969C21.97 40.218 28.104 40 32 40a1.5 1.5 0 010 3c-3.87 0-9.487.235-14.099 2.105-2.284.926-4.245 2.224-5.634 4.023zM51 40a1.5 1.5 0 00-3 0v9h-9a1.5 1.5 0 000 3h9v9a1.5 1.5 0 003 0v-9h9a1.5 1.5 0 000-3h-9v-9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1PlusRegular)
