import { AccentType } from '@ui/Select/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './CardItem.module.scss'

interface ICardItemProps {
  photoURL?: string
  title: string
  description?: string
  selected?: boolean
  size?: 'medium' | 'small'
  additionalText?: string
  tag?: AccentType
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

const CardItem = ({
  photoURL,
  title,
  description,
  selected,
  size = 'medium',
  additionalText,
  tag,
  onClick,
  onFocus,
  onBlur,
}: ICardItemProps) => {
  return (
    <div
      className={cx(styles.card, [styles[`size-${size}`]], {
        [styles.selected]: selected,
      })}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}>
      <div className={styles.image}>
        <img src={photoURL} alt="image" />
      </div>

      <div className={styles.content}>
        {tag && (
          <div className={styles.tag}>
            <Typography size={12} color={COLORS.GRAY.GANDALF}>
              {tag.name}
            </Typography>
          </div>
        )}
        <Typography size={size === 'medium' ? 22 : 16} weight="medium">
          {title}
        </Typography>
        <Typography size={size === 'medium' ? 16 : 14}>
          {description}
        </Typography>
      </div>

      {/*{additionalText && (*/}
      {/*  <div className={styles.additional}>*/}
      {/*    <Typography size={14}>{additionalText}</Typography>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

export default CardItem
