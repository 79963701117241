import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNotificationBellAlarmLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.34 1.116a.5.5 0 10-.69-.723A7.75 7.75 0 00.254 5.734a.5.5 0 101 .034A6.75 6.75 0 013.34 1.116zM12.643.41a.5.5 0 00.016.706 6.75 6.75 0 012.087 4.652.5.5 0 001-.034A7.75 7.75 0 0013.35.393a.5.5 0 00-.707.016z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a5 5 0 00-5 5v2.862l-1.429 2.38A.5.5 0 002 12h4v.5a2 2 0 104 0V12h4a.5.5 0 00.429-.757l-1.43-2.381V6a5 5 0 00-5-5zM4 6a4 4 0 018 0v3a.5.5 0 00.071.257L13.117 11H2.883l1.046-1.743A.5.5 0 003.999 9V6zm3 6v.5a1 1 0 102 0V12H7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.828 1.897a.5.5 0 00-.646-.763A9 9 0 001 8a.5.5 0 001 0 8 8 0 012.828-6.103zM15.114 1.192a.5.5 0 00.058.705A8 8 0 0118 8a.5.5 0 001 0 9 9 0 00-3.182-6.866.5.5 0 00-.704.058z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.625 16H3.19c-1.001 0-1.562-1.154-.943-1.941L3.91 11.94A.418.418 0 004 11.682V8a6 6 0 1112 0v3.682c0 .094.032.185.09.259l1.663 2.118c.619.787.058 1.941-.943 1.941h-4.435v.375a2.375 2.375 0 11-4.75 0V16zM5 8a5 5 0 1110 0v3.682c0 .318.107.627.303.877l1.664 2.118a.2.2 0 01-.157.323H3.19a.2.2 0 01-.157-.323l1.664-2.118c.196-.25.303-.559.303-.877V8zm6.375 8v.375a1.375 1.375 0 11-2.75 0V16h2.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNotificationBellAlarmLight)
