import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiWindowNewDownRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.75 4.25V3.5A2.25 2.25 0 009.5 1.25h-7A2.25 2.25 0 00.25 3.5v5a2.25 2.25 0 002.25 2.25h.75v.75a2.25 2.25 0 002.25 2.25h7a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25h-.75zm-10 4.25c0 .414.336.75.75.75h.75V6.5A2.25 2.25 0 015.5 4.25h4.75V3.5a.75.75 0 00-.75-.75h-7a.75.75 0 00-.75.75v5zM5.5 5.75a.75.75 0 00-.75.75v5c0 .414.336.75.75.75h7a.75.75 0 00.75-.75v-5a.75.75 0 00-.75-.75h-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 6.25V5A2.75 2.75 0 0012 2.25H4A2.75 2.75 0 001.25 5v5A2.75 2.75 0 004 12.75h1.25V14A2.75 2.75 0 008 16.75h8A2.75 2.75 0 0018.75 14V9A2.75 2.75 0 0016 6.25h-1.25zM2.75 10c0 .69.56 1.25 1.25 1.25h1.25V9A2.75 2.75 0 018 6.25h5.25V5c0-.69-.56-1.25-1.25-1.25H4c-.69 0-1.25.56-1.25 1.25v5zm4 4c0 .69.56 1.25 1.25 1.25h8c.69 0 1.25-.56 1.25-1.25V9c0-.69-.56-1.25-1.25-1.25H8c-.69 0-1.25.56-1.25 1.25v5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 13.95a1.3 1.3 0 001.3 1.3.75.75 0 010 1.5 2.8 2.8 0 01-2.8-2.8V6.5A3.25 3.25 0 014.5 3.25h10.45a2.8 2.8 0 012.8 2.8.75.75 0 01-1.5 0 1.3 1.3 0 00-1.3-1.3H4.5A1.75 1.75 0 002.75 6.5v7.45z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 21.75a3.25 3.25 0 01-3.25-3.25v-7A3.25 3.25 0 019.5 8.25h10a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-10zM7.75 18.5c0 .966.784 1.75 1.75 1.75h10a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-10a1.75 1.75 0 00-1.75 1.75v7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.68 19.967a1 1 0 01-.36 1.967A4.051 4.051 0 012 17.95V8.5A4.5 4.5 0 016.5 4h13.45a4.05 4.05 0 013.984 3.32 1 1 0 11-1.967.36A2.05 2.05 0 0019.95 6H6.5A2.5 2.5 0 004 8.5v9.45a2.05 2.05 0 001.68 2.017z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 28A4.5 4.5 0 018 23.5v-9a4.5 4.5 0 014.5-4.5h13a4.5 4.5 0 014.5 4.5v9a4.5 4.5 0 01-4.5 4.5h-13zM10 23.5a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 002.5-2.5v-9a2.5 2.5 0 00-2.5-2.5h-13a2.5 2.5 0 00-2.5 2.5v9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7 35.5a4.5 4.5 0 004.5 4.5h1.1a1.5 1.5 0 010 3h-1.1A7.5 7.5 0 014 35.5v-20A7.5 7.5 0 0111.5 8h28a7.5 7.5 0 017.5 7.5v1.1a1.5 1.5 0 01-3 0v-1.1a4.5 4.5 0 00-4.5-4.5h-28A4.5 4.5 0 007 15.5v20z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 56a7.5 7.5 0 01-7.5-7.5v-20a7.5 7.5 0 017.5-7.5h28a7.5 7.5 0 017.5 7.5v20a7.5 7.5 0 01-7.5 7.5h-28zM20 48.5a4.5 4.5 0 004.5 4.5h28a4.5 4.5 0 004.5-4.5v-20a4.5 4.5 0 00-4.5-4.5h-28a4.5 4.5 0 00-4.5 4.5v20z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiWindowNewDownRightRegular)
