import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNewsFeedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.25 1A2.25 2.25 0 002 3.25v8.5A2.25 2.25 0 004.25 14h8.5A2.25 2.25 0 0015 11.75V5.5a.5.5 0 00-1 0v6.25c0 .69-.56 1.25-1.25 1.25h-8.5C3.56 13 3 12.44 3 11.75v-8.5C3 2.56 3.56 2 4.25 2h6.5c.69 0 1.25.56 1.25 1.25v7.25a.5.5 0 001 0V3.25A2.25 2.25 0 0010.75 1h-6.5z"
          fill="currentColor"
        />
        <path
          d="M4.5 10a.5.5 0 000 1h6a.5.5 0 000-1h-6zM4 8.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zM8.5 6a.5.5 0 000 1h2a.5.5 0 000-1h-2zM8 4.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 4a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h1.5A.75.75 0 007 6.25v-1.5A.75.75 0 006.25 4h-1.5zM5 6V5h1v1H5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5.75 2A2.75 2.75 0 003 4.75v10.5A2.75 2.75 0 005.75 18h9.5A2.75 2.75 0 0018 15.25V7.5a.5.5 0 00-1 0v7.75A1.75 1.75 0 0115.25 17h-9.5A1.75 1.75 0 014 15.25V4.75C4 3.784 4.784 3 5.75 3h7.5c.966 0 1.75.784 1.75 1.75v9.75a.5.5 0 001 0V4.75A2.75 2.75 0 0013.25 2h-7.5z"
        fill="currentColor"
      />
      <path
        d="M9.5 6a.5.5 0 000 1h3a.5.5 0 000-1h-3zM9 8.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM5.5 10a.5.5 0 000 1h7a.5.5 0 000-1h-7zM5 12.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 6a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h1.5A.75.75 0 008 8.25v-1.5A.75.75 0 007.25 6h-1.5zM6 8V7h1v1H6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNewsFeedLight)
