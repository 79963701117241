import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowUiShareAImportLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.06 3.4A3 3 0 016 1h6a3 3 0 013 3v7a3 3 0 01-3 3H6a3 3 0 01-3-3v-.5a.5.5 0 011 0v.5a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H6a2 2 0 00-1.96 1.6.5.5 0 01-.98-.2z"
          fill="currentColor"
        />
        <path
          d="M.5 3a.5.5 0 01.5.5v.05A3.45 3.45 0 004.45 7h5.343L7.646 4.854a.5.5 0 11.708-.708l2.292 2.293a1.5 1.5 0 010 2.122l-2.292 2.293a.5.5 0 01-.708-.708L9.793 8H4.45A4.45 4.45 0 010 3.55V3.5A.5.5 0 01.5 3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.2 4.333A3.501 3.501 0 017.5 2h7A3.5 3.5 0 0118 5.5v8a3.5 3.5 0 01-3.5 3.5h-7A3.5 3.5 0 014 13.5v-1a.5.5 0 011 0v1A2.5 2.5 0 007.5 16h7a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0014.5 3h-7a2.501 2.501 0 00-2.358 1.667.5.5 0 01-.943-.334z"
        fill="currentColor"
      />
      <path
        d="M.5 4a.5.5 0 01.5.5v1A3.5 3.5 0 004.5 9H12c.014 0 .029 0 .043.002a.736.736 0 00-.03-.032L9.146 6.104a.5.5 0 11.708-.708l2.866 2.867a1.75 1.75 0 010 2.474l-2.866 2.867a.5.5 0 01-.708-.708l2.867-2.866.03-.032A.506.506 0 0112 10H4.5A4.5 4.5 0 010 5.5v-1A.5.5 0 01.5 4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiShareAImportLight)
