import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconArrowALeftLight } from '@ui/icons'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import TableProducts from '../components/TableProducts/TableProducts'
import { useProductStore } from '../store/ProductStore'

import styles from './styles.module.scss'

const ProductsPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const productStore = useProductStore()

  useEffect(() => {
    if (!productStore.ProductId) {
      productStore.ProductId = +id
    }
    productStore.getCategoryProduct(
      productStore.ProductId ? productStore.ProductId : +id,
    )
  }, [])

  return (
    <PageWrapper
      className={styles.products}
      title={productStore.categoryProduct?.shortName}
      isLoading={productStore.loading}
      headerRight={
        <Button
          type="fill"
          withBorder
          className={styles.button}
          size="small"
          onClick={useCallback(() => navigate(EMainRoutes.MainProducts), [])}
          IconLeft={IconArrowALeftLight}>
          Назад
        </Button>
      }>
      <div className={styles.description_card}>
        <CardWrapper px={20} py={20} className={styles.withPhoto}>
          <Typography weight={'medium'} className={styles.title} size={22}>
            {productStore.categoryProduct?.name}
          </Typography>
          <img
            src={productStore.categoryProduct?.file}
            className={styles.image}
          />
          <Typography size={18} className={styles.description}>
            {productStore.categoryProduct?.description}
          </Typography>
        </CardWrapper>
        {productStore.categoryProduct?.additionalInfo?.map((info, i) => (
          <CardWrapper px={20} py={20} key={i}>
            <Typography weight={'medium'} className={styles.title} size={18}>
              {info.title}
            </Typography>
            <Typography size={16}>{info.description}</Typography>
          </CardWrapper>
        ))}
      </div>
      <Typography className={styles.models} size={22}>
        Модельный ряд
      </Typography>
      <CardWrapper px={16} py={20} className={styles.table}>
        <TableProducts items={productStore.categoryProduct?.items} />
      </CardWrapper>
    </PageWrapper>
  )
}

export default observer(ProductsPage)
