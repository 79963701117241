import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeTriangleALeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.922a1 1 0 00-1.546-.839l-6.193 4.03a1 1 0 00-.008 1.672l6.193 4.115A1 1 0 0012 12.067V3.922zm-7.194 4.03L11 3.922v8.145L4.806 7.952z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.121a1.1 1.1 0 00-1.71-.915L4.97 9.085a1.1 1.1 0 000 1.83l7.319 4.88a1.1 1.1 0 001.71-.916V5.121zm-1.156-.083a.1.1 0 01.156.083v9.758a.1.1 0 01-.156.083l-7.318-4.878a.1.1 0 010-.167l7.318-4.879z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleALeftLight)
