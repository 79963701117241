import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCDownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.97 5.47a.75.75 0 011.06 0l3.793 3.793a.25.25 0 00.354 0L11.97 5.47a.75.75 0 111.06 1.06l-3.793 3.793a1.75 1.75 0 01-2.474 0L2.97 6.53a.75.75 0 010-1.06z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.78 6.845a.75.75 0 010 1.06l-4.836 4.836a2.75 2.75 0 01-3.889 0L3.22 7.905a.75.75 0 011.06-1.06l4.837 4.836a1.25 1.25 0 001.768 0l4.835-4.836a.75.75 0 011.061 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M20.617 7.881a.875.875 0 010 1.238l-6.407 6.407a3.125 3.125 0 01-4.42 0L3.384 9.119A.875.875 0 114.62 7.88l6.407 6.407a1.375 1.375 0 001.944 0l6.407-6.407a.875.875 0 011.238 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M27.207 10.793a1 1 0 010 1.414l-8.379 8.379a4 4 0 01-5.656 0l-8.38-8.379a1 1 0 111.415-1.414l8.379 8.379a2 2 0 002.828 0l8.379-8.38a1 1 0 011.414 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M54.06 21.94a1.5 1.5 0 010 2.12L37.304 40.819a7.5 7.5 0 01-10.606 0L9.939 24.061a1.5 1.5 0 012.122-2.122l16.757 16.758a4.5 4.5 0 006.364 0l16.757-16.758a1.5 1.5 0 012.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCDownRegular)
