import { ICompany } from '@modules/company/models/Company'

export enum EUserRole {
  Employee = 1,
  Partner = 2,
}

export type TInitials = {
  firstName: string
  lastName: string
  patronymic: string
}

/**
 * @prop id Идентификатор.
 * @prop fullName ФИО.
 * @prop email Емейл.
 * @prop phone Телефон.
 * @prop role Роль.
 * @prop company Компания.
 */
export interface IUser {
  id: string
  fullName: string
  email: string
  phone: string
  role: EUserRole
  company: ICompany
}
