import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'
import { EServerError } from '@lib/services'

import { AuthStore } from '@modules/auth/store/AuthStore'

@singleton()
class AuthFormStore {
  private authStore = container.resolve(AuthStore)

  form = new FormConstructor({
    email: {
      errorText: 'Введен неверный логин или пароль',
      rule: 'required|email',
    },
    password: {
      errorText: 'Введен неверный логин или пароль',
      rule: 'required',
    },
  })

  constructor() {
    makeAutoObservable<AuthFormStore, 'authStore'>(this, {
      authStore: false,
    })
  }

  get submitFormDisabled() {
    return !this.form.fields.email.value || !this.form.fields.password.value
  }

  get formError() {
    return this.form.fields.email.error || this.form.fields.password.error
  }

  handleSubmit = async () => {
    await this.form.handleSubmit(
      (fields) =>
        this.authStore.login({
          email: fields.email.value,
          password: fields.password.value,
        }),
      (errName) => {
        if (errName === EServerError.WrongLoginData) {
          return 'email'
        }
      },
    )
  }
}

export const useAuthFormStore = () => container.resolve(AuthFormStore)
