import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeDRadiusUpRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.982c0-.846 1.024-1.27 1.622-.672l5.805 5.805a1.25 1.25 0 010 1.768l-5.805 5.805c-.598.599-1.622.175-1.622-.672v-2.758c-1.41.034-2.279.176-2.996.505-.83.38-1.543 1.05-2.643 2.367a.75.75 0 01-1.324-.428c-.225-3.159.586-5.435 2.095-6.916C4.446 5.497 6.198 4.901 8 4.776V1.981zM9.5 3.31V5.5a.75.75 0 01-.75.75h-.003c-1.804 0-3.437.5-4.565 1.607-.843.827-1.471 2.058-1.638 3.844.585-.565 1.166-.996 1.835-1.302 1.155-.53 2.485-.65 4.368-.65h.003a.75.75 0 01.75.75v2.19l4.689-4.69L9.5 3.31z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.957c0-1.114 1.346-1.672 2.134-.884l6.69 6.69a1.75 1.75 0 010 2.474l-6.69 6.69c-.788.787-2.134.23-2.134-.884V14c-1.32.006-2.358.054-3.394.395-1.14.375-2.342 1.132-3.866 2.746-.574.609-1.692.293-1.728-.64-.14-3.695.917-6.355 2.738-8.085C5.437 6.816 7.69 6.097 10 6.01V2.957zm1.5.603v3.19a.75.75 0 01-.75.75h-.253c-2.218 0-4.262.627-5.714 2.005-1.261 1.198-2.156 3.038-2.27 5.747 1.28-1.194 2.437-1.89 3.624-2.281 1.427-.47 2.833-.472 4.36-.472h.253a.75.75 0 01.75.75v3.19l6.263-6.263a.25.25 0 000-.353L11.5 3.56z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.448c0-1.202 1.455-1.805 2.306-.954l8.178 8.18c.732.731.732 1.918 0 2.65l-8.178 8.18c-.85.85-2.305.248-2.305-.955V17c-1.8 0-3.18.008-4.564.454-1.37.441-2.807 1.336-4.608 3.24-.604.638-1.777.306-1.814-.673-.168-4.415 1.09-7.691 3.219-9.865C6.298 8.047 9.106 7.055 12 7.002V3.448zm1.5.363V7.75a.75.75 0 01-.75.75h-.504c-2.657 0-5.151.878-6.94 2.704-1.623 1.657-2.733 4.162-2.803 7.686 1.599-1.535 3.023-2.398 4.474-2.865 1.637-.527 3.247-.526 4.998-.525h.776a.75.75 0 01.75.75v3.937l7.922-7.923a.375.375 0 000-.53L13.501 3.81z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.995 5.113c0-1.67 2.02-2.507 3.2-1.326l9.92 9.916a3.25 3.25 0 010 4.597l-9.92 9.915c-1.18 1.181-3.2.345-3.2-1.326v-4.884c-2.002.024-3.631.152-5.254.725-1.866.659-3.815 1.944-6.273 4.556-.807.858-2.37.407-2.42-.894-.223-5.862 1.447-10.01 4.31-12.682 2.6-2.426 6.062-3.518 9.637-3.687v-4.91zm2 .302V11a1 1 0 01-1 1c-3.594 0-6.913.97-9.272 3.172-2.125 1.983-3.594 5.077-3.69 9.702 2.165-2.109 4.083-3.338 6.042-4.03 2.244-.793 4.453-.844 6.92-.844a1 1 0 011 1v5.588l9.706-9.703a1.25 1.25 0 000-1.768l-9.706-9.702z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.001 10.226c0-2.895 3.501-4.345 5.549-2.298L59.439 28.82a4.5 4.5 0 010 6.364l-20.89 20.89c-2.047 2.048-5.548.598-5.548-2.297v-9.774c-4.282.014-7.758.14-11.24 1.264-3.832 1.238-7.797 3.738-12.737 8.968-1.33 1.408-3.894.663-3.975-1.469-.441-11.63 2.882-19.889 8.479-25.227 5.212-4.972 12.216-7.239 19.473-7.51v-9.802zm3 0v11.275a1.5 1.5 0 01-1.5 1.5H34.5c-7.252 0-14.04 2.07-18.901 6.708-4.584 4.372-7.639 11.2-7.588 21.26 4.668-4.694 8.715-7.23 12.828-8.558 4.367-1.41 8.687-1.41 13.535-1.41h.127a1.5 1.5 0 011.5 1.5v11.275c0 .22.272.332.427.177l20.89-20.891a1.5 1.5 0 000-2.121L36.427 10.05a.251.251 0 00-.427.176z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusUpRightRegular)
