import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconEyeClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.467 6.298a.5.5 0 10-.934-.356A6.995 6.995 0 018 10.447a6.995 6.995 0 01-6.533-4.505.5.5 0 10-.934.356 8.009 8.009 0 001.378 2.328L.636 9.98a.5.5 0 10.728.685l1.242-1.319a7.983 7.983 0 002.796 1.668l-.665 1.626a.5.5 0 10.926.379l.71-1.739a8.007 8.007 0 003.253 0l.711 1.739a.5.5 0 00.926-.38l-.665-1.625a7.982 7.982 0 002.797-1.668l1.24 1.319a.5.5 0 10.729-.685l-1.275-1.356a8.009 8.009 0 001.378-2.327z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19.217 7.828a.5.5 0 00-.934-.357c-1.276 3.342-4.504 5.713-8.283 5.713s-7.007-2.37-8.283-5.713a.5.5 0 10-.934.357 9.887 9.887 0 001.774 2.958L.886 12.562a.5.5 0 00.728.685l1.637-1.739a9.853 9.853 0 003.668 2.185l-.882 2.155a.5.5 0 00.926.379l.928-2.27a9.882 9.882 0 004.218 0l.928 2.27a.5.5 0 10.926-.379l-.882-2.156a9.852 9.852 0 003.668-2.184l1.636 1.739a.5.5 0 00.728-.685l-1.67-1.776a9.887 9.887 0 001.774-2.958z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeClosedLight)
