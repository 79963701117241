import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1GearLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a3.502 3.502 0 100 7.003A3.502 3.502 0 008 1zM5.498 4.502a2.502 2.502 0 115.004 0 2.502 2.502 0 01-5.004 0z"
          fill="currentColor"
        />
        <path
          d="M6.56 10.07a.5.5 0 10-.12-.993c-.952.115-2.056.39-2.93 1.062C2.606 10.832 2 11.91 2 13.5a.5.5 0 001 0c0-1.3.478-2.075 1.118-2.568.668-.513 1.564-.756 2.442-.863z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.128 11.208a2.498 2.498 0 000 1.584l-.91.525a.5.5 0 00.5.866l.91-.525c.353.398.83.682 1.372.792v1.05a.5.5 0 001 0v-1.05a2.497 2.497 0 001.371-.792l.91.525a.5.5 0 00.5-.866l-.91-.525a2.497 2.497 0 000-1.584l.91-.525a.5.5 0 00-.5-.866l-.91.525A2.497 2.497 0 0012 9.55V8.5a.5.5 0 00-1 0v1.05a2.497 2.497 0 00-1.371.792l-.91-.525a.5.5 0 10-.5.866l.91.525zm1.066.054a1.494 1.494 0 000 1.476l.007.012.006.012a1.5 1.5 0 002.6-1.5l-.008-.012-.006-.012a1.5 1.5 0 00-2.6.024z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a4.499 4.499 0 100 8.997A4.499 4.499 0 0010 2zM6.501 6.499a3.499 3.499 0 116.998 0 3.499 3.499 0 01-6.998 0z"
        fill="currentColor"
      />
      <path
        d="M9.51 13.005a.5.5 0 00-.02-1c-1.31.026-3.153.174-4.682.917-.772.375-1.48.909-1.993 1.668C2.299 15.353 2 16.312 2 17.5a.5.5 0 001 0c0-1.012.252-1.771.643-2.35.393-.581.949-1.011 1.602-1.329 1.323-.642 2.98-.791 4.265-.816z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.879 14.562a2.78 2.78 0 000 1.875l-1.09.63a.5.5 0 10.5.865l1.09-.63c.409.48.977.82 1.623.938v1.259a.5.5 0 101 0V18.24a2.782 2.782 0 001.623-.937l1.091.63a.5.5 0 00.5-.867l-1.09-.63a2.78 2.78 0 000-1.874l1.09-.63a.5.5 0 10-.5-.865l-1.09.63a2.782 2.782 0 00-1.624-.938V11.5a.5.5 0 10-1 0v1.259a2.783 2.783 0 00-1.623.937l-1.09-.63a.5.5 0 10-.5.867l1.09.629zm1.07.057a1.777 1.777 0 00.001 1.762l.006.011.006.01a1.785 1.785 0 003.079.004l.008-.014a1.777 1.777 0 00.24-.893 1.777 1.777 0 00-.25-.908 1.785 1.785 0 00-1.528-.877h-.018a1.785 1.785 0 00-1.544.905z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1GearLight)
