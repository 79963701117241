import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeAUpLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 1.999A1.75 1.75 0 002 3.749v8.802c0 .98 1.185 1.47 1.878.777l2.251-2.25 2.753 2.752a1.5 1.5 0 002.121 0l2.827-2.828a1.5 1.5 0 000-2.12l-2.752-2.754 2.251-2.252c.693-.693.202-1.878-.778-1.878l-8.8.001zM3 3.749a.75.75 0 01.75-.75l8.802-.001c.029 0 .045.008.056.016.014.009.027.025.036.046a.093.093 0 01-.022.109l-2.605 2.605a.5.5 0 000 .708l3.106 3.106a.5.5 0 010 .707l-2.827 2.828a.5.5 0 01-.707 0l-3.106-3.106a.5.5 0 00-.708 0L3.171 12.62a.099.099 0 01-.17-.07V3.749z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.031A1.969 1.969 0 003.031 5v10.491c0 .974 1.178 1.462 1.867.774l2.461-2.461 3.613 3.612c.671.672 1.76.671 2.43 0l4.014-4.013a1.719 1.719 0 000-2.43L13.802 7.36l2.462-2.462c.688-.689.2-1.867-.774-1.867H5zM3.969 5c0-.57.461-1.031 1.031-1.031h10.491c.14 0 .209.168.11.266L12.81 7.028a.469.469 0 000 .663l3.944 3.944c.305.305.305.8 0 1.105l-4.013 4.013a.781.781 0 01-1.105 0L7.69 12.81a.469.469 0 00-.663 0l-2.793 2.793a.156.156 0 01-.266-.11V5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeAUpLeftLight)
