import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Table } from '@ui/Table'
import TableItem from '@ui/Table/components/TableItem'
import TableRow from '@ui/Table/components/TableRow'
import { HeaderItem } from '@ui/Table/types'

import { EMainRoutes } from '@lib/navigation'
import { getRouteById } from '@lib/navigation/MainRoutes'

import { useTransactionStore } from '@modules/sales/store/TransactionStore'

import TablePlacholder from '../TablePlacholder'
import TransactionTableStatus from '../TransactionTableStatus'

interface ITransactionTableProps {
  header: HeaderItem[]
}

const TransactionTable = ({ header }: ITransactionTableProps) => {
  const transactionStore = useTransactionStore()

  useEffect(() => {
    transactionStore.clearTransaction()
  }, [])

  if (transactionStore.transactions.length === 0) {
    return <TablePlacholder header={header} />
  }

  return (
    <Table header={header}>
      {transactionStore.transactions.map((transaction) => (
        <TableRow key={transaction.id}>
          <TableItem align="left">{transaction.id}</TableItem>
          <TableItem align="center">
            {new Date(transaction.date).toLocaleDateString()}
          </TableItem>
          <TableItem align="center">
            <TransactionTableStatus
              status={transaction.status}
              stage={transaction.stage}
            />
          </TableItem>
          <TableItem align="center">
            <Button
              size="small"
              fullSize
              linkTo={getRouteById(
                transaction.id,
                EMainRoutes.SalesTransaction,
              )}>
              Смотреть
            </Button>
          </TableItem>
        </TableRow>
      ))}
    </Table>
  )
}

export default observer(TransactionTable)
