import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import {
  ICategoryProduct,
  ICategorysProduct,
  IProduct,
} from '@modules/products/models/Products'
import { ProductRest } from '@modules/products/services/ProductRest'

@singleton()
export class ProductStore {
  private productRest = container.resolve(ProductRest)

  categoryProduct?: ICategoryProduct = undefined
  categorysProduct?: ICategorysProduct = undefined
  product?: IProduct = undefined
  ProductTab: IProduct = undefined
  ProductId: number = undefined
  loading = true

  constructor() {
    makeAutoObservable<ProductStore, 'productRest'>(this, {
      productRest: false,
    })
  }

  private setCategoryProduct(data: ICategoryProduct) {
    this.categoryProduct = data
  }

  private setCategorysProduct(data: ICategorysProduct) {
    this.categorysProduct = data
  }

  private setProduct(data: IProduct) {
    this.product = data
  }

  async getCategorysProduct(offset = 0, limit = 15) {
    this.loading = true
    const data = await this.productRest.getCategorysProduct(offset, limit)

    this.setCategorysProduct(data)
    this.loading = false
  }

  async getProduct(id: number) {
    this.loading = true
    const data = await this.productRest.getProduct(id)

    this.setProduct(data)
    this.loading = false
  }

  async getCategoryProduct(id: number) {
    this.loading = true
    const data = await this.productRest.getCategoryProduct(id)

    this.setCategoryProduct(data)
    this.loading = false
  }
}

export const useProductStore = () => container.resolve(ProductStore)
