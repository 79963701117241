import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople2Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 2a3 3 0 100 6 3 3 0 000-6zm-2 3a2 2 0 114 0 2 2 0 01-4 0zM12 3.94a2.06 2.06 0 100 4.12 2.06 2.06 0 000-4.12zM10.94 6a1.06 1.06 0 112.12 0 1.06 1.06 0 01-2.12 0z"
          fill="currentColor"
        />
        <path
          d="M11.493 10.011c-.377 0-.702.034-.98.093a3.452 3.452 0 00-.687-.848c.475-.162 1.032-.245 1.667-.245 1.13 0 2.013.263 2.623.765.622.51.9 1.22.9 1.975v.747a.5.5 0 11-1 0v-.747c0-.497-.175-.907-.535-1.203-.373-.306-1-.537-1.988-.537zM2.327 10.93c-.196.25-.332.59-.332 1.077v.493a.5.5 0 01-1 0v-.493c0-.691.2-1.253.546-1.695.342-.434.801-.718 1.274-.906C3.745 9.036 4.83 9 5.495 9s1.75.035 2.68.406c.473.188.932.472 1.274.906.347.442.546 1.004.546 1.695v.493a.5.5 0 01-1 0v-.493c0-.487-.136-.827-.332-1.076-.202-.257-.494-.452-.857-.596C7.067 10.04 6.152 10 5.496 10c-.658 0-1.573.04-2.312.335-.362.144-.655.34-.857.596z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM4.5 6.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM14.896 5.167a2.583 2.583 0 100 5.166 2.583 2.583 0 000-5.166zM13.312 7.75a1.583 1.583 0 113.167 0 1.583 1.583 0 01-3.166 0z"
        fill="currentColor"
      />
      <path
        d="M14.675 13.01c-.257 0-.507.011-.747.034a4.312 4.312 0 00-.511-.943 8.329 8.329 0 011.258-.092c1.05 0 2.103.176 2.912.61.832.448 1.43 1.19 1.43 2.258v.704a.5.5 0 11-1 0v-.704c0-.62-.318-1.062-.903-1.377-.609-.326-1.477-.49-2.44-.49zM2.493 13.365c-.3.384-.499.9-.499 1.617v.599a.5.5 0 01-1 0v-.6c0-.918.261-1.655.71-2.23.443-.57 1.044-.948 1.673-1.2 1.245-.501 2.706-.55 3.62-.55.914 0 2.375.049 3.62.55.63.252 1.23.63 1.673 1.2.449.575.71 1.312.71 2.23v.6a.5.5 0 11-1 0v-.6c0-.716-.2-1.232-.498-1.616-.305-.39-.739-.678-1.258-.887C9.193 12.056 7.902 12 6.997 12c-.905 0-2.195.056-3.247.478-.519.209-.953.496-1.257.887z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople2Light)
