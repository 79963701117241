import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDataChartBarsXLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.5 1a.5.5 0 01.5.5v9a.5.5 0 01-1 0v-9a.5.5 0 01.5-.5zM7 3.5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7zM9.5 5a.5.5 0 01.5.5v5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5zM3.5 7a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zM1.5 13a.5.5 0 000 1h13a.5.5 0 000-1h-13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.5 3a.5.5 0 01.5.5v10.719a.5.5 0 01-1 0V3.5a.5.5 0 01.5-.5zM8 6a.5.5 0 00-1 0v8.219a.5.5 0 001 0V6zM3.5 10.25a.5.5 0 01.5.5v3.5a.5.5 0 01-1 0v-3.5a.5.5 0 01.5-.5zM1 17.5a.5.5 0 01.5-.5h16a.5.5 0 010 1h-16a.5.5 0 01-.5-.5zM12 8.35a.5.5 0 00-1 0v5.869a.5.5 0 101 0V8.35z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartBarsXLight)
