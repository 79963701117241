import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMarketShoppingCartRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M.003 2.705a.75.75 0 01.75-.75h1.754a1.75 1.75 0 011.726 1.46l.093.552h9.194a1.5 1.5 0 011.447 1.893l-1.447-.393H4.58l.574 3.408a.75.75 0 00.74.625h5.958a.75.75 0 00.724-.554l.944-3.479 1.447.393-.943 3.479A2.25 2.25 0 0111.853 11h-5.96a2.25 2.25 0 01-2.218-1.876l-.708-4.197a.748.748 0 01-.029-.17l-.184-1.093a.25.25 0 00-.247-.209H.753a.75.75 0 01-.75-.75zM7 13.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM11.5 15a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 2.25a.75.75 0 000 1.5h2.31c.37 0 .684.27.74.634l.113.72a.766.766 0 00.004.025l.762 4.873a3.25 3.25 0 003.21 2.748h6.452a3.25 3.25 0 003.121-2.344l1.137-3.918a1.75 1.75 0 00-1.68-2.238H5.549l-.016-.097A2.25 2.25 0 003.31 2.25H1zm5.41 7.52l-.627-4.02h11.386a.25.25 0 01.24.32l-1.137 3.918a1.75 1.75 0 01-1.68 1.262H8.14a1.75 1.75 0 01-1.73-1.48zM4.25 16.25a2.313 2.313 0 114.625 0 2.313 2.313 0 01-4.625 0zm2.313-.812a.813.813 0 100 1.625.813.813 0 000-1.625zM12.427 16.25a2.313 2.313 0 114.625 0 2.313 2.313 0 01-4.625 0zm2.313-.812a.812.812 0 100 1.625.812.812 0 000-1.625z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1 3.25a.75.75 0 000 1.5h2.73c.548 0 1.017.395 1.11.936l1.112 6.528a4.25 4.25 0 004.19 3.536h6.875a4.25 4.25 0 004.123-3.22l1.238-4.95a1.875 1.875 0 00-1.819-2.33H9a.75.75 0 000 1.5h11.56c.243 0 .422.23.363.466l-1.238 4.951a2.75 2.75 0 01-2.668 2.083h-6.876a2.75 2.75 0 01-2.71-2.288L6.317 5.434A2.625 2.625 0 003.73 3.25H1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 19.5a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0zm2.625-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM15.25 19.5a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0zm2.625-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1 4a1 1 0 000 2h3.632a1.5 1.5 0 011.48 1.253l1.595 9.569A5 5 0 0012.639 21h10.238a5 5 0 004.85-3.787l1.777-7.107A2.5 2.5 0 0027.08 7H11.5a1 1 0 100 2h15.579a.5.5 0 01.485.621l-1.777 7.107A3 3 0 0122.877 19H12.639a3 3 0 01-2.96-2.507L8.085 6.925A3.5 3.5 0 004.632 4H1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 26a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM7 26a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M2 10a1.5 1.5 0 000 3h7.288a3.5 3.5 0 013.45 2.905l3.139 18.21A9.5 9.5 0 0025.239 42h20.58a9.5 9.5 0 009.193-7.102l3.46-13.262C59.214 18.785 57.062 16 54.116 16H22a1.5 1.5 0 000 3h32.117a1.5 1.5 0 011.451 1.879l-3.46 13.262A6.5 6.5 0 0145.82 39H25.24a6.5 6.5 0 01-6.406-5.396l-3.14-18.208A6.5 6.5 0 009.289 10H2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 52a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm6.5-3.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM41.5 52a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm6.5-3.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketShoppingCartRegular)
