import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLocationPinRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.748a3.252 3.252 0 100 6.504 3.252 3.252 0 000-6.504zM6.248 7a1.752 1.752 0 113.504 0 1.752 1.752 0 01-3.504 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1C5.723 1 3.665 2.236 2.623 4.282c-1.036 2.036-.706 3.992.167 5.73.856 1.705 2.269 3.275 3.514 4.625.91.987 2.48.975 3.382-.01 1.23-1.341 2.637-2.89 3.498-4.574.88-1.718 1.23-3.655.23-5.693C12.39 2.269 10.31 1 8 1zM3.96 4.963C4.742 3.427 6.278 2.5 8 2.5c1.746 0 3.299.952 4.068 2.52.73 1.489.517 2.911-.219 4.35-.753 1.472-2.014 2.874-3.269 4.244a.804.804 0 01-1.173.006c-1.264-1.37-2.53-2.793-3.277-4.28-.73-1.455-.927-2.89-.17-4.377z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4.719a4.031 4.031 0 100 8.062 4.031 4.031 0 000-8.062zM7.47 8.75a2.531 2.531 0 115.062 0 2.531 2.531 0 01-5.062 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.438c-2.777 0-5.284 1.506-6.554 4-1.26 2.476-.864 4.856.209 6.994 1.056 2.103 2.804 4.047 4.363 5.737 1.063 1.152 2.898 1.14 3.952-.011 1.539-1.681 3.28-3.6 4.344-5.678 1.08-2.112 1.501-4.469.286-6.947-1.25-2.55-3.785-4.096-6.6-4.096zm-5.217 4.68c1.01-1.983 2.995-3.18 5.217-3.18 2.253 0 4.26 1.229 5.253 3.255.946 1.929.663 3.771-.275 5.604-.955 1.867-2.55 3.639-4.114 5.348-.466.508-1.28.509-1.743.008-1.578-1.712-3.179-3.508-4.126-5.394-.93-1.854-1.193-3.714-.212-5.64z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.813a4.687 4.687 0 100 9.374 4.687 4.687 0 000-9.374zM8.813 10.5a3.188 3.188 0 116.375 0 3.188 3.188 0 01-6.375 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.875c-3.277 0-6.233 1.777-7.73 4.718-1.485 2.917-1.024 5.721.25 8.258 1.256 2.502 3.339 4.82 5.212 6.85 1.215 1.318 3.316 1.303 4.521-.013 1.85-2.02 3.924-4.307 5.19-6.78 1.283-2.507 1.773-5.284.342-8.203C18.311 3.7 15.322 1.875 12 1.875zM5.606 7.274C6.844 4.844 9.278 3.375 12 3.375c2.76 0 5.22 1.507 6.438 3.99 1.162 2.37.81 4.633-.33 6.86-1.158 2.261-3.085 4.402-4.961 6.45a1.58 1.58 0 01-2.312.01c-1.892-2.051-3.827-4.222-4.975-6.507-1.13-2.253-1.46-4.537-.254-6.904z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 7.977a6.086 6.086 0 100 12.171 6.086 6.086 0 000-12.171zm-4.086 6.085a4.086 4.086 0 118.172 0 4.086 4.086 0 01-8.172 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2.89c-4.245 0-8.074 2.302-10.013 6.112-1.924 3.779-1.325 7.412.323 10.694 1.625 3.237 4.319 6.234 6.738 8.856 1.582 1.715 4.316 1.697 5.885-.017 2.389-2.608 5.072-5.566 6.708-8.765 1.66-3.243 2.297-6.84.443-10.623C24.174 5.253 20.303 2.891 16 2.891zM7.769 9.91c1.593-3.13 4.727-5.02 8.23-5.02 3.554 0 6.722 1.94 8.289 5.138 1.495 3.049 1.042 5.96-.427 8.831-1.493 2.917-3.98 5.679-6.403 8.325-.784.857-2.158.86-2.94.012-2.444-2.65-4.94-5.45-6.42-8.397-1.458-2.904-1.88-5.843-.33-8.89z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16c-6.628 0-12 5.373-12 12s5.372 12 12 12c6.627 0 12-5.373 12-12s-5.373-12-12-12zm-9 12a9 9 0 1118 0 9 9 0 01-18 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 5.5c-8.554 0-16.263 4.636-20.17 12.31-3.866 7.593-2.679 14.899.67 21.567 3.314 6.602 8.819 12.733 13.82 18.155 3.04 3.296 8.3 3.262 11.32-.035 4.94-5.395 10.424-11.447 13.762-17.97 3.374-6.591 4.636-13.825.91-21.427C48.465 10.258 40.671 5.5 32 5.5zM14.504 19.17C17.89 12.518 24.557 8.5 32 8.5c7.547 0 14.285 4.124 17.618 10.921 3.188 6.503 2.201 12.707-.886 18.74-3.123 6.1-8.312 11.858-13.305 17.31-1.841 2.01-5.062 2.022-6.902.027-5.039-5.463-10.248-11.3-13.345-17.467-3.063-6.1-3.983-12.365-.676-18.86z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLocationPinRegular)
