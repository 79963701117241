import { memo, useCallback, useMemo } from 'react'

import { DropList } from '@ui/DropList'
import { IDropListItem } from '@ui/DropList/types'
import { IconUiMenuDots3HRegular } from '@ui/icons'

import styles from './styles.module.scss'

interface IDropListEmployee {
  id: number
  goToEmployee: (...props) => void
  onOpenModal: (...props) => void
}

const DropListEmployee: React.FC<IDropListEmployee> = ({
  id,
  goToEmployee,
  onOpenModal,
}) => {
  const opener = useCallback(
    ({ onToggle }) => (
      <IconUiMenuDots3HRegular
        onClick={(event) => onToggle(event)}
        className={styles.icon}
      />
    ),
    [],
  )

  const dropItems: IDropListItem[] = useMemo(
    () => [
      {
        label: 'Профиль',
        onClick: () => goToEmployee(id),
      },
      {
        label: 'Удалить',
        color: 'danger',
        onClick: (event) => onOpenModal(event, id),
      },
    ],
    [id],
  )

  return (
    <DropList
      className={styles.dropList}
      renderOpener={opener}
      items={dropItems}
    />
  )
}

export default memo(DropListEmployee)
