import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDataChartPieA1Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a.75.75 0 00-.75.75V8a.75.75 0 001.125.65l5.413-3.125a.75.75 0 00.274-1.025A7 7 0 008 1zm.75 5.701v-4.15a5.5 5.5 0 013.594 2.075L8.75 6.701z"
          fill="currentColor"
        />
        <path
          d="M5.586 3.058a.75.75 0 00-.658-1.348 7 7 0 1010.067 6.026.75.75 0 10-1.499.057 5.501 5.501 0 11-7.91-4.735z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001a.75.75 0 00-.75.75v7.25a.75.75 0 001.125.649l6.278-3.623a.75.75 0 00.275-1.025 7.999 7.999 0 00-6.928-4zm.75 6.7V3.545a6.499 6.499 0 014.466 2.58L10.75 8.7z"
          fill="currentColor"
        />
        <path
          d="M7.186 4.142a.75.75 0 00-.65-1.352 7.999 7.999 0 1011.447 6.707.75.75 0 00-1.497.095 6.5 6.5 0 11-9.3-5.45z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25a.75.75 0 00-.75.75v9a.75.75 0 001.125.65l7.794-4.5a.75.75 0 00.275-1.025A9.75 9.75 0 0012 2.25zm.75 8.451V3.784a8.25 8.25 0 015.99 3.459L12.75 10.7z"
          fill="currentColor"
        />
        <path
          d="M9.026 4.305a.75.75 0 10-.54-1.4 9.75 9.75 0 1013.172 7.757.75.75 0 00-1.486.205A8.249 8.249 0 119.026 4.305z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3a1 1 0 00-1 1v12a1 1 0 001.5.866l10.392-6a1 1 0 00.366-1.366A13 13 0 0016 3zm1 11.268V5.046a11 11 0 017.987 4.61L17 14.269z"
          fill="currentColor"
        />
        <path
          d="M11.416 6a1 1 0 00-.834-1.817 13 13 0 1018.371 10.713 1 1 0 00-1.993.17 11 11 0 11-15.544-9.065z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.031 6.006A1.992 1.992 0 0031 8v22.634c0 1.54 1.667 2.502 3 1.732l19.602-11.317c.95-.548 1.3-1.784.711-2.756A25.5 25.5 0 0033.031 6.006zM34 28.902V9.05a22.5 22.5 0 0117.192 9.926L34 28.902z"
        fill="currentColor"
      />
      <path
        d="M24.964 10.103a1.5 1.5 0 00-.918-2.856A26 26 0 1057.519 27.02a1.5 1.5 0 00-2.945.575 23 23 0 11-29.61-17.493z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartPieA1Regular)
