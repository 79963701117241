import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EDataBanner, EKeyBanner } from '../models/Banners'
import { BannerRest } from '../services/BannerRest'

@singleton()
export class BannerStore {
  bannerRest = container.resolve(BannerRest)

  bannerData: EDataBanner = undefined

  constructor() {
    makeAutoObservable<BannerStore, 'bannerRest'>(this, {
      bannerRest: false,
    })
  }

  private setBannerData(data: EDataBanner) {
    this.bannerData = data
  }

  async fetchBannerData() {
    if (this.bannerData === undefined) {
      const data = await this.bannerRest.fetchBannerData(EKeyBanner.MainPage)

      this.setBannerData(data)
    }
  }
}

export const useBannerStore = () => container.resolve(BannerStore)
