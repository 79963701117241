import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { ICalendarEventsData } from '../models/Calendar'

@singleton()
export class CalendarRest extends AbstractRest {
  async fetchCalendarEvents() {
    const { data } = await this.http.get('/lk/events')

    return data.data
  }

  async fetchCalendarEvent(id: number) {
    const { data } = await this.http.get<ICalendarEventsData>(
      '/lk/events/' + id,
    )

    return data
  }
}
