import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextBulletRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 5.25A1.251 1.251 0 103 2.75a1.251 1.251 0 000 2.502zM6 4a.75.75 0 01.75-.75H13a.75.75 0 010 1.5H6.75A.75.75 0 016 4zM6.75 7.25a.75.75 0 000 1.5H13a.75.75 0 000-1.5H6.75zM6.75 11.25a.75.75 0 000 1.5H13a.75.75 0 000-1.5H6.75zM3 13.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM4.25 8A1.251 1.251 0 111.75 8a1.251 1.251 0 012.502 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 6.25A1.251 1.251 0 104 3.75a1.251 1.251 0 000 2.502zM7 5a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5A.75.75 0 017 5zM7.75 9.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM7.75 14.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM4 16.255a1.25 1.25 0 100-2.502 1.25 1.25 0 000 2.502zM5.25 10.002a1.251 1.251 0 11-2.501 0 1.251 1.251 0 012.502 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.5 6.627a1.627 1.627 0 100-3.254 1.627 1.627 0 000 3.254zM9 5a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H9.75A.75.75 0 019 5zM9.75 11.25a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H9.75zM9.75 18.25a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H9.75zM6.127 12a1.627 1.627 0 11-3.254 0 1.627 1.627 0 013.254 0zM4.5 20.627a1.627 1.627 0 100-3.254 1.627 1.627 0 000 3.254z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 9a2 2 0 100-4 2 2 0 000 4zM12 7a1 1 0 011-1h14a1 1 0 110 2H13a1 1 0 01-1-1zM13 15a1 1 0 100 2h14a1 1 0 100-2H13zM13 24a1 1 0 100 2h14a1 1 0 100-2H13zM8 16a2 2 0 11-4 0 2 2 0 014 0zM6 27a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12 16.5a4 4 0 100-8 4 4 0 000 8zM23 12.5a1.5 1.5 0 011.5-1.5h30a1.5 1.5 0 010 3h-30a1.5 1.5 0 01-1.5-1.5zM24.5 30a1.5 1.5 0 000 3h30a1.5 1.5 0 000-3h-30zM24.5 49a1.5 1.5 0 000 3h30a1.5 1.5 0 000-3h-30zM16 31.5a4 4 0 11-8 0 4 4 0 018 0zM12 54.5a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletRegular)
