import Card from '@ui/Card'
import IconDocArrowSyncRegular from '@ui/icons/data/IconDocArrowSyncRegular'
import IconDocPlusRegular from '@ui/icons/data/IconDocPlusRegular'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import styles from './styles.module.scss'

const SalesPage = () => (
  <PageWrapper title="Продажи">
    <div className={styles.container}>
      <Card
        Icon={IconDocPlusRegular}
        title="Регистрация сделок"
        description="Подать заявку на регистрацию сделки"
        link={EMainRoutes.SalesRegisterTransaction}
        fullWidth
      />
      <Card
        Icon={IconDocArrowSyncRegular}
        title="История сделок"
        description="Просмотр сделок и их статус"
        link={EMainRoutes.SalesTransactions}
        fullWidth
      />
    </div>
  </PageWrapper>
)

export default SalesPage
