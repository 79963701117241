import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFaceAHappyLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.892 8.415a.5.5 0 10-.966-.259 2.502 2.502 0 01-4.834 0 .5.5 0 10-.966.26 3.502 3.502 0 006.766 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM2 7.5a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.922 11.051a.5.5 0 10-.966-.259 3.06 3.06 0 01-5.913 0 .5.5 0 10-.965.259 4.06 4.06 0 007.844 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 100 16 8 8 0 000-16zm-7 8a7 7 0 1114 0 7 7 0 01-14 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFaceAHappyLight)
