import React, { PropsWithChildren } from 'react'

import { cx } from '@lib/styles'

import { AnimatedSlideType } from '@modules/auth/store/AnimatedFormStore'

import styles from './FormAnimation.module.scss'

interface IProps extends PropsWithChildren {
  animationDirection: AnimatedSlideType
}

/**
 * Компонент обертки для форм с анимацией перехода.
 */
export const FormAnimation: React.FC<IProps> = ({
  children,
  animationDirection,
}) => (
  <div
    className={cx(
      styles.formAnimation,
      animationDirection && styles[animationDirection],
    )}>
    {children}
  </div>
)
