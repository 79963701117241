import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowADownRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 11.293V5.5a.5.5 0 011 0v6a1.5 1.5 0 01-1.5 1.5h-6a.5.5 0 010-1h5.793L3.146 3.854a.5.5 0 11.708-.708L12 11.293z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.26 15.966c-.084.022-.17.034-.26.034H6.5a.5.5 0 000 1H15a2 2 0 002-2V6.5a.5.5 0 00-1 0V15a1 1 0 01-.034.259L4.354 3.646a.5.5 0 10-.708.708L15.26 15.966z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowADownRightLight)
