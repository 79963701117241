import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { IRegularIconProps } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'

interface ICardProps {
  Icon?: React.FC<IRegularIconProps>
  title: string
  description: string
  link?: string
  fullWidth?: boolean
  onClick?: () => void
  className?: string
}

const Card = ({
  Icon,
  title,
  description,
  link,
  fullWidth,
  onClick,
  className,
}: ICardProps) => (
  <Link
    to={link}
    className={cx(styles.card, className, {
      [styles.fullWidth]: fullWidth,
    })}
    onClick={onClick}>
    <div className={styles.container}>
      {Icon && <Icon size={32} color={COLORS.BRAND.OCEAN} />}
      <div>
        <Typography size={22}>{title}</Typography>
        <Typography className={styles.subtitle} size={16}>
          {description}
        </Typography>
      </div>
    </div>
  </Link>
)

export default memo(Card)
