import { useMemo } from 'react'
import YouTube from 'react-youtube'

import { observer } from 'mobx-react-lite'

import { Modal } from '@ui/Modal'

import { IVideoPlayerProps } from '@modules/marketing/models/Webinars'
import { useWebinarsStore } from '@modules/marketing/store/WebinarsStore'

import styles from './styles.module.scss'

const REGEXP = '(v=)([A-Za-z0-9_-]{5,11})'

const VideoPlayer = ({ open, handleClick, id, title }: IVideoPlayerProps) => {
  const webinarStore = useWebinarsStore()

  const opts = {
    height: '496',
    width: '886',
  }

  const findVideoId = useMemo((): RegExpMatchArray => {
    const index = webinarStore.webinars?.findIndex((item) => item.id === +id)

    const videoId = webinarStore.webinars[index]?.url?.match(REGEXP)

    return videoId
  }, [webinarStore.webinars])

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClick}
      className={styles.video}
      content={<YouTube opts={opts} videoId={findVideoId[2]} />}
    />
  )
}

export default observer(VideoPlayer)
