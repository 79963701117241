import { useCallback } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { omit } from 'lodash'
import { observer } from 'mobx-react-lite'

import { RadioButton } from '@ui/RadioButton'
import { Select } from '@ui/Select'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import { getCurrencyString } from '@lib/helpers'

import { CardItem } from '@modules/configurator/components'
import { CollapseItem } from '@modules/configurator/components/CollapseItem'
import { ContentBlock } from '@modules/configurator/components/ContentBlock'
import { platformTooltipMap } from '@modules/configurator/components/Tooltips'
import Cpu from '@modules/configurator/components/Tooltips/Cpu'
import Gpu from '@modules/configurator/components/Tooltips/Gpu'
import Hdd from '@modules/configurator/components/Tooltips/Hdd'
import Ram from '@modules/configurator/components/Tooltips/Ram'
import Ssd from '@modules/configurator/components/Tooltips/Ssd'
import { ETubs } from '@modules/configurator/enums'
import {
  IAccessory,
  IComponent,
  IPlatformType,
  ISavedComponent,
} from '@modules/configurator/models/Configurator'
import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

import styles from './SelectPlatform.module.scss'

const SelectPlatform = () => {
  const configuratorStore = useConfiguratorStore()

  const handleClickBack = useCallback(() => {
    configuratorStore.selectTab(ETubs.SERIES)
  }, [])

  const handleClickNext = useCallback(() => {
    configuratorStore.selectTab(ETubs.CART)
  }, [])

  const handleSelect = useCallback(
    (type: string, accessory: IAccessory | null) => {
      configuratorStore.selectAccessory(type, accessory)
    },
    [configuratorStore],
  )

  const handleChangePlatforms = useCallback(
    (
      key: IPlatformType['key'],
      accessories: IComponent['accessories'],
      value: ISavedComponent,
    ) => {
      configuratorStore.setPlatformTypeAccessories(key, accessories, value)
    },
    [configuratorStore],
  )

  return (
    <div className={styles.container}>
      <div className={styles.platforms}>
        {configuratorStore.selectedSeries?.platforms.map((platform) => (
          <CardWrapper
            py={20}
            px={20}
            fullWidth
            key={platform.title}
            className={styles.platform}>
            {platform.types.map((type) => (
              <CollapseItem
                title={type.title}
                description={type.description}
                tooltipTitle={type.title}
                tooltipContent={
                  type.key === 'processor' ? (
                    <Cpu />
                  ) : (
                    <div className={styles.tooltipContent}>
                      <ReactMarkdown>
                        {platformTooltipMap[type.key]}
                      </ReactMarkdown>
                    </div>
                  )
                }
                key={type.title}>
                <div className={styles.radiogroup}>
                  {type.components.map((component) => (
                    <RadioButton
                      key={component.value}
                      label={component.name}
                      name={type.title}
                      checked={
                        component.value ===
                        configuratorStore.selectedData.platforms?.[type.key]
                          .value
                      }
                      value={component.value}
                      onChange={() => {
                        handleChangePlatforms(type.key, component.accessories, {
                          name: component.name,
                          value: component.value,
                          parent: platform.title,
                          title: type.title,
                        })
                      }}
                    />
                  ))}
                </div>
              </CollapseItem>
            ))}
          </CardWrapper>
        ))}
      </div>

      <div className={styles.accessories}>
        <ContentBlock
          onClickBack={handleClickBack}
          onClickNext={handleClickNext}
          disabledBack={configuratorStore.tabs.disabledTabs.includes(
            ETubs.SERIES,
          )}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Typography size={14}>Системный блок</Typography>
              <Typography size={22}>Конфигурация комплектующих</Typography>
            </div>

            {/* CPU */}
            {configuratorStore.accessories?.cpu?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Процессор"
                  tooltipContent={<Cpu />}
                  title="Процессор (CPU)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.cpu.filter(
                  //     (cpu) =>
                  //       cpu.key ===
                  //       configuratorStore.selectedData.accessories.cpu.key,
                  //   )[0]?.price,
                  // )}
                >
                  {configuratorStore.accessories.cpu.length < 5 ? (
                    <div className={styles.cardgroup}>
                      {configuratorStore.accessories.cpu.map((cpu) => (
                        <CardItem
                          key={cpu.key}
                          title={cpu.name}
                          size="small"
                          additionalText={getCurrencyString(cpu.price)}
                          selected={
                            configuratorStore.selectedData.accessories?.cpu
                              ?.key === cpu.key
                          }
                          onClick={() =>
                            handleSelect(
                              cpu.type,
                              configuratorStore.selectedData.accessories?.cpu
                                ?.key === cpu.key
                                ? null
                                : cpu,
                            )
                          }
                          tag={cpu.accent[0]}
                          photoURL={
                            cpu.photoUrl ||
                            'https://img.icons8.com/external-soft-fill-juicy-fish/60/null/external-cpu-computer-hardware-soft-fill-soft-fill-juicy-fish.png'
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div>
                      <Select
                        clearable
                        options={configuratorStore.accessories.cpu.map(
                          (cpu) => ({
                            label: cpu.name,
                            value: cpu.key,
                            tag: cpu.accent[0],
                          }),
                        )}
                        value={
                          configuratorStore.selectedData.accessories?.cpu
                            ?.key || null
                        }
                        onSelect={(_, key) => {
                          handleSelect(
                            'cpu',
                            configuratorStore.accessories.cpu.find(
                              (item) => item.key === key,
                            ),
                          )
                        }}
                      />
                    </div>
                  )}
                </CollapseItem>
              </div>
            )}

            {/* RAM */}
            {configuratorStore.accessories?.ram?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Оперативная память (RAM)"
                  tooltipContent={<Ram />}
                  title="Оперативная память (RAM)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.ram.filter(
                  //     (ram) =>
                  //       ram.key ===
                  //       configuratorStore.selectedData.accessories.ram.key,
                  //   )[0]?.price,
                  // )}
                >
                  <div>
                    <Select
                      options={configuratorStore.accessories.ram.map((ram) => ({
                        label: ram.name,
                        value: ram.key,
                        tag: ram.accent[0],
                      }))}
                      value={
                        configuratorStore.selectedData.accessories?.ram?.key ||
                        null
                      }
                      clearable
                      onSelect={(_, key) => {
                        handleSelect(
                          'ram',
                          configuratorStore.accessories.ram.find(
                            (item) => item.key === key,
                          ),
                        )
                      }}
                    />
                  </div>
                </CollapseItem>
              </div>
            )}

            {/* SSD */}
            {configuratorStore.accessories?.ssd?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Твердотельный диск (SSD)"
                  tooltipContent={<Ssd />}
                  title="Твердотельный диск (SSD)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.ssd.filter(
                  //     (ssd) =>
                  //       ssd.key ===
                  //       configuratorStore.selectedData.accessories.ssd.key,
                  //   )[0]?.price,
                  // )}
                >
                  <div>
                    <Select
                      options={configuratorStore.accessories.ssd.map((ssd) => ({
                        label: ssd.fullName,
                        value: ssd.key,
                        tag: ssd.accent[0],
                      }))}
                      clearable
                      value={
                        configuratorStore.selectedData.accessories?.ssd?.key ||
                        null
                      }
                      onSelect={(_, key) => {
                        handleSelect(
                          'ssd',
                          configuratorStore.accessories.ssd.find(
                            (item) => item.key === key,
                          ),
                        )
                      }}
                    />
                  </div>
                </CollapseItem>
              </div>
            )}

            {/* HDD */}
            {configuratorStore.accessories?.hdd?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Жесткий диск (HDD)"
                  tooltipContent={<Hdd />}
                  title="Жесткий диск (HDD)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.hdd.filter(
                  //     (hdd) =>
                  //       hdd.key ===
                  //       configuratorStore.selectedData.accessories.hdd.key,
                  //   )[0]?.price,
                  // )}
                >
                  <div>
                    <Select
                      options={configuratorStore.accessories.hdd.map((hdd) => ({
                        label: hdd.fullName,
                        value: hdd.key,
                        tag: hdd.accent[0],
                      }))}
                      value={
                        configuratorStore.selectedData.accessories?.hdd?.key ||
                        null
                      }
                      clearable
                      onSelect={(_, key) => {
                        handleSelect(
                          'hdd',
                          configuratorStore.accessories.hdd.find(
                            (item) => item.key === key,
                          ),
                        )
                      }}
                    />
                  </div>
                </CollapseItem>
              </div>
            )}

            {/* GPU */}
            {configuratorStore.accessories?.graphicCard?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Видеокарта (GPU)"
                  tooltipContent={<Gpu />}
                  title="Видеокарта (GPU)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.graphicCard.filter(
                  //     (graphicCard) =>
                  //       graphicCard.key ===
                  //       configuratorStore.selectedData.accessories.graphicCard
                  //         .key,
                  //   )[0]?.price,
                  // )}
                >
                  {configuratorStore.accessories.graphicCard.length < 5 ? (
                    <div className={styles.cardgroup}>
                      {configuratorStore.accessories.graphicCard.map(
                        (graphicCard) => (
                          <CardItem
                            key={graphicCard.key}
                            title={graphicCard.fullName}
                            size="small"
                            additionalText={getCurrencyString(
                              graphicCard.price,
                            )}
                            selected={
                              configuratorStore.selectedData.accessories
                                ?.graphicCard?.key === graphicCard.key
                            }
                            onClick={() =>
                              handleSelect(
                                'graphicCard',
                                configuratorStore.selectedData.accessories
                                  ?.graphicCard?.key === graphicCard.key
                                  ? null
                                  : graphicCard,
                              )
                            }
                            tag={graphicCard.accent[0]}
                            photoURL={
                              graphicCard.photoUrl ||
                              'https://img.icons8.com/external-soft-fill-juicy-fish/60/null/external-graphics-computer-hardware-soft-fill-soft-fill-juicy-fish.png'
                            }
                          />
                        ),
                      )}
                    </div>
                  ) : (
                    <div>
                      <Select
                        options={configuratorStore.accessories.graphicCard.map(
                          (graphicCard) => ({
                            label: graphicCard.fullName,
                            value: graphicCard.key,
                            tag: graphicCard.accent[0],
                          }),
                        )}
                        value={
                          configuratorStore.selectedData.accessories
                            ?.graphicCard?.key || null
                        }
                        clearable
                        onSelect={(_, key) => {
                          handleSelect(
                            'graphicCard',
                            configuratorStore.accessories.graphicCard.find(
                              (item) => item.key === key,
                            ),
                          )
                        }}
                      />
                    </div>
                  )}
                </CollapseItem>
              </div>
            )}

            {/* Monoblock GPU */}
            {configuratorStore.accessories?.monoblockGraphicCard?.length && (
              <div>
                <CollapseItem
                  tooltipTitle="Видеокарта (GPU)"
                  tooltipContent={<Gpu />}
                  title="Видеокарта (GPU)"
                  // titleRight={getCurrencyString(
                  //   configuratorStore.accessories.monoblockGraphicCard.filter(
                  //     (monoblockGraphicCard) =>
                  //       monoblockGraphicCard.key ===
                  //       configuratorStore.selectedData.accessories
                  //         .monoblockGraphicCard.key,
                  //   )[0]?.price,
                  // )}
                >
                  {configuratorStore.accessories.monoblockGraphicCard.length <
                  5 ? (
                    <div className={styles.cardgroup}>
                      {configuratorStore.accessories.monoblockGraphicCard.map(
                        (monoblockGraphicCard) => (
                          <CardItem
                            key={monoblockGraphicCard.key}
                            title={monoblockGraphicCard.fullName}
                            size="small"
                            additionalText={getCurrencyString(
                              monoblockGraphicCard.price,
                            )}
                            selected={
                              configuratorStore.selectedData.accessories
                                ?.monoblockGraphicCard?.key ===
                              monoblockGraphicCard.key
                            }
                            onClick={() =>
                              handleSelect(
                                'monoblockGraphicCard',
                                configuratorStore.selectedData.accessories
                                  ?.monoblockGraphicCard?.key ===
                                  monoblockGraphicCard.key
                                  ? null
                                  : monoblockGraphicCard,
                              )
                            }
                            tag={monoblockGraphicCard.accent[0]}
                            photoURL={
                              monoblockGraphicCard.photoUrl ||
                              'https://img.icons8.com/external-soft-fill-juicy-fish/60/null/external-graphics-computer-hardware-soft-fill-soft-fill-juicy-fish.png'
                            }
                          />
                        ),
                      )}
                    </div>
                  ) : (
                    <div>
                      <Select
                        options={configuratorStore.accessories.monoblockGraphicCard.map(
                          (monoblockGraphicCard) => ({
                            label: monoblockGraphicCard.fullName,
                            value: monoblockGraphicCard.key,
                            tag: monoblockGraphicCard.accent[0],
                          }),
                        )}
                        value={
                          configuratorStore.selectedData.accessories
                            ?.monoblockGraphicCard?.key || null
                        }
                        clearable
                        onSelect={(_, key) => {
                          handleSelect(
                            'monoblockGraphicCard',
                            configuratorStore.accessories.monoblockGraphicCard.find(
                              (item) => item.key === key,
                            ),
                          )
                        }}
                      />
                    </div>
                  )}
                </CollapseItem>
              </div>
            )}

            {/* Other */}
            {Object.keys(
              omit(configuratorStore.accessories, [
                'cpu',
                'ssd',
                'ram',
                'hdd',
                'graphicCard',
                'monoblockGraphicCard',
              ]),
            )?.map((accessoryKey) => (
              <div key={accessoryKey}>
                <CollapseItem
                  title={
                    configuratorStore.componentTypes?.filter(
                      (component) => component.key === accessoryKey,
                    )[0].name
                  }
                  titleRight={getCurrencyString(
                    configuratorStore.accessories[accessoryKey].find(
                      (accessory) =>
                        accessory.key ===
                        configuratorStore.selectedData.accessories[accessoryKey]
                          .key,
                    )?.price,
                  )}>
                  <div>
                    <Select
                      options={configuratorStore.accessories[accessoryKey].map(
                        (item) => ({
                          label: item.fullName,
                          value: item.key,
                          tag: item.accent[0],
                        }),
                      )}
                      clearable
                      value={
                        configuratorStore.selectedData.accessories[accessoryKey]
                          ?.key || null
                      }
                      onSelect={(_, key) => {
                        handleSelect(
                          accessoryKey,
                          configuratorStore.accessories?.[accessoryKey]?.find(
                            (item) => item.key === key,
                          ),
                        )
                      }}
                    />
                  </div>
                </CollapseItem>
              </div>
            ))}
          </div>
        </ContentBlock>
      </div>
    </div>
  )
}

export default observer(SelectPlatform)
