import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechPhoneSmartRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 11.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM5.875 5.75a.875.875 0 100-1.75.875.875 0 000 1.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.75A2.75 2.75 0 014.75 1h5.5A2.75 2.75 0 0113 3.75v8.5A2.75 2.75 0 0110.25 15h-5.5A2.75 2.75 0 012 12.25v-8.5zM4.75 2.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25h5.5c.69 0 1.25-.56 1.25-1.25v-8.5c0-.69-.56-1.25-1.25-1.25h-5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.75 14a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75zM8 7a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.999 5.251a3.25 3.25 0 013.25-3.25h5.5a3.25 3.25 0 013.25 3.25v9.5a3.25 3.25 0 01-3.25 3.25h-5.5a3.25 3.25 0 01-3.25-3.25v-9.5zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75h5.5a1.75 1.75 0 001.75-1.75v-9.5a1.75 1.75 0 00-1.75-1.75h-5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 18a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 8a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 6A3.75 3.75 0 018 2.25h8A3.75 3.75 0 0119.75 6v12A3.75 3.75 0 0116 21.75H8A3.75 3.75 0 014.25 18V6zM8 3.75A2.25 2.25 0 005.75 6v12A2.25 2.25 0 008 20.25h8A2.25 2.25 0 0018.25 18V6A2.25 2.25 0 0016 3.75H8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 24a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1zM11.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7.5A5.5 5.5 0 0110.5 2h11A5.5 5.5 0 0127 7.5v17a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 015 24.5v-17zM10.5 4A3.5 3.5 0 007 7.5v17a3.5 3.5 0 003.5 3.5h11a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0021.5 4h-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M22.5 48.5A1.5 1.5 0 0124 47h15a1.5 1.5 0 010 3H24a1.5 1.5 0 01-1.5-1.5zM23 19a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16.5C11 10.701 15.701 6 21.5 6h21C48.299 6 53 10.701 53 16.5v31C53 53.299 48.299 58 42.5 58h-21C15.701 58 11 53.299 11 47.5v-31zM21.5 9a7.5 7.5 0 00-7.5 7.5v31a7.5 7.5 0 007.5 7.5h21a7.5 7.5 0 007.5-7.5v-31A7.5 7.5 0 0042.5 9h-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPhoneSmartRegular)
