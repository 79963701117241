import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowParallelVLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1.572 6.244a.5.5 0 11-.707-.707l2.619-2.618a1.438 1.438 0 012.032 0l2.619 2.618a.5.5 0 11-.707.707L5 3.816V13a.5.5 0 01-1 0V3.816L1.572 6.244zM12 12.183V3a.5.5 0 00-1 0v9.183L8.572 9.756a.5.5 0 10-.707.707l2.618 2.618a1.438 1.438 0 002.034 0l2.618-2.618a.5.5 0 10-.707-.707L12 12.183z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M1.752 7.717a.5.5 0 11-.708-.707l3.273-3.273a1.672 1.672 0 012.365 0L9.955 7.01a.5.5 0 11-.707.707L6 4.469v11.78a.5.5 0 11-1 0V4.47L1.752 7.717zM15 15.531V3.75a.5.5 0 00-1 0V15.53l-3.248-3.248a.5.5 0 10-.707.707l3.273 3.273a1.672 1.672 0 002.364 0l3.273-3.273a.5.5 0 10-.707-.707L15 15.531z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowParallelVLight)
