import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import PageWrapper from '@lib/components/Page/PageWrapper'

import MaterialsCard from '../components/MaterialsCard'
import { useMarketingStore } from '../store/MarketingStore'

import styles from './styles.module.scss'

const MaterialsPage = observer(() => {
  const store = useMarketingStore()

  useEffect(() => {
    store.fetchMarketingMaterial()
  }, [])

  return (
    <PageWrapper isLoading={store.loading} title="Маркетинговые материалы">
      <div className={styles.cardList}>
        {store.marketingMaterial.map((data) => (
          <MaterialsCard key={data.id} data={data} />
        ))}
      </div>
    </PageWrapper>
  )
})

export default MaterialsPage
