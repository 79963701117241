import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeTriangleAUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.922 12a1 1 0 01-.838-1.545l4.03-6.194a1 1 0 011.671-.008l4.115 6.194A1 1 0 0112.068 12H3.922zm4.03-7.193L3.922 11h8.146L7.952 4.807z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12 14a1.1 1.1 0 01-.914-1.71L9.085 4.97a1.1 1.1 0 011.83 0l4.879 7.319a1.1 1.1 0 01-.915 1.71H5.12zm-.082-1.156A.1.1 0 005.12 13h9.758a.1.1 0 00.083-.156l-4.879-7.318a.1.1 0 00-.166 0l-4.88 7.318z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleAUpLight)
