import { FC, memo, useCallback, useMemo } from 'react'

import { IInputChangeEvent, IInputProps, Input } from '@ui/Input'
export interface PriceInputProps
  extends Omit<IInputProps, 'value' | 'type' | 'onChange'> {
  value?: number
  onChange?: (evt: IInputChangeEvent<number | undefined>) => void
}

const SPACES_REGEX = /[^+\d]/g
const PriceInput: FC<PriceInputProps> = ({ value, onChange, ...rest }) => {
  const formattedValue = useMemo(() => {
    if (value !== undefined) {
      return value.toLocaleString('ru-Ru', {
        maximumSignificantDigits: 15,
      })
    }
    return ''
  }, [value])

  const onChangeValue = useCallback(
    (evt: IInputChangeEvent) => {
      const clearedValue = evt.target.value.replace(SPACES_REGEX, '').trim()
      const resultValue = clearedValue !== '' ? Number(clearedValue) : undefined

      onChange({ target: { value: resultValue, name: evt.target.name } })
    },
    [onChange],
  )

  return (
    <Input
      rightIcon="₽"
      value={formattedValue}
      placeholder="0"
      onChange={onChangeValue}
      {...rest}
    />
  )
}

export default memo(PriceInput)
