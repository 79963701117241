import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechStorageUsbLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.774 7.117l.176.176a1 1 0 010 1.414L8 13.657A4 4 0 112.343 8l4.95-4.95a1 1 0 011.414 0l.175.175 2.122-2.12a1 1 0 011.414 0l2.478 2.477a1 1 0 010 1.414l-2.122 2.121zM9.59 3.933l2.478 2.477 2.121-2.12-2.477-2.479-2.122 2.122zM3.05 12.95a3 3 0 010-4.243L8 3.757 12.243 8l-4.95 4.95a3 3 0 01-4.243 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.565 9.201l.29.29a1.25 1.25 0 010 1.768l-5.457 5.459a5.031 5.031 0 01-7.116-7.116L8.74 4.144a1.25 1.25 0 011.768 0l.291.291 2.585-2.585a1.25 1.25 0 011.768 0l2.998 2.998a1.25 1.25 0 010 1.768L15.565 9.2zm-5.764-4.35a.25.25 0 00-.353 0L3.99 10.31a4.031 4.031 0 005.7 5.701l5.459-5.458a.25.25 0 000-.353L9.8 4.85zm1.705.291l2.586-2.585a.25.25 0 01.353 0l2.998 2.998a.25.25 0 010 .354l-2.585 2.585-3.352-3.352z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechStorageUsbLight)
