import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import SuccessCard from '@lib/components/SuccessCard'
import { EMainRoutes } from '@lib/navigation'

import { useRegisterTransactionFormStore } from '@modules/sales/store/RegisterForm/RegisterTransactionFormStore'

const SuccessTransactionCard = () => {
  const navigate = useNavigate()
  const transactionFormStore = useRegisterTransactionFormStore()

  const handleClick = useCallback(() => {
    transactionFormStore.clear()
    navigate(EMainRoutes.SalesTransactions)
  }, [])

  return (
    <SuccessCard
      title="Данные успешно отправлены!"
      description="Мы скоро проверим вашу заявку на регистрацию сделки. Вы можете отслеживать историю сделок."
      handleClick={handleClick}
      titleButton="Перейти в историю сделок"
      fullSize={false}
    />
  )
}

export default memo(SuccessTransactionCard)
