import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconQuestionCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 11a1 1 0 11-2 0 1 1 0 012 0zM6.76 6.348c0-.48.47-1.018 1.24-1.018s1.24.537 1.24 1.018c0 .359-.242.73-.695.913-.525.214-1.045.606-1.23 1.258a.75.75 0 101.444.409c.013-.046.07-.163.35-.277.92-.374 1.63-1.234 1.63-2.303C10.74 4.877 9.428 3.83 8 3.83c-1.427 0-2.74 1.047-2.74 2.518a.75.75 0 001.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 14a1 1 0 11-2 0 1 1 0 012 0zM8.375 8.078c0-.642.63-1.328 1.625-1.328s1.625.686 1.625 1.328c0 .324-.09.504-.2.637-.13.16-.325.293-.617.466l-.113.065c-.247.142-.576.332-.834.571-.346.321-.61.765-.61 1.378a.75.75 0 101.5 0c0-.12.03-.186.13-.278.124-.115.284-.208.539-.357l.148-.086c.3-.177.7-.424 1.015-.805.336-.407.542-.927.542-1.59 0-1.654-1.496-2.829-3.125-2.829S6.875 6.425 6.875 8.078a.75.75 0 101.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.001 10A7.999 7.999 0 1118 10 7.999 7.999 0 012 10zm8-6.499A6.499 6.499 0 1010 16.5 6.499 6.499 0 0010 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13 17a1 1 0 11-2 0 1 1 0 012 0zM9.75 9.887c0-.945.91-1.875 2.25-1.875 1.34 0 2.25.93 2.25 1.875 0 .453-.129.726-.297.93-.19.23-.462.414-.833.632-.046.028-.096.056-.148.086-.312.18-.706.408-1.013.693-.405.375-.709.886-.709 1.596a.75.75 0 001.5 0c0-.217.07-.35.23-.497.172-.16.396-.29.717-.477l.183-.107c.38-.223.858-.52 1.23-.972.395-.479.64-1.09.64-1.884 0-1.955-1.776-3.375-3.75-3.375s-3.75 1.42-3.75 3.375a.75.75 0 101.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 22.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM13 13.5c0-1.26 1.213-2.5 3-2.5s3 1.24 3 2.5c0 .605-.172.968-.396 1.24-.253.307-.616.553-1.11.844l-.198.114c-.417.24-.941.544-1.351.924-.54.5-.945 1.182-.945 2.128a1 1 0 102 0c0-.29.094-.466.305-.662.231-.215.53-.388.958-.637l.244-.143c.505-.297 1.143-.693 1.64-1.296.526-.638.854-1.453.854-2.512 0-2.607-2.369-4.5-5-4.5C13.367 9 11 10.893 11 13.5a1 1 0 102 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16zM16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 45a2 2 0 11-4 0 2 2 0 014 0zM25.5 27c0-2.855 2.715-5.5 6.5-5.5s6.5 2.645 6.5 5.5c0 1.323-.383 2.163-.907 2.798-.567.689-1.36 1.217-2.353 1.8-.13.077-.266.155-.407.237-.837.484-1.83 1.057-2.603 1.775-.996.925-1.73 2.162-1.73 3.89a1.5 1.5 0 003 0c0-.743.267-1.223.77-1.691.506-.47 1.158-.848 2.012-1.345l.479-.28c1.008-.592 2.214-1.346 3.147-2.478.977-1.184 1.593-2.702 1.593-4.706 0-4.876-4.448-8.5-9.5-8.5-5.053 0-9.502 3.624-9.502 8.5a1.5 1.5 0 103 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 32C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26S6 46.36 6 32zM32 9C19.297 9 9 19.297 9 32s10.297 23 23 23 23-10.297 23-23S44.703 9 32 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconQuestionCircleRegular)
