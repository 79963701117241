import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCommentRectRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.75A2.75 2.75 0 013.75 2h8.5A2.75 2.75 0 0115 4.75v5.5A2.75 2.75 0 0112.25 13H8.7l-3.48 2.456a1.25 1.25 0 01-1.97-1.021v-1.48A2.75 2.75 0 011 10.25v-5.5zM3.75 3.5c-.69 0-1.25.56-1.25 1.25v5.5c0 .69.56 1.25 1.25 1.25H4a.75.75 0 01.75.75v1.703l3.28-2.316a.75.75 0 01.432-.137h3.788c.69 0 1.25-.56 1.25-1.25v-5.5c0-.69-.56-1.25-1.25-1.25h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.999 5.25A3.25 3.25 0 015.249 2h9.502a3.25 3.25 0 013.25 3.25v6.5a3.25 3.25 0 01-3.25 3.25H11.08l-3.812 2.964c-.82.639-2.017.054-2.017-.986V15h-.001a3.25 3.25 0 01-3.25-3.25v-6.5zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v6.5c0 .966.783 1.75 1.75 1.75H6a.75.75 0 01.75.75v2.217l3.611-2.809a.75.75 0 01.46-.158h3.93a1.75 1.75 0 001.75-1.75v-6.5a1.75 1.75 0 00-1.75-1.75H5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 7A3.75 3.75 0 016 3.25h12A3.75 3.75 0 0121.75 7v8A3.75 3.75 0 0118 18.75h-5.569l-4.799 3.49c-.991.721-2.382.013-2.382-1.213v-2.352a3.751 3.751 0 01-3-3.675V7zM6 4.75A2.25 2.25 0 003.75 7v8A2.25 2.25 0 006 17.25a.75.75 0 01.75.75v3.027l4.996-3.634a.75.75 0 01.441-.143H18A2.25 2.25 0 0020.25 15V7A2.25 2.25 0 0018 4.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 8.5A5.5 5.5 0 017.5 3h17A5.5 5.5 0 0130 8.5v11a5.5 5.5 0 01-5.5 5.5h-7.78l-6.557 4.684C8.838 30.63 7 29.684 7 28.057v-3.08A5.5 5.5 0 012 19.5v-11zM7.5 5A3.5 3.5 0 004 8.5v11A3.5 3.5 0 007.5 23H8a1 1 0 011 1v4.057l6.819-4.87A1 1 0 0116.4 23h8.1a3.5 3.5 0 003.5-3.5v-11A3.5 3.5 0 0024.5 5h-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17.5C6 11.701 10.701 7 16.5 7h31C53.299 7 58 11.701 58 17.5v21C58 44.299 53.299 49 47.5 49H33.38l-11.846 8.462C19.218 59.116 16 57.46 16 54.614v-5.626c-5.567-.26-10-4.857-10-10.488v-21zM16.5 10A7.5 7.5 0 009 17.5v21a7.5 7.5 0 007.5 7.5h1a1.5 1.5 0 011.5 1.5v7.114a.5.5 0 00.79.407l12.238-8.742A1.5 1.5 0 0132.9 46h14.6a7.5 7.5 0 007.5-7.5v-21a7.5 7.5 0 00-7.5-7.5h-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRectRegular)
