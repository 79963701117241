import { makeAutoObservable, runInAction } from 'mobx'
import { container, singleton } from 'tsyringe'

import { IFile } from '@lib/components/FileUploader/types'
import { EStatusProcess, FormConstructor } from '@lib/form'

import { EProblem, IContactUs } from '../models/Support'
import { IArticle, SupportRest } from '../services/SupportRest'

const INITIAL_REQUEST: IContactUs = {
  type: EProblem.PROBLEM,
  theme: '',
  description: '',
  file: undefined,
}

@singleton()
export class SupportStore {
  private supportRest = container.resolve(SupportRest)

  warranty?: string = undefined
  politics?: string = undefined
  partners?: IArticle = undefined

  contactUsRequest: IContactUs = INITIAL_REQUEST

  status = EStatusProcess.IDLE

  form = new FormConstructor({
    theme: {
      label: 'Тема сообщения',
      rule: 'required',
    },
    description: {
      label: 'Описание проблемы',
      rule: 'required',
    },
  })

  constructor() {
    makeAutoObservable<SupportStore, 'supportRest'>(this, {
      supportRest: false,
    })
  }

  async fetchPrivacyPolicy() {
    if (!this.politics) {
      const data = await this.supportRest.getPrivacyPolicyText()
      runInAction(() => {
        this.politics = data.markup
      })
    }
  }

  get isDisabledSend() {
    return !(
      this.form.fields.theme.value &&
      this.form.fields.description.value &&
      this.contactUsRequest.type
    )
  }

  async createContactUsRequest() {
    await this.form.handleSubmit((fields) => {
      return this.supportRest.createContactUsRequest({
        theme: fields.theme.value,
        description: fields.description.value,
        type: this.contactUsRequest.type,
        file: this.contactUsRequest.file,
      })
    })

    runInAction(() => {
      this.status = this.form.meta.fetchStatus
    })
  }

  setFile = (file?: IFile) => {
    if (file) {
      this.contactUsRequest.file = file.fileId
    } else {
      this.contactUsRequest.file = null
    }
  }

  clear() {
    for (const key in INITIAL_REQUEST) {
      this.contactUsRequest[key] = INITIAL_REQUEST[key]
    }

    this.form.clear()
    this.status = EStatusProcess.IDLE
  }

  async fetchWarranty() {
    if (!this.warranty) {
      const data = await this.supportRest.getWarrantyText()
      runInAction(() => {
        this.warranty = data.markup
      })
    }
  }

  async fetchPartnersProgram() {
    if (!this.partners) {
      const data = await this.supportRest.getPartnersText()
      runInAction(() => {
        this.partners = data
      })
    }
  }

  async downloadCertificate() {
    await this.supportRest.downloadCertificate()
  }
}

export const useSupportStore = () => container.resolve(SupportStore)
