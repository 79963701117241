import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLocationGlobeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 .995a7.004 7.004 0 100 14.01A7.004 7.004 0 008 .994zM2.577 7.05a5.54 5.54 0 000 1.9h2.371a14.082 14.082 0 010-1.9h-2.37zm.313-1.1h2.179c.134-.868.351-1.655.635-2.312.128-.296.272-.572.433-.82A5.521 5.521 0 002.89 5.95zm3.161 1.1a12.948 12.948 0 000 1.9h3.9a12.946 12.946 0 000-1.9h-3.9zm3.767-1.1H6.183a8.275 8.275 0 01.53-1.875c.212-.489.45-.85.686-1.08.233-.23.436-.304.602-.304.165 0 .368.075.602.303.236.231.473.592.685 1.081.225.52.408 1.157.53 1.875zm1.235 1.1a14.09 14.09 0 010 1.9h2.37a5.538 5.538 0 000-1.9h-2.37zm2.057-1.1h-2.178c-.133-.868-.35-1.655-.635-2.312a5.551 5.551 0 00-.433-.819A5.521 5.521 0 0113.11 5.95zm-6.973 7.231A5.521 5.521 0 012.89 10.05h2.179c.134.868.351 1.655.635 2.312.128.296.272.572.433.82zm.576-1.256a8.276 8.276 0 01-.53-1.875h3.635a8.275 8.275 0 01-.53 1.875c-.212.489-.45.85-.685 1.08-.234.23-.437.304-.602.304-.166 0-.369-.075-.602-.303-.236-.231-.474-.592-.686-1.081zm3.584.437c.284-.657.502-1.444.635-2.312h2.178a5.522 5.522 0 01-3.246 3.13 5.55 5.55 0 00.433-.818z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zM7.585 3.965A6.522 6.522 0 004.11 7.25H6.48c.16-.93.402-1.777.71-2.495.12-.279.252-.544.396-.79zM3.5 10c0-.428.042-.845.12-1.25h2.675a17.024 17.024 0 000 2.5H3.62a6.532 6.532 0 01-.12-1.25zm.61 2.75a6.522 6.522 0 003.474 3.285 7.061 7.061 0 01-.396-.79c-.308-.718-.55-1.565-.71-2.495H4.11zm3.893 0a9.49 9.49 0 00.564 1.904c.248.578.523.998.791 1.262.265.261.48.334.641.334.16 0 .376-.073.64-.334.269-.264.544-.684.792-1.262a9.49 9.49 0 00.564-1.904H8.004zm5.517 0c-.16.93-.403 1.777-.71 2.495-.12.279-.252.544-.396.79a6.522 6.522 0 003.475-3.285H13.52zm2.857-1.5h-2.674a16.996 16.996 0 000-2.5h2.674a6.534 6.534 0 010 2.5zm-4.178 0H7.8a15.467 15.467 0 010-2.5h4.4a15.476 15.476 0 010 2.5zm1.321-4h2.369a6.522 6.522 0 00-3.475-3.285c.145.246.276.511.396.79.307.718.55 1.565.71 2.495zM8.568 5.346a9.49 9.49 0 00-.564 1.904h3.992a9.49 9.49 0 00-.564-1.904c-.248-.578-.523-.998-.791-1.262-.265-.261-.48-.334-.641-.334-.16 0-.376.073-.64.334-.269.264-.544.684-.792 1.262z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.76 8.882a.745.745 0 01.09-.255 9.754 9.754 0 0118.3 0 .746.746 0 01.09.255c.331.979.51 2.028.51 3.118a9.74 9.74 0 01-.51 3.118.747.747 0 01-.09.255 9.754 9.754 0 01-18.3 0 .745.745 0 01-.09-.255A9.739 9.739 0 012.25 12c0-1.09.179-2.14.51-3.118zm6.47-4.656A8.277 8.277 0 004.65 8.25h3.202c.196-1.079.475-2.06.822-2.9.167-.403.352-.78.556-1.124zM4.65 15.75a8.277 8.277 0 004.58 4.023 8.754 8.754 0 01-.556-1.122c-.347-.84-.626-1.822-.822-2.901H4.65zm2.99-1.5H4.06A8.256 8.256 0 013.75 12c0-.78.108-1.535.31-2.25h3.58A22.085 22.085 0 007.527 12c0 .773.039 1.526.113 2.25zm1.738 1.5c.175.876.407 1.662.682 2.327.315.76.67 1.326 1.027 1.691.354.362.663.482.914.482.25 0 .559-.12.913-.482.357-.364.712-.93 1.027-1.691.275-.665.508-1.45.682-2.327H9.378zm5.475-1.5H9.148A20.493 20.493 0 019.027 12c0-.782.042-1.536.121-2.25h5.705c.079.714.122 1.468.122 2.25s-.043 1.536-.122 2.25zm1.297 1.5c-.196 1.079-.476 2.061-.823 2.9-.166.403-.351.78-.556 1.123a8.277 8.277 0 004.58-4.023H16.15zm3.79-1.5H16.36c.075-.724.114-1.477.114-2.25 0-.773-.04-1.526-.114-2.25h3.578c.203.715.311 1.47.311 2.25a8.26 8.26 0 01-.31 2.25zM14.77 4.227a8.277 8.277 0 014.58 4.023H16.15c-.196-1.079-.476-2.06-.823-2.9a8.769 8.769 0 00-.556-1.123zm-.83 1.696c.275.665.507 1.45.682 2.327H9.378c.175-.876.407-1.662.682-2.327.315-.76.67-1.326 1.027-1.69.354-.363.663-.483.914-.483.25 0 .559.12.913.482.357.365.712.93 1.027 1.691z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zm-3.693 2.635A11.036 11.036 0 006.199 11h4.27c.261-1.439.634-2.748 1.097-3.867.222-.537.469-1.04.741-1.498zM5 16c0-1.04.144-2.046.414-3h4.773c-.1.965-.151 1.97-.151 3s.052 2.035.15 3H5.415C5.144 18.046 5 17.04 5 16zm1.2 5a11.036 11.036 0 006.107 5.365c-.272-.458-.52-.961-.741-1.498-.463-1.119-.836-2.428-1.097-3.867h-4.27zm6.305 0c.232 1.168.542 2.216.909 3.103.42 1.013.894 1.768 1.369 2.254.472.483.883.643 1.218.643.335 0 .746-.16 1.218-.643.475-.486.95-1.24 1.369-2.254.367-.887.677-1.935.91-3.103h-6.993zm9.028 0c-.261 1.439-.634 2.748-1.097 3.867a11.68 11.68 0 01-.74 1.497A11.037 11.037 0 0025.8 21h-4.268zm5.053-2h-4.77c.098-.965.15-1.97.15-3s-.052-2.035-.15-3h4.77c.27.954.414 1.96.414 3s-.144 2.046-.414 3zm-6.782 0h-7.606a27.326 27.326 0 01-.162-3c0-1.043.056-2.048.162-3h7.606c.105.952.162 1.957.162 3s-.057 2.048-.162 3zm1.73-8H25.8a11.036 11.036 0 00-6.106-5.364c.272.458.52.96.741 1.497.463 1.12.836 2.428 1.097 3.867zm-8.12-3.103c-.367.887-.677 1.935-.91 3.103h6.993c-.232-1.168-.542-2.216-.91-3.103-.419-1.013-.893-1.768-1.368-2.254C16.747 5.16 16.336 5 16 5c-.335 0-.746.16-1.218.643-.475.486-.95 1.24-1.37 2.254z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zm-6.253 3.86c-6.009 1.694-11.02 5.768-13.951 11.14h9.615c.514-2.553 1.203-4.881 2.037-6.896.663-1.601 1.43-3.04 2.3-4.244zM9 32c0-2.813.505-5.509 1.43-8h10.473a58.437 58.437 0 00-.538 8c0 2.786.188 5.476.538 8H10.43A22.952 22.952 0 019 32zm2.796 11c2.93 5.372 7.942 9.446 13.95 11.14-.868-1.205-1.636-2.642-2.298-4.243-.834-2.015-1.524-4.344-2.038-6.897h-9.614zm12.679 0c.463 2.143 1.054 4.08 1.745 5.75.873 2.109 1.873 3.717 2.908 4.775 1.03 1.052 2 1.474 2.874 1.474.873 0 1.844-.422 2.874-1.474 1.034-1.058 2.035-2.666 2.908-4.775.69-1.67 1.282-3.607 1.745-5.75H24.475zm18.118 0c-.514 2.553-1.203 4.882-2.037 6.897-.662 1.6-1.43 3.037-2.297 4.241C44.265 52.444 49.274 48.37 52.204 43h-9.61zm10.977-3H43.102c.35-2.524.538-5.214.538-8 0-2.785-.188-5.476-.538-8h10.47A22.952 22.952 0 0155 32c0 2.813-.505 5.508-1.43 8zm-13.5 0H23.934a55.131 55.131 0 01-.568-8c0-2.819.202-5.512.568-8h16.138a55.13 55.13 0 01.568 8c0 2.82-.202 5.512-.568 8zm2.523-19h9.611c-2.93-5.37-7.94-9.444-13.946-11.138.868 1.204 1.636 2.641 2.298 4.242.834 2.015 1.523 4.343 2.037 6.896zM26.22 15.251c-.69 1.669-1.282 3.606-1.745 5.749H39.53c-.463-2.143-1.055-4.08-1.745-5.749-.873-2.108-1.874-3.717-2.908-4.774-1.03-1.053-2-1.475-2.874-1.475-.873 0-1.844.422-2.874 1.475-1.035 1.057-2.035 2.665-2.908 4.774z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLocationGlobeRegular)
