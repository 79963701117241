import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowADownRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.5 10.44L4.03 2.97a.75.75 0 00-1.06 1.06l7.47 7.47H5.25a.75.75 0 000 1.5h6A1.75 1.75 0 0013 11.25v-6a.75.75 0 00-1.5 0v5.19z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.19 15.25H6.5a.75.75 0 000 1.5h8a2.25 2.25 0 002.25-2.25v-8a.75.75 0 00-1.5 0v7.69L4.53 3.47a.75.75 0 00-1.06 1.06l10.72 10.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.125 17.888L5.119 3.88A.875.875 0 103.88 5.12l14.007 14.006H7.5a.875.875 0 000 1.75H18A2.875 2.875 0 0020.875 18V7.5a.875.875 0 00-1.75 0v10.388z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M23.584 24.998A1.54 1.54 0 0123.5 25h-13a1 1 0 100 2h13a3.5 3.5 0 003.5-3.5v-13a1 1 0 10-2 0v13c0 .028 0 .056-.002.084L7.208 5.793a1 1 0 10-1.415 1.414l17.79 17.79z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M48.44 50.561l-36-36a1.5 1.5 0 012.12-2.122L50.562 48.44A4.481 4.481 0 0051 46.5v-26a1.5 1.5 0 113 0v26a7.5 7.5 0 01-7.5 7.5h-26a1.5 1.5 0 010-3h26c.695 0 1.353-.157 1.94-.439z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowADownRightRegular)
