import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import { IRegularIconProps } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { getImageUrl } from '@lib/helpers'
import { EMainRoutes } from '@lib/navigation'
import { cx } from '@lib/styles'

import styles from './styles.module.scss'

export interface SiderMenuItem {
  Icon: React.FC<IRegularIconProps>
  title: string
  link: string
}

interface SiderProps {
  navItems: SiderMenuItem[]
  footerItems: SiderMenuItem[]
}

const Sider = ({ navItems, footerItems }: SiderProps) => {
  return (
    <aside className={styles.sider}>
      <div className={styles.logo}>
        <Link to={EMainRoutes.Main}>
          <img alt="Логотип" src={getImageUrl('logo.svg')} width={152} />
        </Link>
      </div>

      <div className={styles['flex-container']}>
        <div>
          {navItems.map(({ Icon, title, link }) => (
            <NavLink
              key={title}
              className={({ isActive }) =>
                cx(styles['menu-item'], {
                  [styles.active]: isActive,
                })
              }
              to={link}
              end={link === '/'}>
              <Icon size={24} />
              <Typography color={COLORS.GRAY.SAURON} size={16}>
                {title}
              </Typography>
            </NavLink>
          ))}
        </div>
        <div>
          {footerItems.map(({ link, title, Icon }) => (
            <NavLink
              key={title}
              to={link}
              className={({ isActive }) =>
                cx(styles['menu-item'], {
                  [styles.active]: isActive,
                })
              }>
              <Icon size={24} />
              <Typography color={COLORS.GRAY.SAURON} size={16}>
                {title}
              </Typography>
            </NavLink>
          ))}
        </div>
      </div>
    </aside>
  )
}

export default Sider
