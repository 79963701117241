import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeADownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.587 14.396a2 2 0 002.828 0l5.689-5.689c.63-.63.183-1.707-.707-1.707H12V2.75A1.75 1.75 0 0010.25 1h-4.5A1.75 1.75 0 004 2.75V7H1.604C.713 7 .266 8.077.897 8.707l5.69 5.69zm1.767-1.06a.5.5 0 01-.707 0L2.811 8.5H4.75a.75.75 0 00.75-.75v-5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v5c0 .414.336.75.75.75h1.94l-4.836 4.836z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.411 17.772a2.25 2.25 0 003.18 0l6.977-6.967c.851-.85.249-2.305-.954-2.305H14.75V4a2 2 0 00-2-2h-5.5a2 2 0 00-2 2v4.5H2.388c-1.203 0-1.805 1.455-.954 2.305l6.977 6.967zm2.12-1.061a.75.75 0 01-1.06 0L2.751 10H6a.75.75 0 00.75-.75V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v5.25c0 .414.335.75.75.75h3.251l-6.72 6.71z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.144 21.205a2.625 2.625 0 003.712 0l8.73-8.73c.913-.913.266-2.475-1.026-2.475H18V4.25A2.25 2.25 0 0015.75 2h-7.5A2.25 2.25 0 006 4.25V10H2.44C1.148 10 .5 11.562 1.414 12.476l8.73 8.729zm2.651-1.06c-.44.439-1.151.439-1.59 0L2.56 11.5h4.19a.75.75 0 00.75-.75v-6.5a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v6.5c0 .415.336.75.75.75h4.19l-8.645 8.644z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.527 28.44a3.5 3.5 0 004.95 0l11.025-11.027c1.26-1.26.368-3.414-1.414-3.414H23V6a3 3 0 00-3-3h-8a3 3 0 00-3 3v7.999H3.916c-1.782 0-2.675 2.154-1.415 3.414L13.527 28.44zm3.536-1.415a1.5 1.5 0 01-2.121 0L3.915 15.999H10a1 1 0 001-1V6a1 1 0 011-1h8a1 1 0 011 1v8.999a1 1 0 001 1h6.088L17.063 27.025z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.408 56.028a6.5 6.5 0 009.193 0l22.05-22.053c2.204-2.205.643-5.974-2.475-5.974H46V12.5A5.5 5.5 0 0040.5 7h-17a5.5 5.5 0 00-5.5 5.5V28H7.831c-3.118 0-4.68 3.77-2.475 5.975l22.052 22.053zm7.071-2.122a3.5 3.5 0 01-4.95 0L7.478 31.854a.5.5 0 01.353-.853h11.67a1.5 1.5 0 001.5-1.5V12.5a2.5 2.5 0 012.5-2.5h17a2.5 2.5 0 012.5 2.5v17a1.5 1.5 0 001.5 1.5h11.675a.5.5 0 01.353.854L34.48 53.906z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownRegular)
