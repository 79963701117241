import React, { PropsWithChildren } from 'react'

import { cx } from '@lib/styles'

import styles from './FormWindowLayout.module.scss'

/**
 * @prop children Чилдрен (Форма).
 */
interface IProps extends PropsWithChildren {
  withoutPadding?: boolean
}
/**
 * Компонент окна с формой.
 */
export const FormWindowLayout: React.FC<IProps> = ({
  children,
  withoutPadding,
}) => (
  <div className={styles.formWindowLayout}>
    <div
      className={cx(
        withoutPadding && styles.withoutPadding,
        styles.formWrapper,
      )}>
      {children}
    </div>
  </div>
)
