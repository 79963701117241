import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBooksLibraryLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.075 2.822A1.25 1.25 0 018.25 2h1.5c.281 0 .541.093.75.25.209-.157.469-.25.75-.25h1.5c.69 0 1.25.56 1.25 1.25v9.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.281 0-.541-.093-.75-.25-.209.157-.469.25-.75.25h-1.5C7.56 14 7 13.44 7 12.75V6.261l-3.212 6.89a1.25 1.25 0 01-1.662.604l-1.36-.634a1.25 1.25 0 01-.604-1.661l4.015-8.61a1.25 1.25 0 011.661-.605l1.237.577zM8.25 3a.25.25 0 00-.25.25v9.5c0 .138.112.25.25.25h1.5a.25.25 0 00.25-.25v-9.5A.25.25 0 009.75 3h-1.5zm3 0a.25.25 0 00-.25.25v9.5c0 .138.112.25.25.25h1.5a.25.25 0 00.25-.25v-9.5a.25.25 0 00-.25-.25h-1.5zm-6.166.272a.25.25 0 01.332-.12l1.36.633a.25.25 0 01.12.333l-4.015 8.61a.25.25 0 01-.332.12l-1.36-.633a.25.25 0 01-.12-.333l4.015-8.61z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.027 4.217A1.5 1.5 0 0111.5 3h1c.384 0 .735.144 1 .382a1.495 1.495 0 011-.382h1A1.5 1.5 0 0117 4.5v11a1.5 1.5 0 01-1.5 1.5h-1c-.384 0-.735-.144-1-.382a1.494 1.494 0 01-1 .382h-1a1.5 1.5 0 01-1.5-1.5V6.685l-4.3 9.22a1.5 1.5 0 01-1.993.725l-.906-.422a1.5 1.5 0 01-.726-1.994l4.65-9.97a1.5 1.5 0 011.993-.725l.906.423c.152.071.287.165.403.275zM14 15.5v-11a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zM12.5 4a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h1zm-4.87.668a.5.5 0 01.665-.242l.906.422a.5.5 0 01.242.665l-4.649 9.97a.5.5 0 01-.664.241l-.906-.423a.5.5 0 01-.242-.664l4.649-9.97z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBooksLibraryLight)
