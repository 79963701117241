import { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import { Typography } from '@ui/Typography'

import { CardItem, ContentBlock } from '@modules/configurator/components'
import { ETubs } from '@modules/configurator/enums'
import { IProductSeries } from '@modules/configurator/models/Configurator'
import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

import styles from './SelectSeries.module.scss'

const SelectSeries = () => {
  const configuratorStore = useConfiguratorStore()

  const handleClickBack = useCallback(() => {
    configuratorStore.selectTab(ETubs.PRODUCT_TYPE)
  }, [])

  const handleClickNext = useCallback(() => {
    configuratorStore.selectTab(ETubs.PLATFORM)
  }, [])

  const handleSelect = useCallback((series: IProductSeries) => {
    configuratorStore.selectSeries(series)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.cardgroup}>
        {configuratorStore.series?.map((productSeries: IProductSeries) => (
          <CardItem
            key={productSeries.key}
            title={productSeries.name}
            description={productSeries.shortDescription}
            photoURL={productSeries.photoUrl}
            onClick={() => handleSelect(productSeries)}
            selected={
              productSeries.id ===
              configuratorStore.selectedData.productSeries.id
            }
          />
        ))}
      </div>

      <ContentBlock onClickBack={handleClickBack} onClickNext={handleClickNext}>
        <div className={styles.image}>
          <img src={configuratorStore.selectedSeries?.photoUrl} />
        </div>
        <div className={styles.text}>
          <Typography size={22}>
            {configuratorStore.selectedSeries?.name}
          </Typography>
          <Typography size={18}>
            {configuratorStore.selectedSeries?.description}
          </Typography>
        </div>
      </ContentBlock>
    </div>
  )
}

export default observer(SelectSeries)
