import { memo } from 'react'

import { cx } from '@lib/styles'

import styles from './styles.scss'

interface IDivider {
  className?: string
}

const Divider = ({ className }: IDivider) => (
  <div className={cx(styles.divider, className)} />
)

export default memo(Divider)
