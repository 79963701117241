import { memo, useCallback, useEffect, useState } from 'react'

import { IconXRegular } from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
import { IToastProps } from './types'

const Toast = ({
  title,
  autoDeleteTime,
  buttonDelete,
  className,
  onVisible,
  onClick,
  onCloseToast,
}: IToastProps) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(onVisible)
  }, [onVisible])

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDeleteTime && onVisible) {
        onClose()
      }
    }, autoDeleteTime)

    return () => {
      clearInterval(interval)
    }
  }, [autoDeleteTime, onVisible])

  const onClose = useCallback(() => {
    onCloseToast(false)
  }, [])

  return (
    <div className={cx(className, styles.container)}>
      {visible && (
        <div className={styles.toast}>
          <Typography color={COLORS.GRAY.GANDALF}> {title}</Typography>
          {onClick && <div onClick={onClick}>Отменить</div>}
          {buttonDelete && (
            <div onClick={() => onClose()}>
              <IconXRegular />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(Toast)
