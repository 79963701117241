import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocsPlusRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 6.038a.6.6 0 01.6.6v1.273h1.273a.6.6 0 010 1.2H8.1v1.273a.6.6 0 11-1.2 0V9.11H5.627a.6.6 0 010-1.2H6.9V6.638a.6.6 0 01.6-.6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.752 0a2.75 2.75 0 00-2.75 2.75v7.5A2.75 2.75 0 004.752 13h5.496a2.75 2.75 0 002.75-2.75V4.651c0-.464-.184-.91-.512-1.237L9.585.513A1.75 1.75 0 008.347 0H4.752zm-1.25 2.75c0-.69.56-1.25 1.25-1.25H7.25v3c0 .69.56 1.25 1.25 1.25h2.998v4.5c0 .69-.56 1.25-1.25 1.25H4.752c-.69 0-1.25-.56-1.25-1.25v-7.5zm7.699 1.5L8.75 1.8v2.45h2.45z"
          fill="currentColor"
        />
        <path
          d="M15.5 5.5a.75.75 0 00-1.5 0v5.247a3.25 3.25 0 01-3.25 3.25H6.5a.75.75 0 000 1.5h4.25a4.75 4.75 0 004.75-4.75V5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 8.903a.75.75 0 01.75.75v1.373h1.373a.75.75 0 010 1.5H9.75V13.9a.75.75 0 01-1.5 0v-1.373H6.877a.75.75 0 010-1.5H8.25V9.653a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.249 1.001a3.25 3.25 0 00-3.25 3.25v9.5a3.25 3.25 0 003.25 3.25h5.5a3.25 3.25 0 003.25-3.25v-7.12c0-.464-.185-.909-.513-1.237l-3.88-3.88a1.75 1.75 0 00-1.238-.513h-3.12zm-1.75 3.25c0-.966.783-1.75 1.75-1.75H8.25V5.5a2.25 2.25 0 002.25 2.25h2.998v6.002a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75v-9.5zM13.22 6.25L9.75 2.78V5.5c0 .414.336.75.75.75h2.72z"
          fill="currentColor"
        />
        <path
          d="M17.563 7.5a.75.75 0 00-1.5 0v6.781a3.75 3.75 0 01-3.75 3.75H7.5a.75.75 0 000 1.5h4.813c2.899 0 5.25-2.35 5.25-5.25V7.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 10.25a.75.75 0 01.75.75v2.25H14a.75.75 0 010 1.5h-2.25V17a.75.75 0 01-1.5 0v-2.25H8a.75.75 0 010-1.5h2.25V11a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.75 17A3.75 3.75 0 0115 20.75H7A3.75 3.75 0 013.25 17V5A3.75 3.75 0 017 1.25h5.05c.729 0 1.428.29 1.944.805l3.95 3.951a2.75 2.75 0 01.806 1.945V17zm-14-12A2.25 2.25 0 017 2.75h4.25V6A2.75 2.75 0 0014 8.75h3.25V17A2.25 2.25 0 0115 19.25H7A2.25 2.25 0 014.75 17V5zm12.285 2.25H14c-.69 0-1.25-.56-1.25-1.25V2.965c.065.044.127.095.183.151l3.95 3.95c.057.057.108.119.152.184z"
          fill="currentColor"
        />
        <path
          d="M21.75 9a.75.75 0 00-1.5 0v8.5a4.75 4.75 0 01-4.75 4.75H9a.75.75 0 000 1.5h6.5a6.25 6.25 0 006.25-6.25V9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15 13a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3h-3a1 1 0 110-2h3v-3a1 1 0 011-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 22.5a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 014 22.5v-17A5.5 5.5 0 019.5 0h6.726a4 4 0 012.829 1.172l5.773 5.773A4 4 0 0126 9.774V22.5zM6 5.5A3.5 3.5 0 019.5 2H15v5.5a3.5 3.5 0 003.5 3.5H24v11.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 016 22.5v-17zM23.844 9H18.5A1.5 1.5 0 0117 7.5V2.156a2 2 0 01.64.43l5.774 5.774a2 2 0 01.43.64z"
          fill="currentColor"
        />
        <path
          d="M30 13a1 1 0 10-2 0v10a7 7 0 01-7 7h-9a1 1 0 100 2h9a9 9 0 009-9V13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 10.5a1.5 1.5 0 00-3 0v6a6.5 6.5 0 006.5 6.5h7a1.5 1.5 0 000-3h-7a3.5 3.5 0 01-3.5-3.5v-6zM31.5 28.5a1.5 1.5 0 00-3 0V35H22a1.5 1.5 0 000 3h6.5v6.5a1.5 1.5 0 003 0V38H38a1.5 1.5 0 000-3h-6.5v-6.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 4C13.701 4 9 8.701 9 14.5v31C9 51.299 13.701 56 19.5 56h21C46.299 56 51 51.299 51 45.5V21.048a7.5 7.5 0 00-2.197-5.303l-9.547-9.548A7.5 7.5 0 0033.951 4H19.5zM12 14.5A7.5 7.5 0 0119.5 7h14.452a4.5 4.5 0 013.182 1.318l9.548 9.548A4.5 4.5 0 0148 21.048V45.5a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-31z"
        fill="currentColor"
      />
      <path
        d="M56.5 23a1.5 1.5 0 011.5 1.5v23C58 56.06 51.06 63 42.5 63h-20a1.5 1.5 0 010-3h20C49.404 60 55 54.404 55 47.5v-23a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocsPlusRegular)
