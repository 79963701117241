import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoOkRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.664a3.211 3.211 0 100 6.422 3.211 3.211 0 000-6.422zm-1.71 3.21a1.711 1.711 0 113.422 0 1.711 1.711 0 01-3.423 0z"
          fill="currentColor"
        />
        <path
          d="M11.824 8.392a.75.75 0 01-.072 1.059c-.763.666-1.649 1.144-2.614 1.357l2.249 2.248a.75.75 0 01-1.061 1.06L8 11.792l-2.326 2.326a.75.75 0 11-1.06-1.06l2.248-2.25c-.965-.212-1.85-.69-2.614-1.356a.75.75 0 01.986-1.13C6.06 9.04 7.008 9.432 8 9.432s1.94-.391 2.766-1.111a.75.75 0 011.058.071z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.664 6.522c-.03-1.312 1.042-2.356 2.348-2.356a2.352 2.352 0 012.349 2.348 2.352 2.352 0 01-2.349 2.348 2.352 2.352 0 01-2.348-2.34zm2.348-1.106c-.638 0-1.113.5-1.098 1.082v.016c0 .604.495 1.098 1.098 1.098.604 0 1.099-.494 1.099-1.098 0-.604-.495-1.098-1.099-1.098z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.192 6.514c0-2.645 2.155-4.795 4.82-4.795a4.803 4.803 0 014.796 4.795 4.767 4.767 0 01-.854 2.727c.429.119.814.39 1.064.797.557.863.3 2.026-.573 2.562a8.157 8.157 0 01-1.552.76l1.614 1.564a1.846 1.846 0 010 2.607 1.846 1.846 0 01-2.607 0l-1.856-1.856-1.855 1.856a1.918 1.918 0 01-1.316.533 1.81 1.81 0 01-1.316-.533 1.846 1.846 0 010-2.607l1.582-1.582a8.165 8.165 0 01-1.574-.77l.334-.528-.325.534c-.874-.532-1.127-1.659-.644-2.515a1.844 1.844 0 011.117-.821 4.757 4.757 0 01-.855-2.728zm1.652 4.014a.619.619 0 00-.835.168.62.62 0 00.215.814l.01.006c.682.433 1.44.733 2.23.919a.625.625 0 01.3 1.05L6.44 15.807a.596.596 0 000 .84.563.563 0 00.432.166.665.665 0 00.445-.18l2.285-2.284a.625.625 0 01.883 0l2.298 2.298c.23.23.609.23.84 0 .229-.23.23-.606.002-.837l-2.368-2.293a.625.625 0 01.292-1.057 7.269 7.269 0 002.23-.919l.01-.006a.592.592 0 00.166-.84.592.592 0 00-.823-.175l-.007.005a5.928 5.928 0 01-6.282.003zm3.168-7.56c-1.98 0-3.57 1.596-3.57 3.546 0 1.95 1.59 3.546 3.57 3.546a3.553 3.553 0 003.546-3.546 3.553 3.553 0 00-3.546-3.545z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.15 7.921c-.037-1.6 1.27-2.875 2.865-2.875A2.87 2.87 0 0114.88 7.91a2.87 2.87 0 01-2.865 2.866A2.87 2.87 0 019.15 7.92zm2.865-1.375c-.793 0-1.385.62-1.366 1.345v.02c0 .75.615 1.365 1.366 1.365a1.37 1.37 0 001.365-1.365 1.37 1.37 0 00-1.365-1.365z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.115 7.911c0-3.238 2.638-5.87 5.9-5.87a5.879 5.879 0 015.87 5.87 5.835 5.835 0 01-1.059 3.358c.531.141 1.01.475 1.32.977.678 1.051.366 2.47-.698 3.121-.614.388-1.262.7-1.929.942l2.002 1.938a2.25 2.25 0 010 3.176c-.876.875-2.301.876-3.176 0l-2.29-2.29-2.29 2.29a2.337 2.337 0 01-1.603.649 2.204 2.204 0 01-1.605-.649 2.249 2.249 0 010-3.176l1.961-1.961a10.01 10.01 0 01-1.949-.95c-1.06-.65-1.366-2.019-.778-3.06a2.246 2.246 0 011.385-1.006 5.823 5.823 0 01-1.061-3.359zm2.02 4.913a.777.777 0 00-1.05.212.779.779 0 00.27 1.023l.012.007c.84.532 1.772.901 2.744 1.13a.75.75 0 01.358 1.26l-2.85 2.852a.749.749 0 000 1.055c.145.145.312.209.542.209.19 0 .379-.068.558-.225l2.805-2.805a.75.75 0 011.06 0l2.821 2.82c.29.29.765.29 1.055 0a.75.75 0 00.003-1.05l-2.907-2.816a.75.75 0 01.35-1.27 8.942 8.942 0 002.744-1.13l.011-.006a.743.743 0 00.21-1.054.744.744 0 00-1.035-.222l-.008.006a7.26 7.26 0 01-7.694.004zm3.88-9.283c-2.44 0-4.4 1.967-4.4 4.37 0 2.404 1.96 4.37 4.4 4.37a4.379 4.379 0 004.37-4.37 4.379 4.379 0 00-4.37-4.37z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.263 10.446c-.048-2.1 1.666-3.77 3.757-3.77a3.763 3.763 0 013.757 3.757 3.763 3.763 0 01-3.757 3.757 3.762 3.762 0 01-3.757-3.744zm3.757-1.77c-1.021 0-1.782.8-1.758 1.73v.027c0 .966.792 1.757 1.758 1.757s1.757-.791 1.757-1.757c0-.966-.791-1.757-1.757-1.757z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.307 10.433c0-4.232 3.448-7.673 7.713-7.673 4.228 0 7.673 3.444 7.673 7.673a7.627 7.627 0 01-1.36 4.354c.682.19 1.297.626 1.696 1.273.89 1.381.48 3.243-.917 4.1-.79.5-1.625.902-2.483 1.216l2.57 2.49.012.012a2.954 2.954 0 010 4.17 2.954 2.954 0 01-4.171.001l-2.969-2.97-2.969 2.97a.932.932 0 01-.038.036c-1.122 1.01-2.975 1.162-4.173-.036a2.953 2.953 0 010-4.171l2.53-2.531a13.058 13.058 0 01-2.518-1.232l.015.01c-1.399-.852-1.803-2.655-1.03-4.024l.033-.054a2.95 2.95 0 011.75-1.258 7.611 7.611 0 01-1.364-4.356zm2.643 6.412a.99.99 0 00-1.336.268c-.243.465-.086 1.042.344 1.303l.015.01c1.093.692 2.305 1.172 3.57 1.47a1 1 0 01.478 1.68l-3.716 3.716a.954.954 0 000 1.343c.336.335.957.366 1.404-.022l3.655-3.654a1 1 0 011.414 0l3.676 3.676a.953.953 0 001.343 0 .954.954 0 00.005-1.338l-3.79-3.67a1 1 0 01.467-1.692c1.265-.297 2.477-.777 3.57-1.47l.015-.009a.947.947 0 00.265-1.343.947.947 0 00-1.317-.281l-.011.007c-3.041 1.923-7.009 1.925-10.05.006zM16.02 4.76c-3.168 0-5.713 2.553-5.713 5.673s2.545 5.672 5.713 5.672a5.685 5.685 0 005.673-5.672A5.685 5.685 0 0016.02 4.76z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.026 20.865c-.092-3.908 3.098-7.033 7.014-7.033 3.865 0 7.014 3.148 7.014 7.014 0 3.865-3.149 7.013-7.014 7.013-3.858 0-7.003-3.138-7.014-6.994zm7.014-4.034c-2.31 0-4.071 1.82-4.014 3.975v.04a4.025 4.025 0 004.014 4.013 4.025 4.025 0 004.014-4.014 4.025 4.025 0 00-4.014-4.014z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.115 20.846C17.115 12.659 23.785 6 32.04 6c8.18 0 14.846 6.665 14.846 14.846 0 3.405-1.156 6.549-3.095 9.058a5.327 5.327 0 013.845 2.483c1.635 2.53.88 5.943-1.676 7.508a25.859 25.859 0 01-5.632 2.654l5.724 5.543.017.017a5.407 5.407 0 010 7.635 5.407 5.407 0 01-7.635 0l-6.292-6.291-6.291 6.291a1.553 1.553 0 01-.057.055c-1.046.94-2.354 1.503-3.8 1.503-1.377 0-2.738-.437-3.858-1.558a5.407 5.407 0 010-7.635l5.625-5.625a25.865 25.865 0 01-5.676-2.67c-2.55-1.56-3.29-4.856-1.873-7.365a5.411 5.411 0 014.018-2.525 14.734 14.734 0 01-3.115-9.079zm5.048 12.418c-1.16-.7-2.617-.394-3.362.703-.625 1.157-.249 2.62.856 3.293l.022.014c2.239 1.418 4.716 2.397 7.293 3.003a1.5 1.5 0 01.717 2.521l-7.432 7.432a2.407 2.407 0 000 3.393c.478.478 1.035.68 1.736.68.621 0 1.221-.23 1.767-.71l7.321-7.322a1.5 1.5 0 012.122 0l7.352 7.352c.932.932 2.46.932 3.392 0 .93-.93.933-2.452.008-3.386l-7.582-7.342a1.5 1.5 0 01.7-2.538c2.576-.606 5.054-1.586 7.292-3.003l.023-.014c1.104-.672 1.465-2.194.722-3.334a1.458 1.458 0 01-.025-.04c-.671-1.103-2.194-1.465-3.333-.721l-.018.011c-5.92 3.743-13.649 3.746-19.571.008zM32.04 9c-6.61 0-11.925 5.328-11.925 11.846 0 6.517 5.316 11.845 11.925 11.845 6.524 0 11.846-5.322 11.846-11.846C43.886 14.323 38.564 9 32.04 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoOkRegular)
