import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoTelegramRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.175 5.207a2819.95 2819.95 0 001.553-.672l.56-.242.719-.31s0-.001 0 0l.017-.008.778-.337a.5.5 0 01.694.52L13.391 5l-.002.02c-.318 2.519-.653 5.036-1.069 7.54a.494.494 0 01-.751.339c-1.11-.7-2.203-1.42-3.298-2.142l-.327-.216c-.323-.213-.473-.394-.508-.563-.057-.276.193-.517.488-.802.077-.074.157-.151.236-.233.035-.036.207-.196.45-.421.364-.34.888-.827 1.346-1.266.334-.32.632-.613.807-.804.096-.105.155-.18.162-.21.015-.065.004-.113-.024-.146-.047-.058-.142-.071-.23-.051a1.21 1.21 0 00-.173.097c-.13.08-.334.211-.61.392l-.331.218a327.234 327.234 0 00-3.12 2.09c-.102.071-.203.133-.3.185-.284.15-.546.223-.787.217a4.85 4.85 0 01-1.266-.224 9.419 9.419 0 01-.312-.1c-.31-.103-.617-.222-.917-.338l-.03-.012a98.535 98.535 0 00-.2-.077c-.214-.082-.222-.393-.01-.482a481.435 481.435 0 001.406-.593c1.72-.73 3.438-1.469 5.154-2.21zM6.084 10.65c-.244.064-.5.099-.767.093-1.112-.024-2.235-.463-3.01-.765l-.22-.086C.653 9.34.537 7.257 2.034 6.628a900.17 900.17 0 008.658-3.711l1.515-.656c1.42-.614 2.97.548 2.778 2.083-.35 2.809-.717 5.639-1.185 8.461a1.994 1.994 0 01-3.031 1.362 192.518 192.518 0 01-3.323-2.158l-.327-.216c-.326-.215-.723-.531-.968-1.001a2.035 2.035 0 01-.067-.142z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.938 12.846c-.373.136-.771.217-1.188.208-1.32-.029-2.66-.551-3.638-.933l-.273-.106C.35 11.443.232 9.282 1.783 8.63c3.616-1.52 7.218-3.078 10.826-4.64l1.894-.82c1.509-.652 3.155.582 2.951 2.214-.438 3.51-.895 7.041-1.478 10.56a2.118 2.118 0 01-3.22 1.447A240.577 240.577 0 018.2 14.428c-.388-.256-.821-.609-1.084-1.112a2.09 2.09 0 01-.178-.47zm-3.282-1.75a39.065 39.065 0 01-1.278-.481c-.269-.103-.279-.49-.014-.602a349.434 349.434 0 001.253-.529c3.204-1.355 6.397-2.737 9.59-4.118l1.891-.819a.624.624 0 01.868.651c-.438 3.508-.892 7.014-1.47 10.502a.618.618 0 01-.94.422 239.869 239.869 0 01-4.122-2.678l-.409-.269c-.804-.53-.747-.905-.416-1.303a5.37 5.37 0 01.392-.403c.096-.093.196-.19.294-.291.044-.046.26-.246.562-.527.53-.493 1.332-1.238 1.957-1.845.303-.295.566-.557.735-.743.12-.13.194-.223.203-.261.051-.221-.143-.287-.318-.247-.02.004-.092.045-.216.121-.179.11-.465.295-.859.553a352.678 352.678 0 00-3.745 2.505l-.471.318c-.5.344-.954.511-1.36.502-.697-.015-1.418-.21-2.127-.457z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.65 14.867c.012.3.09.608.248.911.283.543.758.937 1.218 1.24.62.409 1.117.747 1.621 1.09l.068.047c.528.359 1.069.727 1.76 1.18.154.1.299.204.466.323l.11.078c.201.143.442.312.7.463.521.307 1.24.619 2.13.537 1.488-.137 2.15-1.501 2.392-2.801.511-2.73 1.53-8.718 1.769-11.236.02-.212.04-.559-.02-.932-.057-.35-.223-.954-.774-1.401a2.304 2.304 0 00-.917-.441 2.67 2.67 0 00-.653-.074c-.56.01-1.131.17-1.975.477-.87.315-2.196.852-4.335 1.742-1.513.63-4.52 1.924-9.007 3.88-.412.165-.817.362-1.152.611-.303.225-.813.687-.908 1.44-.07.553.094 1.07.422 1.472.28.344.627.541.86.654.342.166.807.311 1.167.424.089.028.172.053.245.077.414.135.911.285 1.388.404.441.111.986.227 1.443.237.611.014 1.193-.146 1.733-.402zm2.178-3.2c-.659.442-1.394.937-2.205 1.484-.616.423-1.174.63-1.674.619-.552-.012-1.612-.312-2.4-.568a32.681 32.681 0 00-.338-.108c-.802-.252-1.39-.437-1.331-.907.034-.278.417-.562 1.148-.853 4.502-1.961 7.504-3.254 9.006-3.879 4.289-1.784 5.18-2.094 5.76-2.104.128-.002.414.03.599.18.277.224.276.705.245 1.026-.232 2.442-1.238 8.367-1.749 11.102-.216 1.157-.643 1.545-1.055 1.583-.71.066-1.285-.344-1.925-.8-.168-.12-.34-.243-.522-.362-.71-.465-1.258-.838-1.807-1.213-.504-.343-1.008-.686-1.639-1.102-1.312-.864-.763-1.394-.03-2.1.118-.114.242-.233.363-.359.043-.045.224-.214.488-.46l.203-.188c.73-.68 1.876-1.745 2.671-2.528.262-.258.485-.485.643-.658.148-.161.239-.275.25-.322.01-.04.018-.193-.072-.273-.09-.08-.224-.053-.32-.031a1.863 1.863 0 00-.266.15c-.172.106-.427.27-.763.489-.72.469-1.812 1.196-3.28 2.181z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.438 15.555c-.88.59-1.86 1.25-2.94 1.98-.822.564-1.566.839-2.233.825-.735-.016-2.15-.416-3.2-.758l-.45-.143c-1.07-.336-1.854-.583-1.776-1.21.047-.37.558-.75 1.532-1.137 6.003-2.615 10.005-4.34 12.008-5.172 5.718-2.378 6.906-2.792 7.68-2.805.17-.003.551.039.798.24.37.299.368.94.328 1.367-.31 3.256-1.651 11.157-2.333 14.803-.289 1.543-.857 2.06-1.407 2.111-.947.088-1.713-.459-2.567-1.067-.224-.16-.454-.324-.695-.482-.946-.62-1.677-1.118-2.41-1.617-.671-.457-1.344-.915-2.185-1.47-1.75-1.152-1.017-1.859-.04-2.8.158-.152.322-.311.484-.479.058-.06.299-.285.651-.613l.27-.251c.974-.905 2.502-2.326 3.562-3.37.349-.344.647-.647.857-.878.198-.215.318-.367.333-.43.013-.054.025-.256-.096-.363-.12-.107-.298-.07-.426-.042-.032.008-.15.074-.355.2-.23.142-.57.36-1.018.652-.958.625-2.416 1.595-4.372 2.909zm-2.906 4.268c.017.399.12.81.332 1.215.377.723 1.011 1.249 1.624 1.652.828.546 1.49.997 2.161 1.454l.091.062c.704.48 1.425.97 2.347 1.574.205.134.398.272.621.431l.147.104c.268.191.59.416.932.617.696.409 1.653.825 2.841.716 1.984-.183 2.866-2.001 3.19-3.735.68-3.64 2.038-11.624 2.358-14.981a4.93 4.93 0 00-.027-1.243c-.076-.467-.298-1.272-1.032-1.868a3.073 3.073 0 00-1.223-.588 3.559 3.559 0 00-.87-.098c-.747.013-1.509.227-2.633.636-1.16.42-2.929 1.136-5.78 2.322-2.018.84-6.026 2.566-12.01 5.173-.548.22-1.09.483-1.536.816-.404.3-1.084.916-1.21 1.919a2.57 2.57 0 00.562 1.963c.373.46.836.722 1.146.872.457.221 1.077.415 1.557.565l.326.103c.553.18 1.216.38 1.85.539.59.148 1.315.303 1.926.316.814.018 1.59-.195 2.31-.537z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.587 27.455c11.977-5.218 19.99-8.67 24.018-10.345 5.707-2.374 9.224-3.797 11.517-4.629 2.242-.813 3.63-1.188 4.944-1.21.406-.008.935.035 1.49.168.537.128 1.325.393 2.049.98 1.193.968 1.571 2.293 1.706 3.12.141.872.095 1.7.045 2.23-.635 6.664-3.341 22.591-4.703 29.873-.631 3.372-2.238 6.359-5.489 6.658-2.08.192-3.78-.532-5.084-1.298a24.938 24.938 0 01-1.791-1.187 152.8 152.8 0 01-.287-.204c-.447-.319-.852-.607-1.28-.888a299.276 299.276 0 01-4.68-3.137l-.181-.124c-1.344-.916-2.673-1.82-4.336-2.916-1.173-.773-2.272-1.71-2.91-2.933a4.68 4.68 0 01-.263-3.84c-1.826 1.191-3.814 1.99-5.887 1.946-1.1-.024-2.457-.31-3.628-.603a57.075 57.075 0 01-3.636-1.059l-.71-.225c-.96-.3-2.09-.654-2.929-1.06-.566-.275-1.341-.722-1.953-1.474a4.142 4.142 0 01-.907-3.172c.2-1.597 1.291-2.616 2.024-3.16.8-.594 1.797-1.085 2.861-1.51zm1.155 2.77c12.005-5.231 20.01-8.68 24.015-10.345 11.437-4.757 13.813-5.583 15.362-5.61.34-.006 1.102.078 1.595.478.739.6.737 1.88.655 2.737-.62 6.511-3.3 22.313-4.665 29.606-.577 3.086-1.714 4.12-2.814 4.222-1.894.174-3.427-.919-5.133-2.135-.448-.32-.909-.648-1.39-.964a307.478 307.478 0 01-4.821-3.234 325.435 325.435 0 00-4.37-2.94c-3.5-2.305-2.034-3.717-.08-5.6.315-.305.644-.622.967-.957.144-.15.85-.805 1.843-1.73 1.098-1.02 2.549-2.37 3.986-3.726a174.802 174.802 0 003.873-3.747c.383-.387.716-.733.98-1.021.394-.431.636-.735.665-.86.026-.108.05-.514-.192-.727-.24-.215-.596-.141-.852-.083-.065.015-.301.148-.71.4-.297.183-.686.43-1.166.739-1.053.678-2.544 1.66-4.473 2.943-2.77 1.843-6.444 4.309-11.021 7.399-1.644 1.128-3.132 1.678-4.466 1.65-1.47-.032-4.299-.832-6.401-1.515-.309-.1-.61-.196-.9-.287-2.138-.672-3.707-1.166-3.55-2.419.093-.741 1.114-1.5 3.063-2.275z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoTelegramRegular)
