import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import TextPage from '@modules/support/components/TextPage/TextPage'

import { useSupportStore } from '../store/SupportStore'

const PartnersProgramPage = observer(() => {
  const store = useSupportStore()

  useEffect(() => {
    store.fetchPartnersProgram()
  }, [])

  return (
    <TextPage
      title="Партнерская программа"
      markup={store.partners?.markup}
      file={store.partners?.file}
    />
  )
})

export default PartnersProgramPage
