import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureACircleDownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 4.25a.75.75 0 01.75.75v4.428L9.924 8.02a.75.75 0 111.152.96l-1.732 2.078c-.7.84-1.989.84-2.688 0L4.924 8.98a.75.75 0 011.152-.96L7.25 9.428V5A.75.75 0 018 4.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 110 14A7 7 0 018 1zm5.5 7a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 6a.75.75 0 01.75.75v5.193l1.674-2.009a.75.75 0 011.152.96l-2.231 2.679c-.7.84-1.99.84-2.69 0l-2.231-2.678a.75.75 0 011.152-.96l1.674 2.008V6.751A.75.75 0 0110 6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1110 18 7.999 7.999 0 0110 2zm6.499 8A6.499 6.499 0 103.5 10 6.499 6.499 0 0016.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 6.75a.75.75 0 01.75.75v7.69l2.72-2.72a.75.75 0 111.06 1.06l-3.005 3.006a2.156 2.156 0 01-3.05 0L7.47 13.53a.75.75 0 111.06-1.06l2.72 2.72V7.5a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zM20.25 12a8.25 8.25 0 10-16.5 0 8.25 8.25 0 0016.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 9a1 1 0 011 1v10.41l3.308-3.308a1 1 0 111.414 1.414l-3.6 3.6a2.99 2.99 0 01-2.002.877 1.012 1.012 0 01-.24 0 2.99 2.99 0 01-2.001-.876l-3.601-3.601a1 1 0 111.414-1.414L15 20.409V10a1 1 0 011-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3zm11 13c0-6.075-4.925-11-11-11S5 9.925 5 16s4.925 11 11 11 11-4.925 11-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 17.5a1.5 1.5 0 011.5 1.5v23.104l7.94-7.94a1.5 1.5 0 012.12 2.122l-8.378 8.378a4.5 4.5 0 01-6.364 0l-8.379-8.378a1.5 1.5 0 012.122-2.122l7.939 7.94V19a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6c14.36 0 26 11.64 26 26S46.36 58 32 58 6 46.36 6 32 17.64 6 32 6zm23 26C55 19.297 44.703 9 32 9S9 19.297 9 32s10.297 23 23 23 23-10.297 23-23z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureACircleDownRegular)
