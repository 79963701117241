import { EMainRoutes } from './MainRoutes'

export const breadcrumbRoutes = [
  {
    path: EMainRoutes.Main,
    breadcrumb: 'Главная',
    children: [
      {
        path: EMainRoutes.MainConfigurator,
        breadcrumb: 'Конфигуратор',
        children: [
          {
            path: EMainRoutes.ConfiguratorOrders,
            breadcrumb: 'История создания конфигураций',
            children: [
              {
                path: EMainRoutes.ConfiguratorOrder,
                breadcrumb: 'Детали заказа',
              },
            ],
          },
          {
            path: EMainRoutes.ConfiguratorCreate,
            breadcrumb: 'Создать конфигурацию',
          },
        ],
      },
      {
        path: EMainRoutes.MainTraining,
        breadcrumb: 'Обучение',
      },
      {
        path: '*',
        breadcrumb: ' ',
      },
      {
        path: EMainRoutes.MainCompany,
        breadcrumb: 'Информация об организации',
        children: [
          {
            path: EMainRoutes.CompanyEmployees,
            breadcrumb: 'Список сотрудников',
            children: [
              {
                path: EMainRoutes.CompanyEmployee,
                breadcrumb: 'Информация о сотруднике ',
              },
            ],
          },
        ],
      },
      {
        path: EMainRoutes.MainUserInfo,
        breadcrumb: 'Информация о пользователе',
      },
      {
        path: EMainRoutes.MainSales,
        breadcrumb: 'Продажи',
        children: [
          {
            path: EMainRoutes.SalesRegisterTransaction,
            breadcrumb: 'Регистрация сделок',
          },
          {
            path: EMainRoutes.SalesTransactions,
            breadcrumb: 'Сделки',
            children: [
              {
                path: EMainRoutes.SalesTransaction,
                breadcrumb: 'Страница сделки',
              },
            ],
          },
        ],
      },
      {
        path: EMainRoutes.MainMarketing,
        breadcrumb: 'Маркетинг',
        children: [
          {
            path: EMainRoutes.MarketingEvents,
            breadcrumb: 'Мероприятия для партнеров',
          },
          {
            path: EMainRoutes.MarketingWebinars,
            breadcrumb: 'Вебинары',
          },
          {
            path: EMainRoutes.MarketingEventsCalendar,
            breadcrumb: 'Календарь событий',
            children: [
              {
                path: EMainRoutes.MarketingEventCalendar,
                breadcrumb: 'Мероприятие',
              },
            ],
          },
          {
            path: EMainRoutes.MarketingPartnerNews,
            breadcrumb: 'Партнерские новости',
          },
          {
            path: EMainRoutes.MarketingMaterials,
            breadcrumb: 'Маркетинговые материалы',
          },
          {
            path: EMainRoutes.MarketingFunds,
            breadcrumb: 'Маркетинговые средства',
          },
        ],
      },
      {
        path: EMainRoutes.MainSupport,
        breadcrumb: 'Поддержка',
        children: [
          {
            path: EMainRoutes.SupportPrivacyPolicy,
            breadcrumb: 'Политика конфиденциальности',
          },
          {
            path: EMainRoutes.SupportWarranty,
            breadcrumb: 'Гарантии',
          },
          {
            path: EMainRoutes.SupportContact,
            breadcrumb: 'Служба поддержки',
          },
          {
            path: EMainRoutes.SupportDrivers,
            breadcrumb: 'Драйверы',
          },
          {
            path: EMainRoutes.SupportPartnersProgram,
            breadcrumb: 'Партнерская программа',
          },
        ],
      },
      {
        path: EMainRoutes.MainProducts,
        breadcrumb: 'Продукция',
        children: [
          {
            path: EMainRoutes.CategoryProduct,
            breadcrumb: 'Категория',
          },
          {
            path: EMainRoutes.ProductsById,
            breadcrumb: 'Продукт',
          },
        ],
      },
    ],
  },
]
