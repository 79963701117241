import { memo } from 'react'

import Button from '@ui/Button'
import { Table } from '@ui/Table'
import TableItem from '@ui/Table/components/TableItem'
import TableRow from '@ui/Table/components/TableRow'
import { HeaderItem } from '@ui/Table/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { EMainRoutes } from '@lib/navigation'

import styles from './TablePlacholder.module.scss'

interface ITablePlacholderProps {
  header: HeaderItem[]
}

const TablePlacholder = ({ header }: ITablePlacholderProps) => (
  <Table header={header}>
    <TableRow>
      <TableItem colSpan={header?.length}>
        <div className={styles.placeholder}>
          <Typography
            size={18}
            color={COLORS.GRAY.GRAY_1}
            className={styles.title}>
            Здесь будут отображаться ваши сделки
          </Typography>
          <Button size="small" linkTo={EMainRoutes.SalesRegisterTransaction}>
            Зарегистрировать первую сделку
          </Button>
        </div>
      </TableItem>
    </TableRow>
  </Table>
)

export default memo(TablePlacholder)
