import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EStatusProcess, FormConstructor } from '@lib/form'
import { EServerError } from '@lib/services'

import { AuthStore } from './AuthStore'

@singleton()
class ForgotPasswordFormStore {
  private authStore = container.resolve(AuthStore)

  form = new FormConstructor({
    email: {
      rule: 'required|email',
      errorText: 'Данная почта не зарегистрирована в системе',
    },
  })

  constructor() {
    makeAutoObservable<ForgotPasswordFormStore, 'authStore'>(this, {
      authStore: false,
    })
  }

  get restoreSuccess() {
    return this.form.meta.fetchStatus === EStatusProcess.SUCCESS
  }
  get restoreError() {
    return this.form.meta.fetchStatus === EStatusProcess.FAIL
  }
  get restoreIDLE() {
    return this.form.meta.fetchStatus === EStatusProcess.IDLE
  }
  get restoreRunning() {
    return this.form.meta.fetchStatus === EStatusProcess.RUNNING
  }

  handleSubmit = async () => {
    await this.form.handleSubmit(
      (fields) =>
        this.authStore.restore({
          email: fields.email.value,
        }),
      (errName) => {
        if (errName === EServerError.EmailNotFound) {
          return 'email'
        }
      },
    )
  }
}

export const useForgotPasswordFormStore = () =>
  container.resolve(ForgotPasswordFormStore)
