import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNetCloudArrowDownloadLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 4.25v.02a.5.5 0 01-.44.499A2.626 2.626 0 003.55 10h.95a.5.5 0 110 1h-1a3.625 3.625 0 01-.732-7.148 4.25 4.25 0 018.107-1.35A4.25 4.25 0 0110.75 11h-.25a.5.5 0 010-1h.25a3.25 3.25 0 10-.184-6.495.5.5 0 01-.499-.333A3.252 3.252 0 003.75 4.25z"
          fill="currentColor"
        />
        <path
          d="M7.5 5a.5.5 0 01.5.5v9.184l2.135-2.3a.5.5 0 11.733.681l-2.269 2.443a1.5 1.5 0 01-2.198 0l-2.269-2.443a.5.5 0 11.733-.68L7 14.685V5.5a.5.5 0 01.5-.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.125 1.5a4.125 4.125 0 00-4.117 3.871.5.5 0 01-.395.46A3.626 3.626 0 005.293 13H5.5a.5.5 0 010 1h-.25a4.625 4.625 0 01-1.205-9.056 5.126 5.126 0 019.747-1.44A5.25 5.25 0 1114 14h-.002l-.5-.002a.5.5 0 01.004-1L14 13a4.25 4.25 0 10-.477-8.474.5.5 0 01-.525-.324A4.127 4.127 0 009.125 1.5z"
        fill="currentColor"
      />
      <path
        d="M9.5 7.25a.5.5 0 01.5.5V18.5c0 .015 0 .029-.002.043l.032-.03 2.616-2.617a.5.5 0 01.708.708l-2.617 2.616a1.75 1.75 0 01-2.474 0l-2.617-2.616a.5.5 0 01.708-.708l2.616 2.617.032.03A.506.506 0 019 18.5V7.75a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudArrowDownloadLight)
