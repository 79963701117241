import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSettingsGearLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4.95a3.05 3.05 0 100 6.1 3.05 3.05 0 000-6.1zM5.95 8a2.05 2.05 0 114.1 0 2.05 2.05 0 01-4.1 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.395.999A1.5 1.5 0 005.9 2.38l-.032.399c-.014.183-.142.375-.368.496-.116.061-.23.127-.34.196-.217.137-.448.151-.614.072l-.361-.173a1.5 1.5 0 00-1.945.604l-.605 1.048a1.5 1.5 0 00.45 1.986l.33.227c.151.104.254.31.245.566a5.432 5.432 0 000 .395c.009.255-.094.461-.245.566l-.33.227a1.5 1.5 0 00-.45 1.986l.605 1.048a1.5 1.5 0 001.945.604l.361-.173c.166-.08.396-.065.613.072.111.07.225.135.34.197.227.12.355.312.37.496l.03.398a1.5 1.5 0 001.496 1.383h1.21a1.5 1.5 0 001.495-1.383l.032-.399c.014-.183.142-.375.369-.495.116-.062.229-.128.34-.197.217-.137.447-.151.613-.072l.362.173a1.5 1.5 0 001.945-.604l.605-1.048a1.5 1.5 0 00-.45-1.986l-.33-.227c-.152-.105-.255-.311-.245-.566a5.604 5.604 0 000-.395c-.01-.255.093-.461.244-.566l.331-.227a1.5 1.5 0 00.45-1.986l-.605-1.048a1.5 1.5 0 00-1.945-.604l-.362.173c-.166.08-.397.065-.614-.072a5.364 5.364 0 00-.34-.196c-.226-.12-.354-.313-.368-.496L10.1 2.38A1.5 1.5 0 008.604 1h-1.21zm-.498 1.46A.5.5 0 017.395 2h1.21a.5.5 0 01.499.46l.03.399c.047.594.436 1.056.898 1.301.094.05.186.103.276.16.444.279 1.039.384 1.577.127l.361-.172a.5.5 0 01.649.2l.605 1.049a.5.5 0 01-.15.662l-.33.227c-.491.337-.698.904-.679 1.427a4.524 4.524 0 010 .321c-.019.523.188 1.09.678 1.427l.33.227a.5.5 0 01.15.662l-.604 1.048a.5.5 0 01-.649.201l-.361-.172c-.538-.257-1.133-.152-1.576.127-.09.057-.183.11-.277.16-.462.245-.85.707-.897 1.301l-.031.399a.5.5 0 01-.499.46h-1.21a.5.5 0 01-.498-.46l-.032-.399c-.046-.593-.435-1.056-.897-1.3a4.29 4.29 0 01-.276-.16c-.444-.28-1.039-.385-1.577-.128l-.361.172a.5.5 0 01-.648-.201L2.5 10.476a.5.5 0 01.15-.662l.331-.227c.49-.337.697-.904.678-1.427a4.42 4.42 0 010-.321c.02-.523-.188-1.09-.678-1.427l-.33-.227a.5.5 0 01-.15-.662l.605-1.048a.5.5 0 01.648-.201l.362.172c.537.257 1.133.152 1.576-.127.09-.057.182-.11.276-.16.462-.245.85-.707.897-1.301l.032-.399z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.375a3.625 3.625 0 100 7.25 3.625 3.625 0 000-7.25zM7.375 10a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28 1.531a1.75 1.75 0 00-1.744 1.613l-.035.451c-.022.28-.214.555-.528.722-.13.07-.256.143-.38.22-.301.188-.635.218-.89.097l-.408-.196a1.75 1.75 0 00-2.27.705l-.719 1.245a1.75 1.75 0 00.525 2.318l.374.257c.232.159.373.462.361.816a6.534 6.534 0 000 .442c.012.354-.13.657-.361.816l-.374.257a1.75 1.75 0 00-.525 2.317l.72 1.246a1.75 1.75 0 002.269.705l.409-.196c.254-.12.588-.091.889.097.124.077.25.15.38.22.314.167.506.442.528.722l.035.45a1.75 1.75 0 001.745 1.614h1.438a1.75 1.75 0 001.745-1.613l.035-.451c.022-.28.214-.555.528-.722.13-.07.256-.143.38-.22.302-.188.635-.218.89-.097l.408.196a1.75 1.75 0 002.27-.705l.719-1.245a1.75 1.75 0 00-.525-2.318l-.374-.257c-.232-.16-.373-.462-.361-.816a6.472 6.472 0 000-.442c-.012-.354.13-.657.361-.816l.374-.257a1.75 1.75 0 00.525-2.318l-.72-1.245a1.75 1.75 0 00-2.269-.705l-.409.196c-.254.12-.587.091-.888-.097a6.436 6.436 0 00-.381-.22c-.313-.167-.506-.442-.528-.722l-.035-.45a1.75 1.75 0 00-1.745-1.614H9.281zm-.747 1.692a.75.75 0 01.748-.692h1.438a.75.75 0 01.748.692l.035.45c.054.691.508 1.235 1.054 1.526.11.059.217.12.322.186.525.329 1.223.45 1.85.151l.408-.195a.75.75 0 01.973.302l.719 1.245a.75.75 0 01-.225.994l-.374.257c-.57.392-.815 1.055-.795 1.674a5.532 5.532 0 010 .374c-.02.619.224 1.282.795 1.674l.374.257a.75.75 0 01.225.993l-.72 1.246a.75.75 0 01-.972.302l-.409-.195c-.626-.299-1.324-.178-1.85.15a5.44 5.44 0 01-.32.187c-.547.291-1 .835-1.055 1.526l-.035.45a.75.75 0 01-.748.692H9.281a.75.75 0 01-.748-.692l-.035-.45c-.054-.691-.508-1.235-1.054-1.526a5.443 5.443 0 01-.321-.186c-.526-.329-1.224-.45-1.85-.151l-.409.195a.75.75 0 01-.972-.302l-.72-1.245a.75.75 0 01.225-.994l.374-.257c.57-.392.816-1.055.795-1.674a5.537 5.537 0 010-.374c.02-.619-.224-1.283-.795-1.675l-.374-.256a.75.75 0 01-.225-.994l.72-1.245a.75.75 0 01.972-.302l.409.195c.626.299 1.324.178 1.85-.15.104-.066.212-.128.321-.187.546-.291 1-.835 1.054-1.526l.035-.45z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSettingsGearLight)
