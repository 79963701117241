import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechCamPhotoLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5.25a3 3 0 100 6 3 3 0 000-6zm-2 3a2 2 0 114 0 2 2 0 01-4 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.618 1a1.5 1.5 0 00-1.342.83L4.691 3H3.5A2.5 2.5 0 001 5.5v6A2.5 2.5 0 003.5 14h9a2.5 2.5 0 002.5-2.5v-6A2.5 2.5 0 0012.5 3h-1.191l-.585-1.17A1.5 1.5 0 009.382 1H6.618zm-.447 1.276A.5.5 0 016.618 2h2.764a.5.5 0 01.447.276l.586 1.171a1 1 0 00.894.553H12.5A1.5 1.5 0 0114 5.5v6a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 11.5v-6A1.5 1.5 0 013.5 4h1.191a1 1 0 00.894-.553l.586-1.17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.75a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-2.5 3.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.118 2a1.5 1.5 0 00-1.342.83L6.191 4H5a3 3 0 00-3 3v7a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3h-1.191l-.585-1.17A1.5 1.5 0 0011.882 2H8.118zm-.447 1.276A.5.5 0 018.118 3h3.764a.5.5 0 01.447.276l.586 1.171a1 1 0 00.894.553H15a2 2 0 012 2v7a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h1.191a1 1 0 00.894-.553l.586-1.17z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechCamPhotoLight)
