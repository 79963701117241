import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconFlagBLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 10.804c1.594-.781 3.164-.736 4.76.135 1.983 1.081 3.997 1.081 5.98 0A.5.5 0 0014 10.5v-8a.5.5 0 00-.74-.439c-1.683.919-3.336.919-5.02 0C6.454 1.087 4.636.986 2.83 1.78c-.538.237-.83.775-.83 1.314v7.397V15.5a.5.5 0 101 0v-4.696zm4.76-7.865c-1.526-.833-3.02-.906-4.526-.244-.131.058-.234.206-.234.399v6.614c1.743-.712 3.503-.594 5.24.353 1.597.871 3.166.916 4.76.135V3.292c-1.742.712-3.503.594-5.24-.353z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13.796c2.093-1.105 4.156-1.06 6.252.138 2.394 1.368 4.824 1.421 7.228.148a.985.985 0 00.52-.872V3.93c0-.585-.622-.918-1.106-.67-2.052 1.045-4.08.986-6.146-.194-2.3-1.314-4.637-1.417-6.954-.287-.51.249-.794.77-.794 1.301V19.5a.5.5 0 101 0v-5.704zm6.252-9.862C8.227 2.777 6.24 2.7 4.232 3.678A.439.439 0 004 4.08v8.602c2.244-1.023 4.51-.895 6.748.384 2.1 1.2 4.162 1.242 6.252.138V4.318c-2.248 1.025-4.515.892-6.748-.384z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFlagBLight)
