import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconPlusLight } from '@ui/icons'
import { HeaderItem } from '@ui/Table/types'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import TransactionTable from '@modules/sales/components/TransactionsTable'
import { useTransactionStore } from '@modules/sales/store/TransactionStore'

const header: HeaderItem[] = [
  {
    align: 'left',
    data: 'номер сделки',
  },
  {
    data: 'дата',
  },
  {
    data: 'статус',
  },
  {
    data: 'страница сделки',
  },
]

const HistoryTransactionsPage = () => {
  const transactionsStore = useTransactionStore()

  useEffect(() => {
    transactionsStore.getTransactions()
  }, [])

  return (
    <PageWrapper
      title="История сделок"
      headerRight={
        transactionsStore.transactions.length !== 0 && (
          <Button
            size="small"
            type="link"
            linkTo={EMainRoutes.SalesRegisterTransaction}
            IconLeft={IconPlusLight}
            withBorder>
            Новая сделка
          </Button>
        )
      }>
      <TransactionTable header={header} />
    </PageWrapper>
  )
}

export default observer(HistoryTransactionsPage)
