import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDivideRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 5a1 1 0 100-2 1 1 0 000 2zM2.75 8a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9A.75.75 0 012.75 8zM9 12a1 1 0 11-2 0 1 1 0 012 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 5.249a1.249 1.249 0 100-2.498 1.249 1.249 0 000 2.498zM2.25 10A.75.75 0 013 9.25h14a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zM11.248 16a1.249 1.249 0 11-2.497 0 1.249 1.249 0 012.498 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 6.374a1.374 1.374 0 100-2.748 1.374 1.374 0 000 2.748zM3.25 12a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75zM13.374 19a1.374 1.374 0 11-2.748 0 1.374 1.374 0 012.748 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 9a2 2 0 100-4 2 2 0 000 4zM4 16a1 1 0 011-1h22a1 1 0 110 2H5a1 1 0 01-1-1zM18 25a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 18a5 5 0 1110 0 5 5 0 01-10 0zm5-2a2 2 0 100 4 2 2 0 000-4z"
        fill="currentColor"
      />
      <path
        d="M9.5 32a1.5 1.5 0 011.5-1.5h42a1.5 1.5 0 010 3H11A1.5 1.5 0 019.5 32z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 41a5 5 0 100 10 5 5 0 000-10zm-2 5a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDivideRegular)
