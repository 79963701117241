import React, { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
import { ITextAreaProps } from './types'

const TextArea: React.FC<ITextAreaProps> = ({
  id,
  cols,
  label,
  className,
  labelSize = 16,
  labelWeight = 'regular',
  size = 'medium',
  resize = 'none',
  disabled = false,
  error = false,
  hasCount = false,
  errorText = '',
  value = '',
  maxLength,
  overflow = 'hidden',
  labelPosition = 'top',
  ...rest
}) => {
  const classNames = cx(className, styles[`${labelPosition}Label`])
  const classAreaNames = cx(
    hasCount ? styles[`withCount_${size}`] : styles[size],
  )

  return (
    <div className={classNames}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          <Typography size={labelSize} weight={labelWeight}>
            {label}
          </Typography>
        </label>
      )}
      <div className={styles.container}>
        <textarea
          className={classAreaNames}
          disabled={disabled}
          value={value}
          {...rest}
          cols={cols}
          maxLength={maxLength}
          style={{ resize: resize, overflow: overflow }}
        />
        {hasCount && (
          <div className={styles.count}>
            <Typography
              size={labelSize}
              weight="light"
              color={COLORS.GRAY.GRAY_2}>
              {maxLength
                ? `${maxLength - value.toString().length}`
                : `${value.toString().length}`}
            </Typography>
          </div>
        )}
      </div>
      {error && errorText && (
        <Typography
          weight={labelWeight}
          size={labelSize}
          color={COLORS.STATE.ATTENTION_P}>
          {errorText}
        </Typography>
      )}
    </div>
  )
}

export default memo(TextArea)
