import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookClosedHelpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 9.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 5.095c0-.509.562-1.1 1.5-1.1s1.5.591 1.5 1.1c0 .378-.287.784-.84.986-.59.216-1.16.66-1.16 1.414a.5.5 0 101 0c0-.138.084-.321.503-.475C9.328 6.718 10 6.01 10 5.095c0-1.258-1.23-2.1-2.5-2.1S5 3.837 5 5.095a.5.5 0 101 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 13.5V3.25A2.25 2.25 0 014.25 1H12a1 1 0 011 1v10.5a.5.5 0 01-.5.5h-9a.5.5 0 000 1h9a.5.5 0 010 1h-9A1.5 1.5 0 012 13.5zM3 3.25C3 2.56 3.56 2 4.25 2H12v10H3.5c-.175 0-.344.03-.5.085V3.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.148 11.346a.648.648 0 11-1.296 0 .648.648 0 011.296 0zM7.777 6.433c0-.726.703-1.43 1.723-1.43s1.723.704 1.723 1.43c0 .344-.1.556-.232.716-.147.177-.356.316-.631.477l-.111.064c-.232.133-.518.296-.74.501A1.49 1.49 0 009 9.33a.5.5 0 101 0c0-.176.06-.286.186-.402.133-.123.305-.222.543-.36l.134-.077c.28-.164.628-.378.897-.703.285-.342.463-.78.463-1.354 0-1.406-1.288-2.43-2.723-2.43-1.435 0-2.723 1.024-2.723 2.43a.5.5 0 001 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.998 4a3 3 0 013-3h8.254c.966 0 1.75.784 1.75 1.75v10.5a1.75 1.75 0 01-1.75 1.75H4.998a1 1 0 100 2h10.504a.5.5 0 110 1H4.998a2 2 0 01-2-2V4zm1 10.268a1.99 1.99 0 011-.268h9.254a.75.75 0 00.75-.75V2.75a.75.75 0 00-.75-.75H5.998a2 2 0 00-2 2v10.268z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookClosedHelpLight)
