import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTimeClockFastRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.875 6A5.502 5.502 0 0113.5 8 5.5 5.5 0 018 13.5V15a7 7 0 10-6.71-9h1.585z"
          fill="currentColor"
        />
        <path
          d="M7.75 3.949a.75.75 0 01.75.75v2.674a.25.25 0 00.141.225l2.134 1.035a.75.75 0 11-.654 1.35L7.987 8.948A1.75 1.75 0 017 7.373V4.7a.75.75 0 01.75-.75zM.781 7.25a.75.75 0 000 1.5h1.875a.75.75 0 000-1.5H.781zM.031 11a.75.75 0 01.75-.75H4.5a.75.75 0 010 1.5H.781a.75.75 0 01-.75-.75zM1 13.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5H1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.578 9a6.5 6.5 0 0112.92 1c0 3.59-2.909 6.499-6.498 6.499v1.5A7.999 7.999 0 102.063 9h1.515z"
          fill="currentColor"
        />
        <path
          d="M1.5 10.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-2zM.25 14a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5H1A.75.75 0 01.25 14zM.75 17a.75.75 0 01.75-.75H8a.75.75 0 010 1.5H1.5A.75.75 0 01.75 17zM10.48 6.443a.75.75 0 00-1.5 0v3.123a2 2 0 001.085 1.78l2.693 1.383a.75.75 0 10.685-1.334l-2.692-1.384a.5.5 0 01-.272-.445V6.443z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 3.75a8.25 8.25 0 00-8.223 7.575.75.75 0 11-1.495-.12C2.687 6.19 6.882 2.25 12 2.25c5.385 0 9.75 4.365 9.75 9.75 0 5.095-3.907 9.276-8.889 9.712a.75.75 0 11-.13-1.494A8.25 8.25 0 0012 3.75z"
          fill="currentColor"
        />
        <path
          d="M11.75 6.563a.75.75 0 01.75.75v3.947c0 .284.16.544.415.671l3.295 1.648a.75.75 0 11-.67 1.342l-3.296-1.648A2.25 2.25 0 0111 11.26V7.313a.75.75 0 01.75-.75zM2.5 20.25a.75.75 0 000 1.5H9a.75.75 0 000-1.5H2.5zM.25 18a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5H1A.75.75 0 01.25 18zM2 14.25a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5H2z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 5C10.234 5 5.503 9.437 5.038 15.082a1 1 0 01-1.994-.164C3.595 8.244 9.184 3 16 3c7.18 0 13 5.82 13 13 0 6.815-5.244 12.405-11.918 12.956a1 1 0 11-.164-1.994C22.563 26.497 27 21.766 27 16c0-6.075-4.925-11-11-11z"
          fill="currentColor"
        />
        <path
          d="M15 8.75a1 1 0 011 1v5.264a1 1 0 00.553.894l4.394 2.198a1 1 0 11-.894 1.788l-4.395-2.197A3 3 0 0114 15.014V9.75a1 1 0 011-1zM3.5 27a1 1 0 100 2H12a1 1 0 100-2H3.5zM0 24a1 1 0 011-1h8a1 1 0 110 2H1a1 1 0 01-1-1zM2.5 19a1 1 0 100 2H6a1 1 0 100-2H2.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M32 9C19.297 9 9 19.297 9 32a1.5 1.5 0 01-3 0C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26a1.5 1.5 0 010-3c12.703 0 23-10.297 23-23S44.703 9 32 9z"
        fill="currentColor"
      />
      <path
        d="M30.5 18a1.5 1.5 0 011.5 1.5v10.528a2.5 2.5 0 001.382 2.236l8.789 4.394a1.5 1.5 0 11-1.342 2.684l-8.789-4.395a5.5 5.5 0 01-3.04-4.92V19.5a1.5 1.5 0 011.5-1.5zM6.5 55a1.5 1.5 0 000 3h17a1.5 1.5 0 000-3h-17zM0 49.5A1.5 1.5 0 011.5 48h15a1.5 1.5 0 010 3h-15A1.5 1.5 0 010 49.5zM4.5 41a1.5 1.5 0 000 3h7a1.5 1.5 0 000-3h-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeClockFastRegular)
