import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconPeople1BadgeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.498 4.502a3.502 3.502 0 117.004 0 3.502 3.502 0 01-7.004 0zM8 2a2.502 2.502 0 100 5.003A2.502 2.502 0 008 2z"
          fill="currentColor"
        />
        <path
          d="M3.518 11.575c-.31.457-.518 1.075-.518 1.93a.5.5 0 01-1 0c0-1.025.252-1.846.69-2.491.436-.643 1.032-1.078 1.669-1.372 1.254-.58 2.726-.637 3.641-.637s2.387.057 3.641.637c.637.294 1.233.729 1.67 1.372.437.645.689 1.466.689 2.49a.5.5 0 01-1 0c0-.854-.208-1.472-.518-1.929-.311-.46-.749-.79-1.26-1.025-1.04-.481-2.32-.545-3.222-.545-.903 0-2.181.064-3.222.544-.511.237-.949.567-1.26 1.026z"
          fill="currentColor"
        />
        <path
          d="M8.5 12a.5.5 0 000 1H11a.5.5 0 000-1H8.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.501 6.499a4.499 4.499 0 118.998 0 4.499 4.499 0 01-8.998 0zM10 3a3.499 3.499 0 100 6.997A3.499 3.499 0 0010 3z"
        fill="currentColor"
      />
      <path
        d="M3.725 15.034C3.287 15.628 3 16.42 3 17.5a.5.5 0 01-1 0c0-1.269.341-2.276.921-3.06.577-.78 1.364-1.306 2.204-1.662C6.791 12.072 8.755 12 10 12c1.244 0 3.209.072 4.874.778.841.356 1.628.882 2.205 1.662.58.784.921 1.791.921 3.06a.5.5 0 11-1 0c0-1.08-.287-1.872-.725-2.466-.443-.598-1.066-1.028-1.79-1.335C13.02 13.079 11.235 13 10 13c-1.234 0-3.02.079-4.485.699-.724.307-1.347.737-1.79 1.335z"
        fill="currentColor"
      />
      <path d="M10.5 16a.5.5 0 000 1h4a.5.5 0 000-1h-4z" fill="currentColor" />
    </svg>
  )
}

export default observer(IconPeople1BadgeLight)
