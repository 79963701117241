import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import InDevelopPage from '@lib/components/InDevelopPage'
import NotFoundPage from '@lib/components/NotFoundPage'
import Page from '@lib/components/Page'
import { EAuthRoutes, EMainRoutes } from '@lib/navigation'

import { AuthForm } from '@modules/auth/components/AuthForm/AuthForm'
import { AuthLayout } from '@modules/auth/components/AuthLayout/AuthLayout'
import { ForgotPasswordForm } from '@modules/auth/components/ForgotPasswordForm/ForgotPasswordForm'
import { OnboardingForm } from '@modules/auth/components/OnboardingForm/OnboardingForm'
import { RegistrationEmployeeForm } from '@modules/auth/components/RegistrationEmployeeForm/RegistrationEmployeeForm'
import { RegistrationForm } from '@modules/auth/components/RegistrationForm/RegistrationForm'
import { RegistrationLayout } from '@modules/auth/components/RegistrationLayout/RegistrationLayout'
import CompanyPage from '@modules/company/pages/CompanyPage'
import CompanyEmployeesPage from '@modules/company/pages/CompanyPage/CompanyEmployeesPage'
import EmployeePage from '@modules/company/pages/CompanyPage/EmployeePage'
import ConfiguratorCreatePage from '@modules/configurator/pages/ConfiguratorCreatePage'
import ConfiguratorOrderPage from '@modules/configurator/pages/ConfiguratorOrderPage'
import ConfiguratorOrdersPage from '@modules/configurator/pages/ConfiguratorOrdersPage'
import ConfiguratorPage from '@modules/configurator/pages/ConfiguratorPage'
import MainPage from '@modules/home/pages/MainPage'
import EventsCalendar from '@modules/marketing/pages/EventsCalendar/Calendar'
import EventPage from '@modules/marketing/pages/EventsCalendar/EventPage'
import MarketingFundsPage from '@modules/marketing/pages/MarketingFundsPage'
import MarketingPage from '@modules/marketing/pages/MarketingPage'
import MaterialsPage from '@modules/marketing/pages/MaterialsPage'
import Webinars from '@modules/marketing/pages/Webinars/Webinars'
import MainProductPage from '@modules/products/pages/MainProductPage'
import ProductByIdPage from '@modules/products/pages/ProductByIdPage'
import ProductsPage from '@modules/products/pages/ProductsPage'
import HistoryTransactionsPage from '@modules/sales/pages/HistoryTransactionsPage'
import RegisterTransactionPage from '@modules/sales/pages/RegisterTransactionPage'
import { SalesPage } from '@modules/sales/pages/SalesPage'
import TransactionPage from '@modules/sales/pages/TransactionPage'
import PartnersProgramPage from '@modules/support/pages/PartnersProgramPage'
import PrivacyPolicyPage from '@modules/support/pages/PrivacyPolicyPage'
import SupportFormPage from '@modules/support/pages/SupportFormPage'
import SupportPage from '@modules/support/pages/SupportPage'
import WarrantyPage from '@modules/support/pages/WarrantyPage'
import UserInfoPage from '@modules/user/pages/UserInfoPage'

import { Stage, useInit } from './useInit'

function App() {
  const stage = useInit()

  if (stage === Stage.AUTH) {
    return (
      <Routes>
        <Route path={EMainRoutes.Main} element={<RegistrationLayout />}>
          <Route
            path={EAuthRoutes.Registration}
            element={<RegistrationForm />}
          />
          <Route
            path={EAuthRoutes.RegistrationEmployee}
            element={<RegistrationEmployeeForm />}
          />
        </Route>
        <Route path={EAuthRoutes.Registration} element={<AuthLayout />}>
          <Route path={EMainRoutes.Main} element={<AuthForm />} />
          <Route path={EAuthRoutes.Auth} element={<AuthForm />} />
          <Route path={EAuthRoutes.Restore} element={<ForgotPasswordForm />} />
        </Route>
      </Routes>
    )
  } else if (stage === Stage.ONBOARDING) {
    return (
      <Routes>
        <Route path={EMainRoutes.Main} element={<AuthLayout />}>
          <Route path={EMainRoutes.Main} element={<OnboardingForm />} />
          <Route
            path="*"
            element={<Navigate to={EMainRoutes.Main} replace />}
          />
        </Route>
      </Routes>
    )
  } else if (stage === Stage.MAIN) {
    return (
      <Routes>
        <Route path={EMainRoutes.Main} element={<Page />}>
          <Route path="*" element={<NotFoundPage />} />
          <Route path={EMainRoutes.Main} element={<MainPage />} />
          <Route path={EMainRoutes.MainCompany} element={<CompanyPage />} />
          <Route
            path={EMainRoutes.CompanyEmployees}
            element={<CompanyEmployeesPage />}
          />
          <Route
            path={EMainRoutes.CompanyEmployee}
            element={<EmployeePage />}
          />
          <Route path={EMainRoutes.MainUserInfo} element={<UserInfoPage />} />

          <Route path={EMainRoutes.MainTraining} element={<InDevelopPage />} />

          {/* Support */}
          <Route path={EMainRoutes.MainSupport} element={<SupportPage />} />
          <Route
            path={EMainRoutes.SupportPrivacyPolicy}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={EMainRoutes.SupportWarranty}
            element={<WarrantyPage />}
          />
          <Route
            path={EMainRoutes.SupportContact}
            element={<SupportFormPage />}
          />
          <Route
            path={EMainRoutes.SupportPartnersProgram}
            element={<PartnersProgramPage />}
          />
          <Route
            path={EMainRoutes.SupportDrivers}
            element={<InDevelopPage />}
          />

          {/* Sales */}
          <Route path={EMainRoutes.MainSales}>
            <Route index element={<SalesPage />} />
            <Route
              path={EMainRoutes.SalesRegisterTransaction}
              element={<RegisterTransactionPage />}
            />
            <Route
              path={EMainRoutes.SalesTransactions}
              element={<HistoryTransactionsPage />}
            />
            <Route
              path={EMainRoutes.SalesTransaction}
              element={<TransactionPage />}
            />
          </Route>

          {/* Marketing */}
          <Route path={EMainRoutes.MainMarketing} element={<MarketingPage />} />
          <Route
            path={EMainRoutes.MarketingEventsCalendar}
            element={<EventsCalendar />}
          />
          <Route
            path={EMainRoutes.MarketingEventCalendar}
            element={<EventPage />}
          />
          <Route
            path={EMainRoutes.MarketingPartnerNews}
            element={<InDevelopPage />}
          />
          <Route path={EMainRoutes.MarketingWebinars} element={<Webinars />} />
          <Route
            path={EMainRoutes.MarketingEvents}
            element={<InDevelopPage />}
          />
          <Route
            path={EMainRoutes.MarketingMaterials}
            element={<MaterialsPage />}
          />
          <Route
            path={EMainRoutes.MarketingFunds}
            element={<MarketingFundsPage />}
          />

          {/* Products */}
          <Route
            path={EMainRoutes.MainProducts}
            element={<MainProductPage />}
          />
          <Route
            path={EMainRoutes.CategoryProduct}
            element={<ProductsPage />}
          />
          <Route
            path={EMainRoutes.ProductsById}
            element={<ProductByIdPage />}
          />

          {/* Configurator */}
          <Route
            path={EMainRoutes.MainConfigurator}
            element={<ConfiguratorPage />}
          />
          <Route
            path={EMainRoutes.ConfiguratorCreate}
            element={<ConfiguratorCreatePage />}
          />
          <Route
            path={EMainRoutes.ConfiguratorOrders}
            element={<ConfiguratorOrdersPage />}
          />
          <Route
            path={EMainRoutes.ConfiguratorOrder}
            element={<ConfiguratorOrderPage />}
          />
        </Route>
      </Routes>
    )
  }
}

export default observer(App)
