import { memo, ReactNode } from 'react'

import { cx } from '@lib/styles'

import styles from './TableRow.module.scss'

interface ITableRow {
  children: ReactNode
  onClick?: () => void
  withoutDivider?: boolean
  className?: string
}

const TableRow: React.FC<ITableRow> = ({
  className,
  children,
  onClick,
  withoutDivider = false,
}) => (
  <tr
    className={cx(
      styles.row,
      { [styles.withoutDivider]: withoutDivider },
      className,
    )}
    onClick={onClick}>
    {children}
  </tr>
)

export default memo(TableRow)
