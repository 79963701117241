import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconArrowUiAuthLogoutLight } from '@ui/icons'

import PageWrapper from '@lib/components/Page/PageWrapper'
import TaggedContainer from '@lib/components/TaggedContainer'
import { ITagOption } from '@lib/components/TaggedContainer/TaggedContainer'
import { EAuthRoutes } from '@lib/navigation'

import { useAuthStore } from '@modules/auth/store/AuthStore'
import { useCompanyStore } from '@modules/company/store/CompanyStore'
import { BasicInformation, ContactInformation } from '@modules/user/components'
import { useUserStore } from '@modules/user/store/UserStore'

import styles from './styles.module.scss'

const UserInfoPage = () => {
  const userStore = useUserStore()
  const companyStore = useCompanyStore()
  const authStore = useAuthStore()

  const tags: ITagOption[] = useMemo(
    () => [
      {
        label: 'Организация',
        value: companyStore.company.name,
      },
      {
        label: 'Должность',
        value: userStore.isPartner ? 'Руководитель' : 'Подчиненный',
      },
      {
        label: 'Роль',
        value: userStore.isPartner ? 'Партнер' : 'Сотрудник',
      },
    ],
    [userStore.isPartner, companyStore.company.name],
  )

  const navigate = useNavigate()

  const logout = useCallback(() => {
    navigate(EAuthRoutes.Auth)
    authStore.logout()
  }, [])

  return (
    <PageWrapper
      title="Информация о пользователе"
      headerRight={
        <Button
          type="fill"
          withBorder
          className={styles.button}
          size="small"
          onClick={logout}
          IconRight={IconArrowUiAuthLogoutLight}>
          Выйти
        </Button>
      }
      className={styles.container}>
      <div className={styles.content}>
        <TaggedContainer
          // TODO: Вынести потом наверх и добавить тег должность если она имеется. Пока данных по должности нет
          tags={tags}
        />

        <div className={styles.formContainer}>
          <BasicInformation />
          <ContactInformation />
        </div>
      </div>
    </PageWrapper>
  )
}

export default observer(UserInfoPage)
