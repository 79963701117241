import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconStarRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.689.9A.75.75 0 007.31.9L5.547 4.995l-4.442.412a.75.75 0 00-.425 1.31l3.35 2.944-.98 4.352a.75.75 0 001.115.81L8 12.545l3.835 2.277a.75.75 0 001.115-.81l-.98-4.35 3.35-2.945a.75.75 0 00-.425-1.31l-4.442-.412L8.69.899zm1.626 5.59a1.5 1.5 0 01-1.24-.9L8 3.091 6.925 5.589a1.5 1.5 0 01-1.24.9l-2.707.252 2.043 1.794a1.5 1.5 0 01.473 1.457l-.598 2.652 2.338-1.388a1.5 1.5 0 011.532 0l2.338 1.389-.598-2.653a1.5 1.5 0 01.473-1.457l2.043-1.794-2.707-.251z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.861 1.7c-.325-.756-1.397-.756-1.722 0l-2.03 4.713L2 6.887C1.18 6.963.85 7.98 1.467 8.524l3.856 3.387-1.129 5.006c-.18.803.686 1.433 1.393 1.013L10 15.31l4.412 2.62c.708.42 1.574-.21 1.394-1.013l-1.129-5.006 3.856-3.387c.618-.542.287-1.561-.533-1.637l-5.11-.474L10.862 1.7zm1.891 6.206a1.5 1.5 0 01-1.239-.9L10 3.492 8.487 7.006a1.5 1.5 0 01-1.24.9L3.44 8.26l2.874 2.524a1.5 1.5 0 01.473 1.457l-.841 3.732 3.29-1.953a1.5 1.5 0 011.53 0l3.29 1.953-.841-3.732a1.5 1.5 0 01.473-1.457l2.874-2.524-3.809-.354z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.033 1.8c-.39-.908-1.676-.908-2.066 0l-2.53 5.875-6.37.59c-.984.092-1.381 1.315-.64 1.966l4.807 4.222-1.407 6.241c-.217.963.823 1.719 1.672 1.215L12 18.643l5.5 3.266c.85.504 1.89-.252 1.673-1.215l-1.407-6.24 4.806-4.223c.742-.651.345-1.874-.638-1.965l-6.37-.591-2.53-5.876zm2.392 7.369a1.5 1.5 0 01-1.24-.9L12 3.191 9.814 8.268a1.5 1.5 0 01-1.24.9l-5.502.51 4.152 3.648a1.5 1.5 0 01.473 1.457l-1.215 5.392 4.752-2.822a1.5 1.5 0 011.532 0l4.752 2.822-1.215-5.392a1.5 1.5 0 01.473-1.457l4.152-3.647-5.503-.51z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.378 2.2c-.521-1.21-2.235-1.21-2.756 0l-3.439 7.985-8.657.803c-1.31.121-1.84 1.752-.851 2.62l6.532 5.738-1.912 8.482c-.29 1.284 1.097 2.292 2.229 1.62L16 25.008l7.476 4.44c1.132.672 2.518-.336 2.23-1.62l-1.913-8.482 6.532-5.738c.99-.869.46-2.499-.851-2.62l-8.657-.803-3.44-7.986zm3.254 9.976a2 2 0 01-1.652-1.2L16 4.056l-2.98 6.92a2 2 0 01-1.652 1.2l-7.501.696 5.66 4.972a2 2 0 01.63 1.942l-1.656 7.35 6.478-3.847a2 2 0 012.042 0l6.478 3.846-1.657-7.349a2 2 0 01.632-1.942l5.66-4.972-7.502-.696z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.755 6.91c-1.04-2.418-4.47-2.418-5.51 0l-6.343 14.727-15.966 1.48c-2.62.244-3.68 3.504-1.702 5.241L17.28 38.941l-3.526 15.642c-.578 2.568 2.195 4.584 4.459 3.24L32 49.635l13.787 8.186c2.264 1.344 5.037-.67 4.459-3.239L46.72 38.941l12.046-10.583c1.978-1.737.919-4.997-1.703-5.24l-15.966-1.481L34.755 6.91zm6.065 17.714a3 3 0 01-2.478-1.8L32 8.095l-6.342 14.727a3 3 0 01-2.478 1.8L7.214 26.106 19.26 36.687a3 3 0 01.947 2.914L16.68 55.243l13.787-8.187a3 3 0 013.064 0l13.787 8.187L43.793 39.6a3 3 0 01.947-2.914l12.046-10.582-15.966-1.481z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconStarRegular)
