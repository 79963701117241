import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeADownLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.117 14.573a1.25 1.25 0 001.768 0l5.866-5.866c.63-.63.184-1.707-.707-1.707H12V2.5A1.5 1.5 0 0010.5 1h-5A1.5 1.5 0 004 2.5V7H1.958C1.067 7 .62 8.077 1.25 8.707l5.867 5.866zm1.06-.707a.25.25 0 01-.353 0L1.958 8H4.5a.5.5 0 00.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 00.5.5h2.544l-5.866 5.866z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.565 17.727c.793.793 2.08.793 2.873 0l6.827-6.829c.689-.689.2-1.867-.774-1.867H14.97V3.75c0-.95-.77-1.719-1.719-1.719h-6.5c-.95 0-1.719.77-1.719 1.719v5.281H2.51c-.974 0-1.462 1.178-.773 1.867l6.829 6.829zm2.21-.664a1.094 1.094 0 01-1.547 0l-6.83-6.828a.156.156 0 01.111-.266H5.5c.259 0 .469-.21.469-.47V3.75c0-.431.35-.781.781-.781h6.5c.431 0 .781.35.781.781V9.5c0 .259.21.469.469.469h2.991c.14 0 .21.168.11.266l-6.826 6.828z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownLight)
