import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  IconCalendarLight,
  IconLocationPinLight,
  IconTimeClockLight,
} from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { EMainRoutes, getRouteById } from '@lib/navigation/MainRoutes'

import HelperImage from '@modules/marketing/helpers/HelperImage'
import { IEventItemProps } from '@modules/marketing/models/Calendar'
import { useDatesFormatter } from '@modules/marketing/services/DatesFormatter'
import { useCalendarStore } from '@modules/marketing/store/CalendarStore'

import styles from './styles.module.scss'

const EventItemCard = ({
  id,
  date,
  timeStart,
  timeEnd,
  image,
  shortTitle,
  address,
}: IEventItemProps) => {
  const navigate = useNavigate()
  const calendarStore = useCalendarStore()
  const { day, weekDay, monthRod } = useDatesFormatter(date)

  const handleClick = useCallback(async () => {
    await calendarStore.getCalendarEvent(+id)
    navigate(getRouteById(id, EMainRoutes.MarketingEventCalendar))
  }, [])

  return (
    <div role="button" onClick={handleClick} className={styles.item}>
      <HelperImage
        image={image}
        className={styles.image}
        src="CalendarsDefaultImage.png"
        X2src="CalendarsDefaultImageX2.png"
      />
      <div className={styles.content}>
        <Typography size={18} weight="medium">
          {shortTitle}
        </Typography>
        <div className={styles.eventTime}>
          <div className={styles.date}>
            <IconCalendarLight color={COLORS.GRAY.SAURON} />
            <Typography
              color={
                COLORS.GRAY.GRAY_1
              }>{`${weekDay}, ${day} ${monthRod}`}</Typography>
          </div>
          <div className={styles.time}>
            <IconTimeClockLight color={COLORS.GRAY.SAURON} />
            <Typography
              color={
                COLORS.GRAY.GRAY_1
              }>{`${timeStart} - ${timeEnd}`}</Typography>
          </div>
        </div>
        <div className={styles.address}>
          <IconLocationPinLight color={COLORS.GRAY.SAURON} />
          <Typography color={COLORS.GRAY.GRAY_1}>{address}</Typography>
        </div>
      </div>
    </div>
  )
}

export default memo(EventItemCard)
