import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMarketRegisterClassicLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.083 9.69a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM7.458 12.316a.625.625 0 100-1.25.625.625 0 000 1.25zM10.073 9.69a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM9.448 12.316a.625.625 0 100-1.25.625.625 0 000 1.25zM12.063 9.69a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM11.438 12.316a.625.625 0 100-1.25.625.625 0 000 1.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2.203C2 1.54 2.539 1 3.203 1h3.594C7.46 1 8 1.539 8 2.203v.594C8 3.46 7.461 4 6.797 4H5v1h1.5A1.5 1.5 0 018 6.5V7h1V4.736c0-.615.71-.958 1.192-.575l.503.4.435-.478a.5.5 0 01.74 0l.435.479.504-.4A.734.734 0 0114 4.735V13a2 2 0 01-2 2H4a2 2 0 01-2-2V6.5A1.5 1.5 0 013.5 5H4V4h-.797A1.203 1.203 0 012 2.797v-.594zM6.797 3C6.909 3 7 2.91 7 2.797v-.594A.203.203 0 006.797 2H3.203A.203.203 0 003 2.203v.594c0 .112.09.203.203.203h3.594zM10 7h2c.364 0 .706.097 1 .268V5.287l-.439.349a.5.5 0 01-.681-.055l-.38-.419-.38.419a.5.5 0 01-.681.055L10 5.286V7zm-7-.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 00.5.5H12a1 1 0 011 1v4a1 1 0 01-1 1H4a1 1 0 01-1-1V6.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.412 12.219a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.662 15.469a.75.75 0 100-1.5.75.75 0 000 1.5zM13.227 12.219a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.5 15.469a.75.75 0 100-1.5.75.75 0 000 1.5zM16.065 12.219a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM15.315 15.469a.75.75 0 100-1.5.75.75 0 000 1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.498a1.5 1.5 0 011.5-1.5h5a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5H6V6h2.25C9.216 6 10 6.784 10 7.75V9h1V5.604a.813.813 0 011.281-.664l1.067.755.83-.699a.5.5 0 01.644 0l.83.699 1.066-.755A.813.813 0 0118 5.604V16a2 2 0 01-2 2H4a2 2 0 01-2-2V7.75C2 6.784 2.783 6 3.75 6H5V4.998H3.5a1.5 1.5 0 01-1.5-1.5v-1zm1.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-5zm13.5 8.99V16a1 1 0 01-1 1H4a1 1 0 01-1-1V7.75A.75.75 0 013.75 7h4.5a.75.75 0 01.75.75V9.5a.5.5 0 00.5.5H16a1 1 0 011 .987zM12 9h4c.364 0 .706.097 1 .268V5.966l-1.086.768a.5.5 0 01-.611-.026l-.803-.676-.803.676a.5.5 0 01-.61.026L12 5.966V9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketRegisterClassicLight)
