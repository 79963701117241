import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeTriangleAUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.158 12a1.25 1.25 0 01-1.056-1.919l3.842-6.065a1.25 1.25 0 012.112 0l3.841 6.065a1.25 1.25 0 01-1.056 1.92H4.158zm.455-1.5h6.774L8 5.152 4.613 10.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.38 14a1.35 1.35 0 01-1.118-2.107l4.62-6.829a1.35 1.35 0 012.236 0l4.62 6.83A1.35 1.35 0 0114.62 14H5.38zm.283-1.5h8.674L10 6.088 5.663 12.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.437 18.75c-1.386 0-2.222-1.535-1.47-2.7L10.53 5.893a1.75 1.75 0 012.94 0l6.563 10.157c.753 1.165-.083 2.7-1.47 2.7H5.438zm-.21-1.886a.25.25 0 00.21.386h13.127a.25.25 0 00.21-.386L12.21 6.707a.25.25 0 00-.42 0L5.227 16.864z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.7 25c-2.174 0-3.488-2.403-2.315-4.234l9.3-14.507c1.082-1.688 3.548-1.688 4.63 0l9.3 14.507c1.173 1.83-.142 4.234-2.316 4.234H6.7zm-.631-3.154A.75.75 0 006.7 23h18.6a.75.75 0 00.63-1.154L16.632 7.338a.75.75 0 00-1.263 0l-9.3 14.508z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.327 51c-4.352 0-6.98-4.813-4.627-8.474l19.674-30.603c2.165-3.367 7.088-3.367 9.253 0L56.3 42.526c2.353 3.66-.275 8.474-4.627 8.474H12.328zm-2.103-6.852C9.154 45.813 10.349 48 12.327 48h39.346c1.978 0 3.173-2.188 2.103-3.852L34.103 13.546c-.984-1.531-3.222-1.531-4.206 0L10.224 44.148z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleAUpRegular)
