import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCollapseTrianglesHClosedLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.505 9.202a1.7 1.7 0 000-2.404l-3.31-3.31A.7.7 0 002 3.983v8.034a.7.7 0 001.195.495l3.31-3.31zm-.707-1.697a.7.7 0 010 .99L3 11.293V4.707l2.798 2.798zM9.495 9.202a1.7 1.7 0 010-2.404l3.31-3.31A.7.7 0 0114 3.983v8.034a.7.7 0 01-1.195.495l-3.31-3.31zm.707-1.697a.7.7 0 000 .99L13 11.293V4.707l-2.798 2.798z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.393 11.415a2 2 0 000-2.829L4.707 4.9C4.077 4.27 3 4.717 3 5.607v8.786c0 .891 1.077 1.337 1.707.707l3.686-3.685zm-.707-2.122a1 1 0 010 1.415L4 14.393V5.607l3.686 3.686zM11.607 11.415a2 2 0 010-2.829L15.293 4.9c.63-.63 1.707-.183 1.707.707v8.786c0 .891-1.077 1.337-1.707.707l-3.686-3.685zm.707-2.122a1 1 0 000 1.415L16 14.393V5.607l-3.686 3.686z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesHClosedLight)
