import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMailArrowOutRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 2A2.75 2.75 0 000 4.75v5.5A2.75 2.75 0 002.75 13h1.5a.75.75 0 000-1.5h-1.5c-.69 0-1.25-.56-1.25-1.25v-5.5c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v1.5a.75.75 0 001.5 0v-1.5A2.75 2.75 0 0011.25 2h-8.5z"
          fill="currentColor"
        />
        <path
          d="M3.712 4.852a.75.75 0 10-.89 1.207l2.585 1.908a2.75 2.75 0 003.3-.025l2.48-1.89a.75.75 0 10-.909-1.193l-2.48 1.89a1.25 1.25 0 01-1.5.011L3.712 4.852zM10.7 12a3.2 3.2 0 00-3.2 3.2v.05a.75.75 0 01-1.5 0v-.05a4.7 4.7 0 014.7-4.7h2.936L12.26 9.319a.75.75 0 01.978-1.138l2.026 1.742a1.75 1.75 0 010 2.654L13.24 14.32a.75.75 0 11-.978-1.138L13.636 12H10.7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.249 4.001a3.25 3.25 0 00-3.25 3.25v5.498a3.25 3.25 0 003.25 3.25H7.25a.75.75 0 000-1.5H5.249a1.75 1.75 0 01-1.75-1.75V7.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v.75a.75.75 0 001.5 0v-.75a3.25 3.25 0 00-3.25-3.25H5.25z"
          fill="currentColor"
        />
        <path
          d="M6.036 7.105a.75.75 0 10-.931 1.176l2.878 2.279a3.25 3.25 0 004.034 0l2.878-2.279a.75.75 0 00-.93-1.176l-2.879 2.279a1.75 1.75 0 01-2.172 0L6.036 7.105zM13.7 15a3.2 3.2 0 00-3.2 3.2v.05a.75.75 0 01-1.5 0v-.05a4.7 4.7 0 014.7-4.7h3.985l-2.183-1.94a.75.75 0 01.996-1.12l2.817 2.502a1.75 1.75 0 010 2.616l-2.817 2.503a.75.75 0 11-.996-1.122L17.685 15H13.7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 4.25A3.75 3.75 0 002.25 8v8A3.75 3.75 0 006 19.75h3a.75.75 0 000-1.5H6A2.25 2.25 0 013.75 16V8A2.25 2.25 0 016 5.75h12A2.25 2.25 0 0120.25 8v3a.75.75 0 001.5 0V8A3.75 3.75 0 0018 4.25H6z"
          fill="currentColor"
        />
        <path
          d="M6.742 7.934a.75.75 0 10-.984 1.132l3.781 3.288a3.75 3.75 0 004.922 0l3.781-3.288a.75.75 0 10-.984-1.132l-3.782 3.288a2.25 2.25 0 01-2.952 0L6.742 7.934zM17.5 17.75a3.75 3.75 0 00-3.75 3.75v.5a.75.75 0 01-1.5 0v-.5c0-2.9 2.35-5.25 5.25-5.25h4.185l-2.183-1.94a.75.75 0 11.996-1.12l2.817 2.502a1.75 1.75 0 010 2.616l-2.817 2.503a.75.75 0 11-.996-1.122l2.183-1.939H17.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 5A5.5 5.5 0 002 10.5v11A5.5 5.5 0 007.5 27H12a1 1 0 100-2H7.5A3.5 3.5 0 014 21.5v-11A3.5 3.5 0 017.5 7h17a3.5 3.5 0 013.5 3.5V14a1 1 0 102 0v-3.5A5.5 5.5 0 0024.5 5h-17z"
          fill="currentColor"
        />
        <path
          d="M8.659 10.247a1 1 0 00-1.318 1.506l5.037 4.407a5.5 5.5 0 007.244 0l5.037-4.407a1 1 0 00-1.317-1.506l-5.037 4.408a3.5 3.5 0 01-4.61 0l-5.037-4.408zM23 24a5 5 0 00-5 5v1a1 1 0 11-2 0v-1a7 7 0 017-7h6.086l-3.293-3.293a1 1 0 011.414-1.414l3.94 3.94a2.5 2.5 0 010 3.535l-3.94 3.94a1 1 0 01-1.414-1.415L29.086 24H23z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16.5 9C10.701 9 6 13.701 6 19.5v21C6 46.299 10.701 51 16.5 51h10a1.5 1.5 0 000-3h-10A7.5 7.5 0 019 40.5v-21a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v8a1.5 1.5 0 003 0v-8C58 13.701 53.299 9 47.5 9h-31z"
        fill="currentColor"
      />
      <path
        d="M16.47 18.856a1.5 1.5 0 00-1.94 2.288l10.677 9.06a10.5 10.5 0 0013.586 0l10.678-9.06a1.5 1.5 0 00-1.941-2.288l-10.678 9.06a7.5 7.5 0 01-9.704 0l-10.677-9.06zM32 58.5C32 51.044 38.044 45 45.5 45h14.604l-7.94-7.94a1.5 1.5 0 012.122-2.12l8.378 8.378a4.5 4.5 0 010 6.364l-8.378 8.379a1.5 1.5 0 01-2.122-2.122L60.104 48H45.5C39.701 48 35 52.701 35 58.5v2a1.5 1.5 0 01-3 0v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMailArrowOutRegular)
