import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoTelegramLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.873 7.674a987.718 987.718 0 007.956-3.415l1.518-.657a.5.5 0 01.694.52c-.35 2.814-.715 5.625-1.178 8.422a.494.494 0 01-.752.338c-1.113-.701-2.208-1.423-3.306-2.147l-.327-.216c-.855-.563-.497-.908-.02-1.368.077-.074.157-.151.236-.233.022-.023.098-.094.21-.2l.24-.223A95.39 95.39 0 009.86 6.872c.18-.177.335-.333.442-.45.097-.106.156-.18.163-.21.041-.177-.114-.23-.255-.198a1.213 1.213 0 00-.173.097 25.95 25.95 0 00-.515.33 223.9 223.9 0 00-2.24 1.491l-1.314.885c-.4.275-.764.41-1.09.403-.651-.014-1.328-.225-1.986-.468a22.846 22.846 0 01-.515-.197l-.03-.011-.2-.077c-.214-.083-.222-.393-.01-.482a481.337 481.337 0 00.641-.27l.095-.04zm3.09 2.296c-.345.16-.716.258-1.107.25-1.019-.023-2.06-.428-2.85-.735a60.48 60.48 0 00-.218-.085C.76 9.004.68 7.512 1.75 7.063c2.9-1.22 5.789-2.47 8.682-3.721l1.52-.658a1.5 1.5 0 012.083 1.563c-.352 2.814-.718 5.642-1.185 8.46a1.494 1.494 0 01-2.271 1.021c-1.122-.707-2.226-1.434-3.323-2.158l-.328-.216c-.3-.198-.613-.457-.8-.816a1.43 1.43 0 01-.163-.568z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.557 12.066c-.513.303-1.07.5-1.652.488-1.225-.027-2.482-.515-3.474-.901l-.268-.104C1.08 11.133.999 9.563 2.12 9.09 5.74 7.57 9.344 6.011 12.953 4.45l1.893-.82a1.625 1.625 0 012.257 1.693c-.439 3.51-.895 7.032-1.476 10.541a1.618 1.618 0 01-2.46 1.105c-1.396-.88-2.77-1.786-4.138-2.688l-.41-.27c-.361-.238-.71-.534-.915-.925a1.507 1.507 0 01-.147-1.02zm-4.46-2.3c3.426-1.446 6.84-2.923 10.254-4.4l1.892-.818a.624.624 0 01.867.65c-.438 3.508-.892 7.014-1.47 10.502a.618.618 0 01-.94.422c-1.387-.874-2.752-1.775-4.122-2.678l-.408-.269c-1.066-.702-.62-1.132-.025-1.706.096-.093.197-.19.295-.291.044-.046.258-.246.561-.527l.837-.78c.52-.488 1.08-1.02 1.485-1.424.147-.148.274-.278.37-.384.12-.13.194-.223.203-.261.052-.221-.142-.287-.318-.247-.02.004-.092.045-.216.121-.105.065-.248.156-.427.272-.395.255-.968.634-1.721 1.137-.782.522-1.758 1.178-2.927 1.967-.5.344-.954.511-1.36.502-.924-.02-1.89-.356-2.814-.71l-.038-.015-.266-.103-.04-.015-.247-.095c-.268-.104-.278-.492-.013-.603a854.5 854.5 0 00.548-.231l.04-.017z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoTelegramLight)
