import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import FailCard from '@lib/components/FailCard'
import { EMainRoutes } from '@lib/navigation'

import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

const FailConfiguratorCard = () => {
  const navigate = useNavigate()
  const configuratorStore = useConfiguratorStore()

  const handleClick = useCallback(() => {
    configuratorStore.resetAllData()
    navigate(EMainRoutes.ConfiguratorCreate)
  }, [])

  return (
    <FailCard
      description="Во время регистрации произошла ошибка, попробуйте зарегистрироваться повторно."
      titleButton="Повторить создание конфигурации"
      handleClick={handleClick}
    />
  )
}

export default memo(FailConfiguratorCard)
