import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconIdCardRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1V6a1 1 0 00-1-1H5zm0 1h2v2H5V6z"
          fill="currentColor"
        />
        <path
          d="M9 5.5a.5.5 0 01.5-.5H12a.5.5 0 110 1H9.5a.5.5 0 01-.5-.5zM9.5 7a.5.5 0 000 1H12a.5.5 0 100-1H9.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 2A2.75 2.75 0 001 4.75v5.5A2.75 2.75 0 003.75 13h8.5A2.75 2.75 0 0015 10.25v-5.5A2.75 2.75 0 0012.25 2h-8.5zM2.5 4.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 7.002c-.69 0-1.25.56-1.25 1.25v2.5c0 .69.56 1.25 1.25 1.25h2.5c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25h-2.5zm.25 3.5v-2h2v2h-2z"
          fill="currentColor"
        />
        <path
          d="M11 8.25a.75.75 0 01.75-.75h2.75a.75.75 0 010 1.5h-2.75a.75.75 0 01-.75-.75zM11.75 10.5a.75.75 0 100 1.5h2.75a.75.75 0 000-1.5h-2.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.249 4.001a3.25 3.25 0 00-3.25 3.25v5.498a3.25 3.25 0 003.25 3.25h9.502a3.25 3.25 0 003.25-3.25V7.25a3.25 3.25 0 00-3.25-3.25H5.25zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v5.498a1.75 1.75 0 01-1.75 1.75H5.25a1.75 1.75 0 01-1.75-1.75V7.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 9.001c-.69 0-1.25.56-1.25 1.25v3.5c0 .69.56 1.25 1.25 1.25h3.5c.69 0 1.25-.56 1.25-1.25v-3.5c0-.69-.56-1.25-1.25-1.25h-3.5zm.25 4.5v-3h3v3h-3z"
          fill="currentColor"
        />
        <path
          d="M13.25 10.25A.75.75 0 0114 9.5h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM14 12.5a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4.25A3.75 3.75 0 002.25 8v8A3.75 3.75 0 006 19.75h12A3.75 3.75 0 0021.75 16V8A3.75 3.75 0 0018 4.25H6zM3.75 8A2.25 2.25 0 016 5.75h12A2.25 2.25 0 0120.25 8v8A2.25 2.25 0 0118 18.25H6A2.25 2.25 0 013.75 16V8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 12a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2H9zm0 2h4v4H9v-4z"
          fill="currentColor"
        />
        <path
          d="M17 14a1 1 0 011-1h6.5a1 1 0 110 2H18a1 1 0 01-1-1zM18 17a1 1 0 100 2h6.5a1 1 0 100-2H18z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 5A5.5 5.5 0 002 10.5v11A5.5 5.5 0 007.5 27h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 5h-17zM4 10.5A3.5 3.5 0 017.5 7h17a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 21.5v-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 24a4.5 4.5 0 00-4.5 4.5v7a4.5 4.5 0 004.5 4.5h7a4.5 4.5 0 004.5-4.5v-7a4.5 4.5 0 00-4.5-4.5h-7zM17 28.5a1.5 1.5 0 011.5-1.5h7a1.5 1.5 0 011.5 1.5v7a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 01-1.5-1.5v-7z"
        fill="currentColor"
      />
      <path
        d="M35 28.5a1.5 1.5 0 011.5-1.5h12a1.5 1.5 0 010 3h-12a1.5 1.5 0 01-1.5-1.5zM36.5 34a1.5 1.5 0 000 3h12a1.5 1.5 0 000-3h-12z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 11C10.701 11 6 15.701 6 21.5v21C6 48.299 10.701 53 16.5 53h31C53.299 53 58 48.299 58 42.5v-21C58 15.701 53.299 11 47.5 11h-31zM9 21.5a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5h-31A7.5 7.5 0 019 42.5v-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconIdCardRegular)
