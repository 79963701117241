import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyCurrencyRubleRectLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 8h2a2 2 0 100-4H6.69a.69.69 0 00-.69.69V7h-.5a.5.5 0 000 1H6v1h-.5a.5.5 0 000 1H6v.75a.5.5 0 001 0V10h2a.5.5 0 000-1H7V8zm0-3v2h2a1 1 0 000-2H7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.5A2.5 2.5 0 013.5 2h9A2.5 2.5 0 0115 4.5v6a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 011 10.5v-6zM3.5 3A1.5 1.5 0 002 4.5v6A1.5 1.5 0 003.5 12h9a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0012.5 3h-9z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11h2a2.5 2.5 0 000-5H8.714A.714.714 0 008 6.714V10h-.5a.5.5 0 000 1H8v1h-.5a.5.5 0 000 1H8v.75a.5.5 0 001 0V13h2.5a.5.5 0 000-1H9v-1zm0-4v3h2a1.5 1.5 0 000-3H9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3-2a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2H5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleRectLight)
