import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCertRosetteLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.625a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75zM5.625 6a2.375 2.375 0 114.75 0 2.375 2.375 0 01-4.75 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.375 5.95a5.625 5.625 0 119.757 3.816l1.806 2.58a1 1 0 01-.245 1.393l-2.536 1.775a1 1 0 01-1.392-.245L8 12.749l-1.765 2.52a1 1 0 01-1.392.245L2.307 13.74a1 1 0 01-.245-1.393l1.806-2.58A5.605 5.605 0 012.375 5.95zM8 1.325a4.625 4.625 0 100 9.25 4.625 4.625 0 000-9.25zm.388 10.236a5.596 5.596 0 002.997-1.118l1.734 2.477-2.535 1.775L8.416 11.6a.489.489 0 00-.028-.039zm-3.773-1.118a5.596 5.596 0 002.997 1.118.489.489 0 00-.028.039l-2.168 3.095-2.535-1.775 1.734-2.477z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.806a3.846 3.846 0 100 7.692 3.846 3.846 0 000-7.692zM7.154 7.652a2.846 2.846 0 115.692 0 2.846 2.846 0 01-5.692 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.623 7.627a6.377 6.377 0 1111.018 4.374l1.885 2.69a1.5 1.5 0 01-.369 2.09l-2.22 1.555a1.5 1.5 0 01-2.09-.369L10 15.33l-1.848 2.638a1.5 1.5 0 01-2.089.369l-2.22-1.555a1.5 1.5 0 01-.369-2.09l1.884-2.69a6.355 6.355 0 01-1.735-4.374zM10 2.25a5.377 5.377 0 100 10.755A5.377 5.377 0 0010 2.25zm.201 11.752a6.348 6.348 0 003.694-1.324l1.812 2.587a.5.5 0 01-.123.697l-2.22 1.555a.5.5 0 01-.697-.123l-2.255-3.22a.497.497 0 00-.21-.172zm-4.096-1.324A6.347 6.347 0 009.8 14.002a.498.498 0 00-.211.172l-2.255 3.22a.5.5 0 01-.696.123l-2.22-1.555a.5.5 0 01-.124-.697l1.812-2.587z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCertRosetteLight)
