import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArchiveBoxLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 8.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2a1 1 0 00-1 1v2a1 1 0 001 1v6a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 001-1V3a1 1 0 00-1-1H2zm11 4H3v6a1 1 0 001 1h8a1 1 0 001-1V6zm1-1V3H2v2h12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M7 10.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 3C2.56 3 2 3.56 2 4.25v2.5c0 .605.43 1.11 1 1.225v7.775A2.25 2.25 0 005.25 18h9.5A2.25 2.25 0 0017 15.75V7.975c.57-.116 1-.62 1-1.225v-2.5C18 3.56 17.44 3 16.75 3H3.25zM16 8H4v7.75c0 .69.56 1.25 1.25 1.25h9.5c.69 0 1.25-.56 1.25-1.25V8zm.75-1a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25H3.25a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h13.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveBoxLight)
