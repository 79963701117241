import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiMenuShapeSquare4Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <rect
          x={2.5}
          y={2.5}
          width={4}
          height={4}
          rx={0.75}
          stroke="#222"
          strokeLinejoin="round"
        />
        <rect
          x={2.5}
          y={9.5}
          width={4}
          height={4}
          rx={0.75}
          stroke="#222"
          strokeLinejoin="round"
        />
        <rect
          x={9.5}
          y={2.5}
          width={4}
          height={4}
          rx={0.75}
          stroke="#222"
          strokeLinejoin="round"
        />
        <rect
          x={9.5}
          y={9.5}
          width={4}
          height={4}
          rx={0.75}
          stroke="#222"
          strokeLinejoin="round"
        />
        <path stroke="red" strokeOpacity={0.2} d="M.5.5h15v15H.5z" />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path stroke="red" strokeOpacity={0.2} d="M.5.5h19v19H.5z" />
    </svg>
  )
}

export default observer(IconUiMenuShapeSquare4Light)
