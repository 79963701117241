import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconStackHDownRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.5.95a.55.55 0 100 1.1h5a.55.55 0 100-1.1h-5zM2.95 3.5a.55.55 0 01.55-.55h9a.55.55 0 110 1.1h-9a.55.55 0 01-.55-.55z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 5A2.5 2.5 0 001 7.5v5A2.5 2.5 0 003.5 15h9a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0012.5 5h-9zm9 1.5h-9a1 1 0 00-1 1v5a1 1 0 001 1h9a1 1 0 001-1v-5a1 1 0 00-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7 1.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H7zM4.25 5A.75.75 0 015 4.25h10a.75.75 0 010 1.5H5A.75.75 0 014.25 5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7a3 3 0 00-3 3v5a3 3 0 003 3h10a3 3 0 003-3v-5a3 3 0 00-3-3H5zm10 1.5H5A1.5 1.5 0 003.5 10v5A1.5 1.5 0 005 16.5h10a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0015 8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 2a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zM5.25 5.75A.75.75 0 016 5h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 11.75A3.75 3.75 0 015.75 8h12.5A3.75 3.75 0 0122 11.75v6.5A3.75 3.75 0 0118.25 22H5.75A3.75 3.75 0 012 18.25v-6.5zM5.75 9.5a2.25 2.25 0 00-2.25 2.25v6.5a2.25 2.25 0 002.25 2.25h12.5a2.25 2.25 0 002.25-2.25v-6.5a2.25 2.25 0 00-2.25-2.25H5.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 2a1 1 0 100 2h10a1 1 0 100-2H11zM7 7a1 1 0 011-1h16a1 1 0 110 2H8a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 15a5 5 0 015-5h16a5 5 0 015 5v9a5 5 0 01-5 5H8a5 5 0 01-5-5v-9zm5-3a3 3 0 00-3 3v9a3 3 0 003 3h16a3 3 0 003-3v-9a3 3 0 00-3-3H8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M22 7a1.5 1.5 0 000 3h20a1.5 1.5 0 000-3H22zM14.5 14.5A1.5 1.5 0 0116 13h32a1.5 1.5 0 010 3H16a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 28.5a9.5 9.5 0 019.5-9.5h33a9.5 9.5 0 019.5 9.5v20a9.5 9.5 0 01-9.5 9.5h-33A9.5 9.5 0 016 48.5v-20zm9.5-6.5A6.5 6.5 0 009 28.5v20a6.5 6.5 0 006.5 6.5h33a6.5 6.5 0 006.5-6.5v-20a6.5 6.5 0 00-6.5-6.5h-33z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconStackHDownRegular)
