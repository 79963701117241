import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetCloudArrowDownloadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.375 1.5a2.875 2.875 0 00-2.863 2.606.75.75 0 01-.631.672A2.376 2.376 0 004.25 9.5a.75.75 0 010 1.5 3.875 3.875 0 01-1.146-7.578 4.376 4.376 0 017.946-1.421L11.125 2a4.5 4.5 0 01.125 9 .75.75 0 01-.75-.75v-.002a.75.75 0 01.725-.75 3 3 0 10-.495-5.972.75.75 0 01-.771-.414A2.875 2.875 0 007.375 1.5z"
          fill="currentColor"
        />
        <path
          d="M8 5.25a.75.75 0 01.75.75v7.94l1.72-1.72a.75.75 0 111.06 1.06l-2.116 2.116a2 2 0 01-2.828 0L4.47 13.28a.75.75 0 111.06-1.06l1.72 1.72V6A.75.75 0 018 5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.125 1.5a3.875 3.875 0 00-3.868 3.636.75.75 0 01-.592.689 3.377 3.377 0 00.607 6.673.75.75 0 01.728.75v.002a.75.75 0 01-.83.746 4.875 4.875 0 01-1.35-9.493A5.376 5.376 0 0113.948 3H14a5.5 5.5 0 01.578 10.97.75.75 0 01-.156-1.492 4 4 0 10-.871-7.953.75.75 0 01-.787-.487A3.877 3.877 0 009.125 1.5z"
          fill="currentColor"
        />
        <path
          d="M10 7a.75.75 0 01.75.75v10.19l2.22-2.22a.75.75 0 111.06 1.06l-2.616 2.616a2 2 0 01-2.828 0L5.97 16.78a.75.75 0 111.06-1.06l2.22 2.22V7.75A.75.75 0 0110 7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.95 1.75a4.8 4.8 0 00-4.791 4.505.75.75 0 01-.592.688 4.202 4.202 0 00.755 8.305.75.75 0 01.728.75V16a.75.75 0 01-.835.745A5.7 5.7 0 014.719 5.618a6.301 6.301 0 0111.925-1.766l.156-.002a6.45 6.45 0 01.678 12.865.75.75 0 01-.156-1.492 4.95 4.95 0 10-1.078-9.842.75.75 0 01-.787-.487A4.802 4.802 0 0010.95 1.75z"
          fill="currentColor"
        />
        <path
          d="M12 8.25a.75.75 0 01.75.75v12.89l2.92-2.92a.75.75 0 011.06 1.06l-3.14 3.14a2.25 2.25 0 01-3.181 0l-3.14-3.14a.75.75 0 111.061-1.06l2.92 2.92V9a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.125 3a6.876 6.876 0 00-6.82 6.005 1 1 0 01-.796.855 5.625 5.625 0 001 11.138l1.492.002a1 1 0 11-.002 2l-1.451-.001a1.079 1.079 0 01-.119-.002A7.625 7.625 0 016.434 8.07C7.268 4.034 10.842 1 15.125 1a8.874 8.874 0 017.871 4.772A8.626 8.626 0 0122.5 23H22a1 1 0 110-2H22.484a6.625 6.625 0 00-.108-13.25h-.02a1 1 0 01-.918-.603A6.877 6.877 0 0015.125 3z"
          fill="currentColor"
        />
        <path
          d="M16 13a1 1 0 011 1v15.586l4.293-4.293a1 1 0 011.414 1.414l-4.303 4.303a3.4 3.4 0 01-4.808 0l-4.303-4.303a1 1 0 111.414-1.414L15 29.586V14a1 1 0 011-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M29.5 5c-6.795 0-12.374 5.215-12.95 11.86a1.5 1.5 0 01-1.215 1.343C10.02 19.215 6 23.89 6 29.5 6 35.851 11.149 41 17.5 41h3a1.5 1.5 0 010 3h-3C9.492 44 3 37.508 3 29.5c0-6.692 4.532-12.323 10.694-13.995C14.892 7.853 21.511 2 29.5 2c6.333 0 11.803 3.679 14.396 9.01.2-.006.402-.01.604-.01C53.613 11 61 18.387 61 27.5S53.613 44 44.5 44h-2a1.5 1.5 0 010-3h2C51.956 41 58 34.956 58 27.5S51.956 14 44.5 14c-.471 0-.937.024-1.395.071a1.5 1.5 0 01-1.543-.931C39.636 8.365 34.959 5 29.5 5z"
        fill="currentColor"
      />
      <path
        d="M31.5 25.5A1.5 1.5 0 0133 27v32.249a2.23 2.23 0 00.09-.086l9.85-9.849a1.5 1.5 0 012.12 2.122l-9.848 9.848a5.25 5.25 0 01-7.424 0l-9.849-9.848a1.5 1.5 0 012.122-2.122l9.848 9.849.09.086V27a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudArrowDownloadRegular)
