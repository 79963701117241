import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMarketShoppingCartLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.5 2a.5.5 0 000 1h1.463c.36 0 .67.257.737.612l.182.971a.492.492 0 00.003.019l.818 4.359A2.5 2.5 0 006.16 11h5.278a2.5 2.5 0 002.426-1.894l.888-3.553A1.25 1.25 0 0013.54 4H3.79l-.107-.572A1.75 1.75 0 001.963 2H.5zm4.186 6.776L3.977 5h9.562a.25.25 0 01.243.31l-.888 3.554A1.5 1.5 0 0111.438 10H6.16a1.5 1.5 0 01-1.474-1.224z"
          fill="currentColor"
        />
        <path
          d="M6.752 13.5a1.252 1.252 0 11-2.504 0 1.252 1.252 0 012.504 0zM12.751 13.5a1.251 1.251 0 11-2.503 0 1.251 1.251 0 012.503 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.833 3a.5.5 0 000 1h2.332a1 1 0 01.985.83l.13.747.003.018.854 4.918A3 3 0 008.093 13h6.447a3 3 0 002.898-2.224l1.041-3.888A1.5 1.5 0 0017.03 5H5.195l-.06-.342A2 2 0 003.166 3H.833zm5.29 7.342L5.368 6H17.03a.5.5 0 01.483.63l-1.041 3.888A2 2 0 0114.54 12H8.093a2 2 0 01-1.97-1.658zM5 16a2 2 0 114 0 2 2 0 01-4 0zm2-1a1 1 0 100 2 1 1 0 000-2zM13 16a2 2 0 114 0 2 2 0 01-4 0zm2-1a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketShoppingCartLight)
