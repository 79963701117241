import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDataChartPieA1Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a.5.5 0 00-.5.5v6a.5.5 0 00.75.433l5.196-3a.5.5 0 00.183-.683A6.5 6.5 0 007.5 1zM8 6.634V2.023a5.5 5.5 0 013.993 2.305L8 6.634z"
          fill="currentColor"
        />
        <path
          d="M5.683 2.309a.5.5 0 00-.33-.944A6.5 6.5 0 1013.91 6.42a.5.5 0 00-.986.166A5.5 5.5 0 115.683 2.31z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a.5.5 0 00-.5.5v7a.5.5 0 00.75.433l6.062-3.5a.5.5 0 00.183-.683A7.5 7.5 0 009.5 2zm.5 6.634V3.019a6.5 6.5 0 014.863 2.808L10 8.634z"
        fill="currentColor"
      />
      <path
        d="M7.225 3.411a.5.5 0 10-.35-.937 7.5 7.5 0 1010.047 5.95.5.5 0 00-.99.143 6.5 6.5 0 11-8.707-5.156z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartPieA1Light)
