import { memo } from 'react'
import { Link } from 'react-router-dom'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'

/**
 * @prop link Ссылка на страницу.
 * @prop className Класснейм.
 * @prop title Название страницы.
 * @prop icon Иконка ссылки.
 */

export type TLinkItemProps = {
  link?: string
  className?: string
  title?: string
  icon?: React.ReactNode
}

const LinkItem = ({ link, title, className, icon }: TLinkItemProps) => (
  <Typography component="span" size={14} color={COLORS.GRAY.SAURON}>
    <Link className={cx(styles.link, className)} to={link}>
      {title}
      {icon}
    </Link>
  </Typography>
)

export default memo(LinkItem)
