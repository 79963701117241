import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLogoYandexZenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1a3 3 0 00-3 3v7a3 3 0 003 3h7a3 3 0 003-3V4a3 3 0 00-3-3H4zM2 4a2 2 0 012-2h3.311c-.049 2.123-.252 3.364-1.1 4.212-.847.847-2.088 1.05-4.211 1.1V4zm0 3.688V11a2 2 0 002 2h3.311c-.049-2.123-.252-3.364-1.1-4.212-.847-.847-2.088-1.05-4.211-1.1zM7.688 13H11a2 2 0 002-2V7.688c-2.124.05-3.364.253-4.212 1.1-.848.848-1.05 2.089-1.1 4.212zM13 7.312V4a2 2 0 00-2-2H7.688c.05 2.123.252 3.364 1.1 4.212.848.847 2.088 1.05 4.212 1.1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2A3.5 3.5 0 002 5.5v8A3.5 3.5 0 005.5 17h8a3.5 3.5 0 003.5-3.5v-8A3.5 3.5 0 0013.5 2h-8zM3 5.5A2.5 2.5 0 015.5 3h3.782c-.054 2.528-.285 3.997-1.285 4.997S5.528 9.228 3 9.282V5.5zm0 4.218V13.5A2.5 2.5 0 005.5 16h3.782c-.054-2.528-.285-3.997-1.285-4.997S5.528 9.772 3 9.718zM9.718 16H13.5a2.5 2.5 0 002.5-2.5V9.718c-2.528.054-3.997.285-4.997 1.285S9.772 13.472 9.718 16zM16 9.282V5.5A2.5 2.5 0 0013.5 3H9.718c.054 2.528.285 3.997 1.285 4.997S13.472 9.228 16 9.282z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoYandexZenLight)
