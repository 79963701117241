import _, { find, forEach, get, isEmpty } from 'lodash'
import { makeAutoObservable } from 'mobx'
import hash from 'object-hash'
import { container, singleton } from 'tsyringe'

import { EStatusProcess } from '@lib/form'

import { ETubs } from '../enums'
import {
  IAccessory,
  ICart,
  IComplectation,
  IComponent,
  IComponentsTypes,
  IInitData,
  IModals,
  IOrder,
  IPlatformType,
  IProduct,
  IProductSeries,
  IProductType,
  ISavedComponent,
  ISavedPlatformTypes,
  ITabs,
} from '../models/Configurator'
import { ConfiguratorRest } from '../services/ConfiguratorRest'

const initData: IInitData = {
  tabs: {
    currentTab: 0,
    disabledTabs: [1, 2, 3],
  },
  cart: {
    complectations: [],
    comment: '',
  },
  accessories: undefined,
  selectedData: {
    productType: undefined,
    productSeries: undefined,
    platforms: {},
    accessories: {},
  },
  platformTypes: {},
}

@singleton()
export class ConfiguratorStore {
  private configuratorRest = container.resolve(ConfiguratorRest)

  isFetching = true
  status: EStatusProcess = EStatusProcess.IDLE

  tabs: ITabs = initData.tabs

  modals: IModals = {
    cart: false,
    existComplectation: false,
  }
  accessories = initData.accessories
  hashToEdit: string
  hashToDeleteComplectation: string
  componentTypes: IComponentsTypes[] = []
  productTypes: IProductType[]
  cart: ICart = initData.cart
  selectedData: IProduct = initData.selectedData
  platformTypes: ISavedPlatformTypes = initData.platformTypes
  isNewProduct = false
  orders: IOrder[] = []
  order: IOrder

  constructor() {
    makeAutoObservable<ConfiguratorStore, 'configuratorRest'>(this, {
      configuratorRest: false,
    })
  }

  get enabledBlocker(): boolean {
    const { currentTab } = this.tabs
    const hasSelectedSeries = !isEmpty(this.selectedSeries)
    const hasComplectations = !isEmpty(this.cart.complectations)

    return (
      (currentTab >= ETubs.SERIES && !hasComplectations) ||
      (currentTab >= ETubs.PRODUCT_TYPE && hasComplectations) ||
      hasSelectedSeries
    )
  }

  calculateAccessories(): void {
    if (!this.platformTypes) return

    const uniqueAccessories = [
      ...new Map(
        Object.values(this.platformTypes)
          .flat()
          .map((item) => [item['key'], item]),
      ).values(),
    ]

    const accessoriesMap = _(uniqueAccessories)
      .groupBy('type')
      .mapValues((group) => _.uniqBy(group, 'key'))
      .value()

    this.accessories = accessoriesMap
  }

  get series(): IProductSeries[] | undefined {
    return this.selectedData.productType === undefined
      ? undefined
      : this.productTypes.filter(
          (product) => product.id === this.selectedData.productType.id,
        )[0].types
  }

  get selectedSeries(): IProductSeries | undefined {
    return this.selectedData.productSeries === undefined
      ? undefined
      : find(this.series, { id: this.selectedData.productSeries.id })
  }

  selectProductType(product: IProductType) {
    const [{ platforms, ...seriesBase }] = product.types
    const { types, ...productBase } = product

    this.selectedData.productType = productBase
    this.selectedData.productSeries = seriesBase

    forEach(this.selectedSeries?.platforms, (platform) => {
      forEach(platform.types, (type) => {
        const accessory = get(type, ['components', 0, 'accessories'], undefined)
        const data = {
          parent: platform.title,
          name: get(type, ['components', 0, 'name'], undefined),
          value: get(type, ['components', 0, 'value'], undefined),
          title: type?.title,
        }

        this.setPlatformTypeAccessories(type.key, accessory, data)
      })
    })

    this.calculateAccessories()

    this.selectTab(ETubs.SERIES)
  }

  selectSeries(series: IProductSeries) {
    const { platforms, ...seriesBase } = series
    this.selectedData.productSeries = seriesBase
    this.selectedData.accessories = initData.selectedData.accessories

    this.setProductTypes()
    this.calculateAccessories()
  }

  selectAccessory(type: string, accessory: IAccessory | null) {
    if (accessory) {
      this.selectedData.accessories[type] = accessory
    } else {
      this.selectedData.accessories = _.omit(
        this.selectedData.accessories,
        type,
      )
    }
  }

  setPlatformTypeAccessories(
    key: IPlatformType['key'],
    accessories: IComponent['accessories'],
    value: ISavedComponent,
  ) {
    this.selectedData.platforms[key] = value
    this.platformTypes[key] = accessories
    this.calculateAccessories()

    _.forEach(this.accessories, (value, key) => {
      const selectedAccessoryKey = _.get(
        this.selectedData.accessories,
        `${key}.key`,
      )
      const hasKeyInAccessories = _.some(value, { key: selectedAccessoryKey })

      if (hasKeyInAccessories) return

      // eslint-disable-next-line prefer-destructuring
      this.selectedData.accessories[key] = value[0]
    })
  }

  selectTab(index: number) {
    const disabledIndex = this.tabs.disabledTabs.indexOf(index)
    disabledIndex !== -1 && this.tabs.disabledTabs.splice(disabledIndex, 1)

    if (index === ETubs.CART) {
      const productHash = hash(this.selectedData)

      const otherCartItems = this.cart.complectations.filter(
        (item) => item.hash !== this.hashToEdit,
      )

      const existCartItem = otherCartItems.find(
        (item) => item.hash === productHash,
      )

      const existCartItemOnEdit = this.cart.complectations.find(
        (item) => item.hash === this.hashToEdit,
      )

      if (existCartItem) {
        this.openModal('existComplectation')
        return
      }

      const cartItem: IComplectation = {
        hash: productHash,
        count: 1,
        product: this.selectedData,
      }

      if (!this.cart.complectations.length || this.isNewProduct) {
        this.cart.complectations = [...this.cart.complectations, cartItem]
      } else if (this.hashToEdit && existCartItemOnEdit) {
        this.cart.complectations = [
          ...this.cart.complectations.filter(
            (item) => item.hash !== this.hashToEdit,
          ),
          {
            hash: productHash,
            count: existCartItemOnEdit.count,
            product: this.selectedData,
          },
        ]
        this.hashToEdit = undefined
      } else {
        this.cart.complectations = [
          ...this.cart.complectations.filter(
            (item) => item.hash !== this.cart.complectations.at(-1).hash,
          ),
          cartItem,
        ]
      }

      this.isNewProduct = false
      this.tabs.disabledTabs = [0, 1, 2]
    }
    this.tabs.currentTab = index
  }

  editProduct(hash: string) {
    this.hashToEdit = hash

    const existCartItemOnEdit = this.cart.complectations.find(
      (item) => item.hash === hash,
    )

    this.selectedData = {
      productType: existCartItemOnEdit.product.productType,
      productSeries: existCartItemOnEdit.product.productSeries,
      platforms: existCartItemOnEdit.product.platforms,
      accessories: existCartItemOnEdit.product.accessories,
    }

    const series = this.productTypes.filter(
      (product) => product.id === existCartItemOnEdit.product.productType.id,
    )[0].types

    const selectedSeries = find(series, {
      id: existCartItemOnEdit.product.productSeries.id,
    })

    this.platformTypes = initData.platformTypes

    forEach(selectedSeries.platforms, (platform) => {
      forEach(platform.types, (type) => {
        const component = type.components.find(
          (component) =>
            component.name ===
            existCartItemOnEdit.product.platforms[type.key].name,
        )

        this.selectedData.platforms[type.key] = {
          parent: platform.title,
          name: component.name,
          value: component.value,
          title: type?.title,
        }
        this.platformTypes[type.key] = component.accessories
      })
    })

    this.calculateAccessories()

    _.forEach(this.accessories, (value, key) => {
      const selectedAccessoryKey = _.get(
        existCartItemOnEdit.product.accessories,
        `${key}.key`,
      )

      const hasKeyInAccessories = _.some(value, {
        key: selectedAccessoryKey,
      })

      if (hasKeyInAccessories) return

      if (selectedAccessoryKey) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedData.accessories[key] = value[0]
      }
    })

    this.selectTab(ETubs.PLATFORM)
  }

  openModal(modal: keyof IModals) {
    switch (modal) {
      case 'cart': {
        this.modals.cart = true
        return
      }
      case 'existComplectation': {
        this.modals.existComplectation = true
        return
      }
    }
  }

  closeModal(modal: keyof IModals) {
    switch (modal) {
      case 'cart': {
        this.modals.cart = false
        return
      }
      case 'existComplectation': {
        this.modals.existComplectation = false
        return
      }
    }
  }

  setHashToDelete(hash: string) {
    this.hashToDeleteComplectation = hash
  }

  incrementProduct(hash: string) {
    const product = this.cart.complectations.find(
      (product) => product.hash === hash,
    )
    if (product) {
      product.count += 1
    }
  }

  decrementProduct(hash: string) {
    const product = this.cart.complectations.find(
      (product) => product.hash === hash,
    )

    if (product) {
      product.count -= product.count > 1 ? 1 : 0
    }
  }

  deleteProduct(hash: string) {
    this.cart.complectations = this.cart.complectations.filter(
      (product) => product.hash !== hash,
    )

    this.modals.cart = false
    !this.cart.complectations.length && this.resetAllData()
  }

  setComment(value: string) {
    this.cart.comment = value
  }

  addMore() {
    this.resetAllDataWithoutCart()
    this.isNewProduct = true
  }

  addProductType(productType: IProductType) {
    this.resetAllDataWithoutCart()
    this.isNewProduct = true
    this.selectProductType(productType)
    this.selectTab(ETubs.SERIES)
  }

  resetAllData() {
    this.tabs = initData.tabs
    this.cart = initData.cart
    this.selectedData = initData.selectedData
    this.platformTypes = initData.platformTypes
    this.status = EStatusProcess.IDLE
    this.hashToDeleteComplectation = undefined
  }

  async sendOrder() {
    this.isFetching = true

    try {
      await this.configuratorRest.sendOrder(this.cart)
      this.resetAllData()
      this.status = EStatusProcess.SUCCESS
    } catch (err) {
      this.status = EStatusProcess.FAIL
      console.error(err)
    } finally {
      this.isFetching = false
    }
  }

  async getProducts() {
    this.isFetching = true

    try {
      this.productTypes = await this.configuratorRest.getProducts()
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetching = false
    }
  }

  async getOrders() {
    this.isFetching = true

    try {
      this.orders = await this.configuratorRest.getOrders()
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetching = false
    }
  }

  async getOrder(id: number) {
    this.isFetching = true

    try {
      this.order = await this.configuratorRest.getOrder(id)
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetching = false
    }
  }

  async getComponentsTypes() {
    this.isFetching = true

    try {
      this.componentTypes = await this.configuratorRest.getComponentsTypes()
    } catch (err) {
      console.error(err)
    } finally {
      this.isFetching = false
    }
  }

  private setProductTypes() {
    this.platformTypes = initData.platformTypes

    forEach(this.selectedSeries?.platforms, (platform) => {
      forEach(platform.types, (type) => {
        const accessory = get(type, ['components', 0, 'accessories'], null)
        const data = {
          parent: platform.title,
          name: get(type, ['components', 0, 'name'], null),
          value: get(type, ['components', 0, 'value'], null),
          title: type?.title,
        }

        this.setPlatformTypeAccessories(type.key, accessory, data)
      })
    })
  }

  private resetAllDataWithoutCart() {
    this.selectedData = initData.selectedData
    this.platformTypes = initData.platformTypes
    this.tabs = {
      currentTab: 0,
      disabledTabs: [1, 2, 3],
    }
  }
}

export const useConfiguratorStore = () => container.resolve(ConfiguratorStore)
