import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Modal } from '@ui/Modal'
import { Tabs } from '@ui/Tabs'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EStatusProcess } from '@lib/form'
import { INavBlockerControl, useNavBlocker } from '@lib/hooks/useNavBlock'

import FailConfiguratorCard from '../components/FailConfiguratorCard'
import SuccessConfiguratorCard from '../components/SuccessConfiguratorCard'
import { useConfiguratorStore } from '../store/ConfiguratorStore'
import Cart from '../tabs/Cart/Cart'
import SelectPlatform from '../tabs/SelectPlatform/SelectPlatform'
import SelectProductType from '../tabs/SelectProductType/SelectProductType'
import SelectSeries from '../tabs/SelectSeries/SelectSeries'

const tabs = [
  {
    label: '01 Выбор типа продукции',
    item: <SelectProductType />,
  },
  {
    label: '02 Выбор серии',
    item: <SelectSeries />,
  },
  {
    label: '03 Выбор платформы',
    item: <SelectPlatform />,
  },
  {
    label: '04 Корзина',
    item: <Cart />,
  },
]

const ConfiguratorCreatePage = () => {
  const configuratorStore = useConfiguratorStore()
  const [openResetModal, setOpenResetModal] = useState(false)

  const navControlRef = useRef<INavBlockerControl | null>(null)

  useEffect(() => {
    Promise.all([
      configuratorStore.getProducts(),
      configuratorStore.getComponentsTypes(),
    ])
  }, [])

  useNavBlocker({
    onBlock(control) {
      setOpenResetModal(true)
      navControlRef.current = control
    },
    enabled: configuratorStore.enabledBlocker,
  })

  const handleClickTab = useCallback((index: number) => {
    configuratorStore.selectTab(index)
  }, [])

  const handleCloseModal = useCallback(() => {
    configuratorStore.closeModal('existComplectation')
  }, [])

  const handleCloseResetModal = useCallback(() => {
    setOpenResetModal(false)
    navControlRef.current.cancel()
  }, [])

  const handleRedirectResetModal = useCallback(() => {
    setOpenResetModal(false)
    navControlRef.current.confirm()
    configuratorStore.resetAllData()
  }, [])

  return (
    <Fragment>
      {configuratorStore.status === EStatusProcess.IDLE && (
        <PageWrapper
          title="Создать конфигурацию"
          isLoading={
            configuratorStore.isFetching &&
            !configuratorStore.productTypes?.length
          }>
          <>
            <Tabs
              tabs={tabs.map((tab, index) => ({
                ...tab,
                disabled: configuratorStore.tabs.disabledTabs.includes(index),
              }))}
              defaultActiveKey={0}
              onTabClick={handleClickTab}
              activeTabIndex={configuratorStore.tabs.currentTab}
            />
            {configuratorStore.modals.existComplectation && (
              <Modal
                open={configuratorStore.modals.existComplectation}
                onClose={handleCloseModal}
                title="Предупреждение!"
                content="Конфигурация с такими же компонентами уже была добавлена в корзину."
                footer={
                  <>
                    <Button onClick={handleCloseModal}>Понятно</Button>
                  </>
                }
              />
            )}
          </>
        </PageWrapper>
      )}

      {configuratorStore.status === EStatusProcess.SUCCESS && (
        <PageWrapper align="center">
          <SuccessConfiguratorCard />
        </PageWrapper>
      )}

      {configuratorStore.status === EStatusProcess.FAIL && (
        <PageWrapper align="center">
          <FailConfiguratorCard />
        </PageWrapper>
      )}

      <Modal
        open={openResetModal}
        onClose={handleCloseResetModal}
        title="Предупреждение о сбросе"
        content="Если вы покинете эту страницу, ваш выбор платформы и комплектующих будет сброшен."
        footer={
          <>
            <Button type="link" withBorder onClick={handleCloseResetModal}>
              Отмена
            </Button>
            <Button onClick={handleRedirectResetModal}>Выйти</Button>
          </>
        }
      />
    </Fragment>
  )
}

export default observer(ConfiguratorCreatePage)
