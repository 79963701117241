export enum EProblem {
  PROBLEM = 'problem',
  QUESTION = 'question',
  ERROR = 'error',
  FULFILLMENT = 'fulfillment',
  OTHER = 'other',
}

export const problemOptions = [
  {
    label: 'Проблема с порталом',
    value: EProblem.PROBLEM,
  },
  {
    label: 'Вопрос по конфигуратору',
    value: EProblem.QUESTION,
  },
  {
    label: 'Ошибка в данных при регистрации',
    value: EProblem.ERROR,
  },
  {
    label: 'Выполнение заказа',
    value: EProblem.FULFILLMENT,
  },
  {
    label: 'Другое',
    value: EProblem.OTHER,
  },
]

export interface IContactUs {
  type: EProblem
  theme: string
  description: string
  file: number
}
