import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookmarkRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 4A2.75 2.75 0 015 1.25h6A2.75 2.75 0 0113.75 4v10.117a1.25 1.25 0 01-1.893 1.072L8 12.875l-3.857 2.314a1.25 1.25 0 01-1.893-1.072V4zM5 2.75c-.69 0-1.25.56-1.25 1.25v9.675l3.607-2.164a1.25 1.25 0 011.286 0l3.607 2.164V4c0-.69-.56-1.25-1.25-1.25H5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 5a3 3 0 013-3h7a3 3 0 013 3v11.584c0 1.37-1.504 2.21-2.67 1.49l-3.567-2.205a.5.5 0 00-.526 0L5.67 18.073c-1.166.72-2.67-.118-2.67-1.489V5zm3-1.5A1.5 1.5 0 004.5 5v11.584a.25.25 0 00.381.213l3.568-2.204a2 2 0 012.102 0l3.568 2.204a.25.25 0 00.381-.213V5A1.5 1.5 0 0013 3.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 6.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.25A3.75 3.75 0 004.25 6v13.323c0 1.758 1.925 2.837 3.425 1.92l3.933-2.408a.75.75 0 01.784 0l3.933 2.407c1.5.918 3.425-.16 3.425-1.919V6A3.75 3.75 0 0016 2.25H8zM5.75 6A2.25 2.25 0 018 3.75h8A2.25 2.25 0 0118.25 6v13.323a.75.75 0 01-1.142.64l-3.933-2.408a2.25 2.25 0 00-2.35 0l-3.933 2.408a.75.75 0 01-1.142-.64V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M10 7a1 1 0 000 2h12a1 1 0 100-2H10z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2A5.5 5.5 0 005 7.5v19.077c0 2.295 2.473 3.74 4.473 2.613l6.036-3.402a1 1 0 01.982 0l6.036 3.402c2 1.127 4.473-.318 4.473-2.613V7.5A5.5 5.5 0 0021.5 2h-11zM7 7.5A3.5 3.5 0 0110.5 4h11A3.5 3.5 0 0125 7.5v19.077a1 1 0 01-1.491.87l-6.036-3.402a3 3 0 00-2.946 0L8.49 27.448A1 1 0 017 26.577V7.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M20 16a1.5 1.5 0 000 3h24a1.5 1.5 0 000-3H20z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 6a9.5 9.5 0 00-9.5 9.5v35.997c0 4.257 4.627 6.9 8.294 4.738L30.73 49.49a2.5 2.5 0 012.54 0l11.436 6.745C48.372 58.397 53 55.754 53 51.497V15.5A9.5 9.5 0 0043.5 6h-23zM14 15.5A6.5 6.5 0 0120.5 9h23a6.5 6.5 0 016.5 6.5v35.997c0 1.935-2.103 3.136-3.77 2.154l-11.436-6.745a5.5 5.5 0 00-5.588 0L17.77 53.651c-1.667.982-3.77-.219-3.77-2.154V15.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookmarkRegular)
