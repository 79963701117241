import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSecurityKeyBLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 6a2 2 0 100 4 2 2 0 000-4zm-1 2a1 1 0 112 0 1 1 0 01-2 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 3.5a4.5 4.5 0 103.936 6.682l.407.5a1 1 0 001.55 0l.726-.89.733.9a1 1 0 001.536.018l1.35-1.58a1 1 0 00.04-1.25l-1.11-1.48a1 1 0 00-.8-.4H9.533A4.5 4.5 0 005.5 3.5zM2 8a3.5 3.5 0 016.746-1.312.5.5 0 00.463.312h4.66l1.11 1.48-1.351 1.58-.734-.9a1 1 0 00-1.55 0l-.725.89-.924-1.135a.5.5 0 00-.864.162A3.502 3.502 0 012 8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.155 7.156a2.844 2.844 0 100 5.688 2.844 2.844 0 000-5.688zM4.31 10a1.844 1.844 0 113.687 0 1.844 1.844 0 01-3.687 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.155 4.252a5.748 5.748 0 105.211 8.177.311.311 0 01.207-.164c.087-.02.138.003.167.034l.886.918c.441.457 1.172.458 1.615.004l1.065-1.092a.125.125 0 01.183.003l.89.99c.464.515 1.28.492 1.713-.049l1.363-1.697c.327-.407.331-.985.01-1.396L18.13 8.266a1.125 1.125 0 00-.887-.433h-5.66c-.045 0-.114-.032-.152-.119a5.75 5.75 0 00-5.275-3.462zM1.406 10a4.748 4.748 0 019.107-1.888c.176.406.578.721 1.07.721h5.659c.038 0 .075.018.098.048l1.337 1.714a.125.125 0 01-.002.155l-1.363 1.697a.125.125 0 01-.19.006l-.89-.99a1.125 1.125 0 00-1.642-.032l-1.065 1.092c-.049.05-.13.05-.179 0l-.886-.919c-.318-.33-.754-.4-1.122-.31a1.311 1.311 0 00-.878.712A4.749 4.749 0 011.406 10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityKeyBLight)
