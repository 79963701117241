import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconHandThumbUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.138.25c-.662 0-1.274.352-1.608.924L4.625 6.155a2.735 2.735 0 00-.054.097A1.273 1.273 0 004.5 6.25h-2c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h9.438a2.75 2.75 0 002.668-2.083l1.156-4.621a2.25 2.25 0 00-2.183-2.796H11V2.112A1.862 1.862 0 009.138.25zm-3.388 13h6.188a1.25 1.25 0 001.213-.947l1.155-4.621a.75.75 0 00-.727-.932H10.25A.75.75 0 019.5 6V2.112a.362.362 0 00-.674-.183L5.92 6.911a1.25 1.25 0 00-.17.63v5.709zm-1.5-5.5h-1.5v5.5h1.5v-5.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.45 1.25A2.3 2.3 0 009.4 2.506L6.578 8.054c-.033.064-.064.13-.092.197l-.048-.001H3.563c-.724 0-1.312.588-1.312 1.313v6.874c0 .725.588 1.313 1.313 1.313h11.215a3 3 0 002.896-2.216l1.41-5.21a2.437 2.437 0 00-2.352-3.074H13.75v-3.7a2.3 2.3 0 00-2.3-2.3zm-3.7 15h7.028a1.5 1.5 0 001.448-1.108l1.411-5.21a.937.937 0 00-.905-1.182H13a.75.75 0 01-.75-.75V3.55a.8.8 0 00-1.513-.363L7.913 8.734a1.5 1.5 0 00-.163.68v6.836zm-4 0v-6.5h2.5v6.5h-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.874 1c-.824 0-1.574.477-1.924 1.223L8.28 7.921A1.875 1.875 0 016.58 9H3.75A1.75 1.75 0 002 10.75v8.5c0 .966.784 1.75 1.75 1.75h12.996a4.125 4.125 0 003.975-3.023l1.712-6.176A3 3 0 0019.54 8H15V3.126A2.126 2.126 0 0012.874 1zM7.982 19.5h8.764c1.18 0 2.214-.787 2.53-1.924l1.711-6.175A1.5 1.5 0 0019.542 9.5h-4.73A1.312 1.312 0 0113.5 8.187V3.127a.626.626 0 00-1.192-.266l-2.67 5.697A3.374 3.374 0 018 10.187v9.063c0 .085-.006.168-.018.25zm-1.482-9H3.75a.25.25 0 00-.25.25v8.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25V10.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.156 1.667c-1.09 0-2.085.623-2.56 1.605l-3.54 7.317A2.5 2.5 0 018.806 12H5.332A2.333 2.333 0 003 14.333v11.334A2.333 2.333 0 005.333 28H10a1 1 0 001-1V13.429a4.497 4.497 0 001.856-1.969l3.54-7.317A.844.844 0 0118 4.51v6.74c0 .966.784 1.75 1.75 1.75h6.282a2 2 0 011.924 2.545l-2.24 7.91A3.5 3.5 0 0122.347 26H14a1 1 0 100 2h8.348a5.5 5.5 0 005.292-4l2.24-7.91c.724-2.553-1.194-5.09-3.848-5.09H20V4.51a2.844 2.844 0 00-2.844-2.843zM5.333 14a.333.333 0 00-.333.333v11.334c0 .184.15.333.333.333H9V14H5.333z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.516 4a5.484 5.484 0 00-4.952 3.126l-7.018 14.739A5.5 5.5 0 0117.58 25H10.5A4.5 4.5 0 006 29.5v21a4.5 4.5 0 004.5 4.5h6a4.5 4.5 0 004.5-4.5V27.282a8.497 8.497 0 004.255-4.128l7.018-14.738A2.484 2.484 0 0137 9.484V22a3 3 0 003 3h11.647c2.953 0 5.106 2.797 4.35 5.652l-4.174 15.767A7.5 7.5 0 0144.573 52H25.5a1.5 1.5 0 000 3h19.073a10.5 10.5 0 0010.15-7.813l4.174-15.768C60.157 26.661 56.57 22 51.647 22H40V9.484A5.484 5.484 0 0034.516 4zM10.5 28A1.5 1.5 0 009 29.5v21a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V28h-7.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHandThumbUpRegular)
