import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconHandThumbUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.264 0C7.606 0 7.005.372 6.71.96L4.191 6H2a1 1 0 00-1 1v6a1 1 0 001 1h9.438a2.5 2.5 0 002.426-1.894l1.155-4.62A2 2 0 0013.08 5H10V1.736C10 .777 9.223 0 8.264 0zM5 13h6.438a1.5 1.5 0 001.456-1.136l1.155-4.621A1 1 0 0013.079 6H9.5a.5.5 0 01-.5-.5V1.736a.736.736 0 00-1.394-.33L5 6.619V13zM2 7h2v6H2V7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.295 1c-.682 0-1.299.407-1.568 1.034L6.17 8H3.063C2.476 8 2 8.476 2 9.063v6.874C2 16.524 2.476 17 3.063 17H13.79a2.75 2.75 0 002.65-2.014l1.443-5.197A2.2 2.2 0 0015.763 7H12V2.705C12 1.763 11.236 1 10.295 1zM6.998 16h6.792a1.75 1.75 0 001.686-1.282l1.444-5.197A1.2 1.2 0 0015.763 8H12a1 1 0 01-1-1V2.705a.705.705 0 00-1.353-.278L7 8.603v7.335c0 .02 0 .041-.002.062zM3 9.062C3 9.028 3.028 9 3.063 9H6v6.938a.063.063 0 01-.063.062H3.063A.063.063 0 013 15.937V9.064z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconHandThumbUpLight)
