import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { IconArrowADownLeftRegular, IconArrowALeftLight } from '@ui/icons'
import { Tabs } from '@ui/Tabs'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'
import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'
import { getRouteById } from '@lib/navigation/MainRoutes'

import TableDescriptionProduct from '../components/TableDescriptionProduct/TableDescriptionProduct'
import { useProductStore } from '../store/ProductStore'

import styles from './styles.module.scss'

const ProductByIdPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const productStore = useProductStore()

  useEffect(() => {
    const section = document.querySelector('section section')
    section.scrollTo(0, 0)
    productStore.getProduct(+id)
  }, [])

  const tabs = useMemo(
    () =>
      (productStore.product?.images ?? []).map((data) => ({
        typeLabel: data,
        item: <img src={data} />,
        classNameLabel: styles.imageTab,
      })),
    [productStore.product],
  )

  const onBack = useCallback(() => {
    productStore.ProductId = undefined
    navigate(
      getRouteById(productStore.product?.type.id, EMainRoutes.CategoryProduct),
    )
  }, [productStore.product])

  return (
    <PageWrapper
      className={styles.product}
      title={productStore.product?.name}
      headerRight={
        <Button
          type="fill"
          withBorder
          className={styles.button}
          size="small"
          onClick={onBack}
          IconLeft={IconArrowALeftLight}>
          Назад
        </Button>
      }>
      <div className={styles.description_card}>
        {tabs.length && (
          <Tabs
            tabs={tabs}
            onTabClick={(v) => v}
            type="vertical"
            size="small"
            className={styles.tab}
            defaultActiveKey={0}
          />
        )}
        <div className={styles.description}>
          <CardWrapper px={20} py={20} className={styles.card}>
            <Typography weight={'medium'} className={styles.title} size={22}>
              Основные характеристики
            </Typography>
            <Typography size={16}>
              {productStore.product?.specifications}
            </Typography>
            <Button
              download
              size="small"
              href={productStore.product?.file}
              IconLeft={IconArrowADownLeftRegular}
              className={styles.download}>
              Скачать характеристики (PDF)
            </Button>
          </CardWrapper>
          <CardWrapper px={20} py={20}>
            <Typography weight={'medium'} className={styles.title} size={22}>
              Описание
            </Typography>
            <Typography size={16}>
              {productStore.product?.description}
            </Typography>
          </CardWrapper>
        </div>
      </div>
      <CardWrapper px={16} py={20} className={styles.table}>
        <Typography weight={'medium'} size={22}>
          Характеристики {productStore.product?.name}
        </Typography>
        <TableDescriptionProduct info={productStore.product?.info} />
      </CardWrapper>
      <CardWrapper px={16} py={20} className={styles.table}>
        <Typography weight={'medium'} size={22}>
          Как узнать цену?
        </Typography>
        <Typography className={styles.footer} size={16}>
          Цену с расширенными опциями можно узнать у наших менеджеров по
          телефону:
          <Typography
            component={'a'}
            href="tel:+74954806845"
            color={COLORS.BRAND.OCEAN}
            size={16}>
            {' '}
            +7 (495) 480-68-45
          </Typography>
          <br />
          Информация/предложение не является публичной офертой.
        </Typography>
      </CardWrapper>
    </PageWrapper>
  )
}

export default observer(ProductByIdPage)
