import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowAUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.854 7.27a.5.5 0 11-.708-.708l4.006-4.006a1.906 1.906 0 012.696 0l4.006 4.006a.5.5 0 01-.708.707L8.141 3.263A.91.91 0 008 3.148V13.5a.5.5 0 01-1 0V3.148a.91.91 0 00-.14.115L2.853 7.27z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3.291 8.635a.5.5 0 11-.707-.707L7.82 2.69a2.375 2.375 0 013.358 0l5.237 5.237a.5.5 0 01-.707.707l-5.237-5.237A1.371 1.371 0 0010 3.09V17.5a.5.5 0 11-1 0V3.09a1.37 1.37 0 00-.472.308L3.29 8.635z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpLight)
