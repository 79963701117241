import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechPrinterLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.495 8.125a.625.625 0 100-1.25.625.625 0 000 1.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.5V5h1a2 2 0 012 2v4a2 2 0 01-2 2h-1v.5a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 13.5V13H3a2 2 0 01-2-2V7a2 2 0 012-2h1V2.5A1.5 1.5 0 015.5 1h5A1.5 1.5 0 0112 2.5zm-7 0a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V5H5V2.5zM13 6a1 1 0 011 1v4a1 1 0 01-1 1h-1v-1.5A1.5 1.5 0 0010.5 9h-5A1.5 1.5 0 004 10.5V12H3a1 1 0 01-1-1V7a1 1 0 011-1h10zm-2 4.5v3a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16 10.752a.752.752 0 100-1.504.752.752 0 000 1.504z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.5V7h1.75A2.25 2.25 0 0119 9.25v4.5A2.25 2.25 0 0116.75 16H15v1.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 015 17.5V16H3.25A2.25 2.25 0 011 13.75v-4.5A2.25 2.25 0 013.25 7H5V3.5A1.5 1.5 0 016.5 2h7A1.5 1.5 0 0115 3.5zm-9 0a.5.5 0 01.5-.5h7a.5.5 0 01.5.5V7H6V3.5zM16.75 8c.69 0 1.25.56 1.25 1.25v4.5c0 .69-.56 1.25-1.25 1.25H15v-1.5a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 005 13.5V15H3.25C2.56 15 2 14.44 2 13.75v-4.5C2 8.56 2.56 8 3.25 8h13.5zM14 13.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPrinterLight)
