import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoYandexZenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1.25A3.25 3.25 0 001.25 4.5v7a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25v-7a3.25 3.25 0 00-3.25-3.25h-7zM2.75 4.5c0-.966.784-1.75 1.75-1.75h3.305c-.06 1.972-.28 3.148-1.094 3.962-.814.814-1.99 1.034-3.961 1.093V4.5zm0 3.695V11.5c0 .966.784 1.75 1.75 1.75h3.305c-.06-1.972-.28-3.148-1.094-3.962-.814-.814-1.99-1.034-3.961-1.093zm5.445 5.055H11.5a1.75 1.75 0 001.75-1.75V8.195c-1.972.06-3.148.28-3.962 1.093-.814.814-1.034 1.99-1.093 3.962zm5.055-5.445V4.5a1.75 1.75 0 00-1.75-1.75H8.195c.06 1.972.28 3.148 1.093 3.962.814.814 1.99 1.034 3.962 1.093z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.25A3.75 3.75 0 002.25 6v8A3.75 3.75 0 006 17.75h8A3.75 3.75 0 0017.75 14V6A3.75 3.75 0 0014 2.25H6zM3.75 6A2.25 2.25 0 016 3.75h3.776c-.064 2.376-.313 3.78-1.28 4.747-.966.967-2.37 1.215-4.746 1.279V6zm0 4.224V14A2.25 2.25 0 006 16.25h3.776c-.064-2.376-.313-3.78-1.28-4.747-.966-.967-2.37-1.215-4.746-1.279zm6.474 6.026H14A2.25 2.25 0 0016.25 14v-3.776c-2.376.064-3.78.312-4.747 1.279-.967.967-1.215 2.371-1.28 4.747zm6.026-6.474V6A2.25 2.25 0 0014 3.75h-3.776c.064 2.376.312 3.78 1.279 4.747.967.967 2.37 1.215 4.747 1.279z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 2.25A4.25 4.25 0 002.25 6.5v11a4.25 4.25 0 004.25 4.25h11a4.25 4.25 0 004.25-4.25v-11a4.25 4.25 0 00-4.25-4.25h-11zM3.75 6.5A2.75 2.75 0 016.5 3.75h4.726c-.124 3.108-.67 4.607-1.77 5.707-1.1 1.1-2.598 1.646-5.706 1.77V6.5zm0 6.274V17.5a2.75 2.75 0 002.75 2.75h4.726c-.124-3.108-.67-4.607-1.77-5.707-1.1-1.1-2.598-1.646-5.706-1.77zm9.024 7.476H17.5a2.75 2.75 0 002.75-2.75v-4.726c-3.108.123-4.607.67-5.707 1.77-1.1 1.099-1.646 2.598-1.769 5.706zm7.476-9.024V6.5a2.75 2.75 0 00-2.75-2.75h-4.726c.123 3.108.67 4.607 1.77 5.707 1.099 1.1 2.598 1.646 5.706 1.77zm-9.733-.709A6.381 6.381 0 0012 8.203a6.38 6.38 0 001.482 2.314A6.38 6.38 0 0015.796 12a6.38 6.38 0 00-2.313 1.483A6.38 6.38 0 0012 15.797a6.382 6.382 0 00-1.483-2.314A6.38 6.38 0 008.204 12a6.38 6.38 0 002.313-1.483z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 3A5.5 5.5 0 003 8.5v15A5.5 5.5 0 008.5 29h15a5.5 5.5 0 005.5-5.5v-15A5.5 5.5 0 0023.5 3h-15zM5 8.5A3.5 3.5 0 018.5 5h6.468c-.165 4.144-.893 6.143-2.36 7.609-1.466 1.466-3.464 2.195-7.608 2.36V8.5zm0 8.532V23.5A3.5 3.5 0 008.5 27h6.468c-.165-4.144-.893-6.143-2.36-7.609-1.466-1.466-3.464-2.195-7.608-2.36zM17.032 27H23.5a3.5 3.5 0 003.5-3.5v-6.468c-4.145.164-6.143.893-7.61 2.36-1.465 1.465-2.193 3.464-2.358 7.608zM27 14.968V8.5A3.5 3.5 0 0023.5 5h-6.468c.165 4.144.893 6.143 2.359 7.609 1.466 1.466 3.464 2.195 7.609 2.36zm-12.977-.945A8.508 8.508 0 0016 10.938a8.508 8.508 0 001.977 3.085A8.507 8.507 0 0021.06 16a8.507 8.507 0 00-3.084 1.977A8.508 8.508 0 0016 21.062a8.508 8.508 0 00-1.977-3.085A8.507 8.507 0 0010.938 16a8.507 8.507 0 003.085-1.977z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7C11.701 7 7 11.701 7 17.5v29C7 52.299 11.701 57 17.5 57h29C52.299 57 57 52.299 57 46.5v-29C57 11.701 52.299 7 46.5 7h-29zM10 17.5a7.5 7.5 0 017.5-7.5h12.936c-.329 8.345-1.798 12.503-4.866 15.571-3.068 3.068-7.225 4.537-15.57 4.866V17.5zm0 16.063V46.5a7.5 7.5 0 007.5 7.5h12.936c-.329-8.345-1.798-12.503-4.866-15.571-3.068-3.068-7.225-4.537-15.57-4.866zM33.563 54H46.5a7.5 7.5 0 007.5-7.5V33.563c-8.347.329-12.504 1.798-15.572 4.866-3.067 3.068-4.535 7.225-4.864 15.571zM54 30.437V17.5a7.5 7.5 0 00-7.5-7.5H33.563c.33 8.346 1.797 12.503 4.865 15.571 3.068 3.068 7.225 4.537 15.572 4.866zm-26.308-2.744c2.015-2.016 3.398-4.366 4.308-7.373.91 3.007 2.292 5.357 4.307 7.373 2.014 2.014 4.364 3.397 7.37 4.307-3.006.91-5.355 2.293-7.37 4.307-2.015 2.016-3.398 4.366-4.307 7.373-.91-3.007-2.293-5.357-4.308-7.373-2.015-2.015-4.364-3.397-7.37-4.307 3.006-.91 5.355-2.293 7.37-4.307z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoYandexZenRegular)
