import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconUiMenuDots3HLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3 8a1 1 0 102 0 1 1 0 00-2 0zM8 9a1 1 0 110-2 1 1 0 010 2zM12 9a1 1 0 110-2 1 1 0 010 2z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M2.754 10a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zM10 11.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM15.996 11.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiMenuDots3HLight)
