import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMathFunctionLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.643 3A2.643 2.643 0 006 5.643V6h2.5a.5.5 0 010 1H6v3.357A3.643 3.643 0 012.357 14H2a.5.5 0 010-1h.357A2.643 2.643 0 005 10.357V7H3a.5.5 0 010-1h2v-.357A3.643 3.643 0 018.643 2H9.5a.5.5 0 010 1h-.857zM8.604 8.677a.5.5 0 00-.707.707l1.88 1.881-1.88 1.881a.5.5 0 00.707.707l1.88-1.88 1.882 1.88a.5.5 0 00.707-.707l-1.881-1.88 1.88-1.881a.5.5 0 10-.706-.708l-1.881 1.881-1.881-1.88z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.942 3a2.938 2.938 0 00-2.937 2.938v2.055h3.16a.5.5 0 110 1h-3.16v5.048a3.937 3.937 0 01-3.938 3.937h-.556a.5.5 0 110-1h.556a2.937 2.937 0 002.938-2.937V8.993H3.509a.5.5 0 110-1h2.496V5.937A3.938 3.938 0 019.942 2h.89a.5.5 0 110 1h-.89zM16.788 11.543a.5.5 0 10-.707-.707l-2.448 2.448-2.448-2.448a.5.5 0 10-.707.707l2.448 2.448-2.448 2.448a.5.5 0 10.707.707l2.448-2.448 2.448 2.448a.5.5 0 10.707-.707l-2.448-2.448 2.448-2.448z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMathFunctionLight)
