import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFlagARegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 15a.75.75 0 001.5 0v-4.25H14.5a.75.75 0 00.6-1.2L12.438 6 15.1 2.45a.75.75 0 00-.6-1.2H4A1.75 1.75 0 002.25 3v12zM4 2.75a.25.25 0 00-.25.25v6.25H13l-2.1-2.8a.75.75 0 010-.9l2.1-2.8H4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 19a.75.75 0 001.5 0v-5.25H17a1 1 0 00.8-1.6L14.687 8 17.8 3.85a1 1 0 00-.8-1.6H4A1.75 1.75 0 002.25 4v15zM4 3.75a.25.25 0 00-.25.25v8.25H16l-2.85-3.8a.75.75 0 010-.9L16 3.75H4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 23a.75.75 0 001.5 0v-6.25h16.735c1.037 0 1.623-1.19.99-2.012L17.446 9.5l4.03-5.238c.632-.822.046-2.012-.991-2.012H4.5A2.25 2.25 0 002.25 4.5V23zM4.5 3.75a.75.75 0 00-.75.75v10.75h16.227l-4.072-5.293a.75.75 0 010-.914l4.072-5.293H4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 31a1 1 0 102 0v-8h21.718c1.142 0 1.803-1.294 1.135-2.22L23.233 13l5.62-7.78C29.52 4.294 28.86 3 27.718 3H7a3 3 0 00-3 3v25zM7 5a1 1 0 00-1 1v15h20.544l-5.355-7.415a1 1 0 010-1.17L26.544 5H7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 6A5.5 5.5 0 007 11.5v49a1.5 1.5 0 003 0V46h43.882c2.514 0 3.913-2.908 2.343-4.873L44.14 26l12.085-15.127C57.795 8.908 56.396 6 53.882 6H12.5zM10 43h43.882L41.796 27.872a3 3 0 010-3.744L53.882 9H12.5a2.5 2.5 0 00-2.5 2.5V43z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFlagARegular)
