import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetCloudArrowUploadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.375 1.5a2.875 2.875 0 00-2.863 2.606.75.75 0 01-.631.672A2.376 2.376 0 004.25 9.5h.25a.75.75 0 010 1.5h-.25a3.875 3.875 0 01-1.146-7.578 4.376 4.376 0 017.946-1.421L11.125 2a4.5 4.5 0 01.125 9 .75.75 0 01-.75-.75v-.002a.75.75 0 01.725-.75 3 3 0 10-.495-5.972.75.75 0 01-.771-.414A2.875 2.875 0 007.375 1.5z"
          fill="currentColor"
        />
        <path
          d="M8.75 6.31l1.72 1.72a.75.75 0 101.06-1.06L9.237 4.677a1.75 1.75 0 00-2.474 0L4.47 6.97a.75.75 0 001.06 1.06l1.72-1.72v7.94a.75.75 0 001.5 0V6.31z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.125 1.5a3.875 3.875 0 00-3.868 3.636.75.75 0 01-.592.689 3.377 3.377 0 00.607 6.673.75.75 0 01.728.75v.002a.75.75 0 01-.83.746 4.875 4.875 0 01-1.35-9.493A5.376 5.376 0 0113.948 3H14a5.5 5.5 0 01.578 10.97.75.75 0 01-.156-1.492 4 4 0 10-.871-7.953.75.75 0 01-.787-.487A3.877 3.877 0 009.125 1.5z"
          fill="currentColor"
        />
        <path
          d="M10.75 7.81l2.22 2.22a.75.75 0 101.06-1.06l-2.793-2.793a1.75 1.75 0 00-2.474 0L5.97 8.97a.75.75 0 101.06 1.06l2.22-2.22v10.44a.75.75 0 001.5 0V7.81z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.95 1.75a4.8 4.8 0 00-4.791 4.505.75.75 0 01-.592.688 4.202 4.202 0 00.755 8.305.75.75 0 01.728.75V16a.75.75 0 01-.835.745A5.7 5.7 0 014.719 5.618a6.301 6.301 0 0111.925-1.766l.156-.002a6.45 6.45 0 01.678 12.865.75.75 0 01-.156-1.492 4.95 4.95 0 10-1.078-9.842.75.75 0 01-.787-.487A4.802 4.802 0 0010.95 1.75z"
          fill="currentColor"
        />
        <path
          d="M12.75 9.01l2.92 2.92a.75.75 0 101.06-1.06L13.38 7.518a1.95 1.95 0 00-2.758 0L7.27 10.87a.75.75 0 101.06 1.06l2.92-2.92V22a.75.75 0 101.5 0V9.01z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.125 3a6.876 6.876 0 00-6.82 6.005 1 1 0 01-.796.855 5.625 5.625 0 001 11.138l1.492.002a1 1 0 11-.002 2l-1.451-.001a1.079 1.079 0 01-.119-.002A7.625 7.625 0 016.434 8.07C7.268 4.034 10.842 1 15.125 1a8.874 8.874 0 017.871 4.772A8.626 8.626 0 0122.5 23H22a1 1 0 110-2H22.484a6.625 6.625 0 00-.108-13.25h-.02a1 1 0 01-.918-.603A6.877 6.877 0 0015.125 3z"
          fill="currentColor"
        />
        <path
          d="M17 12.414l4.293 4.293a1 1 0 001.414-1.414l-4.303-4.303a3.4 3.4 0 00-4.808 0l-4.303 4.303a1 1 0 101.414 1.414L15 12.414V29a1 1 0 102 0V12.414z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M29.5 5c-6.795 0-12.374 5.215-12.95 11.86a1.5 1.5 0 01-1.215 1.343C10.02 19.215 6 23.89 6 29.5 6 35.851 11.149 41 17.5 41h3a1.5 1.5 0 010 3h-3C9.492 44 3 37.508 3 29.5c0-6.692 4.532-12.323 10.694-13.995C14.892 7.853 21.511 2 29.5 2c6.333 0 11.803 3.679 14.396 9.01.2-.006.402-.01.604-.01C53.613 11 61 18.387 61 27.5S53.613 44 44.5 44h-2a1.5 1.5 0 010-3h2C51.956 41 58 34.956 58 27.5S51.956 14 44.5 14c-.471 0-.937.024-1.395.071a1.5 1.5 0 01-1.543-.931C39.636 8.365 34.959 5 29.5 5z"
        fill="currentColor"
      />
      <path
        d="M33 23.001l.09.086 9.85 9.849a1.5 1.5 0 002.12-2.122l-9.848-9.848a5.25 5.25 0 00-7.424 0l-9.849 9.848a1.5 1.5 0 002.122 2.122l9.848-9.849.09-.086V56.5a1.5 1.5 0 003 0V23.001z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetCloudArrowUploadRegular)
