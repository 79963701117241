import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTextBulletGroupRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 2C1.56 2 1 2.56 1 3.25v2.5C1 6.44 1.56 7 2.25 7h2.5C5.44 7 6 6.44 6 5.75v-2.5C6 2.56 5.44 2 4.75 2h-2.5zm.25 3.5v-2h2v2h-2z"
          fill="currentColor"
        />
        <path
          d="M8 2.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5H8zM7.25 6A.75.75 0 018 5.25h5a.75.75 0 010 1.5H8A.75.75 0 017.25 6zM8 8.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5H8zM8 11.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 9.25C1 8.56 1.56 8 2.25 8h2.5C5.44 8 6 8.56 6 9.25v2.5C6 12.44 5.44 13 4.75 13h-2.5C1.56 13 1 12.44 1 11.75v-2.5zm1.5.25v2h2v-2h-2z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.25C2 3.56 2.56 3 3.25 3h3.5C7.44 3 8 3.56 8 4.25v3.5C8 8.44 7.44 9 6.75 9h-3.5C2.56 9 2 8.44 2 7.75v-3.5zm1.5.25v3h3v-3h-3z"
          fill="currentColor"
        />
        <path
          d="M10.25 4a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zM11 11.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6zM11 15.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6zM11 7.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25 11C2.56 11 2 11.56 2 12.25v3.5c0 .69.56 1.25 1.25 1.25h3.5C7.44 17 8 16.44 8 15.75v-3.5C8 11.56 7.44 11 6.75 11h-3.5zm.25 4.5v-3h3v3h-3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.5A1.5 1.5 0 013.5 3h4A1.5 1.5 0 019 4.5v4A1.5 1.5 0 017.5 10h-4A1.5 1.5 0 012 8.5v-4zm5.5 0h-4v4h4v-4z"
          fill="currentColor"
        />
        <path
          d="M11.75 3.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM11 9a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5A.75.75 0 0111 9zM11.75 14.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM11.75 19.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 14A1.5 1.5 0 002 15.5v4A1.5 1.5 0 003.5 21h4A1.5 1.5 0 009 19.5v-4A1.5 1.5 0 007.5 14h-4zm0 1.5h4v4h-4v-4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm6 0H5v4h4V8z"
          fill="currentColor"
        />
        <path
          d="M15 6a1 1 0 100 2h12a1 1 0 100-2H15zM15 12a1 1 0 100 2h12a1 1 0 100-2H15zM15 24a1 1 0 100 2h12a1 1 0 100-2H15zM14 19a1 1 0 011-1h12a1 1 0 110 2H15a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 18a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2H5zm0 2h4v4H5v-4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14.5a3.5 3.5 0 013.5-3.5h9a3.5 3.5 0 013.5 3.5v9a3.5 3.5 0 01-3.5 3.5h-9A3.5 3.5 0 017 23.5v-9zm3.5-.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5h-9z"
        fill="currentColor"
      />
      <path
        d="M31.5 12a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3h-23zM30 24.5a1.5 1.5 0 011.5-1.5h23a1.5 1.5 0 010 3h-23a1.5 1.5 0 01-1.5-1.5zM31.5 38a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3h-23zM31.5 49a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3h-23z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 37A3.5 3.5 0 007 40.5v9a3.5 3.5 0 003.5 3.5h9a3.5 3.5 0 003.5-3.5v-9a3.5 3.5 0 00-3.5-3.5h-9zm-.5 3.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTextBulletGroupRegular)
