import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSearchLoupeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.853 11.914a6.25 6.25 0 111.06-1.06l2.854 2.853a.75.75 0 11-1.06 1.06l-2.854-2.853zM2.246 6.996a4.75 4.75 0 118.153 3.313.784.784 0 00-.09.09 4.75 4.75 0 01-8.064-3.403z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.502a7.498 7.498 0 104.745 13.303l3.526 3.526a.75.75 0 101.06-1.06l-3.525-3.526A7.497 7.497 0 009 1.503zM3.002 9a5.998 5.998 0 1111.996 0A5.998 5.998 0 013.002 9z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.995 1.503a9.492 9.492 0 100 18.984 9.455 9.455 0 006.161-2.27l4.326 4.326a.75.75 0 001.06-1.06l-4.325-4.327a9.455 9.455 0 002.27-6.16 9.492 9.492 0 00-9.492-9.493zm-7.992 9.492a7.992 7.992 0 1115.985 0 7.992 7.992 0 01-15.985 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2.5C8.096 2.5 2.5 8.096 2.5 15S8.096 27.5 15 27.5a12.45 12.45 0 008.104-2.982l6.189 6.19a1 1 0 001.414-1.415l-6.19-6.19A12.45 12.45 0 0027.5 15c0-6.904-5.596-12.5-12.5-12.5zM4.5 15C4.5 9.201 9.201 4.5 15 4.5S25.5 9.201 25.5 15 20.799 25.5 15 25.5 4.5 20.799 4.5 15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 6C16.745 6 6 16.745 6 30s10.745 24 24 24a23.909 23.909 0 0015.877-6.002l11.062 11.063a1.5 1.5 0 002.122-2.122L47.998 45.877A23.909 23.909 0 0054 30C54 16.745 43.255 6 30 6zM9 30C9 18.402 18.402 9 30 9s21 9.402 21 21-9.402 21-21 21S9 41.598 9 30z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSearchLoupeRegular)
