import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'

import { UserStore } from './UserStore'

@singleton()
export class UserBasicInformationFormStore {
  private userStore = container.resolve(UserStore)

  form = new FormConstructor({
    firstName: {
      label: 'Имя',
      rule: 'required|min:2',
      value: this.userStore.initials.firstName,
    },
    lastName: {
      label: 'Фамилия',
      rule: 'required|min:2',
      value: this.userStore.initials.lastName,
    },
    patronymic: {
      label: 'Отчество',
      rule: 'present|min:2',
      value: this.userStore.initials.patronymic,
    },
  })

  constructor() {
    makeAutoObservable<UserBasicInformationFormStore, 'userStore'>(this, {
      userStore: false,
    })
  }

  handleSubmit = async () => {
    await this.form.handleSubmit((fields) =>
      this.userStore.updateUser({
        fullName: `${fields.lastName.value} ${fields.firstName.value} ${fields.patronymic.value}`,
      }),
    )
  }

  reset = () => {
    this.form.fields.firstName.value = this.userStore.initials.firstName
    this.form.fields.lastName.value = this.userStore.initials.lastName
    this.form.fields.patronymic.value = this.userStore.initials.patronymic
  }
}

export const useUserBasicInformationFormStore = () =>
  container.resolve(UserBasicInformationFormStore)
