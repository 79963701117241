import Button from '@ui/Button'
import { Typography } from '@ui/Typography'

import { getImageUrl } from '@lib/helpers'
import { EMainRoutes } from '@lib/navigation'

import styles from './styles.module.scss'

const InDevelopPage = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Typography size={32} className={styles.title}>
        Раздел в разработке
      </Typography>
      <Typography size={16} className={styles.title}>
        Ведутся работы по разработке этого раздела, попробуйте зайти на него
        позже.
      </Typography>
      <Button
        className={styles.button}
        type="link"
        children="Вернуться на главную"
        linkTo={EMainRoutes.Main}
      />
    </div>
    <div className={styles.imageContainer}>
      <picture>
        <source srcSet={`${getImageUrl('InDevelopPageImageX2.png')} 2x`} />
        <img
          src={`${getImageUrl('InDevelopPageImage.png')}`}
          alt="Схема разработки"
        />
      </picture>
    </div>
  </div>
)

export default InDevelopPage
