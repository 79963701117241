import styles from './styles.scss'

const Hdd = () => (
  <div className={styles.container}>
    <p>
      Жесткий диск (HDD) – это устройство хранения данных, которое используется
      в компьютерах и других электронных устройствах. HDD состоит из магнитных
      дисков, которые вращаются с высокой скоростью, и считывающих головок,
      которые читают и записывают данные на диски.
    </p>

    <p>
      В целом, при выборе HDD стоит обратить внимание на объем хранения данных,
      скорость вращения диска, время доступа, размер буфера кэша, интерфейс,
      надежность и гарантию.
    </p>
  </div>
)

export default Hdd
