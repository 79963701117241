import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconAttachLinkLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M.5 7.5A4.5 4.5 0 015 3h1.5a.5.5 0 010 1H5a3.5 3.5 0 100 7h1.5a.5.5 0 010 1H5A4.5 4.5 0 01.5 7.5zM15.5 7.5A4.5 4.5 0 0011 3H9.5a.5.5 0 000 1H11a3.5 3.5 0 110 7H9.5a.5.5 0 000 1H11a4.5 4.5 0 004.5-4.5z"
          fill="currentColor"
        />
        <path d="M5 7a.5.5 0 000 1h6a.5.5 0 000-1H5z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M1 9.5A5.5 5.5 0 016.5 4h1.25a.5.5 0 010 1H6.5a4.5 4.5 0 000 9h1.25a.5.5 0 010 1H6.5A5.5 5.5 0 011 9.5zM19 9.5A5.5 5.5 0 0013.5 4h-1.25a.5.5 0 000 1h1.25a4.5 4.5 0 110 9h-1.25a.5.5 0 000 1h1.25A5.5 5.5 0 0019 9.5z"
        fill="currentColor"
      />
      <path d="M6 9a.5.5 0 000 1h8a.5.5 0 000-1H6z" fill="currentColor" />
    </svg>
  )
}

export default observer(IconAttachLinkLight)
