import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconNetUploadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.998 5.56a.75.75 0 11-.996-1.12l3.503-3.115a2.25 2.25 0 012.99 0l3.503 3.114a.75.75 0 01-.996 1.122L8.75 2.67v7.579a.75.75 0 01-1.5 0V2.67L3.998 5.56zM1.75 13a.75.75 0 01.75-.75h11a.75.75 0 010 1.5h-11a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.873 7.06a.75.75 0 11-.996-1.12l4.379-3.893a2.625 2.625 0 013.488 0l4.38 3.892a.75.75 0 01-.997 1.122L10.75 3.17v10.08a.75.75 0 01-1.5 0V3.17L4.873 7.06zM2.25 17a.75.75 0 01.75-.75h14a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.748 8.06a.75.75 0 11-.996-1.12l5.255-4.672a3 3 0 013.986 0l5.255 4.671a.75.75 0 01-.996 1.122l-5.255-4.672a1.505 1.505 0 00-.247-.178V16.5a.75.75 0 01-1.5 0V3.211c-.086.05-.17.11-.246.178L5.748 8.061zM2.75 21a.75.75 0 01.75-.75h17a.75.75 0 010 1.5h-17a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.664 10.748a1 1 0 01-1.328-1.495l7.006-6.229a4 4 0 015.315 0l7.007 6.229a1 1 0 01-1.328 1.495l-7.007-6.229A2.004 2.004 0 0017 4.282V22.5a1 1 0 01-2 0V4.282a2.004 2.004 0 00-.329.237l-7.007 6.229zM3.5 28.25a1 1 0 011-1h23a1 1 0 110 2h-23a1 1 0 01-1-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M33.5 8.784a4.488 4.488 0 011.49.88l14.014 12.457a1.5 1.5 0 101.992-2.242L36.983 7.422a7.5 7.5 0 00-9.966 0L13.004 19.88a1.5 1.5 0 101.992 2.242L29.01 9.664a4.488 4.488 0 011.49-.88V46a1.5 1.5 0 003 0V8.784zM7 56.5A1.5 1.5 0 018.5 55h47a1.5 1.5 0 010 3h-47A1.5 1.5 0 017 56.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNetUploadRegular)
