import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckSquareCutRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 4.5c0-.966.784-1.75 1.75-1.75H9a.75.75 0 000-1.5H4.5A3.25 3.25 0 001.25 4.5v7a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25V9a.75.75 0 00-1.5 0v2.5a1.75 1.75 0 01-1.75 1.75h-7a1.75 1.75 0 01-1.75-1.75v-7z"
          fill="currentColor"
        />
        <path
          d="M14.405 4.05a.75.75 0 00-1.06-1.06L7.979 8.354a.5.5 0 01-.708 0L5.386 6.47a.75.75 0 00-1.061 1.06l1.886 1.886a2 2 0 002.828 0l5.366-5.366z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 6A2.25 2.25 0 016 3.75h6a.75.75 0 000-1.5H6A3.75 3.75 0 002.25 6v8A3.75 3.75 0 006 17.75h8A3.75 3.75 0 0017.75 14v-3a.75.75 0 00-1.5 0v3A2.25 2.25 0 0114 16.25H6A2.25 2.25 0 013.75 14V6z"
          fill="currentColor"
        />
        <path
          d="M17.937 5.062A.75.75 0 0016.876 4l-6.708 6.708a.813.813 0 01-1.149 0l-2.33-2.33a.75.75 0 00-1.06 1.06l2.33 2.33a2.312 2.312 0 003.27 0l6.708-6.707z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 6.5A2.75 2.75 0 016.5 3.75H14a.75.75 0 000-1.5H6.5A4.25 4.25 0 002.25 6.5v11a4.25 4.25 0 004.25 4.25h11a4.25 4.25 0 004.25-4.25V13a.75.75 0 00-1.5 0v4.5a2.75 2.75 0 01-2.75 2.75h-11a2.75 2.75 0 01-2.75-2.75v-11z"
          fill="currentColor"
        />
        <path
          d="M21.343 5.968a.75.75 0 00-1.06-1.06l-8.05 8.048c-.44.44-1.152.44-1.591 0L7.655 9.97a.75.75 0 00-1.06 1.06l2.986 2.987a2.625 2.625 0 003.713 0l8.049-8.05z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 8.5A3.5 3.5 0 018.5 5H19a1 1 0 100-2H8.5A5.5 5.5 0 003 8.5v15A5.5 5.5 0 008.5 29h15a5.5 5.5 0 005.5-5.5V18a1 1 0 10-2 0v5.5a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 23.5v-15z"
          fill="currentColor"
        />
        <path
          d="M28.457 8.23a1 1 0 00-1.414-1.414L16.31 17.55a1.5 1.5 0 01-2.122 0l-3.982-3.983a1 1 0 00-1.414 1.415l3.982 3.982a3.5 3.5 0 004.95 0L28.457 8.23z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10 17.5a7.5 7.5 0 017.5-7.5h22a1.5 1.5 0 000-3h-22C11.701 7 7 11.701 7 17.5v29C7 52.299 11.701 57 17.5 57h29C52.299 57 57 52.299 57 46.5v-12a1.5 1.5 0 00-3 0v12a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5v-29z"
        fill="currentColor"
      />
      <path
        d="M56.56 15.56a1.5 1.5 0 00-2.12-2.12L32.974 34.903a3.5 3.5 0 01-4.95 0l-7.964-7.965a1.5 1.5 0 00-2.122 2.122l7.965 7.964a6.5 6.5 0 009.192 0l21.465-21.464z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckSquareCutRegular)
