import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCheckA2Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.965 4.827a.5.5 0 10-.707-.707l-6.583 6.582a1.087 1.087 0 01-1.537 0L.87 8.435a.5.5 0 00-.707.707l2.267 2.267a2.088 2.088 0 002.953 0l6.582-6.582zM15.825 4.827a.5.5 0 10-.707-.707l-7.045 7.045a.5.5 0 10.707.707l7.045-7.045z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.581 5.51a.5.5 0 10-.707-.707l-8.54 8.54c-.58.58-1.52.58-2.1 0l-3.17-3.17a.5.5 0 00-.708.707l3.171 3.17c.97.97 2.543.97 3.514 0l8.54-8.54zM19.832 5.51a.5.5 0 00-.707-.707l-9.093 9.092a.5.5 0 00.707.707l9.093-9.092z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckA2Light)
