import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6 2.061a10.76 10.76 0 00-2.613-.027c-.98.119-2.074.424-2.987 1.123-.913-.7-2.007-1.004-2.987-1.123A10.759 10.759 0 002.4 2.06c-.856.11-1.405.85-1.405 1.634v7.592c0 1.056.918 1.803 1.874 1.787 1.18-.02 2.867.147 3.948 1.012.67.535 1.697.535 2.366 0 1.081-.865 2.768-1.032 3.948-1.012.956.017 1.874-.731 1.874-1.787V3.695c0-.784-.55-1.524-1.405-1.634zM8.847 4.4c.634-.513 1.463-.773 2.322-.877a9.264 9.264 0 012.24.026.087.087 0 01.06.033c.019.023.037.061.037.113v7.592c0 .062-.027.13-.092.19a.374.374 0 01-.256.097c-1.097-.019-2.94.095-4.407.988V4.471a1.33 1.33 0 00.096-.071zm-1.596.07a1.33 1.33 0 01-.096-.07c-.634-.513-1.463-.773-2.322-.877a9.264 9.264 0 00-2.24.026.087.087 0 00-.06.033.176.176 0 00-.037.113v7.592c0 .062.027.13.092.19.068.062.161.099.256.097 1.097-.019 2.94.095 4.407.988V4.471z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.126 2.37c-.816-.106-2.047-.195-3.35-.025-1.26.164-2.645.577-3.776 1.507-1.131-.93-2.516-1.343-3.777-1.507-1.302-.17-2.534-.081-3.35.025-.986.128-1.623.983-1.623 1.897v9.758c0 1.291 1.17 2.182 2.334 2.113 1.559-.092 3.745.09 5.122 1.424.364.353.846.507 1.294.508.448 0 .93-.151 1.296-.504 1.384-1.337 3.566-1.52 5.118-1.428 1.165.068 2.336-.822 2.336-2.114V4.267c0-.914-.637-1.769-1.624-1.897zm-6.248 2.704c.847-.73 1.958-1.094 3.092-1.242 1.13-.147 2.22-.07 2.963.026.161.02.317.167.317.41v9.756c0 .306-.317.641-.748.616-1.486-.087-3.919.021-5.752 1.42V5.172a1.33 1.33 0 00.128-.097zM9.25 5.17a1.331 1.331 0 01-.128-.097c-.847-.73-1.958-1.094-3.092-1.242-1.13-.147-2.22-.07-2.963.026-.161.02-.317.167-.317.41v9.757c0 .307.316.64.746.616 1.487-.088 3.924.018 5.754 1.414V5.17z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.893 5.148c-1.338-1.065-2.974-1.56-4.492-1.766-1.522-.208-2.978-.135-4.01-.021C2.098 3.503 1.25 4.62 1.25 5.83v11.104c0 1.61 1.407 2.785 2.903 2.79 1.919.006 4.546.326 6.285 1.71.882.701 2.242.701 3.123 0 1.74-1.384 4.367-1.704 6.286-1.71 1.496-.005 2.903-1.18 2.903-2.79V5.83c0-1.211-.849-2.328-2.142-2.47-1.03-.114-2.487-.187-4.01.02-1.517.208-3.153.702-4.491 1.767a.182.182 0 01-.214 0zm-8.338-.296c.96-.106 2.284-.17 3.644.016 1.363.186 2.708.616 3.76 1.453.091.073.189.135.291.186v13.659c-2.153-1.636-5.152-1.935-7.091-1.941-.787-.003-1.409-.618-1.409-1.29V5.83c0-.537.36-.93.805-.98zm9.195 15.314c2.153-1.636 5.152-1.935 7.091-1.941.787-.003 1.409-.618 1.409-1.29V5.83c0-.537-.36-.93-.805-.98-.96-.105-2.284-.169-3.644.017-1.363.186-2.708.616-3.76 1.453-.091.073-.189.135-.291.186v13.659z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.851 6.51c-1.826-1.477-4.075-2.124-6.128-2.374-2.059-.25-4-.11-5.292.057C2.96 4.383 2 5.655 2 7.033v15.782c0 1.844 1.614 3.184 3.325 3.155 2.471-.043 6.154.296 8.566 2.225 1.186.948 3.032.948 4.218 0 2.412-1.929 6.095-2.268 8.566-2.225C28.387 26 30 24.66 30 22.815V7.034c0-1.379-.96-2.652-2.43-2.841-1.293-.167-3.234-.307-5.294-.057-2.052.25-4.3.897-6.127 2.374a.252.252 0 01-.298 0zM4.686 6.176c1.197-.154 2.96-.278 4.796-.054 1.844.224 3.683.788 5.112 1.943.127.103.263.19.406.26v18.198c-2.993-2.298-7.215-2.596-9.71-2.553-.728.013-1.29-.56-1.29-1.155V7.034c0-.48.315-.81.686-.858zM17 26.523c2.993-2.298 7.215-2.596 9.71-2.553.728.013 1.29-.56 1.29-1.155V7.034c0-.48-.315-.81-.686-.858-1.197-.154-2.96-.278-4.796-.054-1.844.224-3.683.788-5.112 1.943-.127.103-.263.19-.406.26v18.198z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.383 13.786c-3.476-2.792-7.764-4.035-11.72-4.519-3.966-.485-7.716-.222-10.234.099C6.76 9.706 5 12.018 5 14.55v30.742c0 3.375 2.96 5.863 6.136 5.814 4.846-.075 12.12.592 16.96 4.414 2.193 1.733 5.616 1.733 7.81 0 4.84-3.822 12.114-4.489 16.96-4.414C56.038 51.155 59 48.667 59 45.29V14.55c0-2.532-1.76-4.845-4.43-5.184-2.518-.32-6.268-.584-10.234-.099-3.956.484-8.244 1.727-11.72 4.519a1.013 1.013 0 01-1.233 0zM9.808 12.342c2.374-.302 5.857-.542 9.491-.097 3.644.446 7.323 1.564 10.206 3.88.306.246.642.44.995.581v36.795a2.773 2.773 0 01-.546-.335c-5.712-4.511-13.921-5.136-18.865-5.06-1.704.026-3.09-1.31-3.09-2.815V14.55c0-1.184.793-2.08 1.809-2.208zM33.5 53.5c.196-.091.38-.203.546-.334 5.712-4.511 13.92-5.136 18.865-5.06 1.704.026 3.089-1.31 3.089-2.815V14.55c0-1.184-.793-2.08-1.809-2.208-2.374-.302-5.857-.542-9.49-.097-3.645.446-7.324 1.564-10.206 3.88-.307.246-.642.44-.995.581V53.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookOpenRegular)
