import { memo, useEffect, useRef } from 'react'

import { IconXLight } from '@ui/icons'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.scss'
import { IModalProps } from './types'

const Modal: React.FC<IModalProps> = ({
  title,
  description,
  firstButton,
  secondButton,
  open = false,
  onClose,
  className,
  children,
}) => {
  const classNames = cx(className, styles.modal, { [styles.open]: open })

  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose()
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalRef, onClose])

  return (
    //нужен еще блюр на задний фон и аутсайд клик не работает
    <div>
      <div ref={modalRef} className={classNames}>
        <div className={styles.title}>
          <Typography component="h2" size={24}>
            {title}
          </Typography>
          <IconXLight
            className={styles.pointer}
            size={16}
            onClick={() => onClose()}
          />
        </div>
        {description && <Typography size={16}>{description}</Typography>}
        {children}
        <div className={styles.button}>
          {firstButton}
          {secondButton}
        </div>
      </div>
      <div className={cx({ [styles.blur]: open })} />
    </div>
  )
}

export default memo(Modal)
