import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechScreenGenericLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 2A2.5 2.5 0 001 4.5v5A2.5 2.5 0 003.5 12h8A2.5 2.5 0 0014 9.5v-5A2.5 2.5 0 0011.5 2h-8zM2 4.5A1.5 1.5 0 013.5 3h8A1.5 1.5 0 0113 4.5v5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 9.5v-5z"
          fill="currentColor"
        />
        <path d="M4 13a.5.5 0 000 1h7a.5.5 0 000-1H4z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V6a3 3 0 00-3-3H5zM3 6a2 2 0 012-2h10a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2V6z"
        fill="currentColor"
      />
      <path d="M5 17a.5.5 0 000 1h10a.5.5 0 000-1H5z" fill="currentColor" />
    </svg>
  )
}

export default observer(IconTechScreenGenericLight)
