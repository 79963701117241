import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeDRadiusDownLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.002 13.443c0 .835-1.01 1.254-1.6.663L1.178 8.883a1.25 1.25 0 010-1.768l5.222-5.222c.59-.59 1.6-.173 1.6.663v2.441c1.028-.012 1.86-.079 2.695-.384.957-.35 1.963-1.034 3.24-2.425a.6.6 0 011.043.373c.165 3.019-.654 5.154-2.094 6.53-1.31 1.25-3.068 1.812-4.883 1.897v2.455zm-1-.15V10.5a.5.5 0 01.5-.5c1.822 0 3.506-.5 4.692-1.633 1.039-.992 1.756-2.527 1.803-4.805-1.057 1.03-1.999 1.64-2.958 1.99C9.89 5.972 8.762 6 7.502 6a.5.5 0 01-.5-.5V2.706L1.886 7.823a.25.25 0 000 .353l5.116 5.116z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.116c0 .933-1.128 1.4-1.787.74l-6.841-6.84a1.438 1.438 0 010-2.034l6.84-6.84c.66-.66 1.788-.193 1.788.74V6c1.56 0 2.714-.095 3.853-.541 1.14-.447 2.311-1.265 3.858-2.794.445-.44 1.248-.176 1.276.497.151 3.658-.82 6.379-2.502 8.19C14.805 13.16 12.467 14 10 14v3.116zm-1 0V13.5a.5.5 0 01.5-.5h.5c2.24 0 4.294-.76 5.753-2.33 1.377-1.48 2.273-3.736 2.249-6.894-1.407 1.334-2.581 2.142-3.784 2.614C12.9 6.907 11.591 7 10 7h-.5a.5.5 0 01-.5-.5V2.882a.046.046 0 00-.08-.033l-6.841 6.84a.438.438 0 000 .62l6.84 6.84c.03.03.08.01.08-.033z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusDownLeftLight)
