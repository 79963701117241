export enum EMainRoutes {
  Main = '/',

  MainCompany = '/company',
  CompanyEmployees = '/company/employees',
  CompanyEmployee = '/company/employees/:id',

  MainUserInfo = '/user',
  MainTraining = '/training',

  MainConfigurator = '/configurator',
  ConfiguratorCreate = '/configurator/create',
  ConfiguratorOrders = '/configurator/orders',
  ConfiguratorOrder = '/configurator/orders/:id',

  MainSales = '/sales',
  SalesTransactions = `/sales/transactions`,
  SalesTransaction = '/sales/transactions/:id',
  SalesRegisterTransaction = '/sales/registration',

  MainMarketing = '/marketing',
  MarketingEventsCalendar = '/marketing/events-calendar',
  MarketingEventCalendar = '/marketing/events-calendar/:id',
  MarketingPartnerNews = '/marketing/news',
  MarketingWebinars = '/marketing/webinars',
  MarketingEvents = '/marketing/events',
  MarketingMaterials = '/marketing/materials',
  MarketingFunds = '/marketing/funds',

  MainSupport = '/support',
  SupportPrivacyPolicy = '/support/privacy-policy',
  SupportWarranty = '/support/warranty',
  SupportDrivers = '/support/drivers',
  SupportContact = '/support/contact',
  SupportPartnersProgram = '/support/partners-program',

  MainProducts = '/products',
  CategoryProduct = '/products/:id',
  ProductsById = '/products/product/:id',
}

export const getRouteById = (id: number | string, route: EMainRoutes) =>
  route.replace(':id', typeof id === 'number' ? `${id}` : id)
