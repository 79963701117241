import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowUiShareAImportRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.69 2.867A3.249 3.249 0 016.5 1.25h6a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-6a3.25 3.25 0 01-3.25-3.25.75.75 0 011.5 0c0 .966.784 1.75 1.75 1.75h6a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-6c-.645 0-1.21.349-1.514.872a.75.75 0 11-1.297-.755z"
          fill="currentColor"
        />
        <path
          d="M.75 3a.75.75 0 01.75.75V4a3.25 3.25 0 003.25 3.25h4.94L7.97 5.53a.75.75 0 011.06-1.06l2.293 2.293a1.75 1.75 0 010 2.474L9.03 11.53a.75.75 0 01-1.06-1.06l1.72-1.72H4.75A4.75 4.75 0 010 4v-.25A.75.75 0 01.75 3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.752 4.124A3.749 3.749 0 018 2.25h7A3.75 3.75 0 0118.75 6v8A3.75 3.75 0 0115 17.75H8A3.75 3.75 0 014.25 14a.75.75 0 011.5 0A2.25 2.25 0 008 16.25h7A2.25 2.25 0 0017.25 14V6A2.25 2.25 0 0015 3.75H8c-.832 0-1.56.451-1.95 1.126a.75.75 0 11-1.298-.752z"
          fill="currentColor"
        />
        <path
          d="M1 4.25a.75.75 0 01.75.75v.625A3.625 3.625 0 005.375 9.25h6.564L9.47 6.78a.75.75 0 011.061-1.06l2.866 2.866a2 2 0 010 2.828L10.53 14.28a.75.75 0 11-1.06-1.06l2.47-2.47H5.374A5.125 5.125 0 01.25 5.625V5A.75.75 0 011 4.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.293 5.894A4.25 4.25 0 018.5 2.25h10a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-10a4.25 4.25 0 01-4.25-4.25v-.75a.75.75 0 011.5 0v.75a2.75 2.75 0 002.75 2.75h10a2.75 2.75 0 002.75-2.75v-11a2.75 2.75 0 00-2.75-2.75h-10a2.75 2.75 0 00-2.722 2.356.75.75 0 11-1.485-.212z"
          fill="currentColor"
        />
        <path
          d="M.75 5.25A.75.75 0 011.5 6v.75a4.5 4.5 0 004.5 4.5h8.19l-3.22-3.22a.75.75 0 011.06-1.06l3.44 3.439a2.25 2.25 0 010 3.182l-3.44 3.44a.75.75 0 11-1.06-1.061l3.22-3.22H6a6 6 0 01-6-6V6a.75.75 0 01.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 8.5A5.5 5.5 0 0111.5 3h14A5.5 5.5 0 0131 8.5v15a5.5 5.5 0 01-5.5 5.5h-14A5.5 5.5 0 016 23.5V22a1 1 0 112 0v1.5a3.5 3.5 0 003.5 3.5h14a3.5 3.5 0 003.5-3.5v-15A3.5 3.5 0 0025.5 5h-14A3.5 3.5 0 008 8.5V9a1 1 0 01-2 0v-.5z"
          fill="currentColor"
        />
        <path
          d="M1 7a1 1 0 011 1v1a6 6 0 006 6h11.586l-4.293-4.293a1 1 0 011.414-1.414l4.586 4.586a3 3 0 010 4.242l-4.586 4.586a1 1 0 01-1.414-1.414L19.586 17H8a8 8 0 01-8-8V8a1 1 0 011-1z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9 17.5C9 11.701 13.701 7 19.5 7h29C54.299 7 59 11.701 59 17.5v29C59 52.299 54.299 57 48.5 57h-29C13.701 57 9 52.299 9 46.5V42a1.5 1.5 0 013 0v4.5a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29a7.5 7.5 0 00-7.5-7.5h-29a7.5 7.5 0 00-7.5 7.5v.75a1.5 1.5 0 01-3 0v-.75z"
        fill="currentColor"
      />
      <path
        d="M1.5 15A1.5 1.5 0 013 16.5v2C3 25.404 8.596 31 15.5 31h21.843a2.527 2.527 0 00-.232-.268l-9.172-9.171a1.5 1.5 0 012.122-2.122l9.171 9.172a5.5 5.5 0 010 7.778l-9.171 9.172a1.5 1.5 0 01-2.122-2.122l9.172-9.171c.085-.085.162-.175.233-.268H15.5C6.94 34 0 27.06 0 18.5v-2A1.5 1.5 0 011.5 15z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowUiShareAImportRegular)
