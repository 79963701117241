import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeopleCircle1Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.75a2.748 2.748 0 100 5.496A2.748 2.748 0 008 3.75zM6.752 6.498a1.248 1.248 0 112.496 0 1.248 1.248 0 01-2.496 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-5.5a5.5 5.5 0 00-4.078 9.191c.44-.76 1.163-1.176 1.85-1.398.818-.264 1.682-.29 2.228-.29.546 0 1.41.026 2.227.29.688.223 1.412.638 1.85 1.398A5.5 5.5 0 008 2.5zm0 11c-1.059 0-2.048-.3-2.887-.818.164-.48.551-.777 1.12-.961.583-.188 1.25-.219 1.767-.219.517 0 1.184.03 1.767.219.569.184.956.48 1.12.961A5.474 5.474 0 018 13.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5a3.001 3.001 0 100 6.003A3.001 3.001 0 0010 5zM8.499 8a1.501 1.501 0 113.002 0 1.501 1.501 0 01-3.002 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.001 10A7.999 7.999 0 1118 10 7.999 7.999 0 012 10zM10 3.501a6.499 6.499 0 00-4.905 10.762c.096-.226.216-.435.359-.627.402-.54.942-.892 1.49-1.123 1.076-.452 2.316-.492 3.056-.492.74 0 1.98.04 3.055.492.55.231 1.09.583 1.491 1.123.143.192.263.4.36.627A6.499 6.499 0 0010 3.502zM10 16.5a6.469 6.469 0 01-3.66-1.129c.045-.367.166-.635.317-.838.2-.27.494-.478.87-.636.774-.326 1.748-.375 2.473-.375.725 0 1.7.049 2.474.375.375.158.669.367.869.636.151.203.272.471.318.838A6.469 6.469 0 0110 16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6a3.498 3.498 0 100 6.996A3.498 3.498 0 0012 6zm-1.998 3.498a1.998 1.998 0 113.996 0 1.998 1.998 0 01-3.996 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 3.75a8.25 8.25 0 00-5.997 13.916c.058-.762.317-1.398.731-1.91.464-.575 1.084-.947 1.713-1.19 1.24-.481 2.678-.526 3.553-.526s2.314.045 3.553.525c.629.244 1.249.616 1.713 1.19.414.513.673 1.149.731 1.91A8.25 8.25 0 0012 3.75zm0 16.5a8.211 8.211 0 01-4.51-1.34V18c0-.596.17-1.004.411-1.302.25-.31.62-.553 1.088-.734.957-.37 2.148-.424 3.011-.424s2.055.053 3.01.424c.47.181.838.424 1.089.734.241.298.41.706.41 1.302v.91A8.21 8.21 0 0112 20.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 7.5a5 5 0 100 10 5 5 0 000-10zm-3 5a3 3 0 116 0 3 3 0 01-6 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16zM16 5C9.925 5 5 9.925 5 16c0 2.8 1.046 5.356 2.77 7.298.17-.57.418-1.081.733-1.533.638-.913 1.504-1.521 2.408-1.927 1.775-.796 3.838-.87 5.089-.87 1.251 0 3.314.074 5.089.87.904.406 1.77 1.014 2.408 1.927.315.452.563.962.734 1.533A10.959 10.959 0 0027 16c0-6.075-4.925-11-11-11zm0 22a10.95 10.95 0 01-6.488-2.116c.055-.868.3-1.5.631-1.974.382-.547.926-.95 1.587-1.247 1.355-.608 3.042-.694 4.27-.694 1.228 0 2.915.086 4.27.694.661.296 1.205.7 1.587 1.247.33.473.576 1.106.631 1.974A10.95 10.95 0 0116 27z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 15c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm-7 10a7 7 0 1114 0 7 7 0 01-14 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 32C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26S6 46.36 6 32zM32 9C19.297 9 9 19.297 9 32c0 5.642 2.031 10.81 5.403 14.81a9.815 9.815 0 011.722-3.5c1.329-1.715 3.13-2.855 5.018-3.619 3.735-1.51 8.117-1.66 10.857-1.66 2.74 0 7.122.15 10.857 1.66 1.889.764 3.69 1.904 5.018 3.62a9.815 9.815 0 011.722 3.5A22.908 22.908 0 0055 32C55 19.297 44.703 9 32 9zm0 46a22.91 22.91 0 01-14.985-5.551c.098-1.867.667-3.249 1.482-4.302.913-1.178 2.216-2.046 3.77-2.675 3.152-1.273 7.02-1.44 9.733-1.44 2.714 0 6.581.167 9.733 1.44 1.554.629 2.857 1.497 3.77 2.675.815 1.053 1.384 2.435 1.482 4.302A22.91 22.91 0 0132 55z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeopleCircle1Regular)
