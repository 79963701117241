import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeADownRightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 14A1.75 1.75 0 0014 12.25v-8.8c0-.98-1.185-1.472-1.878-.779L9.87 4.923 7.118 2.17a1.5 1.5 0 00-2.121 0L2.17 4.998a1.5 1.5 0 000 2.12l2.752 2.754-2.251 2.252c-.693.693-.202 1.878.778 1.878l8.8-.001zm.75-1.75a.75.75 0 01-.75.75l-8.801.002a.094.094 0 01-.057-.016.106.106 0 01-.036-.046.106.106 0 01-.007-.058.093.093 0 01.029-.05l2.605-2.607a.5.5 0 000-.707L2.877 6.412a.5.5 0 010-.707l2.827-2.828a.5.5 0 01.707 0l3.106 3.106a.5.5 0 00.708 0l2.604-2.604a.099.099 0 01.17.07v8.802z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.968A1.969 1.969 0 0016.969 15V4.509c0-.975-1.179-1.463-1.868-.774l-2.46 2.461-3.613-3.613a1.719 1.719 0 00-2.431 0L2.584 6.598a1.719 1.719 0 000 2.43l3.612 3.613-2.46 2.461c-.69.69-.202 1.867.773 1.867h10.49zM16.03 15c0 .57-.462 1.031-1.031 1.031H4.509a.156.156 0 01-.11-.267l2.792-2.792a.469.469 0 000-.663L3.247 8.365a.781.781 0 010-1.105L7.26 3.246a.781.781 0 011.105 0l3.944 3.945a.469.469 0 00.663 0l2.792-2.793a.156.156 0 01.267.11V15z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeADownRightLight)
