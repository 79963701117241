import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Typography } from '@ui/Typography'

import FailCard from '@lib/components/FailCard'
import PageWrapper from '@lib/components/Page/PageWrapper'
import SuccessCard from '@lib/components/SuccessCard'
import { EStatusProcess } from '@lib/form'
import { EMainRoutes } from '@lib/navigation'

import MarketingFundsForm from '@modules/marketing/forms/MarketingFunds'
import { useMarketingFundsFormStore } from '@modules/marketing/store/MarketingFundsFormStore'

import styles from './styles.module.scss'

const MarketingFundsPage = observer(() => {
  const marketingFundsFormStore = useMarketingFundsFormStore()
  const navigate = useNavigate()

  useEffect(() => () => marketingFundsFormStore.clear(), [])

  const failedClick = useCallback(() => {
    marketingFundsFormStore.clear()
    navigate(EMainRoutes.MarketingFunds)
  }, [])

  const successClick = useCallback(() => {
    marketingFundsFormStore.clear()
    navigate(EMainRoutes.MainMarketing)
  }, [])

  const showForm =
    marketingFundsFormStore.status === EStatusProcess.IDLE ||
    marketingFundsFormStore.status === EStatusProcess.RUNNING

  return (
    <PageWrapper
      title={showForm && 'Маркетинговые средства'}
      fontSize={22}
      align="center">
      {showForm && (
        <>
          <Typography size={16} weight="light" className={styles.description}>
            Заполните все поля для запроса маркетинговых средств.
          </Typography>
          <MarketingFundsForm />
        </>
      )}

      {marketingFundsFormStore.status === EStatusProcess.SUCCESS && (
        <SuccessCard
          title="Запрос успешно оформлен, ожидайте ответа на почту"
          titleButton="Перейти в маркетинг"
          description="Мы скоро проверим ваш запрос на маркетинговые средства."
          handleClick={successClick}
          className={styles.resultCard}
          classDescriptionName={styles.descriptionCard}
        />
      )}

      {marketingFundsFormStore.status === EStatusProcess.FAIL && (
        <FailCard
          description="Во время запроса произошла ошибка, попробуйте выполнить запрос повторно."
          titleButton="Вернуться к маркетинговым средствам"
          handleClick={failedClick}
          classDescriptionName={styles.descriptionCard}
        />
      )}
    </PageWrapper>
  )
})

export default MarketingFundsPage
