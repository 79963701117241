import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSecurityLockOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.25 8.75c0 .409-.196.772-.5 1v1a.75.75 0 01-1.5 0v-1a1.25 1.25 0 112-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 3.75a3.75 3.75 0 117.5 0v.5a.75.75 0 01-1.5 0v-.5a2.25 2.25 0 00-4.5 0V5h.5A2.75 2.75 0 0113 7.75v3.5A2.75 2.75 0 0110.25 14h-6.5A2.75 2.75 0 011 11.25v-3.5A2.75 2.75 0 013.75 5h4.5V3.75zM3.75 6.5h6.5c.69 0 1.25.56 1.25 1.25v3.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-3.5c0-.69.56-1.25 1.25-1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.374 11.75c0 .482-.248.907-.624 1.152V14a.75.75 0 01-1.5 0v-1.098A1.373 1.373 0 019 10.376c.76 0 1.374.615 1.374 1.374z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 5.25a4.25 4.25 0 018.5 0v.25a.75.75 0 01-1.5 0v-.25a2.75 2.75 0 10-5.5 0V7H13a3 3 0 013 3v5a3 3 0 01-3 3H5a3 3 0 01-3-3v-5a3 3 0 013-3h6.25V5.25zM5 8.5h8a1.5 1.5 0 011.5 1.5v5a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 15v-5A1.5 1.5 0 015 8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.65 14.1a1.65 1.65 0 01-.9 1.47V17a.75.75 0 01-1.5 0v-1.43a1.65 1.65 0 112.4-1.47z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.25 6.5a5.25 5.25 0 1110.5 0V7a.75.75 0 01-1.5 0v-.5a3.75 3.75 0 10-7.5 0v1.75H15A3.75 3.75 0 0118.75 12v6A3.75 3.75 0 0115 21.75H5A3.75 3.75 0 011.25 18v-6A3.75 3.75 0 015 8.25h8.25V6.5zM5 9.75h10A2.25 2.25 0 0117.25 12v6A2.25 2.25 0 0115 20.25H5A2.25 2.25 0 012.75 18v-6A2.25 2.25 0 015 9.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M25 1a7 7 0 00-7 7v1a1 1 0 102 0V8a5 5 0 0110 0v1a1 1 0 102 0V8a7 7 0 00-7-7zM14 20.76a2.2 2.2 0 10-2 0V23a1 1 0 102 0v-2.24z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 16a5 5 0 015-5h14a5 5 0 015 5v8a5 5 0 01-5 5H6a5 5 0 01-5-5v-8zm5-3a3 3 0 00-3 3v8a3 3 0 003 3h14a3 3 0 003-3v-8a3 3 0 00-3-3H6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M38 15c0-7.18 5.82-13 13-13s13 5.82 13 13v1.5a1.5 1.5 0 01-3 0V15c0-5.523-4.477-10-10-10S41 9.477 41 15v1.5a1.5 1.5 0 01-3 0V15zM31 37c0 1.11-.603 2.08-1.5 2.599V44.5a1.5 1.5 0 01-3 0v-4.901A2.999 2.999 0 0128 34a3 3 0 013 3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 30.5a9.5 9.5 0 019.5-9.5h27a9.5 9.5 0 019.5 9.5v18a9.5 9.5 0 01-9.5 9.5h-27A9.5 9.5 0 015 48.5v-18zm9.5-6.5A6.5 6.5 0 008 30.5v18a6.5 6.5 0 006.5 6.5h27a6.5 6.5 0 006.5-6.5v-18a6.5 6.5 0 00-6.5-6.5h-27z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityLockOpenRegular)
