import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBlockLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM3.98 3.273a5.5 5.5 0 017.746 7.746L3.98 3.274zm-.707.707a5.5 5.5 0 007.746 7.746L3.274 3.98z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM5.27 4.564a6.5 6.5 0 019.165 9.165L5.272 4.564zm-.706.707a6.5 6.5 0 009.165 9.165L4.564 5.271z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBlockLight)
