import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconDeliveryBoxFrontOpenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.18 8H2v3.5A2.5 2.5 0 004.5 14h7a2.5 2.5 0 002.5-2.5V8h.99a1 1 0 00.955-1.298l-.828-2.65A1.5 1.5 0 0013.685 3h-11.2a1.5 1.5 0 00-1.431 1.053L.226 6.702A1 1 0 001.18 8zm1.305-4a.5.5 0 00-.477.35L1.18 7h6.127a.5.5 0 00.468-.324L8.78 4H2.485zm7.015.924l-.788 2.103A1.5 1.5 0 017.307 8H3v3.5A1.5 1.5 0 004.5 13H9V8.5a.5.5 0 011 0V13h1.5a1.5 1.5 0 001.5-1.5V8h-1.307a1.5 1.5 0 01-1.404-.973L9.5 4.924zM13.504 7h-1.811a.5.5 0 01-.468-.324L10.222 4h3.463a.5.5 0 01.478.35L14.99 7h-1.487z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3c.001 0 0 0 0 0H3.178a1.5 1.5 0 00-1.393.943l-1.2 3A1.5 1.5 0 001.977 9H2v5a3 3 0 003 3h10a3 3 0 003-3V9h.023a1.5 1.5 0 001.393-2.057l-1.2-3A1.5 1.5 0 0016.823 3h-5.322zM3.178 4a.5.5 0 00-.464.314l-1.2 3A.5.5 0 001.977 8h6.991a.5.5 0 00.47-.328L10.784 4H3.177zm5.791 5H3v5a2 2 0 002 2h6V9.5a.5.5 0 111 0V16h3a2 2 0 002-2V9h-2.823a1.5 1.5 0 01-1.393-.943l-1.264-3.16-1.143 3.12A1.5 1.5 0 018.968 9zm9.055-1a.5.5 0 00.464-.686l-1.2-3A.5.5 0 0016.823 4h-4.585l1.475 3.686a.5.5 0 00.464.314h3.846z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDeliveryBoxFrontOpenLight)
