import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFaceASadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.95 10.734C6.16 10.085 6.966 9.49 8 9.49c1.034 0 1.841.595 2.05 1.244a.75.75 0 001.428-.46C11.024 8.865 9.526 7.99 8 7.99c-1.526 0-3.024.876-3.478 2.283a.75.75 0 001.428.46z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 100 14A7 7 0 008 1zM2.5 8a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.463 13.291c.286-.975 1.322-1.745 2.537-1.745 1.215 0 2.25.77 2.537 1.745a.75.75 0 101.439-.422C13.48 11.182 11.8 10.047 10 10.047c-1.801 0-3.481 1.135-3.976 2.822a.75.75 0 001.44.422z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zm-6.499 8A6.499 6.499 0 1116.5 10 6.499 6.499 0 013.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.392 16.007c.415-1.419 1.899-2.504 3.608-2.504 1.71 0 3.193 1.085 3.607 2.504a.75.75 0 101.44-.42c-.622-2.13-2.748-3.584-5.047-3.584-2.299 0-4.425 1.454-5.047 3.583a.75.75 0 101.44.421z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.01 21.36c.569-1.908 2.614-3.385 4.99-3.385 2.375 0 4.42 1.477 4.99 3.384a1 1 0 101.916-.572c-.856-2.87-3.773-4.812-6.906-4.812-3.134 0-6.05 1.942-6.907 4.812a1 1 0 101.916.572z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM5 16C5 9.925 9.925 5 16 5s11 4.925 11 11-4.925 11-11 11S5 22.075 5 16z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M20.892 43.436C22.174 39.212 26.746 36 32.007 36c5.26 0 9.832 3.212 11.114 7.436a1.5 1.5 0 002.87-.872C44.267 36.878 38.37 33 32.008 33c-6.363 0-12.26 3.878-13.986 9.564a1.5 1.5 0 102.87.872z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zM9 32C9 19.297 19.297 9 32 9s23 10.297 23 23-10.297 23-23 23S9 44.703 9 32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFaceASadRegular)
