import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTechCamPhotoRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5a3.125 3.125 0 100 6.25A3.125 3.125 0 008 5zM6.375 8.125a1.625 1.625 0 113.25 0 1.625 1.625 0 01-3.25 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.618 1a1.75 1.75 0 00-1.565.967L4.536 3H3.75A2.75 2.75 0 001 5.75v5.5A2.75 2.75 0 003.75 14h8.5A2.75 2.75 0 0015 11.25v-5.5A2.75 2.75 0 0012.25 3h-.687l-.623-1.108A1.75 1.75 0 009.415 1H6.618zm-.224 1.638a.25.25 0 01.224-.138h2.797a.25.25 0 01.218.127l.695 1.236a1.25 1.25 0 001.09.637h.832c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-5.5c0-.69.56-1.25 1.25-1.25h.94a1.25 1.25 0 001.119-.691l.585-1.17z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 6.5a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm-2.25 3.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.368 2a1.75 1.75 0 00-1.565.968L6.286 4H5.25a3.25 3.25 0 00-3.25 3.25v6.498a3.25 3.25 0 003.25 3.25h9.502a3.25 3.25 0 003.25-3.25V7.25a3.25 3.25 0 00-3.25-3.25h-1.037l-.517-1.033A1.75 1.75 0 0011.632 2H8.368zm-.223 1.638a.25.25 0 01.223-.138h3.264a.25.25 0 01.223.138l.586 1.172a1.25 1.25 0 001.118.691h1.192c.967 0 1.75.784 1.75 1.75v6.498a1.75 1.75 0 01-1.75 1.75H5.25a1.75 1.75 0 01-1.75-1.75V7.25c0-.966.783-1.75 1.75-1.75H6.44a1.25 1.25 0 001.118-.69l.586-1.173z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.95a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm-3 4.5a3 3 0 116 0 3 3 0 01-6 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.927 2.25a2.25 2.25 0 00-2.012 1.244L7.036 5.25H6A3.75 3.75 0 002.25 9v8A3.75 3.75 0 006 20.75h12A3.75 3.75 0 0021.75 17V9A3.75 3.75 0 0018 5.25h-1.037l-.878-1.756a2.25 2.25 0 00-2.012-1.244H9.927zm-.67 1.915a.75.75 0 01.67-.415h4.146a.75.75 0 01.67.415l.948 1.894a1.25 1.25 0 001.118.691H18A2.25 2.25 0 0120.25 9v8A2.25 2.25 0 0118 19.25H6A2.25 2.25 0 013.75 17V9A2.25 2.25 0 016 6.75h1.191a1.25 1.25 0 001.118-.691l.947-1.894z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 10a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm-4.5 6.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.236 2a3 3 0 00-2.683 1.658L9.382 6H7.5A5.5 5.5 0 002 11.5v11A5.5 5.5 0 007.5 28h17a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 6h-1.882l-1.17-2.342A3 3 0 0018.763 2h-5.528zm-.894 2.553A1 1 0 0113.236 4h5.528a1 1 0 01.894.553l1.171 2.341A2 2 0 0022.618 8H24.5a3.5 3.5 0 013.5 3.5v11a3.5 3.5 0 01-3.5 3.5h-17A3.5 3.5 0 014 22.5v-11A3.5 3.5 0 017.5 8h1.882a2 2 0 001.789-1.106l1.17-2.341z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 22c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-9 12a9 9 0 1118 0 9 9 0 01-18 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.972 7a5.5 5.5 0 00-4.92 3.04l-2.203 4.407a1 1 0 01-.894.553H15.5C9.701 15 5 19.701 5 25.5v20C5 51.299 9.701 56 15.5 56h33C54.299 56 59 51.299 59 45.5v-20C59 19.701 54.299 15 48.5 15h-2.455a1 1 0 01-.894-.553l-2.204-4.407A5.5 5.5 0 0038.027 7H25.973zm-2.236 4.382A2.5 2.5 0 0125.972 10h12.056a2.5 2.5 0 012.236 1.382l2.203 4.407A4 4 0 0046.045 18H48.5a7.5 7.5 0 017.5 7.5v20a7.5 7.5 0 01-7.5 7.5h-33A7.5 7.5 0 018 45.5v-20a7.5 7.5 0 017.5-7.5h2.455a4 4 0 003.578-2.211l2.203-4.407z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechCamPhotoRegular)
