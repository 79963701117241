import { observer } from 'mobx-react-lite'

import { Typography } from '@ui/Typography'

import { getCompanyStatusLabel } from '../../models/Company'
import { useCompanyStore } from '../../store/CompanyStore'

import styles from './styles.module.scss'

const StatusCard = observer(() => {
  const companyStore = useCompanyStore()
  return (
    <div className={styles.card}>
      <Typography size={16} className={styles.status}>
        Ваш статус
      </Typography>
      <Typography size={24} className={styles.subtitle}>
        {getCompanyStatusLabel(companyStore.company.status)}
      </Typography>
    </div>
  )
})

export default StatusCard
