import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconLocationGlobeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM2.207 6C2.072 6.477 2 6.98 2 7.5s.072 1.023.207 1.5h2.497a14.08 14.08 0 010-3H2.207zM2.6 5h2.252a9.074 9.074 0 01.507-1.751c.156-.378.339-.723.548-1.015A5.515 5.515 0 002.6 5zm3.11 1a13.01 13.01 0 00-.085 1.5c0 .523.03 1.026.085 1.5H9.29c.056-.474.085-.977.085-1.5s-.03-1.026-.085-1.5H5.71zm3.42-1H5.872a7.84 7.84 0 01.41-1.369c.2-.483.426-.842.651-1.072.223-.228.415-.3.568-.3.152 0 .344.072.567.3.225.23.45.589.65 1.072.164.395.303.856.412 1.369zm1.167 1a14.083 14.083 0 010 3h2.496a5.507 5.507 0 000-3h-2.495zM12.4 5h-2.25a9.072 9.072 0 00-.507-1.751 5.094 5.094 0 00-.548-1.014A5.515 5.515 0 0112.4 5zm-6.493 7.766A5.515 5.515 0 012.6 10h2.252c.124.65.295 1.241.507 1.751.156.378.339.723.548 1.015zm.376-1.397A7.84 7.84 0 015.872 10H9.13a7.841 7.841 0 01-.411 1.369c-.2.483-.426.842-.651 1.072-.223.228-.415.3-.567.3-.153 0-.345-.072-.568-.3-.225-.23-.45-.589-.65-1.072zm3.36.382A9.07 9.07 0 0010.149 10H12.4a5.515 5.515 0 01-3.305 2.765c.209-.292.391-.636.548-1.014z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 100 16 8 8 0 000-16zM8.348 3.196c-2.07.5-3.78 1.921-4.674 3.804h2.8a9.973 9.973 0 01.717-2.165c.307-.645.696-1.22 1.157-1.639zM3 10c0-.695.101-1.366.29-2h3.009c-.087.638-.133 1.31-.133 2s.046 1.362.133 2h-3.01A7.001 7.001 0 013 10zm.674 3a7.014 7.014 0 004.674 3.804c-.461-.418-.85-.994-1.157-1.64A9.973 9.973 0 016.474 13h-2.8zM7.5 13c.154.65.356 1.236.594 1.735C8.676 15.958 9.384 16.5 10 16.5c.617 0 1.324-.542 1.906-1.765.238-.5.44-1.085.594-1.735h-5zm6.026 0a9.974 9.974 0 01-.717 2.165c-.307.645-.696 1.22-1.157 1.639 2.07-.5 3.78-1.921 4.674-3.804h-2.8zm3.184-1h-3.009c.087-.638.133-1.31.133-2s-.046-1.362-.133-2h3.01c.188.634.289 1.305.289 2 0 .695-.101 1.366-.29 2zm-4.019 0H7.31c-.093-.629-.143-1.3-.143-2s.05-1.371.143-2h5.382c.093.629.143 1.3.143 2s-.05 1.371-.143 2zm.835-5h2.8a7.015 7.015 0 00-4.674-3.804c.461.419.85.994 1.157 1.64.3.627.543 1.36.717 2.164zM8.094 5.265A8.759 8.759 0 007.5 7h5a8.757 8.757 0 00-.594-1.735C11.324 4.042 10.616 3.5 10 3.5c-.617 0-1.324.542-1.906 1.765z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLocationGlobeLight)
