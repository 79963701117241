import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechPhoneClassicLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.997 1.697c.591-.59 1.549-.59 2.14 0L6.67 3.23a1.519 1.519 0 010 2.149c-.318.317-.587.668-.733 1.013-.141.336-.154.632-.024.903.198.41.57.911 1.23 1.57.659.66 1.16 1.032 1.57 1.23.27.13.567.117.903-.024.345-.146.696-.415 1.014-.733a1.519 1.519 0 012.148 0l1.533 1.534c.59.59.59 1.548 0 2.139-1.192 1.192-2.918 2.039-4.691 1.65-1.495-.327-3.245-1.119-5.2-3.073-1.954-1.955-2.746-3.705-3.073-5.2-.389-1.773.458-3.5 1.65-4.691zm1.432.707c-.2-.2-.524-.2-.725 0-1.05 1.05-1.67 2.447-1.38 3.77.284 1.299.977 2.88 2.803 4.706 1.827 1.827 3.408 2.52 4.707 2.804 1.324.29 2.72-.33 3.77-1.38.2-.2.2-.525 0-.725l-1.533-1.534a.519.519 0 00-.734 0c-.366.366-.823.732-1.333.947-.52.219-1.125.292-1.724.004-.557-.268-1.15-.73-1.844-1.424-.694-.693-1.156-1.287-1.424-1.844-.288-.599-.215-1.205.004-1.724.215-.51.581-.967.946-1.333a.519.519 0 000-.734L4.43 2.404z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.966 2.436a1.735 1.735 0 012.453 0l1.87 1.87c.681.681.681 1.785 0 2.465-.392.393-.731.833-.916 1.272-.181.43-.203.825-.027 1.191.248.517.713 1.138 1.52 1.945.808.808 1.43 1.273 1.946 1.521.367.176.761.154 1.191-.027.44-.185.88-.524 1.272-.917.68-.68 1.784-.68 2.465 0l1.87 1.87a1.735 1.735 0 010 2.454c-1.438 1.438-3.507 2.447-5.621 1.984-1.802-.395-3.918-1.35-6.288-3.72S2.377 9.86 1.982 8.058c-.463-2.114.546-4.183 1.984-5.62zm1.746.707a.735.735 0 00-1.039 0C3.377 4.44 2.594 6.18 2.959 7.843c.352 1.605 1.208 3.553 3.45 5.794 2.241 2.242 4.189 3.098 5.794 3.45 1.664.365 3.404-.418 4.7-1.714a.735.735 0 000-1.04l-1.87-1.87a.743.743 0 00-1.051 0c-.44.44-.986.877-1.59 1.132-.613.258-1.319.34-2.013.006-.663-.318-1.377-.872-2.22-1.715-.842-.842-1.396-1.556-1.714-2.218-.334-.695-.252-1.4.006-2.014.255-.604.691-1.15 1.131-1.59.29-.29.29-.76 0-1.05l-1.87-1.87z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechPhoneClassicLight)
