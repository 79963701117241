import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBuildingHomeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.381 3.506a2.25 2.25 0 013.238 0l4.841 5.015a.75.75 0 101.08-1.042l-4.842-5.015a3.75 3.75 0 00-5.396 0L.46 7.48a.75.75 0 001.08 1.042L6.38 3.506z"
          fill="currentColor"
        />
        <path
          d="M4.457 8.817a.75.75 0 10-1.5 0v3.366a2.75 2.75 0 002.75 2.75h4.586a2.75 2.75 0 002.75-2.75V8.817a.75.75 0 00-1.5 0v3.366c0 .69-.56 1.25-1.25 1.25H5.707c-.69 0-1.25-.56-1.25-1.25V8.817z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.09 3.907a2.7 2.7 0 013.82 0l6.31 6.31a.75.75 0 101.06-1.06l-6.31-6.31a4.2 4.2 0 00-5.94 0L.72 9.157a.75.75 0 101.06 1.06l6.31-6.31z"
          fill="currentColor"
        />
        <path
          d="M5.5 10A.75.75 0 004 10v4.75A3.25 3.25 0 007.25 18h5.5A3.25 3.25 0 0016 14.75V10a.75.75 0 00-1.5 0v4.75a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75V10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.348 4.837a3.75 3.75 0 015.304 0l7.318 7.318a.75.75 0 101.06-1.06l-7.318-7.318a5.25 5.25 0 00-7.424 0L.97 11.095a.75.75 0 101.06 1.06l7.318-7.318z"
          fill="currentColor"
        />
        <path
          d="M5.75 13a.75.75 0 00-1.5 0v5A3.75 3.75 0 008 21.75h8A3.75 3.75 0 0019.75 18v-5a.75.75 0 00-1.5 0v5A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18v-5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.464 6.45a5 5 0 017.071 0l9.758 9.757a1 1 0 001.414-1.414L20.95 5.036a7 7 0 00-9.9 0l-9.757 9.757a1 1 0 101.414 1.414l9.758-9.757z"
          fill="currentColor"
        />
        <path
          d="M8 17a1 1 0 10-2 0v6.5a5.5 5.5 0 005.5 5.5h9a5.5 5.5 0 005.5-5.5V17a1 1 0 10-2 0v6.5a3.5 3.5 0 01-3.5 3.5h-9A3.5 3.5 0 018 23.5V17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M25.282 11.839a9.5 9.5 0 0113.436 0L58.939 32.06a1.5 1.5 0 002.122-2.122L40.839 9.717c-4.882-4.881-12.796-4.881-17.678 0L2.94 29.94a1.5 1.5 0 102.122 2.122l20.221-20.222z"
        fill="currentColor"
      />
      <path
        d="M14 33.5a1.5 1.5 0 00-3 0v14C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5v-14a1.5 1.5 0 00-3 0v14a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBuildingHomeRegular)
