import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowRoundTimeForwardRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.157 2.937a5.503 5.503 0 102.784 7.484.75.75 0 111.347.66 7.002 7.002 0 11-.789-7.416V2.128a.75.75 0 011.5 0v4.127a.75.75 0 01-.75.75h-3.515a.75.75 0 010-1.5h2.2a.749.749 0 01-.111-.155 5.503 5.503 0 00-2.666-2.413z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.431 3.974a6.498 6.498 0 103.421 8.852.75.75 0 111.351.652 7.998 7.998 0 11-.703-8.14V2.756a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75H13a.75.75 0 010-1.5h2.479a6.498 6.498 0 00-3.047-2.531z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.044 4.81a8.25 8.25 0 103.595 10.305.75.75 0 111.39.566 9.75 9.75 0 11-.779-8.877V3.246a.75.75 0 011.5 0V9a.75.75 0 01-.75.75h-5.5a.75.75 0 010-1.5h3.849a8.25 8.25 0 00-3.305-3.44z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.118 6.263a11 11 0 104.956 14.156 1 1 0 111.831.803A13 13 0 1127 9.072V5a1 1 0 112 0v6.5a1.5 1.5 0 01-1.5 1.5h-6a1 1 0 110-2h4.298a11 11 0 00-4.68-4.737z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M41.38 11a23 23 0 1010.952 31.753 1.5 1.5 0 012.652 1.403A26 26 0 1154 18.144V8a1.5 1.5 0 013 0v13.25A2.75 2.75 0 0154.25 24H41.5a1.5 1.5 0 010-3h10.699a23 23 0 00-10.818-10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowRoundTimeForwardRegular)
