import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconAttachLinkRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M.25 8A4.75 4.75 0 015 3.25h1.25a.75.75 0 010 1.5H5a3.25 3.25 0 100 6.5h1.25a.75.75 0 010 1.5H5A4.75 4.75 0 01.25 8zM15.75 8A4.75 4.75 0 0011 3.25H9.75a.75.75 0 000 1.5H11a3.25 3.25 0 010 6.5H9.75a.75.75 0 000 1.5H11A4.75 4.75 0 0015.75 8z"
          fill="currentColor"
        />
        <path
          d="M4.75 7.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M.25 10A5.75 5.75 0 016 4.25h1.75a.75.75 0 010 1.5H6a4.25 4.25 0 000 8.5h1.75a.75.75 0 010 1.5H6A5.75 5.75 0 01.25 10zM19.75 10A5.75 5.75 0 0014 4.25h-1.75a.75.75 0 000 1.5H14a4.25 4.25 0 010 8.5h-1.75a.75.75 0 000 1.5H14A5.75 5.75 0 0019.75 10z"
          fill="currentColor"
        />
        <path
          d="M6 9.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1 12a6 6 0 016-6h2.75a.75.75 0 010 1.5H7a4.5 4.5 0 000 9h2.75a.75.75 0 010 1.5H7a6 6 0 01-6-6zM23 12a6 6 0 00-6-6h-2.75a.75.75 0 000 1.5H17a4.5 4.5 0 110 9h-2.75a.75.75 0 000 1.5H17a6 6 0 006-6z"
          fill="currentColor"
        />
        <path
          d="M7.75 11.25a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1 16a8 8 0 018-8h4a1 1 0 110 2H9a6 6 0 000 12h4a1 1 0 110 2H9a8 8 0 01-8-8zM31 16a8 8 0 00-8-8h-4a1 1 0 100 2h4a6 6 0 010 12h-4a1 1 0 100 2h4a8 8 0 008-8z"
          fill="currentColor"
        />
        <path d="M10 15a1 1 0 100 2h12a1 1 0 100-2H10z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19 19c-7.18 0-13 5.82-13 13s5.82 13 13 13h6.5a1.5 1.5 0 010 3H19c-8.837 0-16-7.163-16-16s7.163-16 16-16h6.5a1.5 1.5 0 010 3H19zM45 19c7.18 0 13 5.82 13 13s-5.82 13-13 13h-6.5a1.5 1.5 0 000 3H45c8.837 0 16-7.163 16-16s-7.163-16-16-16h-6.5a1.5 1.5 0 000 3H45z"
        fill="currentColor"
      />
      <path
        d="M19.5 33.5h25a1.5 1.5 0 000-3h-25a1.5 1.5 0 000 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachLinkRegular)
