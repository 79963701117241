import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowFigureASquareLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 7.5a.5.5 0 01-.5.5H5.381l1.94 1.616a.5.5 0 01-.641.768L4.602 8.652a1.5 1.5 0 010-2.304L6.68 4.616a.5.5 0 11.64.768L5.381 7H10.5a.5.5 0 01.5.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3h7a3 3 0 013 3v7zm-3 2a2 2 0 002-2V4a2 2 0 00-2-2H4a2 2 0 00-2 2v7a2 2 0 002 2h7z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M13.5 9.5A.5.5 0 0013 9H6.781l2.54-2.116a.5.5 0 10-.641-.768L6.002 8.348a1.5 1.5 0 000 2.304l2.678 2.232a.5.5 0 00.64-.768L6.781 10H13a.5.5 0 00.5-.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5.5A3.5 3.5 0 0013.5 2h-8A3.5 3.5 0 002 5.5v8A3.5 3.5 0 005.5 17h8a3.5 3.5 0 003.5-3.5v-8zM13.5 3A2.5 2.5 0 0116 5.5v8a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 013 13.5v-8A2.5 2.5 0 015.5 3h8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareLeftLight)
