import React, { memo, ReactElement } from 'react'

import BadgeCount from '@ui/BadgeCount'
import { IRegularIconProps } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '../CardWrapper/CardWrapper'

import styles from './styles.module.scss'

interface StatisticsCardProps {
  Icon: React.FC<IRegularIconProps>
  title: string
  count: string | number
  badgeCount?: string | number
  description?: string
  firstButton?: ReactElement
  secondButton?: ReactElement
}

const StatisticsCard = ({
  Icon,
  title,
  count,
  badgeCount,
  description,
  firstButton,
  secondButton,
}: StatisticsCardProps) => (
  <CardWrapper>
    <div className={styles.container}>
      <div className={styles.content}>
        <Icon size={64} color={COLORS.BRAND.OCEAN} />
        <div>
          <div>
            <Typography size={16}>{title}</Typography>
          </div>
          <div className={styles.counter}>
            <Typography size={32}>{count}</Typography>
            {badgeCount && <BadgeCount count={badgeCount} />}
            {description && <Typography size={12}>{description}</Typography>}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {firstButton}
        {secondButton}
      </div>
    </div>
  </CardWrapper>
)

export default memo(StatisticsCard)
