import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseCHClosedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.432 9.379a1.95 1.95 0 000-2.758L3.78 3.969A.75.75 0 102.72 5.03L5.37 7.682a.45.45 0 010 .636L2.72 10.969a.75.75 0 101.06 1.061l2.652-2.651zM9.568 9.379a1.95 1.95 0 010-2.758l2.652-2.652a.75.75 0 011.06 1.061L10.63 7.682a.45.45 0 000 .636l2.651 2.651a.75.75 0 11-1.06 1.061L9.568 9.379z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.742 8.197a2.55 2.55 0 000 3.606l3.978 3.977a.75.75 0 101.06-1.06l-3.977-3.978a1.05 1.05 0 010-1.484L16.78 5.28a.75.75 0 00-1.06-1.06l-3.978 3.977zM8.258 8.197a2.55 2.55 0 010 3.606L4.28 15.78a.75.75 0 01-1.06-1.06l3.977-3.978a1.05 1.05 0 000-1.484L3.22 5.28a.75.75 0 011.06-1.06l3.978 3.977z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.058 13.68a2.375 2.375 0 000-3.36l-5.69-5.689A.875.875 0 103.132 5.87l5.69 5.689a.625.625 0 010 .884L3.13 18.13a.875.875 0 101.238 1.237l5.689-5.689zM13.942 13.68a2.375 2.375 0 010-3.36l5.69-5.689a.875.875 0 111.237 1.238l-5.69 5.689a.625.625 0 000 .884l5.69 5.689a.875.875 0 11-1.238 1.237l-5.689-5.689z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.94 18.475a3.5 3.5 0 000-4.95L4.706 5.293a1 1 0 00-1.414 1.414l8.232 8.232a1.5 1.5 0 010 2.122l-8.232 8.232a1 1 0 101.414 1.414l8.232-8.232zM19.06 18.475a3.5 3.5 0 010-4.95l8.233-8.232a1 1 0 111.414 1.414l-8.232 8.232a1.5 1.5 0 000 2.122l8.232 8.232a1 1 0 01-1.414 1.414l-8.232-8.232z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M24.818 37.303a7.5 7.5 0 000-10.606L8.561 10.439a1.5 1.5 0 00-2.122 2.122l16.258 16.257a4.5 4.5 0 010 6.364L6.439 51.44a1.5 1.5 0 102.122 2.12l16.257-16.257zM39.181 37.303a7.5 7.5 0 010-10.606L55.44 10.439a1.5 1.5 0 012.121 2.122L41.303 28.818a4.5 4.5 0 000 6.364L57.56 51.44a1.5 1.5 0 11-2.121 2.12L39.18 37.304z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseCHClosedRegular)
