import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconTechServerLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 4.75a.75.75 0 100-1.5.75.75 0 000 1.5zM4.75 9a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3a2 2 0 012-2h9a2 2 0 012 2v2c0 .597-.262 1.134-.677 1.5.415.366.677.903.677 1.5v2a2 2 0 01-2 2H8v2h3.5a.5.5 0 010 1h-8a.5.5 0 010-1H7v-2H3a2 2 0 01-2-2V8c0-.597.262-1.134.677-1.5A1.995 1.995 0 011 5V3zm11 3H3a1 1 0 01-1-1V3a1 1 0 011-1h9a1 1 0 011 1v2a1 1 0 01-1 1zM2 8a1 1 0 011-1h9a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1V8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M6.25 11.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM5.5 6.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.25A2.25 2.25 0 014.25 2h10.5A2.25 2.25 0 0117 4.25v2.5c0 .707-.326 1.338-.836 1.75.51.412.836 1.043.836 1.75v2.5A2.25 2.25 0 0114.75 15H10v3h4.5a.5.5 0 010 1h-10a.5.5 0 010-1H9v-3H4.25A2.25 2.25 0 012 12.75v-2.5c0-.707.326-1.338.836-1.75A2.246 2.246 0 012 6.75v-2.5zM14.75 14c.69 0 1.25-.56 1.25-1.25v-2.5C16 9.56 15.44 9 14.75 9H4.25C3.56 9 3 9.56 3 10.25v2.5c0 .69.56 1.25 1.25 1.25h10.5zm0-6H4.25C3.56 8 3 7.44 3 6.75v-2.5C3 3.56 3.56 3 4.25 3h10.5c.69 0 1.25.56 1.25 1.25v2.5C16 7.44 15.44 8 14.75 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTechServerLight)
