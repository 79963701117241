import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowFigureASquareRightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.25 8A.75.75 0 015 7.25h4.428L8.02 6.076a.75.75 0 01.96-1.152l2.078 1.732c.84.7.84 1.989 0 2.688L8.98 11.076a.75.75 0 01-.96-1.152L9.428 8.75H5A.75.75 0 014.25 8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 4.5A3.25 3.25 0 014.5 1.25h7a3.25 3.25 0 013.25 3.25v7a3.25 3.25 0 01-3.25 3.25h-7a3.25 3.25 0 01-3.25-3.25v-7zM4.5 2.75A1.75 1.75 0 002.75 4.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75v-7a1.75 1.75 0 00-1.75-1.75h-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 10a.75.75 0 01.75-.75h5.193L9.934 7.576a.75.75 0 01.96-1.152l2.679 2.232c.84.7.84 1.989 0 2.688l-2.679 2.232a.75.75 0 01-.96-1.152l2.009-1.674H6.75A.75.75 0 016 10z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6A3.75 3.75 0 016 2.25h8A3.75 3.75 0 0117.75 6v8A3.75 3.75 0 0114 17.75H6A3.75 3.75 0 012.25 14V6zM6 3.75A2.25 2.25 0 003.75 6v8A2.25 2.25 0 006 16.25h8A2.25 2.25 0 0016.25 14V6A2.25 2.25 0 0014 3.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.75 12a.75.75 0 01.75-.75h7.69l-2.72-2.72a.75.75 0 011.06-1.06l3.006 3.005a2.156 2.156 0 010 3.05L13.53 16.53a.75.75 0 11-1.06-1.06l2.72-2.72H7.5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6.5A4.25 4.25 0 016.5 2.25h11a4.25 4.25 0 014.25 4.25v11a4.25 4.25 0 01-4.25 4.25h-11a4.25 4.25 0 01-4.25-4.25v-11zM6.5 3.75A2.75 2.75 0 003.75 6.5v11a2.75 2.75 0 002.75 2.75h11a2.75 2.75 0 002.75-2.75v-11a2.75 2.75 0 00-2.75-2.75h-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 16a1 1 0 011-1h10.41l-3.308-3.308a1 1 0 011.414-1.414l3.6 3.6a2.99 2.99 0 01.877 2.001 1.024 1.024 0 010 .242 2.99 2.99 0 01-.877 2l-3.6 3.601a1 1 0 01-1.414-1.414L20.409 17H10a1 1 0 01-1-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8.5A5.5 5.5 0 018.5 3h15A5.5 5.5 0 0129 8.5v15a5.5 5.5 0 01-5.5 5.5h-15A5.5 5.5 0 013 23.5v-15zM8.5 5A3.5 3.5 0 005 8.5v15A3.5 3.5 0 008.5 27h15a3.5 3.5 0 003.5-3.5v-15A3.5 3.5 0 0023.5 5h-15z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M17.5 32a1.5 1.5 0 011.5-1.5h23.104l-7.94-7.94a1.5 1.5 0 012.122-2.12l8.378 8.378a4.5 4.5 0 010 6.364l-8.378 8.379a1.5 1.5 0 01-2.122-2.122l7.94-7.939H19a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17.5C7 11.701 11.701 7 17.5 7h29C52.299 7 57 11.701 57 17.5v29C57 52.299 52.299 57 46.5 57h-29C11.701 57 7 52.299 7 46.5v-29zM17.5 10a7.5 7.5 0 00-7.5 7.5v29a7.5 7.5 0 007.5 7.5h29a7.5 7.5 0 007.5-7.5v-29a7.5 7.5 0 00-7.5-7.5h-29z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowFigureASquareRightRegular)
