import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyWalletAOpenLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.626 9.126a.626.626 0 100-1.253.626.626 0 000 1.253z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.75 4a2.25 2.25 0 012.236 2H14a1 1 0 011 1v3a1 1 0 01-1 1v.75A2.25 2.25 0 0111.75 14h-8.5A2.25 2.25 0 011 11.75V4.33a2.25 2.25 0 011.648-2.168L9.098.37A1.5 1.5 0 0111 1.816V4h.75zM9.366 1.334a.5.5 0 01.634.482V4H3.5a.5.5 0 000 1h8.25c.605 0 1.11.43 1.225 1H10.5a2.5 2.5 0 000 5H13v.75c0 .69-.56 1.25-1.25 1.25h-8.5C2.56 13 2 12.44 2 11.75V4.33c0-.561.374-1.054.915-1.204l6.451-1.792zM10.5 7H14v3h-3.5a1.5 1.5 0 110-3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M14 11.875a.875.875 0 100-1.75.875.875 0 000 1.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.7v.307c.563.062 1 .539 1 1.118v3.75c0 .579-.438 1.056-1 1.118v.307a2.7 2.7 0 01-2.7 2.7H4.7A2.7 2.7 0 012 14.3V5.137A2.7 2.7 0 013.924 2.55L11.07.407A1.5 1.5 0 0113 1.844V5h2.3A2.7 2.7 0 0118 7.7zm-6.644-6.335a.5.5 0 01.644.479V5H5a.5.5 0 000 1h10.3A1.7 1.7 0 0117 7.7V8h-3a3 3 0 100 6h3v.3a1.7 1.7 0 01-1.7 1.7H4.7A1.7 1.7 0 013 14.3V5.137a1.7 1.7 0 011.212-1.628l7.144-2.144zM14 9h3.875c.069 0 .125.056.125.125v3.75a.125.125 0 01-.125.125H14a2 2 0 010-4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyWalletAOpenLight)
