import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { IconNetUploadRegular, IconXRegular } from '@ui/icons'
import { CommonIconSize } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import { useUploadsRest } from './services/UploadsRest'
import styles from './styles.scss'
import { IFile, IFileUploaderProps } from './types'

const ICON_SIZE = {
  small: 16,
  medium: 20,
  large: 24,
}

const getFileSize = (size: number): number => {
  return Math.round(((size + Number.EPSILON) * 100) / 2 ** 20) / 100
}

const FileUploader: FC<IFileUploaderProps> = ({
  className,
  label,
  loaderState,
  iconPosition = 'left',
  size = 'medium',
  state = 'default',
  file,
  messageFileSize = 10,
  accept,
  onSetFile,
}) => {
  const [localFile, setLocalFile] = useState<IFile>(null)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(null)

  const uploadRest = useUploadsRest()

  const resFile = localFile || file

  useEffect(() => {
    if (localFile) {
      onSetFile(localFile)
    }
  }, [localFile])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    onClear()

    try {
      const fileId = await uploadRest.uploadFileRequest(
        acceptedFiles[0],
        (event: ProgressEvent) =>
          event.lengthComputable &&
          setProgress(Math.round((100 * event.loaded) / event.total)),
      )

      // File successfully uploaded
      setLocalFile({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
        fileId,
      })
    } catch (e) {
      setError(e.message)
    } finally {
      setProgress(0)
    }
  }, [])

  const onClear = useCallback(() => {
    setLocalFile(null)
    setProgress(0)
    setError(null)
    onSetFile(null)
  }, [])

  const isUploading = progress > 0

  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    noClick: !!localFile,
    noKeyboard: true,
    disabled: isUploading,
    onDrop,
    accept,
  })

  const labelClass = cx(
    styles.label,
    styles[size],
    styles[state],
    progress && styles.disabled,
  )
  const loaderClass = cx(styles.loader, styles[loaderState], styles[size])

  useEffect(
    () => () => {
      onClear()
    },
    [],
  )

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={cx(styles.inputContainer, className)}>
      {resFile ? (
        <div className={loaderClass}>
          <div className={styles.content}>
            <IconXRegular className={styles.remove} onClick={onClear} />
            <span className={styles.text}>Загружен</span>
            <span className={styles.text}>{resFile.name}</span>
          </div>
          <div className={styles.text}>{getFileSize(resFile.size)}МБ</div>
        </div>
      ) : (
        <label className={labelClass}>
          {isUploading && (
            <div
              className={styles.progress}
              style={{ width: `${progress}%` }}
            />
          )}
          {iconPosition === 'left' && (
            <IconNetUploadRegular
              size={ICON_SIZE[size] as CommonIconSize}
              color={state === 'disabled' ? COLORS.GRAY.GRAY_2 : 'inherit'}
            />
          )}
          {label && (
            <div className={styles.text}>
              {label} <span className={styles.span}>или перетащите сюда</span>
            </div>
          )}
          {iconPosition === 'right' && (
            <IconNetUploadRegular
              size={ICON_SIZE[size] as CommonIconSize}
              color={state === 'disabled' ? COLORS.GRAY.GRAY_2 : 'inherit'}
            />
          )}
        </label>
      )}
      {error && <div className={styles.error}>{error}</div>}
      <Typography size={14} className={styles.fileSize}>
        Максимальный размер файла: {messageFileSize} MB.
      </Typography>
    </div>
  )
}

export default memo(FileUploader)
