import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMarketShoppingBasketRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.15 1.945a.75.75 0 00-1.2-.9l-2.25 3a.75.75 0 101.2.9l2.25-3zM5.75 9.5a.75.75 0 10-1.5 0v2a.75.75 0 001.5 0v-2zM8 8.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2A.75.75 0 018 8.75zM11.75 9.5a.75.75 0 00-1.5 0v2a.75.75 0 101.5 0v-2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.405 5.94A1.75 1.75 0 00.708 8.114l1.16 4.65a3 3 0 002.911 2.274h6.442a3 3 0 002.91-2.274l1.162-4.65a1.75 1.75 0 00-1.698-2.174H2.405zm-.242 1.81a.25.25 0 01.242-.31h11.19a.25.25 0 01.242.31l-1.16 4.65a1.5 1.5 0 01-1.456 1.137H4.78a1.5 1.5 0 01-1.455-1.136L2.163 7.75z"
          fill="currentColor"
        />
        <path
          d="M8.99.895a.75.75 0 011.05.15l2.25 3a.75.75 0 11-1.2.9l-2.25-3a.75.75 0 01.15-1.05z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.228 1.392a.75.75 0 011.047.169l2.708 3.75a.75.75 0 01-1.216.878l-2.708-3.75a.75.75 0 01.169-1.047zM8.941 2.44a.75.75 0 10-1.216-.88L5.017 5.31a.75.75 0 101.216.88l2.708-3.75zM7.25 10.75a.75.75 0 10-1.5 0v3.504a.75.75 0 001.5 0V10.75zM10 10a.75.75 0 01.75.75v3.504a.75.75 0 01-1.5 0V10.75A.75.75 0 0110 10zM14.25 10.75a.75.75 0 10-1.5 0v3.504a.75.75 0 101.5 0V10.75z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.028 7.25a2 2 0 00-1.934 2.51l1.467 5.568a3.25 3.25 0 003.143 2.422h8.592a3.25 3.25 0 003.143-2.422l1.467-5.569a2 2 0 00-1.934-2.509H3.028zm-.483 2.127a.5.5 0 01.483-.627h13.944a.5.5 0 01.483.627l-1.466 5.569a1.75 1.75 0 01-1.693 1.304H5.704a1.75 1.75 0 01-1.693-1.304L2.545 9.377z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.608 3.19a.75.75 0 10-1.216-.88l-3.25 4.5a.75.75 0 101.216.88l3.25-4.5zM8.75 13.5a.75.75 0 10-1.5 0v4a.75.75 0 001.5 0v-4zM12 12.75a.75.75 0 01.75.75v4a.75.75 0 11-1.5 0v-4a.75.75 0 01.75-.75zM16.75 13.5a.75.75 0 10-1.5 0v4a.75.75 0 001.5 0v-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.625 9.25a2.25 2.25 0 00-2.179 2.813l1.779 6.876a3.75 3.75 0 003.63 2.811h10.29a3.75 3.75 0 003.63-2.811l1.779-6.876a2.25 2.25 0 00-2.179-2.813H3.625zm-.726 2.438a.75.75 0 01.726-.938h16.75a.75.75 0 01.726.938l-1.778 6.875a2.25 2.25 0 01-2.178 1.687H6.855a2.25 2.25 0 01-2.178-1.687l-1.778-6.875z"
          fill="currentColor"
        />
        <path
          d="M13.56 2.142a.75.75 0 011.048.169l3.25 4.5a.75.75 0 11-1.216.878l-3.25-4.5a.75.75 0 01.169-1.047z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.3 4.6a1 1 0 10-1.6-1.2l-4.5 6a1 1 0 001.6 1.2l4.5-6zM12 18a1 1 0 10-2 0v6a1 1 0 002 0v-6zM16 17a1 1 0 011 1v6a1 1 0 01-2 0v-6a1 1 0 011-1zM22 18a1 1 0 10-2 0v6a1 1 0 002 0v-6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.58 12c-2.036 0-3.367 2.003-2.909 3.9l2.446 10.132C4.67 28.326 6.636 30 8.963 30h14.074c2.327 0 4.292-1.674 4.846-3.968L30.33 15.9c.458-1.897-.873-3.9-2.91-3.9H4.58zm-.965 3.43c-.193-.802.387-1.43.965-1.43h22.84c.578 0 1.158.628.965 1.43l-2.446 10.133C25.583 27.039 24.36 28 23.037 28H8.963c-1.323 0-2.546-.961-2.902-2.437L3.615 15.43z"
          fill="currentColor"
        />
        <path
          d="M17.9 3.2a1 1 0 011.4.2l4.5 6a1 1 0 01-1.6 1.2l-4.5-6a1 1 0 01.2-1.4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M28.2 8.9a1.5 1.5 0 00-2.4-1.8l-9 12a1.5 1.5 0 102.4 1.8l9-12zM24.5 35a1.5 1.5 0 10-3 0v12a1.5 1.5 0 103 0V35zM32 33.5a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-3 0V35a1.5 1.5 0 011.5-1.5zM42.5 35a1.5 1.5 0 10-3 0v12a1.5 1.5 0 103 0V35z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.597 24c-3.567 0-6.19 3.343-5.342 6.807L8.95 49.996A10.5 10.5 0 0019.15 58h25.7a10.5 10.5 0 0010.2-8.004l4.695-19.189C60.593 27.343 57.97 24 54.403 24H9.597zm-2.428 6.094A2.5 2.5 0 019.597 27h44.806a2.5 2.5 0 012.428 3.094l-4.695 19.189A7.5 7.5 0 0144.85 55H19.149a7.5 7.5 0 01-7.285-5.717L7.17 30.094z"
        fill="currentColor"
      />
      <path
        d="M36.1 6.8a1.5 1.5 0 012.1.3l9 12a1.5 1.5 0 01-2.4 1.8l-9-12a1.5 1.5 0 01.3-2.1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketShoppingBasketRegular)
