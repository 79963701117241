import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyCurrencyRubleCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 9h1.775a2.5 2.5 0 000-5H6.804A.804.804 0 006 4.804V7.8h-.4a.6.6 0 100 1.2H6v.8h-.4a.6.6 0 100 1.2H6v.75a.6.6 0 101.2 0V11h2.2a.6.6 0 100-1.2H7.2V9zm0-3.8v2.6h1.775a1.3 1.3 0 000-2.6H7.2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 11h2.15a2.75 2.75 0 100-5.5H8.483a.983.983 0 00-.983.983V9.5H7A.75.75 0 007 11h.5v1H7a.75.75 0 000 1.5h.5v.75a.75.75 0 001.5 0v-.75h2.5a.75.75 0 000-1.5H9v-1zm0-4v2.5h2.15a1.25 1.25 0 100-2.5H9z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.001 10A7.999 7.999 0 1118 10 7.999 7.999 0 012 10zM10 3.501A6.499 6.499 0 1010 16.5 6.499 6.499 0 0010 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 14h2.25a3.75 3.75 0 100-7.5h-2.581C9.523 6.5 9 7.023 9 7.669V12.5h-.75a.75.75 0 000 1.5H9v1h-.75a.75.75 0 000 1.5H9v1a.75.75 0 001.5 0v-1h3a.75.75 0 000-1.5h-3v-1zm0-6v4.5h2.25a2.25 2.25 0 000-4.5H10.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 18h3.5a4.5 4.5 0 100-9h-3.773C12.773 9 12 9.773 12 10.727V16h-1a1 1 0 100 2h1v1h-1a1 1 0 100 2h1v2a1 1 0 102 0v-2h4a1 1 0 100-2h-4v-1zm0-7v5h3.5a2.5 2.5 0 000-5H14z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16zM16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 37h7c5.523 0 10-4.477 10-10s-4.477-10-10-10h-6.954A3.045 3.045 0 0025 20.046V34h-2.5a1.5 1.5 0 000 3H25v3h-2.5a1.5 1.5 0 000 3H25v3.5a1.5 1.5 0 003 0V43h7.5a1.5 1.5 0 000-3H28v-3zm.003-16.972a.04.04 0 00-.003.018V34h7a7 7 0 100-14h-6.954c-.007 0-.012 0-.015.002l-.003.001a.048.048 0 00-.015.01.048.048 0 00-.01.015z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 32C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26S6 46.36 6 32zM32 9C19.297 9 9 19.297 9 32s10.297 23 23 23 23-10.297 23-23S44.703 9 32 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleCircleRegular)
