import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowAUpLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.75 3a.75.75 0 010 1.5H5.56l7.47 7.47a.75.75 0 11-1.06 1.06L4.5 5.56v5.19a.75.75 0 01-1.5 0v-6C3 3.784 3.784 3 4.75 3h6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.5 3.25a.75.75 0 010 1.5H5.81l10.72 10.72a.75.75 0 11-1.06 1.06L4.75 5.81v7.69a.75.75 0 01-1.5 0v-8A2.25 2.25 0 015.5 3.25h8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.5 3.125a.875.875 0 010 1.75H6.112L20.12 18.881a.875.875 0 11-1.238 1.238L4.875 6.112V16.5a.875.875 0 01-1.75 0V6A2.875 2.875 0 016 3.125h10.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.5 5a1 1 0 110 2h-13c-.028 0-.056 0-.084.002l17.791 17.79a1 1 0 01-1.414 1.415L7.003 8.417A1.52 1.52 0 007 8.5v13a1 1 0 11-2 0v-13A3.5 3.5 0 018.5 5h13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10 43.5a1.5 1.5 0 003 0v-26c0-.695.157-1.353.439-1.94l36 36a1.5 1.5 0 002.122-2.12L15.56 13.438A4.482 4.482 0 0117.5 13h26a1.5 1.5 0 000-3h-26a7.5 7.5 0 00-7.5 7.5v26z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowAUpLeftRegular)
