import React, { memo } from 'react'

import { Typography } from '@ui/Typography'

import styles from './RegistrationLayout.module.scss'

/**
 * Компонент информации об авторизации.
 */

type InfoLayoutProps = {
  title: string
  description?: string
}

const InfoLayout: React.FC<InfoLayoutProps> = ({ title, description }) => (
  <>
    <Typography className={styles.title} size={48}>
      {title}
    </Typography>
    <Typography className={styles.description} size={16}>
      {description}
    </Typography>
  </>
)

export default memo(InfoLayout)
