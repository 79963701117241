import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconBookmarkLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.5A2.5 2.5 0 014.5 1h6A2.5 2.5 0 0113 3.5v10.52a1 1 0 01-1.514.857L7.5 12.486l-3.986 2.391A1 1 0 012 14.02V3.5zM4.5 2A1.5 1.5 0 003 3.5v10.52l3.986-2.392a1 1 0 011.029 0L12 14.02V3.5A1.5 1.5 0 0010.5 2h-6z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5a3 3 0 013-3h6a3 3 0 013 3v11.486c0 1.203-1.344 1.916-2.34 1.243l-3.38-2.286a.5.5 0 00-.56 0l-3.38 2.286c-.996.673-2.34-.04-2.34-1.243V5zm3-2a2 2 0 00-2 2v11.486a.5.5 0 00.78.414l3.38-2.286a1.5 1.5 0 011.68 0l3.38 2.286a.5.5 0 00.78-.414V5a2 2 0 00-2-2H7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookmarkLight)
