import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCUpLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.94 5.853a1.5 1.5 0 012.12 0l3.794 3.793a.5.5 0 01-.708.707L8.354 6.561a.5.5 0 00-.708 0l-3.792 3.792a.5.5 0 11-.708-.707L6.94 5.853z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M3.396 12.979a.5.5 0 010-.708l4.836-4.836a2.5 2.5 0 013.536 0l4.836 4.836a.5.5 0 11-.707.707L11.06 8.143a1.5 1.5 0 00-2.122 0l-4.835 4.835a.5.5 0 01-.708 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCUpLight)
