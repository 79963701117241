import React, { useCallback } from 'react'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'

import { getImageUrl } from '@lib/helpers'
import { EAuthRoutes } from '@lib/navigation'

import {
  registerEmployee,
  registerPartner,
} from '@modules/auth/components/RegistrationLayout/Info'
import InfoLayout from '@modules/auth/components/RegistrationLayout/InfoLayout'
import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'

import styles from './RegistrationLayout.module.scss'

/**
 * Компонент авторизации.
 */

export const RegistrationLayout: React.FC = observer(() => {
  const animatedStore = useAnimatedFormStore()
  const navigate = useNavigate()

  const handleToAuth = useCallback(() => {
    animatedStore.setAnimatedState('none')
    navigate(EAuthRoutes.Auth)
  }, [])

  const { companyId } = useParams()

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to={EAuthRoutes.Registration}>
          <img
            alt="Логотип"
            src={getImageUrl('logo.svg')}
            width={128}
            height={34}
          />
        </Link>
        <div className={styles.navigation}>
          <span className={styles.hasAccount}>
            Уже есть аккаунт на RDW Computers?
          </span>
          <Button className={styles.auth} onClick={handleToAuth} type="link">
            Войти
          </Button>
        </div>
      </header>
      <div className={styles.content}>
        <div className={styles.side}>
          <img
            alt="Логотип"
            src={getImageUrl('logo-small.svg')}
            width={85}
            height={14}
          />
          <InfoLayout
            title={companyId ? registerEmployee.title : registerPartner.title}
            description={
              companyId
                ? registerEmployee.description
                : registerPartner.description
            }
          />
          <Button
            withBorder
            type="link"
            size="small"
            href="https://rdwcomp.ru/partners/o-sotrudnichestve/">
            Подробнее о партнерской программе
          </Button>
        </div>
        <Outlet />
      </div>
    </div>
  )
})
