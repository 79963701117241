import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconWarningCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9 11a1 1 0 11-2 0 1 1 0 012 0zM8.75 4.762a.75.75 0 00-1.5 0v3.566a.75.75 0 001.5 0V4.762z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 13a1 1 0 11-2 0 1 1 0 012 0zM10.75 6.688a.75.75 0 00-1.5 0v3.565a.75.75 0 101.5 0V6.687z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.001 10A7.999 7.999 0 1118 10 7.999 7.999 0 012 10zm8-6.499A6.499 6.499 0 1010 16.5 6.499 6.499 0 0010 3.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.125 16.127a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM12.75 7.25a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM17 10a1 1 0 10-2 0v7.5a1 1 0 102 0V10z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16zM16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 43a2 2 0 11-4 0 2 2 0 014 0zM33.5 20.5a1.5 1.5 0 00-3 0v15a1.5 1.5 0 003 0v-15z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 32C6 17.64 17.64 6 32 6s26 11.64 26 26-11.64 26-26 26S6 46.36 6 32zM32 9C19.297 9 9 19.297 9 32s10.297 23 23 23 23-10.297 23-23S44.703 9 32 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconWarningCircleRegular)
