import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDataChartBarsXRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 1.25a.75.75 0 01.75.75v9a.75.75 0 01-1.5 0V2a.75.75 0 01.75-.75zM6.75 4a.75.75 0 00-1.5 0v7a.75.75 0 001.5 0V4zM9 5.25a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0V6A.75.75 0 019 5.25zM3 7.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V8A.75.75 0 013 7.25zM.75 13.25a.75.75 0 000 1.5h13.5a.75.75 0 000-1.5H.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16 2.75a.75.75 0 01.75.75v10.719a.75.75 0 01-1.5 0V3.5a.75.75 0 01.75-.75zM8.75 6a.75.75 0 00-1.5 0v8.219a.75.75 0 001.5 0V6zM4 10a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 014 10zM1.25 18a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75zM12.75 8.35a.75.75 0 00-1.5 0v5.869a.75.75 0 101.5 0V8.35z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19 3.25a.75.75 0 01.75.75v13a.75.75 0 01-1.5 0V4a.75.75 0 01.75-.75zM9.75 7a.75.75 0 00-1.5 0v10a.75.75 0 001.5 0V7zM4 12.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75zM1 21a.75.75 0 01.75-.75h20.5a.75.75 0 010 1.5H1.75A.75.75 0 011 21zM14.75 10a.75.75 0 00-1.5 0v7a.75.75 0 001.5 0v-7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M25 3.25a1 1 0 011 1v17.5a1 1 0 11-2 0V4.25a1 1 0 011-1zM14 8.25a1 1 0 10-2 0v13.5a1 1 0 102 0V8.25zM7 15.25a1 1 0 011 1v5.5a1 1 0 11-2 0v-5.5a1 1 0 011-1zM3 27a1 1 0 011-1h24a1 1 0 110 2H4a1 1 0 01-1-1zM20 12.25a1 1 0 10-2 0v9.5a1 1 0 102 0v-9.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M49.5 8A1.5 1.5 0 0151 9.5v35a1.5 1.5 0 01-3 0v-35A1.5 1.5 0 0149.5 8zM27 17.5a1.5 1.5 0 00-3 0v27a1.5 1.5 0 003 0v-27zM13.5 32a1.5 1.5 0 011.5 1.5v11a1.5 1.5 0 01-3 0v-11a1.5 1.5 0 011.5-1.5zM6 55.5A1.5 1.5 0 017.5 54h49a1.5 1.5 0 010 3h-49A1.5 1.5 0 016 55.5zM39 25.5a1.5 1.5 0 00-3 0v19a1.5 1.5 0 003 0v-19z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartBarsXRegular)
