import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconNotificationBellLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a5 5 0 00-5 5v2.862l-1.429 2.38A.5.5 0 002 12h4v.5a2 2 0 104 0V12h4a.5.5 0 00.429-.757L13 8.862V6a5 5 0 00-5-5zM4 6a4 4 0 118 0v3a.5.5 0 00.071.257L13.117 11H2.883L3.93 9.257A.5.5 0 004 9V6zm5 6H7v.5a1 1 0 102 0V12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.625 16H3.19c-1.001 0-1.562-1.154-.943-1.941L3.91 11.94A.418.418 0 004 11.682V8a6 6 0 1112 0v3.682c0 .094.032.185.09.259l1.663 2.118c.619.787.058 1.941-.943 1.941h-4.435v.375a2.375 2.375 0 11-4.75 0V16zM5 8a5 5 0 1110 0v3.682c0 .318.107.627.303.877l1.664 2.117a.2.2 0 01-.157.324H3.19a.2.2 0 01-.157-.324l1.664-2.117c.196-.25.303-.559.303-.877V8zm6.375 8v.375a1.375 1.375 0 11-2.75 0V16h2.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconNotificationBellLight)
