import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSecurityKeyARegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M5 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3a5 5 0 104.584 7h1.419v.25c0 .966.784 1.75 1.75 1.75H14a1.75 1.75 0 001.75-1.75V7A1.75 1.75 0 0014 5.25H9.176A4.996 4.996 0 005 3zM1.5 8a3.5 3.5 0 016.59-1.647.75.75 0 00.661.397H14a.25.25 0 01.25.25v3.25a.25.25 0 01-.25.25h-1.247a.25.25 0 01-.25-.25v-1a.75.75 0 00-.75-.75h-2.69a.75.75 0 00-.717.53A3.502 3.502 0 011.5 8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.875 6.906a3.094 3.094 0 100 6.188 3.094 3.094 0 000-6.188zM5.281 10a1.594 1.594 0 113.188 0 1.594 1.594 0 01-3.188 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.875 3.781a6.219 6.219 0 105.7 8.707h1.81v.793c0 .76.615 1.375 1.374 1.375h2.21c.76 0 1.375-.615 1.375-1.375V9.22c0-.932-.756-1.688-1.688-1.688h-5.072a6.22 6.22 0 00-5.709-3.75zM2.156 10a4.719 4.719 0 019.063-1.847c.209.49.696.878 1.3.878h5.137c.104 0 .188.084.188.188v3.937h-1.96v-1.105c0-.587-.475-1.063-1.062-1.063h-2.31c-.604 0-1.09.385-1.3.874A4.72 4.72 0 012.156 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 6.188a5.812 5.812 0 105.343 8.106c.231-.538.764-.958 1.42-.958h2.773c.622 0 1.125.504 1.125 1.125v1.476h2.651v-4.874a.375.375 0 00-.375-.376h-6.163c-.66 0-1.193-.422-1.424-.962a5.814 5.814 0 00-5.35-3.537zM.937 12A7.313 7.313 0 0114.98 9.137c.01.023.024.038.035.046a.035.035 0 00.01.005h6.162c1.036 0 1.875.839 1.875 1.874v4.876a1.5 1.5 0 01-1.5 1.5h-2.65a1.5 1.5 0 01-1.5-1.5v-1.102h-2.398a.034.034 0 00-.009.005.108.108 0 00-.034.045A7.314 7.314 0 01.937 12zm14.087-2.813z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 9.938a2.062 2.062 0 100 4.124 2.062 2.062 0 000-4.124zM4.687 12a3.563 3.563 0 117.126 0 3.563 3.563 0 01-7.126 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 8a8 8 0 107.614 10.462c.26-.806 1.01-1.462 1.962-1.462H24.5a1.5 1.5 0 011.5 1.5V21h3v-6.5a.5.5 0 00-.5-.5h-8.305c-.863 0-1.568-.544-1.883-1.25A8.002 8.002 0 0011 8zM1 16C1 10.477 5.477 6 11 6c4.078 0 7.583 2.44 9.14 5.937a.14.14 0 00.044.057l.012.006H28.5a2.5 2.5 0 012.5 2.5V21a2 2 0 01-2 2h-3a2 2 0 01-2-2v-2h-3.424a.048.048 0 00-.013.008.146.146 0 00-.046.07C19.22 23.092 15.451 26 11 26 5.477 26 1 21.523 1 16zm19.197-4h-.001z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 13a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 23a9 9 0 100 18 9 9 0 000-18zm-6 9a6 6 0 1112 0 6 6 0 01-12 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13C11.507 13 3 21.507 3 32s8.507 19 19 19c7.833 0 14.555-4.74 17.462-11.5.132-.306.384-.438.575-.438h6.893V42.5a3.5 3.5 0 003.5 3.5h7.07a3.5 3.5 0 003.5-3.5v-13a4.5 4.5 0 00-4.5-4.5H40.063c-.191 0-.444-.132-.575-.44C36.595 17.768 29.856 13 22 13zM6 32c0-8.837 7.163-16 16-16 6.611 0 12.289 4.01 14.728 9.736.545 1.28 1.802 2.264 3.335 2.264H56.5a1.5 1.5 0 011.5 1.5v13a.5.5 0 01-.5.5h-7.07a.5.5 0 01-.5-.5v-3.938a2.5 2.5 0 00-2.5-2.5h-7.393c-1.529 0-2.783.979-3.33 2.252C34.254 44.014 28.591 48 22 48c-8.837 0-16-7.163-16-16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityKeyARegular)
