import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSettingsSlidersH2Light = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 4h5.55a2.5 2.5 0 014.9 0h1.55a.5.5 0 010 1h-1.55a2.5 2.5 0 01-4.9 0H1.5a.5.5 0 010-1zm8-1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM1.5 10a.5.5 0 000 1h1.55a2.5 2.5 0 004.9 0h5.55a.5.5 0 000-1H7.95a2.5 2.5 0 00-4.9 0H1.5zm5.5.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.798 7H2.5a.5.5 0 010-1h7.298a2.748 2.748 0 015.404 0H17.5a.5.5 0 010 1h-2.298a2.748 2.748 0 01-5.404 0zm.954-.5a1.748 1.748 0 113.496 0 1.748 1.748 0 01-3.496 0zM2 13.5a.5.5 0 01.5-.5h2.295a2.75 2.75 0 015.41 0H17.5a.5.5 0 110 1h-7.296a2.75 2.75 0 01-5.408 0H2.5a.5.5 0 01-.5-.5zm5.5-1.752a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSettingsSlidersH2Light)
