import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconSettingsSlidersH2Regular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.354 4.25H2a.75.75 0 000 1.5h5.354a2.751 2.751 0 005.292 0H14a.75.75 0 000-1.5h-1.354a2.751 2.751 0 00-5.292 0zM8.75 5a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM2 10.25a.75.75 0 000 1.5h1.354a2.751 2.751 0 005.292 0H14a.75.75 0 000-1.5H8.646a2.751 2.751 0 00-5.292 0H2zm4-.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 6.75h6.845a3.001 3.001 0 005.81 0h1.845a.75.75 0 000-1.5h-1.845a3.001 3.001 0 00-5.81 0H2.75a.75.75 0 000 1.5zM12.5 4.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM2.75 13.25a.75.75 0 100 1.5h1.845a3.001 3.001 0 005.81 0h6.845a.75.75 0 000-1.5h-6.845a3.001 3.001 0 00-5.81 0H2.75zM6 14a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.325 6.25H3a.75.75 0 000 1.5h8.325a3.751 3.751 0 007.35 0H21a.75.75 0 000-1.5h-2.325a3.751 3.751 0 00-7.35 0zM12.75 7a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM3 16.25a.75.75 0 000 1.5h2.325a3.751 3.751 0 007.35 0H21a.75.75 0 000-1.5h-8.325a3.751 3.751 0 00-7.35 0H3zm6-1.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 8h11.1a5.002 5.002 0 019.8 0H28a1 1 0 110 2h-3.1a5.002 5.002 0 01-9.8 0H4a1 1 0 010-2zm16-2a3 3 0 100 6 3 3 0 000-6zM4 22a1 1 0 100 2h3.1a5.002 5.002 0 009.8 0H28a1 1 0 100-2H16.9a5.002 5.002 0 00-9.8 0H4zm11 1a3 3 0 10-6 0 3 3 0 006 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 17h23.618c.719-4.534 4.646-8 9.382-8s8.663 3.466 9.382 8H56.5a1.5 1.5 0 010 3h-6.618c-.719 4.534-4.646 8-9.382 8s-8.663-3.466-9.382-8H7.5a1.5 1.5 0 010-3zm33-5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM7.5 44a1.5 1.5 0 000 3h6.618c.719 4.534 4.646 8 9.382 8s8.663-3.466 9.382-8H56.5a1.5 1.5 0 000-3H32.882c-.719-4.534-4.646-8-9.382-8s-8.663 3.466-9.382 8H7.5zM30 45.5a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSettingsSlidersH2Regular)
