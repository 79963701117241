import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import SuccessCard from '@lib/components/SuccessCard'
import { EMainRoutes } from '@lib/navigation'

import { useConfiguratorStore } from '@modules/configurator/store/ConfiguratorStore'

const SuccessConfiguratorCard = () => {
  const navigate = useNavigate()
  const configuratorStore = useConfiguratorStore()

  const handleClick = useCallback(() => {
    configuratorStore.resetAllData()
    navigate(EMainRoutes.ConfiguratorOrders)
  }, [])

  return (
    <SuccessCard
      title="Данные успешно отправлены!"
      description="Мы скоро проверим вашу заявку на созданную конфигурацию. Вы можете отслеживать историю заказа."
      handleClick={handleClick}
      titleButton="Перейти в историю заказов"
    />
  )
}

export default memo(SuccessConfiguratorCard)
