import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiMenuDots3HRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1.75 8a1.248 1.248 0 102.497 0A1.248 1.248 0 001.75 8zM8 9.248a1.248 1.248 0 110-2.496 1.248 1.248 0 010 2.496zM13.002 9.248a1.248 1.248 0 110-2.496 1.248 1.248 0 010 2.496z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.2 10a1.56 1.56 0 103.12 0 1.56 1.56 0 00-3.12 0zM10 11.56a1.56 1.56 0 110-3.12 1.56 1.56 0 010 3.12zM16.24 11.56a1.56 1.56 0 110-3.12 1.56 1.56 0 010 3.12z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.62 12.002a1.875 1.875 0 103.75 0 1.875 1.875 0 00-3.75 0zM11.998 13.877a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75zM19.5 13.878a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 16a2 2 0 104 0 2 2 0 00-4 0zM16 18a2 2 0 110-4 2 2 0 010 4zM25 18a2 2 0 110-4 2 2 0 010 4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10.5 32a3.5 3.5 0 107 0 3.5 3.5 0 00-7 0zM50 35.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zM32 35.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiMenuDots3HRegular)
