import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArchiveBoxOpenDocsRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 0a.65.65 0 100 1.3h6A.65.65 0 1011 0H5zM6.5 8.25a.75.75 0 100 1.5h3a.75.75 0 000-1.5h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.002 3.85c0-.635.515-1.15 1.15-1.15h7.696c.635 0 1.15.515 1.15 1.15V5.5h.252a.75.75 0 01.75.75v5.5A2.25 2.25 0 0111.75 14h-7.5A2.25 2.25 0 012 11.75v-5.5a.75.75 0 01.75-.75h.252V3.85zm8.696.15v1.5H4.302V4h7.396zM3.5 11.75V7h9v4.75a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.5.5a.75.75 0 000 1.5h7a.75.75 0 000-1.5h-7zM8 10.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 4.75C4.25 3.784 5.034 3 6 3h8c.966 0 1.75.784 1.75 1.75V7h.5a.75.75 0 01.75.75v7.5A2.75 2.75 0 0114.25 18h-8.5A2.75 2.75 0 013 15.25v-7.5A.75.75 0 013.75 7h.5V4.75zm10 0V7h-8.5V4.75A.25.25 0 016 4.5h8a.25.25 0 01.25.25zM4.5 15.25V8.5h11v6.75c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 .25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H8zM9 12.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H9z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 5.5A2.25 2.25 0 017.5 3.25h9a2.25 2.25 0 012.25 2.25v2.75h.75c.69 0 1.25.56 1.25 1.25V18A3.75 3.75 0 0117 21.75H7A3.75 3.75 0 013.25 18V9.5c0-.69.56-1.25 1.25-1.25h.75V5.5zm12 0v2.75H6.75V5.5a.75.75 0 01.75-.75h9a.75.75 0 01.75.75zM4.75 18V9.75h14.5V18A2.25 2.25 0 0117 20.25H7A2.25 2.25 0 014.75 18z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 1a1 1 0 000 2h12a1 1 0 100-2H10zM12 16a1 1 0 100 2h8a1 1 0 100-2h-8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 8a3 3 0 013-3h12a3 3 0 013 3v3h1a2 2 0 012 2v11.5a4.5 4.5 0 01-4.5 4.5h-15A4.5 4.5 0 014 24.5V13a2 2 0 012-2h1V8zm16 0v3H9V8a1 1 0 011-1h12a1 1 0 011 1zM6 13h20v11.5a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 016 24.5V13z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M18 5.5A1.5 1.5 0 0119.5 4h25a1.5 1.5 0 010 3h-25A1.5 1.5 0 0118 5.5zM24.5 32a1.5 1.5 0 000 3h15a1.5 1.5 0 000-3h-15z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 23A2.5 2.5 0 008 25.5v23a9.5 9.5 0 009.5 9.5h29a9.5 9.5 0 009.5-9.5v-23a2.5 2.5 0 00-2.5-2.5H51v-7.5a5.5 5.5 0 00-5.5-5.5h-27a5.5 5.5 0 00-5.5 5.5V23h-2.5zm5.5 0h32v-7.5a2.5 2.5 0 00-2.5-2.5h-27a2.5 2.5 0 00-2.5 2.5V23zm-5 25.5V26h42v22.5a6.5 6.5 0 01-6.5 6.5h-29a6.5 6.5 0 01-6.5-6.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArchiveBoxOpenDocsRegular)
