import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDataChartTrendXyUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M1.75 1.75a.75.75 0 00-1.5 0V12.5a2.25 2.25 0 002.25 2.25h11.75a.75.75 0 000-1.5H2.5a.75.75 0 01-.75-.75V1.75z"
          fill="currentColor"
        />
        <path
          d="M14.53 4.53a.75.75 0 00-1.06-1.06L9.5 7.44 7.742 5.681a1.05 1.05 0 00-1.484 0L3.47 8.47a.75.75 0 001.06 1.06L7 7.06l1.758 1.758c.41.41 1.074.41 1.485 0L14.53 4.53z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M2.75 4a.75.75 0 00-1.5 0v12A2.75 2.75 0 004 18.75h14a.75.75 0 000-1.5H4c-.69 0-1.25-.56-1.25-1.25V4z"
          fill="currentColor"
        />
        <path
          d="M18.464 6.468a.75.75 0 10-1.06-1.06l-5.36 5.359L9.73 8.453a1.25 1.25 0 00-1.767 0l-3.18 3.18a.75.75 0 001.06 1.061l3.003-3.003 2.314 2.313a1.25 1.25 0 001.768 0l5.536-5.536z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.75 3.75a.75.75 0 00-1.5 0V18A3.75 3.75 0 006 21.75h15.25a.75.75 0 000-1.5H6A2.25 2.25 0 013.75 18V3.75z"
          fill="currentColor"
        />
        <path
          d="M21.405 7.78a.75.75 0 00-1.06-1.06L14.5 12.564l-2.679-2.678a1.25 1.25 0 00-1.767 0L6.47 13.47a.75.75 0 101.06 1.06l3.407-3.407 2.68 2.679a1.25 1.25 0 001.767 0l6.021-6.022z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 5a1 1 0 00-2 0v18.5A4.5 4.5 0 007.5 28H28a1 1 0 100-2H7.5A2.5 2.5 0 015 23.5V5z"
          fill="currentColor"
        />
        <path
          d="M27.707 9.707a1 1 0 00-1.414-1.414L18.5 16.086l-3.69-3.69a1.5 1.5 0 00-2.12 0l-4.897 4.897a1 1 0 101.414 1.414l4.543-4.543 3.69 3.69a1.5 1.5 0 002.12 0l8.147-8.147z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9 9.5a1.5 1.5 0 10-3 0v39a8.5 8.5 0 008.5 8.5h42a1.5 1.5 0 000-3h-42A5.5 5.5 0 019 48.5v-39z"
        fill="currentColor"
      />
      <path
        d="M55.56 20.06a1.5 1.5 0 00-2.12-2.12L37 34.378l-7.555-7.556a2.75 2.75 0 00-3.89 0L15.94 36.44a1.5 1.5 0 002.122 2.122l9.439-9.44 7.556 7.556a2.75 2.75 0 003.889 0L55.56 20.06z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDataChartTrendXyUpRegular)
