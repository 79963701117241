import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import Card from '@ui/Card'
import { IconArchiveBoxRegular } from '@ui/icons'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'
import { getRouteById } from '@lib/navigation/MainRoutes'

import { useProductStore } from '../store/ProductStore'

import styles from './styles.module.scss'

const MainProductPage = () => {
  const productStore = useProductStore()

  useEffect(() => {
    productStore.ProductId = undefined
    productStore.getCategorysProduct()
  }, [])

  return (
    <PageWrapper title="Продукция">
      <div className={styles.cards}>
        {productStore.categorysProduct?.data?.map((category) => (
          <Card
            key={category.id}
            Icon={IconArchiveBoxRegular}
            title={category.shortName}
            description={category.shortDescription}
            link={getRouteById(category.id, EMainRoutes.CategoryProduct)}
          />
        ))}
      </div>
    </PageWrapper>
  )
}

export default observer(MainProductPage)
