import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'
import { IPaginationResponse } from '@lib/types'

import {
  IEditTransaction,
  IRegisterTransaction,
} from '../models/RegisterTransaction/RegisterTransaction'
import { ITransaction } from '../models/Transaction'

@singleton()
export class TransactionRest extends AbstractRest {
  async getTransaction(id: string) {
    const { data } = await this.http.get<ITransaction>(`/lk/sales/deals/${id}`)

    return data
  }

  async getTransactions() {
    const { data } = await this.http.get<IPaginationResponse<ITransaction>>(
      '/lk/sales/deals',
    )
    return data.data
  }

  async createTransaction(params: IRegisterTransaction) {
    const { data } = await this.http.post<ITransaction>(
      '/lk/sales/deals',
      params,
    )

    return data
  }

  async editTransaction(params: IEditTransaction) {
    const { data } = await this.http.patch<ITransaction>(
      `/lk/sales/deals/${params.id}`,
      params,
    )

    return data
  }
}
