import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconAttachPaperclipRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.658 2.573a3.431 3.431 0 014.853 4.852L8.826 13.11a4.772 4.772 0 11-6.748-6.748l4.006-4.006a.75.75 0 011.061 1.06L3.139 7.423a3.272 3.272 0 004.627 4.627l5.684-5.684a1.931 1.931 0 00-2.731-2.731L5.075 9.278a.59.59 0 10.835.835l3.966-3.966a.75.75 0 111.06 1.06l-3.965 3.967a2.09 2.09 0 01-2.957-2.957l5.644-5.644z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.12 3.83a3.64 3.64 0 115.147 5.148l-6.871 6.871a5.085 5.085 0 01-7.191-7.191l4.807-4.807a.75.75 0 011.06 1.06L4.266 9.72a3.585 3.585 0 005.07 5.07l6.872-6.872a2.14 2.14 0 00-3.027-3.026l-6.86 6.86a.695.695 0 00.982.984l4.797-4.797a.75.75 0 011.06 1.06l-4.796 4.798a2.195 2.195 0 11-3.105-3.105L12.12 3.83z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.677 4.88a4.271 4.271 0 116.041 6.04l-8.373 8.373a6.032 6.032 0 01-8.53-8.531l5.857-5.858a.75.75 0 111.061 1.06l-5.858 5.859a4.532 4.532 0 106.41 6.409l8.372-8.373a2.771 2.771 0 10-3.919-3.92L7.378 14.3a1.01 1.01 0 101.43 1.43l5.844-5.846a.75.75 0 011.061 1.06L9.868 16.79a2.51 2.51 0 11-3.55-3.55l8.36-8.36z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.291 6.428a5.842 5.842 0 118.262 8.262l-10.65 10.65A8.263 8.263 0 115.217 13.654l7.488-7.487a1 1 0 111.414 1.414l-7.488 7.488a6.263 6.263 0 008.858 8.857l10.65-10.65a3.842 3.842 0 00-5.434-5.434L9.925 18.623a1.421 1.421 0 002.01 2.01l7.618-7.619a1 1 0 011.414 1.415l-7.619 7.618A3.421 3.421 0 118.51 17.21l10.781-10.78z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M38.937 13.21c4.368-4.368 11.45-4.368 15.817 0 4.368 4.368 4.368 11.45 0 15.817l-21.3 21.3c-6.26 6.259-16.407 6.259-22.665 0-6.259-6.258-6.259-16.406 0-22.665l14.975-14.975a1.5 1.5 0 112.122 2.121L12.91 29.784c-5.087 5.087-5.087 13.335 0 18.422s13.335 5.087 18.422 0l21.3-21.3A8.184 8.184 0 1041.059 15.33L19.496 36.893a3.342 3.342 0 004.727 4.727L39.46 26.382a1.5 1.5 0 112.121 2.122L26.344 43.74a6.342 6.342 0 11-8.969-8.97L38.937 13.21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachPaperclipRegular)
