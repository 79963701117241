import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeTriangleALeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.158a1.25 1.25 0 00-1.919-1.056L4.016 6.944a1.25 1.25 0 000 2.112l6.065 3.841a1.25 1.25 0 001.92-1.056V4.158zm-1.5.455v6.774L5.152 8 10.5 4.613z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 5.38a1.35 1.35 0 00-2.107-1.118l-6.829 4.62a1.35 1.35 0 000 2.236l6.83 4.62A1.35 1.35 0 0014 14.62V5.38zm-1.5.283v8.675L6.088 10 12.5 5.663z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 5.436c0-1.386-1.536-2.222-2.7-1.47L4.893 10.53a1.75 1.75 0 000 2.94l10.157 6.563c1.164.752 2.7-.084 2.7-1.47V5.436zm-1.886-.21a.25.25 0 01.386.21v13.127a.25.25 0 01-.386.21L5.707 12.21a.25.25 0 010-.42l10.157-6.564z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 6.7c0-2.173-2.404-3.488-4.234-2.314l-14.507 9.3c-1.688 1.081-1.688 3.547 0 4.63l14.507 9.299C22.596 28.788 25 27.474 25 25.3V6.7zm-3.155-.63A.75.75 0 0123 6.7v18.6a.75.75 0 01-1.155.631l-14.507-9.3a.75.75 0 010-1.262l14.507-9.3z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 12.326c0-4.351-4.814-6.98-8.474-4.626L10.923 27.373c-3.368 2.165-3.368 7.088 0 9.253L41.526 56.3c3.66 2.353 8.474-.275 8.474-4.627V12.326zm-6.852-2.103c1.664-1.07 3.852.125 3.852 2.103v39.347c0 1.978-2.188 3.173-3.852 2.103L12.545 34.103c-1.53-.985-1.53-3.222 0-4.206l30.603-19.674z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeTriangleALeftRegular)
