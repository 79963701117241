import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

import Button from '@ui/Button'
import {
  IconArrowALeftRegular,
  IconArrowARightRegular,
  IconQuestionCircleLight,
} from '@ui/icons'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { useSliderControl } from '@lib/helpers'
import { EMainRoutes, getRouteById } from '@lib/navigation/MainRoutes'
import { cx } from '@lib/styles'

import {
  ICategoryProduct,
  IInfo,
  IProduct,
} from '@modules/products/models/Products'

import ProductsModal from '../ProductsModal/ProductsModal'

import styles from './TableProducts.module.scss'

SwiperCore.use([Controller])

const TableProducts: FC<ICategoryProduct> = ({ items }) => {
  const navigate = useNavigate()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [swiperDesc, setSwiperDesc] = useState<SwiperClass>()
  const { handleSlideChangeButton } = useSliderControl(swiper)
  const [scrollHide, setScrollHide] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<IInfo>(undefined)

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    swiper?.slideTo(swiper.realIndex)
    swiperDesc?.slideTo(swiper.realIndex)
  }, [])

  useEffect(() => {
    const element = document?.querySelector('.swiper-wrapper-scroll')

    element?.addEventListener('scroll', () => {
      if (element.scrollTop >= 10) {
        setScrollHide(true)
      } else {
        setScrollHide(false)
      }
    })
  }, [document])

  const swiperSettings = {
    speed: 1000,
    onSlideChange: handleSlideChange,
  }

  const fill = (i: number) => {
    return i == 0 || i % 2 === 0
  }

  const getProduct = useCallback((product: IProduct) => {
    navigate(getRouteById(product.id, EMainRoutes.ProductsById))
  }, [])

  const description = (product: IProduct) => (
    <div
      className={cx(styles.title, {
        [styles.titleScroll]: scrollHide,
      })}>
      <img
        src={product.images[0]}
        className={cx(styles.image, {
          [styles.imageScroll]: scrollHide,
        })}
        onClick={() => getProduct(product)}
      />

      <Typography
        className={cx(styles.name, {
          [styles.nameScroll]: scrollHide,
        })}
        onClick={() => getProduct(product)}
        size={16}>
        {product.name}
      </Typography>

      {!scrollHide && (
        <Button
          size="small"
          className={styles.more}
          onClick={() => getProduct(product)}>
          Подробнее
        </Button>
      )}
    </div>
  )

  const handleShowModal = useCallback((item: IInfo) => {
    setModalData(item)
    setIsShowModal(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsShowModal(false)
    setModalData(undefined)
  }, [])

  return (
    <div className={styles.table}>
      <div className={styles.container}>
        <div
          className={cx(styles.controls, {
            [styles.controlsScroll]: scrollHide,
          })}>
          <Button
            size="small"
            className={cx(styles.control, styles.controlLeft, {
              [styles.controlScroll]: scrollHide,
            })}
            type="fill"
            IconLeft={IconArrowALeftRegular}
            onClick={() => {
              handleSlideChangeButton('prev')
            }}
          />
          <Button
            size="small"
            IconRight={IconArrowARightRegular}
            className={cx(styles.control, styles.controlRight, {
              [styles.controlScroll]: scrollHide,
            })}
            type="fill"
            onClick={() => {
              handleSlideChangeButton('next')
            }}
          />
        </div>

        <div className={cx(scrollHide ? styles.scroll : styles.notScroll)}>
          <Swiper
            {...swiperSettings}
            slidesPerView={4}
            slidesPerGroup={1}
            className={styles.container}
            onSwiper={setSwiper}
            controller={{ control: swiperDesc }}>
            {items?.map((product, i) => (
              <SwiperSlide key={i} className={styles.slide}>
                <div className={styles.descriptionCard}>
                  {description(product)}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {!scrollHide && (
          <Typography
            size={22}
            weight="regular"
            color={COLORS.GRAY.OBSIDIAN}
            className={styles.subTitle}>
            Характеристики
          </Typography>
        )}

        <Swiper
          {...swiperSettings}
          slidesPerView={4}
          className={cx(styles.container, 'swiper-wrapper-scroll')}
          onSwiper={setSwiperDesc}
          controller={{ control: swiper }}>
          <div className={styles.labelContainer}>
            {items &&
              items[0].info?.map((item, i) => {
                return (
                  <Typography
                    component="div"
                    key={i}
                    color={COLORS.GRAY.GRAY_1}
                    className={styles.label}>
                    {item.label}
                    <IconQuestionCircleLight
                      onClick={() => handleShowModal(item)}
                    />
                  </Typography>
                )
              })}
          </div>
          {items?.map((product, i) => (
            <SwiperSlide key={i} className={styles.slide}>
              {product.info.map((data, index) => (
                <div
                  key={index}
                  className={cx(styles.info, styles.descriptionTable, {
                    [styles.infoBack]: fill(index),
                  })}>
                  <div className={styles.value}>
                    <Typography size={16} color={COLORS.GRAY.OBSIDIAN}>
                      {data.value}
                    </Typography>
                  </div>
                </div>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <ProductsModal
        handleCloseModal={handleCloseModal}
        isShowModal={isShowModal}
        label={modalData?.label}
        tooltip={modalData?.tooltip}
      />
    </div>
  )
}

export default memo(TableProducts)
