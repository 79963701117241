import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeDRadiusUpLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.002 2.557c0-.836-1.01-1.254-1.6-.663L1.178 7.116a1.25 1.25 0 000 1.768l5.222 5.223c.59.59 1.6.172 1.6-.663v-2.441c1.028.012 1.86.078 2.695.383.957.35 1.963 1.034 3.24 2.425a.6.6 0 001.043-.373c.165-3.018-.654-5.153-2.094-6.529-1.31-1.25-3.068-1.812-4.883-1.897V2.557zm-1 .15V5.5a.5.5 0 00.5.5c1.822 0 3.506.499 4.692 1.632 1.039.992 1.756 2.527 1.803 4.805-1.057-1.029-1.999-1.64-2.958-1.99C9.89 10.027 8.762 10 7.502 10a.5.5 0 00-.5.5v2.793L1.886 8.177a.25.25 0 010-.353l5.116-5.117z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.884c0-.933-1.128-1.4-1.787-.74L1.372 8.985a1.438 1.438 0 000 2.033l6.84 6.84c.66.66 1.788.193 1.788-.74V14c1.56 0 2.714.095 3.853.541 1.14.447 2.311 1.265 3.858 2.794.445.44 1.248.176 1.276-.496.151-3.658-.82-6.38-2.502-8.19C14.805 6.84 12.467 6 10 6V2.884zm-1 0V6.5a.5.5 0 00.5.5h.5c2.24 0 4.294.76 5.753 2.33 1.377 1.481 2.273 3.736 2.249 6.894-1.407-1.334-2.581-2.142-3.784-2.614C12.9 13.093 11.591 13 10 13h-.5a.5.5 0 00-.5.5v3.618c0 .043-.05.064-.08.034L2.079 10.31a.438.438 0 010-.619l6.84-6.841c.03-.03.08-.01.08.033z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusUpLeftLight)
