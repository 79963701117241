import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconUiFilterFunnelRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3.25C1 2.56 1.56 2 2.25 2h11.5c.69 0 1.25.56 1.25 1.25v1.989a1.75 1.75 0 01-.671 1.378l-3.83 2.998v3.345c0 .448-.24.862-.63 1.085L7.37 15.473a1.25 1.25 0 01-1.87-1.085V9.615L1.671 6.617A1.75 1.75 0 011 5.239V3.25zm1.5.25v1.74c0 .076.035.149.096.196L6.52 8.509c.302.237.479.6.479.985v4.463l1.998-1.142V9.494c0-.385.177-.748.48-.985l3.926-3.073a.25.25 0 00.096-.197V3.5h-11z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 3.5c0-.69.56-1.25 1.25-1.25h13c.69 0 1.25.56 1.25 1.25v2.505a1.75 1.75 0 01-.69 1.392l-4.27 3.254v4.813c0 .456-.249.876-.649 1.096L9.06 18.25a1.25 1.25 0 01-1.852-1.096v-6.504L2.94 7.397a1.75 1.75 0 01-.689-1.392V3.5zm1.5.25v2.255a.25.25 0 00.098.199l4.368 3.33c.31.236.492.604.492.993v6.206l2.582-1.417v-4.788c0-.39.182-.758.492-.995l4.37-3.33a.25.25 0 00.098-.198V3.75H3.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 5c0-.966.784-1.75 1.75-1.75h16c.966 0 1.75.784 1.75 1.75v2.864a1.95 1.95 0 01-.856 1.614L15.5 13.134v5.803c0 .516-.294.987-.757 1.213l-4.3 2.102A1.35 1.35 0 018.5 21.039v-7.905L3.106 9.478a1.95 1.95 0 01-.856-1.614V5zM4 4.75a.25.25 0 00-.25.25v2.864c0 .149.074.288.198.372l5.46 3.7c.37.252.592.67.592 1.118V20.8l4-1.955v-5.79c0-.448.222-.866.593-1.117l5.46-3.7a.45.45 0 00.197-.373V5a.25.25 0 00-.25-.25H4z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6.5A2.5 2.5 0 015.5 4h21A2.5 2.5 0 0129 6.5v3.25a3.5 3.5 0 01-1.4 2.8L21 17.5v7.977a1.8 1.8 0 01-1.078 1.649l-6.4 2.8A1.8 1.8 0 0111 28.276V17.5l-6.6-4.95A3.5 3.5 0 013 9.75V6.5zM5.5 6a.5.5 0 00-.5.5v3.25c0 .472.222.917.6 1.2l6.68 5.01c.453.34.72.873.72 1.44v10.571l6-2.625V17.4c0-.567.267-1.1.72-1.44l6.68-5.01a1.5 1.5 0 00.6-1.2V6.5a.5.5 0 00-.5-.5h-21z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M17.5 21a1.5 1.5 0 000 3h29a1.5 1.5 0 000-3h-29z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 9A5.5 5.5 0 007 14.5v5.976a8.5 8.5 0 002.674 6.19l13.854 13.038A1.5 1.5 0 0124 40.796v14.335c0 3.52 3.86 5.678 6.858 3.833l7-4.308A4.5 4.5 0 0040 50.824V40.796c0-.413.17-.809.472-1.092l13.854-13.039A8.5 8.5 0 0057 20.475V14.5A5.5 5.5 0 0051.5 9h-39zM10 14.5a2.5 2.5 0 012.5-2.5h39a2.5 2.5 0 012.5 2.5v5.976a5.5 5.5 0 01-1.73 4.005L38.416 37.519A4.5 4.5 0 0037 40.796v10.028a1.5 1.5 0 01-.714 1.277l-7 4.308A1.5 1.5 0 0127 55.13V40.796a4.5 4.5 0 00-1.416-3.277L11.73 24.481A5.5 5.5 0 0110 20.475V14.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconUiFilterFunnelRegular)
