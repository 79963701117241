import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocCheckRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.251 8.992a.6.6 0 10-.848-.849l-2.39 2.39a.4.4 0 01-.565 0l-.85-.851a.6.6 0 10-.849.848l.85.85a1.6 1.6 0 002.263 0l2.39-2.388z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.75A2.75 2.75 0 014.75 1h3.597c.464 0 .91.184 1.237.513l2.904 2.903c.328.328.512.773.512 1.237v6.597A2.75 2.75 0 0110.25 15h-5.5A2.75 2.75 0 012 12.25v-8.5zM4.75 2.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25h5.5c.69 0 1.25-.56 1.25-1.25v-5.5h-3c-.69 0-1.25-.56-1.25-1.25v-3h-2.5zm4 .3l2.45 2.45H8.75V2.8z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.284 11.576a.75.75 0 00-1.06-1.06L9.447 13.29a.25.25 0 01-.353 0l-1.04-1.04a.75.75 0 10-1.062 1.06l1.041 1.041a1.75 1.75 0 002.475 0l2.775-2.775z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.999 5.251a3.25 3.25 0 013.25-3.25h3.12c.464 0 .909.185 1.237.513l3.88 3.88c.328.328.512.773.512 1.238v7.12a3.25 3.25 0 01-3.25 3.25h-5.5a3.25 3.25 0 01-3.25-3.25v-9.5zm3.25-1.75a1.75 1.75 0 00-1.75 1.75v9.5c0 .967.783 1.75 1.75 1.75h5.5a1.75 1.75 0 001.75-1.75V8.75H11.5A2.25 2.25 0 019.25 6.5V3.501H7.249zm3.501.278l3.47 3.471H11.5a.75.75 0 01-.75-.75V3.78z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M16.256 13.274a.75.75 0 10-1.115-1.003l-3.653 4.059a.5.5 0 01-.726.02l-1.587-1.588a.75.75 0 00-1.06 1.06l1.587 1.588a2 2 0 002.9-.076l3.654-4.06z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.75 18V8.95c0-.729-.29-1.428-.805-1.944l-3.951-3.95a2.75 2.75 0 00-1.945-.806H8A3.75 3.75 0 004.25 6v12A3.75 3.75 0 008 21.75h8A3.75 3.75 0 0019.75 18zM8 3.75A2.25 2.25 0 005.75 6v12A2.25 2.25 0 008 20.25h8A2.25 2.25 0 0018.25 18V9.75H15A2.75 2.75 0 0112.25 7V3.75H8zm7 4.5h3.035a1.254 1.254 0 00-.151-.183l-3.95-3.95a1.253 1.253 0 00-.184-.152V7c0 .69.56 1.25 1.25 1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M21.603 18.037a1 1 0 10-1.415-1.414l-4.78 4.78a1 1 0 01-1.414 0l-1.682-1.683a1 1 0 00-1.415 1.414l1.683 1.683a3 3 0 004.243 0l4.78-4.78z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 24.5V11.774a4 4 0 00-1.172-2.829l-5.773-5.773A4 4 0 0017.226 2H10.5A5.5 5.5 0 005 7.5v17a5.5 5.5 0 005.5 5.5h11a5.5 5.5 0 005.5-5.5zM10.5 4A3.5 3.5 0 007 7.5v17a3.5 3.5 0 003.5 3.5h11a3.5 3.5 0 003.5-3.5V13h-5.5A3.5 3.5 0 0116 9.5V4h-5.5zm9 7h5.344a2.001 2.001 0 00-.43-.64L18.64 4.586a2 2 0 00-.64-.43V9.5a1.5 1.5 0 001.5 1.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34.5 11a1.5 1.5 0 011.5 1.5v6a3.5 3.5 0 003.5 3.5h7a1.5 1.5 0 010 3h-7a6.5 6.5 0 01-6.5-6.5v-6a1.5 1.5 0 011.5-1.5zM43.577 34.56a1.5 1.5 0 00-2.121-2.12L30.784 43.11a2.5 2.5 0 01-3.535 0l-3.672-3.67a1.5 1.5 0 00-2.121 2.12l3.671 3.672a5.5 5.5 0 007.778 0l10.672-10.671z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16.5C11 10.701 15.701 6 21.5 6h14.452a7.5 7.5 0 015.303 2.197l9.548 9.547A7.5 7.5 0 0153 23.048V47.5C53 53.299 48.299 58 42.5 58h-21C15.701 58 11 53.299 11 47.5v-31zM21.5 9a7.5 7.5 0 00-7.5 7.5v31a7.5 7.5 0 007.5 7.5h21a7.5 7.5 0 007.5-7.5V23.048a4.5 4.5 0 00-1.318-3.182l-9.548-9.548A4.5 4.5 0 0035.952 9H21.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocCheckRegular)
