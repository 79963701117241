import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMoneyCurrencyRubleRectRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.175 7.975H9a1.975 1.975 0 000-3.95H6.78a.756.756 0 00-.755.756v2.044H5.6a.575.575 0 100 1.15h.425v.95H5.6a.575.575 0 000 1.15h.425v.625a.575.575 0 101.15 0v-.625H9.4a.575.575 0 000-1.15H7.175v-.95zm0-2.8v1.65H9a.825.825 0 000-1.65H7.175z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.25A2.75 2.75 0 013.75 1.5h8.5A2.75 2.75 0 0115 4.25v6.5a2.75 2.75 0 01-2.75 2.75h-8.5A2.75 2.75 0 011 10.75v-6.5zM3.75 3c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25h-8.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.2 11h1.775a2.5 2.5 0 000-5H8.804A.804.804 0 008 6.804V9.8h-.4a.6.6 0 100 1.2H8v.8h-.4a.6.6 0 100 1.2H8v.625a.6.6 0 101.2 0V13h2.2a.6.6 0 100-1.2H9.2V11zm0-3.8v2.6h1.775a1.3 1.3 0 000-2.6H9.2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.999 6.75a3.25 3.25 0 013.25-3.25h9.502a3.25 3.25 0 013.25 3.25v6.5a3.25 3.25 0 01-3.25 3.25H5.25a3.25 3.25 0 01-3.25-3.25v-6.5zM5.249 5a1.75 1.75 0 00-1.75 1.75v6.5c0 .966.783 1.75 1.75 1.75h9.502a1.75 1.75 0 001.75-1.75v-6.5A1.75 1.75 0 0014.751 5H5.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 13H13a3 3 0 100-6h-2.75a1 1 0 00-1 1v3.5H9A.75.75 0 009 13h.25v1H9a.75.75 0 000 1.5h.25v.75a.75.75 0 001.5 0v-.75h2.5a.75.75 0 000-1.5h-2.5v-1zm0-4.5v3H13a1.5 1.5 0 000-3h-2.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 8A3.75 3.75 0 016 4.25h12A3.75 3.75 0 0121.75 8v8A3.75 3.75 0 0118 19.75H6A3.75 3.75 0 012.25 16V8zM6 5.75A2.25 2.25 0 003.75 8v8A2.25 2.25 0 006 18.25h12A2.25 2.25 0 0020.25 16V8A2.25 2.25 0 0018 5.75H6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 18h3.5a4.5 4.5 0 100-9h-3.773C12.773 9 12 9.773 12 10.727V16h-1a1 1 0 100 2h1v1h-1a1 1 0 100 2h1v1.5a1 1 0 102 0V21h4a1 1 0 100-2h-4v-1zm0-7v5h3.5a2.5 2.5 0 000-5H14z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 10.5A5.5 5.5 0 017.5 5h17a5.5 5.5 0 015.5 5.5v11a5.5 5.5 0 01-5.5 5.5h-17A5.5 5.5 0 012 21.5v-11zM7.5 7A3.5 3.5 0 004 10.5v11A3.5 3.5 0 007.5 25h17a3.5 3.5 0 003.5-3.5v-11A3.5 3.5 0 0024.5 7h-17z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 36h6.5a8.5 8.5 0 000-17h-6.636A2.864 2.864 0 0025 21.864V33h-2.5a1.5 1.5 0 000 3H25v3h-2.5a1.5 1.5 0 100 3H25v2.5a1.5 1.5 0 003 0V42h6.5a1.5 1.5 0 000-3H28v-3zm0-14v11h6.5a5.5 5.5 0 100-11H28z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21.5C6 15.701 10.701 11 16.5 11h31C53.299 11 58 15.701 58 21.5v21C58 48.299 53.299 53 47.5 53h-31C10.701 53 6 48.299 6 42.5v-21zM16.5 14A7.5 7.5 0 009 21.5v21a7.5 7.5 0 007.5 7.5h31a7.5 7.5 0 007.5-7.5v-21a7.5 7.5 0 00-7.5-7.5h-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleRectRegular)
