import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowShapeDRadiusUpLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.982c0-.846-1.023-1.27-1.622-.672L.574 7.115a1.25 1.25 0 000 1.768l5.804 5.805c.599.599 1.622.175 1.622-.672v-2.758c1.41.034 2.28.176 2.996.505.83.38 1.543 1.05 2.643 2.367a.75.75 0 001.324-.428c.225-3.159-.585-5.435-2.095-6.916C11.555 5.497 9.802 4.901 8 4.776V1.981zM6.5 3.31V5.5c0 .414.336.75.75.75h.003c1.804 0 3.437.5 4.565 1.607.843.827 1.471 2.058 1.638 3.844-.585-.565-1.166-.996-1.835-1.302-1.155-.53-2.485-.65-4.368-.65H7.25a.75.75 0 00-.75.75v2.19L1.81 7.998 6.5 3.31z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.957c0-1.114-1.346-1.672-2.134-.884l-6.69 6.69a1.75 1.75 0 000 2.474l6.69 6.69c.788.787 2.134.23 2.134-.884V14c1.32.006 2.358.054 3.394.395 1.14.375 2.343 1.132 3.866 2.746.575.609 1.692.293 1.728-.64.14-3.695-.916-6.355-2.738-8.085-1.687-1.601-3.94-2.32-6.25-2.408V2.957zm-1.5.603v3.19c0 .414.336.75.75.75h.254c2.217 0 4.261.627 5.713 2.005 1.261 1.198 2.156 3.038 2.27 5.747-1.28-1.194-2.437-1.89-3.624-2.281-1.426-.47-2.832-.472-4.36-.472H9.25a.75.75 0 00-.75.75v3.19l-6.263-6.263a.25.25 0 010-.353L8.5 3.56z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 3.448c0-1.202-1.454-1.805-2.305-.954l-8.178 8.18a1.875 1.875 0 000 2.65l8.178 8.18c.85.85 2.305.248 2.305-.955V17c1.8 0 3.18.008 4.564.454 1.37.441 2.807 1.336 4.608 3.24.604.638 1.777.306 1.814-.673.168-4.415-1.09-7.691-3.219-9.865-2.064-2.108-4.872-3.1-7.767-3.153V3.448zm-1.5.363V7.75c0 .414.336.75.75.75h.505c2.657 0 5.151.878 6.94 2.704 1.623 1.657 2.733 4.162 2.803 7.686-1.599-1.535-3.023-2.398-4.474-2.865-1.637-.527-3.247-.526-4.998-.525h-.776a.75.75 0 00-.75.75v3.937l-7.922-7.923a.375.375 0 010-.53l7.922-7.923z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.005 5.113c0-1.67-2.02-2.507-3.2-1.326l-9.92 9.916a3.25 3.25 0 000 4.597l9.92 9.915c1.18 1.181 3.2.345 3.2-1.326v-4.884c2.003.024 3.631.152 5.254.725 1.866.659 3.815 1.944 6.273 4.556.807.858 2.37.407 2.42-.894.223-5.862-1.447-10.01-4.31-12.682-2.6-2.426-6.062-3.518-9.637-3.687v-4.91zm-2 .302V11a1 1 0 001 1c3.594 0 6.913.97 9.272 3.172 2.125 1.983 3.594 5.077 3.69 9.702-2.165-2.109-4.083-3.338-6.042-4.03-2.244-.793-4.453-.844-6.92-.844a1 1 0 00-1 1v5.588l-9.706-9.703a1.25 1.25 0 010-1.768l9.706-9.702z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.999 10.226c0-2.895-3.501-4.345-5.548-2.298L4.56 28.82a4.5 4.5 0 000 6.364l20.89 20.89c2.047 2.048 5.548.598 5.548-2.297v-9.774c4.282.014 7.758.14 11.24 1.264 3.833 1.238 7.797 3.738 12.738 8.968 1.33 1.408 3.893.663 3.974-1.469.442-11.63-2.882-19.889-8.479-25.227-5.212-4.972-12.216-7.239-19.473-7.51v-9.802zm-3 0v11.275a1.5 1.5 0 001.5 1.5h.002c7.25 0 14.04 2.07 18.9 6.708 4.584 4.372 7.639 11.2 7.588 21.26-4.668-4.694-8.715-7.23-12.828-8.558-4.367-1.41-8.686-1.41-13.535-1.41h-.127a1.5 1.5 0 00-1.5 1.5v11.275c0 .22-.271.332-.427.177L6.682 33.062a1.5 1.5 0 010-2.121l20.89-20.891a.251.251 0 01.427.176z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeDRadiusUpLeftRegular)
