import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCheckCircleCutRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.114 2.836a5.498 5.498 0 013.237-.165.75.75 0 00.369-1.454A6.998 6.998 0 1014.894 9.2a.75.75 0 00-1.478-.257 5.497 5.497 0 11-7.302-6.106z"
          fill="currentColor"
        />
        <path
          d="M14.778 4.05a.75.75 0 00-1.06-1.06L8.35 8.354a.5.5 0 01-.707 0L5.758 6.47a.75.75 0 10-1.06 1.06l1.885 1.886a2 2 0 002.829 0l5.366-5.366z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.081 3.791a6.499 6.499 0 013.934.03.75.75 0 00.465-1.425 7.999 7.999 0 105.473 8.462.75.75 0 00-1.492-.16 6.498 6.498 0 11-8.38-6.907z"
          fill="currentColor"
        />
        <path
          d="M18.124 5.237a.75.75 0 10-1.06-1.06l-6.708 6.707a.813.813 0 01-1.15 0L6.878 8.555a.75.75 0 10-1.06 1.06l2.33 2.33a2.312 2.312 0 003.27 0l6.707-6.708z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.418 2.555a9.75 9.75 0 107.293 10.316.75.75 0 00-1.494-.134 8.25 8.25 0 11-6.17-8.73.75.75 0 00.371-1.452z"
          fill="currentColor"
        />
        <path
          d="M21.535 6.191a.75.75 0 10-1.06-1.06l-8.05 8.049c-.439.439-1.151.439-1.59 0l-2.987-2.987a.75.75 0 00-1.06 1.06l2.986 2.987a2.625 2.625 0 003.712 0l8.05-8.049z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.305 3.427a13 13 0 109.505 14.79 1 1 0 10-1.971-.341A11 11 0 1118.796 5.36a1 1 0 10.509-1.934z"
          fill="currentColor"
        />
        <path
          d="M28.733 8.23a1 1 0 00-1.414-1.414L16.587 17.55a1.5 1.5 0 01-2.121 0l-3.982-3.982a1 1 0 10-1.415 1.414l3.982 3.982a3.5 3.5 0 004.95 0L28.733 8.23z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M26.683 9.623a23 23 0 0114.066 1.106 1.5 1.5 0 001.142-2.774A26 26 0 1057.83 34.969a1.5 1.5 0 00-2.98-.342A23 23 0 1126.682 9.623z"
        fill="currentColor"
      />
      <path
        d="M56.56 16.56a1.5 1.5 0 10-2.12-2.12L32.974 35.903a3.5 3.5 0 01-4.95 0l-7.964-7.965a1.5 1.5 0 00-2.122 2.122l7.965 7.964a6.5 6.5 0 009.192 0l21.465-21.464z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCheckCircleCutRegular)
