import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconAttachPinRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.713.863C10.08.23 9.006.68 9.006 1.57c0 .862-.343 1.69-.952 2.299l-.18.18a3.25 3.25 0 01-2.299.952H3.163c-1.114 0-1.671 1.347-.884 2.134l2.763 2.763L1.97 12.97a.75.75 0 101.06 1.06l3.072-3.072 2.761 2.761c.788.788 2.134.23 2.134-.883v-2.413c0-.862.343-1.688.952-2.298l.18-.18a3.251 3.251 0 012.3-.953c.889 0 1.337-1.076.706-1.707L10.713.863zM9.115 4.929a4.75 4.75 0 001.266-2.277l2.965 2.965c-.855.2-1.646.636-2.277 1.267l-.18.18a4.75 4.75 0 00-1.392 3.359v1.809l-5.73-5.73h1.808c1.26 0 2.468-.501 3.36-1.392l.18-.18z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.041 1.793c-.661-.661-1.792-.193-1.792.742v.271a3.25 3.25 0 01-.952 2.298l-.377.377a3.25 3.25 0 01-2.298.953H4.66c-1.247 0-1.872 1.508-.99 2.39l3.222 3.222L2.47 16.47a.75.75 0 001.06 1.06l4.424-4.423 3.22 3.22c.882.882 2.39.257 2.39-.99v-2.961c0-.862.343-1.689.952-2.298l.377-.377a3.25 3.25 0 012.299-.953h.27c.935 0 1.404-1.13.742-1.792l-5.163-5.163zm-1.683 4.372a4.75 4.75 0 001.33-2.603l3.748 3.747a4.75 4.75 0 00-2.604 1.331l-.377.377a4.75 4.75 0 00-1.39 3.359v2.72L4.901 7.934h2.72c1.26 0 2.468-.501 3.359-1.392l.377-.377z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.66 1.769c-.704-.704-1.906-.206-1.906.79 0 1.38-.549 2.703-1.525 3.68l-.116.116a5.601 5.601 0 01-3.96 1.64H4.773c-1.336 0-2.006 1.616-1.06 2.561l4.334 4.335L3.22 19.72a.75.75 0 101.06 1.06l4.829-4.828 4.332 4.332c.945.945 2.56.276 2.56-1.06v-3.38c0-1.485.59-2.91 1.641-3.96l.116-.116a5.205 5.205 0 013.68-1.525c.995 0 1.494-1.202.79-1.906L15.66 1.77zm-2.37 5.53a6.707 6.707 0 001.907-3.872L20.569 8.8a6.705 6.705 0 00-3.871 1.907l-.117.116a7.101 7.101 0 00-2.08 5.021v3.38L4.775 9.494h3.378c1.884 0 3.69-.748 5.022-2.08l.116-.116z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.447 2.471c-.954-.953-2.584-.278-2.584 1.07 0 1.913-.76 3.748-2.112 5.1l-.176.176a7.48 7.48 0 01-5.29 2.191H6.167c-2.071 0-3.108 2.505-1.644 3.97l5.542 5.542-6.755 6.754a1 1 0 101.415 1.415l6.754-6.755 5.538 5.537c1.465 1.465 3.97.428 3.97-1.644V21.71a7.48 7.48 0 012.19-5.289l.176-.176a7.213 7.213 0 015.1-2.112c1.349 0 2.024-1.63 1.07-2.584l-9.076-9.077zm-3.282 7.585a9.213 9.213 0 002.631-5.407l7.55 7.55a9.213 9.213 0 00-5.407 2.631l-.176.176a9.48 9.48 0 00-2.777 6.703v4.118c0 .29-.35.435-.555.23L5.937 13.563a.325.325 0 01.23-.555h4.118a9.48 9.48 0 006.704-2.776l.176-.176z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.715 4.513c-1.838-1.837-4.98-.536-4.98 2.063 0 3.61-1.435 7.074-3.988 9.627l-.569.57a14.5 14.5 0 01-10.253 4.246h-8.721c-4.01 0-6.017 4.847-3.182 7.682L21.6 40.279 7.44 54.44a1.5 1.5 0 102.12 2.122L23.722 42.4l11.56 11.558c2.834 2.835 7.681.828 7.681-3.181v-8.722a14.5 14.5 0 014.247-10.253l.569-.569a13.615 13.615 0 019.627-3.988c2.6 0 3.9-3.142 2.063-4.98L41.715 4.514zm-6.847 13.812a16.615 16.615 0 004.866-11.55l17.472 17.473a16.615 16.615 0 00-11.55 4.865l-.568.569a17.5 17.5 0 00-5.126 12.374v8.722c0 1.336-1.616 2.005-2.56 1.06L12.143 26.58c-.945-.945-.276-2.561 1.06-2.561h8.722A17.5 17.5 0 0034.3 18.893l.568-.568z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconAttachPinRegular)
