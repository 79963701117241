import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowParallelHRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.581 1.28A.75.75 0 1110.637.216l2.61 2.586c.307.305.473.698.496 1.098a.763.763 0 010 .202c-.024.4-.19.793-.496 1.097l-2.61 2.586A.75.75 0 119.581 6.72l1.987-1.97H3a.75.75 0 010-1.5h8.568L9.581 1.282zM6.418 8.285a.75.75 0 00-1.055-1.066L2.755 9.8a1.68 1.68 0 00-.497 1.089.754.754 0 000 .22c.026.397.191.787.497 1.09l2.608 2.581a.75.75 0 001.055-1.066L4.433 11.75H13a.75.75 0 000-1.5H4.433l1.985-1.965z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.106 2.18a.75.75 0 111.061-1.06l3.273 3.273c.75.75.75 1.967 0 2.718l-3.273 3.273a.75.75 0 01-1.06-1.06l2.82-2.822H3.75a.75.75 0 110-1.5h11.178L12.106 2.18zM7.894 10.68a.75.75 0 10-1.061-1.062L3.56 12.891c-.75.75-.75 1.968 0 2.718l3.273 3.273a.75.75 0 101.06-1.06L5.074 15H16.25a.75.75 0 100-1.5H5.072l2.822-2.82z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M14.634 2.61a.75.75 0 011.06-1.06l3.928 3.927a2.156 2.156 0 010 3.05l-3.928 3.927a.75.75 0 11-1.06-1.06l3.641-3.642H4.5a.75.75 0 110-1.5h13.775L14.634 2.61zM9.362 12.61a.75.75 0 00-1.06-1.06l-3.928 3.927a2.156 2.156 0 000 3.049l3.928 3.927a.75.75 0 001.06-1.06L5.721 17.75h13.775a.75.75 0 100-1.5H5.721l3.641-3.641z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.512 3.145a1 1 0 011.414-1.415l5.237 5.237a2.875 2.875 0 010 4.066l-5.237 5.237a1 1 0 01-1.414-1.415L24.367 10H6a1 1 0 110-2h18.367l-4.855-4.855zM12.489 17.145a1 1 0 00-1.415-1.415l-5.236 5.237a2.875 2.875 0 000 4.066l5.236 5.237a1 1 0 001.415-1.415L7.633 24H26a1 1 0 100-2H7.633l4.856-4.855z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M39.377 6.436a1.5 1.5 0 112.121-2.122l10.473 10.474a5.25 5.25 0 010 7.424L41.498 32.686a1.5 1.5 0 01-2.121-2.122L49.85 20.091c.03-.03.058-.06.086-.091H12a1.5 1.5 0 010-3h37.936a2.376 2.376 0 00-.086-.091L39.377 6.436zM24.623 33.436a1.5 1.5 0 00-2.121-2.122L12.028 41.788a5.25 5.25 0 000 7.424l10.474 10.474a1.5 1.5 0 002.121-2.122L14.15 47.091c-.03-.03-.059-.06-.086-.091H52a1.5 1.5 0 000-3H14.064c.027-.03.056-.061.086-.091l10.473-10.473z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowParallelHRegular)
