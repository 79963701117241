import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSecurityKeyALight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6a2 2 0 100 4 2 2 0 000-4zM5 8a1 1 0 112 0 1 1 0 01-2 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.998A5.002 5.002 0 1010.586 10H12v.75c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25v-3.5C16 6.56 15.44 6 14.75 6h-4.164A5.003 5.003 0 006 2.998zM1.998 8a4.002 4.002 0 017.776-1.333.5.5 0 00.472.333h4.504a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.5-.5h-2.254a.5.5 0 00-.472.333A4.004 4.004 0 011.998 8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 7.156a2.844 2.844 0 100 5.688 2.844 2.844 0 000-5.688zM5.031 10a1.844 1.844 0 113.688 0 1.844 1.844 0 01-3.688 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 4.031a5.969 5.969 0 105.486 8.325c.036-.086.105-.118.15-.118h2.123v1.043c0 .622.504 1.125 1.125 1.125h2.21c.621 0 1.125-.503 1.125-1.125V9.22c0-.794-.644-1.438-1.438-1.438H12.52c-.046 0-.115-.032-.151-.118A5.97 5.97 0 006.875 4.03zM1.906 10a4.969 4.969 0 019.543-1.945c.174.41.578.726 1.07.726h5.137c.242 0 .438.196.438.438v4.062c0 .07-.056.125-.125.125h-2.21a.125.125 0 01-.125-.125v-1.23a.812.812 0 00-.812-.813h-2.31c-.492 0-.895.315-1.07.723A4.97 4.97 0 011.906 10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSecurityKeyALight)
