import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconPeople1CheckRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.502 4.498a3.498 3.498 0 116.996 0 3.498 3.498 0 01-6.996 0zM8 2.5a1.998 1.998 0 100 3.996A1.998 1.998 0 008 2.5z"
          fill="currentColor"
        />
        <path
          d="M3.93 11.765c-.251.339-.43.808-.43 1.488a.75.75 0 01-1.5 0c0-.964.26-1.754.723-2.38.457-.618 1.074-1.025 1.709-1.294C5.678 9.052 7.125 9.003 8 9.003a.75.75 0 010 1.5c-.86 0-2.039.058-2.983.458-.46.195-.831.457-1.088.804zM15.782 10.63a.75.75 0 10-1.061-1.06l-3.47 3.47-1.47-1.47a.75.75 0 00-1.06 1.06l1.859 1.859a.95.95 0 001.343 0l3.859-3.859z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45 6.506a4.55 4.55 0 119.1 0 4.55 4.55 0 01-9.1 0zM10 3.456a3.05 3.05 0 100 6.1 3.05 3.05 0 000-6.1z"
          fill="currentColor"
        />
        <path
          d="M4.35 15.373c-.358.44-.6 1.034-.6 1.873a.75.75 0 01-1.5 0c0-1.156.344-2.09.936-2.82.583-.716 1.367-1.187 2.177-1.499 1.601-.617 3.474-.677 4.637-.677a.75.75 0 010 1.5c-1.151 0-2.778.068-4.098.577-.65.25-1.183.592-1.552 1.046zM14.672 18.389l4.858-4.859a.75.75 0 10-1.06-1.06L14 16.94l-1.97-1.97a.75.75 0 10-1.06 1.06l2.358 2.359a.95.95 0 001.344 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 7a6 6 0 1112 0A6 6 0 016 7zm6-4.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"
          fill="currentColor"
        />
        <path
          d="M4.591 18.13c-.505.684-.841 1.603-.841 2.87a.75.75 0 01-1.5 0c0-1.551.418-2.791 1.135-3.762.712-.962 1.68-1.608 2.707-2.043 2.03-.859 4.413-.945 5.908-.945a.75.75 0 010 1.5c-1.48 0-3.596.095-5.323.827-.852.36-1.575.862-2.086 1.553zM16.884 22.177l6.646-6.647a.75.75 0 10-1.06-1.06L16 20.94l-2.97-2.97a.75.75 0 10-1.06 1.06l3.146 3.147a1.25 1.25 0 001.768 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 10a8 8 0 1116 0 8 8 0 01-16 0zm8-6a6 6 0 100 12 6 6 0 000-12z"
          fill="currentColor"
        />
        <path
          d="M6.102 24.975C5.44 25.816 5 26.943 5 28.5a1 1 0 11-2 0c0-1.966.566-3.539 1.532-4.764.955-1.211 2.25-2.017 3.612-2.558C10.84 20.108 14.009 20 16 20a1 1 0 110 2c-1.975 0-4.806.119-7.118 1.037-1.143.454-2.105 1.082-2.78 1.938zM31.707 22.207a1 1 0 00-1.414-1.414L22 29.086l-3.793-3.793a1 1 0 00-1.414 1.414l3.793 3.793a2 2 0 002.828 0l8.293-8.293z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15zM32 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S38.627 9 32 9z"
        fill="currentColor"
      />
      <path
        d="M12.267 49.128C10.893 50.907 10 53.276 10 56.5a1.5 1.5 0 01-3 0c0-3.822 1.074-6.852 2.893-9.206 1.803-2.335 4.261-3.907 6.88-4.969C21.97 40.218 28.104 40 32 40a1.5 1.5 0 010 3c-3.87 0-9.487.235-14.099 2.105-2.284.926-4.245 2.224-5.634 4.023zM48.682 56.44l13.879-13.88a1.5 1.5 0 00-2.122-2.12L46.561 54.317a1.5 1.5 0 01-2.122 0l-5.378-5.379a1.5 1.5 0 00-2.122 2.122l5.379 5.378a4.5 4.5 0 006.364 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconPeople1CheckRegular)
