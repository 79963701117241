import Card from '@ui/Card'
import {
  IconCalendarRegular,
  IconEyeOpenRegular,
  IconMoneyCurrencyRubleCircleRegular,
  IconNewsFeedRegular,
} from '@ui/icons'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import styles from './styles.module.scss'

const MarketingPage = () => (
  <PageWrapper title="Маркетинг">
    <div className={styles.cards}>
      <Card
        className={styles.card}
        Icon={IconNewsFeedRegular}
        title="Маркетинговые материалы"
        description="Скачать маркетинговые материалы"
        link={EMainRoutes.MarketingMaterials}
      />
      <Card
        className={styles.card}
        Icon={IconMoneyCurrencyRubleCircleRegular}
        title="Маркетинговые средства"
        description="Заполнить заявку на запрос средств"
        link={EMainRoutes.MarketingFunds}
      />
      <Card
        className={styles.card}
        Icon={IconCalendarRegular}
        title="Календарь мероприятий"
        description="Все запланированные мероприятия"
        link={EMainRoutes.MarketingEventsCalendar}
      />
      <Card
        className={styles.card}
        Icon={IconEyeOpenRegular}
        title="Вебинары"
        description="Онлайн-обучение"
        link={EMainRoutes.MarketingWebinars}
      />
    </div>
  </PageWrapper>
)

export default MarketingPage
