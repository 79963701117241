import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyCurrencyRubleCircleLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 9h1.5a2.5 2.5 0 000-5H6.69a.69.69 0 00-.69.69V8h-.5a.5.5 0 000 1H6v1h-.5a.5.5 0 000 1H6v.75a.5.5 0 001 0V11h2.25a.5.5 0 000-1H7V9zm0-4v3h1.5a1.5 1.5 0 100-3H7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8.004a7 7 0 1114 0 7 7 0 01-14 0zm7-6a6 6 0 100 12 6 6 0 000-12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11h2a3 3 0 100-6H8.955A.955.955 0 008 5.955V10H7a.5.5 0 000 1h1v1H7a.5.5 0 000 1h1v1.5a.5.5 0 001 0V13h3a.5.5 0 000-1H9v-1zm0-5v4h2a2 2 0 100-4H9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8-7a7 7 0 100 14 7 7 0 000-14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyCurrencyRubleCircleLight)
