import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconFolderSharedRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8 7.5a1.5 1.5 0 01-1.037 1.427c.491.078.947.28 1.305.581.328.278.556.624.663.999.075.265-.155.493-.431.493h-4c-.276 0-.506-.228-.43-.493a2.05 2.05 0 01.662-.999 2.676 2.676 0 011.305-.58A1.5 1.5 0 118 7.5zM10.387 9.057a1.126 1.126 0 10-.774 0 2.01 2.01 0 00-.78.335c.43.45.693 1.013.753 1.608H11.5c.276 0 .506-.227.437-.495a1.975 1.975 0 00-.523-.905 2.006 2.006 0 00-1.027-.543z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1.25A1.75 1.75 0 001.25 3v8A2.75 2.75 0 004 13.75h8A2.75 2.75 0 0014.75 11V6A2.75 2.75 0 0012 3.25H8.464a.25.25 0 01-.202-.103l-.856-1.176a1.75 1.75 0 00-1.415-.721H3zM2.75 3A.25.25 0 013 2.75h2.99a.25.25 0 01.203.103l.855 1.176c.33.453.856.721 1.416.721H12c.69 0 1.25.56 1.25 1.25v5c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25V3z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.838 9.819a1.82 1.82 0 01-1.212 1.715c1.101.189 2.147.904 2.398 1.97.063.269-.167.496-.443.496H5.518c-.277 0-.507-.227-.443-.496.248-1.053 1.271-1.764 2.357-1.963A1.82 1.82 0 119.838 9.82zM12.799 11.976a1.465 1.465 0 10-.584-.002 2.96 2.96 0 00-.91.243c.38.48.644 1.06.734 1.72a.43.43 0 01.004.063H14.5c.276 0 .508-.229.428-.493-.26-.858-1.179-1.43-2.129-1.531z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.25A1.75 1.75 0 002.25 5v9A2.75 2.75 0 005 16.75h10A2.75 2.75 0 0017.75 14V8A2.75 2.75 0 0015 5.25h-4.536a.25.25 0 01-.202-.103l-.856-1.176a1.75 1.75 0 00-1.415-.721H4zM3.75 5A.25.25 0 014 4.75h3.99a.25.25 0 01.203.103l.855 1.176c.33.453.856.721 1.416.721H15c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.974 15.3c.33-.098.695-.126.985-.126.374 0 .875.047 1.262.231.185.088.323.198.415.329.088.126.158.306.158.582v.33a.6.6 0 101.2 0v-.33c0-.499-.133-.924-.376-1.27a2.224 2.224 0 00-.882-.725c-.63-.3-1.346-.347-1.777-.347-.38 0-.985.037-1.556.253l.053.065c.237.3.41.64.518 1.008z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.986 9.014a1.999 1.999 0 100 3.997 1.999 1.999 0 000-3.997zm-.999 1.998a.999.999 0 111.997 0 .999.999 0 01-1.997 0z"
          fill="currentColor"
        />
        <path
          d="M7.405 15.59c-.114.144-.201.349-.201.668v.37a.6.6 0 01-1.2 0v-.37c0-.564.163-1.036.458-1.41.288-.366.67-.599 1.049-.75.741-.295 1.593-.32 2.094-.32.501 0 1.353.025 2.094.32.38.151.761.384 1.05.75.294.374.457.846.457 1.41v.37a.6.6 0 01-1.2 0v-.37c0-.32-.087-.524-.2-.668-.12-.152-.303-.278-.55-.377-.511-.203-1.16-.235-1.651-.235-.491 0-1.14.032-1.65.235-.248.099-.43.225-.55.377z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 7.94a2.56 2.56 0 100 5.121 2.56 2.56 0 000-5.122zM8.14 10.5a1.36 1.36 0 112.721 0 1.36 1.36 0 01-2.721 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 5.875A2.625 2.625 0 014.875 3.25h4.063c.922 0 1.777.484 2.251 1.274l.436.726H18A3.75 3.75 0 0121.75 9v8A3.75 3.75 0 0118 20.75H6A3.75 3.75 0 012.25 17V5.875zM4.875 4.75c-.621 0-1.125.504-1.125 1.125V17A2.25 2.25 0 006 19.25h12A2.25 2.25 0 0020.25 17V9A2.25 2.25 0 0018 6.75h-6.375a1.5 1.5 0 01-1.287-.728l-.435-.726a1.125 1.125 0 00-.965-.546H4.875z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M19.609 21.066c.271-.043.57-.066.891-.066.82 0 1.5.152 1.939.404.386.223.561.497.561.909V23a1 1 0 102 0v-.687c0-1.238-.658-2.12-1.563-2.642C22.583 19.18 21.513 19 20.5 19c-.756 0-1.542.1-2.248.354.264.203.516.44.744.718a4.3 4.3 0 01.613.994z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 11.998a3.002 3.002 0 100 6.004 3.002 3.002 0 000-6.004zM19.748 15a1.252 1.252 0 112.504 0 1.252 1.252 0 01-2.504 0z"
          fill="currentColor"
        />
        <path
          d="M9.322 21.977C9.139 22.199 9 22.51 9 23a1 1 0 11-2 0c0-.92.28-1.688.776-2.293.484-.588 1.121-.96 1.748-1.198C10.754 19.04 12.166 19 13 19c.834 0 2.246.04 3.476.509.627.238 1.264.61 1.748 1.198.497.605.776 1.374.776 2.293a1 1 0 11-2 0c0-.49-.14-.801-.322-1.023-.195-.238-.498-.44-.914-.6C14.906 21.052 13.819 21 13 21c-.819 0-1.906.05-2.764.378-.416.158-.719.36-.914.599z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 10a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6.5A3.5 3.5 0 015.5 3h6.25a3.5 3.5 0 012.8 1.4l1.5 2a1.5 1.5 0 001.2.6h7.25a5.5 5.5 0 015.5 5.5v11a5.5 5.5 0 01-5.5 5.5h-17A5.5 5.5 0 012 23.5v-17zM5.5 5A1.5 1.5 0 004 6.5v17A3.5 3.5 0 007.5 27h17a3.5 3.5 0 003.5-3.5v-11A3.5 3.5 0 0024.5 9h-7.25a3.5 3.5 0 01-2.8-1.4l-1.5-2a1.5 1.5 0 00-1.2-.6H5.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M37.614 41.434l.035-.014c.848-.356 1.957-.42 2.851-.42.894 0 2.004.064 2.851.42.401.168.672.373.843.602.16.214.306.544.306 1.11v.368a1.5 1.5 0 103 0v-.367c0-1.14-.314-2.117-.902-2.905-.575-.771-1.34-1.261-2.086-1.575C43.064 38.046 41.423 38 40.5 38c-.923 0-2.564.046-4.012.653-.11.047-.222.097-.333.152a7.348 7.348 0 011.459 2.63z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 26.5a5 5 0 100 10 5 5 0 000-10zm-2 5a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
      <path
        d="M20.132 41.894c-.362.48-.632 1.157-.632 2.176v.43a1.5 1.5 0 01-3 0v-.43c0-1.598.44-2.925 1.234-3.98.781-1.038 1.827-1.712 2.886-2.152 2.072-.863 4.461-.938 5.88-.938 1.419 0 3.808.075 5.88.938 1.059.44 2.105 1.114 2.886 2.152.793 1.055 1.234 2.382 1.234 3.98v.43a1.5 1.5 0 01-3 0v-.43c0-1.019-.27-1.696-.632-2.176-.374-.497-.926-.889-1.641-1.187C29.751 40.093 27.89 40 26.5 40c-1.39 0-3.251.093-4.727.707-.715.298-1.267.69-1.641 1.187z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 23a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM23 29.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.5A6.5 6.5 0 0112.5 8h11.324a6.5 6.5 0 015.408 2.894l2.292 3.438a1.5 1.5 0 001.248.668H48.5a9.5 9.5 0 019.5 9.5v22a9.5 9.5 0 01-9.5 9.5h-33A9.5 9.5 0 016 46.5v-32zm6.5-3.5A3.5 3.5 0 009 14.5v32a6.5 6.5 0 006.5 6.5h33a6.5 6.5 0 006.5-6.5v-22a6.5 6.5 0 00-6.5-6.5H32.772a4.5 4.5 0 01-3.744-2.004l-2.292-3.438A3.5 3.5 0 0023.824 11H12.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconFolderSharedRegular)
