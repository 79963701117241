import { memo } from 'react'

import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import { cx } from '@lib/styles'

import styles from './styles.module.scss'
import { ISelectDropdownProps } from './types'

export const SelectDropdown = memo(
  ({
    options,
    selectedOption,
    onSelect,
    opened,
    className,
    optionClassName,
  }: ISelectDropdownProps) =>
    opened && (
      <div className={cx(styles.dropdown, className)}>
        {options.map((option) => {
          const isActive = option.value === selectedOption?.value
          return (
            <div
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={() => onSelect(option)}
              className={cx(
                styles.dropdownOption,
                isActive && styles.dropdownOptionActive,
                optionClassName,
              )}
              key={option.value}>
              <div className={styles.dropdownOptionWrapper}>
                <Typography
                  color={isActive && COLORS.GRAY.GANDALF}
                  className={styles.dropdownOptionWrapperTitle}>
                  {option.label}
                </Typography>
                {option.tag && (
                  <div
                    className={cx(
                      styles.dropdownOptionWrapperTag,
                      isActive && styles.dropdownOptionWrapperTagActive,
                    )}>
                    <Typography
                      size={12}
                      color={
                        isActive ? COLORS.GRAY.SAURON : COLORS.GRAY.GANDALF
                      }>
                      {option.tag.name}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    ),
)
