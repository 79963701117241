import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconLogoYoutubeRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.365 10.512A.25.25 0 016 10.29V5.71a.25.25 0 01.365-.222l4.412 2.29a.25.25 0 010 .444l-4.412 2.29z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.954 2.75c-.947 0-1.724 0-2.344.073-.647.077-1.22.242-1.708.642a3.15 3.15 0 00-.437.437C1.065 4.389.9 4.963.823 5.61.75 6.23.75 7.007.75 7.954v.092c0 .947 0 1.724.073 2.344.077.647.242 1.22.642 1.708.13.16.277.306.437.437.487.4 1.061.565 1.708.642.62.073 1.397.073 2.344.073h4.093c.947 0 1.723 0 2.343-.073.647-.077 1.221-.242 1.709-.642.16-.13.306-.277.436-.437.4-.487.566-1.061.642-1.708.073-.62.073-1.397.073-2.344v-.092c0-.947 0-1.724-.073-2.344-.076-.647-.241-1.22-.642-1.708a3.15 3.15 0 00-.436-.437c-.488-.4-1.062-.565-1.709-.642-.62-.073-1.396-.073-2.343-.073H5.954zm-3.1 1.875c.175-.145.427-.252.932-.312C4.308 4.25 4.996 4.25 6 4.25h4c1.005 0 1.693.001 2.214.063.506.06.757.167.933.312.084.068.16.145.229.228.144.176.252.428.312.933.061.522.062 1.21.062 2.214 0 1.005 0 1.692-.063 2.214-.06.505-.167.757-.311.933a1.65 1.65 0 01-.229.229c-.176.144-.427.251-.933.311-.521.062-1.209.063-2.214.063H6c-1.005 0-1.692-.001-2.214-.063-.505-.06-.757-.167-.933-.311a1.65 1.65 0 01-.228-.23c-.145-.175-.252-.427-.312-.932C2.25 9.692 2.25 9.004 2.25 8c0-1.005.001-1.692.063-2.214.06-.505.167-.757.312-.933.068-.083.145-.16.228-.228z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.224 12.888a.5.5 0 01-.724-.447V7.559a.5.5 0 01.724-.447l4.882 2.44a.5.5 0 010 .895l-4.882 2.441z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.917 3.498c-1.16 0-2.09 0-2.834.08-.766.083-1.422.258-1.99.67a3.95 3.95 0 00-.885.884c-.412.57-.587 1.225-.67 1.991-.08.744-.08 1.674-.08 2.834v.086c0 1.16 0 2.09.08 2.834.083.766.258 1.422.67 1.99.246.34.544.64.884.885.57.412 1.225.587 1.991.67.744.08 1.674.08 2.834.08h4.166c1.16 0 2.09 0 2.834-.08.766-.083 1.422-.258 1.99-.67a3.95 3.95 0 00.885-.884c.412-.569.587-1.225.67-1.991.08-.744.08-1.674.08-2.834v-.086c0-1.16 0-2.09-.08-2.834-.083-.766-.258-1.422-.67-1.99a3.95 3.95 0 00-.884-.885c-.57-.412-1.225-.587-1.991-.67-.744-.08-1.674-.08-2.834-.08H7.917zM3.972 5.463c.27-.196.633-.324 1.272-.393.654-.071 1.503-.072 2.716-.072h4.08c1.213 0 2.062 0 2.716.072.64.069 1.001.197 1.272.393.211.153.396.338.549.549.196.27.324.633.393 1.272.071.654.072 1.503.072 2.716 0 1.213 0 2.062-.072 2.716-.069.64-.197 1.002-.393 1.272-.153.211-.338.396-.549.549-.27.196-.633.324-1.272.393-.654.071-1.503.072-2.716.072H7.96c-1.213 0-2.062 0-2.716-.072-.639-.069-1.001-.197-1.272-.393a2.451 2.451 0 01-.549-.549c-.196-.27-.324-.633-.393-1.272-.071-.654-.072-1.503-.072-2.716 0-1.213.001-2.062.072-2.716.069-.64.197-1.001.393-1.272a2.45 2.45 0 01.549-.549z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.121 8.346A.75.75 0 009.004 9v6a.75.75 0 001.117.654l5.35-3a.75.75 0 000-1.308l-5.35-3zm.383 5.373v-3.438L13.57 12l-3.066 1.72z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.508 4.442c-1.512 0-2.72 0-3.67.121-.982.125-1.796.39-2.459.997-.09.083-.176.169-.258.259-.608.662-.872 1.476-.997 2.458-.122.951-.122 2.158-.122 3.67v.106c0 1.512 0 2.719.122 3.67.125.982.39 1.796.997 2.458.082.09.168.176.258.259.663.607 1.477.872 2.459.997.95.121 2.158.121 3.67.121h4.989c1.512 0 2.719 0 3.67-.121.982-.125 1.796-.39 2.458-.997.09-.083.177-.169.26-.259.607-.662.871-1.476.996-2.458.122-.951.122-2.158.121-3.67v-.106c0-1.512 0-2.719-.12-3.67-.126-.982-.39-1.796-.998-2.458a4.256 4.256 0 00-.259-.259c-.662-.607-1.476-.872-2.458-.997-.951-.121-2.158-.121-3.67-.121H9.508zM4.393 6.665c.342-.313.808-.509 1.634-.614.846-.108 1.957-.11 3.534-.11h4.883c1.577 0 2.688.002 3.534.11.826.105 1.292.301 1.633.614.059.054.114.11.168.168.313.341.51.807.614 1.633.108.846.11 1.957.11 3.534 0 1.577-.002 2.688-.11 3.534-.105.826-.3 1.292-.614 1.633-.053.058-.11.114-.168.168-.341.313-.807.509-1.633.614-.846.108-1.957.11-3.534.11H9.561c-1.577 0-2.688-.002-3.534-.11-.826-.105-1.292-.301-1.634-.614a2.753 2.753 0 01-.167-.168c-.313-.341-.51-.807-.614-1.634-.108-.845-.11-1.956-.11-3.533s.002-2.688.11-3.534c.105-.826.3-1.292.614-1.633.053-.058.11-.114.167-.168z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.458 11.424a1 1 0 00-1.458.889v7.374a1 1 0 001.458.889l7.16-3.687a1 1 0 000-1.778l-7.16-3.687zM14 18.047v-4.094L17.975 16 14 18.047z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.941 6c-1.825 0-3.276 0-4.431.125-1.187.129-2.18.399-3.037 1.02a6 6 0 00-1.327 1.328c-.622.856-.892 1.85-1.02 3.036C3 12.665 3 14.116 3 15.941v.118c0 1.825 0 3.276.125 4.431.129 1.187.399 2.18 1.02 3.037a6 6 0 001.328 1.327c.856.622 1.85.892 3.037 1.02C9.665 26 11.116 26 12.94 26h6.118c1.825 0 3.276 0 4.431-.125 1.187-.129 2.18-.399 3.037-1.02a6 6 0 001.327-1.328c.622-.856.892-1.85 1.02-3.037C29 19.335 29 17.885 29 16.06v-.118c0-1.825 0-3.276-.125-4.432-.129-1.186-.399-2.18-1.02-3.036a6 6 0 00-1.328-1.327c-.856-.622-1.85-.892-3.037-1.02C22.335 6 20.885 6 19.06 6h-6.118zM6.649 8.764c.458-.333 1.06-.54 2.076-.65C9.76 8 11.103 8 13 8h6c1.897 0 3.24.001 4.275.114 1.017.11 1.618.317 2.076.65.34.247.638.545.885.885.333.458.54 1.06.65 2.076C27 12.76 27 14.103 27 16c0 1.897-.001 3.24-.113 4.275-.11 1.017-.318 1.618-.65 2.076-.248.34-.546.638-.886.885-.458.333-1.06.54-2.076.65C22.24 24 20.897 24 19 24h-6c-1.897 0-3.24-.001-4.275-.113-1.017-.11-1.618-.318-2.076-.65a4.002 4.002 0 01-.885-.886c-.333-.458-.54-1.06-.65-2.076C5 19.24 5 17.897 5 16c0-1.897.001-3.24.114-4.275.11-1.017.317-1.618.65-2.076a4 4 0 01.885-.885z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.208 23.303A1.5 1.5 0 0025 24.627v14.748a1.5 1.5 0 002.208 1.322l13.773-7.374a1.5 1.5 0 000-2.644l-13.773-7.375zM28 36.87V27.13L37.096 32 28 36.87z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.893 13c-3.91 0-6.988 0-9.405.308-2.477.315-4.471.975-6.082 2.45-.225.207-.441.423-.647.648-1.476 1.611-2.136 3.605-2.451 6.082C6 24.905 6 27.984 6 31.893v.214c0 3.91 0 6.988.308 9.405.315 2.477.975 4.471 2.45 6.082.207.225.423.441.648.647 1.611 1.476 3.604 2.136 6.082 2.451 2.417.308 5.496.308 9.405.308h14.214c3.91 0 6.988 0 9.405-.308 2.477-.315 4.471-.975 6.082-2.45.225-.207.441-.423.647-.648 1.476-1.611 2.136-3.605 2.451-6.082.308-2.417.308-5.496.308-9.405v-.214c0-3.91 0-6.988-.308-9.405-.315-2.477-.975-4.471-2.45-6.082a10.524 10.524 0 00-.648-.647c-1.611-1.476-3.605-2.136-6.082-2.451C46.095 13 43.016 13 39.107 13H24.893zm-13.46 4.97c.97-.888 2.267-1.41 4.434-1.686C18.073 16.003 20.96 16 25 16h14c4.04 0 6.927.003 9.133.284 2.167.275 3.464.798 4.434 1.686.16.148.315.302.463.463.888.97 1.41 2.267 1.686 4.434C54.997 25.073 55 27.96 55 32c0 4.04-.003 6.927-.284 9.133-.275 2.167-.798 3.464-1.686 4.434a7.48 7.48 0 01-.463.463c-.97.888-2.267 1.41-4.434 1.686C45.927 47.997 43.04 48 39 48H25c-4.04 0-6.927-.003-9.133-.284-2.168-.275-3.464-.798-4.434-1.686a7.48 7.48 0 01-.463-.463c-.888-.97-1.41-2.267-1.686-4.434C9.003 38.927 9 36.04 9 32c0-4.04.003-6.927.284-9.133.275-2.168.798-3.464 1.686-4.434a7.48 7.48 0 01.463-.463z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconLogoYoutubeRegular)
