import { memo } from 'react'
import ReactMarkdown from 'react-markdown'

import remarkGfm from 'remark-gfm'

import Button from '@ui/Button'
import { IconPlusLight } from '@ui/icons'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import styles from './TextPage.module.scss'

interface IProps {
  title?: string
  markup?: string
  file?: string
}

const TextPage = ({ title, markup, file }: IProps) => (
  <PageWrapper
    headerRight={
      file ? (
        <Button
          type="fill"
          withBorder
          IconLeft={IconPlusLight}
          className={styles.button}
          size="small"
          href={file}
          download>
          {`Скачать ${file.slice(file.lastIndexOf('.') + 1).toUpperCase()}`}
        </Button>
      ) : undefined
    }
    className={styles.textPage}
    title={title}>
    <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
      {markup}
    </ReactMarkdown>
    {markup && (
      <Button
        className={styles.link}
        type="link"
        linkTo={EMainRoutes.MainSupport}>
        Вернуться в поддержку
      </Button>
    )}
  </PageWrapper>
)

export default memo(TextPage)
