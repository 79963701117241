import { makeAutoObservable } from 'mobx'
import { container, singleton } from 'tsyringe'

import { FormConstructor } from '@lib/form'

import { CompanyStore } from '@modules/company/store/CompanyStore'

@singleton()
export class CompanyBasicInformationFormStore {
  private companyStore = container.resolve(CompanyStore)

  form = new FormConstructor({
    legalAddress: {
      label: 'Юридический адрес',
      rule: 'required',
      value: this.companyStore.company.legalAddress,
    },
    actualAddress: {
      label: 'Фактический адрес',
      rule: 'required',
      value: this.companyStore.company.actualAddress,
    },
  })

  constructor() {
    makeAutoObservable<CompanyBasicInformationFormStore, 'companyStore'>(this, {
      companyStore: false,
    })
  }

  handleSubmit = async () => {
    await this.form.handleSubmit((fields) =>
      this.companyStore.editCompany({
        legalAddress: fields.legalAddress.value,
        actualAddress: fields.actualAddress.value,
      }),
    )
  }

  reset = () => {
    this.form.fields.legalAddress.value = this.companyStore.company.legalAddress
    this.form.fields.actualAddress.value =
      this.companyStore.company.actualAddress
  }
}

export const useCompanyBasicInformationFormStore = () =>
  container.resolve(CompanyBasicInformationFormStore)
