import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconEyeOffLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.354 1.646a.5.5 0 10-.708.708l1.8 1.8A7.93 7.93 0 00.533 7.819a.5.5 0 000 .362C1.682 11.148 4.594 13.25 8 13.25a8.046 8.046 0 003.666-.877l1.98 1.98a.5.5 0 00.708-.707l-12-12zm8.563 9.978l-1.18-1.18a3 3 0 01-4.183-4.182L4.17 4.875A6.94 6.94 0 001.538 8c1.05 2.493 3.546 4.25 6.462 4.25 1.042 0 2.03-.224 2.917-.626zm-4.64-4.64a2 2 0 002.738 2.738L6.277 6.986z"
          fill="currentColor"
        />
        <path
          d="M7.712 5.015l1.212 1.211c.363.19.66.487.85.85l1.212 1.212a3 3 0 00-3.274-3.274z"
          fill="currentColor"
        />
        <path
          d="M14.463 8a6.894 6.894 0 01-1.525 2.24l.707.707a7.887 7.887 0 001.823-2.766.5.5 0 000-.362C14.318 4.852 11.406 2.75 8 2.75c-.777 0-1.529.11-2.24.314l.828.826A7.13 7.13 0 018 3.75c2.916 0 5.412 1.757 6.463 4.25z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.854 2.146a.5.5 0 10-.708.708L4.504 5.21a9.804 9.804 0 00-3.567 4.23 1.394 1.394 0 000 1.118c1.515 3.46 5.004 5.879 9.063 5.879a9.932 9.932 0 004.607-1.124l2.54 2.54a.5.5 0 00.707-.708l-15-15zM13.86 14.568l-1.676-1.676a3.624 3.624 0 01-5.076-5.076L5.226 5.933a8.808 8.808 0 00-3.374 3.908.394.394 0 000 .316c1.36 3.104 4.494 5.28 8.147 5.28a8.942 8.942 0 003.861-.87zM7.825 8.532a2.624 2.624 0 003.643 3.643L7.825 8.532z"
        fill="currentColor"
      />
      <path
        d="M9.14 6.479l.898.897a2.624 2.624 0 012.586 2.586l.897.898A3.624 3.624 0 009.14 6.479z"
        fill="currentColor"
      />
      <path
        d="M18.147 10.158a8.767 8.767 0 01-2.255 3.072l.709.709a9.765 9.765 0 002.462-3.38 1.394 1.394 0 000-1.118C17.548 5.98 14.059 3.562 10 3.562a9.974 9.974 0 00-3.24.537l.8.799A8.999 8.999 0 0110 4.563c3.653 0 6.788 2.175 8.147 5.279.044.1.044.215 0 .316z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconEyeOffLight)
