import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconCommentRectTextLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4 8.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM4.5 6a.5.5 0 000 1h6a.5.5 0 000-1h-6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.5A2.5 2.5 0 013.5 2h8A2.5 2.5 0 0114 4.5v6a2.5 2.5 0 01-2.5 2.5H8.151l-3.907 2.605A.8.8 0 013 14.94V12.95a2.5 2.5 0 01-2-2.45v-6zM3.5 3A1.5 1.5 0 002 4.5v6A1.5 1.5 0 003.5 12a.5.5 0 01.5.5v2.066l3.723-2.482A.5.5 0 018 12h3.5a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0011.5 3h-8z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M5 10.52a.5.5 0 01.5-.5h5a.5.5 0 110 1h-5a.5.5 0 01-.5-.5zM5.5 7a.5.5 0 000 1h8a.5.5 0 000-1h-8z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6a3 3 0 013-3h9a3 3 0 013 3v6a3 3 0 01-3 3h-3.342l-4.085 2.859A1 1 0 015 17.039V15a3 3 0 01-3-3V6zm3-2a2 2 0 00-2 2v6a2 2 0 002 2h.5a.5.5 0 01.5.5v2.54l4.213-2.95A.5.5 0 0110.5 14H14a2 2 0 002-2V6a2 2 0 00-2-2H5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRectTextLight)
