import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCollapseTrianglesHOpenRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.573 9.237a1.75 1.75 0 000-2.475l-2.951-2.951C10.023 3.212 9 3.636 9 4.483v7.034c0 .846 1.023 1.27 1.622.672l2.951-2.952zm-1.06-1.414a.25.25 0 010 .354L10.5 10.189V5.81l2.012 2.013zM2.427 6.762a1.75 1.75 0 000 2.475l2.951 2.952c.599.598 1.622.174 1.622-.672V4.483c0-.847-1.023-1.27-1.622-.672L2.427 6.762zm1.06 1.061a.25.25 0 000 .354L5.5 10.189V5.81L3.487 7.823z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.47 11.591a2.25 2.25 0 000-3.182l-3.336-3.336c-.788-.787-2.134-.23-2.134.884v8.086c0 1.114 1.346 1.671 2.134.884l3.335-3.336zM15.408 9.47a.75.75 0 010 1.06l-2.91 2.91V6.56l2.91 2.91zM3.53 8.41a2.25 2.25 0 000 3.181l3.336 3.336c.787.787 2.134.23 2.134-.884V5.957c0-1.114-1.347-1.671-2.134-.884L3.53 8.41zm1.06 1.06a.75.75 0 000 1.06l2.91 2.91V6.56L4.59 9.47z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.309 13.679a2.375 2.375 0 000-3.359l-5.495-5.496c-.992-.992-2.689-.29-2.689 1.114v12.123c0 1.404 1.697 2.106 2.689 1.114l5.495-5.496zm-1.238-2.121a.625.625 0 010 .883l-5.196 5.198V6.36l5.196 5.197zM1.692 10.32a2.375 2.375 0 000 3.359l5.494 5.496c.992.992 2.689.29 2.689-1.114V5.938c0-1.403-1.697-2.106-2.689-1.114L1.692 10.32zm1.237 1.238a.625.625 0 000 .883l5.196 5.198V6.36l-5.196 5.197z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.94 18.475a3.5 3.5 0 000-4.95L22.413 6C21.154 4.74 19 5.632 19 7.414v17.172c0 1.782 2.154 2.674 3.414 1.414l7.525-7.525zm-1.415-3.536a1.5 1.5 0 010 2.122L21 24.586V7.414l7.525 7.525zM2.06 13.525a3.5 3.5 0 000 4.95L9.587 26c1.26 1.26 3.414.368 3.414-1.414V7.414C13 5.632 10.846 4.74 9.586 6L2.06 13.525zm1.415 1.414a1.5 1.5 0 000 2.122L11 24.586V7.414L3.475 14.94z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.233 35.889a5.5 5.5 0 000-7.778L43.975 12.853C41.77 10.65 38 12.21 38 15.328v33.344c0 3.118 3.77 4.68 5.975 2.474L59.233 35.89zm-2.122-5.657a2.5 2.5 0 010 3.536L41.854 49.025a.5.5 0 01-.854-.353V15.328a.5.5 0 01.854-.353L57.11 30.232zM4.768 28.11a5.5 5.5 0 000 7.779l15.257 15.257C22.23 53.351 26 51.79 26 48.672V15.328c0-3.118-3.77-4.68-5.975-2.475L4.768 28.111zm2.121 2.122a2.5 2.5 0 000 3.536l15.257 15.257a.5.5 0 00.854-.353V15.328a.5.5 0 00-.854-.353L6.89 30.232z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCollapseTrianglesHOpenRegular)
