import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'

import Button from '@ui/Button'
import { Input } from '@ui/Input'
import { Typography } from '@ui/Typography'

import FailCard from '@lib/components/FailCard'
import { Form } from '@lib/components/Form/Form'
import PhoneInput from '@lib/components/PhoneInput'
import SuccessCard from '@lib/components/SuccessCard'

import { FormAnimation } from '@modules/auth/components/FormAnimation/FormAnimation'
import { useAnimatedFormStore } from '@modules/auth/store/AnimatedFormStore'
import { useRegistrationFormStore } from '@modules/auth/store/RegistrationFormStore'

import { FormWindowLayout } from '../FormWindowLayout/FormWindowLayout'

import styles from './RegistrationForm.scss'

/**
 * Форма регистрации.
 */
export const RegistrationForm: React.FC = observer(() => {
  const registrationStore = useRegistrationFormStore()
  const animatedStore = useAnimatedFormStore()

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      registrationStore.form.handleFieldChange(
        event.target.name,
        event.target.value,
      )
    },
    [],
  )

  const handleSubmit = useCallback(async () => {
    animatedStore.setAnimatedState('forward')
    await registrationStore.handleSubmit()
  }, [])

  const handleToRegister = useCallback(() => {
    animatedStore.setAnimatedState('backward')
    registrationStore.form.clear()
  }, [])

  const formContent = () => (
    <Form title="Регистрация">
      <Input
        name="fullName"
        label="Контактное лицо"
        placeholder="Фамилия Имя Отчество"
        id="contact-name"
        className={styles.fullname}
        value={registrationStore.form.fields.fullName.value}
        error={registrationStore.form.fields.fullName.error}
        errorText={registrationStore.form.fields.fullName.errorText}
        onChange={handleFieldChange}
      />
      <Input
        name="email"
        id="contact-email"
        placeholder="Электронная почта"
        className={styles.email}
        value={registrationStore.form.fields.email.value}
        error={registrationStore.form.fields.email.error}
        errorText={registrationStore.form.fields.email.errorText}
        onChange={handleFieldChange}
      />
      <PhoneInput
        name="phone"
        id="contact-phone"
        placeholder="Телефон"
        className={styles.phone}
        value={registrationStore.form.fields.phone.value}
        error={registrationStore.form.fields.phone.error}
        errorText={registrationStore.form.fields.phone.errorText}
        onChange={handleFieldChange}
      />
      <Input
        name="organizationName"
        label="Организация"
        placeholder="Наименование организации"
        className={styles.organizationName}
        id="organization-name"
        value={registrationStore.form.fields.organizationName.value}
        error={registrationStore.form.fields.organizationName.error}
        errorText={registrationStore.form.fields.organizationName.errorText}
        onChange={handleFieldChange}
      />
      <Input
        name="inn"
        id="organization-inn"
        placeholder="ИНН"
        value={registrationStore.form.fields.inn.value}
        error={registrationStore.form.fields.inn.error}
        errorText={registrationStore.form.fields.inn.errorText}
        onChange={handleFieldChange}
      />
      <Button
        disabled={registrationStore.submitFormDisabled}
        onClick={handleSubmit}
        fullSize
        className={styles.submitBtn}>
        Отправить заявку
      </Button>
    </Form>
  )

  return (
    <div className={styles.container}>
      <FormWindowLayout
        withoutPadding={
          registrationStore.registrationSuccess ||
          registrationStore.registrationError
        }>
        <FormAnimation
          animationDirection={
            registrationStore.registrationRunning
              ? 'none'
              : animatedStore.animatedState
          }>
          {(registrationStore.registrationIDLE ||
            registrationStore.registrationRunning) &&
            formContent()}
          {registrationStore.registrationSuccess && (
            <SuccessCard
              className={styles.fitContentWidth}
              title="Данные успешно отправлены"
              description="Мы скоро проверим вашу заявку! Логин и пароль придут вам на указанный email."
            />
          )}
          {registrationStore.registrationError && (
            <FailCard
              className={styles.fitContentWidth}
              description="Во время регистрации произошла ошибка, попробуйте зарегистрироваться повторно"
              titleButton="Вернуться к регистрации"
              handleClick={handleToRegister}
              fullSize={true}
            />
          )}
        </FormAnimation>
      </FormWindowLayout>
      <div className={styles.text}>
        <Typography size={14}>Заполняя форму, вы соглашаетесь на</Typography>
        <Button
          className={styles.link}
          type="link"
          size="small"
          href="https://rdwcomp.ru/politic/">
          &nbsp;обработку персональных данных&nbsp;
        </Button>
        <Typography size={14}>
          и получение информационных сообщений от компании RDW Technology.
        </Typography>
      </div>
    </div>
  )
})
