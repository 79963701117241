import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowADownLeftRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.56 11.5h5.19a.75.75 0 010 1.5h-6A1.75 1.75 0 013 11.25v-6a.75.75 0 011.5 0v5.19l7.47-7.47a.75.75 0 111.06 1.06L5.56 11.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5.81 15.25h7.69a.75.75 0 010 1.5h-8a2.25 2.25 0 01-2.25-2.25v-8a.75.75 0 011.5 0v7.69L15.47 3.47a.75.75 0 111.06 1.06L5.81 15.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.112 19.125H16.5a.875.875 0 010 1.75H6A2.875 2.875 0 013.125 18V7.5a.875.875 0 111.75 0v10.388L18.881 3.88A.875.875 0 1120.12 5.12L6.112 19.125z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.416 24.998L8.5 25h13a1 1 0 110 2h-13A3.5 3.5 0 015 23.5v-13a1 1 0 012 0v13c0 .028 0 .056.002.084l17.79-17.791a1 1 0 111.415 1.414l-17.79 17.79z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M15.56 50.561A4.481 4.481 0 0017.5 51h26a1.5 1.5 0 010 3h-26a7.5 7.5 0 01-7.5-7.5v-26a1.5 1.5 0 013 0v26c0 .695.157 1.353.438 1.94l36.001-36a1.5 1.5 0 012.122 2.12L15.56 50.562z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowADownLeftRegular)
