import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconMarketRegisterClassicRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.13 9.524a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM7.506 12.15a.625.625 0 100-1.25.625.625 0 000 1.25zM10.12 9.524a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM9.496 12.15a.625.625 0 100-1.25.625.625 0 000 1.25zM12.11 9.524a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM11.486 12.15a.625.625 0 100-1.25.625.625 0 000 1.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.033 1.337c0-.72.583-1.303 1.303-1.303h4.328c.72 0 1.303.583 1.303 1.303v.34c0 .72-.583 1.303-1.303 1.303H5.25v1.27h1C7.216 4.25 8 5.034 8 6v.25h.99V3.437a.834.834 0 011.296-.694l.74.491.55-.506a.6.6 0 01.813 0l.552.508.755-.498a.834.834 0 011.293.697v4.842c.007.073.011.148.011.223v4a2.25 2.25 0 01-2.25 2.25h-9.5A2.25 2.25 0 011 12.5V6c0-.966.784-1.75 1.75-1.75h1V3v-.02H2.336c-.72 0-1.303-.584-1.303-1.303v-.34zm1.303-.103a.103.103 0 00-.103.103v.34c0 .057.046.103.103.103h4.328a.103.103 0 00.103-.103v-.34a.103.103 0 00-.103-.103H2.336zM10.19 6.25h2.56c.375 0 .728.092 1.039.254v-2.39l-.58.382a.6.6 0 01-.736-.06l-.49-.452-.492.452a.6.6 0 01-.738.058l-.563-.374v2.13zM2.5 6a.25.25 0 01.25-.25h3.5A.25.25 0 016.5 6v1c0 .414.336.75.75.75h5.5a.75.75 0 01.75.75v4a.75.75 0 01-.75.75h-9.5a.75.75 0 01-.75-.75V6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.147 11.692a.703.703 0 11-1.406 0 .703.703 0 011.406 0zM10.444 15.096a.703.703 0 100-1.406.703.703 0 000 1.406zM13.299 11.692a.703.703 0 11-1.406 0 .703.703 0 011.406 0zM12.596 15.096a.703.703 0 100-1.406.703.703 0 000 1.406zM15.451 11.692a.703.703 0 11-1.406 0 .703.703 0 011.406 0zM14.748 15.096a.703.703 0 100-1.406.703.703 0 000 1.406z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.006 2.14c0-.622.503-1.126 1.125-1.126h5.764c.622 0 1.125.504 1.125 1.125V3.85c0 .621-.503 1.125-1.125 1.125H6.75V6.25h1.125c1.036 0 1.875.84 1.875 1.875v.125h1.26V4.926a.889.889 0 011.372-.746l.979.635.716-.643a.625.625 0 01.835 0l.718.645.997-.641a.889.889 0 011.37.747v5.63l.003.134v4.626a2.437 2.437 0 01-2.437 2.437H4.44a2.437 2.437 0 01-2.438-2.438V8.125c0-1.035.84-1.875 1.875-1.875H5.25V4.975H3.13A1.125 1.125 0 012.007 3.85V2.14zm1.25.124v1.461H8.77v-1.46H3.256zM12.26 8.25V5.59l.822.535c.237.153.547.129.758-.06l.655-.588.654.588c.21.188.52.213.756.061l.842-.541v2.971a2.427 2.427 0 00-1.184-.306H12.26zm-8.758-.125c0-.207.168-.375.375-.375h3.998c.207 0 .375.168.375.375V9c0 .414.336.75.75.75h6.563c.517 0 .937.42.937.938v4.624c0 .518-.42.938-.937.938H4.44a.937.937 0 01-.938-.938V8.125z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12 15a1 1 0 11-2 0 1 1 0 012 0zM11 19a1 1 0 100-2 1 1 0 000 2zM15 15a1 1 0 11-2 0 1 1 0 012 0zM14 19a1 1 0 100-2 1 1 0 000 2zM18 15a1 1 0 11-2 0 1 1 0 012 0zM17 19a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 3.125c0-1.036.84-1.875 1.875-1.875h5.75c1.036 0 1.875.84 1.875 1.875v1.75c0 1.036-.84 1.875-1.875 1.875H7.75v1.5H9.5a2.25 2.25 0 012.236 2h1.514V6.736c0-.919 1.043-1.45 1.787-.91l1.11.808.862-.746a.75.75 0 01.982 0l.862.746 1.11-.807a1.125 1.125 0 011.787.91V19.5a3.25 3.25 0 01-3.25 3.25h-13a3.25 3.25 0 01-3.25-3.25v-9A2.25 2.25 0 014.5 8.25h1.75v-1.5H4.125A1.875 1.875 0 012.25 4.875v-1.75zM9.875 5.25a.375.375 0 00.375-.375v-1.75a.375.375 0 00-.375-.375h-5.75a.375.375 0 00-.375.375v1.75c0 .207.168.375.375.375h5.75zM4.5 9.75a.75.75 0 00-.75.75v9c0 .966.784 1.75 1.75 1.75h13a1.75 1.75 0 001.75-1.75v-6a1.75 1.75 0 00-1.75-1.75H11a.75.75 0 01-.75-.75v-.5a.75.75 0 00-.75-.75h-5zm10.25.5h3.75c.644 0 1.245.188 1.75.51V7.474l-.996.724a.75.75 0 01-.932-.04l-.822-.71-.822.71a.75.75 0 01-.932.04l-.996-.724v2.777z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17 20a1 1 0 11-2 0 1 1 0 012 0zM16 24a1 1 0 100-2 1 1 0 000 2zM21 20a1 1 0 11-2 0 1 1 0 012 0zM20 24a1 1 0 100-2 1 1 0 000 2zM25 20a1 1 0 11-2 0 1 1 0 012 0zM24 24a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4.5A2.5 2.5 0 015.5 2h8A2.5 2.5 0 0116 4.5v2A2.5 2.5 0 0113.5 9h-3v2H13a3 3 0 013 3h9a4 4 0 014 4v7a4 4 0 01-4 4H7a4 4 0 01-4-4V14a3 3 0 013-3h2.5V9h-3A2.5 2.5 0 013 6.5v-2zM13.5 7a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h8zM6 13a1 1 0 00-1 1v11a2 2 0 002 2h18a2 2 0 002-2v-7a2 2 0 00-2-2h-9a2 2 0 01-2-2 1 1 0 00-1-1H6z"
          fill="currentColor"
        />
        <path
          d="M20 12a1 1 0 11-2 0V7.01c0-1.241 1.423-1.945 2.41-1.191l1.034.79.883-.803a1 1 0 011.346 0l.883.802 1.034-.79c.987-.753 2.41-.05 2.41 1.193V12a1 1 0 11-2 0V8.022l-.893.682a1 1 0 01-1.28-.055L23 7.897l-.827.752a1 1 0 01-1.28.055L20 8.022V12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M36 40a2 2 0 11-4 0 2 2 0 014 0zM34 49a2 2 0 100-4 2 2 0 000 4zM43 40a2 2 0 11-4 0 2 2 0 014 0zM41 49a2 2 0 100-4 2 2 0 000 4zM50 40a2 2 0 11-4 0 2 2 0 014 0zM48 49a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.5A4.5 4.5 0 0110.5 5h16A4.5 4.5 0 0131 9.5v4a4.5 4.5 0 01-4.5 4.5H20v4h5.5a5.5 5.5 0 015.5 5.5v1a.5.5 0 00.5.5h19a7.5 7.5 0 017.5 7.5v14a7.5 7.5 0 01-7.5 7.5h-37A7.5 7.5 0 016 50.5v-23a5.5 5.5 0 015.5-5.5H17v-4h-6.5A4.5 4.5 0 016 13.5v-4zM26.5 15a1.5 1.5 0 001.5-1.5v-4A1.5 1.5 0 0026.5 8h-16A1.5 1.5 0 009 9.5v4a1.5 1.5 0 001.5 1.5h16zm-15 10A2.5 2.5 0 009 27.5v23a4.5 4.5 0 004.5 4.5h37a4.5 4.5 0 004.5-4.5v-14a4.5 4.5 0 00-4.5-4.5h-19a3.5 3.5 0 01-3.5-3.5v-1a2.5 2.5 0 00-2.5-2.5h-14z"
        fill="currentColor"
      />
      <path
        d="M38 25.5a1.5 1.5 0 01-3 0v-9.08c0-2.022 2.277-3.207 3.934-2.047l2.487 1.74 2.119-1.765a1.5 1.5 0 011.92 0l2.12 1.765 2.486-1.74c1.657-1.16 3.934.025 3.934 2.048V25.5a1.5 1.5 0 01-3 0v-8.119l-2.64 1.848a1.5 1.5 0 01-1.82-.077l-2.04-1.7-2.04 1.7a1.5 1.5 0 01-1.82.077L38 17.38V25.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMarketRegisterClassicRegular)
