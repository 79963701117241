import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDocRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 1A2.75 2.75 0 002 3.75v8.5A2.75 2.75 0 004.75 15h5.5A2.75 2.75 0 0013 12.25V5.653c0-.464-.184-.909-.512-1.237L9.584 1.513A1.75 1.75 0 008.347 1H4.75zM3.5 3.75c0-.69.56-1.25 1.25-1.25h2.5v3c0 .69.56 1.25 1.25 1.25h3v5.5c0 .69-.56 1.25-1.25 1.25h-5.5c-.69 0-1.25-.56-1.25-1.25v-8.5zm7.7 1.5L8.75 2.8v2.45h2.45z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.249 2.001a3.25 3.25 0 00-3.25 3.25v9.5a3.25 3.25 0 003.25 3.25h5.5a3.25 3.25 0 003.25-3.25v-7.12c0-.464-.185-.909-.513-1.237l-3.88-3.88a1.75 1.75 0 00-1.238-.513h-3.12zm-1.75 3.25c0-.966.783-1.75 1.75-1.75H9.25V6.5a2.25 2.25 0 002.25 2.25h2.998v6.002a1.75 1.75 0 01-1.75 1.75h-5.5a1.75 1.75 0 01-1.75-1.75v-9.5zM14.22 7.25l-3.47-3.47V6.5c0 .414.336.75.75.75h2.72z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.75 18A3.75 3.75 0 0116 21.75H8A3.75 3.75 0 014.25 18V6A3.75 3.75 0 018 2.25h5.05c.729 0 1.428.29 1.944.805l3.95 3.951a2.75 2.75 0 01.806 1.945V18zm-14-12A2.25 2.25 0 018 3.75h4.25V7A2.75 2.75 0 0015 9.75h3.25V18A2.25 2.25 0 0116 20.25H8A2.25 2.25 0 015.75 18V6zm12.285 2.25H15c-.69 0-1.25-.56-1.25-1.25V3.965c.065.044.127.095.183.151l3.95 3.95c.057.057.108.119.152.184z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 24.5a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 015 24.5v-17A5.5 5.5 0 0110.5 2h6.726a4 4 0 012.829 1.172l5.773 5.773A4 4 0 0127 11.774V24.5zM7 7.5A3.5 3.5 0 0110.5 4H16v5.5a3.5 3.5 0 003.5 3.5H25v11.5a3.5 3.5 0 01-3.5 3.5h-11A3.5 3.5 0 017 24.5v-17zM24.844 11H19.5A1.5 1.5 0 0118 9.5V4.156a2 2 0 01.64.43l5.774 5.774c.185.185.33.403.43.64z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M36 12.5a1.5 1.5 0 00-3 0v6a6.5 6.5 0 006.5 6.5h7a1.5 1.5 0 000-3h-7a3.5 3.5 0 01-3.5-3.5v-6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6C15.701 6 11 10.701 11 16.5v31C11 53.299 15.701 58 21.5 58h21C48.299 58 53 53.299 53 47.5V23.048a7.5 7.5 0 00-2.197-5.303l-9.547-9.548A7.5 7.5 0 0035.951 6H21.5zM14 16.5A7.5 7.5 0 0121.5 9h14.452a4.5 4.5 0 013.182 1.318l9.548 9.548A4.5 4.5 0 0150 23.048V47.5a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-31z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDocRegular)
