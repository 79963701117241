import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowCUpRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.763 5.677a1.75 1.75 0 012.474 0L13.03 9.47a.75.75 0 01-1.06 1.06L8.177 6.737a.25.25 0 00-.354 0L4.03 10.53a.75.75 0 11-1.06-1.06l3.793-3.793z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.22 13.155a.75.75 0 010-1.06l4.836-4.836a2.75 2.75 0 013.889 0l4.836 4.835a.75.75 0 01-1.061 1.061L10.884 8.32a1.25 1.25 0 00-1.768 0L4.28 13.155a.75.75 0 01-1.06 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M3.383 16.119a.875.875 0 010-1.238L9.79 8.474a3.125 3.125 0 014.42 0l6.406 6.407a.875.875 0 11-1.237 1.238l-6.407-6.407a1.375 1.375 0 00-1.945 0l-6.406 6.407a.875.875 0 01-1.238 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.793 21.207a1 1 0 010-1.414l8.379-8.379a4 4 0 015.656 0l8.38 8.379a1 1 0 01-1.415 1.414l-8.379-8.379a2 2 0 00-2.828 0l-8.379 8.38a1 1 0 01-1.414 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M9.94 42.06a1.5 1.5 0 010-2.12l16.757-16.758a7.5 7.5 0 0110.606 0l16.758 16.757a1.5 1.5 0 01-2.122 2.122L35.182 25.303a4.5 4.5 0 00-6.364 0L12.061 42.061a1.5 1.5 0 01-2.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCUpRegular)
