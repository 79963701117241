import React from 'react'

import Card from '@ui/Card'
import {
  IconDeliveryBoxFrontOpenRegular,
  IconMarketShoppingCartRegular,
} from '@ui/icons'

import PageWrapper from '@lib/components/Page/PageWrapper'
import { EMainRoutes } from '@lib/navigation'

import styles from './ConfiguratorPage.module.scss'

const ConfiguratorPage = () => {
  return (
    <PageWrapper title="Конфигуратор">
      <div className={styles.cards}>
        <Card
          fullWidth
          Icon={IconMarketShoppingCartRegular}
          title="Создать конфигурацию"
          description="Настроить и отправить запрос на конфигурацию"
          link={EMainRoutes.ConfiguratorCreate}
        />
        <Card
          fullWidth
          Icon={IconDeliveryBoxFrontOpenRegular}
          title="История заказов"
          description="Просмотр статуса заказа"
          link={EMainRoutes.ConfiguratorOrders}
        />
      </div>
    </PageWrapper>
  )
}

export default ConfiguratorPage
