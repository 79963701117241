import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowADownLeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M4.707 12H10.5a.5.5 0 010 1h-6A1.5 1.5 0 013 11.5v-6a.5.5 0 011 0v5.793l8.146-8.147a.5.5 0 01.708.708L4.707 12z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M4.74 15.966c.084.022.17.034.26.034h8.5a.5.5 0 010 1H5a2 2 0 01-2-2V6.5a.5.5 0 011 0V15c0 .09.012.176.034.259L15.646 3.646a.5.5 0 01.708.708L4.74 15.966z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowADownLeftLight)
