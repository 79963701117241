import { ICompany } from '@modules/company/models/Company'

export interface ITransaction {
  id: number
  created_at: string
  companyName: string
  inn: string
  companyUrl: string
  address: string
  index: string
  distributor: string
  name: string
  url: string
  amount: number
  date: string
  customer: string
  manager: string
  сompetitors: string
  comments: string
  percent: number
  file: File
  status: Statuses
  stage: Stages
  company: ICompany
}

export enum Stages {
  // Обработка
  PROCESSING,
  // В производстве
  IN_PRODUCTION,
  // Сборка
  ASSEMBLING,
  // Склад
  TRANSFER_WAREHOUSE,
  // Отгрузка
  SHIPMENT,
}

export type TLokalizedStages = {
  [key in Stages]: string
}

export const LokalizedStages: TLokalizedStages = {
  [Stages.PROCESSING]: 'Обработка заказа',
  [Stages.IN_PRODUCTION]: 'В производстве',
  [Stages.ASSEMBLING]: 'Сборка',
  [Stages.TRANSFER_WAREHOUSE]: 'Передача на склад',
  [Stages.SHIPMENT]: 'Отгрузка',
} as const

export enum Statuses {
  // Рассмотрение
  'Сonsideration' = 'consideration',
  // Подтвержен
  'Concluded' = 'concluded',
  // Отменен
  'Rejected' = 'rejected',
}

export type TLokalizedStatus = {
  [key in Statuses]: string
}

export const LokalizedStatus: TLokalizedStatus = {
  [Statuses.Сonsideration]: 'На рассмотрении',
  [Statuses.Concluded]: 'Заключена',
  [Statuses.Rejected]: 'Отклонена',
} as const
