import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowCDownLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.146 5.647a.5.5 0 01.708 0l3.792 3.792a.5.5 0 00.708 0l3.792-3.792a.5.5 0 01.708.707L9.06 10.147a1.5 1.5 0 01-2.122 0L3.146 6.354a.5.5 0 010-.707z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M16.603 7.021a.5.5 0 010 .708l-4.836 4.836a2.5 2.5 0 01-3.535 0L3.396 7.729a.5.5 0 01.707-.708l4.836 4.836a1.5 1.5 0 002.121 0l4.836-4.836a.5.5 0 01.707 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowCDownLight)
