interface TBannerFile {
  asset_id: string
  bytes: number
  created_at: string
  format: string
  id: number
  original_extension: string
  original_filename: string
  resource_type: string
  url: string
}

export interface EDataBanner {
  id: number
  key: string
  title: string
  buttonText: string
  url: string
  view: boolean
  file: TBannerFile
}

export interface EPropsBanner
  extends Pick<EDataBanner, 'title' | 'buttonText' | 'url'> {
  imageUrl?: string
}

export enum EKeyBanner {
  MainPage = 'main-page',
}
