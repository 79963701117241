import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconDivideCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M5 7.25a.75.75 0 100 1.5h6a.75.75 0 000-1.5H5zM9 11a1 1 0 11-2 0 1 1 0 012 0zM8 6a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 100 14A7 7 0 008 1zM2.5 8a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.75 9.25a.75.75 0 100 1.5h6.5a.75.75 0 100-1.5h-6.5zM11.25 6.748a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM10 14.502a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zm-6.499 8A6.499 6.499 0 1116.5 10 6.499 6.499 0 013.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 11.25a.75.75 0 000 1.5h9a.75.75 0 000-1.5h-9zM13.25 7.748a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM12 17.502a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 15a1 1 0 100 2h12a1 1 0 100-2H10zM17.498 10.498a1.498 1.498 0 11-2.997 0 1.498 1.498 0 012.997 0zM16 22.997A1.498 1.498 0 1016 20a1.498 1.498 0 000 2.997z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM5 16C5 9.925 9.925 5 16 5s11 4.925 11 11-4.925 11-11 11S5 22.075 5 16z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M19 30.5a1.5 1.5 0 000 3h26a1.5 1.5 0 000-3H19z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 21a5 5 0 1110 0 5 5 0 01-10 0zm5-2a2 2 0 100 4 2 2 0 000-4zM32 38a5 5 0 100 10 5 5 0 000-10zm-2 5a2 2 0 114 0 2 2 0 01-4 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zM9 32C9 19.297 19.297 9 32 9s23 10.297 23 23-10.297 23-23 23S9 44.703 9 32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconDivideCircleRegular)
