import { observer } from 'mobx-react-lite'

import { Input } from '@ui/Input'

import CardForm from '@lib/components/CardForm'
import PhoneInput from '@lib/components/PhoneInput'
import useCardForm from '@lib/hooks/useCardForm'

import { useCompanyContactInformationFormStore } from '@modules/company/store/CompanyContactInformationFormStore'

const ContactInformation = () => {
  const store = useCompanyContactInformationFormStore()

  const { handleFocus, handleSubmit, handleCancel, isEditing, isSaved } =
    useCardForm(store.handleSubmit, store.reset)

  return (
    <CardForm
      title="Контактная информация"
      direction="column"
      editable={isEditing}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      saved={isSaved}>
      <PhoneInput
        label="Телефон организации"
        name="phone"
        value={store.form.fields.phone.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.phone.error}
        errorText={store.form.fields.phone.errorText}
      />
      <Input
        label="Email организации"
        name="email"
        value={store.form.fields.email.value}
        onChange={store.form.handleFieldChange2}
        placeholder="Не заполнено"
        onFocus={handleFocus}
        error={store.form.fields.email.error}
        errorText={store.form.fields.email.errorText}
      />
    </CardForm>
  )
}

export default observer(ContactInformation)
