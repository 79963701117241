import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconXCircleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.409 5.348a.75.75 0 00-1.06 1.061L6.938 8 5.35 9.591a.75.75 0 101.06 1.06L8 9.062l1.591 1.59a.75.75 0 101.06-1.06L9.062 8l1.59-1.591a.75.75 0 00-1.06-1.06L8 6.938 6.409 5.35z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 100 14A7 7 0 008 1zM2.5 8a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.958 6.897a.75.75 0 10-1.061 1.06L8.94 10l-2.043 2.043a.75.75 0 001.06 1.06L10 11.061l2.043 2.042a.75.75 0 101.06-1.06L11.061 10l2.042-2.043a.75.75 0 00-1.06-1.06L10 8.939 7.958 6.897z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.001A7.999 7.999 0 1010 18 7.999 7.999 0 0010 2zm-6.499 8A6.499 6.499 0 1116.5 10 6.499 6.499 0 013.5 10z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M9.27 8.21a.75.75 0 00-1.06 1.06L10.94 12l-2.73 2.73a.75.75 0 101.06 1.06L12 13.06l2.73 2.73a.75.75 0 101.06-1.06L13.06 12l2.73-2.73a.75.75 0 00-1.06-1.06L12 10.94 9.27 8.21z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.053 10.639a1 1 0 00-1.415 1.414L14.587 16l-3.947 3.947a1 1 0 001.414 1.415L16 17.413l3.947 3.947a1 1 0 001.415-1.414L17.413 16l3.947-3.947a1 1 0 00-1.414-1.415L16 14.587l-3.947-3.947z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM5 16C5 9.925 9.925 5 16 5s11 4.925 11 11-4.925 11-11 11S5 22.075 5 16z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M22.576 20.455a1.5 1.5 0 10-2.121 2.121L29.879 32l-9.424 9.424a1.5 1.5 0 102.121 2.121L32 34.121l9.424 9.424a1.5 1.5 0 102.121-2.121L34.121 32l9.424-9.424a1.5 1.5 0 10-2.121-2.121L32 29.879l-9.424-9.424z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6C17.64 6 6 17.64 6 32s11.64 26 26 26 26-11.64 26-26S46.36 6 32 6zM9 32C9 19.297 19.297 9 32 9s23 10.297 23 23-10.297 23-23 23S9 44.703 9 32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconXCircleRegular)
