import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconBookNotepadRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.5 4a.5.5 0 000 1h3a.5.5 0 100-1h-3zM7 6.5a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5zM7.5 8a.5.5 0 000 1h3a.5.5 0 100-1h-3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.75A2.75 2.75 0 014.75 1h6.5A2.75 2.75 0 0114 3.75v8.5A2.75 2.75 0 0111.25 15h-6.5A2.75 2.75 0 012 12.25v-8.5zM4.75 2.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25H5v-11h-.25zm6.5 11H6v-11h5.25c.69 0 1.25.56 1.25 1.25v8.5c0 .69-.56 1.25-1.25 1.25z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10 5a.75.75 0 000 1.5h4A.75.75 0 0014 5h-4zM9.25 8.75A.75.75 0 0110 8h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM10 11a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25 4.5A3.25 3.25 0 016.5 1.25h8a3.25 3.25 0 013.25 3.25v11a3.25 3.25 0 01-3.25 3.25h-8a3.25 3.25 0 01-3.25-3.25v-11zm1.5 0a1.75 1.75 0 011.5-1.732v14.464a1.75 1.75 0 01-1.5-1.732v-11zm3-1.75v14.5h6.75a1.75 1.75 0 001.75-1.75v-11a1.75 1.75 0 00-1.75-1.75H7.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.5 7a.75.75 0 000 1.5h5a.75.75 0 000-1.5h-5zM9.75 10.75a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75zM10.5 13a.75.75 0 000 1.5h5a.75.75 0 000-1.5h-5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25 6A3.75 3.75 0 017 2.25h9A3.75 3.75 0 0119.75 6v12A3.75 3.75 0 0116 21.75H7A3.75 3.75 0 013.25 18V6zm1.5 0c0-.98.626-1.813 1.5-2.122v16.244A2.251 2.251 0 014.75 18V6zm3-2.25v16.5H16A2.25 2.25 0 0018.25 18V6A2.25 2.25 0 0016 3.75H7.75z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.5 10a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zM14.5 13a1 1 0 100 2h7a1 1 0 100-2h-7zM13.5 18a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zM11 7a1 1 0 10-2 0v18a1 1 0 102 0V7z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7.5A5.5 5.5 0 0110.5 2h11A5.5 5.5 0 0127 7.5v17a5.5 5.5 0 01-5.5 5.5h-11A5.5 5.5 0 015 24.5v-17zM10.5 4A3.5 3.5 0 007 7.5v17a3.5 3.5 0 003.5 3.5h11a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0021.5 4h-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M20.5 13a1.5 1.5 0 011.5 1.5v35a1.5 1.5 0 01-3 0v-35a1.5 1.5 0 011.5-1.5zM28.5 18a1.5 1.5 0 000 3h14a1.5 1.5 0 000-3h-14zM27 27.5a1.5 1.5 0 011.5-1.5h14a1.5 1.5 0 010 3h-14a1.5 1.5 0 01-1.5-1.5zM28.5 34a1.5 1.5 0 000 3h14a1.5 1.5 0 000-3h-14z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16.5C11 10.701 15.701 6 21.5 6h21C48.299 6 53 10.701 53 16.5v31C53 53.299 48.299 58 42.5 58h-21C15.701 58 11 53.299 11 47.5v-31zM21.5 9a7.5 7.5 0 00-7.5 7.5v31a7.5 7.5 0 007.5 7.5h21a7.5 7.5 0 007.5-7.5v-31A7.5 7.5 0 0042.5 9h-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconBookNotepadRegular)
