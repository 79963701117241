import { format } from 'date-fns'
import Ru from 'date-fns/locale/ru'

export const useDatesFormatter = (date: string) => {
  if (!date) {
    return
  }
  const formattedDate = date && date.replace(/'-'/d, '.')

  const weekDay = format(new Date(formattedDate), 'EEEE', {
    locale: Ru,
  })

  const day = format(new Date(formattedDate), 'd')

  const monthRod = format(new Date(formattedDate), 'MMMM', {
    locale: Ru,
  })

  const monthIm = format(new Date(formattedDate), 'LLLL', {
    locale: Ru,
  })

  return {
    weekDay: weekDay.charAt(0).toUpperCase() + weekDay.slice(1),
    day,
    monthRod: monthRod.charAt(0).toUpperCase() + monthRod.slice(1),
    monthIm: monthIm.charAt(0).toUpperCase() + monthIm.slice(1),
  }
}
