import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowALeftLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M7.27 12.146a.5.5 0 11-.708.707L2.556 8.849a1.906 1.906 0 010-2.696l4.006-4.006a.5.5 0 11.707.707L3.263 6.86a.91.91 0 00-.115.14H13.5a.5.5 0 010 1H3.148a.91.91 0 00.115.142l4.006 4.005z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M8.635 15.709a.5.5 0 11-.707.707L2.69 11.179a2.375 2.375 0 010-3.358l5.237-5.237a.5.5 0 01.707.707L3.398 8.528A1.37 1.37 0 003.09 9H17.5a.5.5 0 010 1H3.09c.066.172.17.333.308.472l5.237 5.237z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowALeftLight)
