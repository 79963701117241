import { isNumber } from 'lodash'
import SwiperClass from 'swiper/types/swiper-class'

import {
  IMapAccessory,
  IOrderComplectation,
} from '@modules/configurator/models/Configurator'

/**
 * Получение урла картинки.
 *
 * @param imageName Название картинки.
 */
export const getImageUrl = (imageName: string) =>
  require(`@assets/${imageName}`)

const isFloat = (number: number) => {
  return Number(number) === number && number % 1 !== 0
}

export const getCurrencyString = (
  number: number | string | undefined,
  type: 'int' | 'float' = 'int',
): string => {
  if (!number && number !== 0) return

  const stringNumber = isNumber(number) ? number.toString() : number
  const splitFloat = stringNumber.split('.')

  const integerString = Number(splitFloat[0]).toLocaleString()
  const floatString =
    type === 'float' && isFloat(Number(number))
      ? `.${splitFloat[1].slice(0, 2)}`
      : '.00'

  const formattedString =
    type === 'int' ? integerString : integerString + floatString

  return `${formattedString} ₽`
}

/**
 * Принимает на вход число, а также массив уже склоняемых слов.
 * Пример: [товар, товара, товаров] - 1 товар, 2 товара, 10 товаров.
 * @param {number} number
 * @param {string[]} words
 * @returns {string}
 */
export const declinationByNum = (number, words) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ]
}

export const getCostOfAccessories = (
  accessory: IMapAccessory,
  count = 1,
): number =>
  Object.values(accessory).reduce(
    (acc, cur) =>
      (acc += isNumber(cur.price) ? cur.price : parseFloat(cur.price)),
    0,
  ) * count

export const getCostOfComplactations = (
  complectations: IOrderComplectation[],
): number =>
  complectations.reduce(
    (complectationsSum, complectation) =>
      complectationsSum +
      getCostOfAccessories(
        complectation.components.accessories,
        complectation.count,
      ),
    0,
  )

export const useSliderControl = (instance: SwiperClass) => {
  const handleSlideChangeButton = (direction: 'prev' | 'next') => {
    if (direction === 'prev')
      return instance?.slideTo(
        instance.realIndex === 0
          ? instance.slides.length - 1
          : instance.realIndex - 1,
      )
    if (direction === 'next')
      return instance.slideTo(
        instance.realIndex ===
          instance.slides.length - +instance.params.slidesPerView
          ? 0
          : instance.realIndex + 1,
      )
  }

  return {
    handleSlideChangeButton,
  }
}
