import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSearchLoupeLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 1.751a5.749 5.749 0 103.696 10.152l2.706 2.705a.5.5 0 10.707-.707l-2.706-2.705A5.749 5.749 0 006.5 1.751zM1.751 7.5a4.749 4.749 0 119.498 0 4.749 4.749 0 01-9.498 0z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.504 2.256a7.248 7.248 0 104.759 12.714l3.375 3.376a.5.5 0 10.707-.707l-3.375-3.376A7.248 7.248 0 009.504 2.256zM3.256 9.504a6.248 6.248 0 1112.495 0 6.248 6.248 0 01-12.495 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSearchLoupeLight)
