import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconCommentRectXRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.404 6.404a.75.75 0 10-1.06-1.06L8 6.687 6.656 5.344a.75.75 0 00-1.06 1.06l1.343 1.344-1.343 1.344a.75.75 0 001.06 1.06L8 8.81l1.344 1.343a.75.75 0 101.06-1.06L9.061 7.748l1.343-1.344z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 2A2.75 2.75 0 001 4.75v6c0 1.348.97 2.47 2.25 2.705v1.28a1.25 1.25 0 001.97 1.021L8.418 13.5h3.833A2.75 2.75 0 0015 10.75v-6A2.75 2.75 0 0012.25 2h-8.5zM2.5 4.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25H8.18a.75.75 0 00-.433.137L4.75 14.253V12.75A.75.75 0 004 12h-.25c-.69 0-1.25-.56-1.25-1.25v-6z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M12.784 6.784a.75.75 0 00-1.06-1.06L10 7.446 8.277 5.723a.75.75 0 00-1.061 1.061l1.723 1.723-1.723 1.724a.75.75 0 001.06 1.06L10 9.568l1.723 1.723a.75.75 0 101.061-1.06l-1.723-1.724 1.723-1.723z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.249 2a3.25 3.25 0 00-3.25 3.25v6.5A3.25 3.25 0 005.249 15h.001v1.978c0 1.04 1.196 1.625 2.017.986L11.08 15h3.672a3.25 3.25 0 003.25-3.25v-6.5A3.25 3.25 0 0014.751 2H5.25zm-1.75 3.25c0-.966.783-1.75 1.75-1.75h9.502c.967 0 1.75.784 1.75 1.75v6.5a1.75 1.75 0 01-1.75 1.75h-3.93a.75.75 0 00-.46.158L6.75 16.467V14.25A.75.75 0 006 13.5h-.751a1.75 1.75 0 01-1.75-1.75v-6.5z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M15.153 8.907a.75.75 0 10-1.06-1.06L12 9.939 9.907 7.847a.75.75 0 10-1.06 1.06L10.939 11l-2.092 2.093a.75.75 0 001.06 1.06L12 12.061l2.093 2.092a.75.75 0 001.06-1.06L13.061 11l2.092-2.093z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3.25A3.75 3.75 0 002.25 7v8a3.751 3.751 0 003 3.675v2.352c0 1.226 1.39 1.934 2.382 1.213l4.8-3.49H18A3.75 3.75 0 0021.75 15V7A3.75 3.75 0 0018 3.25H6zM3.75 7A2.25 2.25 0 016 4.75h12A2.25 2.25 0 0120.25 7v8A2.25 2.25 0 0118 17.25h-5.813a.75.75 0 00-.44.143L6.75 21.027V18a.75.75 0 00-.75-.75A2.25 2.25 0 013.75 15V7z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M20.708 10.706a1 1 0 00-1.414-1.414L16 12.586l-3.294-3.294a1 1 0 00-1.414 1.414L14.586 14l-3.294 3.294a1 1 0 001.414 1.414L16 15.414l3.294 3.294a1 1 0 101.414-1.414L17.414 14l3.294-3.294z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 3A5.5 5.5 0 002 8.5v11a5.5 5.5 0 005 5.478v3.079c0 1.627 1.839 2.573 3.162 1.627L16.721 25H24.5a5.5 5.5 0 005.5-5.5v-11A5.5 5.5 0 0024.5 3h-17zM4 8.5A3.5 3.5 0 017.5 5h17A3.5 3.5 0 0128 8.5v11a3.5 3.5 0 01-3.5 3.5h-8.1a1 1 0 00-.581.186L9 28.056V24a1 1 0 00-1-1h-.5A3.5 3.5 0 014 19.5v-11z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M41.546 20.576a1.5 1.5 0 00-2.122-2.122L32 25.88l-7.424-7.425a1.5 1.5 0 00-2.122 2.122L29.88 28l-7.425 7.425a1.5 1.5 0 002.122 2.12L32 30.122l7.424 7.425a1.5 1.5 0 102.122-2.121L34.12 28l7.425-7.424z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 7C10.701 7 6 11.701 6 17.5v21c0 5.631 4.433 10.227 10 10.488v5.626c0 2.846 3.218 4.502 5.534 2.848L33.381 49H47.5C53.299 49 58 44.299 58 38.5v-21C58 11.701 53.299 7 47.5 7h-31zM9 17.5a7.5 7.5 0 017.5-7.5h31a7.5 7.5 0 017.5 7.5v21a7.5 7.5 0 01-7.5 7.5H32.9a1.5 1.5 0 00-.872.28l-12.237 8.74a.5.5 0 01-.791-.406V47.5a1.5 1.5 0 00-1.5-1.5h-1A7.5 7.5 0 019 38.5v-21z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconCommentRectXRegular)
