import React, { memo, useCallback, useState } from 'react'

import { IconQuestionCircleLight } from '@ui/icons'
import { Typography } from '@ui/Typography'

import { IInfo } from '@modules/products/models/Products'

import ProductsModal from '../ProductsModal/ProductsModal'

import styles from './TableDescriptionProduct.module.scss'
import { IDescriptionProductProps } from './types'

const TableDescriptionProduct: React.FC<IDescriptionProductProps> = ({
  info,
}) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<IInfo>(undefined)

  const handleShowModal = useCallback((data: IInfo) => {
    setIsShowModal(true)
    setModalData(data)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsShowModal(false)
    setModalData(undefined)
  }, [])

  return (
    <>
      <table className={styles.table}>
        <tbody>
          {info?.map((data, index) => (
            <tr key={index}>
              <td className={styles.name}>
                <Typography size={16}>{data.label} </Typography>
                <IconQuestionCircleLight
                  onClick={() => handleShowModal(data)}
                />
              </td>
              <td>
                <Typography size={16}>{data.value}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ProductsModal
        handleCloseModal={handleCloseModal}
        isShowModal={isShowModal}
        label={modalData?.label}
        tooltip={modalData?.tooltip}
      />
    </>
  )
}

export default memo(TableDescriptionProduct)
