import styles from './styles.scss'

const Gpu = () => (
  <div className={styles.container}>
    <p>
      Видеокарта (графический адаптер) – это компонент компьютера, который
      обрабатывает и выводит на экран графическую информацию. Она является
      ответственной за отображение изображений, видео и 3D-графики на мониторе.
    </p>

    <p>
      При выборе видеокарты нужно обратить внимание на ее производительность,
      совместимость с другими компонентами вашего компьютера и поддержку нужных
      вам функций, таких как поддержка многопоточной обработки изображений, HDR,
      ray tracing и других технологий. Также нужно убедиться, что видеокарта
      имеет достаточное охлаждение, чтобы избежать перегрева во время работы.
    </p>
  </div>
)

export default Gpu
