import { singleton } from 'tsyringe'

import { AbstractRest } from '@lib/services'

import { EDataBanner } from '../models/Banners'

@singleton()
export class BannerRest extends AbstractRest {
  async fetchBannerData(key: string) {
    const { data } = await this.http.get<EDataBanner>(`/lk/banners/${key}`)
    return data
  }
}
