import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconArrowShapeARightLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.573 7.116a1.25 1.25 0 010 1.768L8.707 14.75c-.63.63-1.707.184-1.707-.707V12H2.5A1.5 1.5 0 011 10.5v-5A1.5 1.5 0 012.5 4H7V1.957C7 1.067 8.077.62 8.707 1.25l5.866 5.866zm-.707 1.061a.25.25 0 000-.353L8 1.957V4.5a.5.5 0 01-.5.5h-5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h5a.5.5 0 01.5.5v2.543l5.866-5.866z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.727 8.565c.793.793.793 2.08 0 2.873l-6.829 6.827c-.689.689-1.867.2-1.867-.774V14.97H3.75c-.95 0-1.719-.77-1.719-1.719v-6.5c0-.95.77-1.719 1.719-1.719h5.281V2.51c0-.974 1.178-1.462 1.867-.773l6.829 6.829zm-.664 2.21a1.094 1.094 0 000-1.547l-6.828-6.83a.156.156 0 00-.266.111V5.5c0 .259-.21.469-.47.469H3.75a.781.781 0 00-.781.781v6.5c0 .431.35.781.781.781H9.5c.259 0 .469.21.469.469v2.991c0 .14.168.21.266.11l6.828-6.826z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowShapeARightLight)
