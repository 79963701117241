import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconWarningTriangleRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.875 10.656a.875.875 0 11-1.75 0 .875.875 0 011.75 0zM8.75 4.688a.75.75 0 10-1.5 0V7.9a.75.75 0 101.5 0V4.688z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.864 1.232c.973-1.602 3.299-1.602 4.273 0l5.496 9.046C16.625 11.91 15.45 14 13.539 14H2.461C.551 14-.625 11.91.367 10.278l5.497-9.046zm2.99.779a1 1 0 00-1.708 0l-5.497 9.046a.95.95 0 00.812 1.443H13.54a.95.95 0 00.812-1.443L8.855 2.01z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11 13a1 1 0 11-2 0 1 1 0 012 0zM10.75 6.51a.75.75 0 00-1.5 0v3.589a.75.75 0 001.5 0v-3.59z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.91 2.32c.954-1.565 3.226-1.565 4.181 0l6.535 10.702c.997 1.632-.178 3.726-2.09 3.726H3.465c-1.914 0-3.089-2.094-2.092-3.726L7.91 2.319zm2.901.781a.95.95 0 00-1.622 0L2.654 13.803a.95.95 0 00.811 1.445h13.07a.95.95 0 00.811-1.445L10.811 3.101z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.125 15.928a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM12.75 7.845a.75.75 0 10-1.5 0v4.407a.75.75 0 001.5 0V7.845z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.906 3.149c.954-1.57 3.233-1.57 4.188 0l7.985 13.142c.992 1.632-.184 3.722-2.094 3.722H4.015c-1.91 0-3.086-2.09-2.094-3.722L9.906 3.149zm2.906.779a.95.95 0 00-1.624 0L3.203 17.07a.95.95 0 00.812 1.443h15.97a.95.95 0 00.812-1.444L12.812 3.929z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M17.249 21.5a1.248 1.248 0 11-2.497 0 1.248 1.248 0 012.497 0zM17 10.25a1 1 0 10-2 0v7.25a1 1 0 002 0v-7.25z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.582 3.93c1.558-2.563 5.278-2.563 6.836 0l10.329 16.992c1.62 2.666-.299 6.078-3.418 6.078H5.67c-3.12 0-5.038-3.412-3.418-6.078L12.582 3.93zm5.127 1.039c-.779-1.282-2.639-1.282-3.418 0L3.962 21.96c-.81 1.333.15 3.039 1.71 3.039h20.657c1.56 0 2.519-1.706 1.709-3.039L17.709 4.97z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M34 44a2 2 0 11-4 0 2 2 0 014 0zM33.5 21.5a1.5 1.5 0 00-3 0v15a1.5 1.5 0 003 0v-15z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.574 9.766c2.915-4.842 9.937-4.842 12.852 0L58.81 43.632C61.82 48.631 58.22 55 52.385 55h-40.77C5.78 55 2.18 48.63 5.189 43.632L25.574 9.766zm10.281 1.547c-1.749-2.905-5.961-2.905-7.71 0L7.759 45.18C5.954 48.18 8.114 52 11.615 52h40.77c3.501 0 5.661-3.822 3.856-6.82L35.855 11.312z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconWarningTriangleRegular)
