import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconSendPaperplaneDiagLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.214 7.129c-.859-.592-.659-1.913.337-2.223L12.729 1.42a1.25 1.25 0 011.566 1.565L10.81 14.165c-.31.995-1.632 1.195-2.223.336L5.58 10.135 1.214 7.129zm5.374 2.706l2.823 4.1a.25.25 0 00.444-.068L13.34 2.689a.25.25 0 00-.313-.314L1.849 5.86a.25.25 0 00-.068.445l4.1 2.823 2.67-2.671a.5.5 0 11.708.707l-2.671 2.67z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.254 8.919c-.933-.646-.71-2.083.376-2.416l12.907-3.95a1.358 1.358 0 011.696 1.697l-3.95 12.907c-.332 1.085-1.77 1.308-2.415.375l-3.523-5.09-5.09-3.523zm6.1 3.221l3.336 4.823c.17.246.55.187.637-.099l3.95-12.907a.358.358 0 00-.447-.447L2.922 7.46a.358.358 0 00-.099.636l4.823 3.337 3.106-3.106a.5.5 0 11.707.707L8.353 12.14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconSendPaperplaneDiagLight)
