import { makeAutoObservable, runInAction } from 'mobx'
import { container, singleton } from 'tsyringe'

import { EStatusProcess, FormConstructor } from '@lib/form'

import { MarketingStore } from './MarketingStore'

@singleton()
export class MarketingFundsFormStore {
  private marketingStore = container.resolve(MarketingStore)

  status: EStatusProcess = EStatusProcess.IDLE

  form = new FormConstructor({
    event: {
      rule: 'regex:/^[a-zA-ZА-ЯЁа-яё0-9-:"\'@. ?!№]+$/',
      errorText: 'Введите корректную информацию',
      label: 'Расскажите о мероприятии',
    },
    result: {
      rule: 'regex:/^[a-zA-ZА-ЯЁа-яё0-9-:"\'@. ?!№]+$/',
      errorText: 'Введите корректную информацию',
      label: 'Результат мероприятия',
    },
    budget: {
      valueType: 'number',
      rule: 'required',
      label: 'Бюджет на мероприятие',
    },
    date: {
      rule: 'required|date',
      label: 'Дата проведения',
    },
  })

  constructor() {
    makeAutoObservable<MarketingFundsFormStore, 'marketingStore'>(this, {
      marketingStore: false,
    })
  }

  async handleSubmit() {
    await this.form.handleSubmit((fields) =>
      this.marketingStore.sendMarketingFundsRequest({
        event: fields.event.value,
        result: fields.result.value,
        budget: fields.budget.value,
        date: fields.date.value,
      }),
    )

    runInAction(() => {
      this.status = this.form.meta.fetchStatus
    })
  }

  clear() {
    this.status = EStatusProcess.IDLE
    this.form.clear()
  }

  get isDisabledSend(): boolean {
    return !(
      this.form.fields.event.value &&
      !this.form.fields.event.error &&
      !this.form.fields.result.error &&
      !this.form.fields.budget.error &&
      !this.form.fields.date.error &&
      this.form.fields.result.value &&
      this.form.fields.budget.value &&
      this.form.fields.date.value
    )
  }
}

export const useMarketingFundsFormStore = () =>
  container.resolve(MarketingFundsFormStore)
