import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconToolPencilSquareLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.357 5.35l1.193-1.193a1.5 1.5 0 000-2.12l-.586-.587a1.5 1.5 0 00-2.121 0L5.439 7.854A1.5 1.5 0 005 8.914V10.5a.5.5 0 00.5.5h1.586a1.5 1.5 0 001.06-.44l5.204-5.203.004-.003.003-.004zm-.1-3.193a.5.5 0 00-.707 0L11.707 3 13 4.293l.843-.843a.5.5 0 000-.707l-.586-.586zM12.293 5L11 3.707 6.146 8.561A.5.5 0 006 8.914V10h1.086a.5.5 0 00.353-.146L12.293 5z"
          fill="currentColor"
        />
        <path
          d="M4 3a2 2 0 00-2 2v7a2 2 0 002 2h7a2 2 0 002-2V9.5a.5.5 0 011 0V12a3 3 0 01-3 3H4a3 3 0 01-3-3V5a3 3 0 013-3h3.5a.5.5 0 110 1H4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.358 6.35l1.192-1.193a1.5 1.5 0 000-2.12l-.586-.587a1.5 1.5 0 00-2.121 0L7.439 9.854A1.5 1.5 0 007 10.914V12.5a.5.5 0 00.5.5h1.586a1.5 1.5 0 001.06-.44l6.203-6.202.005-.004.004-.004zm-.101-3.193a.5.5 0 00-.707 0L14.707 4 16 5.293l.843-.843a.5.5 0 000-.707l-.586-.586zM15.293 6L14 4.707l-5.854 5.854a.5.5 0 00-.146.353V12h1.086a.5.5 0 00.353-.146L15.293 6z"
        fill="currentColor"
      />
      <path
        d="M5.5 4A2.5 2.5 0 003 6.5v8A2.5 2.5 0 005.5 17h8a2.5 2.5 0 002.5-2.5v-4a.5.5 0 111 0v4a3.5 3.5 0 01-3.5 3.5h-8A3.5 3.5 0 012 14.5v-8A3.5 3.5 0 015.5 3h4a.5.5 0 010 1h-4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconToolPencilSquareLight)
