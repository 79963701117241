import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconMoneyTypeCardLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path d="M9.5 6a.5.5 0 110-1h2a.5.5 0 110 1h-2z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5.5A2.5 2.5 0 013.5 3h8A2.5 2.5 0 0114 5.5v5a2.5 2.5 0 01-2.5 2.5h-8A2.5 2.5 0 011 10.5v-5zm12 0V9H2V5.5A1.5 1.5 0 013.5 4h8A1.5 1.5 0 0113 5.5zm-11 5V10h11v.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 10.5z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M12 7.5a.5.5 0 00.5.5h2a.5.5 0 000-1h-2a.5.5 0 00-.5.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm1 5v1a2 2 0 002 2h10a2 2 0 002-2v-1H3zm14-1H3V7a2 2 0 012-2h10a2 2 0 012 2v4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconMoneyTypeCardLight)
