import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconTimeClockMoveForwardRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6.57 2.687a5.503 5.503 0 016.253 2.664c.031.057.07.109.112.154h-1.2a.75.75 0 000 1.5h2.515a.75.75 0 00.75-.75V2.766a.75.75 0 00-1.5 0v.9a7.002 7.002 0 10.788 7.416.75.75 0 00-1.347-.66 5.503 5.503 0 11-6.37-7.735z"
          fill="currentColor"
        />
        <path
          d="M8.5 4.699a.75.75 0 10-1.5 0v2.675c0 .67.383 1.282.987 1.574l2.134 1.035a.75.75 0 10.654-1.35L8.641 7.6a.25.25 0 01-.141-.225V4.699z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.203 3.755a6.499 6.499 0 017.276 2.75H14a.75.75 0 100 1.5h3.25a.75.75 0 00.75-.75v-3.5a.75.75 0 00-1.5 0v1.583a7.999 7.999 0 10.703 8.14.75.75 0 10-1.35-.652 6.498 6.498 0 11-7.65-9.071z"
          fill="currentColor"
        />
        <path
          d="M10.479 6.443a.75.75 0 00-1.5 0v3.123a2 2 0 001.086 1.78l2.693 1.383a.75.75 0 10.685-1.334l-2.692-1.384a.5.5 0 01-.272-.445V6.443z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M10.533 3.881A8.25 8.25 0 0119.35 8.25H16.5a.75.75 0 000 1.5H21a.75.75 0 00.75-.75V4.246a.75.75 0 00-1.5 0v2.558a9.75 9.75 0 10.778 8.877.75.75 0 00-1.389-.566A8.25 8.25 0 1110.533 3.88z"
          fill="currentColor"
        />
        <path
          d="M12.5 7.313a.75.75 0 00-1.5 0v3.948a2.25 2.25 0 001.244 2.012l3.296 1.648a.75.75 0 10.67-1.342l-3.295-1.648a.75.75 0 01-.415-.67V7.313z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.821 5.218A11 11 0 0125.798 11H22.5a1 1 0 100 2h5a1.5 1.5 0 001.5-1.5V6a1 1 0 10-2 0v3.072a13 13 0 10.905 12.15 1 1 0 00-1.832-.803A11 11 0 1113.821 5.218z"
          fill="currentColor"
        />
        <path
          d="M16 9.75a1 1 0 10-2 0v5.264a3 3 0 001.658 2.683l4.395 2.197a1 1 0 10.894-1.788l-4.394-2.198a1 1 0 01-.553-.894V9.75z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M26.452 9.68A23 23 0 0152.2 21H44.5a1.5 1.5 0 000 3h9.75A2.75 2.75 0 0057 21.25V11a1.5 1.5 0 10-3 0v7.144a26 26 0 10.984 26.012 1.5 1.5 0 00-2.652-1.403 23 23 0 11-25.88-33.074z"
        fill="currentColor"
      />
      <path
        d="M32 19.5a1.5 1.5 0 00-3 0v10.528a5.5 5.5 0 003.04 4.92l8.79 4.394a1.5 1.5 0 001.34-2.684l-8.788-4.394A2.5 2.5 0 0132 30.028V19.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconTimeClockMoveForwardRegular)
