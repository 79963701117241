import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { ILightIconProps } from '../types'
import { useLightIconSize } from '../utils'

const IconInfoSquareLight = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: ILightIconProps) => {
  const iconSize = useLightIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M6 7.1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V10h.5a.5.5 0 010 1h-2a.5.5 0 110-1H7V7.6h-.5a.5.5 0 01-.5-.5zM7.25 5.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4a3 3 0 013-3h7a3 3 0 013 3v7a3 3 0 01-3 3H4a3 3 0 01-3-3V4zm3-2a2 2 0 00-2 2v7a2 2 0 002 2h7a2 2 0 002-2V4a2 2 0 00-2-2H4z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M10 6a.75.75 0 11-1.5 0A.75.75 0 0110 6zM8.5 8.011a.5.5 0 000 1H9v2.999H8a.5.5 0 100 1h3a.5.5 0 000-1h-1V8.761a.75.75 0 00-.75-.75H8.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5A3.5 3.5 0 015.5 2h8A3.5 3.5 0 0117 5.5v8a3.5 3.5 0 01-3.5 3.5h-8A3.5 3.5 0 012 13.5v-8zM5.5 3A2.5 2.5 0 003 5.5v8A2.5 2.5 0 005.5 16h8a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0013.5 3h-8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconInfoSquareLight)
