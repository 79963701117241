import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { IRegularIconProps } from '../types'
import { useRegularIconSize } from '../utils'

const IconArrowARightRegular = ({
  size = 16,
  onClick,
  className,
  color,
  adaptive = true,
}: IRegularIconProps) => {
  const iconSize = useRegularIconSize(size, adaptive)
  const inlineStyles = useMemo(
    () => ({
      display: 'inline-block',
      minWidth: iconSize,
    }),
    [iconSize],
  )

  if (iconSize === 16) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M8.426 3.608a.75.75 0 111.061-1.06l3.928 3.927a2.156 2.156 0 010 3.05l-3.928 3.927a.75.75 0 11-1.06-1.06l3.64-3.642H2.716a.75.75 0 010-1.5h9.353L8.426 3.608z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 20) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M11.188 3.968a.75.75 0 111.061-1.06l5.237 5.236a2.625 2.625 0 010 3.712l-5.237 5.237a.75.75 0 01-1.06-1.06l5.236-5.238c.015-.014.03-.03.043-.045H2.5a.75.75 0 010-1.5h13.968a1.15 1.15 0 00-.043-.045l-5.237-5.237z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 24) {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M13.444 4.744a.875.875 0 011.237-1.238l6.284 6.284a3.125 3.125 0 010 4.42l-6.284 6.284a.875.875 0 11-1.237-1.238l6.284-6.284c.031-.031.06-.063.088-.097H3a.875.875 0 010-1.75h16.816a1.415 1.415 0 00-.088-.097l-6.284-6.284z"
          fill="currentColor"
        />
      </svg>
    )
  } else if (iconSize === 32) {
    return (
      <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={inlineStyles}
        color={color}>
        <path
          d="M18.043 6.207a1 1 0 011.414-1.414l8.379 8.378a4 4 0 010 5.657l-8.379 8.379a1 1 0 11-1.414-1.414l8.379-8.379c.126-.127.232-.266.318-.414H4a1 1 0 110-2h22.74a2.005 2.005 0 00-.318-.414l-8.38-8.379z"
          fill="currentColor"
        />
      </svg>
    )
  }

  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={inlineStyles}
      color={color}>
      <path
        d="M36.44 12.06a1.5 1.5 0 012.12-2.12l16.758 16.757a7.5 7.5 0 010 10.606L38.561 54.061a1.5 1.5 0 01-2.122-2.122l16.758-16.757a4.482 4.482 0 001.061-1.682H8a1.5 1.5 0 110-3h46.258a4.483 4.483 0 00-1.061-1.682L36.439 12.061z"
        fill="currentColor"
      />
    </svg>
  )
}

export default observer(IconArrowARightRegular)
