import React, { memo } from 'react'

import {
  IconArchiveBoxRegular,
  IconBuildingOfficeRegular,
  IconDeliveryBoxFrontOpenRegular,
  IconMinusRegular,
  IconSettingsGearRegular,
  IconTimeClockMoveForwardRegular,
} from '@ui/icons'
import { IIconProps } from '@ui/icons/types'
import { COLORS } from '@ui/Theme/color'
import { Typography } from '@ui/Typography'

import CardWrapper from '@lib/components/CardWrapper'

import { LokalizedStages, Stages } from '@modules/sales/models/Transaction'

import styles from './TransactionStage.module.scss'

interface ITransactionStageProps {
  stage: Stages
}

interface IStageItem extends ITransactionStageProps {
  stageType: Stages
  Icon: React.FC<IIconProps>
}

const StageItem = memo(({ stage, stageType, Icon }: IStageItem) => (
  <div className={styles.stage}>
    <Icon
      size={16}
      color={
        stage === stageType ? COLORS.BRAND.DARK_SKY : COLORS.BRAND.MEDIUM_SKY
      }
    />
    <Typography
      size={16}
      color={stage === stageType ? COLORS.GRAY.OBSIDIAN : COLORS.GRAY.GRAY_2}>
      {LokalizedStages[stageType]}
    </Typography>

    {stageType !== Stages.SHIPMENT && (
      <IconMinusRegular color={COLORS.GRAY.GRAY_2} />
    )}
  </div>
))

const TransactionStage = ({ stage }: ITransactionStageProps) => (
  <CardWrapper py={20}>
    <Typography size={18}>Состояние выполнения сделки (заказа)</Typography>
    <div className={styles.stages}>
      {/* Обработка заказа */}
      <StageItem
        stage={stage}
        stageType={Stages.PROCESSING}
        Icon={IconTimeClockMoveForwardRegular}
      />

      {/* В производстве */}
      <StageItem
        stage={stage}
        stageType={Stages.IN_PRODUCTION}
        Icon={IconSettingsGearRegular}
      />

      {/* Сборка */}
      <StageItem
        stage={stage}
        stageType={Stages.ASSEMBLING}
        Icon={IconDeliveryBoxFrontOpenRegular}
      />

      {/* Передача на склад */}
      <StageItem
        stage={stage}
        stageType={Stages.TRANSFER_WAREHOUSE}
        Icon={IconBuildingOfficeRegular}
      />

      {/* Отгрузка */}
      <StageItem
        stage={stage}
        stageType={Stages.SHIPMENT}
        Icon={IconArchiveBoxRegular}
      />
    </div>
  </CardWrapper>
)

export default memo(TransactionStage)
